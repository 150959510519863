import {
  CategoryDTO,
  CategoryDTOV1,
  ContractDTOV1,
  ContractFieldDTOV1,
} from "openapi";

type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

export const getChangedValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: UnknownArrayOrObject
): UnknownArrayOrObject => {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      getChangedValues(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dirtyFields[key] as UnknownArrayOrObject,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        allValues[key] as UnknownArrayOrObject
      ),
    ])
  );
};

export const initializeContractFormFieldsData = (
  category: CategoryDTO | CategoryDTOV1,
  fieldDefinitions: ContractFieldDTOV1[],
  fields: ContractDTOV1["fields"]
) => {
  const initializedFields: ContractDTOV1["fields"] = { ...fields };

  for (const section of category.sections) {
    for (const field of section.fields) {
      if (field.id in initializedFields) {
        continue;
      }

      const definition = fieldDefinitions.find(
        (definition) => definition.id === field.id
      );

      if (!definition) {
        continue;
      }

      switch (definition.type) {
        case ContractFieldDTOV1.type.AMOUNT: {
          initializedFields[field.id] = { value: "", currency: "EUR" };
          break;
        }
        case ContractFieldDTOV1.type.DURATION: {
          initializedFields[field.id] = {
            type: null,
            startDate: null,
            endDate: null,
            terminationDate: null,
            automaticRenewal: null,
            interval: null,
            noticePeriod: null,
          };
          break;
        }
        default: {
          initializedFields[field.id] = { value: "" };
        }
      }
    }
  }
  return initializedFields;
};

export const countVerificationStatus = (contract: ContractDTOV1) => {
  const fields = contract?.fields;

  if (!fields) return;

  let verifiedCount = 0;
  let unverifiedCount = 0;

  Object.values(fields).forEach((field) => {
    if (field.metadata) {
      Object.values(field.metadata).forEach(({ unverified }) =>
        unverified ? unverifiedCount++ : verifiedCount++
      );
    }
  });

  return { verified: verifiedCount, unverified: unverifiedCount };
};
