import React from "react";
import { useTranslation } from "react-i18next";
import { useTeam } from "contexts/team/hooks";
import { theme } from "theme";
import { Header, OptionalText, Row } from "./styles";
import { EntityGridViewSelector } from "components/EntityGrid/EntityGridViewSelector";
import { EntityGridFilterResetButton } from "components/EntityGrid/EntityGridFilterResetButton";
import UploadContractDropdown from "components/ContractUpload/Dropdown/Dropdown";
import { EntityGridSearchInput } from "components/EntityGrid/EntityGridSearchInput";
import { ContractDTOV1 } from "openapi";
import { CTAButton } from "components";
import MoveIcon from "assets/svg/move-icon.svg?react";

type Props = {
  movingContractsAllowed?: boolean;
  canCreateContract?: boolean;
  selectedContracts?: ContractDTOV1[];
  onMoveContractsClick?: () => void;
};

const ContractGridHeader = ({
  movingContractsAllowed,
  canCreateContract,
  selectedContracts,
  onMoveContractsClick,
}: Props) => {
  const { t } = useTranslation();
  const { hasWriteAccessToAny } = useTeam();

  const showContractAddButton = hasWriteAccessToAny();
  const selectedContractsNumber = selectedContracts?.length;

  return (
    <div>
      <Header>
        <Row>
          <EntityGridSearchInput
            label={t("pages.contracts.header.searchContract")}
          />
          <div style={{ display: "flex", gap: theme.spacing.lg }}>
            {movingContractsAllowed ? (
              <CTAButton
                name={t("common.buttons.moveContracts")}
                disabled={!selectedContractsNumber}
                onClick={onMoveContractsClick}
                variant="primary"
                type="button"
                label="move-contracts-button"
                icon={<MoveIcon />}
              />
            ) : null}
            <EntityGridFilterResetButton />
            <EntityGridViewSelector />
            {showContractAddButton ? (
              <UploadContractDropdown
                dataCy="addNewContract"
                label={t("pages.contracts.header.newContract")}
                visibleOptions={{
                  createDraft: true,
                  importViaUpload: true,
                }}
                showLabel
                showTooltip={false}
                iconOrientation="right"
                canCreateContract={canCreateContract}
              />
            ) : null}
          </div>
        </Row>
        <Row>
          {selectedContractsNumber ? (
            <OptionalText>
              {t("pages.contracts.header.selectedContractsNumber", {
                selectedContractsNumber,
              })}
            </OptionalText>
          ) : null}
        </Row>
      </Header>
    </div>
  );
};

export default ContractGridHeader;
