// Same styles for Contracts and Contacts

import styled from "@emotion/styled";
import { theme } from "theme";

export const SIDEBAR_WIDTH = "40px";

type ListItemProps = {
  active?: boolean;
  visible?: boolean;
};

type ListProps = {
  visible?: boolean;
};

export const Wrapper = styled.div`
  width: ${SIDEBAR_WIDTH};
  background: ${theme.color.white};
  border-top-right-radius: ${theme.borderRadius};
  border-bottom-right-radius: ${theme.borderRadius};
  border-left: 1px solid ${theme.color.gray[200]};
`;

export const List = styled.ul<ListProps>`
  list-style-type: none;
  display: flex;
  gap: ${theme.spacing.md};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: transform 0.2s ease-in-out;
  padding: ${theme.spacing.lg} 0 0;
  margin: 0;
`;

export const ListItem = styled.li<ListItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.md};
  width: 24px;
  height: 24px;
  color: ${theme.color.blue[600]};
  border-radius: calc(${theme.borderRadius} / 2);
  cursor: pointer;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out;

  ${({ active }) =>
    active
      ? `
    background-color: ${theme.color.gray[300]};
  `
      : `
    background-color: transparent;
    :hover {
      background-color: ${theme.color.gray[200]};
      svg {
        fill: #283B7D;
      }
    }
    :active {
      background-color: ${theme.color.gray[300]};
      svg {
        fill: #283B7D;
      }
    }
  `}
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    max-width: 100%;
    max-height: 100%;
    fill: #364fa6;
  }
`;

export const ToggleMenu = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${theme.spacing.xs};
  cursor: pointer;
  color: ${theme.color.gray[600]};
  background-color: ${theme.color.gray[300]};
  transition: transform 0.15s ease-in-out;
  box-shadow: ${theme.shadow.standard};

  :active {
    transform: scale(0.95);
  }
`;
