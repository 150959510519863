import styled from "@emotion/styled";
import theme from "theme/theme";

export const EmptyList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${theme.spacing.sm};
  color: ${theme.color.blue[600]};
  opacity: 0.5;
  font-size: ${theme.font.size.body};
  font-weight: ${theme.font.weight.semiBold};
`;
