import React from "react";
import { FormTextField } from "components";
import { FormSelect } from "components/FormItems";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { S3BackupConfig } from "openapi";

const BackupFormS3 = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<S3BackupConfig>();
  return (
    <>
      <FormSelect
        control={control}
        name="storageClass"
        options={[
          "STANDARD",
          "GLACIER",
          "DEEP_ARCHIVE",
          "OUTPOSTS",
          "GLACIER_IR",
        ]}
        translationPrefix={
          "pages.settings.tabs.backup.form.storageClassOptions"
        }
        label={t("pages.settings.tabs.backup.form.storageClass")}
        required
      />
      <FormTextField
        control={control}
        name="bucket"
        label={t("pages.settings.tabs.backup.form.bucket")}
        required
      />
      <FormTextField
        control={control}
        name="accessKey"
        label={t("pages.settings.tabs.backup.form.accessKey")}
        required
      />
      <FormTextField
        control={control}
        name="secretKey"
        label={t("pages.settings.tabs.backup.form.secretKey")}
        required
      />
      <FormSelect
        control={control}
        name="region"
        options={["eu-central-1", "eu-west-1", "eu-west-2"]}
        label={t("pages.settings.tabs.backup.form.region")}
        required
      />
    </>
  );
};

export default BackupFormS3;
