import React, { ReactNode } from "react";
import {
  Title,
  Description,
  IconWrapper,
  TextWrapper,
  Wrapper,
} from "./styles";

export type MessageBoxProps = {
  title?: string;
  ariaLabel?: string;
  description?: string | ReactNode;
  icon?: JSX.Element | undefined | null;
  descriptionLabel?: string;
  variation: "standard" | "error" | "warning" | "info" | "success";
};

const MessageBox = ({
  title,
  description,
  icon,
  ariaLabel,
  descriptionLabel,
  variation,
}: MessageBoxProps) => {
  return (
    <Wrapper aria-label={ariaLabel} variation={variation}>
      {icon ? <IconWrapper variation={variation}>{icon}</IconWrapper> : null}
      <TextWrapper variation={variation}>
        {title && <Title variation={variation}>{title}</Title>}
        {description ? (
          <Description
            noMargin
            id={descriptionLabel}
            aria-label={descriptionLabel}
            variation={variation}
          >
            {description}
          </Description>
        ) : null}
      </TextWrapper>
    </Wrapper>
  );
};

export default MessageBox;
