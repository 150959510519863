import styled from "@emotion/styled";
import { ListItem } from "@mui/material";
import { theme } from "theme";
import { settingsButtonSize } from "../../components/Popover/popoverStyles";

export const Wrapper = styled.div``;

export const paperPropsStyles = {
  border: `1px solid ${theme.color.gray[300]}`,
  backgroundColor: theme.color.white,
};

type CategoryItemProps = {
  active?: boolean;
};

export const CategoryItem = styled(ListItem)`
  cursor: pointer;
  font-weight: normal;
  padding: ${theme.spacing.xs} 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${theme.font.size.body};
  transition: background-color 0.3s ease-in-out;
  color: ${theme.color.gray[700]};

  :hover {
    color: ${theme.color.blue};
  }

  ${({ active }: CategoryItemProps) =>
    active &&
    `
    border-radius: ${theme.borderRadius};
    background-color: ${theme.color.blue[100]};
    color: ${theme.color.blue[600]};
  `}

  .settings {
    width: ${settingsButtonSize};
    height: ${settingsButtonSize};
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    color: ${theme.color.gray[700]};
  }

  ${({ active }: CategoryItemProps) =>
    active &&
    `
    .settings {
      color: ${theme.color.blue[600]};
    }
  `}

  :hover {
    .settings {
      opacity: 1;
    }
  }
`;

export const CategoryLabelBlock = styled.div`
  display: flex;
  align-items: center;
  word-break: break-all;
  padding: ${theme.spacing.sm};
  width: 100%;
`;

export const Row = styled.div<{
  alignItems?: string;
  justifyItems?: string;
  maxWidth?: string;
  gap?: string;
}>`
  display: flex;
  justify-content: ${(props) => props.justifyItems || "space-between"};
  align-items: ${(props) => props.alignItems || "center"};
  max-width: ${(props) => props.maxWidth || "100%"};
  gap: ${(props) => props.gap || theme.spacing.md};
`;

export const FormSection = styled.div`
  padding-top: ${theme.spacing.sm};
  margin-top: ${theme.spacing.lg};
  border-top: 1px solid ${theme.color.gray[400]};
  &:first-child {
    border-top: none;
    padding-top: 0;
    margin-top: 0;
  }
`;

export const Grid = styled.div<{ numberOfColumns?: string }>`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => props.numberOfColumns || "2"},
    1fr
  );

  .MuiFormControlLabel-root {
    overflow: hidden;
  }

  .MuiTypography-root {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${theme.spacing.xl};
  margin-top: ${theme.spacing.xxl};
`;
