import { useTranslation } from "react-i18next";
import { OverviewDataDto, TeamInfoDto } from "openapi";
import { useLocale } from "hooks/GlobalStateHooks";
import { routePaths } from "../../../../../constants";

export const useNumFormatter = (amount: number, locale: string) => {
  if (amount > 1e6 - 1 && amount <= 1e9) {
    const quotient = amount / 1e6;
    const formattedAmount = new Intl.NumberFormat(locale).format(
      +quotient.toFixed(2)
    );
    return formattedAmount + "M";
  } else if (amount >= 1e9) {
    const quotient = amount / 1e9;
    const formattedAmount = new Intl.NumberFormat(locale).format(
      +quotient.toFixed(2)
    );
    return formattedAmount + "B";
  } else {
    const formattedAmount = new Intl.NumberFormat(locale).format(
      Math.round(+amount)
    );
    return formattedAmount;
  }
};

export const useStatisticsData = (
  data: OverviewDataDto,
  teamInfo: TeamInfoDto
) => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  function getContractCount() {
    const count = data?.statistics?.contractCount ?? 0;

    if (teamInfo && teamInfo?.isSubTeam) return `${count}`;

    if (!teamInfo?.maxContracts || teamInfo?.maxContracts >= 10000) {
      return `${count} / ∞`;
    }

    return `${count} / ${teamInfo?.maxContracts}`;
  }

  const income = data?.financials?.income ?? 0;
  const expense = data?.financials?.expense ?? 0;

  const incomeFormatted = `${useNumFormatter(income, locale)} €`;
  const expenseFormatted = `${useNumFormatter(expense, locale)} €`;

  const responseFormatted = [
    {
      id: "statistic1",
      title: t("pages.dashboard.statistics.titles.reminders"),
      value: data?.statistics?.tasksWithDueDate ?? "0",
      tooltipText: t(
        "pages.dashboard.statistics.counters.numberOfTasksWithDueDate"
      ),
      linkTo: routePaths.TASKS,
      linkState: {
        filter: data?.statistics?.tasksWithDueDate ? "task_due_date_view" : "",
      },
      iconName: "reminders",
    },
    {
      id: "statistic2",
      title: t("pages.dashboard.statistics.titles.contracts"),
      value: getContractCount(),
      tooltipText: t("pages.dashboard.statistics.counters.numberOfContracts"),
      linkTo: routePaths.CONTRACTS,
      iconName: "contracts",
    },
    {
      id: "statistic3",
      title: t("pages.dashboard.statistics.titles.income"),
      value: incomeFormatted,
      tooltipText: t("pages.dashboard.statistics.financials.tooltipIncome"),
      linkTo: null,
      iconName: "arrow-up",
    },
    {
      id: "statistic4",
      title: t("pages.dashboard.statistics.titles.expense"),
      value: expenseFormatted,
      tooltipText: t("pages.dashboard.statistics.financials.tooltipExpense"),
      linkTo: null,
      iconName: "arrow-down",
    },
  ];

  return responseFormatted;
};
