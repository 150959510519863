import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { useSnackbar } from "notistack";
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import _ from "lodash";
import { useLocale } from "hooks/GlobalStateHooks";
import {
  ApiError,
  CommonService,
  ContractFieldDto,
  ContractFieldDTOV1,
  ContractFieldUpdateDTOV1,
  ListFieldData,
  NumberFieldConfiguration,
  TranslationDTO,
} from "openapi";
import { CTAButton, LabelWrapper, NewModal } from "components";
import theme from "theme/theme";
import { FormTextField } from "components";
import { getListItems, hasDuplicateListItems } from "./helpers";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { BadRequestResponse } from "../../../../../../../shared/types/ErrorResponse.type";
import { ConfirmSaveChangesModal } from "components/ConfirmSaveChangesModal/ConfirmSaveChangesModal";
import { maxDecimalConfigurationValueValidator } from "constants/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "@emotion/styled";
import { ContractFieldConfiguration } from "../../types";
import { useContractDefinitionUpdateMutation } from "../../../../../../../shared/api/contract-definition";
import { useTeam } from "../../../../../../../contexts/team/hooks";
import { ButtonWithIcon } from "pages/Settings/OrganizationSettings/Category/styles";
import TranslationIcon from "assets/svg/translation-icon.svg?react";
import { ButtonsWrapper } from "./styles";

export const ConfigurationEditAccordion = styled(Accordion)`
  &::before {
    display: none;
  }
`;

const defaultConfigurations: {
  [key in ContractFieldDTOV1["type"]]?: ContractFieldConfiguration | null;
} = {
  // Any newtypes and their corresponding default configurations go here.
  [ContractFieldDto.type.NUMBER]: { key: "decimalScale", value: 2 },
};

type Props = {
  open: boolean;
  onClose: () => void;
  field: ContractFieldDTOV1;
};

type EditFieldForm = {
  name: { de: string; en: string };
  type: ContractFieldDTOV1.type;
  listItemsAsString: string;
  configuration: ContractFieldConfiguration | string;
};

const getListText = (data: ListFieldData) => {
  const items = Object.entries(data);
  const itemsArray = items[0][1].map((item) => item.value);
  const list = itemsArray.join("\n");
  return list;
};

const getConfigurationValue = (
  type: string,
  configuration: ContractFieldConfiguration | null
): string => {
  const defaultConfig =
    defaultConfigurations[type as keyof typeof defaultConfigurations];
  if (!defaultConfig || !configuration) {
    return "";
  }
  return configuration[defaultConfig.key] as string;
};

const EditFieldModal = ({ open, onClose, field }: Props) => {
  const [isTranslationLoading, setIsTranslationLoading] =
    useState<boolean>(false);
  const [showConfirmChangesModal, setShowConfirmChangesModal] = useState(false);
  const [configurationChangePreview, setConfigurationChangePreview] =
    useState("");
  const { locale } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { organizationId } = useTeam();
  const updateContractDefinition = useContractDefinitionUpdateMutation();
  const fieldName = field?.name[locale] ? field?.name[locale] : field?.name;

  const sourceLanguage = locale === "en" ? "en" : "de";
  const targetLanguage = locale === "en" ? "de" : "en";

  const validationSchema = () =>
    Yup.object().shape({
      name: Yup.object().shape({
        [sourceLanguage ?? targetLanguage]: Yup.string().required(),
      }),
      type: Yup.string().required(),
      listItemsAsString: Yup.string().when("type", {
        is: ContractFieldDTOV1.type.LIST,
        then: Yup.string().required(),
      }),
      configuration: Yup.string().when("type", {
        is: ContractFieldDTOV1.type.NUMBER,
        then: maxDecimalConfigurationValueValidator(5),
      }),
    });

  const { control, handleSubmit, getValues, reset, setValue, setError } =
    useForm<EditFieldForm>({
      resolver: yupResolver(validationSchema()),
    });

  const getConfigurationObject = () => {
    const defaultConfig = defaultConfigurations[field.type] || null;
    const configuration = getValues().configuration;
    if (defaultConfig && configuration) {
      return {
        [defaultConfig.key]: configuration,
      } as ContractFieldConfiguration;
    }
    return null;
  };

  const getTranslation = async () => {
    const data = getValues();

    if (!data.name || !data.name[sourceLanguage]) return;

    if (data.name[sourceLanguage] === "") {
      setValue(`name.${targetLanguage}`, "");
      return;
    }
    const requestBody = {
      text: data.name[locale],
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
    };

    try {
      setIsTranslationLoading(true);
      const translatedName = await CommonService.translateText(
        requestBody as TranslationDTO
      );
      setValue(`name.${targetLanguage}`, translatedName.text);
    } catch (error) {
      enqueueSnackbar(t("pages.categoryDetails.messages.translationFailed"), {
        variant: "error",
      });
    } finally {
      setIsTranslationLoading(false);
    }
  };

  const onSubmit = async () => {
    const list = getListItems(getValues().listItemsAsString);
    const configuration = getConfigurationObject();

    const requestBody: ContractFieldUpdateDTOV1 = {
      name: {
        en: getValues().name.en,
        de: getValues().name.de,
      },
      configuration: configuration as unknown as NumberFieldConfiguration,
    };

    if (list) {
      if (hasDuplicateListItems(list)) {
        setError("listItemsAsString", {
          type: "custom",
          message: t("common.errors.duplicatedListItems"),
        });
        return;
      }

      requestBody.data = {
        items: list.map((item) => ({
          id: item,
          value: item,
        })),
      };
    }

    try {
      await updateContractDefinition.mutateAsync({
        organizationId: organizationId,
        fieldId: field.id,
        field: requestBody,
      });

      enqueueSnackbar(
        t(
          "pages.settings.organization.administrator.fields.modal.edit.success"
        ),
        {
          variant: "success",
        }
      );
      handleOnClose();
    } catch (error) {
      if (
        error instanceof ApiError &&
        (error.body as BadRequestResponse).error === "duplicated_name"
      ) {
        setError("name.de", {
          type: "custom",
          message: "common.errors.duplicatedFieldName",
        });
        setError("name.en", {
          type: "custom",
          message: "common.errors.duplicatedFieldName",
        });
        return;
      }
      enqueueSnackbar(
        t(
          "pages.settings.organization.administrator.fields.modal.edit.failure"
        ),
        {
          variant: "error",
        }
      );
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    reset();
    onClose();
    handleConfirmChangesClose();
  };

  const getConfigurationChangePreview = (
    currentConfiguration: number | undefined
  ): string => {
    // Return a preview of decimal scale changes (e.g. 12.00000 -> 12.000)
    const newDecimalScale =
      (getValues().configuration as unknown as number) ||
      currentConfiguration ||
      2;
    if (isNaN(Number(newDecimalScale))) {
      return "";
    }
    const safeDecimalPlaces = Math.min(Math.max(newDecimalScale, 0), 5);
    const formatNumber = (decimalPlaces: number) => {
      const exampleNumber = 12;
      return exampleNumber.toLocaleString(locale, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      });
    };
    const newFormattedNumber = formatNumber(safeDecimalPlaces);
    return `(e.g. ${newFormattedNumber})`;
  };

  const confirmSaveChanges = async () => {
    // Show alert modal only if user changed the configuration.
    const currentConfiguration = field.configuration;
    const updatedConfiguration = getConfigurationObject();
    const isEqual = _.isEqual(currentConfiguration, updatedConfiguration);
    if (!isEqual) {
      setShowConfirmChangesModal(true);
    } else {
      await onSubmit();
    }
  };

  const handleConfirmChangesClose = () => {
    setShowConfirmChangesModal(false);
  };

  const configuration = useWatch({
    name: "configuration",
    control: control,
  });

  useEffect(() => {
    reset({
      name: { en: field.name.en, de: field.name.de },
      type: field.type,
      listItemsAsString: field?.data
        ? getListText(field?.data as ListFieldData)
        : undefined,
      configuration: getConfigurationValue(
        field.type,
        field.configuration as ContractFieldConfiguration
      ),
    });
  }, [field]);

  useEffect(() => {
    setValue("configuration", configuration as ContractFieldConfiguration);
    if (field.type === ContractFieldDto.type.NUMBER) {
      const preview = getConfigurationChangePreview(
        field.configuration?.decimalScale
      );
      setConfigurationChangePreview(preview);
    }
  }, [configuration, setValue]);

  return (
    <>
      <NewModal
        open={open}
        handleClose={handleOnClose}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
          },
        }}
        title={t(
          "pages.settings.organization.administrator.fields.modal.edit.title"
        )}
        body={
          <>
            <div>
              {t(
                "pages.settings.organization.administrator.fields.modal.edit.description"
              )}
            </div>
            <form
              name="contractCustomFieldForm"
              noValidate
              onSubmit={handleSubmit(confirmSaveChanges)}
              style={{
                marginTop: theme.spacing.lg,
              }}
            >
              <Grid container spacing={2} className="flex-align-center">
                <Grid item xs={12}>
                  <FormTextField
                    control={control}
                    name={`name.${sourceLanguage}`}
                    label={`${t(
                      "pages.settings.tabs.customFields.form.customFieldName"
                    )} (${t(`common.languages.${sourceLanguage}`)})`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    control={control}
                    name={`name.${targetLanguage}`}
                    label={`${t(
                      "pages.settings.tabs.customFields.form.customFieldName"
                    )} (${t(`common.languages.${targetLanguage}`)})`}
                  />
                  <ButtonWithIcon
                    onClick={getTranslation}
                    startIcon={<TranslationIcon />}
                    loading={isTranslationLoading}
                    loadingPosition="start"
                    variant="contained"
                  >
                    <span>
                      {t("pages.categoryDetails.buttons.translation")}
                    </span>
                  </ButtonWithIcon>
                </Grid>
                <Grid item xs={12}>
                  <LabelWrapper
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing.xs,
                    }}
                  >
                    <span>
                      {t("pages.contractEdit.modals.customFieldEdit.type")}
                    </span>
                    <span>{t(`customFields.typeOptions.${field.type}`)}</span>
                  </LabelWrapper>
                </Grid>
                {field.type === ContractFieldDto.type.LIST ? (
                  <>
                    <Grid item xs={6}>
                      <FormTextField
                        control={control}
                        name="listItemsAsString"
                        label={t(
                          "pages.contractEdit.modals.customField.customFieldValues"
                        )}
                        required
                        multiline
                        minRows={4}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <div>
                        {t(
                          "pages.contractEdit.modals.customField.customFieldValuesDescription"
                        )}
                      </div>
                    </Grid>
                  </>
                ) : field.type === ContractFieldDto.type.NUMBER ? (
                  <>
                    <ConfigurationEditAccordion
                      square
                      elevation={0}
                      style={{ border: "none" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-number-config-content"
                        id="panel-number-config-header"
                      >
                        <Typography>
                          {t(
                            "pages.contractEdit.modals.customField.NumberFieldConfiguration"
                          )}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <FormTextField
                              control={control}
                              name="configuration"
                              label={t(
                                "pages.contractEdit.modals.customField.NumberFieldConfiguration"
                              )}
                              required
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <div>
                              {t(
                                "pages.contractEdit.modals.customField.NumberFieldConfigurationDescription"
                              )}{" "}
                              {configurationChangePreview}
                            </div>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </ConfigurationEditAccordion>
                  </>
                ) : (
                  <></>
                )}
              </Grid>

              <ButtonsWrapper>
                <CTAButton
                  type="reset"
                  variant="secondary"
                  label="cancelEditCustomFieldButton"
                  onClick={() => handleOnClose()}
                  name={t("common.buttons.cancel")}
                />
                <CTAButton
                  variant="primary"
                  type="submit"
                  label="updateCustomFieldButton"
                  name={t("common.buttons.update")}
                />
              </ButtonsWrapper>
            </form>
          </>
        }
      ></NewModal>
      <ConfirmSaveChangesModal
        title={t(
          "pages.settings.organization.administrator.fields.modal.changeDecimalConfigAlert.title"
        )}
        description={t(
          "pages.settings.organization.administrator.fields.modal.changeDecimalConfigAlert.subtitle",
          { fieldName }
        )}
        showModal={showConfirmChangesModal}
        handleCloseModal={handleConfirmChangesClose}
        handleDiscardChanges={handleConfirmChangesClose}
        handleSaveChanges={onSubmit}
      />
    </>
  );
};

export default EditFieldModal;
