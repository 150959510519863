import styled from "@emotion/styled";
import { theme } from "theme";
import { css } from "@emotion/react";

export const DatapointCellContainer = styled.span`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
`;

export const DatapointFieldContainer = styled.div<{
  hoverable?: boolean;
  jumpable?: boolean;
}>`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.md};

  padding: ${theme.spacing.xs};

  ${(p) =>
    p.hoverable
      ? css`
          :hover {
            background-color: ${theme.color.purple[100]};
            .MuiPaper-root {
              background-color: ${theme.color.purple[100]};
            }
          }
        `
      : ""}

  ${(p) =>
    p.jumpable
      ? css`
          :hover {
            cursor: pointer;
          }
          :active {
            background-color: ${theme.color.purple[200]};
          }
        `
      : ""}
`;

export const DatapointFieldContent = styled.div<{ isLink?: boolean }>`
  flex: 1;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;

  ${({ isLink }) =>
    isLink &&
    `
    :hover {
      text-decoration: underline;
    }
  `}
`;

export const DatapointFieldHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DatapointFieldFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 25px;
`;

export const DatapointFieldActions = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${theme.spacing.xs};
  margin-top: ${theme.spacing.lg};
`;

export const InputAdornmentWrapper = styled.span`
  cursor: pointer;
  margin-right: ${theme.spacing.md};
`;
