import React from "react";
import { Wrapper } from "./styles";

type Props = {
  children: React.ReactNode | React.ReactNode[] | string;
  style?: React.CSSProperties;
};

const LabelWrapper = ({ style, children, ...props }: Props) => {
  return (
    <Wrapper {...props} style={style}>
      {children}
    </Wrapper>
  );
};

export default LabelWrapper;
