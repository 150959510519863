import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import { useStyles as useStyledButtons } from "components/StyledComponents/StyledBaseButtons";
import { ContractDTOV1 } from "openapi";

type Props = {
  contractData?: ContractDTOV1;
};

const EditContractButton = ({ contractData }: Props) => {
  const { t } = useTranslation();
  const buttonClasses = useStyledButtons();
  const navigate = useNavigate();

  const { mode } = useParams();

  const onClick = () => {
    if (contractData?.id) {
      navigate(mode ? "../edit" : "edit", { relative: "path" });
    }
  };

  return (
    <Button
      id="editContractBtn"
      onClick={onClick}
      className={buttonClasses.previewHeaderButton}
    >
      <EditIcon fontSize="small" sx={{ marginRight: ".4rem" }} />
      {t("pages.contractDetails.buttons.editContract")}
    </Button>
  );
};

export default EditContractButton;
