import React from "react";
import { ContractParties } from "openapi";
import styled from "@emotion/styled";

type Props = {
  party: ContractParties;
};

const Details = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const AnalyzedPartner = ({ party }: Props) => {
  return (
    <div>
      <h3>{party.company_name?.value}</h3>
      <Details>
        {(party.street || party.postal_code || party.city) && (
          <>
            <div>address</div>
            <div>
              {party.street?.value}
              <br />
              {party.postal_code?.value} {party.city?.value}
            </div>
          </>
        )}
        {party.phone && (
          <>
            <div>phone</div>
            <div>{party.phone?.value}</div>
          </>
        )}
        {party.email && (
          <>
            <div>email</div>
            <div>{party.email?.value}</div>
          </>
        )}
        {party.register_id && (
          <>
            <div>register_id</div>
            <div>{party.register_id?.value}</div>
          </>
        )}
      </Details>
      <hr />
    </div>
  );
};

export default AnalyzedPartner;
