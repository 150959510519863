export const getHostname = (): string | null => {
  const parts = window.location.hostname.split(".");
  // localhost / standard url (app.contracthero.com)
  if (parts.length === 1 || parts.length === 3) {
    return null;
  }
  return parts[0];
};

export const getOriginWithoutHostname = () => {
  const origin = window.location.origin;
  const hostname = getHostname();
  if (hostname) {
    return origin.replace(`${hostname}.`, "");
  }
  return origin;
};

export const useHostname = () => {
  return getHostname();
};
