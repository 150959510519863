import React from "react";
import { SxProps, IconButton, Tooltip, IconButtonProps } from "@mui/material";
import { ReactElement, FC } from "react";
import { theme } from "theme";
import { ToolbarButtonContainer } from "../styles";

type ToolbarButtonProps = {
  highlighted?: boolean;
  tooltip: string;
  children: ReactElement;
  sx?: SxProps;
  collapses?: boolean | "collapse" | "negative-collapse" | undefined;
  onClick?: IconButtonProps["onClick"];
};

export const ToolbarButton: FC<ToolbarButtonProps> = ({
  tooltip,
  children,
  sx,
  onClick,
  collapses,
  highlighted = false,
}) => {
  let className = "";
  if (collapses) {
    className =
      collapses === "negative-collapse"
        ? "toolbar-negative-collapsing-button"
        : "toolbar-collapsing-button";
  }
  return (
    <ToolbarButtonContainer className={className} sx={sx}>
      <Tooltip
        PopperProps={{
          container: document.fullscreenElement ?? document.body,
        }}
        title={tooltip}
      >
        <IconButton
          sx={{
            color: highlighted ? theme.color.blue[700] : theme.color.gray[700],
          }}
          onClick={onClick}
        >
          {children}
        </IconButton>
      </Tooltip>
    </ToolbarButtonContainer>
  );
};
