import {
  TeamPermissionInfoDto,
  TeamService,
  UserInfoDto,
  UserService,
} from "openapi";
import { SetSelectedTeamPayload, TeamState } from "./types";

const getPermissionRecord = (
  permissions?: TeamPermissionInfoDto[]
): Record<string, TeamPermissionInfoDto> | undefined => {
  if (!permissions) return;
  return permissions.reduce((record, element) => {
    record[element.teamId] = element;
    return record;
  }, {} as Record<string, TeamPermissionInfoDto>);
};

export const setSelectedTeam = (
  state: TeamState,
  payload: SetSelectedTeamPayload
): TeamState => {
  if (payload.error) {
    return {
      ...state,
      selectedTeam: undefined,
      permissions: undefined,
      error: payload.error.message,
      status: "error",
    };
  }

  if (payload.data?.teamInfo.id) {
    void UserService.setDefaultTeam(payload.data.teamInfo.id);
  }

  return {
    ...state,
    selectedTeam: payload.data?.teamInfo,
    status: "success",
    error: null,
    permissions: getPermissionRecord(payload.data?.teamInfo.permissions),
  };
};

export const getSelectedTeamPayload = async (
  teamId: string,
  userInfo?: UserInfoDto
) => {
  const actionPayload: SetSelectedTeamPayload = { error: null };
  try {
    const teamInfo = await TeamService.getTeamInfo(teamId);
    actionPayload.data = {
      userInfo: userInfo,
      teamInfo: teamInfo,
    };
  } catch (e) {
    if (e instanceof Error) {
      console.error("error setting selected team", e);
      actionPayload.error = e;
    } else {
      console.error("unknown error setting selected team", e);
      actionPayload.error = new Error("unknown error setting selected team");
    }
  }
  return actionPayload;
};

export const toggleDrawer = (state: TeamState, data: boolean): TeamState => {
  return { ...state, drawerOpenState: data };
};
