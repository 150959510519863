import React from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { DraggableFieldContainer } from "../styles";

type Props = {
  name: string;
};

const OverlayDraggableField = ({ name, ...props }: Props) => {
  return (
    <DraggableFieldContainer {...props} overlay>
      <span>{name}</span>
      <MoreHorizIcon fontSize="small" />
    </DraggableFieldContainer>
  );
};

export default OverlayDraggableField;
