import styled from "@emotion/styled";
import { theme } from "theme";

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const AccessTokenContainer = styled.div`
  margin: ${theme.spacing.lg} 0;
`;

export const AccessTokenBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing.md};
  background-color: ${theme.color.orange[100]};
  border-radius: ${theme.borderRadius};
  margin-bottom: ${theme.spacing.sm};
`;

export const InformationMessage = styled.div`
  font-size: ${theme.font.size.caption};
  color: ${theme.color.gray[600]};
`;

export const AccessToken = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
