import styled from "@emotion/styled";
import { Email } from "@mui/icons-material";
import { theme } from "theme";
import { fadeIn } from "theme/animations";

export const EmailIcon = styled(Email)`
  color: ${theme.color.orange[400]};
`;

export const Description = styled.div`
  font-size: ${theme.font.size.subheading};
`;

export const CopySection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  margin: ${theme.spacing.xl} 0;
  border-radius: ${theme.borderRadius};
  border: 1px solid ${theme.color.blue[200]};
  min-height: 60px;
  background-color: ${theme.color.blue[100]};

  a {
    animation: ${fadeIn} 0.15s ease-in;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  a:hover {
    text-decoration: underline !important;
  }

  span {
    display: flex;
    align-items: center;
    color: ${theme.color.gray[600]};
    font-size: ${theme.font.size.caption};
  }
`;

export const Note = styled.div`
  font-size: ${theme.font.size.caption};
  color: ${theme.color.gray[600]};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg};
`;
