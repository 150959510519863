import styled from "@emotion/styled";
import InfoIcon from "@mui/icons-material/Info";

type Props = {
  active?: boolean;
};

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

export const MainContainer = styled.div`
  background-color: #3182ce;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  h1 {
    background: linear-gradient(
      to right,
      #fff 20%,
      #bedbf6 40%,
      #bedbf6 60%,
      #fff 80%
    );
    background-size: 200% auto;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 7s linear infinite;
    font-weight: bold;
    font-size: 2.5rem;
    padding: 1rem 1rem 0 1rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #bedbf6;
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
`;

export const InfoButton = styled(InfoIcon)`
  visibility: hidden;

  @media (min-width: 768px) {
    visibility: visible;
    color: #bedbf6;
    cursor: pointer;
    margin-left: 0.5rem;

    :hover {
      color: #fff;
    }
  }
`;

export const SvgContainer = styled.div`
  width: 20%;
  fill: #bedbf6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .machine {
    width: 40%;
  }

  .small-shadow,
  .medium-shadow,
  .large-shadow {
    fill: rgba(0, 0, 0, 0.05);
  }

  .small {
    animation: counter-rotation 10s infinite linear;
    transform-origin: 100.136px 225.345px;
  }

  .small-shadow {
    animation: counter-rotation 10s infinite linear;
    transform-origin: 110.136px 235.345px;
  }

  .medium {
    animation: rotation 15s infinite linear;
    transform-origin: 254.675px 379.447px;
  }

  .medium-shadow {
    animation: rotation 15s infinite linear;
    transform-origin: 264.675px 389.447px;
  }

  .large {
    animation: counter-rotation 20s infinite linear;
    transform-origin: 461.37px 173.694px;
  }

  .large-shadow {
    animation: counter-rotation 20s infinite linear;
    transform-origin: 471.37px 183.694px;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @keyframes counter-rotation {
    from {
      transform: rotate(359deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  .server {
    max-width: 100px;
  }
`;

export const ContactContainer = styled.div`
  display: none;
  flex-direction: column;
  transition: width 0.3s, opacity 0.1s ease-in-out;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    display: flex;

    ${({ active }: Props) =>
      active
        ? `
        width: 40%;
        opacity: 1;
      `
        : `
        width: 0;
        opacity: 0;
      `};
  }

  @media (min-width: 960px) {
    ${({ active }: Props) => active && ` width: 30%;`};
  }
`;

export const Logo = styled.img`
  padding: 1rem;
  margin: 4rem 0;
`;

export const Details = styled.div`
  color: #718096;

  a {
    color: #718096;
    margin: 0.5rem 0;
    line-break: auto;
  }
`;

export const DetailsItem = styled.div`
  padding: 0.2rem 0;
`;
