import styled from "@emotion/styled";
import { theme } from "theme";
import { FormSelect as FormSelectComponent } from "components/FormItems/FormSelect/FormSelect";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: ${theme.spacing.md};
`;

export const Container = styled.div`
  height: 100%;
  overflow: auto;
  padding: 0;
`;

export const CategoryNameWrapper = styled.div<{ maxWidth: number }>`
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: ${({ maxWidth }) => maxWidth}rem;
`;

export const CategoryItem = styled.div`
  display: flex;
  gap: ${theme.spacing.md};
  align-items: center;
  justify-content: space-between;
`;

export const CategoryTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  flex: 1;
`;

export const FormSelect = styled(FormSelectComponent)`
  flex: 1;
`;
