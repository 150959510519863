import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  border-bottom: 1px solid ${theme.color.gray[300]};
  padding-bottom: ${theme.spacing.md};
`;

export const Header = styled.header`
  margin-bottom: ${theme.spacing.md};
`;

export const Title = styled.h4`
  margin-bottom: ${theme.spacing.xs};
  margin-top: 0;
  font-weight: ${theme.font.weight.normal};
  font-size: ${theme.font.size.subheading};
`;

export const Date = styled.div`
  color: ${theme.color.gray[600]};
  font-size: ${theme.font.size.caption};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
`;
