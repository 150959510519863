import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export const TagBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0.1rem 0.5rem;
  margin: 0.2rem;
  color: #3182ce;
  background-color: rgba(25, 118, 210, 0.08);
  border-radius: 0.4rem;
  cursor: pointer;
`;

export const AddIconWrapper = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  margin: 0.2rem;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  transition: transform 0.2s ease-in-out;

  :hover {
    background-color: rgba(25, 118, 210, 0.08);
  }

  svg {
    fill: #3182ce;
    width: 20px;
    height: 20px;
  }

  transform: ${({ status }: { status: string }) =>
    status === "open" && "rotate(45deg)"};
`;

export const CloseIcon = styled(Close)`
  font-size: 16px;
  margin-left: 0.5rem;
  transition: transform 0.2s ease-in-out;

  :focus,
  :active {
    transform: scale(0.7);
  }
`;
