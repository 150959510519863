import React from "react";
import { useTranslation } from "react-i18next";
import { Title, Description } from "./styles";
import CopyField from "components/CopyField/CopyField";

export type DelegationModalContentType = {
  delegationId: string;
};

const DelegationModalContent = ({
  delegationId,
}: DelegationModalContentType) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>
        {t(
          "pages.settings.tabs.team-security.delegationSettings.modal.contentTitle"
        )}
      </Title>
      <Description>
        {t(
          "pages.settings.tabs.team-security.delegationSettings.modal.contentDescription"
        )}
      </Description>
      <CopyField value={delegationId} />
    </>
  );
};

export default DelegationModalContent;
