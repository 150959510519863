import React from "react";
import { Outlet } from "react-router-dom";
import { useUserInfo } from "hooks";
import { Container, Loader } from "components";
import { Sidebar } from "../components";
import { Wrapper } from "./styles";

const CustomLayout = () => {
  const { status } = useUserInfo();

  if (status === "idle") return <Loader />;

  return (
    <Wrapper>
      <Sidebar />
      <Container fullWidth>
        <Outlet />
      </Container>
    </Wrapper>
  );
};

export default CustomLayout;
