import styled from "@emotion/styled";
import { theme } from "theme";

export const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.sm} 0;
  border-bottom: 1px solid #ccc;

  :nth-last-of-type(1),
  :nth-last-of-type(2),
  :nth-last-of-type(3) {
    border: none;
  }
`;

export const ToggleAllWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  margin-bottom: ${theme.spacing.xl};
  font-weight: ${theme.font.weight.medium};

  @media screen and (min-width: 600px) {
    grid-template-columns: 8fr 1fr 1fr;
  }
`;
