import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const BaseOverflowTipTextStyles = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BaseOverflowTipTextContainer = styled.span(
  BaseOverflowTipTextStyles
);
