/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DirectDebitPaymentMethod = {
  type: DirectDebitPaymentMethod.type;
  mandateId: string;
};

export namespace DirectDebitPaymentMethod {
  export enum type {
    DIRECT_DEBIT = "direct_debit",
  }
}
