import React, { FC, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { FormNumericField } from "../FormNumeric/FormNumericField";
import { CurrencySelect } from "../CurrencySelect/CurrencySelect";
import { useFormContext } from "react-hook-form";
import { AmountFieldTypeDtoV1, ContractFieldLimitedDTOV1 } from "openapi";
import { useTranslation } from "react-i18next";
import { AnalysisHookData } from "components/Datapoints/hooks/useAnalysis";
import { AnalysisWrapper } from "components/Datapoints/components/AnalysisWrapper";
import { Suggestions } from "components/Datapoints/hooks/types";
import { DatapointFieldInputAdornment } from "components/Datapoints/components/DatapointFieldInputAdornment";

type Props = {
  label: string;
  field: ContractFieldLimitedDTOV1;
  analysis?: AnalysisHookData<AmountFieldTypeDtoV1>;
  suggestions?: Record<string, Suggestions> | null;
};

export type AmountFormType = {
  fields: Record<string, AmountFieldTypeDtoV1>;
};

export const FormAmountField: FC<Props> = ({
  label,
  field,
  analysis,
  suggestions,
}) => {
  const { control, getValues, setValue } = useFormContext<AmountFormType>();
  const { t } = useTranslation();
  useEffect(() => {
    const currency = getValues(`fields.${field.id}.currency`);
    if (!currency) {
      setValue(`fields.${field.id}.currency`, "EUR");
    }
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} md={6}>
        <AnalysisWrapper
          analysis={analysis}
          fieldKey="value"
          renderField={(data, selector) => {
            return (
              <FormNumericField
                control={control}
                name={`fields.${field.id}.value`}
                label={label}
                InputProps={{
                  startAdornment: selector ?? (
                    <DatapointFieldInputAdornment
                      definition={field}
                      fieldKey="value"
                      suggestions={suggestions}
                      setValue={setValue}
                      data={getValues().fields[field.id]}
                    />
                  ),
                }}
                inputProps={{
                  suggestion: data?.selectedSuggestion.value != null,
                }}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <AnalysisWrapper
          analysis={analysis}
          fieldKey="currency"
          renderField={(data, selector) => {
            return (
              <CurrencySelect
                control={control}
                name={`fields.${field.id}.currency`}
                label={t("pages.contractEdit.forms.financialForm.currency")}
                value="EUR"
                InputProps={{
                  startAdornment: selector ?? (
                    <DatapointFieldInputAdornment
                      definition={field}
                      fieldKey="currency"
                      suggestions={suggestions}
                      setValue={setValue}
                      data={getValues().fields[field.id]}
                    />
                  ),
                }}
                inputProps={{
                  suggestion: data?.selectedSuggestion.value != null,
                }}
              />
            );
          }}
        ></AnalysisWrapper>
      </Grid>
    </Grid>
  );
};
