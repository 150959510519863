/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect, useCallback } from "react";
import { ContractDTOV1, ContractFieldDTOV1 } from "openapi";
import { FieldKey, Suggestions } from "./types";
import { AnalysisFieldData } from "../types";
import {
  getDefaultAnalysisParser,
  getFieldKeys,
  getHumanReadableAnalysisParser,
} from "./utils";
import { useTeam } from "contexts/team/hooks";
import { useVerifyDatapointMutation } from "shared/api/analysis";
import { useLocale, useUserInfo } from "hooks";
import { useUpdateContractMutation } from "shared/api/contracts";
import { useSnackbar } from "notistack";
import * as Sentry from "@sentry/react";
import { Features } from "constants/features";

const useDataPointAnalysis = <T extends ContractDTOV1["fields"][string]>(
  definition: ContractFieldDTOV1,
  contract: Pick<ContractDTOV1, "id" | "teamId">,
  data: T | undefined,
  customParsers?: Partial<
    Record<
      FieldKey<T>,
      {
        parser: (value: string) => string | undefined;
        displayParser: (value: string) => string | undefined;
      }
    >
  >
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { organizationId, hasFeature } = useTeam();
  const { locale } = useLocale();
  const { userInfo } = useUserInfo();
  const verifyDatapointMutation = useVerifyDatapointMutation();
  const updateContractMutation = useUpdateContractMutation();

  const [suggestions, setSuggestions] = useState<{
    [key: string]: Suggestions;
  } | null>(null);

  const fieldKeys = getFieldKeys(data);

  const extractData = useCallback(() => {
    if (!hasFeature(Features.CONTRACT_ANALYSIS)) {
      setSuggestions(null);
      return;
    }

    if (data && fieldKeys.length > 0) {
      const analysisData = data?.analysisData;

      if (analysisData) {
        const newSuggestions: { [key: string]: Suggestions } = {};

        fieldKeys.forEach((fieldKey) => {
          const fieldData: AnalysisFieldData = analysisData[fieldKey as string];

          const parser =
            customParsers?.[fieldKey]?.parser ??
            getDefaultAnalysisParser<T>(definition.type, fieldKey);
          const displayParser =
            customParsers?.[fieldKey]?.displayParser ??
            getHumanReadableAnalysisParser<T>(
              definition.type,
              fieldKey,
              locale,
              userInfo?.dateFormat
            );

          if (fieldData && fieldData.items) {
            newSuggestions[fieldKey as string] =
              fieldData.items.reduce<Suggestions>((acc, item) => {
                try {
                  const parsedDisplayValue = displayParser(item.value);
                  const parsedValue = parser(item.value);

                  if (parsedDisplayValue && parsedValue) {
                    acc.push({
                      displayValue: parsedDisplayValue,
                      value: parsedValue,
                      confidence: item.confidence,

                      beginOffset: item.beginOffset,
                      endOffset: item.endOffset,
                    });
                  }
                } catch (e) {
                  Sentry.captureException(e, (scope) => {
                    scope.setTransactionName(
                      `Couldn't parse analysis value for: ${contract.id}`
                    );
                    return scope;
                  });
                }

                return acc;
              }, []);
          }
        });

        setSuggestions(newSuggestions);
      }
    }
  }, [data, fieldKeys]);

  useEffect(() => extractData(), []);

  const verify = async (fields: typeof fieldKeys) => {
    try {
      await verifyDatapointMutation.mutateAsync({
        organizationId: organizationId,
        teamId: contract.teamId,
        contractId: contract.id,
        datapointId: definition.id,
        requestBody: {
          fields: fields as string[],
        },
      });
    } catch (e) {
      enqueueSnackbar({
        variant: "error",
        message: JSON.stringify(e),
      });
    }
  };

  const selectInsight = async (field: FieldKey<T>, value: string) => {
    if (!data) {
      return;
    }
    try {
      await updateContractMutation.mutateAsync({
        teamId: contract.teamId,
        contractId: contract.id,
        requestBody: {
          fields: {
            [definition.id]: {
              ...data,
              [field]: value,
            },
          },
        },
      });
    } catch (e) {
      enqueueSnackbar({
        variant: "error",
        message: JSON.stringify(e),
      });
    }
  };

  const callback = (fun: () => void | Promise<void>) => void fun();

  return {
    suggestions,
    callback,
    verify,
    selectInsight,
  };
};

export default useDataPointAnalysis;
