import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Box, Divider, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "components/Modal/Modal";
import { ContractDTOV1 } from "openapi";
import { useTeam } from "contexts/team/hooks";
import { Chip } from "components";
import theme from "theme/theme";
import ContractSwapModalContent from "../../Modals/SwapContract";

const MainTitleWrapper = styled.h1`
  margin: 0;
  font-size: ${theme.font.size.h6};
  font-weight: ${theme.font.weight.semiBold};
  line-height: 2.25rem;
  word-break: break-word;
  display: flex;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing.sm};
`;

const SubTitleWrapper = styled.p`
  margin-bottom: ${theme.spacing.xl};
  margin-top: 0;
`;

type Prop = {
  titleKey: string;
  mainTitle?: string;
  isMainContract?: boolean;
  mainContractCategoryId: string | undefined;
  titleInformation?: string;
  subTitleKey?: string;
  baseButtonTextKey?: string;
  icon?: JSX.Element;
  contractData?: ContractDTOV1;
  contractId: string | undefined;
  refetchData?: () => Promise<void>;
};

const HeaderWrapper = ({
  titleKey,
  mainTitle,
  isMainContract = false,
  mainContractCategoryId,
  titleInformation,
  subTitleKey,
  baseButtonTextKey,
  icon,
  contractData,
  contractId,
  refetchData,
}: Prop) => {
  const { t } = useTranslation();
  const { hasWriteAccess } = useTeam();
  const [showSwapContractModal, setShowSwapContractModal] = useState(false);

  const handleContractModalToSwap = () => {
    setShowSwapContractModal(true);
  };

  const handleContractModalToSwapClose = () => {
    setShowSwapContractModal(false);
  };

  const chipStyles = {
    whiteSpace: "nowrap",
    height: "fit-content",
  } as React.CSSProperties;

  return (
    <>
      {mainTitle && (
        <>
          <Box className="flex" sx={{ justifyContent: "space-between" }}>
            <Header>
              <MainTitleWrapper data-cy="contractTitle">
                <span>{mainTitle}</span>
              </MainTitleWrapper>

              <Chip
                color={
                  isMainContract
                    ? theme.color.blue[600]
                    : theme.color.green[600]
                }
                style={chipStyles}
              >
                {isMainContract
                  ? t("contractType.MAIN_CONTRACT")
                  : t("contractType.ATTACHMENT")}
              </Chip>
            </Header>

            <Box className="flex" sx={{ alignItems: "flex-start" }}>
              {hasWriteAccess(mainContractCategoryId) &&
                hasWriteAccess(contractData?.categoryId) &&
                !isMainContract && (
                  <IconButton>
                    <EditIcon
                      fontSize="small"
                      sx={{
                        color: theme.color.black,
                        "&:hover": {
                          color: theme.color.blue[600],
                          cursor: "pointer",
                        },
                      }}
                      onClick={handleContractModalToSwap}
                    />
                  </IconButton>
                )}
            </Box>
          </Box>

          <Divider
            sx={{
              backgroundColor: theme.color.gray[100],
              margin: `${theme.spacing.sm} 0`,
            }}
          />
        </>
      )}
      {subTitleKey && <SubTitleWrapper>{t(subTitleKey)}</SubTitleWrapper>}
      <Modal
        open={showSwapContractModal}
        isShowHeader={false}
        isShowActionButtons={false}
        fullWidth
      >
        <ContractSwapModalContent
          handleClose={handleContractModalToSwapClose}
          selectedContractId={contractId}
          refetchData={refetchData}
        />
      </Modal>
    </>
  );
};

export default HeaderWrapper;
