/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OffsetConfig } from "./OffsetConfig";

export type TaskAutomationDueDateConfigDTO = {
  unit?: TaskAutomationDueDateConfigDTO.unit | null;
  enabled: boolean;
  offset?: number | null;
  relativeFieldId?: string | null;
  relativeFieldKey?: string | null;
  repeatConfig?: OffsetConfig | null;
};

export namespace TaskAutomationDueDateConfigDTO {
  export enum unit {
    DAY = "DAY",
    WEEK = "WEEK",
    MONTH = "MONTH",
    YEAR = "YEAR",
  }
}
