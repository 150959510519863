import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { transformChartData } from "./helper";
import { CategoryDTO } from "openapi";
import { useLocale } from "hooks";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.defaults.font.family = "HankenGrotesk, sans-serif, system-ui";

type Props = {
  data: { category: CategoryDTO; count: number }[];
};

const options = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
  },
  animation: {
    duration: 0,
  },
};

const DoughnutChart = ({ data }: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { locale } = useLocale();

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  const chartData = transformChartData(data, locale);
  return (
    <Doughnut key={windowWidth} redraw data={chartData} options={options} />
  );
};

export default DoughnutChart;
