import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Tooltip } from "@mui/material";
import { Button } from "./styles";

type Props = {
  text: string;
  isActive: boolean;
  onClick: () => void;
};

const MinimizeIcon = ({ isActive, onClick, text, ...props }: Props) => (
  <Button {...props} isActive={isActive} onClick={onClick}>
    <Tooltip title={text}>
      <KeyboardArrowUpIcon />
    </Tooltip>
  </Button>
);

export default MinimizeIcon;
