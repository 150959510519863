import styled from "@emotion/styled";
import { theme } from "theme";
import { DialogActions } from "@mui/material";

export const FlexBox = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${theme.spacing.lg};
`;

export const Description = styled.p`
  margin-top: ${theme.spacing.md};
`;

export const ButtonsWrapper = styled(DialogActions)`
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: flex-end;
  gap: ${theme.spacing.md};
  margin-top: ${theme.spacing.xl};
`;
