import routePaths from "../../constants/routePaths";

export interface CardLink {
  route: string;
  labelKey: string; //translation key
}

export class CardLinks {
  static forgotPassword: CardLink = {
    route: routePaths.FORGOT_PASSWORD,
    labelKey: "common.authLinks.forgotPassword",
  };
  static login: CardLink = {
    route: routePaths.LOGIN,
    labelKey: "common.authLinks.login",
  };
  static register: CardLink = {
    route: routePaths.REGISTER,
    labelKey: "common.authLinks.register",
  };
}
