import styled from "@emotion/styled";
import { theme } from "theme";
import CheckIcon from "assets/svg/check-icon.svg";
import { css } from "@emotion/react";

type OuterLabelProps = {
  stretch: boolean;
  disabled?: boolean;
  labelPosition?: "left" | "right";
};

export const OuterLabel = styled.label<OuterLabelProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: ${theme.spacing.lg};
  flex-direction: ${(p) =>
    p.labelPosition === "right" ? "row" : "row-reverse"};

  justify-content: ${(p) => (p.stretch ? "space-between" : "flex-start")};

  ${(p) =>
    p.stretch &&
    css`
      width: 100%;
    `}

  ${(p) =>
    p.disabled
      ? css`
          opacity: 0.7;
          &:hover {
            cursor: not-allowed;
          }
        `
      : css`
          &:hover {
            cursor: pointer;
          }
        `}
`;

type AdditionalInputProps = {
  variant?: "primary" | "without-icon";
};

export const Input = styled.input<AdditionalInputProps>`
  cursor: inherit;
  position: absolute;
  appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 16px;
  min-width: 36px;
  min-height: 24px;
  max-height: 24px;
  z-index: 1;

  &:focus-visible {
    outline: 1px solid #ff8e00;
    outline-offset: 1px;
  }

  &:hover + span {
    background-color: ${theme.color.blue[500]} !important;
  }

  &:checked + span {
    background-color: ${theme.color.blue[600]};

    & > span {
      display: flex;
      justify-content: center;
      align-items: center;
      left: calc(100% - 20px - 2px);

      ${(p) =>
        p.variant === "primary" &&
        css`
          &::before {
            display: inline-block;
            width: 8px;
            height: 8px;
            content: "";
            mask: url("${CheckIcon}") no-repeat 50% 50%;
            -webkit-mask: url("${CheckIcon}") no-repeat 50% 50%;
            mask-size: cover;
            -webkit-mask-size: cover;
            background-color: ${theme.color.blue[600]};
          }
        `}
    }
  }
`;

export const Rail = styled.span`
  position: relative;
  min-width: 36px;
  min-height: 24px;
  max-height: 24px;
  border-radius: 16px;

  background-color: ${theme.color.gray[600]};
`;

export const Label = styled.span`
  font-size: 14px;
`;

export const Knob = styled.span`
  display: inline-block;
  position: relative;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${theme.color.white};
  transition: left 75ms linear;
`;
