import styled from "@emotion/styled";
import { theme } from "theme";

export const Field = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  background: ${theme.color.gray[200]};
  padding: ${theme.spacing.md};
  border-radius: ${theme.borderRadius};
  height: ${theme.spacing.xxxl};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

export const Link = styled.a`
  &:hover {
    text-decoration: underline !important;
  }
`;
