import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import MainStep from "./MainStep";
import ImportSteps from "./ImportSteps/ImportSteps";
import { useGuideModal } from "hooks/GlobalStateHooks";
import { Footer, SkipButton } from "./styles";
import { ImportDataDTO } from "openapi";

type FooterProps = {
  handleClose: () => void;
  backButton?: JSX.Element;
};

export const FooterComponent = ({ backButton, handleClose }: FooterProps) => {
  const { t } = useTranslation();

  return (
    <Footer>
      <SkipButton type="submit" onClick={() => handleClose()}>
        {t("common.buttons.skip")}
      </SkipButton>
      {backButton}
    </Footer>
  );
};

type Props = {
  open: boolean;
  handleClose: () => void;
  fetchData: () => Promise<void>;
  handleOnCSVData: (status: ImportDataDTO) => void;
};

const GuideModal = ({
  open = true,
  handleClose,
  fetchData,
  handleOnCSVData,
}: Props) => {
  const [steps, setSteps] = useState(["main"]);
  const { setShowGuideModal } = useGuideModal();

  const handleSkip = () => {
    setShowGuideModal(false);
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onBackdropClick={handleSkip}
      scroll="paper"
      maxWidth="xl"
    >
      {steps.length === 1 ? (
        <MainStep
          handleClose={handleSkip}
          open={open}
          steps={steps}
          setSteps={setSteps}
          fetchData={fetchData}
        />
      ) : (
        <ImportSteps
          handleClose={handleSkip}
          steps={steps}
          setSteps={setSteps}
          fetchData={fetchData}
          handleOnCSVData={handleOnCSVData}
        />
      )}
    </Dialog>
  );
};

export default GuideModal;
