import React, { useState } from "react";
import { CategoryItem } from "./styles";

import { CategoryDTO, TeamDTO } from "openapi";
import { useLocale } from "hooks";
import {
  useAssignTeamToCategoryMutation,
  useRemoveTeamFromCategoryMutation,
} from "shared/api/organization/categories";
import { useTranslation } from "react-i18next";
import { Switch } from "new-components";

type AccessSwitcherProps = {
  organizationId: string;
  team: Pick<TeamDTO, "id" | "name">;
  category: Pick<CategoryDTO, "id" | "name">;
  onAccessGranted?: (category: Pick<CategoryDTO, "id" | "name">) => void;
  onAccessRevoked?: (category: Pick<CategoryDTO, "id" | "name">) => void;
};

const AccessSwitcher = ({
  organizationId,
  team,
  category,
  onAccessGranted,
  onAccessRevoked,
}: AccessSwitcherProps) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const [isAccessGranted, setIsAccessGranted] = useState<boolean>(false);

  const assignTeamToCategory = useAssignTeamToCategoryMutation(
    organizationId,
    () => {
      onAccessGranted?.(category);
    }
  );
  const removeTeamFromCategory = useRemoveTeamFromCategoryMutation(
    organizationId,
    () => {
      onAccessRevoked?.(category);
    }
  );

  const updateAccessPermissions = async () => {
    if (!isAccessGranted) {
      await assignTeamToCategory.mutateAsync({
        teamId: team.id,
        dto: {
          categoryId: category.id,
        },
      });
    } else {
      await removeTeamFromCategory.mutateAsync({
        teamId: team.id,
        categoryId: category.id,
      });
    }
    setIsAccessGranted(!isAccessGranted);
  };

  const categoryName = category.name[locale];

  return (
    <CategoryItem>
      <Switch
        label={t("pages.contracts.modals.moveContracts.grantAccessToCategory", {
          categoryName,
        })}
        onChange={updateAccessPermissions}
      />
    </CategoryItem>
  );
};

export default AccessSwitcher;
