export type ColorSelectorType = {
  hexaCode: string;
  backgroundHexaCode: string;
  textHexaCode: string;
};

export const categoryColors: ColorSelectorType[] = [
  {
    hexaCode: "#e2e8f0",
    backgroundHexaCode: "#f7fafc",
    textHexaCode: "#2d3748",
  },
  {
    hexaCode: "#faf089",
    backgroundHexaCode: "#fffff0",
    textHexaCode: "#975a16",
  },
  {
    hexaCode: "#ddff99",
    backgroundHexaCode: "#f9ffed",
    textHexaCode: "#3d4d1f",
  },
  {
    hexaCode: "#a6f296",
    backgroundHexaCode: "#edfaeb",
    textHexaCode: "#163310",
  },
  {
    hexaCode: "#9ae6b4",
    backgroundHexaCode: "#f0fff4",
    textHexaCode: "#276749",
  },
  {
    hexaCode: "#7edce2",
    backgroundHexaCode: "#d5f5f6",
    textHexaCode: "#05505c",
  },
  {
    hexaCode: "#90cdf4",
    backgroundHexaCode: "#e9f3ff",
    textHexaCode: "#2c5282",
  },
  {
    hexaCode: "#a3bffa",
    backgroundHexaCode: "#ebf4ff",
    textHexaCode: "#434190",
  },
  {
    hexaCode: "#d6bcfa",
    backgroundHexaCode: "#faf5ff",
    textHexaCode: "#553c9a",
  },
  {
    hexaCode: "#e595e5",
    backgroundHexaCode: "#fff0ff",
    textHexaCode: "#853585",
  },
  {
    hexaCode: "#fbb6ce",
    backgroundHexaCode: "#fff5f7",
    textHexaCode: "#97266d",
  },
  {
    hexaCode: "#feb2b2",
    backgroundHexaCode: "#fff5f5",
    textHexaCode: "#9b2c2c",
  },
  {
    hexaCode: "#ff9999",
    backgroundHexaCode: "#fff5f5",
    textHexaCode: "#632828",
  },
  {
    hexaCode: "#fbc49d",
    backgroundHexaCode: "#fff6f0",
    textHexaCode: "#472913",
  },
];
