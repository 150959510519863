import React, { FC } from "react";
import { CategoryDTO } from "openapi";
import { ColoredPoint } from "components";
import { useLocale } from "hooks";

type CategoryLabelProps = {
  category: CategoryDTO;
};

export const CategoryLabel: FC<CategoryLabelProps> = ({ category }) => {
  const { locale } = useLocale();
  return (
    <>
      <ColoredPoint color={category.color} />
      <span>{category.name[locale]}</span>
    </>
  );
};
