import { OrganizationNewService, OrganizationService } from "openapi";
import { useQuery } from "@tanstack/react-query";

export const useOrganizationTeamsQuery = (id: string) => {
  return useQuery({
    queryKey: ["organizationTeams", id],
    queryFn: async () => {
      return await OrganizationService.getOrganizationById(id);
    },
  });
};

export const useOrganizationCurrentTeamQuery = (
  organizationId: string,
  currentTeamId: string
) => {
  return useQuery({
    queryKey: ["organizationCurrentTeam", organizationId, currentTeamId],
    queryFn: async () => {
      const currentTeam = await OrganizationNewService.getTeam(
        organizationId,
        currentTeamId
      );
      return currentTeam;
    },
    enabled: !!organizationId && !!currentTeamId,
  });
};

export const useOrganizationAllTeamsQuery = (organizationId: string) => {
  return useQuery({
    queryKey: ["organizationAllTeams", organizationId],
    queryFn: async () => {
      const teams = await OrganizationNewService.getTeams(organizationId);
      return teams;
    },
    enabled: !!organizationId,
  });
};

export const useOrganizationPermissionChangeLog = (organizationId: string) => {
  return useQuery({
    queryKey: ["permissionHistory", organizationId],
    queryFn: async () => {
      const permissionChangeLog =
        await OrganizationNewService.getOrganizationPermissionHistory(
          organizationId
        );
      return permissionChangeLog;
    },
    enabled: !!organizationId,
  });
};
