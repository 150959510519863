import {
  TextDatapointDTO,
  ContactDatapointDefinitionDTO,
  ContactTypeDTO,
} from "openapi";
import { ContactDataDTO } from "../Contacts/ContactDataDTO";

export const formatContactName = (contact?: ContactDataDTO) => {
  if (!contact) {
    return "";
  }
  return (contact["name"] as TextDatapointDTO)?.value?.value ?? "";
};

export const initFormFields = (
  contactDataPointDefinitions: ContactDatapointDefinitionDTO[],
  contactType: ContactTypeDTO
) => {
  const newObj = {};

  contactType?.sections?.forEach((section) => {
    section.definitions.forEach((definition) => {
      const fieldName = contactDataPointDefinitions.find(
        (d) => d.id === definition.id
      )?.visibleId;

      Object.assign(newObj, {
        [fieldName as string]: {
          value: {
            value: "",
          },
        },
      });
    });
  });

  return newObj;
};
