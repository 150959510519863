import styled from "@emotion/styled";
import { DialogActions } from "@mui/material";
import { theme } from "theme";

export const ButtonsWrapper = styled(DialogActions)`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.md};
`;
export const CategoryPermissionsHeader = styled.div`
  border-bottom: 1px solid ${theme.color.gray[400]};
`;
