import dayjs, { Dayjs, ManipulateType } from "dayjs";
import {
  ContractDTOV1,
  ContractFieldDTOV1,
  DateFieldTypeDtoV1,
  DurationFieldTypeDtoV1,
  OffsetConfig,
  TaskAutomationDueDateConfigDTO,
  TaskNewDTO,
  TeamMemberDto,
} from "openapi";
import { Language } from "shared/i18n/i18n";
import { TFunction } from "i18next";
import dueDateType = TaskNewDTO.dueDateType;

export type ExtendedTaskData = TaskNewDTO & {
  contract: string;
  contractId: string;
  assignees?: TeamMemberDto[];
  reminderAt?: string | Dayjs;
  dueDate: string;
};

export type DueDateConfigType = {
  enabled: boolean;
  offset: number;
  unit: TaskAutomationDueDateConfigDTO.unit;
  dueDate: string;
  relativeFieldId: string;
  relativeFieldKey: string;
};

export const getNewAssigneesNames = (list: TeamMemberDto[]) => {
  return list
    ?.map(
      ({ firstname, lastname }: TeamMemberDto) =>
        `${firstname ?? ""} ${lastname ?? ""}`
    )
    .join(", ");
};

export const transformRowData = (
  data: TaskNewDTO[],
  contracts: ContractDTOV1[],
  teamMembers: TeamMemberDto[],
  fields: ContractFieldDTOV1[],
  locale: Language,
  t: TFunction<"translation">,
  contractId?: string
) => {
  if (!data || !contracts) return [];
  let updatedData;

  if (contractId) {
    updatedData = data.reduce((acc: ExtendedTaskData[], item: TaskNewDTO) => {
      let dueDate = "";
      const contract = contracts.filter(
        (contractItem) => item.contractId === contractItem.id
      )[0];
      if (item.dueDateType === TaskNewDTO.dueDateType.FIXED) {
        dueDate = item?.dueDateConfig?.dueDate as string;
      } else if (item.dueDateType === TaskNewDTO.dueDateType.RELATIVE) {
        const dueDateConfig = item?.dueDateConfig as DueDateConfigType;
        const relativeFieldKey = dueDateConfig?.relativeFieldKey;
        const fieldData = contract?.fields[dueDateConfig?.relativeFieldId] as
          | DateFieldTypeDtoV1
          | DurationFieldTypeDtoV1
          | undefined;

        const baseDateFieldValue =
          fieldData?.[relativeFieldKey as keyof typeof fieldData] ?? "";
        if (baseDateFieldValue) {
          dueDate = item.dueDateConfig?.enabled
            ? dayjs(baseDateFieldValue as unknown as string)
                .subtract(
                  Number(item?.dueDateConfig?.offset),
                  item?.dueDateConfig?.unit as unknown as ManipulateType
                )
                .toISOString()
            : "";
        } else {
          dueDate = getRelativeFieldName(
            dueDateConfig.relativeFieldId,
            dueDateConfig.relativeFieldKey,
            fields,
            locale,
            t
          );
        }
      }
      const updatedItem = {
        ...item,
        contract: contract ? contract.name : "",
        contractId: item.contractId,
        dueDate,
      };

      return [...acc, updatedItem];
    }, []);
  } else {
    const filteredData = data.filter(
      (task) => !contractId || task.contractId === contractId
    );

    updatedData = filteredData.reduce(
      (acc: ExtendedTaskData[], item: TaskNewDTO) => {
        let dueDate = "";
        let reminderDate = "";
        const contract = contracts.find(
          (contractItem) => item.contractId === contractItem.id
        );

        const assignees = teamMembers?.filter((assignee) =>
          item.assigneeIds?.includes(assignee.id)
        );

        if (item.dueDateType === TaskNewDTO.dueDateType.FIXED) {
          dueDate = item?.dueDateConfig?.dueDate as string;
          reminderDate = item.reminderConfig?.enabled
            ? dayjs(dueDate)
                .subtract(
                  Number(item?.reminderConfig?.offset),
                  item?.reminderConfig?.unit as unknown as ManipulateType
                )
                .toISOString()
            : "";
        } else if (item.dueDateType === TaskNewDTO.dueDateType.RELATIVE) {
          const dueDateConfig = item?.dueDateConfig as DueDateConfigType;
          const relativeFieldKey = dueDateConfig?.relativeFieldKey;
          const fieldData = contract?.fields[dueDateConfig?.relativeFieldId] as
            | DateFieldTypeDtoV1
            | DurationFieldTypeDtoV1
            | undefined;

          const baseDateFieldValue =
            fieldData?.[relativeFieldKey as keyof typeof fieldData] ?? "";
          if (baseDateFieldValue) {
            dueDate = item.dueDateConfig?.enabled
              ? dayjs(baseDateFieldValue as unknown as string)
                  .subtract(
                    Number(item?.dueDateConfig?.offset),
                    item?.dueDateConfig?.unit as unknown as ManipulateType
                  )
                  .toISOString()
              : "";

            reminderDate = item.reminderConfig?.enabled
              ? dayjs(dueDate)
                  .subtract(
                    Number(item?.reminderConfig?.offset),
                    item?.reminderConfig?.unit as unknown as ManipulateType
                  )
                  .toISOString()
              : "";
          } else {
            dueDate = getRelativeFieldName(
              dueDateConfig.relativeFieldId,
              dueDateConfig.relativeFieldKey,
              fields,
              locale,
              t
            );

            reminderDate = item.reminderConfig
              ? getReminderConfigText(item.reminderConfig, t)
              : "";
          }
        }
        const updatedItem = {
          ...item,
          contract: contract ? contract.name : "",
          contractId: item.contractId,
          assignees: assignees,
          reminderAt: reminderDate,
          dueDate,
        };
        return [...acc, updatedItem];
      },
      []
    );
  }

  return updatedData;
};

export const getRelativeFieldName = (
  fieldId: string,
  fieldKey: string,
  fields: ContractFieldDTOV1[],
  locale: Language,
  t: TFunction<"translation">
) => {
  const field = fields.find((field) => field.id === fieldId);
  if (!field) {
    return "";
  }
  if (
    field.type === ContractFieldDTOV1.type.DURATION &&
    fieldKey &&
    fieldKey !== "value"
  ) {
    return `${t(`pages.tasks.modal.fields.dates.${fieldKey}`)} (${
      field.name[locale]
    })`;
  } else {
    return field.name[locale];
  }
};

const getReminderConfigText = (
  reminderConfig: OffsetConfig,
  t: TFunction<"translation">
) => {
  return reminderConfig?.enabled
    ? t(
        "pages.settings.organization.taskAutomations.overviewTable.body.dueDate",
        {
          dueDateConfigOffset: reminderConfig.offset,
          dueDateConfigUnit: t(
            `common.intevalEnums.${reminderConfig.unit as string}`
          ),
          timePreposition: t("common.timePrepositions.before"),
          relativeFieldName: "",
        }
      )
    : "";
};

export const getDueDateValue = (task: TaskNewDTO, contract: ContractDTOV1) => {
  if (task.dueDateConfig) {
    if (task.dueDateType === dueDateType.FIXED) {
      return task?.dueDateConfig.dueDate as string;
    } else if (task.dueDateType === dueDateType.RELATIVE) {
      const { relativeFieldKey, relativeFieldId, enabled, offset, unit } =
        task.dueDateConfig as TaskAutomationDueDateConfigDTO;

      if (!relativeFieldKey || !relativeFieldId || !enabled || !offset) {
        return;
      }

      const fieldData = contract?.fields[relativeFieldId] as
        | DateFieldTypeDtoV1
        | DurationFieldTypeDtoV1
        | undefined;

      const baseDateFieldValue = fieldData?.[
        relativeFieldKey as keyof typeof fieldData
      ] as unknown as string;

      return enabled && baseDateFieldValue
        ? dayjs(baseDateFieldValue)
            .subtract(Number(offset), unit as unknown as ManipulateType)
            .toISOString()
        : null;
    }
  }
};
