import styled from "@emotion/styled";
import { theme } from "theme";
import aiIcon from "assets/svg/ai.svg?react";
import arrowRightIcon from "assets/svg/arrow-right.svg?react";

export const InfoWrapper = styled.div`
  .info {
    margin: ${theme.spacing.sm};
    cursor: pointer;
    transition: background-color 0.25s ease-in-out;
  }

  :hover .info {
    background-color: ${theme.color.purple[100]};
  }

  :active .info {
    background-color: ${theme.color.purple[300]};
  }

  .cta {
    opacity: 0;
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  }

  .info:hover .cta,
  .info:active .cta {
    opacity: 1;
  }

  .info:active .cta {
    transform: translateX(4px);
  }
`;

export const AiIcon = styled(aiIcon)`
  color: ${theme.color.purple[900]};
`;

export const ArrowRightIcon = styled(arrowRightIcon)`
  fill: ${theme.color.purple[900]};
  color: ${theme.color.purple[900]};
`;
