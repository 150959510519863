import React from "react";
import { Box, Button } from "@mui/material";
import { UserService } from "../../openapi";
import { useStyles } from "../../components/StyledComponents/StyledBaseButtons";
import * as Yup from "yup";
import { FormTextField } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import * as Sentry from "@sentry/react";

type ForgotPasswordType = { email: string };

const validationSchema = () =>
  Yup.object({
    email: Yup.string().email().required(),
  });

const initialValues = {
  email: "",
};

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema()),
  });

  const onSubmit = async (values: ForgotPasswordType) => {
    try {
      await UserService.forgotPassword(values.email);
    } catch (e) {
      Sentry.captureException(e);
    }
    enqueueSnackbar(t("pages.forgotPassword.emailAlert"), {
      variant: "success",
    });
  };

  return (
    <form
      name="forgotPasswordForm"
      style={{
        padding: "0 5rem",
        margin: "1rem 0",
        width: "100%",
      }}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormTextField
        control={control}
        name="email"
        label={t("pages.forgotPassword.form.emailAddress")}
        required
        type="email"
      />
      <Box sx={{ mb: 2 }}>
        <Button
          type="submit"
          fullWidth
          size="large"
          className={classes.baseButton}
        >
          {t("pages.forgotPassword.form.submit")}
        </Button>
      </Box>
    </form>
  );
};

export default ForgotPasswordForm;
