import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ApiError,
  OrganizationNewService,
  InvitationDTO,
  InvitationCreateDTO,
} from "openapi";

export const useInvitationsQuery = (
  organizationId: string,
  isOwner: boolean
) => {
  return useQuery<InvitationDTO[], ApiError>({
    queryKey: ["invitation", organizationId],
    queryFn: () => OrganizationNewService.getInvitations(organizationId),
    enabled: !!organizationId && isOwner,
    retry(failureCount, error) {
      return (
        failureCount < 3 &&
        !!error &&
        error.status !== 404 &&
        error.status !== 403
      );
    },
    refetchOnWindowFocus: false,
  });
};

export const useSendInvitationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      organizationId: string;
      invitationBody: InvitationCreateDTO;
    }) => {
      return OrganizationNewService.inviteMember(
        data.organizationId,
        data.invitationBody
      );
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["invitation", variables.organizationId],
      });
    },
  });
};
export const useInvitationDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { teamMemberId: string; organizationId: string }) => {
      return OrganizationNewService.deleteInvitation(
        data.organizationId,
        data.teamMemberId
      );
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["invitation", variables.organizationId],
      });
    },
  });
};
