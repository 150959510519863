import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { $generateHtmlFromNodes } from "@lexical/html";
import html2pdf from "html2pdf.js";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";
import { ContractAttachmentService, ContractDTOV1 } from "openapi";
import { useContractTemplatesQueryV1 } from "shared/api/contract-templates";
import routePaths from "constants/routePaths";
import { useTeam } from "contexts/team/hooks";
import { FormTextField, NewModal } from "components";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import { FormSelect } from "components/FormItems/FormSelect/FormSelect";
import { CategorySelector } from "components/ContractUpload/CategorySelector";
import CircularLoading from "components/CircularLoading/CircularLoading";
import { Description } from "components/Modal/styles";
import { TextEditor } from "pages/Settings/OrganizationSettings/ContractTemplates/components/TextEditor";
import "../../pages/Settings/OrganizationSettings/ContractTemplates/editor-theme.css";
import { DescriptionIcon, Form, Wrapper } from "./styles";
import { useOrganizationCategoriesQuery } from "shared/api/organization/categories";
import { categoriesTeamSelector } from "shared/api/organization/categories.helpers";

type ImportFromTemplateProps = {
  contract?: ContractDTOV1;
  open: boolean;
  onClose: () => void;
};

type FormData = {
  categoryId: string;
  name: string;
  templateName: string;
};

const getSchema = (requireName: boolean) =>
  yup
    .object({
      categoryId: yup.string(),
      name: requireName ? yup.string().required() : yup.string(),
      templateName: yup.string().required(),
    })
    .required();

const ImportFromTemplate = ({
  open,
  onClose,
  contract,
}: ImportFromTemplateProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { selectedTeamId, organizationId } = useTeam();
  const styles = useStyles();
  const [modifiedTemplate, setModifiedTemplate] = useState<
    string | undefined
  >();
  const [selectedTemplateContent, setSelectedTemplateContent] = useState<
    string | undefined
  >();
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, setValue, reset, watch } = useForm<FormData>({
    defaultValues: {
      categoryId: contract ? contract.categoryId : "",
    },
    resolver: yupResolver(getSchema(!contract)),
  });

  const { data: categories } = useOrganizationCategoriesQuery(
    organizationId,
    (categories) =>
      categories.filter((category) =>
        categoriesTeamSelector(category, selectedTeamId)
      )
  );

  const { data } = useContractTemplatesQueryV1(organizationId);

  const categoryId = watch("categoryId");

  const handleOnClose = () => {
    onClose();
    setModifiedTemplate("");
    setSelectedTemplateContent("");
    reset();
  };

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      const output = (await html2pdf()
        .from(modifiedTemplate)
        .set({
          margin: [0.25, 0.75],
          html2canvas: { scale: 5 },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        })

        .output("blob")
        .then()) as File;

      if (contract) {
        await ContractAttachmentService.uploadFileForContract(contract.id, {
          file: new File([output], `${contract.name}.pdf`, {
            type: output.type,
            lastModified: output.lastModified,
          }),
        });
        handleOnClose();
      } else {
        const contractCreated = await ContractAttachmentService.createContract(
          false,
          selectedTeamId,
          {
            file: new File([output as Blob], `${data.name}.pdf`, {
              type: output.type,
              lastModified: output.lastModified,
            }),
            categoryId: data.categoryId || undefined,
          }
        );
        handleOnClose();
        navigate(`${routePaths.CONTRACTS}/${contractCreated.id}`);
      }

      enqueueSnackbar(
        t("pages.settings.tabs.contractTemplates.snackbars.createPDFSuccess"),
        {
          variant: "success",
        }
      );
    } catch (e) {
      enqueueSnackbar(
        t("pages.settings.tabs.contractTemplates.snackbars.createPDFFailure"),
        {
          variant: "error",
        }
      );
    } finally {
      setLoading(false);
    }
  };

  if (!data || !categories) return <CircularLoading isLoading />;

  const handleOnTemplateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const templateName = e.target.value;
    const selectedTemplate = data?.find(
      (template) => template.name === templateName
    );
    if (selectedTemplate) {
      setModifiedTemplate(selectedTemplate.content);
      setSelectedTemplateContent(selectedTemplate.content);
    }
  };

  const body = (
    <Wrapper>
      <Description>{t("importFromTemplate.subtitle")}</Description>
      <Form>
        <FormSelect
          control={control}
          options={data?.map((template) => template.name)}
          name="templateName"
          label={t("pages.settings.tabs.contractTemplates.templateName")}
          inputProps={{
            onChange: (e) =>
              handleOnTemplateChange(
                e as unknown as ChangeEvent<HTMLSelectElement>
              ),
          }}
          required
        />
        {!contract && (
          <>
            <FormTextField
              style={{ marginTop: 0 }}
              control={control}
              name="name"
              required
              size="small"
              label={t("pages.contractEdit.forms.generalForm.contractTitle")}
            />
            <CategorySelector
              categories={categories}
              selectedCategoryId={categoryId}
              onSelectCategory={(categoryId) =>
                setValue("categoryId", categoryId)
              }
            />
          </>
        )}
        <TextEditor
          initialHTML={selectedTemplateContent}
          onChange={(editorState, editor) => {
            editorState.read(() => {
              setModifiedTemplate($generateHtmlFromNodes(editor, null));
            });
          }}
        />
      </Form>
    </Wrapper>
  );

  const footer = (
    <Button
      type="submit"
      disabled={loading}
      className={styles.saveButton}
      fullWidth
      onClick={handleSubmit(onSubmit)}
    >
      {loading ? t("common.buttons.creating") : t("common.buttons.create")}
    </Button>
  );

  return (
    <NewModal
      open={open}
      handleClose={handleOnClose}
      title={t("pages.contracts.header.importFromTemplate")}
      icon={<DescriptionIcon />}
      body={body}
      footer={footer}
      maxWidth="md"
      fullWidth
    />
  );
};

export default ImportFromTemplate;
