import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { theme } from "../../theme";

export const SimpleLink = styled(Link)`
  color: ${theme.color.blue[600]} !important;
  &:hover {
    text-decoration: underline !important;
  }
  &:active {
    color: ${theme.color.blue[800]};
  }
  &:active {
    color: ${theme.color.blue[800]};
  }
  &:disabled {
    color: ${theme.color.gray[300]};
  }
`;
