/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FTPSBackupConfigUpdate = {
  type: FTPSBackupConfigUpdate.type;
  password?: string;
  hostname?: string;
  port?: number;
  username?: string;
  ftpsPassword?: string;
  path?: string;
};

export namespace FTPSBackupConfigUpdate {
  export enum type {
    FTPS = "FTPS",
    SFTP = "SFTP",
    AWS_S3 = "AWS S3",
  }
}
