/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractUpdateDto } from "../models/ContractUpdateDto";
import type { TagInsertDto } from "../models/TagInsertDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ContractService {
  /**
   * Create empty contract
   * @param teamId
   * @returns string
   * @throws ApiError
   */
  public static createEmptyContract(teamId: string): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/contracts",
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Update a contract
   * @param id
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static updateContract(
    id: string,
    requestBody: ContractUpdateDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/contracts/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Delete a contract
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static deleteContract(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/contracts/{id}",
      path: {
        id: id,
      },
    });
  }

  /**
   * Add tags to a contract
   * @param id
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static addTags(
    id: string,
    requestBody: TagInsertDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/contracts/{id}/tag",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Remove a tag from a contract
   * @param id
   * @param tagId
   * @returns any
   * @throws ApiError
   */
  public static removeTag(id: string, tagId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/contracts/{id}/tag/{tagId}",
      path: {
        id: id,
        tagId: tagId,
      },
    });
  }
}
