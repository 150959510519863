import React from "react";
import { IField } from "@flatfile/adapter/build/main/interfaces";
import { TFunction } from "i18next";
import * as Sentry from "@sentry/react";
import { isEmpty } from "lodash";
import {
  ContactDatapointDefinitionDTO,
  ContractFieldDTOV1,
  ContractInputDTOV1,
  TeamDescriptionDto,
  CategoryDTO,
  ContractDTOV1,
  ContactDTO,
} from "openapi";
import { Language } from "shared/i18n/i18n";
import { isUUID } from "pages/Contract/helpers";
import {
  createTextField,
  createCategoryField,
  createCustomFields,
  createCustomContactFields,
  createTeamField,
  createStatusField,
} from "./helpers";
import {
  formatDate,
  formatNumber,
  getAmountField,
  getDateField,
  getUUID,
} from "./utils";
import type = ContractFieldDTOV1.type;
import { getDefaultCategory } from "shared/api/organization/categories.helpers";

type ContactData = {
  [key: string]: {
    value: {
      value: string;
    };
  };
};

export const fillImportFields = (
  selectedTeamId: string,
  parentTeamId: string,
  categories: CategoryDTO[],
  fields: ContractFieldDTOV1[],
  teams: TeamDescriptionDto[],
  setMappedImportFields: React.Dispatch<
    React.SetStateAction<IField[] | undefined>
  >,
  locale: Language,
  t: TFunction<"translation">,
  userDateFormat: string,
  contactFields?: ContactDatapointDefinitionDTO[]
) => {
  const filteredCategories = categories.filter((item) => {
    return item.teams.includes(selectedTeamId);
  });

  const filteredFields = fields.filter((v, i, a) => {
    if (v.type === type.FORMULA || v.type === type.CONTACT) {
      return false;
    }
    return a.findIndex((v2) => v2.name[locale] === v.name[locale]) === i;
  });

  if (!contactFields) return;

  const contactFieldsWithPrefix = contactFields.map((field) => {
    const updatedObj = { ...field };
    updatedObj.name = {
      de: `${t("contractUpload.partner")} ${field.name.de}`,
      en: `${t("contractUpload.partner")} ${field.name.en}`,
    };
    return updatedObj;
  });

  setMappedImportFields(() => {
    const importFields = [
      createTextField("id", t("pages.import.labels.id")),
      createTextField("name", t("pages.import.labels.name"), [
        { type: "required" },
      ]),
      createStatusField("status", ContractDTOV1.status, t),
      createCategoryField(filteredCategories, t, locale),
      createTextField("tags", t("pages.import.labels.tags")),
      ...createCustomFields(filteredFields, locale, t, userDateFormat).flat(),
      ...createCustomContactFields(contactFieldsWithPrefix, locale, t).flat(),
    ];

    if (teams.length > 1) {
      importFields.unshift(createTeamField(teams, "teamId", t));
    }
    return importFields as IField[];
  });
};

export const flatItemToContactCreateDTO = (
  importItem: { [x: string]: string },
  fields: ContactDatapointDefinitionDTO[],
  typeId: string
) => {
  const contactData: ContactData = {};
  fields.forEach((item) => {
    if (!importItem[item.id]) return;
    contactData[item?.visibleId] = { value: { value: importItem[item.id] } };
  });
  if (!isEmpty(contactData))
    return {
      ...contactData,
      typeId,
      id: importItem["counterPartyId"],
    } as ContactDTO;
};

export const flatItemToContractCreateDTO = (
  importItem: { [x: string]: string },
  params: {
    fields: ContractFieldDTOV1[];
    categories: CategoryDTO[];
    selectedTeamId: string;
    userDateFormat: string;
  }
): ContractInputDTOV1 => {
  // Import on current team
  // @Note: Is there a case where we do not have a team associated with a field?
  const { fields, categories, selectedTeamId, userDateFormat } = params;
  if (!importItem["teamId"] || importItem["teamId"] === selectedTeamId) {
    const contractImportItem = convertItemToContractDTO(importItem, {
      fields,
      teamId: selectedTeamId,
      userDateFormat,
    });
    if (contractImportItem?.categoryId) {
      return contractImportItem;
    }
    if (contractImportItem.id) {
      return contractImportItem;
    }
    contractImportItem.categoryId = getDefaultCategory(
      categories,
      selectedTeamId
    )?.id;
    return contractImportItem;
  }

  // import on sub-teams

  const contractTeamId = importItem["teamId"];
  const contractImportItem = convertItemToContractDTO(importItem, {
    fields,
    teamId: contractTeamId,
    userDateFormat: userDateFormat,
  });

  if (contractImportItem.categoryId) {
    return contractImportItem;
  }
  if (contractImportItem.id) {
    return contractImportItem;
  }
  contractImportItem.categoryId = getDefaultCategory(
    categories,
    contractTeamId
  )?.id;
  return contractImportItem;
};

export const convertItemToContractDTO = (
  row: { [x: string]: string },
  params: {
    fields: ContractFieldDTOV1[];
    teamId: string;
    userDateFormat: string;
  }
): ContractInputDTOV1 => {
  const { fields, teamId, userDateFormat } = params;
  const contractCreateData: ContractInputDTOV1 = { fields: {} };
  const customFields = {};
  for (const key in row) {
    //duration field
    const fieldKeys = key.split(".");
    const fieldId = fieldKeys[0];
    if (row[key]) {
      if (isUUID(key)) {
        const teamField = getMatchingField(fields, fieldId, teamId);
        const value = getValue(row[key]);
        if (teamField)
          Object.assign(
            customFields,
            convertFieldToCustomField(
              teamField,
              value as string,
              userDateFormat
            )
          );
      } else if (fieldKeys[1]) {
        const teamField = getMatchingField(fields, fieldId, teamId);
        if (teamField)
          Object.assign(
            customFields,
            convertFieldToDurationField(row, {
              fieldId,
              teamFieldId: teamField.id,
              userDateFormat,
            })
          );
      }
    }
  }

  return Object.assign(contractCreateData, {
    ...convertItemToContractMainData(row),
    fields: customFields,
  }) as ContractInputDTOV1;
};

export const convertFieldToCustomField = (
  field: ContractFieldDTOV1,
  value: string,
  userDateFormat: string
) => {
  const fieldId = field.id;
  switch (field?.type) {
    case ContractFieldDTOV1.type.AMOUNT:
      return getAmountField(value, fieldId);
    case type.NUMBER:
      return { [fieldId]: { value: parseFloat(value) } };
    case type.DURATION:
      return;
    case type.DATE:
      return getDateField(value, fieldId, userDateFormat);
    default:
      return { [fieldId]: { value: value } };
  }
};

const convertItemToContractMainData = (item: { [x: string]: string }) => {
  const id = getUUID(getValue(item["id"]) as string) as string;
  return {
    id,
    name: getValue(item["name"]),
    categoryId: getValue(item["categoryId"]),
    status: getValue(item["status"]) || undefined,
    tags: item["tags"],
    teamId: item["teamId"] || undefined,
  };
};

export const convertFieldToDurationField = (
  item: {
    [x: string]: string;
  },
  params: {
    fieldId: string;
    teamFieldId: string;
    userDateFormat: string;
  }
) => {
  const { fieldId, teamFieldId, userDateFormat } = params;
  const durationFields = Object.fromEntries(
    Object.entries(item).filter(([key]) => key.startsWith(fieldId))
  );

  return {
    [teamFieldId]: {
      type: durationFields[`${fieldId}.type`] || null,
      endDate:
        formatDate(durationFields[`${fieldId}.endDate`], userDateFormat) ||
        null,
      startDate:
        formatDate(durationFields[`${fieldId}.startDate`], userDateFormat) ||
        null,
      interval: formatNumber(durationFields[`${fieldId}.interval`]) || null,
      terminationDate:
        formatDate(
          durationFields[`${fieldId}.terminationDate`],
          userDateFormat
        ) || null,
      noticePeriod:
        formatNumber(durationFields[`${fieldId}.noticePeriod`]) || null,
      automaticRenewal:
        formatNumber(durationFields[`${fieldId}.automaticRenewal`]) || null,
    },
  };
};

export const getCurrentTeams = (
  teams: TeamDescriptionDto[],
  teamId: string
) => {
  const parentTeam = teams.find(
    (item) =>
      item.id === teamId || item.children.find((item) => item.id === teamId)
  );
  return parentTeam ? [parentTeam, ...parentTeam.children] : [];
};

export const getMatchingField = (
  fields: ContractFieldDTOV1[],
  fieldId: string,
  teamId: string
) => {
  const field = fields.find((item) => item.id === fieldId);
  if (field?.teamId === teamId || field?.organizationId !== null) {
    return field;
  }
  return fields.find(
    (item) =>
      item.teamId === teamId &&
      item.name["de"] === field?.name["de"] &&
      item.type === field?.type
  );
};

type DynamicField = {
  value: string | number;
};

const isDynamicField = (value: DynamicField) => {
  return value && typeof value === "object" && "value" in value;
};

export const getValue = (field: string | DynamicField): string | undefined => {
  if (typeof field === "string") {
    return field.trim();
  }

  if (isDynamicField(field)) {
    return field.value.toString().trim();
  }

  if (Sentry) {
    Sentry.captureMessage(`Invalid field type: ${JSON.stringify(field)}`);
  }

  return undefined;
};
