import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
`;

export const Values = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xxs};
`;

export const Body = styled.div`
  font-size: ${theme.font.size.body};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xxs};
`;

export const TitleIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  margin-right: ${theme.spacing.sm};
  svg {
    fill: ${theme.color.gray[700]};
    max-width: 14px;
    max-height: 14px;
  }
`;

export const ValueIconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  svg {
    transform: rotate(90deg);
    width: 20px;
    height: 20px;
  }
`;
