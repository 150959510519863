import React, { PropsWithChildren, ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { isEmpty } from "lodash";
import AlertComponent from "../Alert/Alert";
import { AlertTypeEnum } from "../../shared/enums/alert.enum";
import ContractHeroLogo from "../../assets/svg/logo-icon.svg?react";
import EnglishFlag from "../../assets/svg/locale/gb.svg?react";
import GermanFlag from "../../assets/svg/locale/de.svg?react";
import { useLocale } from "../../hooks/GlobalStateHooks";
import { Language } from "../../shared/i18n/i18n";
import { CardLink } from "./AuthCardWrapperLinks";
import {
  SubTitleWrapper,
  TitleWrapper,
  Wrapper,
  Divider,
  FooterLink,
} from "./AuthCardWrapper.styles";

export type AuthCardWrapperProps = PropsWithChildren<{
  title?: string;
  subTitle?: string;
  links?: CardLink[];
  padding?: number;
  maxWidth?: number;
  alertElement?: ReactElement;
  alertKey?: string; //translation key
  alertType?: AlertTypeEnum;
  useFooterActionInsteadofLink?: boolean;
  footerActionLabelKey?: string;
  footerActionClick?: () => void;
}>;

const AuthCardWrapper = ({
  title,
  subTitle,
  links = [],
  children,
  maxWidth,
  alertElement = undefined,
  alertKey,
  alertType = AlertTypeEnum.success,
  padding,
  useFooterActionInsteadofLink = false,
  footerActionLabelKey,
  footerActionClick,
}: AuthCardWrapperProps) => {
  const { locale, changeLocale } = useLocale();
  const { t } = useTranslation();
  const location = useLocation();

  const handleChange = (event: SelectChangeEvent) => {
    changeLocale(event.target.value as Language).catch((e) => console.error(e));
  };

  return (
    <Wrapper maxwidth={maxWidth} padding={padding}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ContractHeroLogo data-testid="auth-card-logo" />

        <FormControl
          sx={{ m: 1, minWidth: 120, position: "absolute", right: "15px" }}
          id="languageSwitch"
          data-testid="auth-card-language-switcher"
        >
          <Select
            id="language-select"
            value={locale}
            onChange={handleChange}
            size="small"
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem id="english-item" value="en">
              <EnglishFlag height={12} width={30} />
              <span>{t("common.languages.en")}</span>
            </MenuItem>
            <MenuItem id="german-item" value="de">
              <GermanFlag height={12} width={30} />
              <span>{t("common.languages.de")}</span>
            </MenuItem>
          </Select>
        </FormControl>

        {title && (
          <TitleWrapper data-testid="auth-card-title">{title}</TitleWrapper>
        )}

        {subTitle && (
          <SubTitleWrapper data-testid="auth-card-subtitle">
            {subTitle}
          </SubTitleWrapper>
        )}

        {alertKey && (
          <AlertComponent
            type={alertType}
            textKey={alertKey}
            element={alertElement}
          />
        )}

        {children}

        {!isEmpty(links) && <Divider />}

        {useFooterActionInsteadofLink && footerActionLabelKey ? (
          <span
            className="hover-pointer"
            onClick={footerActionClick}
            style={{ color: "#4a5568" }}
          >
            {t(footerActionLabelKey)}
          </span>
        ) : (
          <Box sx={{ mt: 1, display: "flex" }}>
            {links.map((link, index) => (
              <FooterLink
                key={index}
                id={`auth-link-${index}`}
                to={{
                  pathname: link.route,
                  search: location.search,
                }}
              >
                {t(`${link.labelKey}`)}
              </FooterLink>
            ))}
          </Box>
        )}
      </Box>
    </Wrapper>
  );
};

export default AuthCardWrapper;
