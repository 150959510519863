import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import React, { FC } from "react";
import { ToolbarButton } from "./ToolbarButton";
import { ToolbarSpecificButtonProps } from "./types";
import { useTranslation } from "react-i18next";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

export const FullscreenButton: FC<
  ToolbarSpecificButtonProps<"EnterFullScreen">
> = ({ Slot, collapses, collapsed }) => {
  const { t } = useTranslation();
  return (
    <Slot>
      {(props) => {
        return collapsed ? (
          <MenuItem
            onClick={() => {
              props.onClick();
            }}
          >
            <ListItemIcon>
              <FullscreenIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("pdfViewer.toolbar.fullscreen")}</ListItemText>
          </MenuItem>
        ) : (
          <ToolbarButton
            tooltip={t("pdfViewer.toolbar.fullscreen")}
            onClick={() => {
              props.onClick();
            }}
            collapses={collapses}
          >
            <FullscreenIcon />
          </ToolbarButton>
        );
      }}
    </Slot>
  );
};
