import React from "react";
import { Tooltip } from "@mui/material";
import { Chip } from "components";
import { getColor } from "components/Chip/helpers";
import { ChipContainer, StyledChip, EllipsisText } from "./styles";

type HeaderParams = {
  id: string;
  name: string;
  color?: string;
};

type DefaultParams = {
  data: {
    id: string;
    name: string;
    color?: string;
  };
};

type Params = HeaderParams | DefaultParams;

const getHeaderCellParams = (
  params: HeaderParams & { isHeader?: boolean }
) => ({
  id: params.id,
  name: params.name,
  color: params.color ?? "",
  isHeader: params.isHeader,
});

const getDefaultCellParams = (params: DefaultParams) => ({
  id: params.data.id,
  name: params.data.name,
  color: params.data.color ?? "",
});

const CategoryNameCellRenderer: React.FC<Params & { isHeader?: boolean }> = (
  params
) => {
  const data =
    "data" in params
      ? getDefaultCellParams(params)
      : getHeaderCellParams(params);

  const isLongText = data.name.length > 13;

  if (isLongText) {
    return (
      <Tooltip title={data.name} placement="top" arrow>
        <ChipContainer isHeader={!!params.isHeader}>
          <StyledChip
            key={data.id}
            blendMode="soft"
            color={getColor(data.color)}
            isHeader={!!params.isHeader}
          >
            <EllipsisText>{data.name}</EllipsisText>
          </StyledChip>
        </ChipContainer>
      </Tooltip>
    );
  }

  return (
    <Chip key={data.id} blendMode="soft" color={getColor(data.color)}>
      {data.name}
    </Chip>
  );
};

export default CategoryNameCellRenderer;
