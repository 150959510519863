import { CategoryDTO } from "openapi";

type PermissionType = {
  categoryId: string;
  level: string;
};

const generatePrefilledCategories = (
  availableCategories: CategoryDTO[],
  defaultPermissions: PermissionType[]
): PermissionType[] | null => {
  if (!availableCategories || !defaultPermissions) return null;
  return availableCategories.map((category) => {
    const existingCategoryPermissions = defaultPermissions.find(
      (p) => p.categoryId === category.id
    );

    return existingCategoryPermissions
      ? existingCategoryPermissions
      : {
          categoryId: category.id,
          level: "NO_ACCESS",
        };
  });
};

export default generatePrefilledCategories;
