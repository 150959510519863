/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ContractDatapointMetadata } from "./ContractDatapointMetadata";
import type { FormulaFieldVariable } from "./FormulaFieldVariable";

export type FormulaFieldTypeDtoV1 = {
  metadata?: Record<string, ContractDatapointMetadata> | null;
  displayAs?: FormulaFieldTypeDtoV1.displayAs;
  variables: Array<FormulaFieldVariable>;
  value: string;
  annotationData?: Record<string, any> | null;
  analysisData?: Record<string, any> | null;
};

export namespace FormulaFieldTypeDtoV1 {
  export enum displayAs {
    TEXTFIELD = "TEXTFIELD",
    TEXTAREA = "TEXTAREA",
    NUMBER = "NUMBER",
    DATE = "DATE",
    LIST = "LIST",
    AMOUNT = "AMOUNT",
    LINK = "LINK",
    COUNTRY = "COUNTRY",
    FORMULA = "FORMULA",
    DURATION = "DURATION",
    CONTACT = "CONTACT",
  }
}
