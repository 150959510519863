import React from "react";
import Tag from "new-components/Tag/Tag";
import { ContractDTOV1 } from "openapi";
import { countVerificationStatus } from "../../Contract/helpers";
import { TagWrapper } from "./styles";

const VerifiedCountCellRenderer = ({
  data: contract,
}: {
  data: ContractDTOV1;
}) => {
  const count = contract?.fields && countVerificationStatus(contract);
  const verified = count?.verified || 0;
  const total = verified + (count?.unverified ?? 0);

  return (
    <TagWrapper>
      <Tag variant="ai">{`${verified} / ${total}`}</Tag>
    </TagWrapper>
  );
};

export default VerifiedCountCellRenderer;
