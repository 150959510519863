import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";
import { AuthCardContainer } from "components/AuthCardWrapper/AuthCardWrapper.styles";
import { Divider } from "components/AuthCardWrapper";
import { AuthWithSSOButton } from "pages/LoginPage/components/AuthWithSSOButton";
import { AuthProvider } from "pages/LoginPage/components/AuthProvider.enum";
import AuthCardWrapper from "components/AuthCardWrapper/AuthCardWrapper";
import { CardLinks } from "components/AuthCardWrapper/AuthCardWrapperLinks";
import RegisterForm from "./registerForm";
import styled from "@emotion/styled";
import omrBadge from "./assets/Leader.webp";
import dsgvoBadge from "./assets/DSGVO-transparent.webp";
import g2Badge from "./assets/ContractManagement_BestSupport_QualityOfSupport.webp";
import { useAuthMethodsQuery } from "shared/api/auth";
import { useHostname } from "hooks/HostnameHook";
import AlertComponent from "components/Alert/Alert";
import { AlertTypeEnum } from "shared/enums/alert.enum";
import { TeamPublicInfoDto, TeamService } from "openapi";
import { useSnackbar } from "notistack";
import routePaths from "constants/routePaths";
import { setInvalidTokenError } from "shared/service/errorResponseService";

const TrustContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;

  & > img {
    height: 100px;
    user-select: none;
    width: auto;
  }

  & > img:nth-of-type(3) {
    height: 64px;
  }
`;

const Register = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [invitationInfo, setInvitationInfo] = useState<TeamPublicInfoDto>();

  const hostname = useHostname();
  const { data } = useAuthMethodsQuery(hostname ?? "");

  const token = searchParams.get("invitationToken") || undefined;

  const links = [];
  if (data?.password) {
    links.push(CardLinks.forgotPassword);
  }
  links.push(CardLinks.login);

  const getInvitedTeamName = async () => {
    if (!token) return;

    try {
      const teamPublicInfo = await TeamService.getInvitationInfo({ token });
      setInvitationInfo(teamPublicInfo);
      if (teamPublicInfo.userExist) {
        navigate({
          pathname: routePaths.LOGIN,
          search: `?${searchParams.toString()}`,
        });
      }
    } catch (e) {
      setInvalidTokenError(e, "pages.register", enqueueSnackbar, t);
    }
  };

  useEffect(() => void getInvitedTeamName(), []);

  return (
    <Container component="main" sx={{ marginTop: "2.5rem" }}>
      <AuthCardWrapper
        title={t("pages.register.title")}
        subTitle={t("pages.register.subtitle")}
        links={links}
      >
        <AuthCardContainer>
          {invitationInfo && (
            <AlertComponent
              type={AlertTypeEnum.success}
              textKey="pages.register.foundInvitation"
              params={{ teamName: invitationInfo.name }}
            />
          )}
          {data?.password && (
            <RegisterForm
              invitationToken={token}
              invitationInfo={invitationInfo}
            />
          )}
          {data?.password &&
            (((data?.google || data.microsoft) && !hostname) || data?.oidc) && (
              <Divider>{t("pages.login.or")}</Divider>
            )}
          {data?.google && (
            <AuthWithSSOButton provider={AuthProvider.GOOGLE} mode="register" />
          )}
          {data?.microsoft && (
            <AuthWithSSOButton
              provider={AuthProvider.MICROSOFT}
              mode="register"
            />
          )}
          {data?.oidc && (
            <>
              <AuthWithSSOButton
                provider={AuthProvider.OIDC}
                mode="register"
                data={data.oidc}
              />
            </>
          )}
        </AuthCardContainer>
        <TrustContainer>
          <img src={omrBadge} draggable={false} alt="OMR badge" />
          <img src={g2Badge} draggable={false} alt="G2 badge" />
          <img src={dsgvoBadge} draggable={false} alt="DSGVO badge" />
        </TrustContainer>
      </AuthCardWrapper>
    </Container>
  );
};

export default Register;
