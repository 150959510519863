import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useStyles as useStyledButtons } from "components/StyledComponents/StyledBaseButtons";
import saveAs from "file-saver";
import { ContractDTOV1 } from "openapi";
import { convertContractName } from "constants/utils";

type Props = {
  contract: ContractDTOV1;
  contractFile?: Blob | null | undefined;
};

const DownloadPDF = ({ contractFile, contract }: Props) => {
  const { t } = useTranslation();
  const buttonClasses = useStyledButtons();

  const downloadPdfFile = () => {
    if (contractFile) {
      saveAs(contractFile, convertContractName(contract.name));
    }
  };

  return (
    <Button
      id="downloadPDFButton"
      className={buttonClasses.previewHeaderButton}
      onClick={downloadPdfFile}
    >
      <DownloadIcon fontSize="small" sx={{ marginRight: "0.5rem" }} />
      {t("pages.admin.tabs.pendingContracts.modal.buttons.downloadPdf")}
    </Button>
  );
};

export default DownloadPDF;
