import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const shine = keyframes`
  to { background-position: 200% center; }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  color: #0000008a;

  h1 {
    background: linear-gradient(
      to right,
      #bedbf6 20%,
      #3182ce 40%,
      #3182ce 60%,
      #bedbf6 80%
    );
    background-size: 200% auto;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${shine} 7s linear infinite;
    font-weight: bold;
    font-size: 2.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  text-align: center;
`;

export const Description = styled.div`
  margin-bottom: 1.5rem;
  font-size: 1rem;
`;
