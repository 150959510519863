import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ICellEditorParams } from "ag-grid-community";
import { CellEditorField } from "./style";
import "dayjs/locale/en";
import "dayjs/locale/de";
import { CellValue } from "../helpers";
import { DatePicker } from "components/DatePicker/DatePicker";
import { flushSync } from "react-dom";

interface DateCellEditorProps extends ICellEditorParams {
  value: CellValue;
  matchedFieldValue?: string;
  isCustomField?: boolean;
}

export const DateCellEditor = forwardRef((props: DateCellEditorProps, ref) => {
  const { matchedFieldValue, isCustomField, value: cellValue } = props;
  const [value, setValue] = useState<string | null>(
    (cellValue?.value ?? cellValue ?? matchedFieldValue) as string
  );
  const refInput = useRef<HTMLInputElement | null>(null);
  const refCalendar = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event: MouseEvent) {
    const isClickedOutside =
      refInput.current &&
      !refInput.current.contains(event.target as Node) &&
      refCalendar.current &&
      !refCalendar.current.contains(event.target as Node);
    // Close cell editor when clicking outside the input and the calendar
    if (isClickedOutside || isClickedOutside === null) {
      flushSync(() => {
        refInput.current?.blur();
      });
      props.api.stopEditing();
    }
  }
  // focus on the input
  useEffect(() => {
    // get ref from React component
    refInput.current?.focus();
  }, []);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return isCustomField ? { value } : value;
      },
    };
  });

  return (
    <DatePicker
      ref={refCalendar}
      inputRef={refInput}
      value={value ?? null}
      inputProps={{
        sx: { fontSize: "12px" },
      }}
      datePickerProps={{
        slots: {
          textField: CellEditorField,
        },
        slotProps: {
          popper: {
            role: "presentation",
            ref: refCalendar,
          },
        },
      }}
      onDateChanged={(value) => {
        if (!value) {
          setValue(null);
        }
        setValue(value);
      }}
    />
  );
});
DateCellEditor.displayName = "DateCellEditor";
