/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AddMemberToRoleDTO } from "./AddMemberToRoleDTO";
import type { TeamPermissionDto } from "./TeamPermissionDto";

export type TeamInvitationRequestDto = {
  role: TeamInvitationRequestDto.role;
  email: string;
  language: TeamInvitationRequestDto.language;
  owner?: boolean | null;
  categoryAdmin?: boolean | null;
  permissions?: Array<TeamPermissionDto> | null;
  roles?: Array<AddMemberToRoleDTO> | null;
};

export namespace TeamInvitationRequestDto {
  export enum role {
    USER = "USER",
    LIMITED_USER = "LIMITED_USER",
    OWNER = "OWNER",
  }

  export enum language {
    EN = "en",
    DE = "de",
  }
}
