import styled from "@emotion/styled";
import { Divider as MUIDivider } from "@mui/material";
import { theme } from "theme";

export const Divider = styled(MUIDivider)`
  margin: ${theme.spacing.lg} auto;
  text-transform: uppercase;
  color: ${theme.color.gray[600]};
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  :before,
  :after {
    position: absolute;
    content: "";
    border: none;
    background-color: ${theme.color.gray[400]} !important;
    height: 1px;
    width: calc(50% - ${theme.spacing.xxl});
  }

  :before {
    left: 0;
  }

  :after {
    right: 0;
  }
`;
