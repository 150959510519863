import styled from "@emotion/styled";
import { theme } from "theme";
import CheckDenyIcon from "assets/svg/check-cross-icon.svg?react";
import CheckApproveIcon from "assets/svg/check-square-icon.svg?react";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.md};
`;

export const DenyIcon = styled(CheckDenyIcon)`
  fill: ${theme.color.red[800]};
  flex: 0 0 auto;
`;

export const AllowIcon = styled(CheckApproveIcon)`
  fill: ${theme.color.green[800]};
  flex: 0 0 auto;
`;

export const Value = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${theme.spacing.sm};
  line-height: 1.5;
`;

export const EntityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg};
  font-size: ${theme.font.size.caption};

  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.color.gray[300]};
    padding-bottom: ${theme.spacing.lg};
    margin-bottom: ${theme.spacing.sm};
  }
`;
