/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Addon } from "./Addon";
import type { TeamPermissionDto } from "./TeamPermissionDto";

export type TeamPermissionDTOV1 = {
  role: TeamPermissionDTOV1.role;
  permissions: Array<TeamPermissionDto>;
  addons?: Array<Addon>;
};

export namespace TeamPermissionDTOV1 {
  export enum role {
    USER = "USER",
    LIMITED_USER = "LIMITED_USER",
    OWNER = "OWNER",
  }
}
