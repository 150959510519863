/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { OrganizationNewService } from "openapi";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useContractVerificationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      organizationId: string;
      teamId: string;
      contractId: string;
    }) => {
      return OrganizationNewService.verifyContract(
        data.organizationId,
        data.teamId,
        data.contractId
      );
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["contracts"] });
      void queryClient.invalidateQueries({ queryKey: ["overview"] });
    },
  });
};
