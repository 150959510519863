import React from "react";
import { ChangeLogDataItemType } from "../../types";
import EditEntityItem from "../EditEntityItem/EditEntityItem";
import { getChangedFields } from "../LogEntity/LogEntity";
import { UserInfoDto } from "openapi";
import { useTranslation } from "react-i18next";
import CircleArrow from "assets/svg/circle-arrow-icon.svg?react";
import CalendarIcon from "assets/svg/calendar-icon.svg?react";
import { fieldsToSkip } from "../../utils";

export type DurationFieldType = {
  fieldData: ChangeLogDataItemType;
  fieldVisibleId: string;
  userInfo: UserInfoDto | undefined;
  variant?: "row";
};

const DurationField = ({
  fieldData,
  fieldVisibleId,
  userInfo,
  variant,
}: DurationFieldType) => {
  const { t } = useTranslation();

  const changedFields = getChangedFields(fieldData, fieldVisibleId, userInfo);
  let item: ChangeLogDataItemType = {};
  let fieldIcon: JSX.Element;
  return (
    <>
      {Object.keys(changedFields).map((key) => {
        if (fieldsToSkip.includes(key)) return null;
        switch (key) {
          case "type": {
            const fromValue = changedFields?.[key]?.from?.value;
            const toValue = changedFields?.[key]?.to?.value;
            const fromTranslation = fromValue
              ? t(`enums.duration.${fromValue}`)
              : "";
            const toTranslation = toValue ? t(`enums.duration.${toValue}`) : "";
            item = {
              from: { value: fromTranslation },
              to: { value: toTranslation },
            };
            fieldIcon = <CircleArrow />;
            break;
          }
          default: {
            item = changedFields?.[key];
            fieldIcon = <CalendarIcon />;
            break;
          }
        }

        const fieldName = t(`pages.import.labels.duration.${key}`) || key;
        return (
          <EditEntityItem
            key={key}
            data={item}
            fieldName={fieldName}
            fieldIcon={fieldIcon}
            variant={variant}
          />
        );
      })}
    </>
  );
};

export default DurationField;
