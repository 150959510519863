import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { ICellRendererParams } from "ag-grid-community";
import { Wrapper } from "./styles";
import { ContactDataDTO } from "../../../../../ContactDataDTO";

const LinkCellRenderer = ({
  data,
  value,
}: ICellRendererParams<ContactDataDTO, string>) => {
  const url = data ? `/contacts/${data?.id}` : null;
  const displayedValue = value || "-";

  return (
    <Wrapper link={!!url}>
      {url ? (
        <RouterLink to={url}>{displayedValue}</RouterLink>
      ) : (
        displayedValue
      )}
    </Wrapper>
  );
};

export default LinkCellRenderer;
