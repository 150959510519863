import styled from "@emotion/styled";
import { Link as LinkComponent } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { theme, breakpoints } from "theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg};
  flex-basis: 100%;
  padding: ${theme.spacing.xl};
  border-radius: ${theme.borderRadius};
  background-color: ${theme.color.white};
  box-shadow: ${theme.shadow.standard};

  @media screen and (min-width: ${breakpoints.lg}) {
    flex-basis: calc(50% - ${theme.spacing.sm});
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.sm};
  font-weight: ${theme.font.weight.medium};
  font-size: ${theme.font.size.subheading};
  color: ${theme.color.gray[600]};

  svg {
    font-size: ${theme.font.size.subheading};
  }

  span {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.md};
  }
`;

export const Body = styled.div`
  font-size: ${theme.font.size.h5};
  font-weight: ${theme.font.weight.semiBold};
  white-space: nowrap;
`;

export const Footer = styled.div`
  font-size: ${theme.font.size.body};
`;

export const ArrowDown = styled(ArrowDownwardIcon)`
  color: ${theme.color.red[400]};
`;

export const ArrowUp = styled(ArrowUpwardIcon)`
  color: ${theme.color.green[400]};
`;

export const ContractsIcon = styled(FolderCopyIcon)`
  color: ${theme.color.blue[400]};
`;

export const RemindersIcon = styled(InsertInvitationIcon)`
  color: ${theme.color.orange[400]};
`;

export const Link = styled(LinkComponent)`
  :hover {
    border-bottom: 1px solid ${theme.color.gray[600]};
  }
`;
