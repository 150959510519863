import React from "react";
import { TeamOptionWrapper as Wrapper } from "./styles";
import { Checkbox } from "@mui/material";

type TeamOptionTypes = {
  active: boolean;
  onChange: () => void;
  name: string;
};

const TeamOption = ({ active, name, onChange }: TeamOptionTypes) => {
  return (
    <Wrapper active={active}>
      <Checkbox
        key={name}
        checked={active}
        onChange={onChange}
        sx={{
          padding: 0,
        }}
      />
      <span>{name}</span>
    </Wrapper>
  );
};

export default TeamOption;
