import React, { ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { FormTextField } from "components";
import { SearchBarWrapper } from "./styles";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";

export type SearchBarType = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const SearchBar = ({ onChange }: SearchBarType) => {
  const { control } = useForm();
  const { t } = useTranslation();
  return (
    <SearchBarWrapper>
      <FormTextField
        name="search"
        control={control}
        label={t("pages.settings.organization.taskAutomations.search")}
        InputProps={{
          onInput: onChange,
          endAdornment: (
            <InputAdornment position="end">
              <SearchOutlinedIcon fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
    </SearchBarWrapper>
  );
};

export default SearchBar;
