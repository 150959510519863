import React, { Key, useEffect, useState } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MenuItem, TextFieldProps } from "@mui/material";
import { ColoredPoint } from "components/index";
import { StyledTextField } from "../../StyledComponents/StyledBaseFields";
import { theme } from "theme";

export type FormSelectItem = {
  key: Key;
  value: string;
  color?: string;
};

export type FormSelectProps<T extends FieldValues> = Omit<
  TextFieldProps,
  "name"
> & {
  control?: Control<T>;
  name: Path<T>;
  options: Array<string> | Array<FormSelectItem>;
  emptyOptionText?: string;
  translationPrefix?: string;
  margin?: "none" | "normal" | "dense";
  suggestion?: boolean;
  defaultValue?: string;
};

function createOptions(options: Array<string> | Array<FormSelectItem>) {
  return options.map<{ key: Key; value: string; color?: string }>((option) => {
    if (typeof option === "string") {
      return { key: option, value: option };
    }
    return option;
  });
}

export function FormSelect<T extends FieldValues>({
  name,
  control,
  options,
  emptyOptionText,
  translationPrefix,
  margin,
  suggestion,
  defaultValue,
  ...rest
}: FormSelectProps<T>): JSX.Element {
  const { t } = useTranslation();
  const [menuItems, setMenuItems] = useState<FormSelectItem[]>(
    createOptions(options)
  );

  useEffect(() => {
    setMenuItems(createOptions(options));
  }, [options]);

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
      }) => (
        <StyledTextField
          {...rest}
          id={name}
          margin={margin ?? "normal"}
          value={value ?? defaultValue ?? ""}
          onChange={onChange}
          onBlur={onBlur}
          fullWidth
          select={true}
          SelectProps={{
            MenuProps: {
              sx: {
                maxHeight: "calc(100% - 40%)",
                "& .MuiPaper-root": {
                  boxShadow: theme.shadow.standard,
                  maxWidth: "100%",
                },
              },
            },
          }}
          size="small"
          error={invalid}
          helperText={t(error?.message as string)}
          suggestion={suggestion}
        >
          {emptyOptionText && (
            <MenuItem value={null as never}>
              <em>{emptyOptionText}</em>
            </MenuItem>
          )}
          {menuItems.map(({ key, value, color }) => (
            <MenuItem key={key} value={key}>
              {color ? <ColoredPoint color={color} /> : null}
              <span>
                {translationPrefix ? t(`${translationPrefix}.${value}`) : value}
              </span>
            </MenuItem>
          ))}
        </StyledTextField>
      )}
    />
  );
}
