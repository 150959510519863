import { TFunction } from "i18next";
import { ContractChangeLogDTO } from "openapi";

export const fieldsToSkip = [
  "id",
  "teamId",
  "parentId",
  "createdAt",
  "updatedAt",
  "createdBy",
  "tasksNumber",
  "hasFile",
  "analysisData",
  "annotationData",
  "metadata",
];

export const dateFields = ["startDate", "endDate", "terminationDate"];

export const availableTranslationsByEventType = [
  ContractChangeLogDTO.event.CONTRACT_CREATED,
  ContractChangeLogDTO.event.CONTRACT_EDITED,
  ContractChangeLogDTO.event.CONTRACT_INITIATED,
  ContractChangeLogDTO.event.DOCUMENT_UPLOADED,
  ContractChangeLogDTO.event.DOCUMENT_REMOVED,
  ContractChangeLogDTO.event.CONTRACT_MOVED,
  ContractChangeLogDTO.event.SIGNATURE_INITIATED,
  ContractChangeLogDTO.event.SIGNATURE_SENT,
  ContractChangeLogDTO.event.SIGNATURE_SIGNED,
  ContractChangeLogDTO.event.SIGNATURE_DECLINED,
  ContractChangeLogDTO.event.SIGNATURE_COMPLETED,
  ContractChangeLogDTO.event.SIGNATURE_CANCELLED,
  ContractChangeLogDTO.event.SIGNATURE_REMINDER_SENT,
  ContractChangeLogDTO.event.CONTRACT_TASK_ADDED,
  ContractChangeLogDTO.event.CONTRACT_TASK_REMOVED,
  ContractChangeLogDTO.event.ATTACHMENT_ADDED,
  ContractChangeLogDTO.event.ATTACHMENT_REMOVED,
  ContractChangeLogDTO.event.MAIN_CONTRACT_CHANGED,
];

export const getTranslation = (
  t: TFunction<"translation">,
  item: string,
  key: string
) => {
  switch (key) {
    case "status":
      return t(`pages.contractDetails.general.statusOptions.${item}`);

    case "type":
      return t(`contractType.${item}`);
  }
};
