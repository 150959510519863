/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Addon } from "./Addon";
import type { TeamPermissionDto } from "./TeamPermissionDto";

export type TeamPermissionInfoDto = {
  teamId: string;
  role: TeamPermissionInfoDto.role;
  permissions: Array<TeamPermissionDto>;
  addons?: Array<Addon>;
};

export namespace TeamPermissionInfoDto {
  export enum role {
    USER = "USER",
    LIMITED_USER = "LIMITED_USER",
    OWNER = "OWNER",
  }
}
