import styled from "@emotion/styled";
import { Container as MUIContainer, ContainerProps } from "@mui/material";
import { theme } from "theme";

type Props = ContainerProps & {
  component?: string;
};

export const Container = styled(MUIContainer)<Props>`
  margin-top: 2.5rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${theme.spacing.md};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
  width: 100%;
`;
