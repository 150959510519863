import React, { useState, useEffect, useRef } from "react";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { IconButton, Popover } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { CategoryDTO, ContractTemplateV1DTO } from "openapi";
import { useTeam } from "contexts/team/hooks";
import { useLocale } from "hooks";
import {
  useContractTemplateCreateMutationV1,
  useContractTemplateUpdateMutationV1,
} from "shared/api/contract-templates";
import { Tag, TextField } from "new-components";
import { CTAButton, NewModal } from "components";
import { dateFormatter } from "constants/utils";
import { getDarkenedColor, getSoftenedColor } from "utils/color";
import { theme } from "theme";
import { Tags } from "../../../components/Header/components";
import DeleteTemplateModal from "../../../components/DeleteTemplate/DeleteTemplate";
import Preview from "./Preview";
import {
  TemplateInfo,
  TemplateNameWrapper,
  TemplateName,
  TemplateDate,
  BinIcon,
  TemplateIcon,
  CalendarIcon,
  DuplicateIcon,
  TemplateRenameIcon,
  Menu,
  MenuItem,
} from "./styles";

type TemplateCardProps = {
  template: ContractTemplateV1DTO;
  categories?: CategoryDTO[];
  onClick: () => void;
};

const TemplateCard = ({ template, categories, onClick }: TemplateCardProps) => {
  const { t } = useTranslation();
  const { organizationId } = useTeam();
  const { locale } = useLocale();
  const templateRef = useRef(template);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [newTemplateName, setNewTemplateName] = useState(template.name);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);

  const createContractTemplate = useContractTemplateCreateMutationV1();
  const updateContractTemplate = useContractTemplateUpdateMutationV1();

  useEffect(() => {
    templateRef.current = template;
  }, [template.tags]);

  const currentCategory = categories?.find(
    (cat) => cat.id === template.categoryId
  );

  const handleOnCreateDuplicate = async () => {
    try {
      await createContractTemplate.mutateAsync({
        organizationId,
        template: {
          ...templateRef.current,
          name: `${t(
            "pages.settings.organization.contractTemplates.fields.name.duplicatePrefix"
          )}${template.name}`,
          content: template.content ?? "",
          categoryId: template?.categoryId,
          tagIds: template.tags.map((tag) => tag.id),
        },
      });
      setAnchorEl(null);
      enqueueSnackbar(
        t(
          "pages.settings.tabs.contractTemplates.gallery.snackbars.duplicate.success"
        ),
        {
          variant: "success",
        }
      );
    } catch (error) {
      enqueueSnackbar(
        t(
          "pages.settings.tabs.contractTemplates.gallery.snackbars.duplicate.failed"
        ),
        {
          variant: "error",
        }
      );
    }
  };

  const handleOnRenameTemplate = async () => {
    try {
      await updateContractTemplate.mutateAsync({
        organizationId,
        templateId: template.id,
        template: {
          ...templateRef.current,
          name: newTemplateName,
        },
      });
      setAnchorEl(null);
      setShowRenameModal(false);
      enqueueSnackbar(
        t(
          "pages.settings.tabs.contractTemplates.gallery.snackbars.rename.success"
        ),
        {
          variant: "success",
        }
      );
    } catch (error) {
      enqueueSnackbar(
        t(
          "pages.settings.tabs.contractTemplates.gallery.snackbars.rename.failed"
        ),
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <div>
      <Preview htmlContent={template?.content} onClick={onClick} />

      <TemplateInfo>
        <TemplateNameWrapper>
          <TemplateName>
            <TemplateIcon />
            <span>{template.name}</span>
          </TemplateName>
          <IconButton
            onClick={(event) => setAnchorEl(event.currentTarget)}
            size="small"
          >
            <MoreHoriz fontSize="small" />
          </IconButton>
        </TemplateNameWrapper>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              padding: "8px",
              borderRadius: "4px",
              maxWidth: "200px",
            },
          }}
        >
          <Menu>
            <MenuItem onClick={handleOnCreateDuplicate}>
              <DuplicateIcon />
              {t("common.buttons.duplicate")}
            </MenuItem>
            <MenuItem onClick={() => setShowRenameModal(true)}>
              <TemplateRenameIcon />
              {t("common.buttons.rename")}
            </MenuItem>
            <MenuItem onClick={() => setShowDeleteModal(true)} remove>
              <BinIcon />
              {t("common.buttons.delete")}
            </MenuItem>
          </Menu>
        </Popover>

        <TemplateDate>
          <CalendarIcon />
          {dateFormatter(locale, template.updatedAt ?? template.createdAt)}
        </TemplateDate>

        {currentCategory && (
          <Tag
            variant="custom"
            backgroundColor={
              getSoftenedColor(currentCategory?.color) ?? theme.color.blue[200]
            }
            color={
              getDarkenedColor(currentCategory?.color) ?? theme.color.blue[600]
            }
          >
            {currentCategory?.name[locale]}
          </Tag>
        )}
        <Tags
          templateRef={templateRef}
          maxTagsCount={3}
          preselectedTags={template.tags}
        />
      </TemplateInfo>

      <DeleteTemplateModal
        open={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setAnchorEl(null);
        }}
        currentCategory={currentCategory}
        data={template}
      />

      <NewModal
        open={showRenameModal}
        handleClose={() => setShowRenameModal(false)}
        title={t(
          "pages.settings.tabs.contractTemplates.gallery.modals.rename.title"
        )}
        body={
          <div>
            <p>
              {t(
                "pages.settings.tabs.contractTemplates.gallery.modals.rename.description"
              )}
            </p>
            <TextField
              name={t(
                "pages.settings.organization.contractTemplates.fields.name.label"
              )}
              defaultValue={template.name}
              onChange={(e) =>
                setNewTemplateName(e.target.value.toString().trim())
              }
              size="sm"
              fullWidth
            />
          </div>
        }
        footer={
          <>
            <CTAButton
              name={t("common.buttons.cancel")}
              variant="secondary"
              onClick={() => setShowRenameModal(false)}
            />
            <CTAButton
              name={t("common.buttons.rename")}
              variant="primary"
              onClick={handleOnRenameTemplate}
            />
          </>
        }
      />
    </div>
  );
};

export default React.memo(TemplateCard);
