import React from "react";
import { useTranslation } from "react-i18next";
import { useTeam } from "contexts/team/hooks";
import { useImportViaEmailQuery } from "shared/api";
import { Description } from "components/Modal/styles";
import { Note, Wrapper } from "./styles";
import CopyField from "components/CopyField/CopyField";

const UploadViaEmail = () => {
  const { t } = useTranslation();
  const { selectedTeamId } = useTeam();
  const { data: emailLink = "", isLoading } =
    useImportViaEmailQuery(selectedTeamId);

  return (
    <Wrapper>
      <Description>{t("importViaEmail.subtitle")}</Description>
      <CopyField value={emailLink} disabled={isLoading} valueAsLink />
      <div>
        <Note>- {t("importViaEmail.bulletpoints.first")}</Note>
        <Note>- {t("importViaEmail.bulletpoints.second")}</Note>
      </div>
    </Wrapper>
  );
};

export default UploadViaEmail;
