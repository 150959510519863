import styled from "@emotion/styled";

export const ColorPointComponent = styled.div`
  display: inline-block;
  height: 0.75rem;
  min-width: 0.75rem;
  width: 0.75rem;
  background-color: ${(prop) => prop.color ?? "#e2e8f0"};
  border-radius: 50%;
  margin-right: 0.3rem;
`;
