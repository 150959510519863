// @TODO: needs to be refactored
import React, {
  forwardRef,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ICellEditorParams } from "ag-grid-community";
import { ContractFieldDTOV1 } from "../../../openapi";
import { TextAreaCellEditor, TextCellEditor } from "./style";
import { CellValue } from "../helpers";

export const CustomCellEditorType: Record<ContractFieldDTOV1.type, unknown> = {
  TEXTFIELD: "text",
  TEXTAREA: "textarea",
  NUMBER: "number",
  LIST: "list",
  LINK: "text",
  DATE: "date",
  AMOUNT: "amount",
  COUNTRY: "country",
  DURATION: "text",
  FORMULA: "text",
  CONTACT: "contact",
};

interface Props extends ICellEditorParams {
  type?: string;
  isTitle?: boolean;
  matchedFieldValue?: string;
  isCustomField?: boolean;
  value: CellValue;
}

export const CustomCellEditor = forwardRef((props: Props, ref) => {
  const { matchedFieldValue, isCustomField, value: cellValue } = props;
  const [value, setValue] = useState<string>(
    (cellValue?.value ?? cellValue ?? matchedFieldValue) as string
  );
  const refInput = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event: MouseEvent) {
    if (refInput.current && !refInput.current.contains(event.target as Node)) {
      props.api.stopEditing();
    }
  }

  // focus on the input
  useEffect(() => {
    // get ref from React component
    refInput?.current?.focus();
  }, []);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        const trimmedValue = value?.trim();
        return isCustomField ? { value: trimmedValue } : trimmedValue;
      },
    };
  });

  return (
    <>
      {props.type === "textarea" ? (
        <TextAreaCellEditor
          ref={refInput as RefObject<HTMLTextAreaElement>}
          value={value}
          rows={4}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            setValue(event.target.value)
          }
        />
      ) : (
        <TextCellEditor
          isTitle={props.isTitle as boolean}
          ref={refInput as RefObject<HTMLInputElement>}
          value={value}
          type={props.type}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value);
          }}
        />
      )}
    </>
  );
});
CustomCellEditor.displayName = "CustomCellEditor";
