import React, { useState } from "react";
import { Wrapper } from "./styles";
import { CTAButton } from "components";
import { useTranslation } from "react-i18next";

export type CollapsibleProps = {
  itemsToShow?: number;
  children?: JSX.Element | JSX.Element[] | null;
  gap?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
};

const Collapsible = ({ children, gap, itemsToShow = 5 }: CollapsibleProps) => {
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation();

  if (!children) return null;

  const handleViewAll = () => {
    setShowAll((prevState) => !prevState);
  };

  const childrenArray = React.Children.toArray(children);
  const difference = childrenArray.length - itemsToShow;

  return (
    <Wrapper gap={gap}>
      {childrenArray.slice(0, showAll ? childrenArray.length : itemsToShow)}
      {childrenArray.length > itemsToShow && (
        <CTAButton
          variant="tertiary"
          onClick={handleViewAll}
          name={
            showAll
              ? t(
                  "pages.settings.organization.permissionChangeLog.buttons.hideAll"
                )
              : t(
                  "pages.settings.organization.permissionChangeLog.buttons.showAll",
                  { difference }
                )
          }
          size={"tiny"}
          label="view-more-button"
        />
      )}
    </Wrapper>
  );
};

export default Collapsible;
