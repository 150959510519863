import styled from "@emotion/styled";
import { Description as MUIDescriptionIcon } from "@mui/icons-material";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg};
  width: 100%;
`;

export const DescriptionIcon = styled(MUIDescriptionIcon)`
  color: ${theme.color.blue[600]};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg};
`;
