import { Menu } from "@mui/material";
import React, { FC, ReactElement, useEffect } from "react";
import { ToolbarButton } from "./ToolbarButton";
import { ToolbarSpecificButtonProps } from "./types";
import { useTranslation } from "react-i18next";
import { theme } from "theme";

import MoreVertIcon from "@mui/icons-material/MoreVert";

export type AdditionalActionsButtonProps = {
  children: ReactElement<ToolbarSpecificButtonProps<never>>[];
};

export const AdditionalActionsButton: FC<AdditionalActionsButtonProps> = ({
  children,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // the useEffect makes sure the menu is closed when the additonal actions button becomes invisible
  useEffect(() => {
    if (!anchorEl) return;

    const observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0) {
          continue;
        }
        setAnchorEl(null);
      }
    });

    observer.observe(anchorEl);
    return () => observer.unobserve(anchorEl);
  }, [anchorEl]);

  return (
    <>
      <ToolbarButton
        tooltip={t("pdfViewer.toolbar.additionalActions")}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        collapses="negative-collapse"
      >
        <MoreVertIcon />
      </ToolbarButton>
      <Menu
        anchorEl={anchorEl}
        container={document.fullscreenElement ?? document.body}
        open={open}
        onClose={() => setAnchorEl(null)}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: theme.shadow.standard,
          },
        }}
      >
        {children}
      </Menu>
    </>
  );
};
