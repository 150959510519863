import styled from "@emotion/styled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { theme } from "theme";

export const ImportStatusHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${theme.font.weight.bold};
  font-size: ${theme.font.size.subheading};
  line-height: 24px;
  overflow-wrap: break-word;
  hyphens: auto;
`;

export const ImportedIcon = styled(CheckCircleIcon)`
  color: ${theme.color.green[800]};
  margin-right: ${theme.spacing.sm};
`;
export const FailedImportIcon = styled(ErrorIcon)`
  color: ${theme.color.red[800]};
  margin-right: ${theme.spacing.sm};
`;
