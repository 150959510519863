import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { OpenWith } from "@mui/icons-material";
import { useStyles as useStyledButtons } from "components/StyledComponents/StyledBaseButtons";

type Props = {
  contractFile?: Blob | null | undefined;
};

const FullscreenToggle = ({ contractFile }: Props) => {
  const { t } = useTranslation();
  const buttonClasses = useStyledButtons();

  const openPdfFullscreen = () => {
    if (contractFile) {
      const url = URL.createObjectURL(contractFile);
      window.open(url);
    }
  };

  return (
    <Button
      id="fullscreenBtn"
      onClick={() => openPdfFullscreen()}
      className={buttonClasses.previewHeaderButton}
    >
      <OpenWith fontSize="small" sx={{ marginRight: ".4rem" }} />
      {t("pages.contractDetails.buttons.fullscreen")}
    </Button>
  );
};

export default FullscreenToggle;
