import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIcon from "assets/svg/drag-icon.svg?react";

import { FormTextField } from "components";
import { theme } from "theme";

export const Container = styled(Box)`
  padding: ${theme.spacing.sm};
`;

export const Header = styled(Box)`
  margin-bottom: ${theme.spacing.lg};
`;

export const TitleWrapper = styled.h3`
  line-height: 1.5rem;
  font-weight: ${theme.font.weight.medium};
  color: ${theme.color.gray[900]};
  margin: 0 0 ${theme.spacing.md};
`;

export const SubTitleWrapper = styled.h4`
  line-height: 1.5rem;
  font-weight: ${theme.font.weight.bold};
  color: ${theme.color.gray[900]};
  margin: 0;
`;

export const DescriptionWrapper = styled.p`
  margin: 0;
`;

export const HintWrapper = styled.p`
  line-height: 1.5rem;
  font-weight: ${theme.font.weight.normal};
  color: ${theme.color.gray[700]};
  font-size: ${theme.font.size.caption};
  margin: 0;
`;

export const SwitcherWrapper = styled.div`
  width: 100%;
  margin-bottom: ${theme.spacing.xl};
`;

export const TextArea = styled(FormTextField)`
  margin-bottom: ${theme.spacing.lg};
`;

export const SignersField = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${theme.spacing.sm};
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  & .MuiFormControl-root {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const RemoveIcon = styled(DeleteIcon)`
  align-self: center;
  margin-left: ${theme.spacing.sm};

  :hover {
    opacity: 0.8;
    color: ${theme.color.red[600]};
    cursor: pointer;
  }
`;

export const AddButton = styled(Button)`
  margin: 0 0 ${theme.spacing.xl};
  padding-left: 0;
`;

export const AddIcon = styled(AddCircleIcon)`
  margin-right: ${theme.spacing.sm};
  color: ${theme.color.gray[700]};
`;

export const AddDescription = styled.span`
  color: ${theme.color.gray[700]};
`;

export const FieldLabel = styled.p`
  margin: ${theme.spacing.md} 0 0;
`;

export const Signatory = styled.div<{ dragging?: boolean; isOver?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.25s ease-in-out;

  ${({ dragging }) =>
    dragging &&
    `
      transition: background-color 0.25s ease-in-out;
      background-color: ${theme.color.gray[200]}
  `}

  ${({ isOver }) =>
    isOver &&
    `
    transition: background-color 0.25s ease-in-out;
    background-color: ${theme.color.blue[100]};
  `}
`;

export const DragIndicatorIcon = styled(DragIcon)<{
  disabled?: boolean;
}>`
  transition: color 0.25s ease-in-out;
  outline: none;
  margin-right: ${theme.spacing.md};

  :hover {
    cursor: grab;
    color: ${theme.color.blue[600]};
  }
`;

export const QesPreviewDescriptionContainer = styled.div`
  padding: 0 0 1rem 0;
`;
