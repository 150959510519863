import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { UploadTypeEnum } from "shared/enums/contractUpload.enum";
import ContractUpload from "../ContractUpload";
import NewModal from "components/Modal/NewModal";
import { theme } from "theme";

type ContractUploadModalProps = {
  open: boolean;
  handleClose: () => void;
};

const ContractUploadModal = ({
  open,
  handleClose,
}: ContractUploadModalProps) => {
  const { t } = useTranslation();
  const [isDragActive, setIsDragActive] = useState(false);

  return (
    <NewModal
      icon={<UploadFileIcon />}
      title={t("contractUpload.title")}
      open={open}
      handleClose={handleClose}
      headerProps={{
        style: {
          transition: `background-color 0.25s ease-in-out`,
          backgroundColor: isDragActive ? `${theme.color.blue[200]}80` : "",
        },
      }}
      body={
        <ContractUpload
          uploadType={UploadTypeEnum.CREATE_CONTRACT}
          handleClose={handleClose}
          setIsDragActive={setIsDragActive}
        />
      }
      bodyProps={{
        noPadding: true,
      }}
      fullWidth
      disableBackdropClick
    />
  );
};

export default ContractUploadModal;
