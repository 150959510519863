/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RoleChange = {
  action: RoleChange.action;
  id: string;
};

export namespace RoleChange {
  export enum action {
    ALLOWED = "ALLOWED",
    DENIED = "DENIED",
  }
}
