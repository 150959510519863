import styled from "@emotion/styled";
import { theme } from "theme";
import { Link } from "react-router-dom";

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xl};
  padding: 0 ${theme.spacing.xs};
`;

export const Title = styled.div`
  font-weight: ${theme.font.weight.semiBold};
`;

export const Description = styled.div``;

export const URL = styled.div`
  font-family: monospace;
`;

export const DateLog = styled(Link)`
  color: ${theme.color.gray[600]};

  :hover {
    color: ${theme.color.indigo[600]};
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const EventList = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  flex-wrap: wrap;
`;

export const TableWrapper = styled.div`
  margin: ${theme.spacing.md} ${theme.spacing.xxs};
`;

export const EventOptionLabel = styled.span`
  font-family: monospace;
  font-size: ${theme.font.size.caption};
  color: ${theme.color.gray[700]};
  background-color: ${theme.color.blue[200]};
  padding: ${theme.spacing.xs};
  text-transform: lowercase;
  width: fit-content;
`;

export const SectionTitle = styled.div<{ error?: boolean }>`
  margin: ${theme.spacing.xl} 0;
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.bold};

  ${({ error }) =>
    error &&
    `
    color: ${theme.color.red[700]};
  `}
`;

export const Status = styled.span<{ active?: boolean; variation?: string }>`
  font-weight: ${theme.font.weight.semiBold};
  ${({ active }) =>
    active
      ? `color: ${theme.color.green[700]};`
      : `color: ${theme.color.gray[500]};`}
  ${({ variation }) =>
    variation === "success" && `color: ${theme.color.green[700]}`};
  ${({ variation }) =>
    variation === "error" && `color: ${theme.color.red[700]}`};
  ${({ variation }) =>
    variation === "retry" && `color: ${theme.color.peach[700]}`};
`;

export const SnippetTitle = styled.div`
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.bold};
  text-transform: capitalize;
  margin: ${theme.spacing.xl} 0;
`;
