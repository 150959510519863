import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { theme } from "theme";

const BodyWrapper = styled.div<{ noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  ${({ noPadding }) =>
    noPadding
      ? `padding: 0 0 ${theme.spacing.xl};`
      : `padding: 0 ${theme.spacing.xl} ${theme.spacing.xl};`}
`;

export type CardCenteredBodyWrapperProps = {
  textKey: string;
  noHorizontalPadding?: boolean;
};

const CardBodyWrapper = ({
  textKey,
  noHorizontalPadding,
}: CardCenteredBodyWrapperProps) => {
  const { t } = useTranslation();
  return (
    <BodyWrapper
      noPadding={noHorizontalPadding}
      data-testid="card-centered-body-wrapper"
    >
      {t(textKey)}
    </BodyWrapper>
  );
};

export default CardBodyWrapper;
