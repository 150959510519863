import React from "react";
import { Control, Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { ContractDTOV1 } from "openapi";
import { Container, Link } from "./styles";
import { useLocation } from "react-router-dom";
import { routePaths } from "constants/";
import { AutoCompleteSelect } from "components/FormItems";

type Props = {
  control: unknown;
  contracts: ContractDTOV1[];
  onChange: (e: { key: string; value: string }) => void;
  disabled?: boolean;
  handleOnModalClose: () => void;
};

const ContractLinkComponent = ({
  control,
  contracts,
  onChange: handleOnLinkChange,
  disabled,
  handleOnModalClose,
}: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isContractView = location.pathname.includes("contracts");
  const isTaskView = location.pathname.includes("task");

  const onClose = () => isContractView && isTaskView && handleOnModalClose();
  const isContractSelected = useWatch({
    name: "contractId",
    control: control as Control,
  }) as string;

  const contractsOptions = contracts
    .map((contract) => {
      return {
        key: contract.id,
        value: contract.name,
      };
    })
    .sort((a, b) => a.value.localeCompare(b.value));

  const contract = contracts.find(
    (contract) => contract.id === isContractSelected
  );

  const currentContractLink = `${routePaths.CONTRACTS}/${
    contract?.id ?? ""
  }/tasks`;

  return (
    <Container>
      <>
        <Controller
          name="contractId"
          control={control as Control}
          disabled={disabled}
          render={({ field }) => (
            <AutoCompleteSelect
              {...field}
              dataCy="taskToContractsAutocompleteList"
              id="contractsAutocompleteList"
              options={contractsOptions}
              onSelect={(value: { key: string; value: string }) => {
                handleOnLinkChange(value);
              }}
              label={t("pages.tasks.modal.fields.linkContract")}
            />
          )}
        />
      </>
      {isContractSelected && (
        <>
          <Link to={currentContractLink ?? ""} onClick={onClose}>
            <Tooltip title={t("pages.tasks.modal.visitContractLink")}>
              <LaunchIcon fontSize="small" />
            </Tooltip>
          </Link>
        </>
      )}
    </Container>
  );
};

export default ContractLinkComponent;
