import * as Yup from "yup";

export function setYupLanguage() {
  Yup.setLocale({
    mixed: {
      required: "common.validation.required",
    },
    array: {
      min: "common.validation.required",
    },
    string: {
      email: "common.validation.validEmail",
    },
  });
}
