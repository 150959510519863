import styled from "@emotion/styled";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { theme } from "theme";

export const Button = styled(ListItemButton)`
  border-radius: ${theme.borderRadius};
  ${({ active }: { active?: string }) =>
    active === "true" &&
    `
    background-color: ${theme.color.blue[700]}14;
  `}
`;

export const Icon = styled(ListItemIcon)`
  color: ${theme.color.gray[700]};

  ${({ active }: { active?: string }) =>
    active === "true" &&
    `
    color: ${theme.color.blue[600]};
  `}
`;

export const Label = styled(ListItemText)`
  color: ${theme.color.gray[700]};
  font-size: ${theme.font.size.body};

  ${({ active }: { active?: string }) =>
    active === "true" &&
    `
    color: ${theme.color.blue[600]};
  `}
`;
