import styled from "@emotion/styled";
import { theme } from "theme";
import AIIcon from "assets/svg/ai.svg?react";
import AIFormulaIcon from "assets/svg/ai-formula.svg?react";
import UsedIcon from "assets/svg/used.svg?react";

export const Menu = styled.div`
  border: 1px solid ${theme.color.red[600]};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
`;

export const StyledAiIcon = styled(AIIcon)`
  background-color: ${theme.color.purple[200]};
  color: ${theme.color.purple[900]};
  margin-top: ${theme.spacing.xxs};
  border-radius: 2.5px;
  max-width: ${theme.font.size.h6};
  max-height: ${theme.font.size.h6};
  margin-right: ${theme.spacing.xs};
`;

export const StyledAiFormulaIcon = styled(AIFormulaIcon)`
  background-color: ${theme.color.purple[200]};
  color: ${theme.color.purple[900]};
  margin-top: ${theme.spacing.md};
  border-radius: 2.5px;
  max-width: ${theme.font.size.h6};
  max-height: ${theme.font.size.h6};
  margin-right: ${theme.spacing.xs};
`;

export const StyledUsedIcon = styled(UsedIcon)`
  background-color: ${theme.color.green[100]};
  color: ${theme.color.green[900]};
  margin-top: ${theme.spacing.md};
  border-radius: 2.5px;
  max-width: ${theme.font.size.h6};
  max-height: ${theme.font.size.h6};
  margin-right: ${theme.spacing.xs};
`;

export const TooltipRow = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
  font-weight: ${theme.font.weight.semiBold}
  margin-top: ${theme.spacing.sm};
  margin-bottom: ${theme.spacing.sm};

  em {
    display: inline;
    font-style:normal
  }
  span {
    display: inline;
    font-weight: ${theme.font.weight.normal};
  }
`;

export const tooltipStyles = {
  tooltip: {
    fontWeight: `${theme.font.weight.semiBold}`,
    backgroundColor: "#364FA6",
    color: "#F9F6EE",
    fontSize: `${theme.font.size.body}`,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    borderRadius: `${theme.labelBorderRadius}`,
    maxWidth: "313px",
    maxHeight: "auto",
  },
};
