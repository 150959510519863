import React, { FC } from "react";
import Grid from "@mui/material/Grid";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { useLocale } from "hooks/GlobalStateHooks";
import {
  ContactDatapointDefinitionDTO,
  ContactTypeDTO,
  ContractDTOV1,
  ContractFieldDTOV1,
  ContractSectionDTOV1,
} from "openapi";
import { DataPoint } from "components/Datapoints/DataPoint";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import { ContactDataDTO } from "pages/Contacts/ContactDataDTO";

type ContractSectionEditProps = {
  section: ContractSectionDTOV1;
  fields: ContractFieldDTOV1[];
  contract: ContractDTOV1;
  editable: boolean;
  isContractDataFetching?: boolean;
  fetchData?: () => Promise<void>;
  contactTypes: ContactTypeDTO[];
  contactDefinitions: ContactDatapointDefinitionDTO[];
  contacts: ContactDataDTO[];
  refetchContacts: () => Promise<unknown>;
};

const Section: FC<ContractSectionEditProps> = ({
  section,
  fields,
  contract,
  editable,
  isContractDataFetching,
  fetchData,
  contactTypes,
  contactDefinitions,
  contacts,
  refetchContacts,
}) => {
  const { locale } = useLocale();

  return (
    <CardWrapper>
      {editable ? (
        <Grid container>
          <Grid item xs={6} md={6}>
            <CardHeaderWrapper title={section.name[locale]} />
          </Grid>
        </Grid>
      ) : (
        <CardHeaderWrapper title={section.name[locale]} />
      )}
      <Grid container spacing={1.25} sx={{ marginTop: "0.5rem" }}>
        {section.fields.map((field) => {
          return (
            <DataPoint
              key={field.id}
              id={field.id}
              definitions={fields}
              values={contract.fields}
              editable={editable}
              isLoading={isContractDataFetching}
              fetchData={fetchData}
              contactTypes={contactTypes}
              contactDefinitions={contactDefinitions}
              contacts={contacts}
              refetchContacts={refetchContacts}
              categoryId={contract.categoryId}
              teamId={contract.teamId}
              contractId={contract.id}
            />
          );
        })}
      </Grid>
    </CardWrapper>
  );
};

export default Section;
