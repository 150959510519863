/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ContractTransferResultDTO = {
  contractId: string;
  status: ContractTransferResultDTO.status;
  reason?: string;
};

export namespace ContractTransferResultDTO {
  export enum status {
    SUCCESS = "success",
    FAILURE = "failure",
  }
}
