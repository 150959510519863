/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DatapointConfigurationDTO } from "./DatapointConfigurationDTO";
import type { I18nDto } from "./I18nDto";

export type ContactDatapointDefinitionDTO = {
  id: string;
  type: ContactDatapointDefinitionDTO.type;
  visibleId: string;
  name: I18nDto;
  configuration?: DatapointConfigurationDTO;
};

export namespace ContactDatapointDefinitionDTO {
  export enum type {
    TEXTFIELD = "TEXTFIELD",
    TEXTAREA = "TEXTAREA",
    NUMBER = "NUMBER",
    DATE = "DATE",
    LIST = "LIST",
    AMOUNT = "AMOUNT",
    LINK = "LINK",
    COUNTRY = "COUNTRY",
    FORMULA = "FORMULA",
  }
}
