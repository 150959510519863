import styled from "@emotion/styled";
import { theme } from "theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  .MuiFormControl-root {
    margin: 0;
  }
`;

export const Row = styled.div<{
  alignItems?: string;
  justifyItems?: string;
  maxWidth?: string;
  gap?: string;
  verticalMargin?: string;
}>`
  display: flex;
  justify-content: ${(props) => props.justifyItems || "space-between"};
  align-items: ${(props) => props.alignItems || "center"};
  max-width: ${(props) => props.maxWidth || "100%"};
  gap: ${(props) => props.gap || theme.spacing.md};
  margin-top: ${(props) => props.verticalMargin || theme.spacing.md};
  margin-bottom: ${(props) => props.verticalMargin || theme.spacing.md};
  min-height: 40px;
`;

export const FormOffsetFieldWrapper = styled.div`
  max-width: 80px;
`;
