import styled from "@emotion/styled";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { theme } from "theme";

export const ImportDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.lg} ${theme.spacing.sm};
  background: ${({ index }: { index: number }) =>
    index % 2 === 0 ? "none" : theme.color.gray[200]};
`;

export const ImportStatus = styled.div`
  margin: 0 ${theme.spacing.sm};
`;

export const ImportedContractContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 300px;
`;

export const ContractTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const ImportedContractIcon = styled(InsertDriveFileIcon)`
  color: ${theme.color.green[400]};
  margin-right: ${theme.spacing.sm};
  ${({ status }: { status: "success" | "failed" }) =>
    status === "success"
      ? `color: ${theme.color.green[400]};`
      : `color: ${theme.color.red[400]};`}
`;
