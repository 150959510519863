import React, { RefObject, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  ContractDTOV1,
  ContractViewItemDto,
  TaskNewDTO,
  TeamMemberDto,
} from "openapi";
import { useLocale, useUserInfo } from "hooks/GlobalStateHooks";
import { transformRowData } from "./helpers";
import { getColumnDataConfig } from "./columnData";
import { Box } from "@mui/material";
import { patchScrollbar } from "components/AgGridBaseTable/utils";
import { useTeam } from "contexts/team/hooks";
import { Loader } from "components";
import {
  useContractsQuery,
  useFieldsQuery,
  useTasksQuery,
  useTeamMembersQuery,
} from "shared/api";
import { theme } from "theme";
import { getDaysUntilDate, isDatePast } from "utils/helpers";
import { GridApi, RowClassParams } from "ag-grid-community";
import { getLocale } from "../../../../components/AgGridBaseTable/locale";
import { dateFormats } from "constants/utils";
import { getActiveViewDeterminerFunction } from "../../../../contexts/grid/helpers";
import { useOverviewActions } from "../../../../contexts/grid/hooks";
import { useContractViewsQuery } from "../../../../shared/api/views";
import { getSidebarOptions } from "../../../Contracts/contractTable.config";

type Props = {
  gridRef: React.MutableRefObject<AgGridReact | undefined>;
  contractId?: string;
  viewType?: string;
  contactId?: string;
  setTasksNumber?: () => void;
  viewColumns: string[];
};

const TaskGrid = ({ gridRef, contractId, contactId, viewColumns }: Props) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { selectedTeamId, organizationId } = useTeam();
  const { gridProps, overview } = useOverviewActions();
  const { data: views } = useContractViewsQuery(
    selectedTeamId,
    ContractViewItemDto.type.TASK
  );

  const { data: contracts } = useContractsQuery(selectedTeamId);
  const { data: teamMembers } = useTeamMembersQuery(selectedTeamId);
  const { data: fields } = useFieldsQuery(organizationId);

  const contract = contracts?.find((item) => item.id === contractId);
  const teamIdFromContract = contract?.teamId;

  const {
    data: tasks,
    isLoading,
    isError,
  } = useTasksQuery(
    // different team id depending on where are tasks fetched from Contract details page or Tasks page.
    teamIdFromContract ? teamIdFromContract : selectedTeamId,
    contactId,
    organizationId,
    contractId
  );

  const { teamById, userInfo } = useUserInfo();
  const userDateFormat = userInfo?.dateFormat || dateFormats.EU_DOTS;

  const transformedRowData = useMemo(() => {
    if (!fields) return;
    return transformRowData(
      tasks as TaskNewDTO[],
      contracts as ContractDTOV1[],
      teamMembers?.members as TeamMemberDto[],
      fields,
      locale,
      t,
      contractId
    );
  }, [tasks, contractId, contactId, contracts, teamMembers]);

  if (isError) return <div>Error</div>;
  if (isLoading) return <Loader />;

  let columnData = getColumnDataConfig(t, locale, teamById, userDateFormat);

  if (viewColumns) {
    columnData = getColumnDataConfig(
      t,
      locale,
      teamById,
      userDateFormat
    ).filter((item) => viewColumns.includes(item.field));
  }

  const hideClosedTasks = (api: GridApi) => {
    api.setFilterModel({
      status: {
        type: "set",
        values: [TaskNewDTO.status.OPEN, TaskNewDTO.status.IN_PROGRESS],
      },
    });
  };

  return (
    <Box className="ag-theme-alpine" sx={{ flex: 1 }}>
      <AgGridReact
        animateRows
        embedFullWidthRows
        suppressCellFocus
        suppressContextMenu
        ref={gridRef as unknown as RefObject<AgGridReact>}
        columnDefs={columnData}
        rowData={transformedRowData}
        sideBar={getSidebarOptions(t)}
        localeText={{
          ...getLocale(t),
          noRowsToShow: t("pages.tasks.table.noRowsToShow"),
          blanks: t("pages.tasks.table.blanks"),
        }}
        enableCellChangeFlash={true}
        onGridReady={({ api }) => {
          patchScrollbar();
          api.sizeColumnsToFit();
          hideClosedTasks(api);

          overview.dispatch({
            type: "initialize",
            value: {
              gridRef: gridRef ?? undefined,
              determineActiveView: getActiveViewDeterminerFunction(
                views || { items: [] }
              ),
            },
          });
        }}
        gridOptions={{
          defaultColDef: {
            resizable: true,
            sortable: true,
            menuTabs: ["filterMenuTab"],
          },
          accentedSort: true,
          getRowStyle: ({
            data,
          }: RowClassParams<TaskNewDTO & { dueDate: string }>) => {
            const isDueDatePast = isDatePast(data?.dueDate);
            const daysUntilDate = getDaysUntilDate(data?.dueDate, 1);
            const isClosed = data?.status === TaskNewDTO.status.CLOSED;
            if (isDueDatePast && !isClosed) {
              return { background: theme.color.red[100] };
            }
            if ((daysUntilDate === 0 || daysUntilDate) && !isClosed) {
              return { background: theme.color.yellow[100] };
            }
          },
        }}
        {...gridProps}
      />
    </Box>
  );
};

export default TaskGrid;
