import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ChipsWrapper,
  TitleLink,
  TitleWrapper,
  ToggleButton,
  ToggleMarker,
} from "./styles";

import routePaths from "constants/routePaths";
import { Chip } from "components";
import { getColor } from "components/Chip/helpers";
import { theme } from "theme";
import { useLocale } from "hooks";
import { CategoryDTO, TaskAutomationDTO } from "openapi";

export type ExpandableProps = {
  item: TaskAutomationDTO;
  maxVisibleItems: number;
  categories: (CategoryDTO | undefined)[];
};

const Expandable = ({ maxVisibleItems, item, categories }: ExpandableProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const { locale } = useLocale();

  if (!item) return <></>;

  const visibleItems = expanded
    ? categories
    : categories.slice(0, maxVisibleItems);
  const hiddenItems = expanded ? [] : categories.slice(maxVisibleItems);
  const buttonName = `+${hiddenItems.length} ${t("common.buttons.more")}`;

  return (
    <>
      <TitleWrapper>
        <TitleLink
          to={`${routePaths.SETTINGS_TASK_AUTOMATION}/${item.id}`}
          className="name"
          aria-label="task-automation"
          data-order-key={item.title}
        >
          {item.title}
        </TitleLink>
        {categories.length > maxVisibleItems ? (
          <ToggleButton onClick={() => setExpanded(!expanded)}>
            <ToggleMarker isOpen={expanded} />
          </ToggleButton>
        ) : null}
      </TitleWrapper>
      <ChipsWrapper>
        {visibleItems.map((categoryItem) => (
          <Chip
            key={categoryItem?.id}
            blendMode="soft"
            color={getColor(categoryItem?.color || theme.color.blue[600])}
          >
            {categoryItem?.name[locale]}
          </Chip>
        ))}
        {hiddenItems.length > 0 ? (
          <Button onClick={() => setExpanded(!expanded)}>{buttonName}</Button>
        ) : null}
      </ChipsWrapper>
    </>
  );
};

export default Expandable;
