import React from "react";
import { ChangeLogDataSimpleItemType } from "../../types";
import EditEntityItem from "../EditEntityItem/EditEntityItem";
import { CategoryDTO } from "openapi";
import { useLocale } from "hooks";
import TreeIcon from "assets/svg/tree-icon.svg?react";
import { useTranslation } from "react-i18next";

export type CategoryFieldType = {
  fieldData: ChangeLogDataSimpleItemType;
  categories: CategoryDTO[];
  variant?: "row";
};

const CategoryField = ({
  fieldData,
  categories,
  variant,
}: CategoryFieldType) => {
  const { locale } = useLocale();
  const { t } = useTranslation();

  const oldCategory = categories?.find((item) => item.id === fieldData?.from);
  const oldCategoryName = oldCategory?.name[locale];
  const oldCategoryBg = oldCategory?.color;
  const newCategory = categories.find((item) => item.id === fieldData?.to);
  const newCategoryName = newCategory?.name[locale];
  const newCategoryBg = newCategory?.color;

  const fieldName = t("pages.import.labels.categoryId");

  const categoryNames = {
    from: { value: oldCategoryName },
    to: { value: newCategoryName },
  };
  return (
    <EditEntityItem
      data={categoryNames}
      fieldName={fieldName}
      oldBg={oldCategoryBg}
      newBg={newCategoryBg}
      fieldIcon={<TreeIcon />}
      variant={variant}
    />
  );
};

export default CategoryField;
