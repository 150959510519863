import React from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { categoryColors } from "components/ColoredPoint/colorsConfig";
import { Wrapper, Select, MenuItem, ColorIcon } from "./styles";
import { FormControl, InputLabel } from "@mui/material";
import { theme } from "theme";

export type ColorDropdownProps<T extends FieldValues> = {
  control: Control<T>;
  name: string;
  onFocus?: () => void;
  label?: string;
  defaultValue?: PathValue<T, Path<T>>;
};

const ColorDropdown = <T extends FieldValues>({
  onFocus,
  name,
  control,
  label,
  defaultValue,
}: ColorDropdownProps<T>) => {
  const colorOptions = categoryColors.map((item) => {
    return {
      key: item.hexaCode,
      value: item.hexaCode,
      component: <ColorIcon color={item.hexaCode} />,
    };
  });

  const randomIndex = Math.floor(Math.random() * colorOptions.length);

  return (
    <Wrapper>
      <Controller
        name={name as Path<T>}
        control={control}
        defaultValue={
          defaultValue ??
          (colorOptions[randomIndex].value as PathValue<T, Path<T>>)
        }
        render={({ field }) => (
          <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select
              {...field}
              label={label}
              displayEmpty
              value={field?.value as string}
              onFocus={onFocus}
              MenuProps={{
                PaperProps: {
                  style: {
                    boxShadow: theme.shadow.standard,
                  },
                },
              }}
            >
              {colorOptions.map((item) => (
                <MenuItem key={item.key} value={item.value}>
                  {item.component}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </Wrapper>
  );
};

export default ColorDropdown;
