import { ColumnState } from "ag-grid-community";
import { ContractViewItemDto } from "openapi";

const getTemporarySearchKey = (type: ContractViewItemDto.type) => {
  const TEMPORARY_STATE_KEY = "temporary-search";
  return `${type}-${TEMPORARY_STATE_KEY}`;
};

const getTemporaryStateKey = (type: ContractViewItemDto.type) => {
  const TEMPORARY_STATE_KEY = "temporary-column-state";
  return `${type}-${TEMPORARY_STATE_KEY}`;
};

const getTemporaryFilterKey = (type: ContractViewItemDto.type) => {
  const TEMPORARY_FILTER_KEY = "temporary-filter-model";
  return `${type}-${TEMPORARY_FILTER_KEY}`;
};

const getTemporaryActiveViewKey = (type: ContractViewItemDto.type) => {
  const ACTIVE_VIEW_KEY = "active-view";
  return `${type}-${ACTIVE_VIEW_KEY}`;
};

export const clearTemporaryState = (type: ContractViewItemDto.type) => {
  sessionStorage.removeItem(getTemporaryStateKey(type));
  sessionStorage.removeItem(getTemporaryFilterKey(type));
  sessionStorage.removeItem(getTemporaryActiveViewKey(type));
  sessionStorage.removeItem(getTemporarySearchKey(type));
};

export const clearTemporaryStates = () => {
  for (const type of Object.values(ContractViewItemDto.type)) {
    clearTemporaryState(type);
  }
};

export const persistTemporaryState = (
  type: ContractViewItemDto.type,
  columnState: ColumnState[] | null
) => {
  if (!columnState) {
    sessionStorage.removeItem(getTemporaryStateKey(type));
    return;
  }
  sessionStorage.setItem(
    getTemporaryStateKey(type),
    JSON.stringify(columnState)
  );
};

export const retrieveTemporaryState = (
  type: ContractViewItemDto.type
): ColumnState[] | null => {
  const state = sessionStorage.getItem(getTemporaryStateKey(type));
  if (!state) {
    return null;
  }
  return JSON.parse(state) as ColumnState[];
};

export const persistTemporaryFilter = (
  type: ContractViewItemDto.type,
  columnState: Record<string, unknown> | null
) => {
  if (!columnState) {
    sessionStorage.removeItem(getTemporaryFilterKey(type));
    return;
  }
  sessionStorage.setItem(
    getTemporaryFilterKey(type),
    JSON.stringify(columnState)
  );
};

export const retrieveTemporaryFilter = (
  type: ContractViewItemDto.type
): Record<string, unknown> => {
  const state = sessionStorage.getItem(getTemporaryFilterKey(type));
  if (!state) {
    return {};
  }
  return JSON.parse(state) as Record<string, unknown>;
};

export const persistActiveView = (
  type: ContractViewItemDto.type,
  id: string | null
) => {
  if (!id) {
    sessionStorage.removeItem(getTemporaryActiveViewKey(type));
    return;
  }
  sessionStorage.setItem(getTemporaryActiveViewKey(type), id);
};

export const retrieveActiveView = (
  type: ContractViewItemDto.type
): string | null => {
  const state = sessionStorage.getItem(getTemporaryActiveViewKey(type));
  if (!state) {
    return null;
  }
  return state;
};

export const retrieveTemporarySearch = (type: ContractViewItemDto.type) => {
  const state = sessionStorage.getItem(getTemporarySearchKey(type));
  if (!state) {
    return "";
  }
  return state;
};

export const persistTemporarySearch = (
  type: ContractViewItemDto.type,
  search: string
) => {
  if (!search) {
    sessionStorage.removeItem(getTemporarySearchKey(type));
    return;
  }
  sessionStorage.setItem(getTemporarySearchKey(type), search);
};
