import styled from "@emotion/styled";
import aiIcon from "assets/svg/ai.svg?react";
import { theme } from "theme";

export const Wrapper = styled.div`
  margin: ${theme.spacing.lg} 0;

  .verify-all-banner {
    background-color: ${theme.color.purple[100]};
    border-radius: ${theme.borderRadius};
  }
`;

export const AiIcon = styled(aiIcon)`
  color: ${theme.color.purple[900]};
`;
