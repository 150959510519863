import React, {
  useState,
  RefObject,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { useNavigate, unstable_useBlocker } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { ButtonWithArrow, CTAButton, NewModal } from "components";
import Switch from "new-components/Switch";
import { StyledTextField } from "components/StyledComponents/StyledBaseFields";
import { theme } from "theme";
import { TeamOptionType, CategoryOptionType, ViewMode } from "../../Teams";
import Filters from "../Filters/Filters";
import { Container, ButtonsGroup } from "./styles";

type HeaderProps = {
  gridRef: RefObject<AgGridReact>;
  teams?: TeamOptionType[];
  categories?: CategoryOptionType[];
  viewMode: ViewMode;
  handleViewModeChange: () => void;
  isSaveButtonVisible: boolean;
  handleOnSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnSave: () => void;
  setFilteredRowsIds: Dispatch<SetStateAction<Set<string>>>;
  setFilteredColumnIds: Dispatch<SetStateAction<string[]>>;
  onColumnsFilter: (fieldId: string) => void;
};

const Header = ({
  gridRef,
  teams,
  categories,
  viewMode,
  handleViewModeChange,
  handleOnSearch,
  isSaveButtonVisible: hasUnsavedChanges,
  setFilteredRowsIds,
  setFilteredColumnIds,
  onColumnsFilter,
  handleOnSave,
}: HeaderProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedCategories, setSelectedCategories] = useState<
    CategoryOptionType[]
  >([]);
  const [selectedTeams, setSelectedTeams] = useState<TeamOptionType[]>([]);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState(false);

  const onResetGridAxis = useCallback(() => {
    if (!gridRef) return;

    const allColumnIds = gridRef?.current?.columnApi
      ?.getColumns()
      ?.map((col) => col.getColId());

    if (!allColumnIds) return;
    // Reset columns visibility (show all columns)
    allColumnIds?.forEach((columnId) => {
      gridRef?.current?.columnApi.setColumnVisible(columnId, true);
    });

    // Reset rows visibility (show all rows)
    gridRef?.current?.api.setFilterModel(null);
    gridRef?.current?.api.onFilterChanged();
  }, [gridRef]);

  const resetAllFilters = () => {
    // @Note: Filters state
    setSelectedCategories([]);
    setSelectedTeams([]);
    setFilteredRowsIds(new Set<string>());
    setFilteredColumnIds([]);
    // @Note: Ag-Grid state
    onResetGridAxis();
  };

  const handleChange = () => {
    handleViewModeChange();
    resetAllFilters();
  };

  const blocker = unstable_useBlocker(hasUnsavedChanges);

  const handleSaveChanges = () => {
    void handleOnSave();
    resetAllFilters();
    blocker.proceed?.();
    setIsUnsavedChangesModalOpen(false);
  };

  const handleDiscardChanges = () => {
    setIsUnsavedChangesModalOpen(false);
    blocker.proceed?.();
    resetAllFilters();
  };

  const handleOnCloseModal = (shouldResetBlocker = true) => {
    setIsUnsavedChangesModalOpen(false);
    if (shouldResetBlocker) blocker.reset?.();
  };

  return (
    <>
      <Container aria-label="header">
        <ButtonWithArrow
          id="go-back-button"
          onClick={() => navigate(-1)}
          label={t("pages.settings.tabs.subTeams.section.showSubTeams.goBack")}
          orientation="left"
        />

        <ButtonsGroup>
          <Switch
            onClick={handleChange}
            label={t(
              "pages.settings.organization.teams.header.viewMode.description"
            )}
          />

          <StyledTextField
            margin="none"
            size="small"
            name="search"
            onChange={handleOnSearch}
            placeholder={t(
              "pages.settings.organization.teams.header.search.placeholder"
            )}
            InputProps={{
              sx: {
                height: "100%",
                backgroundColor: theme.color.white,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />

          <Filters
            gridRef={gridRef}
            viewMode={viewMode}
            categories={categories}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            teams={teams}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            setFilteredRowsIds={setFilteredRowsIds}
            onResetGridAxis={onResetGridAxis}
            resetAllFilters={resetAllFilters}
            onColumnsFilter={onColumnsFilter}
          />

          {hasUnsavedChanges && (
            <CTAButton
              label="Save"
              name={t("common.buttons.save")}
              onClick={handleSaveChanges}
            />
          )}
        </ButtonsGroup>
      </Container>

      <NewModal
        open={
          isUnsavedChangesModalOpen ||
          (!!blocker && blocker.state === "blocked")
        }
        handleClose={handleOnCloseModal}
        title={t("common.modals.unsavedChanges.title")}
        body={t("common.modals.unsavedChanges.subtitle")}
        footer={
          <>
            <CTAButton
              label="Save"
              name={t("common.buttons.save")}
              onClick={handleSaveChanges}
            />
            <CTAButton
              name={t("common.buttons.discard")}
              onClick={handleDiscardChanges}
              color="red"
            />
          </>
        }
      />
    </>
  );
};

export default Header;
