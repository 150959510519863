import { ColDef, DateFilter } from "ag-grid-community";
import { dateFormatter } from "constants/utils";
import { Language } from "shared/i18n/i18n";

export const getDateColumnDefinition = (
  locale: Language,
  userDateFormat: string
): ColDef => {
  return {
    filter: DateFilter,
    filterParams: {
      suppressAndOrCondition: true,
      comparator: (date: Date, cellValue: string) => {
        const cellDateValue = new Date(cellValue).setHours(0, 0, 0, 0);
        const dateParam = date.setHours(0, 0, 0, 0);

        if (cellDateValue < dateParam) {
          return -1;
        }

        if (cellDateValue > dateParam) {
          return 1;
        }
        return 0;
      },
    },
    valueFormatter: (params) =>
      dateFormatter(locale, params.value as string, userDateFormat),
  };
};
