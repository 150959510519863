import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import { Wrapper } from "./styles";

type Props = {
  onSubmit: () => void;
};

const Toolbar = ({ onSubmit }: Props) => {
  const buttonStyles = useStyles();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Button
        id="saveCategory"
        data-testid="save-category"
        className={buttonStyles.baseButton}
        onClick={onSubmit}
      >
        {t("common.buttons.save")}
      </Button>
    </Wrapper>
  );
};

export default Toolbar;
