import styled from "@emotion/styled";
import { theme } from "theme";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: ${theme.color.black}15;
`;

export const Container = styled.div`
  position: relative;
  z-index: 1300;
`;

export const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background: ${theme.color.white};
  box-shadow: ${theme.shadow.standard};
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.spacing.xl};
  padding: ${theme.spacing.xl};
`;

export const TitleText = styled.span`
  flex: 1 1 auto;
  color: ${theme.color.gray[900]};
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.semiBold};
  text-align: center;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  right: ${theme.spacing.xl};
  top: ${theme.spacing.xl};
`;

export const Body = styled.div`
  flex: 1 1 auto;
  padding: 0 ${theme.spacing.xl};
  box-sizing: border-box;
`;

export const BodyContent = styled.div`
  flex: 1 1 auto;
  margin-bottom: ${theme.spacing.lg};
`;

export const Footer = styled.div`
  position: relative;
  flex: 0 1 auto;
  padding: ${theme.spacing.xl};
  border-top: 1px solid ${theme.color.gray[400]};
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing.xl};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  padding: ${theme.spacing.sm} 0;
  min-width: 1.75rem;
`;
