import React, { useEffect } from "react";
import { Outlet, Path, useLocation, useNavigate } from "react-router-dom";
import { useUserInfo } from "hooks/GlobalStateHooks";
import { Loader } from "components";
import { routePaths } from "../../constants";
import { TopNavigation } from "../components";

const TopNavigationLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { status, userInfo, isTeamlessUser } = useUserInfo();

  const isUserPath = location.pathname.includes("user");
  const state = location.state as { redirect?: Path } | null;

  const isApprovalPath = state?.redirect?.pathname.match(
    /federated-identity\/.+\/approve$/
  );

  const shouldRedirect =
    status === "success" &&
    isTeamlessUser &&
    !isUserPath &&
    !isApprovalPath &&
    userInfo;

  useEffect(() => {
    if (shouldRedirect) {
      return navigate(routePaths.TEAM_CREATION);
    }
  }, [isTeamlessUser, status]);

  if (!userInfo) return <Loader />;

  return (
    <>
      <TopNavigation />
      <Outlet />
    </>
  );
};

export default TopNavigationLayout;
