import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import { routePaths } from "../../constants";
import { Wrapper, Container, Description } from "./styles";

const NotFound = () => {
  const { t } = useTranslation();
  const buttonClasses = useStyles();

  return (
    <Wrapper>
      <Container>
        <h1>{t("pages.notFound.title")}</h1>
        <Description>{t("pages.notFound.description")}</Description>

        <Button
          to={routePaths.HOME}
          className={buttonClasses.baseButton}
          component={RouterLink}
        >
          {t("pages.notFound.button")}
        </Button>
      </Container>
    </Wrapper>
  );
};

export default NotFound;
