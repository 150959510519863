import gerryAvatar from "./img/gerry.jpg";
import sebastianAvatar from "./img/seba.jpg";
import jaquelineAvatar from "./img/jaque.jpg";

type ContactInfoType = {
  phone: {
    value: string;
    label: string;
  };
  email: string;
  calendar: string;
};

export const contactInfo: ContactInfoType = {
  phone: {
    value: "+493057712332",
    label: "(+49)30-57712332",
  },
  email: "support@contracthero.com",
  calendar: "https://calendly.com/contracthero-support/contracthero-support",
};

export const avatarsInfo = [
  {
    src: gerryAvatar,
    alt: "Gerry avatar",
  },
  {
    src: jaquelineAvatar,
    alt: "Jaqueline avatar",
  },
  {
    src: sebastianAvatar,
    alt: "Sebastian avatar",
  },
];
