import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Arrows from "assets/svg/two-directions-arrows.svg?react";
import ArrowUp from "assets/svg/arrow-up.svg?react";
import { theme } from "theme";
import { useTranslation } from "react-i18next";
import { Features } from "constants/features";
import { useTeam } from "contexts/team/hooks";
import {
  SortDirection,
  SortType,
  SORT_DIRECTIONS,
  SORT_TYPES,
} from "../../Fields";
import {
  MenuButton,
  MenuLabel,
  Title,
  MenuList,
  MenuOption,
  ArrowContainer,
} from "./styles";

const ArrowIcon = ({ orientation }: { orientation: "up" | "down" }) => (
  <ArrowContainer orientation={orientation}>
    <ArrowUp />
  </ArrowContainer>
);

type FieldsMenuProps = {
  sortDirection: SortDirection;
  onSort: (sortType: SortType, sortDirection: SortDirection) => void;
  onReset: () => void;
};

const FieldsMenu = ({ sortDirection, onSort, onReset }: FieldsMenuProps) => {
  const { t } = useTranslation();
  const { hasFeature } = useTeam();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [activeOptionIndex, setActiveOptionIndex] = useState<number>(-1);
  const [selectedSortOption, setSelectedSortOption] = useState("");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const getOptionIcon = (key: number, sortType: string) => {
    if (activeOptionIndex !== key) return null;

    switch (key) {
      case 1:
      case 2:
      case 3:
      case 4:
        return sortType === SORT_DIRECTIONS.ASC ? (
          <ArrowIcon orientation="down" />
        ) : (
          <ArrowIcon orientation="up" />
        );
      default:
        return null;
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "fields-menu-popover-container" : undefined;

  const options = [
    {
      key: 1,
      name: SORT_TYPES.NAME,
      label: t(
        "pages.settings.organization.administrator.fields.menu.options.title"
      ),
      hasIcon: true,
    },
    {
      key: 2,
      name: SORT_TYPES.DATE,
      label: t(
        "pages.settings.organization.administrator.fields.menu.options.recent"
      ),
      hasIcon: true,
    },
    {
      key: 3,
      name: SORT_TYPES.AI,
      label: t(
        "pages.settings.organization.administrator.fields.menu.options.aiInsights"
      ),
      hasIcon: true,
    },
    {
      key: 4,
      name: SORT_TYPES.USED,
      label: t(
        "pages.settings.organization.administrator.fields.menu.options.used"
      ),
      hasIcon: true,
    },
    {
      key: 5,
      name: SORT_TYPES.DEFAULT,
      label: t(
        "pages.settings.organization.administrator.fields.menu.options.reset"
      ),
    },
  ] as { key: number; name: SortType; label: string; hasIcon?: boolean }[];

  const handleOptionClick = (key: number) => {
    setActiveOptionIndex(key);

    if (key === 5) {
      setSelectedSortOption(t("common.labels.default"));
      return;
    }

    setSelectedSortOption(options[key - 1].label);

    handleClose();
  };

  return (
    <>
      <MenuButton type="button" onClick={handleClick} active={open}>
        <MenuLabel active={!!anchorEl}>
          <Arrows />
          <span>{selectedSortOption}</span>
        </MenuLabel>
      </MenuButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            backgroundColor: theme.color.white,
            padding: theme.spacing.md,
          },
        }}
      >
        <Title>{t("common.labels.sortBy")}</Title>

        <MenuList>
          {options.map((option, index) => {
            const isActive = activeOptionIndex === option.key;
            const skipAiOption =
              !hasFeature(Features.CONTRACT_ANALYSIS) && option.key === 3;
            if (skipAiOption) return null;
            return (
              <MenuOption
                key={index}
                active={isActive}
                onClick={() => {
                  handleOptionClick(option.key);

                  if (option.name === SORT_TYPES.DEFAULT) {
                    onReset();
                    return;
                  }

                  onSort(option.name, sortDirection);
                  handleClose();
                }}
              >
                {option.label}
                {option.hasIcon &&
                  isActive &&
                  getOptionIcon(option.key, sortDirection ?? "")}
              </MenuOption>
            );
          })}
        </MenuList>
      </Popover>
    </>
  );
};

export default FieldsMenu;
