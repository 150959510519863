import React, { useMemo } from "react";
import {
  Row,
  Container,
  FormItem,
  FormItemHint,
  FormMessage,
  FormOffsetFieldWrapper,
  FormItemLabel,
} from "./styles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { CTAButton } from "components";
import { FormNumericField, FormSelect } from "components/FormItems";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FormSelectItem } from "components/FormItems/FormSelect/FormSelect";
import { theme } from "theme";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import { CalculatedRelativeDatesType } from "pages/Tasks/Modals/TaskDetails";
import { Control } from "react-hook-form";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  ContractFieldDTOV1,
  TaskAutomationDueDateConfigDTO,
  TaskNewDTO,
} from "openapi";
import { useTranslation } from "react-i18next";
import { useLocale } from "hooks";
import { getRelativeFieldName } from "../TasksTable/helpers";

export type RelativeDueDateProps = {
  control: unknown;
  dateFieldOptions: FormSelectItem[];
  calculatedDates?: CalculatedRelativeDatesType;
  reminderIsEnabled: boolean;
  repeatIsEnabled: boolean;
  toggleReminder: (value: boolean) => void;
  toggleRepeat: (value: boolean) => void;
  taskId?: string;
  isBaseDate?: boolean;
  task: TaskNewDTO | undefined;
  fields: ContractFieldDTOV1[] | undefined;
};
const RelativeDueDateComponent = ({
  control,
  dateFieldOptions,
  calculatedDates,
  reminderIsEnabled,
  repeatIsEnabled,
  toggleReminder,
  toggleRepeat,
  taskId,
  isBaseDate,
  task,
  fields,
}: RelativeDueDateProps) => {
  const { t } = useTranslation();
  const dateFiledsEnums = TaskAutomationDueDateConfigDTO.unit;
  const { locale } = useLocale();

  const relativeFieldId: string = task?.dueDateConfig
    ?.relativeFieldId as string;

  const relativeFieldKey: string = task?.dueDateConfig
    ?.relativeFieldKey as string;

  const dateUnits = Object.keys(dateFiledsEnums).map((item) => ({
    key: item,
    value: t(
      `common.intevalEnums.${
        dateFiledsEnums[item as keyof typeof dateFiledsEnums] as string
      }`
    ),
  }));

  const isDateFieldInCategory = useMemo(() => {
    return dateFieldOptions.some((item) =>
      (item.key as string).includes(relativeFieldId)
    );
  }, [task, dateFieldOptions]);

  const dateFieldName = useMemo(() => {
    return getRelativeFieldName(
      relativeFieldId,
      relativeFieldKey,
      fields as ContractFieldDTOV1[],
      locale,
      t
    );
  }, [fields, task]);

  const isDateFieldFilled = !!taskId && !!isBaseDate;

  return (
    <Container>
      <Row
        justifyItems="flex-start"
        gap={theme.spacing.lg}
        verticalMargin={theme.spacing.lg}
      >
        <AccessTimeIcon />
        <FormItem>
          <FormOffsetFieldWrapper>
            <FormNumericField
              label=""
              name="dueDateConfig.offset"
              decimalScale={0}
              control={control as Control}
              placeholder="1"
              showButtons
            />
          </FormOffsetFieldWrapper>
          <FormSelect
            control={control as Control}
            name="dueDateConfig.unit"
            options={dateUnits}
            style={{ maxWidth: "120px" }}
            margin="none"
          />
          {calculatedDates?.beforeBaseDate ? (
            <FormItemHint>
              {t("pages.tasks.modal.hints.calculatedDate", {
                date: calculatedDates?.beforeBaseDate,
              })}
            </FormItemHint>
          ) : null}
        </FormItem>
        <span>{t("common.timePrepositions.before")}</span>
        <FormItem>
          <FormSelect
            control={control as Control}
            name="dueDateConfig.relativeFieldId"
            options={dateFieldOptions}
            margin="none"
            style={{ maxWidth: "200px" }}
            label={t("pages.tasks.modal.fields.dateField")}
          />
          {calculatedDates?.baseDate ? (
            <FormItemHint>
              {t("pages.tasks.modal.hints.dateInContract", {
                date: calculatedDates?.baseDate,
              })}
            </FormItemHint>
          ) : null}
        </FormItem>
      </Row>
      {!isDateFieldInCategory && !!relativeFieldId ? (
        <Row
          alignItems="flex-start"
          justifyItems="flex-start"
          gap={theme.spacing.md}
          verticalMargin={theme.spacing.xxs}
        >
          <InfoOutlinedIcon style={{ color: theme.color.orange[600] }} />
          <FormMessage>
            {t("pages.tasks.modal.hints.noFieldInCategory", { dateFieldName })}
          </FormMessage>
        </Row>
      ) : null}
      {!isDateFieldFilled && !!relativeFieldId && isDateFieldInCategory ? (
        <Row
          alignItems="flex-start"
          justifyItems="flex-start"
          gap={theme.spacing.md}
          verticalMargin={theme.spacing.xxs}
        >
          <InfoOutlinedIcon style={{ color: theme.color.orange[600] }} />
          <FormMessage>
            {t("pages.tasks.modal.hints.noDateInContract", { dateFieldName })}
          </FormMessage>
        </Row>
      ) : null}
      <Row
        justifyItems="flex-start"
        gap={theme.spacing.lg}
        verticalMargin={theme.spacing.lg}
      >
        <RepeatOutlinedIcon />
        {repeatIsEnabled ? (
          <>
            <FormItemLabel>{t("common.repeatEvery")}</FormItemLabel>
            <FormItem>
              <FormOffsetFieldWrapper>
                <FormNumericField
                  label=""
                  name="dueDateConfig.repeatConfig.offset"
                  decimalScale={0}
                  control={control as Control}
                  disabled={!repeatIsEnabled}
                  placeholder="1"
                  showButtons
                />
              </FormOffsetFieldWrapper>
              <FormSelect
                control={control as Control}
                name="dueDateConfig.repeatConfig.unit"
                options={dateUnits}
                style={{ maxWidth: "120px" }}
                margin="none"
                disabled={!repeatIsEnabled}
              />
              <IconButton
                className="delete"
                onClick={() => toggleRepeat(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              {calculatedDates?.repeatDate ? (
                <FormItemHint>
                  {t("pages.tasks.modal.hints.calculatedDate", {
                    date: calculatedDates?.repeatDate,
                  })}
                </FormItemHint>
              ) : null}
            </FormItem>
          </>
        ) : (
          <CTAButton
            variant="tertiary"
            name={t("common.buttons.addRepeat")}
            onClick={() => toggleRepeat(true)}
          />
        )}
      </Row>
      <Row
        justifyItems="flex-start"
        gap={theme.spacing.lg}
        verticalMargin={theme.spacing.lg}
      >
        <NotificationsNoneIcon />
        {reminderIsEnabled ? (
          <>
            <FormItem>
              <FormOffsetFieldWrapper>
                <FormNumericField
                  label=""
                  name="reminderConfig.offset"
                  decimalScale={0}
                  control={control as Control}
                  placeholder="1"
                  showButtons
                />
              </FormOffsetFieldWrapper>
              <FormSelect
                control={control as Control}
                name="reminderConfig.unit"
                options={dateUnits}
                style={{ maxWidth: "120px" }}
                margin="none"
              />
              {calculatedDates?.reminderDate ? (
                <FormItemHint>
                  {t("pages.tasks.modal.hints.calculatedDate", {
                    date: calculatedDates?.reminderDate,
                  })}
                </FormItemHint>
              ) : null}
              <span>{t("pages.tasks.modal.hints.beforeDueDate")}</span>
              <IconButton
                className="delete"
                onClick={() => toggleReminder(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </FormItem>
          </>
        ) : (
          <CTAButton
            variant="tertiary"
            name={t("common.buttons.addReminder")}
            onClick={() => toggleReminder(true)}
          />
        )}
      </Row>
    </Container>
  );
};

export default RelativeDueDateComponent;
