import styled from "@emotion/styled";
import { theme } from "theme";

export const RoleBox = styled.div`
  font-size: ${theme.font.size.caption};
  font-weight: ${theme.font.weight.normal};
  padding: 0 ${theme.spacing.xs};
  color: ${theme.color.blue[600]};
  background-color: ${theme.color.blue[100]};
  border-radius: ${theme.labelBorderRadius};
  align-self: flex-start;
`;
