/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-var-requires */
import countries from "i18n-iso-countries";
import * as de from "../../node_modules/i18n-iso-countries/langs/de.json";
import * as en from "../../node_modules/i18n-iso-countries/langs/en.json";
import { Language } from "shared/i18n/i18n";

const langs: { [key: string]: countries.LocaleData } = {
  de: de,
  en: en,
};

export const initCountries = (locale: string) => {
  const lang = langs[locale];
  if (lang) {
    countries.registerLocale(lang);
  }
};

export const getCountriesOptions = (locale: Language) => {
  const list: countries.LocalizedCountryNames<{
    select: "official";
  }> = countries.getNames(locale, { select: "official" });

  const result = [];

  for (const key in list) {
    result.push({
      key: key,
      value: list[key],
    });
  }

  return result;
};
