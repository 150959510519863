import styled from "@emotion/styled";
import { theme } from "theme";

type ContractNameProps = {
  categoryAccessible?: boolean;
};

export const ContractInfoItem = styled.div`
  display: flex;
  gap: ${theme.spacing.md};
  align-items: center;
`;

export const ContractName = styled.p`
  margin: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CategoryName = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 0 auto;
`;
export const IconWrapper = styled.div<ContractNameProps>`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: ${({ categoryAccessible }) =>
    categoryAccessible ? theme.color.green[600] : theme.color.red[600]};
  svg {
    width: 14px;
    height: 14px;
    color: ${theme.color.white};
  }
`;
