import { useLocation } from "react-router-dom";
import { useInvitation } from "../../hooks/GlobalStateHooks";
import { useEffect } from "react";

type Props = {
  children: JSX.Element;
};

const InvitationTokenChecker = ({ children }: Props) => {
  const { search } = useLocation();

  let token = new URLSearchParams(search).get("invitationToken");

  if (!token) {
    token = new URLSearchParams(search).get("token");
  }

  const { setInvitationToken } = useInvitation();

  useEffect(() => {
    if (token) {
      setInvitationToken(token);
    }
  }, [token]);

  return children;
};

export default InvitationTokenChecker;
