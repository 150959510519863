import styled from "@emotion/styled";
import { theme } from "theme";

export const InfoWrapper = styled.span`
  display: grid;
  place-items: center;
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;

  :hover {
    opacity: 1;
  }

  svg {
    width: 100%;
    height: 100%;
    color: ${theme.color.blue[600]};
  }
`;
