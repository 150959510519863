import React from "react";
import { useTranslation } from "react-i18next";
import { Values, Wrapper, Body, ValueIconWrapper } from "./styles";
import { Diff, PermissionChangeLogDTO, PermissionDiff } from "openapi";
import ArrowIcon from "assets/svg/arrow-up.svg?react";
import CategoryBadge from "../CategoryBadge/CategoryBadge";

export type CategoryLabelType = {
  permissions: PermissionDiff;
  name?: string;
  color?: string;
  event: PermissionChangeLogDTO.event;
};

const CategoryLabel = ({
  name,
  color,
  permissions,
  event,
}: CategoryLabelType) => {
  const { t } = useTranslation();
  const isUpdated = event === PermissionChangeLogDTO.event.ROLE_UPDATED;

  const getFieldValue = (permissions: PermissionDiff, type: keyof Diff) => {
    if (isUpdated) {
      if (permissions.level[type] === null) {
        return t(`common.accessOptions.NO_ACCESS`);
      }
      return t(
        `common.accessOptions.${permissions.level[type] as unknown as string}`
      );
    }
    return t(`common.accessOptions.${permissions.level as unknown as string}`);
  };

  return (
    <Wrapper>
      <CategoryBadge color={color} name={name} />
      <Body>
        {isUpdated ? (
          <Values>
            <span>{getFieldValue(permissions, "from")}</span>
            <ValueIconWrapper>
              <ArrowIcon />
            </ValueIconWrapper>
            <span>{getFieldValue(permissions, "to")}</span>
          </Values>
        ) : (
          <span>{getFieldValue(permissions, "to")}</span>
        )}
      </Body>
    </Wrapper>
  );
};

export default CategoryLabel;
