import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Badge from "@mui/material/Badge";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import IconButton from "@mui/material/IconButton";
import NewModal from "../Modal/NewModal";
import Notification from "./NotificationModalContent";
import {
  useMarkNotificationsAsReadMutation,
  useNotificationsQuery,
} from "shared/api/notification";

const Notifications = () => {
  const { t } = useTranslation();
  const { data } = useNotificationsQuery();
  const markAllAsRead = useMarkNotificationsAsReadMutation();
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleOpenModal = () => {
    markAllAsRead.mutate();
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <IconButton
        id="notifications"
        aria-label="notification-button"
        onClick={handleOpenModal}
      >
        <Badge badgeContent={data?.unread} color="error">
          <NotificationsActiveIcon fontSize="medium" />
        </Badge>
      </IconButton>
      <NewModal
        open={showModal}
        handleClose={handleCloseModal}
        title={t("topbar.notifications.modal.title")}
        body={
          <Notification
            handleClose={handleCloseModal}
            notificationData={data}
          />
        }
        icon={<NotificationsActiveIcon />}
      />
    </>
  );
};

export default Notifications;
