import styled from "@emotion/styled";
import { breakpoints, theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing.xs};
  @media screen and (min-width: ${breakpoints.lg}) {
    align-items: center;
    flex-direction: row;
  }
`;

export const Email = styled.div`
  color: ${theme.color.gray[600]};
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.xs};
`;
