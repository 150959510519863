/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ContractFieldCreateDTOV1,
  ContractFieldUpdateDTOV1,
  OrganizationNewService,
  OrganizationService,
} from "openapi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useFieldsQuery = (organizationId: string | undefined) => {
  return useQuery({
    queryKey: ["contract-definitions", organizationId],
    queryFn: () =>
      OrganizationNewService.getFieldsOfOrganization(organizationId!),
    enabled: !!organizationId,
  });
};

export const useFieldsCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { teamId: string; field: ContractFieldCreateDTOV1 }) => {
      return OrganizationService.createContractField(data.teamId, data.field);
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["fields", variables.teamId],
      });
    },
  });
};

export const useFieldsDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { teamId: string; fieldId: string }) => {
      return OrganizationService.deleteContractField(data.teamId, data.fieldId);
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["fields", variables.teamId],
      });
    },
  });
};

export const useFieldsUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      teamId: string;
      fieldId: string;
      field: ContractFieldUpdateDTOV1;
    }) => {
      return OrganizationService.updateContractField(
        data.teamId,
        data.fieldId,
        data.field
      );
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["fields", variables.teamId],
      });
    },
  });
};
