import {
  ContractFieldDTOV1,
  ContractFieldLimitedDTOV1,
  ContractSectionDTOV1,
} from "openapi";

export const getSectionFields = (
  fields: ContractFieldDTOV1[],
  sectionFields: ContractFieldLimitedDTOV1[]
) => {
  const fullSectionFields: ContractFieldDTOV1[] = [];
  const fieldMap = new Map<string, ContractFieldDTOV1>();

  for (const field of fields) {
    if (field && field.id) {
      fieldMap.set(field.id, field);
    }
  }

  for (const sectionField of sectionFields) {
    const field = fieldMap.get(sectionField.id);
    if (field) {
      fullSectionFields.push(field);
    }
  }

  return fullSectionFields;
};

export const isFieldUsed = (
  sections: ContractSectionDTOV1[],
  fieldId: string
) => {
  for (const section of sections) {
    if (section.fields.find((field) => field.id === fieldId)) {
      return true;
    }
  }
  return false;
};
