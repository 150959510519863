import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  width: 100%;
`;

const commonStyles = `
  padding: ${theme.spacing.md};
  margin-bottom: ${theme.spacing.md};
  box-shadow: ${theme.shadow.alternative};
  border-radius: ${theme.borderRadius};
`;

export const Container = styled.div`
  border: 1px solid ${theme.color.gray};
  ${commonStyles}
`;

export const Form = styled.form`
  ${commonStyles}
`;

export const Main = styled.div`
  padding: ${theme.spacing.md};
  overflow: auto;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacing.md};
  gap: ${theme.spacing.sm};
`;

// Contact details
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  background-color: ${theme.color.gray[200]};
  border-radius: ${theme.borderRadius};
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  font-size: ${theme.font.size.caption};
  text-transform: uppercase;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};

  svg {
    fill: ${theme.color.blue[400]};
  }
`;

export const List = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  position: relative;

  ::-webkit-scrollbar {
    height: 8px;
  }

  ::-webkit-scrollbar,
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    margin: 4px 0;
    border: none;
    border-radius: ${theme.borderRadius};
  }

  ::-webkit-scrollbar-track {
    background-color: ${theme.color.gray[200]};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.color.gray[500]};
  }

  svg {
    fill: ${theme.color.gray[500]};
  }

  & .MuiRadio-root svg {
    fill: ${theme.color.blue[500]};
  }
`;

export const Text = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
`;

export const StickyListItem = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${theme.spacing.sm};
  min-width: 130px;
  background-color: ${theme.color.white};
  border-radius: ${theme.borderRadius};
  position: sticky;
  right: -${theme.spacing.md};
  min-height: 42px;
`;

export const NoDuplicateMessage = styled.h5`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${theme.font.size.h5};
  font-weight: ${theme.font.weight.semiBold};
  color: ${theme.color.gray[500]};
  gap: ${theme.spacing.sm};

  svg {
    fill: ${theme.color.gray[500]};
  }
`;
