import React from "react";
import { useTranslation } from "react-i18next";
import { SuggestionsList, SuggestionsTitle, SuggestionOption } from "./styles";

type AISuggestionDropdownProps = {
  options: {
    displayValue: string | number;
    value: string | number;
  }[];
  handleOptionClick: (option: unknown) => void;
};

const AISuggestionDropdown = ({
  options,
  handleOptionClick,
}: AISuggestionDropdownProps) => {
  const { t } = useTranslation();
  return (
    <>
      <SuggestionsList>
        <SuggestionsTitle>
          {t("customFields.aiSuggestions.title")}
        </SuggestionsTitle>

        {options.length &&
          options.map((option, index) => {
            return (
              <SuggestionOption
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOptionClick(option.value);
                }}
              >
                {option.displayValue}
              </SuggestionOption>
            );
          })}
      </SuggestionsList>
    </>
  );
};

export default AISuggestionDropdown;
