import React, { RefObject, useCallback, useMemo } from "react";

import currencies from "../../utils/currencies-list.json";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./ag-theme.css";

import {
  CellValueChangedEvent,
  CheckboxSelectionCallbackParams,
  ColDef,
  ExcelStyle,
  GridOptions,
  GridReadyEvent,
  SideBarDef,
  ToolPanelVisibleChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { NameCellRenderer } from "pages/Contracts/CellRenderers/NameCellRenderer";
import { useTranslation } from "react-i18next";
import { ContractDtoWithResolvedParent } from "constants/utils";
import { Box } from "@mui/material";
import CustomDatepicker from "pages/Contracts/Gridcomponents/CustomDatepicker";
import { useOverviewActions, useOverviewState } from "contexts/grid/hooks";
import { CustomCellEditor } from "../../pages/Contracts/CellEditors/CustomCellEditor";
import { patchScrollbar } from "./utils";
import { getCellClass, isContractEditable } from "pages/Contracts/helpers";
import { useTeam } from "contexts/team/hooks";
import CustomStatusBar from "pages/Contracts/CustomStatusBar/CustomStatusBar";
import { ContractDTOV1 } from "openapi";
import { getLocale } from "./locale";
import { AGGridBaseTableContext } from "./types";

export type AGGridBaseTableProps = {
  gridOptions: GridOptions;
  rowData: ContractDtoWithResolvedParent[] | undefined;
  gridName: string;
  sidebarOptions: string | boolean | SideBarDef;
  onGridReady: (event: GridReadyEvent) => void;
  gridRef: React.MutableRefObject<AgGridReact | undefined>;
  onCellValueChanged: (
    event: CellValueChangedEvent<ContractDtoWithResolvedParent>
  ) => void;
  onToolPanelVisibleChanged?: (event: ToolPanelVisibleChangedEvent) => void;
  onSelectionChanged?: GridOptions["onSelectionChanged"];
  onRowDataUpdated?: GridOptions["onRowDataUpdated"];
  allowSelection?: boolean;
  localeText?: { [key: string]: string };
  context?: AGGridBaseTableContext;
};

const AgGridBaseTable = ({
  gridOptions,
  sidebarOptions,
  rowData,
  gridName,
  onGridReady,
  gridRef,
  onCellValueChanged,
  onSelectionChanged,
  onRowDataUpdated,
  onToolPanelVisibleChanged,
  allowSelection = false,
  localeText,
  context,
}: AGGridBaseTableProps) => {
  const { gridStateProps } = useOverviewState();
  const { gridProps } = useOverviewActions();
  const { t } = useTranslation();
  const { hasWriteAccess } = useTeam();
  const localeTexts = { ...getLocale(t), ...localeText };
  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      field: "name",
      minWidth: 285,
      editable: isContractEditable(hasWriteAccess),
      cellEditor: CustomCellEditor,
      cellEditorParams: {
        type: "text",
        isTitle: true,
      },
      headerName: t("pages.contracts.tableColumns.title"),
      cellClass: getCellClass(null, undefined),
      cellRendererParams: {
        innerRenderer: NameCellRenderer,
        suppressCount: true,
      },
      checkboxSelection: allowSelection
        ? (params: CheckboxSelectionCallbackParams<ContractDTOV1>) => {
            return params.node.level === 0;
          }
        : false,
      headerCheckboxSelection: allowSelection,
      headerCheckboxSelectionFilteredOnly: true,
    }),
    [hasWriteAccess]
  );

  const getDataPath = useCallback(
    (data: ContractDtoWithResolvedParent): string[] => {
      const path = [];
      let currentParent = data.parent;
      while (currentParent) {
        path.push(currentParent.id);
        currentParent = currentParent.parent;
      }
      path.reverse();

      path.push(data.id);
      return path;
    },
    []
  );

  const excelStyles: ExcelStyle[] = useMemo(() => {
    const styles: ExcelStyle[] = [
      {
        id: "date-cell",
        dataType: "DateTime",
        numberFormat: {
          format: "dd.MM.yyyy",
        },
      },
    ];

    styles.push(
      ...currencies.map((currency) => ({
        id: `amount-cell-${currency.cc}`,
        numberFormat: {
          format: `#,##0.00\\ [$${currency.symbol}]`,
        },
      }))
    );

    return styles;
  }, []);

  return (
    <Box
      id={`ag-${gridName}-grid`}
      data-testid="ag-grid-base-table"
      className="ag-theme-alpine"
      sx={{
        flex: 1,
      }}
    >
      <AgGridReact
        ref={gridRef as unknown as RefObject<AgGridReact>}
        treeData
        excelStyles={excelStyles}
        excludeChildrenWhenTreeDataFiltering
        rowData={rowData}
        getRowId={(params) => params.data.id}
        gridOptions={gridOptions}
        onGridReady={(e) => {
          patchScrollbar();
          onGridReady(e);
        }}
        sideBar={sidebarOptions}
        animateRows
        getDataPath={getDataPath}
        localeText={localeTexts}
        onSelectionChanged={onSelectionChanged}
        suppressContextMenu
        context={context}
        suppressRowDrag
        embedFullWidthRows
        autoGroupColumnDef={autoGroupColumnDef}
        components={{
          agDateInput: CustomDatepicker,
        }}
        onCellValueChanged={onCellValueChanged}
        onRowDataUpdated={onRowDataUpdated}
        onToolPanelVisibleChanged={onToolPanelVisibleChanged}
        statusBar={{
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
            {
              statusPanel: CustomStatusBar,
              align: "right",
            },
          ],
        }}
        suppressRowClickSelection={true}
        {...gridProps}
        {...gridStateProps}
      />
    </Box>
  );
};

export default AgGridBaseTable;
