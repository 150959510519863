import styled from "@emotion/styled";
import { theme } from "theme";

export const AlertWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.lg};
  border: 2px solid ${theme.color.red[400]};
  border-radius: ${theme.borderRadius};
  background: ${theme.color.red[200]};
`;

export const AlertText = styled.div`
  flex: 1 1 auto;
  & h3 {
    margin: 0;
  }
`;

export const AlertIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin-right: ${theme.spacing.xl};
  flex: 0 0 auto;
  background: ${theme.color.red[600]};
`;
