import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { SnackbarKey, useSnackbar } from "notistack";
import React, { FC } from "react";

type SnackbarWithCloseButtonProps = {
  snackbarKey: SnackbarKey;
};

export const SnackbarWithCloseButton: FC<SnackbarWithCloseButtonProps> = ({
  snackbarKey,
}) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <Close sx={{ color: "white" }} />
    </IconButton>
  );
};
