import styled from "@emotion/styled";
import { Button, Card, Popover as MUIPopover } from "@mui/material";
import { theme } from "theme";

export const ContractHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContractTitle = styled.h1`
  padding-bottom: ${theme.spacing.lg};
  margin-bottom: ${theme.spacing.lg};
  border-width: ${theme.spacing.lg};
  border-bottom: 2.5px dashed #cccccca3;
`;

export const ContractGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
  overflow: hidden;
  z-index: 1300; // for safari

  @media screen and (min-width: 768px) {
    flex-direction: row;
    position: relative;
  }
`;

export const ContractColumn = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  box-sizing: border-box;
  overflow: auto;

  @media screen and (min-width: 768px) {
    width: 50%;
  }
  :nth-of-type(1) {
    margin-right: ${theme.spacing.sm};
  }
  :nth-of-type(2) {
    position: sticky;
    top: 0;
    align-self: start;
  }
`;

export const Settings = styled.div`
  border: 1px solid orange;
  margin: ${theme.spacing.lg} 0;
  padding: ${theme.spacing.lg} 0;
`;

export const DatapointContent = styled.div<{ isLink?: boolean }>`
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;

  ${({ isLink }) =>
    isLink &&
    `
    :hover {
      text-decoration: underline;
    }
  `}
`;

export const PreviewPDF = styled.object`
  height: calc(100% - 4.5rem);
  width: 100%;
  border: none;
  margin-top: 0.5rem;
`;

export const StickyCard = styled(Card)`
  position: sticky;
  bottom: 0;
  border-radius: 0.5rem;
  padding: 16px;
  background-color: transparent;
  z-index: 2;
`;

export const SaveButton = styled(Button)`
  padding: 10px;
  background-color: ${theme.color.blue[600]};
  width: 100%;
  color: ${theme.color.white};
  text-transform: none;
  font-weight: 700;
  font-size: ${theme.font.size.subheading};

  :hover {
    background-color: ${theme.color.blue[400]};
  }
`;

export const Popover = styled(MUIPopover)`
  .MuiPaper-root {
    padding: 24px;
    min-width: 375px;
    border-radius: 16px;
    box-shadow: 0 20px 32px rgba(39, 44, 50, 0.25);
  }
`;

export const ReminderButton = styled(Button)`
  width: 100%;
  padding: 0.47em 0;
  color: ${theme.color.gray[700]};
  margin-top: ${theme.spacing.lg};
  border: 1px solid ${theme.color.gray[500]};
  border-radius: ${theme.borderRadius};
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  text-transform: none;
  ${(props: { disabled: boolean }) =>
    !props.disabled &&
    `
    &:hover {
      cursor: pointer;
      background-color: ${theme.color.gray[200]};
      color: ${theme.color.blue[600]};
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing.xl};
  margin: ${theme.spacing.xl} 0 ${theme.spacing.sm};
`;
