import styled from "@emotion/styled";
import { Button as MUIButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${theme.spacing.lg} 0;
  padding: ${theme.spacing.xxs} ${theme.spacing.lg};
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.sm};
`;

export const AddIcon = styled(Add)``;

const commonButtonStyles = `
  text-transform: capitalize;
  height: fit-content;
  margin: 0 !important;
  padding: ${theme.spacing.sm} ${theme.spacing.lg};

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    margin-left: ${theme.spacing.xs};
    font-size: ${theme.font.size.subheading};
  }
`;

export const NewContactButton = styled(MUIButton)`
  ${commonButtonStyles}
`;

export const MergeDuplicatesButton = styled(MUIButton)`
  ${commonButtonStyles}

  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
  box-shadow: ${theme.shadow.standard};
  border: 1px solid ${theme.color.gray[300]};
  color: ${theme.color.gray[900]} !important;
  background-color: ${theme.color.white} !important;

  svg {
    fill: ${theme.color.gray[700]};
  }
`;
