/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type S3BackupConfigUpdate = {
  type: S3BackupConfigUpdate.type;
  password?: string;
  accessKey?: string;
  secretKey?: string;
  bucket?: string;
  region?: string;
  storageClass?: string;
};

export namespace S3BackupConfigUpdate {
  export enum type {
    FTPS = "FTPS",
    SFTP = "SFTP",
    AWS_S3 = "AWS S3",
  }
}
