import React from "react";
import {
  CategoryDTO,
  ParentTeamDTO,
  PermissionChangeLogDTO,
  PermissionLogInitialLogDTO,
  RoleDTO,
  TeamMemberDto,
} from "openapi";
import TypeLabel from "../TypeLabel/TypeLabel";
import StatusLabel from "../StatusLabel/StatusLabel";
import { useTranslation } from "react-i18next";
import { EntityWrapper, Title, Wrapper } from "./styles";
import MemberInitial from "../MemberInitial/MemberInitial";
import TeamInitial from "../TeamInitial/TeamInitial";
import RoleInitial from "../RoleInitial/RoleInitial";
import InvitationInitital from "../InvitationInitital/InvitationInitital";
import Collapsible from "../Collapsible/Collapsible";

export type InitialEntryType = {
  data: PermissionLogInitialLogDTO;
  event: PermissionChangeLogDTO.event;
  roles: RoleDTO[];
  members: TeamMemberDto[];
  categories: CategoryDTO[];
  organization: ParentTeamDTO;
};

const InitialEntry = ({
  data,
  event,
  roles,
  members,
  categories,
  organization,
}: InitialEntryType) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Title>
        {data.organizationName}
        <StatusLabel
          variant={event}
          title={t(
            `pages.settings.organization.permissionChangeLog.status.${event}`
          )}
        />
      </Title>
      {!!data.members.length && (
        <EntityWrapper>
          <TypeLabel variant="teamMember" amount={data.members.length} />
          <Collapsible gap="lg">
            {data.members.map((memberItem, index) => {
              return (
                <MemberInitial
                  data={memberItem}
                  members={members}
                  roles={roles}
                  teams={organization.teams}
                  key={index}
                />
              );
            })}
          </Collapsible>
        </EntityWrapper>
      )}
      {!!data.teams.length && (
        <EntityWrapper>
          <TypeLabel variant="team" amount={data.teams.length} />
          <Collapsible gap="lg">
            {data.teams.map((teamItem) => {
              return (
                <TeamInitial
                  data={teamItem}
                  categories={categories}
                  key={teamItem.id}
                />
              );
            })}
          </Collapsible>
        </EntityWrapper>
      )}
      {!!data.roles.length && (
        <EntityWrapper>
          <TypeLabel variant="userRole" amount={data.roles.length} />
          <Collapsible gap="lg">
            {data.roles.map((roleItem) => {
              return (
                <RoleInitial
                  data={roleItem}
                  categories={categories}
                  teams={organization.teams}
                  key={roleItem.id}
                />
              );
            })}
          </Collapsible>
        </EntityWrapper>
      )}

      {!!data.pendingInvitations.length && (
        <EntityWrapper>
          <TypeLabel
            variant="pendingInvitations"
            amount={data.pendingInvitations.length}
          />
          {data.pendingInvitations.map((invitationItem, index) => {
            return (
              <InvitationInitital
                data={invitationItem}
                roles={roles}
                teams={organization.teams}
                key={index}
              />
            );
          })}
        </EntityWrapper>
      )}
    </Wrapper>
  );
};

export default InitialEntry;
