import React from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Wrapper,
  Header,
  Body,
  Footer,
  ArrowUp,
  ArrowDown,
  ContractsIcon,
  RemindersIcon,
  Link,
} from "./styles";
import { theme } from "theme";
import { StatisticDataType } from "./types";

const getIcon = (variant: string) => {
  switch (variant) {
    case "arrow-up":
      return <ArrowUp />;
    case "arrow-down":
      return <ArrowDown />;
    case "contracts":
      return <ContractsIcon />;
    case "reminders":
      return <RemindersIcon />;
  }
};

const Card = ({
  data: { id, iconName, title, tooltipText, value, linkTo, linkState },
  isLoading,
}: StatisticDataType) => {
  const { t } = useTranslation();

  return (
    <Wrapper data-testid="card">
      <Header>
        <span>
          {iconName && getIcon(iconName)}
          {title}
        </span>

        <Tooltip title={tooltipText}>
          <InfoOutlinedIcon
            sx={{ marginLeft: theme.spacing.md, fill: theme.color.gray[600] }}
          />
        </Tooltip>
      </Header>

      {!isLoading ? (
        <Body id={id}>{value ?? 0}</Body>
      ) : (
        <CircularProgress data-testid="card-loader" />
      )}

      {linkTo && (
        <Footer>
          <Link to={linkTo} state={linkState} style={{ padding: 0 }}>
            {t("common.buttons.showAll")}
          </Link>
        </Footer>
      )}
    </Wrapper>
  );
};

export default Card;
