import { Box, styled } from "@mui/material";
import { theme } from "theme";

const DetailNameWrapper = styled(Box)`
  margin-top: ${theme.spacing.md};
  color: #4a5568;
  font-weight: 500;
`;

export default DetailNameWrapper;
