const theme = {
  font: {
    family: "var(--font-family)",
    size: {
      h1: "3.25rem",
      h2: "2.75rem",
      h3: "2.25rem",
      h4: "1.75rem",
      h5: "1.5rem",
      h6: "1.25rem",
      subheading: "1rem",
      body: "0.875rem",
      caption: "0.75rem",
      icon: "1rem",
    },
    weight: {
      light: "300",
      normal: "400",
      medium: "500",
      semiBold: "600",
      bold: "700",
    },
  },
  spacing: {
    xxs: "0.125rem",
    xs: "0.25rem",
    sm: "0.5rem",
    md: "0.75rem",
    lg: "1rem",
    xl: "1.5rem",
    xxl: "2rem",
    xxxl: "3rem",
    xxxxl: "4rem",
  },
  color: {
    white: "#FFFFFF",
    black: "#000000",
    gray: {
      "100": "#F7FAFC",
      "200": "#EDF2F7",
      "300": "#E2E8F0",
      "400": "#CBD5E0",
      "500": "#A0AEC0",
      "600": "#718096",
      "700": "#4A5568",
      "800": "#2D3748",
      "900": "#1A202C",
    },
    blue: {
      "100": "#EBF8FF",
      "200": "#BEE3F8",
      "300": "#90CDF4",
      "400": "#63B3ED",
      "500": "#4299E1",
      "600": "#3182CE",
      "700": "#2B6CB0",
      "800": "#2C5282",
      "900": "#2A4365",
    },
    indigo: {
      "100": "#EBF4FF",
      "200": "#C3DAFE",
      "300": "#A3BFFA",
      "400": "#7F9CF5",
      "500": "#667EEA",
      "600": "#5A67D8",
      "700": "#4C51BF",
      "800": "#434190",
      "900": "#3C366B",
    },
    purple: {
      "100": "#FAF5FF",
      "200": "#E9D8FD",
      "300": "#D6BCFA",
      "400": "#B794F4",
      "500": "#9F7AEA",
      "600": "#805AD5",
      "700": "#6B46C1",
      "800": "#553C9A",
      "900": "#44337A",
    },
    yellow: {
      "100": "#FFFFF0",
      "200": "#FEFCBF",
      "300": "#FAF089",
      "400": "#F6E05E",
      "500": "#ECC94B",
      "600": "#D69E2E",
      "700": "#B7791F",
      "800": "#975A16",
      "900": "#744210",
    },
    orange: {
      "100": "#FFFAF0",
      "200": "#FEEBC8",
      "300": "#FBD38D",
      "400": "#F6AD55",
      "500": "#ED8936",
      "600": "#DD6B20",
      "700": "#C05621",
      "800": "#9C4221",
      "900": "#7B341E",
    },
    green: {
      "100": "#F0FFF4",
      "200": "#C6F6D5",
      "300": "#9AE6B4",
      "400": "#68D391",
      "500": "#48BB78",
      "600": "#38A169",
      "700": "#2F855A",
      "800": "#276749",
      "900": "#22543D",
    },
    teal: {
      "100": "#E6FFFA",
      "200": "#B2F5EA",
      "300": "#7edce2",
      "400": "#4FD1C5",
      "500": "#38B2AC",
      "600": "#319795",
      "700": "#2C7A7B",
      "800": "#285E61",
      "900": "#234E52",
    },
    red: {
      "100": "#FFF5F5",
      "200": "#FED7D7",
      "300": "#FEB2B2",
      "400": "#FC8181",
      "500": "#F56565",
      "600": "#E53E3E",
      "700": "#C53030",
      "800": "#9B2C2C",
      "900": "#742A2A",
    },
    pink: {
      "100": "#FFF5F7",
      "200": "#FED7E2",
      "300": "#FBB6CE",
      "400": "#F687B3",
      "500": "#ED64A6",
      "600": "#D53F8C",
      "700": "#B83280",
      "800": "#97266D",
      "900": "#702459",
    },
    mindaro: {
      "100": "#f6ffe3",
      "200": "#e3ffaa",
      "300": "#ddff99",
      "400": "#bdff39",
      "500": "#aaff00",
      "600": "#84c600",
      "700": "#5e8e00",
      "800": "#395500",
      "900": "#131c00",
    },
    lightGreen: {
      "100": "#eafce6",
      "200": "#bff6b3",
      "300": "#a6f296",
      "400": "#69e94f",
      "500": "#3fe31c",
      "600": "#31b016",
      "700": "#237e10",
      "800": "#154c09",
      "900": "#071903",
    },
    plum: {
      "100": "#f9e8f9",
      "200": "#eebbee",
      "300": "#e595e5",
      "400": "#d860d8",
      "500": "#cd32cd",
      "600": "#9f279f",
      "700": "#721c72",
      "800": "#441144",
      "900": "#170617",
    },
    salmonPink: {
      "100": "#ffe3e3",
      "200": "#ffaaaa",
      "300": "#ff9999",
      "400": "#ff3939",
      "500": "#ff0000",
      "600": "#c60000",
      "700": "#8e0000",
      "800": "#550000",
      "900": "#1c0000",
    },
    peach: {
      "100": "#feefe4",
      "200": "#fccead",
      "300": "#fbc49d",
      "400": "#f78c40",
      "500": "#f56c0a",
      "600": "#bf5408",
      "700": "#883c06",
      "800": "#522403",
      "900": "#1b0c01",
    },
  },
  shadow: {
    standard: "0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)",
    alternative:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(49, 130, 206, 0.15) 0px 0px 0px 1px",
  },
  lineHeight: "normal",
  borderRadius: ".5rem",
  labelBorderRadius: "2px",
};

export default theme;
