import styled from "@emotion/styled";
import { Button as MUIButton } from "@mui/material";
import { theme } from "theme";
import { SIDEBAR_WIDTH } from "./StickyMenu/styles";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto ${SIDEBAR_WIDTH};
`;

export const SignatureComponent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${theme.color.blue[200]};
  border-radius: ${theme.borderRadius};
  background-color: ${theme.color.blue[100]};
  padding: ${theme.spacing.md};
  color: ${theme.color.blue[900]};
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.md};
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
`;

export const Button = styled(MUIButton)`
  border: none !important;
  font-size: ${theme.font.size.caption} !important;
`;
