import React, { FC, useMemo } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { CategoryDTO } from "openapi";
import { useStyles as useStyledFields } from "../../components/StyledComponents/StyledFields";
import { CategoryLabel } from "./CategoryLabel";
import { useTranslation } from "react-i18next";

export type CategorySelectorProps = {
  categories: CategoryDTO[];
  selectedCategoryId: string;
  onSelectCategory(categoryId: string): void;
  disabled?: boolean;
};

export const CategorySelector: FC<CategorySelectorProps> = ({
  selectedCategoryId,
  onSelectCategory,
  categories,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const fieldClasses = useStyledFields();

  const activeCategory = useMemo(
    () =>
      categories
        ? categories.find((category) => category.id === selectedCategoryId)
        : undefined,
    [selectedCategoryId, categories]
  );

  return (
    <FormControl
      error={selectedCategoryId === ""}
      disabled={disabled}
      required
      fullWidth
      size="small"
    >
      <InputLabel id="category-label">
        {t("contractUpload.categoriesLabel")}
      </InputLabel>
      <Select
        labelId="category-label"
        id="category-select"
        value={selectedCategoryId}
        label={t("contractUpload.categoriesLabel")}
        className={fieldClasses.SelectField}
        onChange={(e) => {
          onSelectCategory(e.target.value);
        }}
        renderValue={(value) =>
          activeCategory && <CategoryLabel category={activeCategory} />
        }
      >
        {categories.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            <CategoryLabel category={category} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
