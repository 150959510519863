import React from "react";
import styled from "@emotion/styled";
import { theme } from "theme";

const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: ${theme.spacing.md} auto;
  background-color: ${theme.color.gray[300]};
`;

const Divider = () => <StyledDivider />;

export default Divider;
