import styled from "@emotion/styled";
import { theme } from "theme";
import TeamIcon from "assets/svg/team-icon.svg?react";

export const BackgroundBox = styled.div`
  padding: ${theme.spacing.lg} ${theme.spacing.lg};
  border-radius: ${theme.borderRadius};
  background-color: ${theme.color.gray[200]};
  margin: ${theme.spacing.md} 0;
`;

export const TeamIconFilled = styled(TeamIcon)`
  fill: ${theme.color.black};
`;

export const TeamLine = styled.p`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.md};

  span {
    width: 40px;
    flex: 0 0 auto;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SubTitle = styled.div`
  font-weight: ${theme.font.weight.semiBold};
`;

export const TeamsInfo = styled.div`
  margin-bottom: ${theme.spacing.xl};
`;

export const ContractsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
  margin-top: ${theme.spacing.lg};
`;
