/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ContractFieldLimitedDTOV1 } from "./ContractFieldLimitedDTOV1";
import type { I18nDto } from "./I18nDto";

export type ContractSectionDTOV1 = {
  id: string;
  deprecatedType?: ContractSectionDTOV1.deprecatedType | null;
  name: I18nDto;
  fields: Array<ContractFieldLimitedDTOV1>;
  deprecatedSectionDefinitionId?: string | null;
};

export namespace ContractSectionDTOV1 {
  export enum deprecatedType {
    GENERAL = "GENERAL",
    PARTNER = "PARTNER",
    DURATION = "DURATION",
    PAYMENT = "PAYMENT",
    CUSTOM = "CUSTOM",
  }
}
