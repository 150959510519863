/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OffsetConfig = {
  unit?: OffsetConfig.unit | null;
  enabled: boolean;
  offset?: number | null;
};

export namespace OffsetConfig {
  export enum unit {
    DAY = "DAY",
    WEEK = "WEEK",
    MONTH = "MONTH",
    YEAR = "YEAR",
  }
}
