import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { DateDatapointDTO } from "openapi";
import { useLocale, useUserInfo } from "hooks/GlobalStateHooks";
import { DatapointContent } from "pages/Contract/styles";
import FormDatePickerField from "components/FormItems/FormDatePicker/FormDatePicker";
import DetailNameWrapper from "components/DetailNameWrapper/DetailNameWrapper";
import { dateFormatter } from "constants/utils";
import { DataPointProps } from "./types";
import { ContactDataDTO } from "../../pages/Contacts/ContactDataDTO";

export const DateDatapoint: FC<DataPointProps> = ({
  definition,
  contact,
  editable,
}) => {
  const { locale } = useLocale();
  const { control } = useFormContext<ContactDataDTO>();
  const { userInfo } = useUserInfo();
  const userDateFormat = userInfo?.dateFormat;

  if (editable) {
    return (
      <Grid item xs={12} md={6}>
        <FormDatePickerField
          control={control}
          name={`${definition.visibleId}.value.value`}
          label={definition.name[locale]}
        />
      </Grid>
    );
  }
  const data = contact[definition.visibleId] as DateDatapointDTO;
  return (
    <Grid item xs={12} md={6}>
      <DetailNameWrapper>{definition.name[locale]}</DetailNameWrapper>
      <DatapointContent>
        {data ? dateFormatter(locale, data.value.value, userDateFormat) : "-"}
      </DatapointContent>
    </Grid>
  );
};
