import { OverlayScrollbars } from "overlayscrollbars";
import "overlayscrollbars/overlayscrollbars.css";

export const patchScrollbar = () => {
  const scrollViewports = document.querySelectorAll<HTMLElement>(
    ".ag-body-vertical-scroll-viewport, .ag-body-horizontal-scroll-viewport"
  );
  for (const viewport of scrollViewports) {
    const scrollElement = viewport.parentElement;
    if (!scrollElement) {
      continue;
    }

    OverlayScrollbars(
      {
        target: scrollElement,
        elements: {
          viewport: viewport,
        },
      },
      {}
    );
  }
};
