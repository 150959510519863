import React, { useMemo, useRef, useState } from "react";
import { StyledTextField } from "../StyledComponents/StyledBaseFields";
import {
  DatePicker as MUIDatePicker,
  DatePickerProps as MUIDatePickerProps,
  deDE,
  enUS,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { TextFieldProps } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import { useLocale, useUserInfo } from "hooks/GlobalStateHooks";
import "dayjs/locale/en";
import "dayjs/locale/de";
import { mergeRefs } from "utils/helpers";
import { useTranslation } from "react-i18next";
import { DateValidationError } from "@mui/x-date-pickers/models";
import { theme } from "theme";
import { dateFormats } from "constants/utils";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const localeMap = {
  de: {
    dayjs: "de",
    mui: deDE,
  },
  en: {
    dayjs: "de",
    mui: enUS,
  },
};

export type DatePickerProps = {
  datePickerProps: Omit<
    MUIDatePickerProps<Dayjs>,
    "value" | "onChange" | "renderInput" | "inputRef" | "ref"
  >;
  inputProps?: TextFieldProps & { suggestion?: boolean };
  margin?: "none" | "dense" | "normal";
  inputRef?: React.Ref<HTMLInputElement>;
  value: string | null;
  onDateChanged: (value: string | null) => void;
  fixOnInput?: boolean;
  disablePast?: boolean;
};

export const DatePicker = React.forwardRef<HTMLDivElement, DatePickerProps>(
  function DatePicker(
    {
      inputProps,
      margin,
      value,
      onDateChanged,
      inputRef = null,
      datePickerProps,
      disablePast,
    },
    ref
  ) {
    const { locale } = useLocale();
    const fieldRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const [error, setError] = useState<DateValidationError | null>(null);
    const { userInfo } = useUserInfo();
    const userDateFormat = userInfo?.dateFormat || dateFormats.EU_DOTS;

    const errorMessage = useMemo(() => {
      switch (error) {
        case "disablePast": {
          return t("pages.tasks.modal.fields.errors.pastDate");
        }
        default: {
          return "";
        }
      }
    }, [error]);

    const date = useMemo(() => (value ? dayjs.utc(value) : null), [value]);
    const onChange = (date: Dayjs | null) => {
      onDateChanged(date ? date.toISOString() : null);
    };

    const autoCompleteDate = useMemo(() => {
      switch (userDateFormat) {
        case dateFormats.EU_DOTS:
          return "DD.MM...YY";
        case dateFormats.US_DASHES:
        case dateFormats.US_SLASHES:
          return "MM.DD...YY";
        case dateFormats.NORMAL:
          return "YY...MM-DD";
        default:
          return "DD.MM...YY";
      }
    }, [userDateFormat]);

    const processIncompleteDate = () => {
      if (fieldRef.current?.value) {
        const parsedDate = dayjs(fieldRef.current.value, autoCompleteDate).utc(
          true
        );

        if (parsedDate.isValid()) {
          onChange(parsedDate);
        }
      }
    };

    return (
      <LocalizationProvider
        localeText={
          localeMap[locale].mui.components.MuiLocalizationProvider.defaultProps
            .localeText
        }
        adapterLocale={localeMap[locale].dayjs}
        dateAdapter={AdapterDayjs}
        dateLibInstance={dayjs.utc}
      >
        <MUIDatePicker
          ref={ref}
          {...datePickerProps}
          format={userDateFormat}
          onError={(newError) => setError(newError)}
          value={date}
          disablePast={disablePast}
          slots={{
            ...datePickerProps.slots,
            textField: datePickerProps.slots?.textField ?? StyledTextField,
          }}
          inputRef={mergeRefs([fieldRef, inputRef])}
          slotProps={{
            ...datePickerProps.slotProps,
            field: {
              ...datePickerProps.slotProps?.field,
              clearable: true,
            },
            textField: (ownerState) => ({
              ...inputProps,
              onBlur: () => {
                processIncompleteDate();
              },
              fullWidth: true,
              size: "small",
              margin: margin ?? "normal",
              helperText: errorMessage,
              sx: {
                "& .MuiFormHelperText-root": {
                  color: theme.color.yellow[600],
                },
              },
            }),
            actionBar: ({ wrapperVariant }) => ({
              actions:
                wrapperVariant === "desktop"
                  ? ["clear"]
                  : ["clear", "cancel", "accept"],
            }),
            popper: {
              ...datePickerProps.slotProps?.popper,
              modifiers: [
                {
                  name: "viewHeightModifier",
                  enabled: true,
                  phase: "beforeWrite",
                  fn: ({ state }) => {
                    if (state.styles?.popper) {
                      state.styles.popper.height = "380px";
                      if (state.placement?.includes("top-start")) {
                        state.styles.popper = {
                          ...state.styles.popper,
                          display: "flex",
                          alignItems: "flex-end",
                          marginBottom: "10px",
                        };
                      }
                      if (state.placement?.includes("bottom")) {
                        state.styles.popper = {
                          ...state.styles.popper,
                          display: "block",
                        };
                      }
                    }
                  },
                },
              ],
              sx: {
                "& .MuiPaper-root": {
                  boxShadow: theme.shadow.standard,
                },
              },
            },
          }}
          onChange={(date, ctx) => {
            if (!date || (date && date.isValid())) {
              onChange(date);
            }
          }}
        />
      </LocalizationProvider>
    );
  }
);
