import React from "react";
import { useTranslation } from "react-i18next";
import { Chip } from "components";
import { theme } from "theme";
import { TaskNewDTO } from "openapi";

import status = TaskNewDTO.status;

const statusColors: Record<status, string> = {
  [status.OPEN]: theme.color.green[600],
  [status.IN_PROGRESS]: theme.color.purple[600],
  [status.CLOSED]: theme.color.gray[600],
};

type Props = {
  data: {
    status: status;
  };
};

const StatusCellRenderer = ({ data: { status } }: Props) => {
  const { t } = useTranslation();
  const color = statusColors[status];
  const label = t(`pages.tasks.status.options.${status}`);

  return <Chip color={color}>{label}</Chip>;
};

export default StatusCellRenderer;
