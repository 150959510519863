import styled from "@emotion/styled";
import { theme } from "theme";
import { Button as MUIButton } from "@mui/material";
import { ExitToApp as ExitToAppIcon } from "@mui/icons-material";

// @TODO: Export from components and reuse it.
export const TeamIcon = styled.div`
  font-size: ${theme.font.size.body};
  color: ${theme.color.gray[600]};
  padding: ${theme.spacing.xxs} ${theme.spacing.md};
  border: 1px solid ${theme.color.gray[300]};
  border-radius: ${theme.borderRadius};
  margin-top: ${theme.spacing.xs};
`;

export const Container = styled.div`
  display: flex;
  gap: ${theme.spacing.lg};
  align-items: center;
  justify-content: space-between;
`;

export const TeamInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${theme.spacing.lg};
`;

export const TeamName = styled.div`
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.bold};
  color: ${theme.color.gray[900]};
  padding: ${theme.spacing.xs} 0;
`;

export const MemberRole = styled.div`
  font-size: ${theme.font.size.body};
  color: ${theme.color.gray[700]};
`;

export const LeaveIcon = styled(ExitToAppIcon)``;

export const LeaveButton = styled(MUIButton)`
  font-weight: ${theme.font.weight.medium};
  border-radius: ${theme.borderRadius};
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  text-transform: none;
  color: ${theme.color.white};
  background-color: ${theme.color.red[500]};

  :hover {
    background-color: ${theme.color.red[600]};
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ModalBody = styled.div`
  padding: 0 ${theme.spacing.xxl};
`;
