import React from "react";
import Grid from "@mui/material/Grid";
import { FormTextField } from "components";
import { useLocale } from "hooks/GlobalStateHooks";
import { ContractDTOV1, SingleLineTextFieldTypeDtoV1 } from "openapi";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { formatFieldValue } from "./helper";
import { useAnalysis } from "./hooks/useAnalysis";
import { DataPointProps } from "./types";
import { AnalysisWrapper } from "./components/AnalysisWrapper";
import { DatapointField } from "./components/DatapointField";
import useDataPointAnalysis from "./hooks/useDatapointAnalysis";

export const SingleLineTextDatapoint: FC<DataPointProps> = ({
  definition,
  values,
  editable,
  showAnalysis,
  contractId,
  teamId,
  fetchData,
}) => {
  const { locale } = useLocale();
  const { control } = useFormContext<ContractDTOV1>();

  const name = definition.name[locale];

  const data = values[definition.id] as SingleLineTextFieldTypeDtoV1;
  const analysis = useAnalysis(definition, data);

  const { suggestions, verify, selectInsight } = useDataPointAnalysis(
    definition,
    {
      id: contractId,
      teamId: teamId,
    },
    data
  );

  if (editable) {
    return (
      <Grid item xs={12} md={6}>
        <AnalysisWrapper
          analysis={showAnalysis ? analysis : undefined}
          fieldKey="value"
          renderField={(data, selector) => {
            return (
              <FormTextField
                control={control}
                name={`fields.${definition.id}.value`}
                label={definition.name[locale]}
                InputProps={{
                  startAdornment: selector,
                }}
                inputProps={{
                  suggestion: !!data?.selectedSuggestion.value,
                }}
              />
            );
          }}
        />
      </Grid>
    );
  }

  return (
    <Grid item xs={12} md={6}>
      <DatapointField
        definition={definition}
        data={data}
        field="value"
        suggestions={suggestions}
        onVerify={async () => {
          await verify(["value"]);
          void fetchData?.();
        }}
        onSelectInsight={async (value) => {
          await selectInsight("value", value);
          void fetchData?.();
        }}
        name={name}
      >
        {formatFieldValue(data)}
      </DatapointField>
    </Grid>
  );
};
