import { CategoryDTO } from "openapi";

export const categoriesTeamSelector = (
  category: CategoryDTO,
  teamId: string
) => {
  return category.teams.includes(teamId);
};

export const categoriesHasTeamSelector = (category: CategoryDTO) => {
  return category.teams.length > 0;
};

export const getDefaultCategory = (
  categories: CategoryDTO[] | undefined,
  teamId: string
) => {
  if (!categories) {
    return null;
  }
  for (const category of categories) {
    if (
      category.categoryTeamsInfo?.some(
        (info) => info.teamId === teamId && info.default
      )
    ) {
      return category;
    }
  }
};
