import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CardWrapper from "components/CardWrapper/CardWrapper";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import BasicTable from "components/BasicTable/BasicTable";
import { TeamListItemDto } from "openapi";
import { useTableHeaders, useTableRows } from "./helpers";
import SearchOrganization from "./SearchOrganization";
import { Row } from "components/BasicTable/types";
import { isEmpty } from "lodash";

const Organizations = () => {
  const [data, setData] = useState<TeamListItemDto[]>([]);
  const { t } = useTranslation();

  const headers = useTableHeaders();
  const rows = useTableRows(data);

  return (
    <CardWrapper>
      <CardHeaderWrapper title={t("pages.admin.tabTitles.organizations")} />
      <SearchOrganization setData={setData} />
      {!isEmpty(rows) ? (
        <BasicTable headers={headers} data={rows as Row[]} />
      ) : (
        <></>
      )}
    </CardWrapper>
  );
};

export default Organizations;
