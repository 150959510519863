import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { ContractDTOV1 } from "openapi";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import routePaths from "constants/routePaths";
import { CellContainer, NameLink } from "./styles";

import DraftIcon from "assets/svg/docs/draft.svg?react";
import ActiveIcon from "assets/svg/docs/active.svg?react";
import ProcessingIcon from "assets/svg/docs/processing.svg?react";
import SigningIcon from "assets/svg/docs/signing.svg?react";
import CancelledIcon from "assets/svg/docs/cancelled.svg?react";
import TerminatedIcon from "assets/svg/docs/terminated.svg?react";

const StatusIconMap: Record<ContractDTOV1.status, React.FunctionComponent> = {
  DRAFT: DraftIcon,
  SIGNING: SigningIcon,
  PROCESSING: ProcessingIcon,
  ACTIVE: ActiveIcon,
  CANCELLED: CancelledIcon,
  TERMINATED: TerminatedIcon,
};

export const NameCellRenderer = (props: ICellRendererParams) => {
  const { t } = useTranslation();
  const data = props.data as ContractDTOV1;
  if (!data || !data.name) return;
  const childrenCount = props.node.allChildrenCount ?? 0;

  const path = `${routePaths.CONTRACTS}/${data.id.toString()}`;
  const name = data.name.replaceAll("_", " ");

  return (
    <Tooltip title={t(`enums.status.${data.status}`)} className="editable-cell">
      <CellContainer>
        <NameLink className="hover-underline font-semibold" to={path}>
          <span className="icon-container">
            {React.createElement(StatusIconMap[data.status])}
          </span>
          <span className="name">{name}</span>
          {childrenCount > 0 && (
            <span className="children-count">{childrenCount}</span>
          )}
        </NameLink>
      </CellContainer>
    </Tooltip>
  );
};
