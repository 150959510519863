import { ContractFieldDTOV1, ContractSectionCreateDTO } from "openapi";

export const getFieldId = (
  selectedTeamId: string,
  visibleId: string,
  fields: ContractFieldDTOV1[]
) => {
  return fields.find((field) => field.visibleId === visibleId)?.id;
};

export const getDefaultSections = (
  selectedTeamId: string,
  fields: ContractFieldDTOV1[]
): ContractSectionCreateDTO[] => {
  const sections = [
    {
      name: {
        en: "General",
        de: "Generell",
      },
      deprecatedType: ContractSectionCreateDTO.deprecatedType.GENERAL,
      fields: [
        {
          id: getFieldId(selectedTeamId, "partnerCompany", fields),
        },
        {
          id: getFieldId(selectedTeamId, "internalTeam", fields),
        },
      ].filter((f) => !!f.id),
    },
    {
      name: {
        en: "Duration",
        de: "Laufzeit",
      },
      deprecatedType: ContractSectionCreateDTO.deprecatedType.DURATION,
      fields: [
        {
          id: getFieldId(selectedTeamId, "duration", fields),
        },
      ].filter((f) => !!f.id),
    },
    {
      name: {
        en: "Financial",
        de: "Finanzielles",
      },
      deprecatedType: ContractSectionCreateDTO.deprecatedType.PAYMENT,
      fields: [
        {
          id: getFieldId(selectedTeamId, "paymentType", fields),
        },
        {
          id: getFieldId(selectedTeamId, "paymentCycle", fields),
        },
        {
          id: getFieldId(selectedTeamId, "paymentPriceNet", fields),
        },
        {
          id: getFieldId(selectedTeamId, "paymentPriceGross", fields),
        },
        {
          id: getFieldId(selectedTeamId, "paymentPriceMonthly", fields),
        },
        {
          id: getFieldId(selectedTeamId, "paymentTax", fields),
        },
        {
          id: getFieldId(selectedTeamId, "paymentMethod", fields),
        },
      ].filter((f) => !!f.id),
    },
  ];

  return sections as unknown as ContractSectionCreateDTO[];
};
