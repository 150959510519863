// import * as Sentry from "@sentry/react";
import { enqueueSnackbar } from "notistack";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  OrganizationNewService,
  TaskAutomationCreateDTO,
  TaskAutomationUpdateDTO,
} from "openapi";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

export const useTaskAutomationsQuery = (organizationId: string) => {
  return useQuery({
    queryKey: ["task-automations", organizationId],
    queryFn: async () => {
      return await OrganizationNewService.getTaskAutomations(organizationId);
    },
    enabled: !!organizationId,
    onError: (error: Error) => {
      console.error(error);
    },
  });
};

export const useGetTaskAutomationByIdQuery = (
  organizationId: string,
  taskAutomationId: string
) => {
  return useQuery({
    queryKey: ["task-automation", organizationId, taskAutomationId],
    queryFn: async () => {
      return await OrganizationNewService.getTaskAutomationById(
        organizationId,
        taskAutomationId
      );
    },
    enabled: !!organizationId && !!taskAutomationId,
    onError: (error: Error) => {
      console.error(error);
    },
  });
};

export const useCreateTaskAutomationMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: {
      organizationId: string;
      requestBody: TaskAutomationCreateDTO;
    }) => {
      return OrganizationNewService.createTaskAutomation(
        data.organizationId,
        data.requestBody
      );
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["task-automation", variables.organizationId],
      });

      enqueueSnackbar(
        t(
          "pages.settings.organization.taskAutomations.notifications.success.create"
        ),
        { variant: "success" }
      );
    },
    onError: (error: Error) => {
      Sentry &&
        Sentry.captureMessage(
          `Something went wrong while trying to create a task automation: ${JSON.stringify(
            error
          )}`
        );

      enqueueSnackbar(
        t(
          "pages.settings.organization.taskAutomations.notifications.error.create"
        ),
        { variant: "error" }
      );
    },
  });
};

export const useUpdateTaskAutomationMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: {
      organizationId: string;
      taskAutomationId: string;
      requestBody: TaskAutomationUpdateDTO;
    }) => {
      return OrganizationNewService.updateTaskAutomation(
        data.organizationId,
        data.taskAutomationId,
        data.requestBody
      );
    },
    onSuccess: async (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["task-automation", variables.organizationId],
      });
      await queryClient.refetchQueries({
        queryKey: [
          "task-automation",
          variables.organizationId,
          variables.taskAutomationId,
        ],
      });

      enqueueSnackbar(
        t(
          "pages.settings.organization.taskAutomations.notifications.success.update"
        ),
        { variant: "success" }
      );
    },
    onError: (error: Error) => {
      Sentry &&
        Sentry.captureMessage(
          `Something went wrong while trying to update the task automation: ${JSON.stringify(
            error
          )}`
        );
      enqueueSnackbar(
        t(
          "pages.settings.organization.taskAutomations.notifications.error.update"
        ),
        { variant: "error" }
      );
    },
  });
};

export const useDeleteTaskAutomationMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: {
      organizationId: string;
      taskAutomationId: string;
    }) => {
      return OrganizationNewService.deleteTaskAutomation(
        data?.organizationId,
        data?.taskAutomationId
      );
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["task-automations", variables.organizationId],
        exact: true,
      });

      enqueueSnackbar(
        t(
          "pages.settings.organization.taskAutomations.notifications.success.delete"
        ),
        { variant: "success" }
      );
    },
    onError: (error: Error) => {
      Sentry &&
        Sentry.captureMessage(
          `Something went wrong while trying to delete a task automation: ${JSON.stringify(
            error
          )}`
        );

      enqueueSnackbar(
        t(
          "pages.settings.organization.taskAutomations.notifications.error.delete"
        ),
        {
          variant: "error",
        }
      );
    },
  });
};
