import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

const CircularLoading = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <Backdrop
      sx={{
        color: "#3182ce",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isLoading}
      invisible
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default CircularLoading;
