import { RecipientDto } from "openapi";
import * as yup from "yup";

export const invalidCharactersRegex = /[äöü]/;

export const validationSchema = () =>
  yup.object().shape({
    language: yup.string().required(),
    message: yup.string().required(),
    signers: yup.array().of(
      yup.object().shape({
        name: yup.string().required(),
        email: yup
          .string()
          .transform((value: string) => value.toLowerCase())
          .email()
          .test("unique-emails", function () {
            const { path, createError } = this;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            const signers: RecipientDto[] = this.from[1].value.signers;
            if (signers.length > 1) {
              const emails = signers.map((signer: RecipientDto) =>
                signer.email.toLowerCase()
              );
              const isUnique = new Set(emails).size === emails.length;
              return (
                isUnique ||
                createError({
                  path,
                  message:
                    "pages.contractDetails.modals.requestSignature.errors.duplicateEmails",
                })
              );
            }
            return true;
          })
          .test("no-umlauts", function (value) {
            const { path, createError } = this;

            if (value && invalidCharactersRegex.test(value)) {
              return createError({
                path,
                message:
                  "pages.contractDetails.modals.requestSignature.errors.invalidCharacters",
              });
            }

            return true;
          })
          .required(),
      })
    ),
  });
