import React from "react";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import BackupForm from "./BackupForm";
import BackupLog from "./BackupLog";
import { useTeam } from "contexts/team/hooks";
import { Navigate } from "react-router-dom";
import { Features } from "constants/features";
import routePaths from "constants/routePaths";
import { useTranslation } from "react-i18next";

const Backup = () => {
  const { t } = useTranslation();

  const { hasFeature } = useTeam();

  if (!hasFeature(Features.BACKUP)) {
    return <Navigate to={routePaths.HOME} />;
  }

  return (
    <>
      <BackupForm />
      <CardHeaderWrapper title={t("pages.settings.tabs.backup.log.title")} />
      <BackupLog />
    </>
  );
};

export default Backup;
