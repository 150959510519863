/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  OrganizationService,
  TaskNewCreateDTO,
  TaskNewDTO,
  TaskNewService,
  TaskNewUpdateDTO,
} from "openapi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useTaskQuery = (
  organizationId: string,
  teamId: string,
  taskId?: string
) => {
  return useQuery({
    queryKey: ["tasks", teamId, taskId],
    queryFn: () =>
      TaskNewService.getTask(taskId ?? "unknown", organizationId, teamId),
    enabled: !!teamId && !!taskId && !!organizationId,
    refetchOnWindowFocus: false,
  });
};

export const useCreateTaskMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      organizationId: string;
      teamId: string;
      requestBody: TaskNewCreateDTO;
    }) => {
      return TaskNewService.createTask(
        data.organizationId,
        data.teamId,
        data.requestBody
      );
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["tasks", variables.teamId],
      });
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });
};

export const useUpdateTaskMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      taskId: string;
      organizationId: string;
      teamId: string;
      requestBody: TaskNewUpdateDTO;
    }) => {
      return TaskNewService.updateTask(
        data.taskId,
        data.organizationId,
        data.teamId,
        data.requestBody
      );
    },
    onSuccess: async (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["tasks", variables.teamId],
      });
      await queryClient.refetchQueries({
        queryKey: ["tasks", variables.teamId, variables.taskId],
      });
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });
};

export const useDeleteTaskMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      taskId: string;
      organizationId: string;
      teamId: string;
    }) => {
      return TaskNewService.deleteTask(
        data.taskId,
        data.organizationId,
        data.teamId
      );
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["tasks", variables.teamId],
        exact: true,
      });
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });
};

export const useTasksQuery = (
  teamId: string,
  contactId?: string,
  organizationId?: string,
  contractId?: string
) => {
  const queryKey = ["tasks", teamId];
  let queryFn: () => Promise<TaskNewDTO[]>;
  let enabled: boolean;

  if (contactId) {
    queryFn = () =>
      OrganizationService.getTasksByContact(contactId, organizationId!, teamId);
    enabled = !!teamId && !!contactId && !!organizationId;
  } else if (contractId) {
    queryFn = () =>
      TaskNewService.getTasksByContract(
        teamId,
        contractId,
        organizationId as string
      );
    enabled = !!teamId && !!organizationId && !!contractId;
  } else {
    queryFn = () => TaskNewService.getTasks(organizationId as string, teamId);
    enabled = !!teamId && !!organizationId;
  }

  return useQuery({ queryKey, queryFn, enabled });
};
