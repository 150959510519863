import styled from "@emotion/styled";
import { theme } from "theme";
import CheckDenyIcon from "assets/svg/check-cross-icon.svg?react";
import CheckApproveIcon from "assets/svg/check-square-icon.svg?react";

export const Values = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
`;

export const Wrapper = styled.div`
  font-size: ${theme.font.size.body};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xxs};
`;

export const ValueIconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  svg {
    transform: rotate(90deg);
    width: 20px;
    height: 20px;
    flex: 0 0 auto;
  }
`;

export const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  svg {
    width: 16px;
    height: 16px;
    flex: 0 0 auto;
  }
`;

export const DenyIcon = styled(CheckDenyIcon)`
  fill: ${theme.color.red[800]};
`;

export const AllowIcon = styled(CheckApproveIcon)`
  fill: ${theme.color.green[800]};
`;
