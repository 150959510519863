import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useAuthentication, useUserInfo } from "hooks/GlobalStateHooks";
import NotificationItem from "components/Notifications/Notifications";
import Logo from "components/Logo/Logo";
import { ProfileDropdown } from "..";
import { Wrapper, Container } from "./styles";

const TopNavigation = () => {
  const { userInfo } = useUserInfo();
  const { t } = useTranslation();
  const { exitImpersonate } = useAuthentication();

  const impersonated = userInfo?.impersonate as string;

  return (
    <Wrapper impersonated={impersonated}>
      <Logo />

      <Container>
        {userInfo?.admin &&
          (userInfo?.impersonate ? (
            <Button className="avatar-button" onClick={exitImpersonate}>
              <BackspaceIcon fontSize="small" />
              <span className="user-name">{t("navbar.exitImpersonation")}</span>
            </Button>
          ) : (
            <Link to="/kiosk">
              <Button className="avatar-button">
                <CampaignIcon fontSize="small" />
                <span className="user-name">{t("navbar.admin")}</span>
              </Button>
            </Link>
          ))}
        <NotificationItem />
        <ProfileDropdown />
      </Container>
    </Wrapper>
  );
};

export default TopNavigation;
