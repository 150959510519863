import React from "react";
import { useTranslation } from "react-i18next";
import { ZoomOutMap } from "@mui/icons-material";
import { EmptyList } from "./styles";

const EmptyFieldsGroup = () => {
  const { t } = useTranslation();

  return (
    <EmptyList>
      <ZoomOutMap />
      <span>
        {t(
          "pages.settings.organization.administrator.sections.droppableArea.message"
        )}
      </span>
    </EmptyList>
  );
};

export default EmptyFieldsGroup;
