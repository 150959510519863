import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  padding: ${theme.spacing.lg};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${theme.borderRadius};
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.lg};
`;
