import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { ApiError, TeamCreateDto, TeamService, UserService } from "openapi";
import { useGuideModal, useUserInfo } from "hooks/GlobalStateHooks";
import { useTeam } from "contexts/team/hooks";
import { CTAButton, FormTextField, SectionHeader } from "components";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import { ForbiddenResponse } from "shared/types/ErrorResponse.type";
import { FormButtons } from "./styles";
import { useQueryClient } from "@tanstack/react-query";
import CardWrapper from "components/CardWrapper/CardWrapper";
import PermissionsForm, {
  CheckBoxesProps,
} from "pages/Settings/OrganizationSettings/Team/components/PermissionsForm";
import TreeIcon from "assets/svg/tree-icon.svg?react";
import { HeaderIconStyles } from "pages/Settings/OrganizationSettings/Teams/styles";

const validationSchema = () =>
  Yup.object({
    name: Yup.string().required(),
  });

type TeamInfoType = {
  name: string;
  parentTeam?: string;
};

const initialValues: TeamInfoType = {
  name: "",
  parentTeam: "",
};

type Props = {
  header?: JSX.Element | string | null;
  variant: "parent" | "sub-team";
  shouldRedirect?: boolean;
  onCancel?: () => void;
  isNewDesign?: boolean;
  refetch?: () => void;
};

const TeamCreationForm = ({
  header,
  variant,
  shouldRedirect,
  onCancel,
  isNewDesign,
  refetch,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { refreshUserInfo } = useUserInfo();
  const { selectedTeamId, setSelectedTeam, organizationId } = useTeam();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const { setShowGuideModal } = useGuideModal();
  const queryClient = useQueryClient();
  const [checkedBoxes, setCheckedBoxes] = useState<CheckBoxesProps>({});
  const { control, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema()),
  });
  const [nameFieldValue, setNameFieldValue] = useState<string>("");

  // aka Team
  async function updateOrganizationData(id: string) {
    await UserService.setDefaultTeam(id);
    await refreshUserInfo();
    await setSelectedTeam(id);
    navigate("/home");
  }

  const handleCreate = async (
    data: TeamInfoType,
    categoryPermissions: string[]
  ) => {
    try {
      const teamData: TeamCreateDto = {
        name: data.name,
      };

      if (variant === "sub-team") {
        await TeamService.addTeam(selectedTeamId, {
          ...teamData,
          categoryIds: categoryPermissions,
        });
        await refreshUserInfo();
        void queryClient.invalidateQueries({
          queryKey: ["organizationAllTeams", organizationId],
        });
      } else {
        await TeamService.createTeam(teamData).then(async (responseId) => {
          await updateOrganizationData(responseId);
        });
        setShowGuideModal(true);
      }
      enqueueSnackbar(t("pages.teamCreation.messages.successfulTeamCreation"), {
        variant: "success",
      });
    } catch (error) {
      if (!(error instanceof ApiError)) return;
      if (
        error.status === 403 &&
        (error.body as ForbiddenResponse).message === "team limit reached"
      ) {
        enqueueSnackbar(
          t(
            "pages.settings.tabs.subTeams.section.createTeam.validation.maxTeamLimit"
          ),
          {
            variant: "error",
          }
        );
      }
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNameFieldValue(e.target.value);
  };

  const onSubmit = (values: TeamInfoType) => {
    const categoryPermissions = [];
    for (const key of Object.keys(checkedBoxes)) {
      if (checkedBoxes[key]) {
        categoryPermissions.push(key);
      }
    }
    if (variant === "sub-team" && categoryPermissions.length === 0) {
      enqueueSnackbar(
        t(
          "pages.settings.tabs.subTeams.section.createTeam.validation.atLeastOneCategory"
        ),
        {
          variant: "error",
        }
      );
      return;
    }

    setIsLoading(true);
    void handleCreate(values, categoryPermissions).then(() => {
      if (shouldRedirect) {
        navigate("/home");
      }
      setIsLoading(false);
      reset();
      refetch?.();
      if (onCancel) {
        onCancel();
      }
    });
  };

  // @Todo: Replace when all buttons are in design system.
  const SubmitButton = () =>
    isNewDesign ? (
      <CTAButton
        name={
          isLoading
            ? t("pages.teamCreation.loading")
            : t("pages.teamCreation.submit")
        }
        variant="primary"
        type="submit"
        onClick={handleSubmit(onSubmit)}
      />
    ) : (
      <Button
        id="submitBtn"
        type="submit"
        fullWidth
        disabled={isLoading || !nameFieldValue}
        className={classes.baseButton}
        onClick={handleSubmit(onSubmit)}
      >
        {isLoading
          ? t("pages.teamCreation.loading")
          : t("pages.teamCreation.submit")}
      </Button>
    );

  return (
    <div>
      {header}
      <form style={{ margin: "1rem 0" }} name="teamCreationForm" noValidate>
        <FormTextField
          style={{ marginBottom: "1rem" }}
          control={control}
          name="name"
          label={t("pages.settings.tabs.team.teamNameForm.name")}
          required
          margin="none"
          InputProps={{
            onInput: onChange,
          }}
        />
        {variant === "sub-team" ? (
          <CardWrapper height="500px" overflow="auto">
            <SectionHeader
              title={t(
                "pages.settings.tabs.subTeams.section.showSubTeams.categoryAccess"
              )}
              icon={<TreeIcon />}
              noPadding
              iconStyle={HeaderIconStyles}
              hideButton={true}
            />
            <PermissionsForm
              hideButton={true}
              setParentCheckboxes={setCheckedBoxes}
            />
          </CardWrapper>
        ) : null}

        <FormButtons>
          {onCancel ? (
            <Button
              type="button"
              size="medium"
              className={classes.baseButtonNoBackground}
              disabled={isLoading}
              onClick={onCancel}
            >
              {t("pages.teamCreation.cancel")}
            </Button>
          ) : null}
          <SubmitButton />
        </FormButtons>
      </form>
    </div>
  );
};

export default TeamCreationForm;
