import styled from "@emotion/styled";
import { theme, breakpoints } from "theme";

export const Wrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.md};
  flex-wrap: wrap;
  margin-bottom: ${theme.spacing.xl};

  @media (max-width: ${breakpoints.md}) {
    gap: ${theme.spacing.lg};
  }
`;
