import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};
`;

export const TitleWrapper = styled.h3`
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.medium};
  color: ${theme.color.gray[900]};
  margin: 0;
  line-height: 1.5rem;
`;

export const TeamNameWrapper = styled.h2`
  font-size: ${theme.font.size.h6};
  color: ${theme.color.blue[600]};
`;

type ActionButtonsProps = {
  isInline?: boolean;
};

export const ActionButtons = styled.div<ActionButtonsProps>`
  display: ${({ isInline }) => (isInline ? "inline-flex" : "flex")};
  gap: ${theme.spacing.sm};
  width: max-content;

  .edit:hover {
    color: ${theme.color.blue[600]};
  }

  .delete:hover {
    color: ${theme.color.red[600]};
  }
`;
