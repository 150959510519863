import React, { ChangeEvent, useEffect, useState } from "react";
import { BasicTable, Loader, SectionHeader } from "components";
import CardWrapper from "components/CardWrapper/CardWrapper";
import {
  Wrapper,
  HeaderIconStyles,
  Message,
  CellWithIcon,
  AssigneeNameWrapper,
  AssigneeName,
  BinIcon,
  TableRow,
  ActionButtons,
} from "./styles";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TaskAutomationIcon from "assets/svg/task-automation-icon.svg?react";

import { Header, Row } from "components/BasicTable/types";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import routePaths from "constants/routePaths";
import { TaskAutomationDTO, TeamMemberDto } from "openapi";
import {
  useFieldsQuery,
  useOrganizationCategoriesQuery,
  useTaskAutomationsQuery,
  useTeamMembersQuery,
} from "shared/api";
import { useTeam } from "contexts/team/hooks";
import { useLocale } from "hooks";
import { isNil } from "lodash";
import { DeleteModalConfirmation } from "./components/DeleteTaskAutomation";

import {
  getAssigneesWithoutAccessToCategory,
  getCategoriesWithoutDateField,
} from "./utils";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ActionsModal from "./components/ActionsModal";
import SearchBar from "./components/SearchBar";
import { useTranslation } from "react-i18next";
import { getRelativeFieldName } from "../../../Tasks/components/TasksTable/helpers";
import Expandable from "./components/Expandable/Expandable";

const TaskAutomations = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedTaskAutomation, setSelectedTaskAutomation] =
    useState<TaskAutomationDTO>();
  const { organizationId, selectedTeamId } = useTeam();
  const { locale } = useLocale();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActionsModal, setShowActionsModal] = useState(false);
  const [filteredTaskAutomations, setFilteredTaskAutomations] = useState<
    TaskAutomationDTO[] | null | undefined
  >([]);

  const { data: categories, isLoading: categoriesLoading } =
    useOrganizationCategoriesQuery(organizationId);
  const { data: teamMembersData, isLoading: membersLoading } =
    useTeamMembersQuery(selectedTeamId);
  const { data: fields } = useFieldsQuery(organizationId);
  const { data: taskAutomations, isLoading: taskAutomationsLoading } =
    useTaskAutomationsQuery(organizationId);

  const handleOnCreate = () =>
    navigate(`${routePaths.SETTINGS_TASK_AUTOMATION}/new`);

  useEffect(() => {
    setFilteredTaskAutomations(taskAutomations);
  }, [taskAutomations]);

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filteredItems = taskAutomations?.filter((item) =>
      item.title.toLowerCase().includes(e.target.value.toLowerCase())
    );
    if (filteredItems) setFilteredTaskAutomations(filteredItems);
  };

  if (membersLoading || categoriesLoading || taskAutomationsLoading)
    return <Loader />;

  const tableHeaders = [
    {
      key: "title",
      name: t(
        "pages.settings.organization.taskAutomations.overviewTable.headings.title"
      ),
    },
    {
      key: "dueDate",
      name: t(
        "pages.settings.organization.taskAutomations.overviewTable.headings.dueDate"
      ),
      notSortable: true,
    },
    {
      key: "reminder",
      name: t(
        "pages.settings.organization.taskAutomations.overviewTable.headings.reminder"
      ),
      notSortable: true,
    },
    {
      key: "assignee",
      name: t(
        "pages.settings.organization.taskAutomations.overviewTable.headings.assignee"
      ),
      notSortable: true,
    },
    {
      key: "creator",
      name: t(
        "pages.settings.organization.taskAutomations.overviewTable.headings.creator"
      ),
    },
    {
      key: "actions",
      name: t(
        "pages.settings.organization.taskAutomations.overviewTable.headings.actions"
      ),
      notSortable: true,
    },
  ] as Header[];

  const tableData = filteredTaskAutomations
    ?.sort((a, b) => a.title.localeCompare(b.title))
    .map((item) => {
      const member = teamMembersData?.members?.find(
        (member: TeamMemberDto) => member.id === item.creatorId
      );
      const taskCategories = item.categoryIds.map((categoryId) =>
        categories?.find((category) => category.id === categoryId)
      );

      const taskAssignees = teamMembersData?.members?.filter((member) =>
        item.assigneeIds?.includes(member.id)
      );
      const categoriesWithoutDateField = getCategoriesWithoutDateField(
        categories,
        item?.categoryIds,
        item?.dueDateConfig?.relativeFieldId as string
      );

      const assigneesWithoutAccessToCategory =
        getAssigneesWithoutAccessToCategory(
          categories,
          teamMembersData,
          item?.categoryIds,
          item?.assigneeIds as string[]
        );

      const displayViewIcon =
        categoriesWithoutDateField?.length ||
        assigneesWithoutAccessToCategory?.length;

      const dueDateConfigOffset = item?.dueDateConfig?.offset;
      const dueDateConfigUnit = item?.dueDateConfig?.unit;
      const reminderConfigOffset = item?.reminderConfig?.offset;
      const reminderConfigUnit = item?.reminderConfig?.unit;

      const dueDate =
        !isNil(dueDateConfigOffset) &&
        fields &&
        item?.dueDateConfig?.relativeFieldId &&
        item?.dueDateConfig?.relativeFieldKey
          ? t(
              "pages.settings.organization.taskAutomations.overviewTable.body.dueDate",
              {
                dueDateConfigOffset,
                dueDateConfigUnit: t(
                  `common.intevalEnums.${dueDateConfigUnit as string}`
                ),
                timePreposition: t("common.timePrepositions.before"),
                relativeFieldName: getRelativeFieldName(
                  item?.dueDateConfig?.relativeFieldId,
                  item?.dueDateConfig?.relativeFieldKey,
                  fields,
                  locale,
                  t
                ),
              }
            )
          : "-";

      const reminder =
        !isNil(reminderConfigOffset) && !isNil(reminderConfigUnit)
          ? t(
              "pages.settings.organization.taskAutomations.overviewTable.body.reminder",
              {
                reminderConfigOffset,
                reminderConfigUnit: t(
                  `common.intevalEnums.${reminderConfigUnit as string}`
                ),
                timePreposition: t("common.timePrepositions.before"),
              }
            )
          : "-";

      return {
        id: item.id,
        title: (
          <Expandable
            maxVisibleItems={4}
            item={item}
            categories={taskCategories}
          />
        ),
        dueDate: (
          <CellWithIcon>
            <span>{dueDate}</span>
            {categoriesWithoutDateField?.length ? (
              <InfoOutlinedIcon fontSize="small" />
            ) : null}
          </CellWithIcon>
        ),
        reminder: <span>{reminder}</span>,
        creator: (
          <span data-order-key={member?.firstname}>
            {member?.firstname} {member?.lastname}
          </span>
        ),
        assignee: (
          <CellWithIcon>
            <AssigneeNameWrapper>
              {taskAssignees?.map((assignee) => {
                return (
                  <AssigneeName key={assignee.id}>
                    {assignee?.firstname}
                  </AssigneeName>
                );
              })}
            </AssigneeNameWrapper>
            {assigneesWithoutAccessToCategory?.length ? (
              <InfoOutlinedIcon fontSize="small" />
            ) : null}
          </CellWithIcon>
        ),
        actions: (
          <ActionButtons className="action-buttons">
            <IconButton
              onClick={() => {
                setSelectedTaskAutomation(item);
                setShowDeleteModal(true);
              }}
            >
              <BinIcon />
            </IconButton>
            {displayViewIcon ? (
              <IconButton
                onClick={() => {
                  setSelectedTaskAutomation(item);
                  setShowActionsModal(true);
                }}
              >
                <RemoveRedEyeOutlinedIcon fontSize="small" />
              </IconButton>
            ) : null}
          </ActionButtons>
        ),
      };
    });

  return (
    <>
      <Wrapper>
        <CardWrapper>
          <SectionHeader
            title={t("pages.settings.organization.taskAutomations.title")}
            icon={<TaskAutomationIcon />}
            iconStyle={HeaderIconStyles}
            noPadding
            buttonVariant="primary"
            baseButtonText={t(
              "pages.settings.organization.taskAutomations.addNewButton"
            )}
            buttonId="create-task-automation"
            onButtonClick={handleOnCreate}
          />
          <SearchBar onChange={onFilterChange} />
          {filteredTaskAutomations && filteredTaskAutomations.length ? (
            <BasicTable
              headers={tableHeaders}
              data={tableData as Row[]}
              components={{ TableRow }}
            />
          ) : (
            <Message>
              {t(
                "pages.settings.organization.taskAutomations.notifications.warnings.noTaskAutomationsFound"
              )}
            </Message>
          )}
        </CardWrapper>
      </Wrapper>
      <>
        {selectedTaskAutomation ? (
          <>
            <DeleteModalConfirmation
              open={showDeleteModal}
              handleClose={() => setShowDeleteModal(false)}
              selectedTaskAutomation={selectedTaskAutomation}
            />
            <ActionsModal
              open={showActionsModal}
              handleClose={() => setShowActionsModal(false)}
              categories={categories}
              teamMembersData={teamMembersData}
              fields={fields}
              selectedTaskAutomation={selectedTaskAutomation}
            />
          </>
        ) : (
          <></>
        )}
      </>
    </>
  );
};

export default TaskAutomations;
