import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Wrapper, Title, AccessWrapper, ValueIconWrapper } from "./styles";

import {
  PermissionChangeLogDTO,
  TeamUpdateLogDTO,
  TeamMemberDto,
  CategoryDTO,
  ParentTeamDTO,
  CategoryChange,
  TeamDTOV1,
} from "openapi";

import { useLocale } from "hooks";
import StatusLabel from "../StatusLabel/StatusLabel";
import TypeLabel from "../TypeLabel/TypeLabel";
import FromTo from "../FromTo/FromTo";
import ArrowIcon from "assets/svg/arrow-up.svg?react";
import CategoryBadge from "../CategoryBadge/CategoryBadge";
import { DELETED_CATEGORY_COLOR } from "../../helpers";
import Collapsible from "../Collapsible/Collapsible";

export type TeamEditType = {
  data: TeamUpdateLogDTO;
  event: PermissionChangeLogDTO.event;
  members: TeamMemberDto[];
  categories: CategoryDTO[];
  organization: ParentTeamDTO;
};

const TeamEdit = ({
  data,
  event,
  members,
  categories,
  organization,
}: TeamEditType) => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  const showCategories = !!data?.categories?.length;
  const GRANTED = t(
    "pages.settings.organization.permissionChangeLog.messages.granted"
  );

  const convertedCategoriesData = useMemo(() => {
    if (event !== PermissionChangeLogDTO.event.TEAM_CREATED || !showCategories)
      return;

    return data?.categories?.map((item) => ({
      id: item,
      action: CategoryChange.action.ALLOWED,
    }));
  }, [data?.categories]);

  const convertedFromToData = (item: CategoryChange) => {
    return event === PermissionChangeLogDTO.event.TEAM_CREATED
      ? {
          to: item?.action === CategoryChange.action.ALLOWED,
        }
      : {
          from: item?.action !== CategoryChange.action.ALLOWED,
          to: item?.action === CategoryChange.action.ALLOWED,
        };
  };

  const teamName = useMemo(() => {
    return organization?.teams?.find((item: TeamDTOV1) => item.id === data.id)
      ?.name;
  }, [data?.id]);

  const renderTitleByEventType = () => {
    switch (event) {
      case PermissionChangeLogDTO.event.TEAM_CREATED:
        return (
          <Title>
            <StatusLabel
              title={t(
                `pages.settings.organization.permissionChangeLog.status.${event}`
              )}
              variant={event}
            />
            <TypeLabel
              variant="teamName"
              title={data.name as unknown as string}
            />
            with
            <TypeLabel variant="categoryAccess" />
            {GRANTED}
          </Title>
        );
      case PermissionChangeLogDTO.event.TEAM_UPDATED:
        return getTeamUpdatedTitle();

      case PermissionChangeLogDTO.event.TEAM_DELETED:
        return (
          <Title>
            <StatusLabel
              title={t(
                `pages.settings.organization.permissionChangeLog.status.${event}`
              )}
              variant={event}
            />
            <TypeLabel
              variant="teamName"
              title={data.name as unknown as string}
            />
          </Title>
        );
      default:
        return null;
    }
  };

  const renderCategoriesByEventType = () => {
    if (!showCategories) return null;

    const categoriesData =
      event === PermissionChangeLogDTO.event.TEAM_CREATED
        ? convertedCategoriesData
        : data.categories;

    return categoriesData?.map((item, index) => {
      const category = categories.find((category) => category.id === item.id);
      const categoryName = category
        ? category.name[locale]
        : t("common.labels.deletedCategory");
      const categoryColor = category?.color || DELETED_CATEGORY_COLOR;

      const fromTo = convertedFromToData(item as CategoryChange);

      return (
        <AccessWrapper key={index}>
          <FromTo data={fromTo} />
          <CategoryBadge color={categoryColor} name={categoryName} />
        </AccessWrapper>
      );
    });
  };

  const getTeamUpdatedTitle = () => {
    const titleChanged = !!data?.name?.from;
    const categoryAccessChanged = !!data?.categories?.length;

    return (
      <Title>
        <StatusLabel
          title={t(
            `pages.settings.organization.permissionChangeLog.status.${event}`
          )}
          variant={event}
        />
        {titleChanged && (
          <>
            <TypeLabel
              variant="teamName"
              title={data?.name?.from as unknown as string}
            />
            <ValueIconWrapper>
              <ArrowIcon />
            </ValueIconWrapper>
            <TypeLabel
              variant="teamName"
              title={data?.name?.to as unknown as string}
            />
          </>
        )}
        {categoryAccessChanged && (
          <>
            <TypeLabel variant="categoryAccess" />
            {t(
              `pages.settings.organization.permissionChangeLog.messages.forTeam`,
              { teamName }
            )}
          </>
        )}
      </Title>
    );
  };

  const entityTitle = renderTitleByEventType();
  const entityBody = renderCategoriesByEventType();

  return (
    <Wrapper>
      {entityTitle}
      <Collapsible>{entityBody}</Collapsible>
    </Wrapper>
  );
};

export default TeamEdit;
