import * as math from "mathjs";
import {
  AmountFieldTypeDtoV1,
  ContractUpdateDto,
  ContractDTOV1,
  ContractFieldDTOV1,
  FormulaFieldData,
  FormulaFieldTypeDtoV1,
  FormulaFieldVariable,
} from "openapi";
import React, { FC } from "react";
import { AmountDatapoint } from "./AmountDatapoint";
import { SingleLineTextDatapoint } from "./SingleLineTextDatapoint";
import { NumberDatapoint } from "./NumberDatapoint";
import { getPaymentPriceCycle } from "pages/Contracts/helpers";
import { DataPointProps } from "./types";
import { useLocale } from "hooks/GlobalStateHooks";
import { useFormContext, useWatch } from "react-hook-form";
import { AmountFormType } from "components/FormItems/FormAmountField/FormAmountField";
import { getFieldIdByVisibleId } from "./helper";

export type FormulaDatapointProps = {
  name: string;
  data: FormulaFieldTypeDtoV1;
  field: ContractFieldDTOV1;
  fields: ContractFieldDTOV1[];
  contractData: ContractDTOV1["fields"];
};

export type FormulaAmountResult = {
  name: string;
  value: number;
  currency: string | null;
  type: ContractFieldDTOV1.type.AMOUNT;
};

type FormulaNumberResult = {
  name: string;
  value: number;
  type?: Omit<ContractFieldDTOV1.type, ContractFieldDTOV1.type.AMOUNT>;
};

export type FormulaResult = FormulaNumberResult | FormulaAmountResult;

export const evaluateForumla = ({
  name,
  data,
  field,
  fields,
  contractData,
}: FormulaDatapointProps): FormulaResult => {
  if (field.data) {
    data = (field.data as FormulaFieldData).formula;
  }

  const parser = math.parser();
  let mainVariable: FormulaFieldVariable | null = null;
  let referencedMainField;
  let displayAs: ContractFieldDTOV1.type | undefined =
    data.displayAs as unknown as ContractFieldDTOV1.type;

  for (const variableData of data.variables) {
    const referencedField = fields.find(
      (field) =>
        (field.visibleId === variableData.ref ||
          field.id === variableData.ref) &&
        field.id in contractData
    );

    if (!referencedField) {
      console.warn(
        `referenced field (${variableData.ref}) doesn't exist, which invalidates the formula`
      );
      return {
        name,
        value: NaN,
      };
    }

    if (!referencedMainField) {
      mainVariable = variableData;
      referencedMainField = referencedField;
    }

    if (variableData.isMain) {
      mainVariable = variableData;
      referencedMainField = referencedField;
      if (referencedMainField) {
        displayAs = referencedMainField.type;
      }
    }

    const field = contractData[referencedField.id];
    if (!field) {
      console.warn("field data doesn't exist, which invalidates the formula");
      return {
        name,
        value: NaN,
      };
    }
    if ("value" in field) {
      if (field.value === null) {
        console.warn(
          "referenced field contains null, which invalidates the formula"
        );
        return {
          name,
          value: NaN,
        };
      }
      let value = field.value;
      if (variableData.ref === "paymentCycle") {
        value = getPaymentPriceCycle(value as ContractUpdateDto.paymentCycle);
      }
      parser.set(variableData.variable, value);
    } else {
      console.warn(
        "referenced field is not supported for use in formula field"
      );
      return {
        name,
        value: NaN,
      };
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const evaluatedValue = parser.evaluate(data.value);

  if (!displayAs || !mainVariable || !referencedMainField) {
    return {
      name,
      value: evaluatedValue as number,
    };
  }

  switch (displayAs) {
    case ContractFieldDTOV1.type.AMOUNT: {
      const referencedDataField = contractData[
        referencedMainField.id
      ] as AmountFieldTypeDtoV1;

      return {
        type: ContractFieldDTOV1.type.AMOUNT,
        name,
        value: evaluatedValue as number,
        currency: referencedDataField.currency,
      };
    }
    default: {
      return {
        name,
        value: evaluatedValue as number,
      };
    }
  }
};

export const FormulaDatapoint: FC<DataPointProps> = ({
  definition,
  definitions,
  values,
  editable,
  contactTypes,
  contactDefinitions,
  contacts,
  refetchContacts,
  categoryId,
  teamId,
  contractId,
}) => {
  const { locale } = useLocale();

  const { control } = useFormContext<AmountFormType>();
  const formValues = useWatch({ control }) as ContractDTOV1;

  const amountNettoFieldId = getFieldIdByVisibleId(
    definitions,
    "paymentPriceNet"
  );

  const paymentTaxFieldId = getFieldIdByVisibleId(definitions, "paymentTax");

  useWatch({
    name: `fields.${amountNettoFieldId}.value`,
    control: control,
  });

  useWatch({
    name: `fields.${paymentTaxFieldId}.value`,
    control: control,
  });

  const fieldsWithValue: ContractDTOV1["fields"] = {};

  for (const id in formValues.fields) {
    const fieldValue = (formValues.fields[id] as { value: string | number })
      .value;

    if (fieldValue !== undefined) {
      fieldsWithValue[id] = formValues.fields[id];
    }
  }

  const data = editable
    ? (formValues.fields[definition.id] as unknown as FormulaFieldTypeDtoV1)
    : (values[definition.id] as FormulaFieldTypeDtoV1);

  const name = definition.name[locale];
  const formulaResult = evaluateForumla({
    name,
    data,
    field: definition,
    fields: definitions,
    contractData: editable ? fieldsWithValue : values,
  });

  if (isNaN(formulaResult.value)) {
    return (
      <SingleLineTextDatapoint
        definition={definition}
        definitions={definitions}
        values={{ [definition.id]: { value: "-" } }}
        contactTypes={contactTypes}
        contactDefinitions={contactDefinitions}
        contacts={contacts}
        refetchContacts={refetchContacts}
        categoryId={categoryId}
        teamId={teamId}
        contractId={contractId}
      />
    );
  }
  switch (formulaResult.type) {
    case ContractFieldDTOV1.type.AMOUNT: {
      const result = formulaResult as FormulaAmountResult;
      return (
        <AmountDatapoint
          definition={definition}
          values={{
            [definition.id]: {
              value: result.value,
              currency: result.currency,
            },
          }}
          definitions={definitions}
          contactTypes={contactTypes}
          contactDefinitions={contactDefinitions}
          contacts={contacts}
          refetchContacts={refetchContacts}
          categoryId={categoryId}
          teamId={teamId}
          contractId={contractId}
        />
      );
    }
    default:
      return (
        <NumberDatapoint
          definition={definition}
          definitions={definitions}
          values={{
            [definition.id]: {
              value: formulaResult.value,
            },
          }}
          contactTypes={contactTypes}
          contactDefinitions={contactDefinitions}
          contacts={contacts}
          refetchContacts={refetchContacts}
          categoryId={categoryId}
          teamId={teamId}
          contractId={contractId}
        />
      );
  }
};
