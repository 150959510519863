import React from "react";
import { Arrow, RoundedBox } from "./styles";

export type ArrowIconProps = { orientation?: string; onClick?: () => void };

const ArrowIcon = ({ orientation, onClick, ...props }: ArrowIconProps) => (
  <RoundedBox {...props} onClick={onClick}>
    <Arrow fontSize="small" orientation={orientation} />
  </RoundedBox>
);

export default ArrowIcon;
