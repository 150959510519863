export const openWindow = () => {
  const size = 800;
  const x = window.screenLeft + size / 2;
  const y = window.screenTop + size / 4;

  return window.open(
    undefined,
    "_blank",
    `toolbar=yes,scrollbars=yes,resizable=yes,left=${x},top=${y},width=${size},height=${size}`
  );
};
