import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  margin-top: ${theme.spacing.xl};
`;

export const Form = styled.form`
  margin: ${theme.spacing.sm} 0;
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacing.md};
  gap: ${theme.spacing.md};
`;

export const BackButton = styled.div`
  margin-bottom: ${theme.spacing.lg};
`;

export const Heading = styled.div`
  font-size: ${theme.font.size.body};
  margin-bottom: ${theme.spacing.xs};
`;

export const Name = styled.div`
  font-size: ${theme.font.size.body};
  font-weight: ${theme.font.weight.bold};
`;

export const AlertWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.lg};
  border: 2px solid ${theme.color.red[400]};
  border-radius: ${theme.borderRadius};
  background: ${theme.color.red[200]};
`;

export const AlertText = styled.div`
  flex: 1 1 auto;
  & h3 {
    margin: 0;
  }
`;

export const AlertIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin-right: ${theme.spacing.xl};
  flex: 0 0 auto;
  background: ${theme.color.red[600]};
`;

export const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.xs} 0;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${theme.font.weight.medium};
  margin-bottom: ${theme.spacing.sm};
  padding-bottom: ${theme.spacing.sm};
  border-bottom: 1px solid ${theme.color.gray[400]};
`;
