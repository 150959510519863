export const getListItems = (list?: string) => {
  if (!list) return null;

  return list
    .trim()
    .split("\n")
    .filter((item) => item !== "")
    .map((item) => item.trim());
};

export const hasDuplicateListItems = (list: string[]) => {
  const set = new Set();
  for (const item of list) {
    if (set.has(item)) return true;
    set.add(item);
  }
  return false;
};
