import React, { useMemo, useState } from "react";
import CardWrapper from "components/CardWrapper/CardWrapper";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { SectionSubtitle, SectionText, TextLink } from "./styles";
import { CTAButton, FormTextField, NewModal } from "components";
import { Form } from "./styles";
import { useTeam } from "contexts/team/hooks";
import {
  useOrganizationQuery,
  useUpdateOrganization,
} from "shared/api/organization/organization";
import { OrganizationUpdateDTO } from "openapi";
import DelegationModalContent from "./DelegationModalContent/DelegationModalContent";

export type LinkType = {
  onClick: () => void;
  children?: JSX.Element;
};

const Link = ({ onClick, children }: LinkType) => (
  <TextLink type="button" onClick={onClick}>
    {children}
  </TextLink>
);

type DelegationUpdateForm = Pick<OrganizationUpdateDTO, "authDelegation">;

const DelegationForm = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const { organizationId } = useTeam();
  const {
    data: organizationInfo,
    isLoading,
    refetch,
    error,
  } = useOrganizationQuery(organizationId);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<DelegationUpdateForm>();

  const updateOrganization = useUpdateOrganization();

  const handleLinkClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const onSubmit = async (data: OrganizationUpdateDTO) => {
    if (!data) {
      return;
    }

    const requestBody = data?.authDelegation?.id
      ? data
      : {
          authDelegation: null,
        };

    await updateOrganization.mutateAsync({
      organizationId,
      requestBody: requestBody,
    });
    reset();
    await refetch();
  };

  const organisationName = useMemo(() => {
    reset({
      authDelegation: {
        id: organizationInfo?.authDelegation?.id,
      },
    });
    return organizationInfo?.authDelegation?.name || "";
  }, [organizationInfo]);

  if (!organizationInfo || isLoading || error) return null;

  const delegationId = organizationInfo?.delegationId;
  const buttonDisabled = !isDirty;

  return (
    <>
      <CardWrapper>
        <CardHeaderWrapper
          title={t(
            "pages.settings.tabs.team-security.delegationSettings.title"
          )}
          subTitle={
            <Trans
              i18nKey="pages.settings.tabs.team-security.delegationSettings.description"
              components={{ linkComponent: <Link onClick={handleLinkClick} /> }}
            />
          }
        />
        <SectionSubtitle>
          {t("pages.settings.tabs.team-security.delegationSettings.subtitle")}
        </SectionSubtitle>
        <SectionText>
          {t("pages.settings.tabs.team-security.delegationSettings.info")}
        </SectionText>
        <Form
          name="delegationForm"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <FormTextField
            control={control}
            disabled={false}
            name="authDelegation.id"
            label={t(
              "pages.settings.tabs.team-security.delegationSettings.form.placeholder"
            )}
            helperText={
              organisationName &&
              t(
                "pages.settings.tabs.team-security.delegationSettings.hints.organisationName",
                { organisationName }
              )
            }
          />
          <CTAButton
            label="updateBtn"
            name={t("pages.settings.tabs.backup.form.submit")}
            size="stretched"
            disabled={buttonDisabled}
            type="submit"
          />
        </Form>
      </CardWrapper>

      <NewModal
        fullWidth
        open={showModal}
        handleClose={handleModalClose}
        title={t(
          "pages.settings.tabs.team-security.delegationSettings.modal.title"
        )}
        body={<DelegationModalContent delegationId={delegationId} />}
        footer={
          <>
            <CTAButton
              name={t("common.buttons.close")}
              variant="secondary"
              onClick={handleModalClose}
            />
          </>
        }
      />
    </>
  );
};

export default DelegationForm;
