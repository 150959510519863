import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { ColorPoint } from "../components/Header/components/CategorySelector/styles";
import { useOrganizationCategoriesQuery } from "shared/api/organization/categories";
import {
  useContractTemplatesQueryV1,
  useGetAllContractTemplateTagsQuery,
} from "shared/api/contract-templates";
import { categoriesTeamSelector } from "shared/api/organization/categories.helpers";
import { useTeam } from "contexts/team/hooks";
import { useLocale } from "hooks";
import {
  CategoryDTO,
  ContractTemplateTagDTO,
  ContractTemplateV1DTO,
} from "openapi";
import { TextField, Tag } from "new-components";
import routePaths from "constants/routePaths";
import { TemplateCard } from "./components";
import {
  AllButton,
  Filters,
  FilterSection,
  FilterTitle,
  List,
  ListItem,
  Container,
  SearchBlock,
  TemplatesContainer,
  TemplatesGallery,
  CreateTemplateCard,
  CreateNewCaption,
  CreateNewButton,
  TagList,
  Divider,
} from "./styles";

const Collection = () => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const navigate = useNavigate();
  const { organizationId, selectedTeamId } = useTeam();
  const { data: categories } = useOrganizationCategoriesQuery(
    organizationId,
    (categories) =>
      categories.filter((category) =>
        categoriesTeamSelector(category, selectedTeamId)
      )
  );
  const { data: tags } = useGetAllContractTemplateTagsQuery(organizationId);
  const { data: templates } = useContractTemplatesQueryV1(organizationId);

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const filteredTemplates = useMemo(() => {
    return templates?.filter((template) => {
      const filters = [
        () =>
          selectedCategories.length === 0 ||
          selectedCategories.includes(template.categoryId),
        () =>
          selectedTags.length === 0 ||
          template.tags.some((tag) => selectedTags.includes(tag.id)),
        () =>
          !searchTerm ||
          template.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ];
      return filters.every((filter) => filter());
    });
  }, [templates, selectedCategories, selectedTags, searchTerm]);

  const toggleCategory = (category: CategoryDTO) => {
    setSelectedCategories((prev) =>
      prev.includes(category.id)
        ? prev.filter((id) => id !== category.id)
        : [...prev, category.id]
    );
  };

  const toggleTag = (tag: ContractTemplateTagDTO) => {
    setSelectedTags((prev) =>
      prev.includes(tag.id)
        ? prev.filter((id) => id !== tag.id)
        : [...prev, tag.id]
    );
  };

  const searchByName = (term: string) => setSearchTerm(term);

  const resetFilters = () => {
    setSelectedCategories([]);
    setSelectedTags([]);
    setSearchTerm("");
  };

  return (
    <Container>
      <Filters>
        <FilterSection>
          <FilterTitle>
            {t(
              "pages.settings.tabs.contractTemplates.gallery.filters.title.templates"
            )}
          </FilterTitle>
          <div className="all">
            <AllButton
              name={t(
                "pages.settings.tabs.contractTemplates.gallery.filters.title.all"
              )}
              onClick={resetFilters}
              size="stretched"
            />
          </div>
        </FilterSection>

        <FilterSection>
          <FilterTitle>
            {t(
              "pages.settings.tabs.contractTemplates.gallery.filters.title.categories"
            )}
          </FilterTitle>
          <OverlayScrollbarsComponent defer style={{ overflowY: "auto" }}>
            <List>
              {categories?.map((category) =>
                category.name[locale].length < 19 ? (
                  <ListItem
                    key={category.id}
                    onClick={() => toggleCategory(category)}
                    style={{
                      fontWeight: selectedCategories.includes(category.id)
                        ? "bold"
                        : "normal",
                    }}
                  >
                    <ColorPoint color={category.color} />
                    <span>{category.name[locale]}</span>
                  </ListItem>
                ) : (
                  <Tooltip title={category.name[locale]} key={category.id}>
                    <ListItem
                      onClick={() => toggleCategory(category)}
                      style={{
                        fontWeight: selectedCategories.includes(category.id)
                          ? "bold"
                          : "normal",
                      }}
                    >
                      <ColorPoint color={category.color} />
                      <span>{category.name[locale]}</span>
                    </ListItem>
                  </Tooltip>
                )
              )}
            </List>
          </OverlayScrollbarsComponent>
        </FilterSection>

        <Divider />

        <FilterSection>
          <FilterTitle>
            {t(
              "pages.settings.tabs.contractTemplates.gallery.filters.title.tags"
            )}
          </FilterTitle>
          <OverlayScrollbarsComponent>
            <List>
              <TagList>
                {tags?.map((tag) => (
                  <div
                    key={tag.id}
                    onClick={() => toggleTag(tag)}
                    className="tag"
                  >
                    <Tag
                      variant={
                        selectedTags.includes(tag.id) ? "selected" : "tag"
                      }
                    >
                      {tag.name}
                    </Tag>
                  </div>
                ))}
              </TagList>
            </List>
          </OverlayScrollbarsComponent>
        </FilterSection>
      </Filters>

      <OverlayScrollbarsComponent style={{ width: "100%" }}>
        <TemplatesContainer>
          <SearchBlock>
            <TextField
              fullWidth
              name="search-contract-template"
              onChange={(event) => searchByName(event.target.value)}
              placeholder={t(
                "pages.settings.tabs.contractTemplates.gallery.searchBar.placeholder"
              )}
              value={searchTerm}
            />
          </SearchBlock>

          <h3>{t("pages.settings.tabs.contractTemplates.gallery.title")}</h3>

          <TemplatesGallery>
            <CreateTemplateCard
              onClick={() =>
                navigate(`${routePaths.SETTINGS_CONTRACT_TEMPLATES}/new`)
              }
            >
              <CreateNewButton>+</CreateNewButton>
              {t(
                "pages.settings.tabs.contractTemplates.gallery.createTemplateCard.title"
              )}
              <CreateNewCaption>
                {t(
                  "pages.settings.tabs.contractTemplates.gallery.createTemplateCard.caption"
                )}
              </CreateNewCaption>
            </CreateTemplateCard>

            {filteredTemplates?.map((template) => (
              <TemplateCard
                key={template.id}
                template={{
                  ...template,
                  content: (template as ContractTemplateV1DTO)?.content ?? "",
                }}
                categories={categories}
                onClick={() =>
                  navigate(
                    `${routePaths.SETTINGS_CONTRACT_TEMPLATES}/${template.id}`
                  )
                }
              />
            ))}
          </TemplatesGallery>
        </TemplatesContainer>
      </OverlayScrollbarsComponent>
    </Container>
  );
};

export default Collection;
