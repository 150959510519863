import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useSnackbar } from "notistack";
import { ContractDTOV1, ContractNameDto, ContractService } from "openapi";
import { useTeam } from "contexts/team/hooks";
import { useStyles as useStyledButtons } from "components/StyledComponents/StyledBaseButtons";
import { getNavigationToContractViewById } from "constants/utils";
import { NewModal, CTAButton } from "components";
import {
  Body,
  BodyColumn,
  Description,
  DocumentType,
  Title,
  AttachmentsCount,
  AttachmentList,
  AttachmentItem,
  SingleFile,
  MultipleFiles,
} from "./styles";

type Props = {
  contractData?: ContractDTOV1;
  contractGroup: ContractNameDto[];
};

const DeleteContract = ({ contractData: data, contractGroup }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const buttonClasses = useStyledButtons();
  const { hasWriteAccess } = useTeam();
  const [showDeleteContractModal, setShowDeleteContractModal] = useState(false);

  const handleOnClose = () => setShowDeleteContractModal(false);

  const handleOnDelete = async () => {
    if (!id) return;

    await ContractService.deleteContract(id);

    enqueueSnackbar(
      t("pages.contracts.messages.successfulDeletion", {
        contractName: data?.name,
      }),
      { variant: "success" }
    );

    let contractId = data?.parentId;

    if (contractId) {
      if (
        !contractGroup.find(
          (contractGroupItem) => contractId === contractGroupItem.id
        )
      ) {
        contractId = contractGroup.find(
          (contractGroupItem) => id !== contractGroupItem.id
        )?.id;
      }
    }

    navigate(getNavigationToContractViewById(contractId), {
      state: { deleted: true, contractName: data?.name },
    });

    handleOnClose();
  };

  const hasAccess = hasWriteAccess(data?.categoryId, data?.teamId);

  if (!hasAccess || !id) return null;

  const mainContract = contractGroup.find((doc) => !doc.parentId);
  const isMainContract = mainContract?.id === data?.id;
  const attachments = contractGroup.filter((doc) => doc.parentId);
  const isContractWithAttachments = attachments.length > 0;

  const modalTitle = () => {
    let title = t("pages.contracts.modals.delete.title.attachment");

    if (isMainContract && isContractWithAttachments) {
      title = t("pages.contracts.modals.delete.title.contractGroup");
    }

    if (isMainContract && !isContractWithAttachments) {
      title = t("pages.contracts.modals.delete.title.contract");
    }

    return title;
  };

  const confirmDeletionButtonLabel = () => {
    let label = t("pages.contracts.modals.delete.submit.attachment");

    if (isMainContract && isContractWithAttachments) {
      label = t("pages.contracts.modals.delete.submit.contractGroup");
    }

    if (isMainContract && !isContractWithAttachments) {
      label = t("pages.contracts.modals.delete.submit.contract");
    }

    return label;
  };

  const getDescription = () => {
    let description = t("pages.contracts.modals.delete.description.attachment");

    if (isMainContract && isContractWithAttachments) {
      description = t(
        "pages.contracts.modals.delete.description.contractGroup"
      );
    }

    if (isMainContract && !isContractWithAttachments) {
      description = t("pages.contracts.modals.delete.description.contract");
    }

    return description;
  };

  const visual =
    isMainContract && isContractWithAttachments ? (
      <MultipleFiles />
    ) : (
      <SingleFile />
    );
  const title = modalTitle();
  const description = getDescription();
  const docType = data?.parentId
    ? t("contractType.ATTACHMENT")
    : t("contractType.CONTRACT");
  const deleteButtonLabel = confirmDeletionButtonLabel();

  return (
    <>
      <Button
        id="deleteBtn"
        className={buttonClasses.previewHeaderDeleteButton}
        onClick={() => setShowDeleteContractModal(true)}
        sx={{ textTransform: "none" }}
      >
        <DeleteForeverIcon fontSize="small" />
      </Button>

      <NewModal
        open={showDeleteContractModal}
        handleClose={() => setShowDeleteContractModal(false)}
        title={title}
        fullWidth
        body={
          <Body>
            <BodyColumn>{visual}</BodyColumn>

            <BodyColumn>
              <Description>{description}</Description>

              <DocumentType>{docType}</DocumentType>

              <Title>{data?.name}</Title>

              {isContractWithAttachments && isMainContract && (
                <>
                  <AttachmentsCount>
                    {t("pages.contracts.modals.delete.attachments", {
                      count: attachments.length,
                    })}
                  </AttachmentsCount>

                  <AttachmentList>
                    {attachments.map((attachment) => (
                      <AttachmentItem key={attachment.id}>
                        {attachment.name}
                      </AttachmentItem>
                    ))}
                  </AttachmentList>
                </>
              )}
            </BodyColumn>
          </Body>
        }
        footer={
          <>
            <CTAButton
              onClick={handleOnClose}
              variant="secondary"
              name={t("common.buttons.cancel")}
            />
            <CTAButton
              onClick={handleOnDelete}
              variant="primary"
              color="red"
              name={deleteButtonLabel}
            />
          </>
        }
      />
    </>
  );
};

export default DeleteContract;
