import styled from "@emotion/styled";
import { Card, Divider as MUIDivider } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { theme } from "theme";

export const Wrapper = styled(Card)<{ padding?: number; maxwidth?: number }>`
  padding: ${(prop) => (prop.padding ? `${prop.padding}rem` : "1.5rem")};
  max-width: ${(prop) => (prop.maxwidth ? `${prop.maxwidth}rem` : "36rem")};
  justify-content: center;
  margin: auto;
  border-radius: ${theme.borderRadius};
  border: 1px solid ${theme.color.gray[200]};
  position: relative;
`;

export const TitleWrapper = styled.h1`
  font-size: ${theme.font.size.h5};
  font-weight: ${theme.font.weight.semiBold};
  text-align: center;
  margin-bottom: 0px;
`;

export const SubTitleWrapper = styled.p`
  font-size: ${theme.font.size.subheading};
  margin: ${theme.spacing.md} auto 0;
  padding: 0;
  color: ${theme.color.gray[600]};
  text-align: center;
`;

export const AuthCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing.md};
  padding: 0 2.5rem;
  margin: ${theme.spacing.xxl} 0;
  width: 100%;
`;

export const Divider = styled(MUIDivider)`
  border: none;
  margin: 0 ${theme.spacing.lg} ${theme.spacing.lg};
  height: 1px;
  width: calc(100% - 5.5rem);
  background-color: ${theme.color.gray[200]};
`;

export const FooterLink = styled(RouterLink)`
  color: ${theme.color.gray[700]} !important;

  :hover {
    text-decoration: underline !important;
  }

  :last-child:not(:only-of-type) {
    margin-left: ${theme.spacing.xxl};
    position: relative;
  }

  :last-child:not(:only-of-type)::before {
    position: absolute;
    content: "";
    top: 50%;
    margin-left: -${theme.spacing.lg};
    height: 2px;
    width: 2px;
    border-radius: 50%;
    background-color: ${theme.color.gray[700]};
  }
`;
