import styled from "@emotion/styled";
import {
  Add as MUIAddIcon,
  Remove as MUIRemoveIcon,
  Edit as MUIEditIcon,
  DeleteForever as MUIDeleteIcon,
  ManageAccounts as MUIManageAccounts,
} from "@mui/icons-material";
import { theme } from "theme";

export const ActionButtons = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};

  .edit:hover {
    color: ${theme.color.blue[600]};
  }

  .delete:hover {
    color: ${theme.color.red[600]};
  }
`;

export const AddIcon = styled(MUIAddIcon)``;

export const RemoveIcon = styled(MUIRemoveIcon)``;

export const DeleteIcon = styled(MUIDeleteIcon)``;

export const EditIcon = styled(MUIEditIcon)``;

export const AddRoleIcon = styled(MUIManageAccounts)``;

export const TeamRoleMembersContainer = styled.div`
  margin-bottom: ${theme.spacing.xl};
  padding-bottom: ${theme.spacing.lg};
  border-bottom: 1px solid ${theme.color.gray[400]};
`;

export const TeamRoleMembersTitle = styled.div`
  margin-bottom: ${theme.spacing.md};
  font-weight: ${theme.font.weight.semiBold};
  color: ${theme.color.gray[700]};
  font-size: ${theme.font.size.body};
`;

export const TeamRoleMembersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.sm};
`;

export const NoPermissions = styled.div`
  color: ${theme.color.gray[700]};
  padding: ${theme.spacing.sm} ${theme.spacing.xs};
`;
