import styled from "@emotion/styled";
import { theme } from "theme";

export const Subheading = styled.span`
  display: inline-block;
  font-weight: ${theme.font.weight.bold};
  margin-bottom: ${theme.spacing.lg};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xl};
`;

export const Description = styled.span`
  font-weight: ${theme.font.weight.medium};
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg};
`;
