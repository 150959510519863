import { Modal } from "components";
import { LexicalEditor } from "lexical";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { Box, Button, DialogActions } from "@mui/material";
import { useStyles } from "components/StyledComponents/StyledZones";
import { useStyles as useButtonStyles } from "components/StyledComponents/StyledBaseButtons";
import { theme } from "theme";

import Image from "@mui/icons-material/AddPhotoAlternate";
import { INSERT_IMAGE_COMMAND } from "../plugins/ImagePlugin";

type InsertImageProps = {
  open: boolean;
  editor: LexicalEditor;
  onClose: () => void;
};

export const InsertImage: FC<InsertImageProps> = ({
  editor,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const [image, setImage] = useState<string>();

  const onDrop = (acceptedFiles: File[]) => {
    if (image) {
      URL.revokeObjectURL(image);
    }
    setImage(URL.createObjectURL(acceptedFiles[0]));
  };

  const reset = () => {
    if (image) {
      URL.revokeObjectURL(image);
      setImage(undefined);
    }
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, image]);

  const dropzone = useDropzone({
    accept: { "image/*": [".png", ".gif", ".jpeg", ".jpg"] },
    onDrop: onDrop,
  });

  const onConfirm = () => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === "string") {
        editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
          src: reader.result,
          altText: "",
        });
        onClose();
      }
    };
    reader.readAsDataURL(dropzone.acceptedFiles[0]);
  };

  return (
    <Modal open={open} isShowHeader={false} isShowActionButtons={false}>
      <>
        <Box
          {...dropzone.getRootProps({
            className: classes.baseDropzone,
          })}
        >
          <input {...dropzone.getInputProps()} data-cy="dropzone" />
          <Box
            className="flex-center"
            sx={{
              width: "100%",
              marginBottom: theme.spacing.xs,
            }}
          >
            {image ? (
              <img src={image} alt="preview" />
            ) : (
              <Image fontSize="large" htmlColor={theme.color.blue[500]} />
            )}
          </Box>
          <Box
            sx={{
              fontWeight: "600",
              color: "#4299e1",
              fontSize: "0.92rem !important",
              cursor: "pointer",
              ":hover": {
                textDecoration: "underline",
                color: "#007bff",
              },
            }}
          >
            {t("pages.settings.tabs.contractTemplates.imageModal.description")}
          </Box>
        </Box>
        <DialogActions>
          <Button
            id="cancel"
            className={buttonClasses.cancelButton}
            onClick={onClose}
          >
            {t("common.buttons.cancel")}
          </Button>
          <Button
            id="addSectionButton"
            type="submit"
            className={buttonClasses.saveButton}
            onClick={onConfirm}
          >
            {t("common.buttons.confirm")}
          </Button>
        </DialogActions>
      </>
    </Modal>
  );
};
