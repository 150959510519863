/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OffsetConfig } from "./OffsetConfig";

export type TaskNewDTO = {
  id: string;
  status: TaskNewDTO.status;
  dueDateType?: TaskNewDTO.dueDateType | null;
  createdAt?: string;
  updatedAt?: string;
  title: string;
  description: string;
  contractId: string;
  assigneeIds?: Array<string> | null;
  dueDateConfig?: Record<string, any>;
  reminderConfig?: OffsetConfig | null;
  teamId: string;
  isMigrated: boolean;
};

export namespace TaskNewDTO {
  export enum status {
    OPEN = "OPEN",
    IN_PROGRESS = "IN_PROGRESS",
    CLOSED = "CLOSED",
  }

  export enum dueDateType {
    FIXED = "FIXED",
    RELATIVE = "RELATIVE",
  }
}
