import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { Divider, InputAdornment } from "@mui/material";
import { AuthMethodsDTO } from "openapi";
import { useTeam } from "contexts/team/hooks";
import { CTAButton, FormTextField } from "components";
import { CheckboxLabel, CheckboxWrapper, Form, FormHint } from "./styles";
import { FormCheckbox } from "components/FormItems/FormCheckbox/FormCheckbox";
import CardWrapper from "components/CardWrapper/CardWrapper";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import { useTeamQuery, useTeamUpdateMutation } from "shared/api/team";
import { InfoTooltip } from "components/InfoTooltip/InfoTooltip";
import { StyledTextField } from "components/StyledComponents/StyledBaseFields";
import { CopyToClipboardButton } from "components";

const initialValues: AuthMethodsDTO & { customEnabled: boolean } = {
  password: false,
  google: false,
  microsoft: false,
  oidc: null,
  customEnabled: false,
};

const LoginMethodForm = () => {
  const { t } = useTranslation();
  const { selectedTeamId } = useTeam();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: { ...initialValues },
  });

  const { data } = useTeamQuery(selectedTeamId);
  const updateTeam = useTeamUpdateMutation();

  const customEnabled = watch("customEnabled");

  useEffect(() => {
    if (data) {
      reset({
        ...data.authMethods,
        customEnabled: !!data.authMethods?.oidc,
      });
    }
  }, [data]);

  const isAllDisabled =
    !watch("google") &&
    !watch("microsoft") &&
    !watch("password") &&
    !watch("customEnabled");

  const onSubmit = async (values: typeof initialValues) => {
    if (isAllDisabled) {
      return;
    }
    try {
      await updateTeam.mutateAsync({
        teamId: selectedTeamId,
        team: {
          authMethods: {
            password: values.password,
            google: values.google,
            microsoft: values.microsoft,
            oidc:
              customEnabled && values.oidc
                ? {
                    ...values.oidc,
                    discoveryUrl: values.oidc.discoveryUrl.replace(
                      /\/.well-known\/openid-configuration$/,
                      ""
                    ),
                  }
                : null,
          },
        },
      });
      enqueueSnackbar(
        t("pages.settings.tabs.team-security.information.updated"),
        {
          variant: "success",
        }
      );
    } catch (e) {
      enqueueSnackbar(
        t("pages.settings.tabs.team-security.information.error"),
        {
          variant: "error",
        }
      );
    }
  };

  const isOpenIDConnectToggleable = !!data?.hostname;

  const redirectUrl = `${
    window.location.hostname.startsWith(data?.hostname ?? "")
      ? window.location.origin
      : window.location.origin.replace(
          window.location.hostname,
          `${data?.hostname ?? ""}.${window.location.hostname}`
        )
  }/federated-identity/oidc`;

  const frontChannelLogout = `${
    window.location.hostname.startsWith(data?.hostname ?? "")
      ? window.location.origin
      : window.location.origin.replace(
          window.location.hostname,
          `${data?.hostname ?? ""}.${window.location.hostname}`
        )
  }/logout`;

  return (
    <CardWrapper>
      <CardHeaderWrapper
        title={t("pages.settings.tabs.team-security.form.socialLogin.title")}
        subTitle={t(
          "pages.settings.tabs.team-security.form.socialLogin.description"
        )}
      />
      <Form name="methodsForm" onSubmit={handleSubmit(onSubmit)} noValidate>
        <CheckboxWrapper>
          <FormCheckbox
            control={control}
            name="password"
            label={t(
              "pages.settings.tabs.team-security.form.passwordAuthEnabled"
            )}
          />
          <FormCheckbox
            control={control}
            name="google"
            label={t(
              "pages.settings.tabs.team-security.form.googleAuthEnabled"
            )}
          />
          <FormCheckbox
            control={control}
            name="microsoft"
            label={t(
              "pages.settings.tabs.team-security.form.microsoftAuthEnabled"
            )}
          />
          <FormCheckbox
            control={control}
            name="customEnabled"
            disabled={!isOpenIDConnectToggleable}
            label={
              <CheckboxLabel
                style={{ display: "flex", gap: "4px", alignItems: "center" }}
              >
                {t("pages.settings.tabs.team-security.form.oidcAuthEnabled")}
                <InfoTooltip>
                  {t("pages.settings.tabs.team-security.form.oidcUnavailable")}
                </InfoTooltip>
              </CheckboxLabel>
            }
          />
        </CheckboxWrapper>
        {customEnabled && (
          <>
            <FormTextField
              control={control}
              name="oidc.discoveryUrl"
              label={t("pages.settings.tabs.team-security.form.discoveryUrl")}
              disabled={!isOpenIDConnectToggleable}
              required
            />
            <FormTextField
              control={control}
              name="oidc.clientId"
              label={t("pages.settings.tabs.team-security.form.clientId")}
              disabled={!isOpenIDConnectToggleable}
              required
            />
            <FormTextField
              control={control}
              name="oidc.clientSecret"
              label={t("pages.settings.tabs.team-security.form.clientSecret")}
              disabled={!isOpenIDConnectToggleable}
              required
            />
            <Divider />
            <StyledTextField
              sx={{ mt: 2 }}
              size="small"
              label={t("pages.settings.tabs.team-security.form.redirectUrl")}
              value={redirectUrl}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyToClipboardButton content={redirectUrl} />
                  </InputAdornment>
                ),
              }}
            />
            <StyledTextField
              sx={{ mt: 2 }}
              size="small"
              label={t(
                "pages.settings.tabs.team-security.form.frontChannelLogoutUrl"
              )}
              value={frontChannelLogout}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyToClipboardButton content={frontChannelLogout} />
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
        <FormHint error={isAllDisabled}>
          {t("pages.settings.tabs.team-security.information.atLeastOne")}
        </FormHint>
        <CTAButton
          label="updateBtn"
          name={t("pages.settings.tabs.backup.form.submit")}
          size="stretched"
          type="submit"
          disabled={!isDirty}
        />
      </Form>
    </CardWrapper>
  );
};

export default LoginMethodForm;
