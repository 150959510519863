import React, { useEffect, useMemo, useReducer, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  ApiError,
  ContractAttachmentService,
  ContractCreatedDto,
  ContractDTOV1,
  OrganizationService,
} from "openapi";
import { useMatch, useNavigate } from "react-router-dom";
import { useStyles } from "../StyledComponents/StyledZones";
import { useStyles as useStyledBaseButtons } from "../StyledComponents/StyledBaseButtons";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { UploadTypeEnum } from "shared/enums/contractUpload.enum";
import ClosedFolder from "../../assets/svg/folder-closed.svg?react";
import routePaths from "../../constants/routePaths";
import { useUploadStatus } from "../../hooks/GlobalStateHooks";
import { AlertTypeEnum } from "shared/enums/alert.enum";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import {
  getPrefilledFields,
  getSizeLimit,
  isTotalUploadSizeValid,
  totalSizeInMegaBytes,
} from "./helpers";
import { ForbiddenResponse } from "shared/types/ErrorResponse.type";
import { useTeam } from "contexts/team/hooks";
import {
  ContractFile,
  ContractUploadItem,
  ContractUploadItemType,
} from "./ContractUploadItem";
import { nanoid } from "nanoid";
import { FilenameLabel, PaddedGrid, Row, TitleWrapper } from "./styles";
import { UploadMultipleFilesProps } from ".";
import * as Sentry from "@sentry/react";
import { useFieldsQuery } from "shared/api";
import DropZone from "./DropZone/DropZone";
import { useOrganizationCategoriesQuery } from "shared/api/organization/categories";
import { useContractUploadMutation } from "shared/api/contracts";
import { categoriesTeamSelector } from "shared/api/organization/categories.helpers";
import AlertV2 from "components/AlertV2/AlertV2";
import WarningIcon from "@mui/icons-material/Warning";
import { theme } from "theme";
import { Switch } from "new-components";

enum ContractUploadKind {
  ADD = "ADD",
  REMOVE = "REMOVE",
  CHANGE_CATEGORY = "CHANGE_CATEGORY",
  SET_MAIN_FILE = "SET_MAIN_FILE",
  CHANGE_UPLOAD_STATUS = "CHANGE_UPLOAD_STATUS",
}

type ChangeCategoryPayload = { id: string; categoryId: string };
type ChangeUploadStatusPayload = {
  status: { isUploaded: boolean; errorMessage: string };
  contractTempId: string;
};

type ContractUploadAction =
  | {
      type: ContractUploadKind.ADD;
      payload: ContractFile | ContractFile[];
    }
  | {
      type: ContractUploadKind.REMOVE;
      payload: string;
    }
  | {
      type: ContractUploadKind.CHANGE_CATEGORY;
      payload: ChangeCategoryPayload;
    }
  | {
      type: ContractUploadKind.CHANGE_UPLOAD_STATUS;
      payload: ChangeUploadStatusPayload;
    }
  | {
      type: ContractUploadKind.SET_MAIN_FILE;
      payload: string;
    };

export interface ContractUploadState {
  mainContract?: ContractFile;
  contracts: ContractFile[];
}

const contractUploadReducer = (
  state: ContractUploadState,
  action: ContractUploadAction
): ContractUploadState => {
  const { type, payload } = action;
  switch (type) {
    case ContractUploadKind.ADD: {
      const payloadTyped = payload as ContractFile[];
      const contracts = state.contracts.concat(payloadTyped);
      return { contracts, mainContract: state.mainContract ?? contracts[0] };
    }
    case ContractUploadKind.CHANGE_UPLOAD_STATUS: {
      const { contractTempId, status } = payload || {};
      if (!contractTempId) return state;

      const updatedItemIndex = state.contracts.findIndex(
        (file) => file.id === contractTempId
      );
      const updatedList = [...state.contracts];
      updatedList[updatedItemIndex] = {
        ...updatedList[updatedItemIndex],
        ...status,
      };
      const isMainContractChanged = state?.mainContract?.id === contractTempId;
      const updatedMainContract = isMainContractChanged
        ? { ...state.mainContract, ...status }
        : { ...state.mainContract };
      return {
        ...state,
        contracts: updatedList,
        mainContract: updatedMainContract as ContractFile,
      };
    }
    case ContractUploadKind.REMOVE: {
      const updatedFiles = [...state.contracts];
      updatedFiles.splice(
        updatedFiles.findIndex((file) => file.id === payload),
        1
      );

      return {
        contracts: updatedFiles,
        mainContract:
          state.mainContract?.id === payload
            ? updatedFiles[0]
            : state.mainContract,
      };
    }
    case ContractUploadKind.SET_MAIN_FILE: {
      return {
        ...state,
        mainContract: state.contracts
          ? state.contracts.find((contract) => contract.id === payload)
          : undefined,
      };
    }
    case ContractUploadKind.CHANGE_CATEGORY: {
      const updatedContracts = [...state.contracts];
      const changedFileIndex = updatedContracts.findIndex(
        (file) => file.id === payload.id
      );
      updatedContracts[changedFileIndex].categoryId = payload.categoryId;
      return {
        ...state,
        contracts: updatedContracts,
      };
    }
    default:
      return state;
  }
};

enum UploadCreationMode {
  COMBINED,
  DISTINCT,
}

const createContractFile = (file: File, categoryId: string): ContractFile => {
  return {
    id: nanoid(),
    file,
    categoryId,
  };
};

const ContractUpload = ({
  titleKey,
  uploadType,
  contractId,
  contractData,
  contractGroup,
  handleClose,
  refetchPdf,
  setIsDragActive,
}: UploadMultipleFilesProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const buttonClasses = useStyledBaseButtons();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isOwner,
    selectedTeam,
    selectedTeamId,
    hasWriteAccess,
    organizationId,
  } = useTeam();
  const contactsMatch = useMatch("/contacts/:id");

  const maxUploadLimit = 100;
  const dropzone = useDropzone({
    accept: { "application/pdf": [".pdf"] },
  });

  const { data: fields } = useFieldsQuery(organizationId);

  const { data: categories } = useOrganizationCategoriesQuery(
    organizationId,
    (data) =>
      data.filter((category) =>
        categoriesTeamSelector(category, selectedTeamId)
      )
  );

  const contractUploadMutation = useContractUploadMutation();

  const [rejectedFileArray, setRejectedFileArray] = useState<File[]>([]);
  const [uploadCreationMode, setUploadCreationMode] =
    useState<UploadCreationMode>(UploadCreationMode.DISTINCT);
  const [successfulUpload, setSuccessfulUpload] = useState<boolean>(false);
  const [isUploadProcessing, setIsUploadProcessing] = useState<boolean>(false);
  const [isContractLimitReached, setIsContractLimitReached] =
    useState<boolean>(false);
  const [mainContractId, setMainContractId] = useState<string | undefined>(
    undefined
  );
  const [mainContractName, setMainContractName] = useState<string>("");

  const { setUploadStatus } = useUploadStatus();

  const [uploadState, dispatch] = useReducer(contractUploadReducer, {
    contracts: [],
  });

  const filteredCategories = useMemo(
    () =>
      categories?.filter((category) =>
        hasWriteAccess(category.id, contractData?.teamId ?? selectedTeamId)
      ),
    [categories, contractData, selectedTeamId]
  );

  const mainContractCategoryId = useMemo(
    () => contractGroup?.find((item) => item.id === mainContractId)?.categoryId,
    [contractGroup, mainContractId]
  );

  useEffect(() => {
    if (dropzone.acceptedFiles) {
      let defaultCategory = "";
      if (filteredCategories && filteredCategories.length > 0) {
        const category = filteredCategories.find((category) =>
          category.categoryTeamsInfo?.find(
            (item) => item.teamId === selectedTeamId && item.default
          )
        );
        defaultCategory = category ? category.id : filteredCategories[0].id;
      }
      const categoryId = mainContractCategoryId ?? defaultCategory;
      dispatch({
        type: ContractUploadKind.ADD,
        payload: dropzone.acceptedFiles.map((file) =>
          createContractFile(file, categoryId)
        ),
      });
    }
  }, [dropzone.acceptedFiles]);

  useEffect(() => {
    if (dropzone.fileRejections) {
      setRejectedFileArray([
        ...rejectedFileArray.concat(
          dropzone.fileRejections.map((fileRejection) => fileRejection.file)
        ),
      ]);
    }
  }, [dropzone.fileRejections]);

  useEffect(() => {
    if (contractData && contractId) {
      setMainContractId(contractData.parentId ?? contractId);
    }
  }, [contractData, contractId]);

  useEffect(() => {
    if (mainContractId) {
      if (contractGroup) {
        setMainContractName(
          contractGroup?.find((item) => item.id === mainContractId)?.name ?? ""
        );
      }
    }
  }, [mainContractId]);

  useEffect(() => {
    if (successfulUpload) setUploadStatus(true);
  }, [successfulUpload]);

  useEffect(() => {
    if (dropzone.isDragActive) {
      setIsDragActive?.(true);
    } else {
      setIsDragActive?.(false);
    }
  }, [dropzone.isDragActive]);

  const handleContractLimitReachedError = (e: ApiError) => {
    if (
      e.status === 403 &&
      (e.body as ForbiddenResponse).message === "contract limit reached"
    ) {
      setIsContractLimitReached(true);
      enqueueSnackbar(t("contractUpload.validation.maxContractLimit"), {
        variant: "error",
      });
    }
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploadCreationMode(
      event.target.checked
        ? UploadCreationMode.COMBINED
        : UploadCreationMode.DISTINCT
    );
  };

  const uploadMainContract = async (
    contract: ContractFile,
    isMainForAttachments: boolean
  ) => {
    try {
      const createdContract: ContractCreatedDto =
        await contractUploadMutation.mutateAsync({
          teamId: selectedTeamId,
          file: {
            file: contract.file,
            categoryId: contract.categoryId,
            parentId: mainContractId,
          },
        });
      dispatch({
        type: ContractUploadKind.CHANGE_UPLOAD_STATUS,
        payload: {
          status: { isUploaded: true, errorMessage: "" },
          contractTempId: contract.id,
        },
      });

      const fieldValues = getPrefilledFields(
        selectedTeamId,
        categories ?? [],
        fields ?? [],
        contactsMatch?.params.id
      );
      if (fieldValues && Object.keys(fieldValues).length > 0) {
        await OrganizationService.updateContract(
          selectedTeamId,
          createdContract.id,
          {
            fields: fieldValues,
          }
        );
      }

      return createdContract;
    } catch (e) {
      if (!(e instanceof ApiError)) {
        return;
      }

      handleContractLimitReachedError(e);

      const errorMessageConstraints = Object.keys(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        e?.body?.message[0]?.constraints
      );
      const errorMessageKey = errorMessageConstraints
        ? errorMessageConstraints[0]
        : "unexpected";

      const errorMessage = t(`common.errors.${errorMessageKey}`);

      dispatch({
        type: ContractUploadKind.CHANGE_UPLOAD_STATUS,
        payload: {
          status: {
            isUploaded: false,
            errorMessage: isMainForAttachments
              ? t("common.errors.mainFileIsNotPdf", { errorMessage })
              : errorMessage,
          },
          contractTempId: contract.id,
        },
      });

      setIsUploadProcessing(false);
      setSuccessfulUpload(true);
    }
  };

  const uploadAttachments = async (
    contract: ContractFile,
    mainContractId: string | undefined,
    selectedTeamId: string
  ) => {
    try {
      const createdContract = await contractUploadMutation.mutateAsync({
        teamId: selectedTeamId,
        file: {
          file: contract.file,
          categoryId: contract.categoryId,
          parentId: mainContractId,
        },
      });

      dispatch({
        type: ContractUploadKind.CHANGE_UPLOAD_STATUS,
        payload: {
          status: { isUploaded: true, errorMessage: "" },
          contractTempId: contract?.id,
        },
      });

      await OrganizationService.updateContract(
        selectedTeamId,
        createdContract.id,
        {
          fields: getPrefilledFields(
            selectedTeamId,
            categories ?? [],
            fields ?? [],
            contactsMatch?.params.id
          ),
        }
      );
    } catch (e) {
      if (!(e instanceof ApiError)) {
        return;
      }

      handleContractLimitReachedError(e);

      const errorMessageConstraints = Object.keys(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        e?.body?.message[0]?.constraints
      );
      const errorMessageKey = errorMessageConstraints
        ? errorMessageConstraints[0]
        : "unexpected";

      const errorMessage = t(`common.errors.${errorMessageKey}`);

      dispatch({
        type: ContractUploadKind.CHANGE_UPLOAD_STATUS,
        payload: {
          status: { isUploaded: false, errorMessage },
          contractTempId: contract.id,
        },
      });
    }
  };

  const createContractWithFileUpload = async () => {
    try {
      setIsUploadProcessing(true);
      if (
        !isTotalUploadSizeValid(uploadState.contracts.map(({ file }) => file))
      ) {
        enqueueSnackbar(
          t("contractUpload.validation.rejectedBySize", {
            maxSize: getSizeLimit(),
            fileSize: totalSizeInMegaBytes(
              uploadState.contracts.map(({ file }) => file)
            ).toFixed(1),
          }),
          {
            variant: "error",
          }
        );
        setIsUploadProcessing(false);
        return;
      }
      if (uploadType === UploadTypeEnum.CREATE_CONTRACT) {
        const allMainContracts =
          uploadCreationMode === UploadCreationMode.DISTINCT;
        if (allMainContracts) {
          for (const contract of uploadState.contracts) {
            await uploadMainContract(contract, false);
          }
        } else {
          const mainIndex = uploadState.contracts.findIndex(
            (file) => file.id === uploadState.mainContract?.id
          );
          const contract = uploadState.contracts[mainIndex];

          const mainContract = await uploadMainContract(contract, true);

          if (!mainContract) return;

          for (const [i, contract] of uploadState.contracts.entries()) {
            if (i === mainIndex) {
              continue;
            }
            await uploadAttachments(contract, mainContract?.id, selectedTeamId);
          }
        }
      } else if (uploadType === UploadTypeEnum.ATTACHMENT_CONTRACT) {
        for (const contract of uploadState.contracts) {
          await uploadAttachments(
            contract,
            mainContractId || "",
            contractData?.teamId ?? selectedTeamId
          );
        }
      }

      setIsUploadProcessing(false);
      setSuccessfulUpload(true);
    } catch (e) {
      Sentry.captureException(e, (scope) => {
        scope.setTransactionName(
          `Upload attachment failed on: ${mainContractId as string}`
        );
        return scope;
      });
      setIsUploadProcessing(false);

      if (!(e instanceof ApiError)) {
        return;
      }

      if (!mainContractId) {
        const mainIndex = uploadState.contracts.findIndex(
          (file) => file.id === uploadState.mainContract?.id
        );

        const errorMessageConstraints = Object.keys(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          e?.body?.message[0]?.constraints
        );
        const errorMessageKey = errorMessageConstraints
          ? errorMessageConstraints[0]
          : "unexpected";

        const errorMessage = t(`common.errors.${errorMessageKey}`);

        dispatch({
          type: ContractUploadKind.CHANGE_UPLOAD_STATUS,
          payload: {
            status: {
              isUploaded: false,
              errorMessage: t("common.errors.mainFileIsNotPdf", {
                errorMessage,
              }),
            },
            contractTempId: uploadState.contracts[mainIndex]?.id,
          },
        });
        setIsUploadProcessing(false);
        setSuccessfulUpload(true);
      }

      handleContractLimitReachedError(e);
    }
  };

  const existingContractFileUpload = async () => {
    try {
      setIsUploadProcessing(true);
      if (contractId) {
        await ContractAttachmentService.uploadFileForContract(contractId, {
          file: uploadState.mainContract?.file,
        });
        setIsUploadProcessing(false);
        setSuccessfulUpload(true);
      } else {
        enqueueSnackbar("This is not an existing contract!", {
          variant: "error",
        });
      }
    } catch (e) {
      setIsUploadProcessing(false);
      if (uploadState.mainContract) {
        setRejectedFileArray([
          ...rejectedFileArray.concat(uploadState.mainContract.file),
        ]);
      }
      enqueueSnackbar(t((e as ApiError).message), { variant: "error" });
    }
  };

  const handleUpload = () => {
    uploadType === UploadTypeEnum.EXISTING_CONTRACT
      ? void existingContractFileUpload()
      : void createContractWithFileUpload();
  };

  const closeModalAndNavigate = () => {
    handleClose && handleClose();
    navigate(routePaths.HOME);
  };

  const closeModalAndReload = async () => {
    handleClose && handleClose();
    if (refetchPdf) {
      await refetchPdf();
    }
  };

  const successMessage = (
    <TitleWrapper>{t("contractUpload.successfulUpload.title")}</TitleWrapper>
  );
  const errorMessage = titleKey ? (
    <TitleWrapper>{t(titleKey)}</TitleWrapper>
  ) : null;

  const successfullyUploadedFiles = successfulUpload
    ? uploadState.contracts.filter((item) => item.isUploaded)
    : uploadState.contracts;

  const isMaxUploadLimitReached: boolean =
    successfullyUploadedFiles.length > maxUploadLimit;

  return (
    <>
      {successfulUpload && successfullyUploadedFiles?.length
        ? successMessage
        : errorMessage}

      {!successfulUpload && !isUploadProcessing && (
        <DropZone
          isExistingContract={uploadType === UploadTypeEnum.EXISTING_CONTRACT}
          hasContracts={!!uploadState.contracts.length}
          dropzone={dropzone}
        />
      )}

      {/* Alert box with the list of rejected files */}
      {!successfulUpload && rejectedFileArray.length > 0 && (
        <Row>
          <AlertV2
            type={AlertTypeEnum.warning}
            icon={<WarningIcon fontSize="small" />}
          >
            <>
              {isContractLimitReached ? (
                <>
                  {t("contractUpload.validation.currentContractLimit", {
                    contractLimit: selectedTeam?.maxContracts,
                  })}
                  {isOwner() ? (
                    <Box>{t("contractUpload.validation.upgradePlan")}</Box>
                  ) : (
                    <Box>{t("contractUpload.validation.checkPlan")}</Box>
                  )}
                </>
              ) : (
                <Trans i18nKey={"contractUpload.validation.formatErrorMessage"}>
                  <strong />
                </Trans>
              )}
              <Box>{t("contractUpload.validation.rejectedFiles")}</Box>
              {rejectedFileArray.map((file, index) => {
                return <Box key={index}>{file.name}</Box>;
              })}
            </>
          </AlertV2>
        </Row>
      )}

      {isMaxUploadLimitReached && (
        <Row>
          <AlertV2
            type={AlertTypeEnum.warning}
            icon={<WarningIcon fontSize="small" />}
          >
            <Box>
              {t("contractUpload.validation.maxContractPerUpload", {
                maxUploadLimit,
              })}
            </Box>
          </AlertV2>
        </Row>
      )}

      {uploadState.contracts.filter((item) => !item.isUploaded).length > 0 &&
        !isUploadProcessing &&
        successfulUpload && (
          <Row>
            <AlertV2
              type={AlertTypeEnum.warning}
              icon={<WarningIcon fontSize="small" />}
            >
              <>
                <Box>{t("contractUpload.validation.rejectedFiles")}</Box>
                {uploadState.contracts.map((item, index) => {
                  if (item?.isUploaded) return null;
                  return (
                    <Box key={index}>
                      <span>{item.file.name}</span>
                      {item?.errorMessage && (
                        <span style={{ paddingLeft: "8px" }}>
                          {item?.errorMessage}
                        </span>
                      )}
                    </Box>
                  );
                })}
              </>
            </AlertV2>
          </Row>
        )}

      {/* FileZone appearance logic */}
      {uploadState.contracts.length > 0 && (
        <>
          {/* Only appears when files are still being uploaded to the cloud */}
          {isUploadProcessing && !successfulUpload ? (
            <Box className={classes.baseFilezone}>
              <PaddedGrid container alignItems="center">
                <Grid
                  item
                  xs={12}
                  className="flex-center"
                  marginTop="1rem"
                  sx={{ position: "relative" }}
                >
                  <ClosedFolder />
                  <CircularProgress
                    size={20}
                    sx={{
                      position: "absolute",
                      top: "40%",
                      color: "#fff",
                    }}
                  />
                </Grid>
                <Grid item xs={12} className="flex-center" margin="2rem 0">
                  <Box sx={{ color: "#718096" }}>
                    {t("contractUpload.stillUploading")}
                  </Box>
                </Grid>
              </PaddedGrid>
            </Box>
          ) : (
            <>
              <Box className={classes.baseFilezone}>
                {/* Only one file can appear when uploading file to Contract without PDF,
                  When selected multiple files, only the first one will be uploaded*/}
                {uploadType === UploadTypeEnum.EXISTING_CONTRACT &&
                uploadState.mainContract ? (
                  <ContractUploadItem
                    type={
                      contractData?.type === ContractDTOV1.type.MAIN_CONTRACT
                        ? ContractUploadItemType.MAIN_CONTRACT
                        : ContractUploadItemType.ATTACHMENT
                    }
                    categories={filteredCategories ?? []}
                    contractFile={uploadState.mainContract}
                    selected
                    disabled={successfulUpload}
                    onDelete={(id) => {
                      dispatch({
                        type: ContractUploadKind.REMOVE,
                        payload: id,
                      });
                    }}
                  />
                ) : (
                  <Box id="uploadedFileContainer">
                    {/* The first contract is always the main contract of the group which can not be deleted */}
                    {uploadType === UploadTypeEnum.ATTACHMENT_CONTRACT ? (
                      <>
                        <PaddedGrid container alignItems="center" spacing={2}>
                          <Grid item xs={2} className="flex-center">
                            <ClosedFolder />
                          </Grid>
                          <Grid item xs={9}>
                            <Box className="flex-column">
                              <FilenameLabel>{mainContractName}</FilenameLabel>
                              <Box className="flex-align-center">
                                <Box>{t("contractUpload.mainContract")}</Box>
                              </Box>
                            </Box>
                          </Grid>
                        </PaddedGrid>
                        {/* The rest are to be uploaded contracts which can only be attachments */}
                        {successfullyUploadedFiles.map((item) => (
                          <ContractUploadItem
                            key={item.id}
                            type={ContractUploadItemType.ATTACHMENT}
                            categories={filteredCategories ?? []}
                            contractFile={item}
                            disabled={successfulUpload}
                            onChangeCategory={(id, categoryId) => {
                              dispatch({
                                type: ContractUploadKind.CHANGE_CATEGORY,
                                payload: {
                                  id,
                                  categoryId,
                                },
                              });
                            }}
                            onDelete={(id) => {
                              dispatch({
                                type: ContractUploadKind.REMOVE,
                                payload: id,
                              });
                            }}
                            onSelect={(id) => {
                              dispatch({
                                type: ContractUploadKind.SET_MAIN_FILE,
                                payload: id,
                              });
                            }}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        {/* New contract(s) can be uploaded with radio button select.
                            Select main contract or select all as main contracts if all main contract switch is on */}
                        {successfullyUploadedFiles.map((item) => (
                          <ContractUploadItem
                            key={item.id}
                            type={
                              uploadCreationMode === UploadCreationMode.COMBINED
                                ? ContractUploadItemType.SELECTABLE
                                : ContractUploadItemType.MAIN_CONTRACT
                            }
                            categories={filteredCategories ?? []}
                            contractFile={item}
                            selected={item.id === uploadState.mainContract?.id}
                            disabled={successfulUpload}
                            onChangeCategory={(id, categoryId) => {
                              dispatch({
                                type: ContractUploadKind.CHANGE_CATEGORY,
                                payload: {
                                  id,
                                  categoryId,
                                },
                              });
                            }}
                            onDelete={(id) => {
                              dispatch({
                                type: ContractUploadKind.REMOVE,
                                payload: id,
                              });
                            }}
                            onSelect={(id) => {
                              dispatch({
                                type: ContractUploadKind.SET_MAIN_FILE,
                                payload: id,
                              });
                            }}
                          />
                        ))}
                      </>
                    )}
                  </Box>
                )}
                {uploadState.contracts.length > 1 &&
                  uploadType === UploadTypeEnum.CREATE_CONTRACT &&
                  !isMaxUploadLimitReached && (
                    <>
                      <hr
                        style={{
                          borderTop: `0 solid ${theme.color.gray[300]}`,
                          margin: "1rem",
                        }}
                      />
                      {/* All main contracts switch button will only appear if uploaded contracts are more than 1 */}
                      <Box
                        className="flex-center"
                        sx={{ columnGap: theme.spacing.sm }}
                        data-cy="switchButton"
                      >
                        <Box>{t("contractUpload.allMainContract")}</Box>
                        <Switch
                          variant="without-icon"
                          checked={
                            uploadCreationMode === UploadCreationMode.COMBINED
                          }
                          onChange={handleSwitchChange}
                          disabled={successfulUpload}
                        />
                        <Box>{t("contractUpload.oneMainContract")}</Box>
                      </Box>
                      {successfullyUploadedFiles.length > 0 ? (
                        <Box
                          sx={{
                            padding: "1rem",
                          }}
                        >
                          {successfulUpload
                            ? t(
                                "contractUpload.successfulUpload.uploadDescription"
                              )
                            : t("contractUpload.switchDescription")}
                        </Box>
                      ) : null}
                    </>
                  )}
                {!isMaxUploadLimitReached ? (
                  <Box
                    className="flex-justify-center"
                    sx={{
                      mt: "1rem",
                      mb: "2rem",
                    }}
                  >
                    {/* Upload, navigation and reload button logic */}
                    {successfulUpload ? (
                      <>
                        {uploadType !== UploadTypeEnum.CREATE_CONTRACT ? (
                          <Button
                            data-cy="closeAndReloadBtn"
                            size="large"
                            className={buttonClasses.baseButtonBlue}
                            onClick={closeModalAndReload}
                          >
                            {t(
                              "contractUpload.successfulUpload.closeAndReloadButton"
                            )}
                          </Button>
                        ) : (
                          <Button
                            data-cy="backToDashboard"
                            size="large"
                            className={buttonClasses.baseButtonBlue}
                            onClick={closeModalAndNavigate}
                          >
                            {t(
                              "contractUpload.successfulUpload.goToDashboardButton"
                            )}
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button
                        data-cy="uploadButton"
                        size="large"
                        className={buttonClasses.baseButtonBlue}
                        onClick={() => handleUpload()}
                      >
                        {t("contractUpload.uploadButton")}
                      </Button>
                    )}
                  </Box>
                ) : null}
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ContractUpload;
