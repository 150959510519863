import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { useLocale } from "hooks/GlobalStateHooks";
import { DatapointContent } from "pages/Contract/styles";
import DetailNameWrapper from "components/DetailNameWrapper/DetailNameWrapper";
import { CountrySelect } from "components/FormItems/CountrySelect/CountrySelect";
import { DataPointProps } from "./types";
import { CountryDatapointDTO } from "openapi";

export const CountryDatapoint: FC<DataPointProps> = ({
  definition,
  contact,
  editable,
}) => {
  const { locale } = useLocale();
  const { control } = useFormContext();

  const name = definition.name[locale];
  if (editable) {
    return (
      <Grid item xs={12} md={6}>
        <CountrySelect
          control={control}
          name={`${definition.visibleId}.value.value`}
          label={definition.name[locale]}
        />
      </Grid>
    );
  }

  const data = contact[definition.visibleId] as CountryDatapointDTO;

  const displayNames = new Intl.DisplayNames(locale, { type: "region" });
  let displayedCountry: string;
  try {
    displayedCountry = data?.value.value
      ? displayNames.of(data?.value.value) ?? "-"
      : "-";
  } catch (e) {
    displayedCountry = "-";
  }

  return (
    <Grid item xs={12} md={6}>
      <DetailNameWrapper>{name}</DetailNameWrapper>
      <DatapointContent>{displayedCountry}</DatapointContent>
    </Grid>
  );
};
