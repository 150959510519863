/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmbeddedSigningPageDTO } from "../models/EmbeddedSigningPageDTO";
import type { SignatureEmbeddedPageDTO } from "../models/SignatureEmbeddedPageDTO";
import type { SignatureRequestCancelDTO } from "../models/SignatureRequestCancelDTO";
import type { SignatureRequestDTO } from "../models/SignatureRequestDTO";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ContractSignatureV1Service {
  /**
   * Create an embedded signature page
   * @param id
   * @param requestBody
   * @returns SignatureEmbeddedPageDTO
   * @throws ApiError
   */
  public static initiateSignatureRequest(
    id: string,
    requestBody: SignatureRequestDTO
  ): CancelablePromise<SignatureEmbeddedPageDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/v1/contract-signature/create/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Return signing url page
   * @param signerId
   * @returns EmbeddedSigningPageDTO
   * @throws ApiError
   */
  public static getSigningPage(
    signerId: string
  ): CancelablePromise<EmbeddedSigningPageDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/contract-signature/signing/{signerId}",
      path: {
        signerId: signerId,
      },
    });
  }

  /**
   * Cancel a signature request
   * @param id
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static cancelSignatureRequest(
    id: string,
    requestBody: SignatureRequestCancelDTO
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/v1/contract-signature/cancel/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Remind a signer to sign a contract
   * @param signerId
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static remindSigner(
    signerId: string,
    id: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/v1/contract-signature/remind/{id}/signer/{signerId}",
      path: {
        signerId: signerId,
        id: id,
      },
    });
  }

  /**
   * Cleanup signature data of contract
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static cleanupSignature(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/v1/contract-signature/cleanup/{id}",
      path: {
        id: id,
      },
    });
  }
}
