import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  margin-top: ${theme.spacing.xl};
`;

export const Form = styled.form`
  margin: 0.5rem 0;
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacing.md};
  gap: ${theme.spacing.md};
`;

export const BackButton = styled.div`
  margin-bottom: ${theme.spacing.lg};
`;

export const Heading = styled.div`
  font-size: ${theme.font.size.body};
  margin-bottom: ${theme.spacing.xs};
`;

export const Name = styled.div`
  font-size: ${theme.font.size.body};
  font-weight: ${theme.font.weight.bold};
`;

export const AlertWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.lg};
  border: 2px solid ${theme.color.red[400]};
  border-radius: ${theme.borderRadius};
  background: ${theme.color.red[200]};
`;

export const AlertText = styled.div`
  flex: 1 1 auto;
  & h3 {
    margin: 0;
  }
`;

export const AlertIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin-right: 24px;
  flex: 0 0 auto;
  background: ${theme.color.red[600]};
`;

export const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.xs} 0;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${theme.font.weight.medium};
  margin-bottom: ${theme.spacing.sm};
  padding-bottom: ${theme.spacing.sm};
  border-bottom: 1px solid ${theme.color.gray[400]};
`;

export const FieldDescription = styled.div`
  margin-top: ${theme.spacing.xl};
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.bold};
  line-height: 24px;
  display: flex;
  gap: ${theme.spacing.sm};
  align-items: center;
`;

export const Row = styled.div<{
  alignItems?: string;
  justifyItems?: string;
  maxWidth?: string;
  gap?: string;
  wrap?: string;
}>`
  display: flex;
  justify-content: ${(props) => props.justifyItems || "space-between"};
  align-items: ${(props) => props.alignItems || "center"};
  max-width: ${(props) => props.maxWidth || "100%"};
  gap: ${(props) => props.gap || theme.spacing.md};
  flex-wrap: ${(props) => props.wrap || "no-wrap"};
  min-height: 40px;
`;

export const FormCheckboxWrapper = styled.div`
  min-width: 120px;
  & .MuiFormControlLabel-root {
    margin-right: 0;
  }
`;

export const FormMessage = styled.div`
  p:first-child {
    margin-top: 0;
  }
`;

export const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  color: ${theme.color.gray[800]};
  font-weight: ${theme.font.weight.semiBold};
`;

export const AccordionWrapper = styled.div`
  margin-top: ${theme.spacing.lg};
`;

export const FormOffsetFieldWrapper = styled.div`
  max-width: 80px;
  flex: 1 0 auto;
  .MuiFormControl-root {
    margin: 0;
  }
`;

export const VisibilityWrapper = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  flex: 0 0 auto;
  .MuiFormControl-root {
    margin: 0;
  }
  gap: ${theme.spacing.md};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

export const DueDateAndReminderWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg};
  background-color: ${theme.color.gray[100]};
  padding: ${theme.spacing.lg};
  border-radius: ${theme.borderRadius};
  margin-top: ${theme.spacing.md};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  & .MuiFormHelperText-root {
    display: none;
  }
`;

export const TooltipRow = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
  svg {
    width: 14px;
    height: 14px;
  }
`;
