/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AttachmentData } from "./AttachmentData";
import type { ContractFlattenedData } from "./ContractFlattenedData";
import type { ContractMovedData } from "./ContractMovedData";
import type { ContractUpdatedData } from "./ContractUpdatedData";
import type { DocumentData } from "./DocumentData";
import type { SignatureRequestCancelDTO } from "./SignatureRequestCancelDTO";
import type { SignatureRequestDTO } from "./SignatureRequestDTO";
import type { SignerData } from "./SignerData";
import type { SignerDeclinedData } from "./SignerDeclinedData";
import type { TaskData } from "./TaskData";

export type ContractChangeLogDTO = {
  data:
    | ContractUpdatedData
    | ContractFlattenedData
    | DocumentData
    | ContractMovedData
    | SignatureRequestDTO
    | SignatureRequestCancelDTO
    | SignerDeclinedData
    | SignerData
    | TaskData
    | AttachmentData;
  id: string;
  event: ContractChangeLogDTO.event;
  authorId: string | null;
  contractId: string;
  createdAt: string;
};

export namespace ContractChangeLogDTO {
  export enum event {
    CONTRACT_INITIATED = "CONTRACT_INITIATED",
    CONTRACT_CREATED = "CONTRACT_CREATED",
    CONTRACT_EDITED = "CONTRACT_EDITED",
    CONTRACT_MOVED = "CONTRACT_MOVED",
    DOCUMENT_UPLOADED = "DOCUMENT_UPLOADED",
    DOCUMENT_REMOVED = "DOCUMENT_REMOVED",
    SIGNATURE_INITIATED = "SIGNATURE_INITIATED",
    SIGNATURE_SENT = "SIGNATURE_SENT",
    SIGNATURE_SIGNED = "SIGNATURE_SIGNED",
    SIGNATURE_DECLINED = "SIGNATURE_DECLINED",
    SIGNATURE_COMPLETED = "SIGNATURE_COMPLETED",
    SIGNATURE_CANCELLED = "SIGNATURE_CANCELLED",
    SIGNATURE_REMINDER_SENT = "SIGNATURE_REMINDER_SENT",
    CONTRACT_TASK_ADDED = "CONTRACT_TASK_ADDED",
    CONTRACT_TASK_REMOVED = "CONTRACT_TASK_REMOVED",
    ATTACHMENT_ADDED = "ATTACHMENT_ADDED",
    ATTACHMENT_REMOVED = "ATTACHMENT_REMOVED",
    MAIN_CONTRACT_CHANGED = "MAIN_CONTRACT_CHANGED",
  }
}
