/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ContractFieldCreateDto = {
  section: ContractFieldCreateDto.section;
  customSectionId: string | null;
  type: ContractFieldCreateDto.type;
  categoryIds: Array<string>;
  listItems: Array<string> | null;
  name: string;
};

export namespace ContractFieldCreateDto {
  export enum section {
    GENERAL = "GENERAL",
    PARTNER = "PARTNER",
    DURATION = "DURATION",
    PAYMENT = "PAYMENT",
    CUSTOM = "CUSTOM",
  }

  export enum type {
    TEXTFIELD = "TEXTFIELD",
    TEXTAREA = "TEXTAREA",
    NUMBER = "NUMBER",
    DATE = "DATE",
    LIST = "LIST",
    AMOUNT = "AMOUNT",
    LINK = "LINK",
    COUNTRY = "COUNTRY",
    FORMULA = "FORMULA",
    DURATION = "DURATION",
    CONTACT = "CONTACT",
  }
}
