/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TranslationDTO } from "../models/TranslationDTO";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class CommonService {
  /**
   * @param requestBody
   * @returns TranslationDTO
   * @throws ApiError
   */
  public static translateText(
    requestBody: TranslationDTO
  ): CancelablePromise<TranslationDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/common/translate",
      body: requestBody,
      mediaType: "application/json",
    });
  }
}
