/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocale } from "hooks";
import {
  AssignCategoryToTeamDTO,
  CategoryDTO,
  OrganizationNewService,
} from "openapi";
import { useCallback } from "react";

export const CATEGORY_KEY = "categories";

export const useOrganizationCategoriesQuery = (
  organizationId: string | undefined,
  select?: (data: CategoryDTO[]) => CategoryDTO[]
) => {
  const { locale } = useLocale();

  const orderByName = useCallback(
    (data: CategoryDTO[]) =>
      data.sort((a, b) => a.name[locale].localeCompare(b.name[locale])),
    [locale]
  );

  return useQuery({
    queryKey: [CATEGORY_KEY, organizationId, locale],
    queryFn: async () => {
      const categories = await OrganizationNewService.getCategories(
        organizationId!
      );
      return categories;
    },
    select: (data) => {
      if (select) {
        data = select(data);
      }
      return orderByName(data);
    },
    enabled: !!organizationId,
  });
};

export const useAssignTeamToCategoryMutation = (
  organizationId: string,
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { teamId: string; dto: AssignCategoryToTeamDTO }) => {
      return OrganizationNewService.assignTeamToCategory(
        organizationId,
        data.teamId,
        data.dto
      );
    },
    onSuccess: (_, variables) => {
      onSuccess?.();
      void queryClient.invalidateQueries({
        queryKey: [CATEGORY_KEY, organizationId],
      });
    },
  });
};

export const useRemoveTeamFromCategoryMutation = (
  organizationId: string,
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { teamId: string; categoryId: string }) => {
      return OrganizationNewService.removeTeamFromCategory(
        organizationId,
        data.teamId,
        data.categoryId
      );
    },
    onSuccess: (_, variables) => {
      onSuccess?.();
      void queryClient.invalidateQueries({
        queryKey: [CATEGORY_KEY, organizationId],
      });
    },
  });
};
