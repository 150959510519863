import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationNewService, WebhookTestResultDTO } from "openapi";
import { CTAButton, NewModal, CodeSnippet } from "components";
import { useTeam } from "contexts/team/hooks";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { SnippetTitle } from "../components/styles";
import { NotificationMessage } from "./styles";

const NotificationComponent = ({ statusCode }: { statusCode?: number }) => {
  const { t } = useTranslation();
  const message = {
    success: {
      icon: <CheckCircleIcon fontSize="small" />,
      text: t(
        "pages.settings.organization.webhooks.notifications.success.test"
      ),
    },
    error: {
      icon: <CancelIcon fontSize="small" />,
      text: t("pages.settings.organization.webhooks.notifications.error.test"),
    },
  };

  if (!statusCode) return <></>;
  const isSuccess = (statusCode > 199 && statusCode < 300) || false;

  return (
    <NotificationMessage isSuccess={isSuccess}>
      {isSuccess ? message.success.icon : message.error.icon}
      <span>{isSuccess ? message.success.text : message.error.text}</span>
    </NotificationMessage>
  );
};

export const TestURLButton = ({
  selectedWebhookURL,
}: {
  selectedWebhookURL: string;
}) => {
  const { t } = useTranslation();
  const { organizationId } = useTeam();
  const [showTestWebhookModal, setShowTestWebhookModal] = useState(false);
  const [testWebhookResponse, setTestWebhookResponse] =
    useState<WebhookTestResultDTO>();

  const handleOnTestURLClick = async () => {
    if (!selectedWebhookURL) return;

    try {
      const response = await OrganizationNewService.testWebhook(
        organizationId,
        { url: selectedWebhookURL }
      );
      if (!response) return;
      setTestWebhookResponse(response);
      setShowTestWebhookModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const getRequestData = (data?: WebhookTestResultDTO) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestBody = data?.request?.body || "{}";

    const request = {
      ...data?.request,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
      body: JSON.parse(requestBody),
    };

    return JSON.stringify(request, null, 2);
  };

  const getResponseData = (data?: WebhookTestResultDTO) => {
    return JSON.stringify(data?.response, null, 2);
  };

  const statusCode = testWebhookResponse?.response.statusCode;

  return (
    <>
      <CTAButton
        type="button"
        variant="secondary"
        label={t("pages.settings.organization.webhooks.test.button.label")}
        name={t("pages.settings.organization.webhooks.test.button.label")}
        onClick={handleOnTestURLClick}
        disabled={!selectedWebhookURL}
      />

      <NewModal
        open={showTestWebhookModal}
        handleClose={() => setShowTestWebhookModal(false)}
        title={t("pages.settings.organization.webhooks.test.modal.title")}
        body={
          <>
            <NotificationComponent statusCode={statusCode} />
            <SnippetTitle>Request</SnippetTitle>
            <CodeSnippet
              title="Data"
              content={getRequestData(testWebhookResponse)}
            />
            <SnippetTitle>Response</SnippetTitle>
            <CodeSnippet
              title="Data"
              content={getResponseData(testWebhookResponse)}
            />
          </>
        }
        footer={
          <CTAButton
            variant="primary"
            name={t("common.buttons.close")}
            onClick={() => setShowTestWebhookModal(false)}
          />
        }
      />
    </>
  );
};
