import styled from "@emotion/styled";
import { PermissionChangeLogDTO } from "openapi";
import { theme } from "theme";

export const Label = styled.div<{ variant?: string }>`
  padding: 0 ${theme.spacing.xs};
  border-radius: ${theme.labelBorderRadius};
  font-size: ${theme.font.size.caption};

  ${({ variant }) => {
    switch (variant) {
      case PermissionChangeLogDTO.event.INVITATION_SENT:
      case PermissionChangeLogDTO.event.INVITATION_ACCEPTED:
      case PermissionChangeLogDTO.event.MEMBER_UPDATED:
      case PermissionChangeLogDTO.event.ROLE_UPDATED:
      case PermissionChangeLogDTO.event.TEAM_UPDATED:
        return `
          background-color: ${theme.color.orange[200]};
          color: ${theme.color.peach[800]};
        `;
      case PermissionChangeLogDTO.event.INVITATION_DELETED:
      case PermissionChangeLogDTO.event.MEMBER_DELETED:
      case PermissionChangeLogDTO.event.ROLE_DELETED:
      case PermissionChangeLogDTO.event.TEAM_DELETED:
        return `
          background-color: ${theme.color.salmonPink[100]};
          color: ${theme.color.red[700]};
        `;
      case PermissionChangeLogDTO.event.INITIAL_STATE:
      case PermissionChangeLogDTO.event.TEAM_CREATED:
      case PermissionChangeLogDTO.event.ROLE_CREATED:
        return `
          background-color: ${theme.color.gray[300]};
          color: ${theme.color.gray[800]};
        `;
      default:
        return `
          background-color: ${theme.color.gray[200]};
          color: ${theme.color.gray[900]};
        `;
    }
  }}
`;
