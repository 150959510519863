import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { IconButton, Popover } from "@mui/material";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { useTeam } from "contexts/team/hooks";
import UploadContractDropdown from "components/ContractUpload/Dropdown/Dropdown";
import ContactUs from "components/ContactUs/ContactUs";
import TrialAlert from "../TrialAlert/TrialAlert";
import {
  Divider,
  LinkButton,
  TeamSelector,
  ToggleArrowButton,
} from "./components";
import {
  useOrganizationMainNavLinks,
  useOrganizationSettingsNavLinks,
} from "./links";
import Dropdown from "./components/Dropdown/Dropdown";
import { Footer, List, MenuWrapper, Wrapper } from "./styles";

const Sidebar = () => {
  const location = useLocation();
  const {
    features,
    selectedTeam,
    getRoleByTeamId,
    selectedTeamId,
    hasWriteAccessToAny,
    parentTeamId,
  } = useTeam();
  const [open, setOpen] = useState(true);
  const handleToggle = () => setOpen(!open);
  const isSettingsPage = location.pathname.startsWith("/settings");
  const role = getRoleByTeamId();
  const settingsLinks = useOrganizationSettingsNavLinks(
    role,
    features,
    selectedTeam?.isSubTeam ?? false
  );
  const defaultLinks = useOrganizationMainNavLinks(
    features,
    parentTeamId === selectedTeamId
  );
  const navLinks = isSettingsPage ? settingsLinks : defaultLinks;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => setAnchorEl(null);
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "support-popover" : undefined;

  return (
    <Wrapper open={open}>
      <List>
        <TrialAlert minimal={!open} />

        <TeamSelector visible={open} />

        <Divider />

        <OverlayScrollbarsComponent
          defer
          options={{
            scrollbars: {
              autoHide: "scroll",
            },
          }}
        >
          <MenuWrapper>
            {navLinks.map((link) => {
              if (link?.children?.length && link.enabled) {
                return <Dropdown key={link.id} link={link} expanded={open} />;
              }
              return (
                link.enabled &&
                link.to && (
                  <LinkButton
                    key={link.id}
                    divider={link.id === "settings" ? "top" : ""}
                    id={link.id}
                    icon={link.icon}
                    to={link.to}
                    label={link.label}
                    showLabel={open}
                    showTooltip={!open}
                  />
                )
              );
            })}

            {!isSettingsPage && hasWriteAccessToAny(selectedTeamId) && (
              <UploadContractDropdown
                dataCy="importDropdownButton"
                showLabel={open}
                showTooltip={!open}
                isSidebar
              />
            )}
          </MenuWrapper>
        </OverlayScrollbarsComponent>
      </List>

      <Footer>
        <ToggleArrowButton onClick={handleToggle} isOpen={open} />

        {open ? (
          <ContactUs isOpen={open} />
        ) : (
          <>
            <IconButton onClick={handlePopoverOpen} aria-describedby={id}>
              <HelpCenterIcon />
            </IconButton>
            <Popover
              id={id}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <ContactUs
                expanded
                hasExpandedIcon={false}
                style={{ margin: "0" }}
              />
            </Popover>
          </>
        )}
      </Footer>
    </Wrapper>
  );
};

export default Sidebar;
