import styled from "@emotion/styled";
import { Card, Button as MUIButton } from "@mui/material";
import { theme } from "theme";

export const Wrapper = styled(Card)`
  border-radius: 0.5rem;
  border-color: ${theme.color.gray[200]};
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
`;

export const Form = styled.form`
  margin-top: ${theme.spacing.xxl};
`;

export const Header = styled.div`
  margin: ${theme.spacing.lg} 0;
  padding: ${theme.spacing.xxs} ${theme.spacing.lg};
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.sm};
  align-items: center;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg};
`;

export const MenuButton = styled(MUIButton)`
  color: ${theme.color.gray[700]};
  gap: ${theme.spacing.sm};
  font-size: ${theme.font.size.caption};

  svg {
    font-size: ${theme.font.size.h6};
  }
`;

export const NewTaskButton = styled(MUIButton)`
  text-transform: capitalize;
  height: fit-content;
  margin: 0 !important;
  padding: ${theme.spacing.sm} ${theme.spacing.lg};

  svg {
    margin-left: ${theme.spacing.xs};
    font-size: ${theme.font.size.subheading};
  }
`;
