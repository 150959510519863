import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  color: ${theme.color.gray[700]};
  font-size: ${theme.font.size.caption};

  svg {
    flex: 0 0 auto;
    fill: ${theme.color.gray[700]};
    width: 16px;
    height: 16px;
    margin-right: ${theme.spacing.xs};
  }
`;
