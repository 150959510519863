import { ExtendedTeamDescriptionDto } from "./Dropdown";

export const findParentId = (
  items: ExtendedTeamDescriptionDto[],
  teamId: string
): string | null => {
  for (const item of items) {
    if (item.children?.some((child) => child.id === teamId)) {
      return item.id;
    }
    if (item.children) {
      const result = findParentId(item.children, teamId);
      if (result) return result;
    }
  }
  return null;
};
