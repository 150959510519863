import React from "react";
import { Button, Popover } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export type AnnotationData = {
  contractId: string;
  text: string;
  startBlockId: string;
  startOffset: number;
  contextMenuVisible: boolean;
  left: number;
  top: number;
  ref: Element;
};

type Props = {
  annotationData: AnnotationData;
  addAnnotationData: (fieldName?: string, fieldKey?: string) => void;
};

type FieldAnnotation = {
  field: string;
};

const AnnotationMenu = ({ annotationData, addAnnotationData }: Props) => {
  const { t } = useTranslation();
  const { handleSubmit, register } = useForm<FieldAnnotation>({
    defaultValues: {
      field: "duration.startDate",
    },
  });

  function addValue(values: FieldAnnotation) {
    const [field, value] = values.field.split(".");
    addAnnotationData(field, value);
  }

  const options = [
    {
      field: "partnerCompany.value",
      name: t("pages.admin.tabs.pendingContracts.modal.counterParty"),
    },
    {
      field: "duration.type",
      name: t("pages.admin.tabs.pendingContracts.modal.durationType"),
    },
    {
      field: "duration.startDate",
      name: t("pages.admin.tabs.pendingContracts.modal.startDate"),
    },
    {
      field: "duration.endDate",
      name: t("pages.admin.tabs.pendingContracts.modal.endDate"),
    },
    {
      field: "duration.interval",
      name: t("pages.admin.tabs.pendingContracts.modal.durationInMonths"),
    },
    {
      field: "duration.terminationDate",
      name: t("pages.admin.tabs.pendingContracts.modal.lastTerminationDate"),
    },
    {
      field: "duration.noticePeriod",
      name: t("pages.admin.tabs.pendingContracts.modal.noticeInMonths"),
    },
    {
      field: "duration.automaticRenewal",
      name: t("pages.admin.tabs.pendingContracts.modal.autoRenewalInMonths"),
    },
    {
      field: "paymentCycle.value",
      name: t("pages.admin.tabs.pendingContracts.modal.paymentCycle"),
    },
    {
      field: "paymentPriceNet.value",
      name: t("pages.admin.tabs.pendingContracts.modal.amount"),
    },
    {
      field: "paymentPriceNet.currency",
      name: t("pages.admin.tabs.pendingContracts.modal.amountCurrency"),
    },
    {
      field: "paymentTax.value",
      name: t("pages.admin.tabs.pendingContracts.modal.taxRate"),
    },
    {
      field: "paymentType.value",
      name: t("pages.admin.tabs.pendingContracts.modal.financialNature"),
    },
    {
      field: "iban.value",
      name: t("pages.admin.tabs.pendingContracts.modal.iban"),
    },
    {
      field: "bic.value",
      name: t("pages.admin.tabs.pendingContracts.modal.bic"),
    },
    {
      field: "paymentMethod.value",
      name: t("pages.admin.tabs.pendingContracts.modal.paymentMethod"),
    },
    {
      field: "contractNumber.value",
      name: t("pages.admin.tabs.pendingContracts.modal.contractNumber"),
    },
    {
      field: "orderNumber.value",
      name: t("pages.admin.tabs.pendingContracts.modal.orderNumber"),
    },
    {
      field: "deposit.value",
      name: t("pages.admin.tabs.pendingContracts.modal.deposit"),
    },
    {
      field: "deposit.currency",
      name: t("pages.admin.tabs.pendingContracts.modal.depositCurrency"),
    },
  ];

  return (
    <Popover
      anchorEl={annotationData.ref}
      open={true}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <div onMouseUp={(e) => e.stopPropagation()}>
        <div>{annotationData.text}</div>
        <form
          name="annotationForm"
          onSubmit={handleSubmit(addValue)}
          noValidate
        >
          <>
            {options.map((item) => (
              <div key={item.field}>
                <input
                  {...register("field")}
                  type="radio"
                  id={item.field}
                  value={item.field}
                />
                <label htmlFor={item.field}>{item.name}</label>
                <br />
              </div>
            ))}
            <Button type="submit" size="small">
              Save
            </Button>
            <Button
              onClick={() => addAnnotationData()}
              size="small"
              color="error"
            >
              Cancel
            </Button>
          </>
        </form>
      </div>
    </Popover>
  );
};

export default AnnotationMenu;
