/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptInvitationResponseDto } from "../models/AcceptInvitationResponseDto";
import type { AccessTokenDataDTO } from "../models/AccessTokenDataDTO";
import type { AccessTokenDTO } from "../models/AccessTokenDTO";
import type { AccessTokenFullDTO } from "../models/AccessTokenFullDTO";
import type { ChangePasswordDto } from "../models/ChangePasswordDto";
import type { EmailVerificationDto } from "../models/EmailVerificationDto";
import type { FederatedIdentityApprovalResponseDTO } from "../models/FederatedIdentityApprovalResponseDTO";
import type { FederatedIdentityDto } from "../models/FederatedIdentityDto";
import type { NotificationSettingsDto } from "../models/NotificationSettingsDto";
import type { NotificationSettingsUpdateDto } from "../models/NotificationSettingsUpdateDto";
import type { PasswordResetDto } from "../models/PasswordResetDto";
import type { ProviderAuthData } from "../models/ProviderAuthData";
import type { RegistrationDto } from "../models/RegistrationDto";
import type { RegistrationResponseDto } from "../models/RegistrationResponseDto";
import type { ReviewFormAccessDTO } from "../models/ReviewFormAccessDTO";
import type { UserInfoDto } from "../models/UserInfoDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class UserService {
  /**
   * Register a new user
   * @param requestBody
   * @returns RegistrationResponseDto
   * @throws ApiError
   */
  public static register(
    requestBody: RegistrationDto
  ): CancelablePromise<RegistrationResponseDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/user/register",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Accept invitation
   * @param token
   * @returns AcceptInvitationResponseDto
   * @throws ApiError
   */
  public static acceptInvitation(
    token: string
  ): CancelablePromise<AcceptInvitationResponseDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/user/accept-invitation",
      query: {
        token: token,
      },
    });
  }

  /**
   * Send password reset email
   * @param email
   * @returns any
   * @throws ApiError
   */
  public static forgotPassword(email: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/user/forgot-password",
      query: {
        email: email,
      },
    });
  }

  /**
   * Change password using a token (see forgot-password operation)
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static resetPassword(
    requestBody: PasswordResetDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/user/reset-password",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Resend email verification
   * @param email
   * @returns any
   * @throws ApiError
   */
  public static resendEmailVerification(email: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/user/resend-email-verification",
      query: {
        email: email,
      },
    });
  }

  /**
   * Verify email
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static verifyEmail(
    requestBody: EmailVerificationDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/user/verify-email",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get user data for the logged in user
   * @returns UserInfoDto
   * @throws ApiError
   */
  public static getUserInfo(): CancelablePromise<UserInfoDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/user/user-info",
    });
  }

  /**
   * Change password
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static changePassword(
    requestBody: ChangePasswordDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/user/change-password",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Default team when user logging out and in
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static setDefaultTeam(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/user/default-team/{id}",
      path: {
        id: id,
      },
    });
  }

  /**
   * Get notification filters list by user
   * @returns NotificationSettingsDto
   * @throws ApiError
   */
  public static getUserNotificationSettings(): CancelablePromise<NotificationSettingsDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/user/notification-settings",
    });
  }

  /**
   * Update notification filters list by user
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static editUserNotificationSettings(
    requestBody: NotificationSettingsUpdateDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/user/notification-settings",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get all federated identities of user
   * @returns FederatedIdentityDto
   * @throws ApiError
   */
  public static getFederatedIdentities(): CancelablePromise<FederatedIdentityDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/user/federated-identity",
    });
  }

  /**
   * Connect federated identity by id
   * @param identityProvider
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static connectFederatedIdentity(
    identityProvider: "google" | "microsoft" | "oidc",
    requestBody: ProviderAuthData
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/user/federated-identity/{identityProvider}",
      path: {
        identityProvider: identityProvider,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Disconnect federated identity by id
   * @param identityProvider
   * @param federatedIdentityId
   * @returns void
   * @throws ApiError
   */
  public static disconnectFederatedIdentity(
    identityProvider: string,
    federatedIdentityId: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/user/federated-identity/{identityProvider}/{federatedIdentityId}",
      path: {
        identityProvider: identityProvider,
        federatedIdentityId: federatedIdentityId,
      },
    });
  }

  /**
   * Approve liniking of a federated identity by its id
   * @param federatedIdentityId
   * @returns FederatedIdentityApprovalResponseDTO
   * @throws ApiError
   */
  public static approveFederatedIdentity(
    federatedIdentityId: string
  ): CancelablePromise<FederatedIdentityApprovalResponseDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/user/federated-identity/{federatedIdentityId}/approve",
      path: {
        federatedIdentityId: federatedIdentityId,
      },
    });
  }

  /**
   * Get all access tokens of the user
   * @returns AccessTokenDTO
   * @throws ApiError
   */
  public static getAccessTokens(): CancelablePromise<Array<AccessTokenDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/user/access-tokens",
    });
  }

  /**
   * Create an access token
   * @param requestBody
   * @returns AccessTokenFullDTO
   * @throws ApiError
   */
  public static createAccessToken(
    requestBody: AccessTokenDataDTO
  ): CancelablePromise<AccessTokenFullDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/user/access-tokens",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Delete an access token
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static deleteAccessToken(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/user/access-tokens/{id}",
      path: {
        id: id,
      },
    });
  }

  /**
   * Request a G2 review form access token
   * @returns ReviewFormAccessDTO
   * @throws ApiError
   */
  public static generateReviewFormUrl(): CancelablePromise<ReviewFormAccessDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/user/generate-review-form-url",
    });
  }
}
