import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Button from "@mui/material/Button";
import { NewModal } from "components";
import { useStyles as useStyledButtons } from "components/StyledComponents/StyledBaseButtons";
import ContractUpload from "components/ContractUpload/ContractUpload";
import { UploadTypeEnum } from "shared/enums/contractUpload.enum";
import { ContractNameDto, ContractDTOV1 } from "openapi";
import { theme } from "theme";

type Props = {
  fetchData: (loadFromStash?: boolean | undefined) => Promise<void>;
  fetchPdf: (loadFromStash?: boolean | undefined) => Promise<void>;
  contractData?: ContractDTOV1;
  contractGroup: ContractNameDto[];
};

const Attachment = ({
  contractData,
  contractGroup,
  fetchPdf,
  fetchData,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const buttonClasses = useStyledButtons();
  const [showAddAttachmentModal, setShowAddAttachmentModal] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);

  const handleOnOpen = () => setShowAddAttachmentModal(true);

  const handleClose = () => {
    setShowAddAttachmentModal(false);
    void fetchData();
  };

  return (
    <>
      <Button
        data-cy="addAttachment"
        className={buttonClasses.previewHeaderButton}
        onClick={handleOnOpen}
      >
        <AttachFileIcon fontSize="small" sx={{ marginRight: ".4rem" }} />
        {t("pages.contractDetails.buttons.addAttachments")}
      </Button>

      <NewModal
        open={showAddAttachmentModal}
        handleClose={handleClose}
        icon={<AttachFileIcon fontSize="small" sx={{ marginRight: ".4rem" }} />}
        title={t("pages.contractDetails.modals.addAttachments.title")}
        headerProps={{
          style: {
            transition: `background-color 0.25s ease-in-out`,
            backgroundColor: isDragActive ? `${theme.color.blue[200]}80` : "",
          },
        }}
        body={
          <ContractUpload
            contractId={id}
            contractData={contractData}
            contractGroup={contractGroup}
            handleClose={handleClose}
            refetchPdf={fetchPdf}
            uploadType={UploadTypeEnum.ATTACHMENT_CONTRACT}
            setIsDragActive={setIsDragActive}
          />
        }
        bodyProps={{
          noPadding: true,
        }}
        fullWidth
        disableBackdropClick
      />
    </>
  );
};

export default Attachment;
