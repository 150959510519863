import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { theme } from "theme/";
import { Link } from "react-router-dom";

export const TagBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0.1rem 0.5rem;
  margin: 0.2rem;
  color: #3182ce;
  background-color: rgba(25, 118, 210, 0.08);
  border-radius: 0.4rem;
  cursor: pointer;
`;

export const AddIconWrapper = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  margin: 0.2rem;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  transition: transform 0.2s ease-in-out;

  :hover {
    background-color: rgba(25, 118, 210, 0.08);
  }

  svg {
    ${({ status }: { status: string }) =>
      status === "open"
        ? `
        fill: ${theme.color.blue[600]};
      `
        : `
        fill: ${theme.color.green[600]};
      `}
    width: 20px;
    height: 20px;
  }

  transform: ${({ status }: { status: string }) =>
    status === "open" && "rotate(45deg)"};
`;

export const CloseIcon = styled(Close)`
  font-size: 16px;
  margin-left: 0.5rem;
  transition: transform 0.2s ease-in-out;

  :focus,
  :active {
    transform: scale(0.7);
  }
`;

export const SimpleLink = styled(Link)`
  color: ${theme.color.blue[600]} !important;
  &:hover {
    text-decoration: underline !important;
  }
`;

export const FormMessage = styled.span`
  font-size: ${theme.font.size.caption};
  color: ${theme.color.gray[700]};
  > span,
  strong {
    padding: 0 ${theme.spacing.xxs};
  }
`;
