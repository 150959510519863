import React, { useCallback, useEffect, useState } from "react";
import { ToolbarContainer } from "../styles";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  LexicalEditor,
  TextFormatType,
  ElementFormatType,
  $isTextNode,
} from "lexical";

import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
} from "@lexical/rich-text";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from "@lexical/list";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";

import Undo from "@mui/icons-material/Undo";
import Redo from "@mui/icons-material/Redo";

import FormatBold from "@mui/icons-material/FormatBold";
import FormatItalic from "@mui/icons-material/FormatItalic";
import FormatUnderlined from "@mui/icons-material/FormatUnderlined";
import FormatStrikethrough from "@mui/icons-material/FormatStrikethrough";

import FormatAlignLeft from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenter from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRight from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustify from "@mui/icons-material/FormatAlignJustify";

import Image from "@mui/icons-material/Image";

import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import {
  $getSelectionStyleValueForProperty,
  $setBlocksType,
  $patchStyleText,
} from "@lexical/selection";
import { InsertImage } from "./modals/InsertImageModal";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { theme } from "theme";

const LowPriority = 1;

const supportedBlockTypes = new Set([
  "paragraph",
  "quote",
  "h1",
  "h2",
  "ul",
  "ol",
]);

const supportedFontFamilies: [string, string][] = [
  ["Arial", "Arial"],
  ["Courier New", "Courier New"],
  ["Georgia", "Georgia"],
  ["Times New Roman", "Times New Roman"],
  ["Trebuchet MS", "Trebuchet MS"],
  ["Verdana", "Verdana"],
];

const supportedFontSizes: [string, string][] = [
  ["10px", "10px"],
  ["11px", "11px"],
  ["12px", "12px"],
  ["13px", "13px"],
  ["14px", "14px"],
  ["15px", "15px"],
  ["16px", "16px"],
  ["17px", "17px"],
  ["18px", "18px"],
  ["19px", "19px"],
  ["20px", "20px"],
];

const getBlockTypeToBlockName = (t: TFunction) => ({
  h1: {
    name: t("pages.settings.tabs.contractTemplates.editor.toolbar.h1"),
    set: (blockType: string, editor: LexicalEditor) => {
      if (blockType !== "h1") {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createHeadingNode("h1"));
          }
        });
      }
    },
  },
  h2: {
    name: t("pages.settings.tabs.contractTemplates.editor.toolbar.h2"),
    set: (blockType: string, editor: LexicalEditor) => {
      if (blockType !== "h2") {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createHeadingNode("h2"));
          }
        });
      }
    },
  },
  ol: {
    name: t("pages.settings.tabs.contractTemplates.editor.toolbar.ol"),
    set: (blockType: string, editor: LexicalEditor) => {
      if (blockType !== "ol") {
        editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, void 0);
      } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND, void 0);
      }
    },
  },
  paragraph: {
    name: t("pages.settings.tabs.contractTemplates.editor.toolbar.paragraph"),
    set: (blockType: string, editor: LexicalEditor) => {
      if (blockType !== "paragraph") {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createParagraphNode());
          }
        });
      }
    },
  },
  quote: {
    name: t("pages.settings.tabs.contractTemplates.editor.toolbar.quote"),
    set: (blockType: string, editor: LexicalEditor) => {
      if (blockType !== "quote") {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createQuoteNode());
          }
        });
      }
    },
  },
  ul: {
    name: t("pages.settings.tabs.contractTemplates.editor.toolbar.ul"),
    set: (blockType: string, editor: LexicalEditor) => {
      if (blockType !== "ul") {
        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, void 0);
      } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND, void 0);
      }
    },
  },
});

export const Toolbar = () => {
  const { t } = useTranslation();
  const [editor] = useLexicalComposerContext();
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);

  const [blockType, setBlockType] = useState("paragraph");
  const [fontFamily, setFontFamily] = useState("Arial");
  const [fontSize, setFontSize] = useState("15px");

  const [orientation, setOrientation] = useState<ElementFormatType>("start");
  const [formats, setFormats] = useState<TextFormatType[]>([]);

  const [openImageModal, setOpenImageModal] = useState(false);

  const blockTypeToBlockName = getBlockTypeToBlockName(t);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }

      if ($isTextNode(element)) {
        const parent = element.getParentOrThrow();
        setOrientation(parent.getFormatType() || "left");
      } else {
        setOrientation(element.getFormatType() || "left");
      }

      const activeFormats: TextFormatType[] = [];

      if (selection.hasFormat("bold")) {
        activeFormats.push("bold");
      }
      if (selection.hasFormat("italic")) {
        activeFormats.push("italic");
      }
      if (selection.hasFormat("underline")) {
        activeFormats.push("underline");
      }
      if (selection.hasFormat("strikethrough")) {
        activeFormats.push("strikethrough");
      }

      setFormats(activeFormats);

      setFontSize(
        $getSelectionStyleValueForProperty(selection, "font-size", "15px")
      );
      setFontFamily(
        $getSelectionStyleValueForProperty(selection, "font-family", "Arial")
      );
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  return (
    <>
      <ToolbarContainer>
        <Box
          sx={{
            display: "flex",
            minHeight: "min-content",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: theme.spacing.sm,
          }}
        >
          <IconButton
            disabled={!canUndo}
            onClick={() => {
              editor.dispatchCommand(UNDO_COMMAND, void 0);
            }}
          >
            <Undo />
          </IconButton>
          <IconButton
            disabled={!canRedo}
            onClick={() => {
              editor.dispatchCommand(REDO_COMMAND, void 0);
            }}
          >
            <Redo />
          </IconButton>
          <Box sx={{ display: "flex", gap: theme.spacing.xs }}>
            <FormControl size="small">
              <Select
                value={blockType}
                onChange={(e) => {
                  const blockData =
                    blockTypeToBlockName[
                      e.target
                        .value as unknown as keyof typeof blockTypeToBlockName
                    ];
                  blockData.set(blockType, editor);
                }}
              >
                {Array.from(supportedBlockTypes).map((blockType) => {
                  const blockData =
                    blockTypeToBlockName[
                      blockType as unknown as keyof typeof blockTypeToBlockName
                    ];
                  return (
                    <MenuItem key={blockType} value={blockType}>
                      {blockData.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <Select
                value={fontFamily}
                onChange={(e) => {
                  editor.update(() => {
                    const selection = $getSelection();
                    if ($isRangeSelection(selection)) {
                      $patchStyleText(selection, {
                        "font-family": e.target.value,
                      });
                    }
                  });
                }}
              >
                {Array.from(supportedFontFamilies).map((fontFamily) => {
                  return (
                    <MenuItem key={fontFamily[1]} value={fontFamily[1]}>
                      {fontFamily[0]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <Select
                value={fontSize}
                onChange={(e) => {
                  editor.update(() => {
                    const selection = $getSelection();
                    if ($isRangeSelection(selection)) {
                      $patchStyleText(selection, {
                        "font-size": e.target.value,
                      });
                    }
                  });
                }}
              >
                {Array.from(supportedFontSizes).map((fontSize) => {
                  return (
                    <MenuItem key={fontSize[1]} value={fontSize[1]}>
                      {fontSize[0]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <ToggleButtonGroup
            size="small"
            value={formats}
            onChange={(e, changedFormats: TextFormatType[]) => {
              const formatSet = new Set(formats);
              for (const format of changedFormats) {
                if (!formatSet.has(format)) {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, format);
                }
                formatSet.delete(format);
              }
              formatSet.forEach((format) =>
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, format)
              );
            }}
          >
            <ToggleButton value="bold">
              <FormatBold />
            </ToggleButton>
            <ToggleButton value="italic">
              <FormatItalic />
            </ToggleButton>
            <ToggleButton value="underline">
              <FormatUnderlined />
            </ToggleButton>
            <ToggleButton value="strikethrough">
              <FormatStrikethrough />
            </ToggleButton>
          </ToggleButtonGroup>
          <ToggleButtonGroup
            size="small"
            exclusive
            value={orientation}
            onChange={(e, newOrientation: ElementFormatType | null) => {
              if (newOrientation) {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, newOrientation);
              }
            }}
          >
            <ToggleButton value="left">
              <FormatAlignLeft />
            </ToggleButton>
            <ToggleButton value="center">
              <FormatAlignCenter />
            </ToggleButton>
            <ToggleButton value="right">
              <FormatAlignRight />
            </ToggleButton>
            <ToggleButton value="justify">
              <FormatAlignJustify />
            </ToggleButton>
          </ToggleButtonGroup>
          <IconButton
            onClick={() => {
              setOpenImageModal(true);
            }}
          >
            <Image />
          </IconButton>
        </Box>
      </ToolbarContainer>
      <InsertImage
        open={openImageModal}
        editor={editor}
        onClose={() => {
          setOpenImageModal(false);
        }}
      />
    </>
  );
};
