import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Control,
  Controller,
  UseFormTrigger,
  ControllerRenderProps,
  FieldValues,
} from "react-hook-form";
import { Chip, Autocomplete } from "@mui/material";
import { StyledTextField } from "components/StyledComponents/StyledBaseFields";
import { InvitationType } from "./TeamMembers";

export type EmailListProps = {
  name: string;
  control: Control;
  error: boolean;
  trigger: UseFormTrigger<InvitationType>;
};

const InputEmails = ({ name, control, error, trigger }: EmailListProps) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");

  const handleBlur = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>,
    field: ControllerRenderProps<FieldValues, string>,
    emailList: string[]
  ) => {
    const input = event.target.value;

    if (!input) return;

    const newEmails = Array.from(new Set(input.split(/\s+/).filter(Boolean)));

    const validatedEmails = newEmails;

    const updatedEmailList = Array.from(
      new Set([...emailList, ...validatedEmails])
    );
    field.onChange(updatedEmailList);

    setInputValue("");

    void trigger("email");
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => {
          const emailList = (field?.value as string[]) || [];
          return (
            <Autocomplete
              value={emailList}
              onChange={(_, newValue) => {
                field.onChange(newValue);
                void trigger("email");
              }}
              multiple
              freeSolo
              id="invitation-emails-list"
              data-testid="invitation-emails-list"
              options={[]}
              getOptionLabel={(option) => option}
              inputValue={inputValue}
              onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    data-testid="email-chip"
                    label={option}
                    color="primary"
                    key={index}
                  />
                ));
              }}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    "data-testid": "invitation-emails-list-input",
                    onBlur: (e) => handleBlur(e, field, emailList),
                  }}
                  size="small"
                  variant="outlined"
                  error={fieldState.invalid || error}
                  helperText={
                    error
                      ? t(fieldState.error?.message as string) ||
                        t(
                          "pages.settings.tabs.team.teamInvitationForm.validation.isEmail"
                        )
                      : ""
                  }
                  label={t(
                    "pages.settings.tabs.team.teamInvitationForm.emailAddress"
                  )}
                  placeholder="johndoe@email.com"
                />
              )}
            />
          );
        }}
      />
    </>
  );
};

export default InputEmails;
