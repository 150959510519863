import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import ExcelCard from "components/ExcelCard/ExcelCard";
import { useTranslation } from "react-i18next";
import CustomMultipleSelect from "components/CustomMultipleSelect/CustomMultipleSelect";
import { ContractExportService } from "openapi";
import * as FileSaver from "file-saver";
import dayjs from "dayjs";
import { useTeam } from "contexts/team/hooks";
import { theme } from "theme";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { useOrganizationCategoriesQuery } from "shared/api/organization/categories";
import {
  categoriesHasTeamSelector,
  categoriesTeamSelector,
} from "shared/api/organization/categories.helpers";
import { getCategory } from "pages/Contracts/helpers";

const Export = () => {
  const { t } = useTranslation();
  const { selectedTeam, selectedTeamId, parentTeamId, organizationId } =
    useTeam();

  const { data: categories } = useOrganizationCategoriesQuery(
    organizationId,
    (categories) =>
      categories.filter((category) =>
        selectedTeamId === parentTeamId
          ? categoriesHasTeamSelector(category)
          : categoriesTeamSelector(category, selectedTeamId)
      )
  );

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const exportContract = async () => {
    const response = (await ContractExportService.exportContracts(
      selectedTeamId,
      {
        categories: selectedCategories.map((categoryId) => {
          const category = getCategory(categories ?? [], categoryId);
          if (!category) {
            throw new Error("category not found"); // unreachable code
          }
          return {
            categoryId: category.id,
            teamId: selectedTeamId,
          };
        }),
      }
    )) as Blob;

    FileSaver.saveAs(
      response,
      `ContractHero_export-${dayjs().format("YYYY-MM-DD")}.xlsx`
    );
  };

  return (
    <CardWrapper>
      <ExcelCard subtitle={t("pages.export.subtitle")}>
        <Box>
          <ul className="exportList">
            <li>{t("pages.export.bulletpoints.first")}</li>
            <li>{t("pages.export.bulletpoints.second")}</li>
            <li>{t("pages.export.bulletpoints.third")}</li>
          </ul>
          <CustomMultipleSelect
            categoriesData={categories}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            customLabel={t("pages.export.categoryWarning")}
            isEnabledReadAccess
            multiTeam={
              selectedTeam?.permissions && selectedTeam?.permissions.length > 1
            }
          />
          <Button
            onClick={exportContract}
            disabled={selectedCategories.length < 1}
            variant="contained"
            size="large"
            sx={{ textTransform: "none", marginTop: theme.spacing.lg }}
          >
            {t("pages.export.generateExport")}
          </Button>
        </Box>
      </ExcelCard>
    </CardWrapper>
  );
};

export default Export;
