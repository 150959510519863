/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type S3BackupConfig = {
  accessKey: string;
  secretKey: string;
  bucket: string;
  region: string;
  storageClass: string;
  type: S3BackupConfig.type;
  password?: string;
};

export namespace S3BackupConfig {
  export enum type {
    FTPS = "FTPS",
    SFTP = "SFTP",
    AWS_S3 = "AWS S3",
  }
}
