import styled from "@emotion/styled";
import { Modal } from "@mui/material";

export const ReviewModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ReviewIFrame = styled.iframe`
  height: 90%;
  width: 80%;
`;
