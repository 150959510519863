import React from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { RadioGroup, FormControlLabel, RadioGroupProps } from "@mui/material";
import { CustomRadio } from "./styles";

type Option = {
  label: string;
  value: string | boolean;
};

export type RadioButtonTypes<T extends FieldValues> = Omit<
  RadioGroupProps,
  "name"
> & {
  name: Path<T>;
  control: Control<T>;
  options: Option[];
  emptyOptionText?: string;
  translationPrefix?: string;
  margin?: "none" | "normal" | "dense";
  suggestion?: boolean;
  row?: boolean;
};

const CHRadioGroup = <T extends FieldValues>(props: RadioButtonTypes<T>) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <RadioGroup {...field} data-testid="radio-group" row={props.row}>
          {props.options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              label={option.label}
              control={<CustomRadio />}
            />
          ))}
        </RadioGroup>
      )}
    />
  );
};

export default CHRadioGroup;
