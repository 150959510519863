import styled from "@emotion/styled";
import { theme } from "theme";
import ChevronIcon from "../../assets/svg/chevron-icon.svg?react";

type AccordionProps = {
  isOpen?: boolean;
  variant?: "warning";
};

export const Container = styled.div<AccordionProps>`
  border-radius: ${theme.borderRadius};
  border-width: 1px;
  border-style: solid;

  ${({ variant }) => {
    switch (variant) {
      case "warning":
        return `
        // @Todo update with variable names from new theme
        background: #FEF4DA;
        border-color: #F9D153;
        `;
      default:
        return `
          background: ${theme.color.gray[100]};
          border-color:${theme.color.gray[200]};
        `;
    }
  }}
`;

export const AccordionSummary = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.lg} ${theme.spacing.xxl} ${theme.spacing.lg}
    ${theme.spacing.lg};
  cursor: pointer;
`;

export const AccordionDetails = styled.div`
  padding: ${theme.spacing.sm} ${theme.spacing.lg} ${theme.spacing.lg};
`;

export const ToggleMarker = styled(ChevronIcon)<AccordionProps>`
  transition: transform 0.3s;
  transform-origin: center center;
  margin-left: auto;
  fill: ${theme.color.gray[900]};

  ${({ isOpen }) =>
    isOpen ? `transform: rotate(180deg)` : `transform: rotate(0)`}}
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
`;
