import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  color: ${theme.color.gray[600]};
  font-weight: ${theme.font.weight.medium};
`;
