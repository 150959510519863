import React, { RefObject, useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { CellValueChangedEvent, ColDef } from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { patchScrollbar } from "components/AgGridBaseTable/utils";
import { useLocale, useUserInfo } from "hooks";
import { ContractViewItemDto, OrganizationService } from "openapi";
import { useContactTypesQuery } from "shared/api";
import { useTeam } from "contexts/team/hooks";
import { Loader } from "components";
import { getColumnDataConfig } from "./columnConfig";
import { getSidebarOptions } from "./helpers";
import { TableProps, CellValueType } from "./types";
import { Wrapper } from "./styles";
import { getContactTableLocaleTexts } from "../../helpers";
import { useOverviewActions, useOverviewState } from "contexts/grid/hooks";
// import { getActiveViewDeterminerFunction } from "contexts/grid/helpers";
import { useContractViewsQuery } from "shared/api/views";
import { ContactDataDTO, ContactDatapointDTOs } from "../../ContactDataDTO";
import { GetRowIdParams } from "ag-grid-community";
import { setValidationSnackbarErrors } from "shared/service/errorResponseService";
import { getActiveViewDeterminerFunction } from "contexts/grid/helpers";
import { dateFormats } from "constants/utils";

const Table = ({ definitions, data: rowData, gridRef }: TableProps) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { gridStateProps } = useOverviewState();
  const { gridProps, overview } = useOverviewActions();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedTeam, selectedTeamId } = useTeam();
  const [columnData, setColumnData] = useState<ColDef[]>();
  const { userInfo } = useUserInfo();
  const userDateFormat = userInfo?.dateFormat || dateFormats.EU_DOTS;

  useEffect(() => {
    const columnDefs = getColumnDataConfig(
      definitions,
      locale,
      t,
      userDateFormat
    );
    setColumnData(columnDefs);
  }, []);

  const { data: views } = useContractViewsQuery(
    selectedTeamId,
    ContractViewItemDto.type.CONTACT
  );
  const organizationId = (selectedTeam?.parentTeamId ||
    selectedTeam?.id) as string;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { data: contactTypesData } = useContactTypesQuery(organizationId);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const contactTypes = contactTypesData?.map(
    (contactType) => contactType.name[locale]
  ) as string[];

  const handleOnCellValueChanged = async (
    params: CellValueChangedEvent<ContactDataDTO>
  ) => {
    const colDef = params.colDef;
    const oldValue = params.oldValue as CellValueType;
    const newValue = params.newValue as CellValueType;
    const data = params.data;
    const field = colDef.field;
    const contactId = data.id;
    const visibleId = field?.split(".")[0];

    if (!field || oldValue === newValue) return;

    const requestBody = {
      [visibleId as string]: {
        value: {
          value: newValue,
        },
      },
    };

    try {
      await OrganizationService.updateContact(
        contactId,
        organizationId,
        requestBody
      );
    } catch (error) {
      params.api.applyTransaction({
        update: [
          {
            ...data,
            [visibleId as string]: {
              value: {
                value: oldValue,
              },
            } as ContactDatapointDTOs,
          },
        ],
      });

      setValidationSnackbarErrors(
        error,
        "pages.contractEdit.forms.generalForm",
        enqueueSnackbar,
        t
      );
    }
  };

  if (!contactTypes || !contactTypesData || !views || !columnData)
    return <Loader />;

  return (
    <Wrapper className="ag-theme-alpine">
      <AgGridReact
        animateRows
        embedFullWidthRows
        suppressCellFocus
        suppressContextMenu
        ref={gridRef as unknown as RefObject<AgGridReact>}
        rowData={rowData}
        enableCellChangeFlash={true}
        onCellValueChanged={(event) => handleOnCellValueChanged(event)}
        onGridReady={({ api }) => {
          patchScrollbar();
          // this breaks the initial grid state. it changes the size, of the elements, which leads to a mismatch in column states.
          // we could set the grid initiaization into a setTimeout to circumvent the issue, but its not ideal. IMO lets just leave out the sizecolumns call, maybe nobody
          // notices.
          //api.sizeColumnsToFit();

          overview.dispatch({
            type: "initialize",
            value: {
              gridRef: gridRef ?? undefined,
              determineActiveView: getActiveViewDeterminerFunction(views),
            },
          });
        }}
        getRowId={(params: GetRowIdParams<ContactDataDTO>) => params.data.id}
        gridOptions={{
          columnDefs: columnData,
          sideBar: getSidebarOptions(t),
          accentedSort: true,
          singleClickEdit: false,
          stopEditingWhenCellsLoseFocus: true,
          defaultColDef: {
            resizable: true,
            sortable: true,
            editable: true,
            menuTabs: ["filterMenuTab"],
          },
        }}
        localeText={getContactTableLocaleTexts(t)}
        statusBar={{
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        }}
        {...gridProps}
        {...gridStateProps}
      />
    </Wrapper>
  );
};

export default Table;
