import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { WebhookDTO } from "openapi";
import { useTeam } from "contexts/team/hooks";
import { Features, routePaths } from "constants/";
import { IconButton } from "@mui/material";
import WebhookIcon from "assets/svg/webhook-icon.svg?react";
import { SectionHeader } from "components";
import CardWrapper from "components/CardWrapper/CardWrapper";
import BasicTable from "components/BasicTable/BasicTable";
import { Header } from "components/BasicTable/types";
import { HeaderIconStyles } from "pages/Settings/OrganizationSettings/Teams/styles";
import { useDeleteWebhookMutation, useWebhooksQuery } from "shared/api";
import { theme } from "theme";
import Webhook from "./Webhook/Webhook";
import { DeleteModalConfirmation } from "./components";
import { Status } from "./components/styles";
import { Subheading, LinkAddress, Empty, BinIcon, TableRow } from "./styles";
import { useUserInfo } from "hooks";
import { dateFormats } from "constants/utils";

const Webhooks = () => {
  const { t } = useTranslation();
  const { id: webhookId } = useParams();
  const { hasFeature, organizationId } = useTeam();
  const isPageAvailable = hasFeature(Features.WEBHOOK);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { mutateAsync: deleteWebhook } = useDeleteWebhookMutation();
  const [selectedWebhook, setSelectedWebhook] = useState<WebhookDTO>();
  const { data: webhookEvents } = useWebhooksQuery(organizationId);
  const { userInfo } = useUserInfo();
  const userDateFormat = userInfo?.dateFormat || dateFormats.EU_DOTS;

  const handleOnDelete = async (id: string) => {
    if (!id || id === "new") return;
    await deleteWebhook({ webhookId: id, organizationId });
  };

  const tableHeaders: Header[] = [
    {
      key: "name",
      name: t(
        "pages.settings.organization.webhooks.availableHooksTable.headers.name"
      ).toUpperCase(),
    },
    {
      key: "url",
      name: t(
        "pages.settings.organization.webhooks.availableHooksTable.headers.url"
      ).toUpperCase(),
    },
    {
      key: "status",
      name: t(
        "pages.settings.organization.webhooks.availableHooksTable.headers.status"
      ).toUpperCase(),
    },
    {
      key: "createdAt",
      name: t(
        "pages.settings.organization.webhooks.availableHooksTable.headers.createdAt"
      ).toUpperCase(),
    },
    {
      key: "actions",
      name: "",
    },
  ];

  const tableData = webhookEvents?.map((row) => {
    const status = row.active
      ? t("pages.settings.organization.webhooks.common.events.status.active")
      : t("pages.settings.organization.webhooks.common.events.status.inactive");

    const createdAt = dayjs(row.createdAt).format(`${userDateFormat} HH:mm`);
    return {
      name: (
        <span
          data-order-key={row.name}
          aria-label="webhook"
          className="name"
          onClick={() => handleOnEdit(row.id)}
        >
          {row.name}
        </span>
      ),
      status: (
        <Status data-order-key={status} active={row.active}>
          {status}
        </Status>
      ),
      url: <LinkAddress data-order-key={row.url}>{row.url}</LinkAddress>,
      createdAt: (
        <span data-order-key={createdAt}>
          {dayjs(row.createdAt).format("DD/MM/YYYY HH:mm:ss")}
        </span>
      ),
      actions: (
        <IconButton
          onClick={() => {
            setSelectedWebhook(row);
            setShowDeleteModal(true);
          }}
        >
          <BinIcon />
        </IconButton>
      ),
    };
  });

  const handleOnEdit = (id: string) =>
    navigate(`${routePaths.SETTINGS_WEBHOOK}/${id}`);
  const handleOnCreate = () => navigate(`${routePaths.SETTINGS_WEBHOOK}/new`);

  if (!isPageAvailable) navigate(routePaths.HOME);
  if (webhookId) return <Webhook />;

  return (
    <>
      <CardWrapper>
        <SectionHeader
          title={t("pages.settings.organization.webhooks.header.main.title")}
          baseButtonText={t(
            "pages.settings.organization.webhooks.header.main.addButton"
          )}
          icon={<WebhookIcon style={{ fill: theme.color.blue[600] }} />}
          iconStyle={{
            ...HeaderIconStyles,
            backgroundColor: theme.color.white[800],
          }}
          noPadding
          onButtonClick={handleOnCreate}
          buttonVariant="primary"
        />

        <Subheading>
          {t("pages.settings.organization.webhooks.header.main.subheading")}
        </Subheading>

        {webhookEvents?.length ? (
          <BasicTable
            headers={tableHeaders ?? []}
            data={tableData ?? []}
            components={{ TableRow }}
          />
        ) : (
          <Empty>
            {t(
              "pages.settings.organization.webhooks.header.main.noHooksMessage"
            )}
          </Empty>
        )}
      </CardWrapper>
      {selectedWebhook ? (
        <DeleteModalConfirmation
          open={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          handleOnDelete={() => handleOnDelete(selectedWebhook.id)}
          events={selectedWebhook.events.map((event) => event.type)}
          url={selectedWebhook.url}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Webhooks;
