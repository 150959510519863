import styled from "@emotion/styled";
import {
  Accordion as MUIAccordion,
  AccordionDetails as MUIAccordionDetails,
  AccordionSummary as MUIAccordionSummary,
  Button as MUIButton,
} from "@mui/material";
import { theme } from "theme";

export const AccordionDetails = styled(MUIAccordionDetails)`
  position: relative;
  padding-bottom: ${theme.spacing.xl};
  margin-bottom: ${theme.spacing.xl};
  border-top: 2px dashed ${theme.color.gray[400]}80;
  border-bottom: 2px dashed ${theme.color.gray[400]}80;
  padding: ${theme.spacing.sm} 0 ${theme.spacing.xl};
`;

export const Accordion = styled(MUIAccordion)`
  &.MuiAccordion-root:before {
    display: none;
  }
`;

export const TitleButton = styled(MUIButton)`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;
  text-transform: none;
  color: ${theme.color.gray[900]};
  font-weight: ${theme.font.weight.normal};
  font-size: ${theme.font.size.body};
`;

export const AccordionSummary = styled(MUIAccordionSummary)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px dashed transparent;
  font-size: ${theme.font.size.body};
  padding: 0;

  & .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  span {
    font-size: 0.92rem;
    transition: transform 0.25s linear;
  }

  :hover {
    span {
      transform: translateX(2px);
    }

    .arrow svg {
      fill: ${theme.color.blue[500]};
    }
  }
`;

export const ActionButtons = styled.div`
  flex: 0 0 auto;
`;

export const ButtonWithIcon = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background-color: ${theme.color.white};
  border: 2px dashed ${theme.color.gray[400]}80;
  border-radius: 40px;
  padding: ${theme.spacing.xs} ${theme.spacing.md};
  cursor: pointer;
  margin-bottom: -16px;
  font-family: inherit; // TODO This is stupid, somehow the styled.button reset the font-family so I have to set it. Someone make it go away
  svg {
    fill: ${theme.color.gray[700]};
  }
  span {
    color: ${theme.color.gray[700]};
    padding-left: ${theme.spacing.xs};
  }
  &:hover {
    svg {
      fill: ${theme.color.blue[500]};
    }
    span {
      color: ${theme.color.blue[500]};
    }
  }
`;
