/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ApiError, OrganizationService, RoleDTO } from "openapi";
import { useQuery } from "@tanstack/react-query";

type RolesQueryOptions = {
  includeSubteams?: boolean;
};

export const useRolesQuery = (
  id: string | undefined,
  { includeSubteams = true }: RolesQueryOptions = { includeSubteams: true }
) => {
  return useQuery({
    queryKey: ["roles", id, includeSubteams],
    queryFn: async () => {
      const roles = await OrganizationService.getRoles(id!);
      return includeSubteams
        ? roles
        : roles.filter((role) => role.teamId === id);
    },
    enabled: !!id,
  });
};

export const useRolesOfMemberQuery = (
  memberId?: string,
  id?: string,
  { includeSubteams = true }: RolesQueryOptions = { includeSubteams: true }
) => {
  return useQuery<RoleDTO[], ApiError>({
    queryKey: ["rolesOfMember", memberId],
    queryFn: async () => {
      const roles = await OrganizationService.getRolesOfMember(memberId!, id!);
      return includeSubteams
        ? roles
        : roles.filter((role) => role.teamId === id);
    },
    enabled: !!id && !!memberId,
    retry(failureCount, error) {
      return (
        failureCount < 3 &&
        !!error &&
        error.status !== 404 &&
        error.status !== 403
      );
    },
    refetchOnWindowFocus: false,
  });
};

export const useRoleQuery = (roleId?: string, parentTeamId?: string) => {
  return useQuery({
    queryKey: ["role", roleId],
    queryFn: async () => {
      const role = await OrganizationService.getRole(roleId!, parentTeamId!);
      return role;
    },
    enabled: !!parentTeamId && !!roleId,
  });
};
