import React, { MouseEvent, useMemo } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ContractFieldDTOV1, ContractSectionDTOV1 } from "openapi";
import Section from "./Base/Section";
import { DraggableData } from "./types";
import { getSectionFields } from "../../utils/fields";

type Props = {
  id: string;
  isCollapsed?: boolean;
  fields: ContractFieldDTOV1[];
  section: ContractSectionDTOV1;
  onRemoveField?: (field: ContractFieldDTOV1, sectionId: string) => void;
  popoverComponent: React.ReactNode;
  handleOnOpenPopover?: (e: MouseEvent<HTMLButtonElement>) => void;
  isSettingsDisabled: boolean;
};

export const toDraggableSectionId = (id: string) => {
  return `draggable-section-${id}`;
};

const DraggableSection = ({
  id,
  isCollapsed,
  section,
  fields,
  onRemoveField,
  popoverComponent,
  handleOnOpenPopover,
  isSettingsDisabled,
}: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    transform,
    transition,
    active,
    over,
  } = useSortable({
    id: toDraggableSectionId(id),
    data: { type: "section", id: id },
  });

  const showDroppableArea = active?.data?.current?.set === "collection";

  const isOverSection = useMemo(() => {
    const activeData = active?.data.current as DraggableData | undefined;
    const overData = over?.data.current as DraggableData | undefined;
    if (activeData?.type === "section") {
      return false;
    }

    if (over?.id === toDraggableSectionId(id)) {
      return true;
    }

    const sectionFields = getSectionFields(fields, section.fields);
    const foundField = sectionFields.find((field) => field.id === overData?.id);

    if (foundField) {
      return true;
    }

    return false;
  }, [over, id, fields, section]);

  const styles = {
    opacity: isDragging ? 0.5 : 1,
    zIndex: 99,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Section
      ref={setNodeRef}
      style={styles}
      section={section}
      fields={fields}
      isCollapsed={isCollapsed}
      onRemoveField={onRemoveField}
      popoverComponent={popoverComponent}
      handleOnOpenPopover={handleOnOpenPopover}
      isSettingsDisabled={isSettingsDisabled}
      hover={isOverSection}
      showDroppableArea={showDroppableArea}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
    />
  );
};

export default DraggableSection;
