import React from "react";
import { Tooltip } from "@mui/material";
import { theme } from "theme";
import AISuggestionDropdown from "./AiSuggestionDropdown";

const customTooltipStyles = {
  backgroundColor: theme.color.white,
  color: theme.color.gray[600],
  padding: theme.spacing.sm,
  borderRadius: theme.borderRadius,
  boxShadow: theme.shadow.standard,
};

type AiSuggestionProps = {
  children: React.ReactElement;
  options: {
    displayValue: string | number;
    value: string | number;
  }[];
  handleOptionClick: (option: unknown) => void;
};

const AiSuggestion = ({
  children,
  options,
  handleOptionClick,
}: AiSuggestionProps) => {
  return (
    <Tooltip
      placement="right-start"
      componentsProps={{
        tooltip: {
          style: customTooltipStyles,
        },
      }}
      title={
        <AISuggestionDropdown
          options={options}
          handleOptionClick={handleOptionClick}
        />
      }
    >
      {children}
    </Tooltip>
  );
};

export default AiSuggestion;
