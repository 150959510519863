import React, { useEffect } from "react";
import { ContractDTOV1 } from "openapi";
import { AnalysisData, DataPointWithoutMetadata } from "../types";
import { getDefaultAnalysisParser } from "../hooks/utils";
import AnalysisSuggestions from "./AnalysisSuggestions";
import { useFormContext, useWatch } from "react-hook-form";
import * as Sentry from "@sentry/react";
import { AnalysisHookData } from "../hooks/useAnalysis";

export type AnalysisWrapperProps<T extends ContractDTOV1["fields"][string]> = {
  fieldKey: keyof DataPointWithoutMetadata<T>;
  analysis: AnalysisHookData<T> | undefined;
  renderField: (
    data: AnalysisHookData<T> | null,
    dropdown: React.ReactNode | null
  ) => JSX.Element;
};

const shouldAutofill: string[] = [
  "partnerCompany",
  "duration",
  "paymentCycle",
  "paymentPriceNet",
  "paymentTax",
  "paymentType",
  "iban",
  "bic",
  "paymentMethod",
  "contractNumber",
  "orderNumber",
  "deposit",
];

export const AnalysisWrapper = <T extends ContractDTOV1["fields"][string]>({
  fieldKey,
  analysis,
  renderField,
}: AnalysisWrapperProps<T>) => {
  const {
    setValue,
    formState: { isDirty, defaultValues },
    control,
  } = useFormContext();

  const value = useWatch({
    control: control,
    name: `fields.${analysis?.definition.id ?? ""}.${fieldKey as string}`,
  }) as unknown;

  const valueMatchesAnalysisSuggestion =
    analysis &&
    fieldKey in analysis.selectedSuggestion &&
    value === analysis.selectedSuggestion[fieldKey];

  const getFieldAnalysisData = () => {
    const analysisData = analysis?.data?.analysisData as
      | AnalysisData
      | undefined;

    if (!analysisData) {
      return null;
    }

    const fieldAnalysisData = analysisData[fieldKey as keyof AnalysisData];
    return fieldAnalysisData ?? null;
  };
  const fieldAnalysisData = getFieldAnalysisData();

  const resetSuggestion = () => {
    if (!analysis) {
      return;
    }

    analysis.unsetSuggestion(fieldKey);
  };

  const displayConverter = !analysis
    ? (input: string) => input
    : analysis.displayConverters?.[fieldKey] ??
      getDefaultAnalysisParser<T>(analysis.definition.type, fieldKey);

  const dataConverter = !analysis
    ? (input: string) => input
    : analysis.dataConverters?.[fieldKey] ??
      getDefaultAnalysisParser<T>(analysis.definition.type, fieldKey);

  const fillValue = (analysisValue: string) => {
    if (!analysis) {
      return;
    }

    try {
      const convertedValue = dataConverter(analysisValue);
      setValue(
        `fields.${analysis.definition.id}.${fieldKey as string}`,
        convertedValue,
        {
          shouldDirty: true,
        }
      );
      analysis.setSuggestion(fieldKey, convertedValue);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    if (isDirty) {
      return;
    }

    const id = analysis?.definition.visibleId
      ? analysis?.definition.visibleId
      : analysis?.definition.id;

    const bestItem = fieldAnalysisData?.items?.at(0);

    if (
      analysis &&
      !value &&
      shouldAutofill.includes(id as string) &&
      bestItem &&
      bestItem.confidence >= 0.5
    ) {
      fillValue(bestItem.value);
      if (fieldAnalysisData) {
        fillValue(fieldAnalysisData.Text);
      }
    }
  }, [isDirty, defaultValues]);

  useEffect(() => {
    if (!valueMatchesAnalysisSuggestion) {
      resetSuggestion();
    }
  }, [valueMatchesAnalysisSuggestion]);

  if (!analysis || !fieldAnalysisData) {
    return renderField(null, null);
  }

  return renderField(
    analysis,
    <AnalysisSuggestions
      visibleId={analysis.definition.visibleId}
      fieldKey={fieldKey as string}
      data={fieldAnalysisData}
      selectAnalysisValue={(analysisData) => {
        fillValue(analysisData.value);
      }}
      converter={displayConverter}
    />
  );
};
