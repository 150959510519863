import styled from "@emotion/styled";
import { ListItem } from "@mui/material";
import {
  BaseOverflowTipTextContainer,
  BaseOverflowTipTextStyles,
} from "components/OverflowTip/styles";
import { Link } from "react-router-dom";
import { theme } from "theme";
import FlagIcon from "@mui/icons-material/Flag";
import { TaskAlt } from "@mui/icons-material";

export const ContractName = styled(BaseOverflowTipTextContainer)`
  flex: 1;
  color: ${theme.color.gray[500]};
`;

export const TaskName = styled(Link)`
  ${BaseOverflowTipTextStyles}
  font-weight: ${theme.font.weight.medium};
  display: inline-block;
  width: 100%;
`;

export const TaskListItem = styled(ListItem)`
  display: flex;
  align-items: center;
  &:nth-of-type(even) {
    background-color: ${theme.color.gray["100"]};
  }
`;

export const TaskContainer = styled.div`
  flex: 1;
  min-width: 0;
`;

export const TaskMetadataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TaskDateComponent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const TaskDateSpan = styled.span<{ color: string }>`
  color: ${(p) => p.color};
  white-space: nowrap;
`;

export const TaskDateFlagIcon = styled(FlagIcon, {
  shouldForwardProp: (props) => props !== "iconColor",
})<{ iconColor: string }>`
  margin-left: auto;
  color: ${(p) => p.iconColor};
`;

export const TaskIcon = styled(TaskAlt)`
  margin-right: ${theme.spacing.sm};
  color: ${theme.color.blue[600]};
`;
