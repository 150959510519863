import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import ContractIcon from "assets/svg/contract-icon.svg?react";
import TasksIcon from "assets/svg/check-square-icon.svg?react";
import {
  Wrapper,
  List,
  ListItem,
  IconWrapper,
} from "pages/Contract/components/RightSide/StickyMenu/styles";

type StickyMenuProps = {
  currentSelectedTab: {
    id: number;
    key: string;
  };
  handleSelectedTab: (val: number, key: string) => void;
};

export const options = [
  {
    id: 1,
    key: "documents",
    icon: ContractIcon,
  },
  {
    id: 2,
    key: "tasks",
    icon: TasksIcon,
  },
] as const;

const StickyMenu = ({
  currentSelectedTab,
  handleSelectedTab,
}: StickyMenuProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <List>
        {options.map((item) => {
          const Icon = item.icon;
          // @TODO: Show only the tabs which are available for current user (based on permissions system).
          return (
            <ListItem
              key={item.key}
              active={currentSelectedTab.id === item.id}
              onClick={() => handleSelectedTab(item.id, item.key)}
            >
              <Tooltip
                title={t(`pages.contact.folders.${item.key}`)}
                disableInteractive
                placement="left"
              >
                <IconWrapper>
                  <Icon />
                </IconWrapper>
              </Tooltip>
            </ListItem>
          );
        })}
      </List>
    </Wrapper>
  );
};

export default StickyMenu;
