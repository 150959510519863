import React from "react";
import {
  ContractInfoItem,
  ContractName,
  IconWrapper,
  CategoryName,
} from "./styles";
import Chip from "components/Chip/Chip";
import { getColor } from "components/Chip/helpers";
import { CategoryDTO, ContractDTOV1 } from "openapi";
import { useLocale } from "hooks";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

type ContractInfoProps = {
  contract: Pick<ContractDTOV1, "name">;
  category: Pick<CategoryDTO, "name" | "color">;
  categoryAccessible?: boolean | undefined;
};

const ContractInfo = ({
  contract,
  category,
  categoryAccessible,
}: ContractInfoProps) => {
  const { locale } = useLocale();

  return (
    <ContractInfoItem>
      {categoryAccessible !== undefined ? (
        <IconWrapper categoryAccessible={categoryAccessible}>
          {categoryAccessible ? <CheckIcon /> : <CloseIcon />}
        </IconWrapper>
      ) : null}

      <ContractName>{contract.name}</ContractName>
      <CategoryName>
        <Chip blendMode="soft" color={getColor(category.color)}>
          {category.name[locale]}
        </Chip>
      </CategoryName>
    </ContractInfoItem>
  );
};

export default ContractInfo;
