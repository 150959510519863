import React, { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContractFieldDTOV1, ContractSectionDTOV1, CategoryDTO } from "openapi";
import {
  AddNewButton,
  MinimizeButton as ToggleSectionsButton,
  DraggableSection,
  PopoverComponent,
} from "../../components";
import {
  AddNewSectionModal,
  DeleteSectionModal,
  EditSectionModal,
} from "./Modals";
import {
  Background,
  Column,
  ColumnTitle,
  ListContainer,
} from "../containerStyles";
import { getIdByName } from "../../utils";
import { theme } from "theme";
import { getSectionFields } from "../../utils/fields";

type Props = {
  onRemoveSection: (id: string) => Promise<void>;
  sections: ContractSectionDTOV1[];
  onRemoveField: (field: ContractFieldDTOV1, sectionId: string) => void;
  setSections: (val: ContractSectionDTOV1[]) => void;
  fields: ContractFieldDTOV1[];
  selectedCategory: CategoryDTO;
};

const Sections = ({
  fields,
  sections,
  onRemoveField,
  onRemoveSection,
  setSections,
  selectedCategory,
}: Props) => {
  const { t } = useTranslation();
  const [popoverElement, setPopoverElement] =
    useState<HTMLButtonElement | null>();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedSection, setSelectedSection] =
    useState<ContractSectionDTOV1 | null>();

  const open = Boolean(popoverElement);

  const handleOnOpenPopover = (
    event: MouseEvent<HTMLButtonElement>,
    sectionObj: ContractSectionDTOV1
  ) => {
    setPopoverElement(event.currentTarget);
    setSelectedSection(sectionObj);
  };

  const handleOnClosePopover = () => setPopoverElement(null);

  const showSections =
    selectedCategory && sections?.length && sections.length > 0;

  const handleSectionToggle = () => setIsCollapsed((prev) => !prev);

  return (
    <Column>
      <ColumnTitle>
        <div>
          <ToggleSectionsButton
            data-cy="collapse-sections-button"
            onClick={handleSectionToggle}
            isActive={isCollapsed}
            text={
              isCollapsed
                ? t(
                    "pages.settings.organization.administrator.sections.showSectionsContentButton"
                  )
                : t(
                    "pages.settings.organization.administrator.sections.hideSectionsContentButton"
                  )
            }
          />
          <span style={{ marginLeft: theme.spacing.md }}>
            {t("pages.settings.organization.administrator.sections.title")}
          </span>
        </div>
        {selectedCategory && (
          <AddNewButton
            id="addNewSectionButton"
            onClick={() => setOpenCreateModal(true)}
            text={t(
              "pages.settings.organization.administrator.sections.addNew"
            )}
          />
        )}
      </ColumnTitle>

      <Background>
        <ListContainer id="section-list">
          {showSections
            ? sections.map((section) => (
                <DraggableSection
                  id={section.id}
                  isCollapsed={isCollapsed}
                  key={section.id}
                  fields={getSectionFields(fields, section.fields)}
                  onRemoveField={onRemoveField}
                  section={section}
                  handleOnOpenPopover={(e: MouseEvent<HTMLButtonElement>) =>
                    handleOnOpenPopover(e, section)
                  }
                  isSettingsDisabled={false}
                  popoverComponent={
                    <PopoverComponent
                      id={section.id}
                      editOptionId={`${getIdByName(
                        section.name["en"]
                      )}-section-settings-btn-edit`}
                      deleteOptionId={`${getIdByName(
                        section.name["en"]
                      )}-section-settings-btn-delete`}
                      open={open}
                      popoverElement={popoverElement}
                      setOpenEditModal={() => setOpenEditModal(true)}
                      setOpenDeleteModal={() => setOpenDeleteModal(true)}
                      handleOnClosePopover={handleOnClosePopover}
                    />
                  }
                />
              ))
            : null}
        </ListContainer>
      </Background>

      <AddNewSectionModal
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        sections={sections}
        setSections={setSections}
      />

      {selectedSection && (
        <>
          <DeleteSectionModal
            open={openDeleteModal}
            section={selectedSection}
            sections={sections}
            setSections={setSections}
            onRemoveSection={onRemoveSection}
            handleOnClose={() => {
              setOpenDeleteModal(false);
              handleOnClosePopover();
            }}
          />

          <EditSectionModal
            open={openEditModal}
            onClose={() => {
              setOpenEditModal(false);
              handleOnClosePopover();
            }}
            section={selectedSection}
            sections={sections}
            setSections={setSections}
          />
        </>
      )}
    </Column>
  );
};

export default Sections;
