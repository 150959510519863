import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { Add as AddIcon } from "@mui/icons-material";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import TasksTable from "./components/TasksTable/TasksTable";
import { Header, Wrapper, NewTaskButton } from "./styles";
import { Divider, Title, TitleWrapper } from "pages/Contracts/styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import TaskDetails from "./Modals/TaskDetails";
import { EntityGridFilterResetButton } from "../../components/EntityGrid/EntityGridFilterResetButton";
import { EntityGridViewSelector } from "../../components/EntityGrid/EntityGridViewSelector";
import { EntityGridSearchInput } from "../../components/EntityGrid/EntityGridSearchInput";
import { ActionButtons } from "../../components/Modal/styles";

type TasksProps = {
  contractId?: string;
  viewType?: string;
  contactId?: string;
  title?: string;
  isNewTaskButtonHidden?: boolean;
  viewColumns?: string[];
  style?: React.CSSProperties;
};

const TasksPage = ({
  contractId,
  viewType,
  contactId,
  title,
  isNewTaskButtonHidden,
  viewColumns,
  ...props
}: TasksProps) => {
  const [showTaskDetails, setShowTaskDetails] = useState(false);
  const buttonStyles = useStyles();
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>();
  const [searchParams, setSearchParams] = useSearchParams();
  const taskId = searchParams.get("task") ?? undefined;

  useEffect(() => {
    if (taskId !== undefined) {
      setShowTaskDetails(true);
    }
  }, [taskId]);

  const onDetailsClose = () => {
    setSearchParams({});
    setShowTaskDetails(false);
  };

  return (
    <Wrapper
      className="flex-column"
      sx={{ flex: 1, height: "100%" }}
      {...props}
    >
      {title && (
        <>
          <TitleWrapper>
            <TaskAltIcon fontSize="small" />
            <Title>{title}</Title>
          </TitleWrapper>
          <Divider />
        </>
      )}
      {!isNewTaskButtonHidden && (
        <Header>
          <EntityGridSearchInput
            data-cy="searchTasks"
            label={t("pages.tasks.header.searchTasks")}
          />
          <ActionButtons>
            <EntityGridFilterResetButton />
            <EntityGridViewSelector />

            <NewTaskButton
              onClick={() => setShowTaskDetails(true)}
              className={buttonStyles.navBlueButton}
            >
              <span style={{ textTransform: "none" }}>
                {t("pages.tasks.header.buttons.createNewTask")}
              </span>
              <AddIcon fontSize="small" />
            </NewTaskButton>
          </ActionButtons>
        </Header>
      )}
      <TasksTable
        contractId={contractId}
        contactId={contactId}
        gridRef={gridRef}
        viewType={viewType}
        viewColumns={viewColumns as []}
      />
      <TaskDetails
        drawerOpen={showTaskDetails}
        taskId={taskId}
        handleOnModalClose={onDetailsClose}
        contractId={contractId}
        contactId={contactId}
      />
    </Wrapper>
  );
};

export default TasksPage;
