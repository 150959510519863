import styled from "@emotion/styled";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorCircleIcon from "@mui/icons-material/Error";
import { theme } from "theme";

export const Section = styled.div`
  margin: ${theme.spacing.xxl} 0;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 30% auto;
  grid-column-gap: 50px;
`;

export const Form = styled.form`
  display: flex;
  max-width: 36rem;
  flex-direction: column;
  justify-content: space-between;
  margin: ${theme.spacing.lg} 0;
`;

export const FormTitle = styled.h3`
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 1rem;
`;

export const CodeHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    font-size: ${theme.font.size.subheading};
    font-weight: ${theme.font.weight.normal};
    font-family: monospace;
    margin-right: ${theme.spacing.md};
    color: ${theme.color.white};
  }

  svg {
    color: #fff;
  }
`;

export const CodeTitle = styled.div`
  line-height: 1.5rem;
  display: flex;
  align-items: center;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const commonIconStyles = `
  font-size: 18px;
`;

export const CopyIcon = styled(ContentCopyIcon)`
  ${commonIconStyles}
`;

export const DoneIcon = styled(DoneAllIcon)`
  fill: #27bba3;
  ${commonIconStyles}
`;

export const SuccessIcon = styled(CheckCircleIcon)`
  fill: #27bba3;
  ${commonIconStyles}
`;

export const ErrorIcon = styled(ErrorCircleIcon)`
  ${commonIconStyles}
  fill: #d32f2f;
`;

export const FormContainer = styled.div`
  padding-bottom: 1rem;
`;
