import React from "react";
import { CardBodyWrapper, Role } from "components";
import { useRoleQuery } from "shared/api/roles";
import {
  TeamRoleMembersContainer,
  TeamRoleMembersWrapper,
  TeamRoleMembersTitle,
} from "./styles";

export type TeamRoleMembersProps = {
  title?: string;
  roleId: string;
  organizationId: string;
};

const TeamRoleMembers = ({
  title,
  roleId,
  organizationId,
}: TeamRoleMembersProps): JSX.Element => {
  const {
    data: roleData,
    isError,
    isLoading,
  } = useRoleQuery(roleId, organizationId);

  if (isLoading) return <></>;
  if (isError) return <div>Error</div>;

  return roleData?.members?.length ? (
    <TeamRoleMembersContainer data-testid="team-role-members">
      <TeamRoleMembersTitle data-testid="team-role-members-title">
        {title}
      </TeamRoleMembersTitle>
      <TeamRoleMembersWrapper>
        {roleData.members.map((member) => {
          const fullName = `${member.firstname || ""} ${member.lastname || ""}`;
          return <Role key={member.id} name={fullName} data-testid="member" />;
        })}
      </TeamRoleMembersWrapper>
    </TeamRoleMembersContainer>
  ) : (
    <TeamRoleMembersContainer data-testid="team-role-members-error">
      <TeamRoleMembersTitle data-testid="team-role-members-error-title">
        {title}
      </TeamRoleMembersTitle>
      <CardBodyWrapper
        noHorizontalPadding
        textKey={
          "pages.settings.tabs.team.teamRoles.modals.edit.noMembersMessage"
        }
      />
    </TeamRoleMembersContainer>
  );
};

export default TeamRoleMembers;
