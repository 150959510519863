import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams, unstable_useBlocker } from "react-router-dom";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import _ from "lodash";
import {
  OrganizationNewService,
  CreateWebhookDto,
  WebhookDTO,
  UpdateWebhookDTO,
} from "openapi";
import { useTeam } from "contexts/team/hooks";
import {
  ButtonWithArrow,
  BasicTable,
  CardWrapper,
  CTAButton,
  FormTextField,
  MessageBox,
  UnsavedChangesModal,
} from "components";
import { FormSwitch } from "components/FormItems/FormSwitch/FormSwitch";
import routePaths from "constants/routePaths";
import { theme } from "theme";
import LampIcon from "assets/svg/bulb-icon.svg?react";
import { hasValidProperty } from "utils/forms";
import { DeleteSection, SnippetExamples, HistoryLog } from "../components";
import { TestURLButton } from "./TestURLButton";
import { defaultEventOptions } from "./config";
import { SectionTitle, EventOptionLabel } from "../components/styles";
import { useCreateWebhookMutation, useUpdateWebhookMutation } from "shared/api";
import {
  Wrapper,
  Title,
  Form,
  URLFieldGroup,
  Body,
  FieldDescription,
  CheckAllWrapper,
  MessageBoxWrapper,
  Footer,
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableCell,
} from "./styles";

const validationSchema = () =>
  Yup.object({
    name: Yup.string().required(),
    url: Yup.string().required(),
    events: Yup.array().of(Yup.string()).required(),
  });

const Webhook = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: webhookId } = useParams();
  const { organizationId } = useTeam();
  const [webhookEventsOptions, setWebhookEventsOptions] =
    useState<WebhookDTO[]>(defaultEventOptions);
  const [currentWebhookDetails, setCurrentWebhookDetails] = useState<{
    url: string;
    events: string[];
  }>();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    resolver: yupResolver(validationSchema()),
  });

  const currentFormState = useWatch({
    control,
  });
  const createWebhookMutation = useCreateWebhookMutation();
  const updateWebhookMutation = useUpdateWebhookMutation();

  const isEditMode = webhookId && webhookId !== "new";
  const selectedWebhookURL = getValues("url") as string;

  const blocker = unstable_useBlocker(hasUnsavedChanges);

  const handleDiscardChanges = () => {
    setShowModal(false);
    blocker.proceed?.();
  };

  const handleCloseModal = (shouldResetBlocker = true) => {
    setShowModal(false);
    if (shouldResetBlocker) {
      blocker.reset?.();
    }
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newCheckedState = event.target.checked;
    const allEventsSelected = webhookEventsOptions.map((item) => ({
      ...item,
      id: item.name,
      active: newCheckedState,
    }));

    setWebhookEventsOptions(allEventsSelected);

    if (newCheckedState) {
      const events = allEventsSelected.map((item) => item.name);
      setValue("events", events);
    } else {
      setValue("events", []);
    }
  };

  const handleCheckboxChange = (index: number) => {
    if (!webhookEventsOptions) return;
    const newItems = [...webhookEventsOptions];
    newItems[index].active = !newItems[index].active;

    setWebhookEventsOptions(newItems);

    const events = newItems
      .filter((item) => item.active)
      .map((item) => item.name);
    setValue("events", events);
  };

  const getCurrentWebhookEventDetails = async () => {
    try {
      if (!webhookId) return;

      const response = await OrganizationNewService.getWebhook(
        webhookId,
        organizationId
      );

      const initialWebhookValues = {
        ...response,
        events: response.events.map((event) => event.type),
      };

      // @Initialize text fields
      reset(initialWebhookValues);
      // @Initialize checkboxes fields
      const eventsOptions = defaultEventOptions.map((event) => ({
        ...event,
        active: initialWebhookValues.events.some(
          (eventName) => eventName === event.name
        ),
      }));

      setCurrentWebhookDetails(initialWebhookValues);
      setWebhookEventsOptions(eventsOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data: UpdateWebhookDTO | CreateWebhookDto) => {
    if (isEditMode) {
      await updateWebhookMutation.mutateAsync({
        webhookId,
        organizationId,
        requestBody: {
          ...data,
          active: data.active,
        } as UpdateWebhookDTO,
      });
      reset();
    } else {
      await createWebhookMutation.mutateAsync({
        organizationId,
        requestBody: { ...data, active: true } as CreateWebhookDto,
      });
      reset();
    }
    await getCurrentWebhookEventDetails();
    blocker.proceed?.();
    navigate(routePaths.SETTINGS_WEBHOOK);
  };

  useEffect(() => {
    reset();
    if (isEditMode) void getCurrentWebhookEventDetails();
  }, [isEditMode]);

  useEffect(() => {
    if (isEditMode && currentWebhookDetails) {
      setHasUnsavedChanges(!_.isEqual(currentFormState, currentWebhookDetails));
    } else {
      setHasUnsavedChanges(hasValidProperty(currentFormState));
    }
  }, [currentFormState, currentWebhookDetails]);

  if (!webhookId) return null;

  const eventsTableHeaders = [
    {
      key: "checkbox",
      name: (
        <FormControlLabel
          label=""
          control={
            <CheckAllWrapper>
              <Checkbox
                onChange={handleSelectAllChange}
                name="selectAll"
                checked={webhookEventsOptions?.every((item) => item.active)}
                indeterminate={
                  !webhookEventsOptions?.every((item) => item.active) &&
                  webhookEventsOptions?.some((item) => item.active)
                }
              />
            </CheckAllWrapper>
          }
        />
      ),
    },
    {
      key: "type",
      name: t(
        "pages.settings.organization.webhooks.common.events.table.header.event"
      ),
    },
    {
      key: "description",
      name: t(
        "pages.settings.organization.webhooks.common.events.table.header.triggerWhen"
      ),
    },
  ];

  const eventsTableRows = webhookEventsOptions?.map((eventOption, index) => ({
    checkbox: (
      <FormControlLabel
        label=""
        control={
          <Checkbox
            checked={eventOption.active}
            onChange={() => handleCheckboxChange(index)}
            name={`items[${index}].active`}
          />
        }
      />
    ),
    type: (
      <EventOptionLabel>
        {t(
          `pages.settings.organization.webhooks.common.events.options.${eventOption.name}.label`
        )}
      </EventOptionLabel>
    ),
    description: t(
      `pages.settings.organization.webhooks.common.events.options.${eventOption.name}.description`
    ),
  }));

  return (
    <>
      <Wrapper>
        <ButtonWithArrow
          style={{ marginBottom: theme.spacing.xl }}
          label={t("common.buttons.back")}
          onClick={() => navigate(routePaths.SETTINGS_WEBHOOK)}
        />

        <CardWrapper>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Title>
              {isEditMode
                ? t("pages.settings.organization.webhooks.header.editTitle")
                : t("pages.settings.organization.webhooks.header.createTitle")}
            </Title>

            <Body>
              <FieldDescription>
                {t(
                  "pages.settings.organization.webhooks.common.fields.name.label"
                )}
              </FieldDescription>

              <FormTextField
                control={control}
                name="name"
                label={t(
                  "pages.settings.organization.webhooks.common.fields.name.placeholder"
                )}
              />

              <FieldDescription>
                {t(
                  "pages.settings.organization.webhooks.common.fields.url.label"
                )}
              </FieldDescription>

              <URLFieldGroup>
                <FormTextField
                  style={{ width: "calc(100% - 125px)" }}
                  control={control}
                  name="url"
                  label={t(
                    "pages.settings.organization.webhooks.common.fields.url.placeholder"
                  )}
                />

                <TestURLButton selectedWebhookURL={selectedWebhookURL} />
              </URLFieldGroup>

              {!isEditMode && (
                <MessageBoxWrapper>
                  <MessageBox
                    description="https://hooks.your-endpoint.com/updates"
                    icon={<LampIcon fill={theme.color.white} />}
                    variation="standard"
                    title="Example:"
                  />
                </MessageBoxWrapper>
              )}

              {isEditMode && (
                <Box sx={{ margin: `${theme.spacing.xxl} 0` }}>
                  <FormSwitch
                    control={control}
                    name="active"
                    label={t(
                      "pages.settings.organization.webhooks.common.fields.active"
                    )}
                  />
                </Box>
              )}
            </Body>

            <SectionTitle>
              {t(
                "pages.settings.organization.webhooks.common.events.description"
              )}
            </SectionTitle>

            <FormGroup>
              <BasicTable
                disableDefaultStyles
                sortable={false}
                headers={eventsTableHeaders}
                data={eventsTableRows}
                components={{
                  Table,
                  TableHead,
                  TableRow,
                  TableHeadCell,
                  TableCell,
                }}
              />
            </FormGroup>

            <Footer visible={hasUnsavedChanges}>
              <CTAButton
                type="button"
                name={t("common.buttons.cancel")}
                label={t("common.buttons.cancel")}
                variant="secondary"
                onClick={() => navigate(routePaths.SETTINGS_WEBHOOK)}
              />

              <CTAButton
                type="submit"
                variant="primary"
                label={
                  isEditMode
                    ? t("common.buttons.update")
                    : t("common.buttons.saveChanges")
                }
                name={
                  isEditMode
                    ? t("common.buttons.update")
                    : t("common.buttons.saveChanges")
                }
              />
            </Footer>
          </Form>

          <UnsavedChangesModal
            title={t("common.modals.unsavedChanges.title")}
            description={t("common.modals.unsavedChanges.subtitle")}
            showModal={showModal || (!!blocker && blocker.state === "blocked")}
            handleDiscardChanges={handleDiscardChanges}
            handleSaveChanges={handleSubmit(onSubmit)}
            handleCloseModal={handleCloseModal}
            saveBtnId="saveWebhookChanges"
            discardBtnId="discardWebhookChanges"
          />
        </CardWrapper>

        {isEditMode && <HistoryLog id={webhookId} />}

        {isEditMode && <SnippetExamples />}

        {isEditMode && currentWebhookDetails && (
          <DeleteSection
            url={currentWebhookDetails.url}
            events={currentWebhookDetails.events}
          />
        )}
      </Wrapper>
    </>
  );
};

export default Webhook;
