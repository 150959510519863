import {
  CategoryDTO,
  ContractFieldCreateDTOV1,
  ContractFieldDTOV1,
  ContractInputDTOV1,
} from "openapi";
import globalConfig from "shared/config/global.config";

const step = 1024;
const convertToBytes = (megaBytes: number): number => {
  return megaBytes * step * step;
};
const convertToMegaBytes = (bytes: number): number => {
  return bytes / step / step;
};

export const totalSizeInMegaBytes = (files: File[]): number => {
  const maxTotalSize = files.reduce(
    (total: number, currentFile: { size: number }): number => {
      const sum: number = total + currentFile?.size;
      return sum;
    },
    0
  );

  return convertToMegaBytes(maxTotalSize);
};

export const isTotalUploadSizeValid = (files: { size: number }[]): boolean => {
  const defaultLimit = 256;
  const limitSizeInMegaBytes = parseInt(
    globalConfig.REACT_APP_MAX_TOTAL_FILE_SIZE as string
  );
  const limitInBytes: number = convertToBytes(
    limitSizeInMegaBytes || defaultLimit
  );

  const maxTotalSize = files.reduce(
    (total: number, currentFile: { size: number }): number => {
      const sum: number = total + currentFile?.size;
      return sum;
    },
    0
  );

  return maxTotalSize < limitInBytes;
};

export const getSizeLimit = (): number => {
  const defaultLimit = 256;
  const limitSizeInMegaBytes = parseInt(
    globalConfig.REACT_APP_MAX_TOTAL_FILE_SIZE as string
  );

  return limitSizeInMegaBytes || defaultLimit;
};

export const getPrefilledFields = (
  selectedTeamId: string,
  categories: CategoryDTO[],
  fields: ContractFieldDTOV1[],
  contactId?: string
): ContractInputDTOV1["fields"] => {
  const prefilledFields: ContractInputDTOV1["fields"] = {};

  const category =
    categories.find((item) => {
      const teamInfo = item.categoryTeamsInfo?.find(
        (teamsInfo) => teamsInfo.teamId === selectedTeamId
      );
      return teamInfo?.default;
    }) || categories.find((item) => item.teams.includes(selectedTeamId));

  if (category && contactId) {
    const contactFields = new Map<string, ContractFieldDTOV1>(
      fields
        .filter(
          (field) =>
            field.type === ContractFieldCreateDTOV1.type.CONTACT &&
            field.visibleId === "partnerCompany"
        )
        .map((field) => [field.id, field])
    );

    for (const section of category.sections) {
      for (const field of section.fields) {
        const fullField = contactFields.get(field.id);
        if (fullField) {
          prefilledFields[field.id] = {
            value: contactId,
          };
          break;
        }
      }
    }
  }
  return prefilledFields;
};
