export const getErrorMessage = (error: string | undefined) => {
  switch (error) {
    case "write_protected_file":
      return "pages.contractDetails.modals.requestSignature.errors.writeProtected";
    case "no_eid_feature":
      return "pages.contractDetails.modals.requestSignature.errors.noEidFeature";
    case "no_signature_feature":
      return "pages.contractDetails.modals.requestSignature.errors.noSignatureFeature";

    case "not_enough_eid_credits":
      return "pages.contractDetails.modals.requestSignature.errors.noEidCredits";

    case "not_enough_signature_credits":
      return "pages.contractDetails.modals.requestSignature.errors.noSignatureCredits";

    default:
      return "pages.contractDetails.modals.requestSignature.errors.message";
  }
};
