import React, { CSSProperties } from "react";
import { Box } from "@mui/material";
import { Wrapper, TitleWrapper, SubTitleWrapper, Actions } from "./styles";

export type CardHeaderWrapperProps = {
  title: string;
  titleInformation?: string;
  subTitle?: string | JSX.Element;
  icon?: JSX.Element;
  titleStyles?: CSSProperties;
  actions?: JSX.Element | null;
};

const CardHeaderWrapper = ({
  title,
  titleInformation,
  titleStyles,
  subTitle,
  actions,
  icon,
}: CardHeaderWrapperProps) => {
  return (
    <Wrapper data-testid="card-header-wrapper">
      <div>
        <Box sx={{ display: "flex" }}>
          {icon && <Box sx={{ marginRight: "0.5rem" }}>{icon}</Box>}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <TitleWrapper
              style={titleStyles}
              id="cardTitleWrapper"
            >{`${title} ${titleInformation ?? ""}`}</TitleWrapper>
          </Box>
        </Box>
        {subTitle && <SubTitleWrapper>{subTitle}</SubTitleWrapper>}
      </div>
      {actions && <Actions>{actions}</Actions>}
    </Wrapper>
  );
};

export default CardHeaderWrapper;
