import React from "react";
import { Title } from "./styles";
import { theme } from "theme";
import { getDarkenedColor, getSoftenedColor } from "utils/color";

export type CategoryBadgeType = {
  name?: string;
  color?: string;
};

const CategoryBadge = ({ name, color }: CategoryBadgeType) => {
  const defaultTextColor = theme.color.blue[600];
  const defaultBgColor = theme.color.blue[200];

  return (
    <Title
      bgColor={getSoftenedColor(color || defaultTextColor)}
      textColor={getDarkenedColor(color || defaultBgColor)}
    >
      {name}
    </Title>
  );
};

export default CategoryBadge;
