import React, { MouseEvent, forwardRef, useMemo } from "react";
import { IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { ContractFieldDTOV1, ContractSectionDTOV1 } from "openapi";
import { useLocale } from "hooks/GlobalStateHooks";
import { HandleIcon, DroppableFieldsArea, PaperContainer } from "../..";
import { SectionHeader, SectionTitle } from "../styles";
import { getIdByName } from "../../../utils";
import { getSectionFields } from "../../../utils/fields";

type Props = {
  section: ContractSectionDTOV1 | null;
  fields: ContractFieldDTOV1[];
  isCollapsed?: boolean;
  handleProps?: Record<string, unknown>;
  isSettingsDisabled: boolean;
  popoverComponent?: React.ReactNode;
  handleOnOpenPopover?: (e: MouseEvent<HTMLButtonElement>) => void;
  onRemoveField?: (field: ContractFieldDTOV1, sectionId: string) => void;
  style?: React.CSSProperties;
  showDroppableArea?: boolean;
  hover?: boolean;
};

const BaseSection = forwardRef<HTMLDivElement, Props>(
  (
    {
      section,
      fields,
      isCollapsed,
      handleProps,
      isSettingsDisabled,
      popoverComponent,
      handleOnOpenPopover,
      onRemoveField,
      showDroppableArea = false,
      hover = false,
      ...props
    }: Props,
    ref
  ) => {
    const { locale } = useLocale();

    const filteredFields = useMemo(() => {
      return section ? getSectionFields(fields, section.fields) : [];
    }, [fields, section?.fields]);

    if (!section) {
      return null;
    }

    return (
      <div {...props} ref={ref}>
        <PaperContainer>
          <SectionHeader>
            <div style={{ display: "flex", gap: ".5rem" }}>
              <HandleIcon {...handleProps} />
              <SectionTitle>{section.name[locale]}</SectionTitle>
            </div>

            {!isSettingsDisabled && (
              <IconButton
                id={`${getIdByName(section.name["en"])}-section-settings-btn`}
                className="settings"
                onClick={handleOnOpenPopover}
              >
                <SettingsIcon fontSize="small" />
              </IconButton>
            )}

            {popoverComponent}
          </SectionHeader>

          <DroppableFieldsArea
            isCollapsed={isCollapsed}
            sectionId={section.id}
            section={section}
            fields={filteredFields}
            onRemoveField={onRemoveField ? onRemoveField : () => void 0}
            showDroppableArea={showDroppableArea}
            hover={hover}
          />
        </PaperContainer>
      </div>
    );
  }
);
BaseSection.displayName = "Section";
export default BaseSection;
