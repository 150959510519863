import React, { InputHTMLAttributes, ReactNode, forwardRef } from "react";
import { Rail, Input, Knob, OuterLabel, Label } from "./styles";

export type SwitchProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string | ReactNode;
  stretch?: boolean;
  variant?: "primary" | "without-icon";
  labelPosition?: "left" | "right";
};

const Switch = forwardRef<HTMLInputElement, SwitchProps>(function Switch(
  {
    label,
    labelPosition = "right",
    stretch = false,
    variant = "primary",
    ...props
  },
  ref
) {
  return (
    <OuterLabel
      labelPosition={labelPosition}
      stretch={stretch}
      disabled={props.disabled}
    >
      <Input {...props} variant={variant} ref={ref} type="checkbox" />
      <Rail>
        <Knob />
      </Rail>
      {label && <Label>{label}</Label>}
    </OuterLabel>
  );
});

export default Switch;
