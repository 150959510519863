import styled from "@emotion/styled";
import { breakpoints, theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
`;

export const AccessWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing.xs};
  @media screen and (min-width: ${breakpoints.lg}) {
    align-items: center;
    flex-direction: row;
  }
`;

export const ValueIconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  svg {
    transform: rotate(90deg);
    width: 20px;
    height: 20px;
  }
`;
