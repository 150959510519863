/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ContractViewItemDto = {
  id: string;
  type: ContractViewItemDto.type;
  name: string;
  data: string;
  filter: string | null;
  shared: boolean;
  owner: Record<string, any>;
};

export namespace ContractViewItemDto {
  export enum type {
    CONTRACT = "CONTRACT",
    CONTACT = "CONTACT",
    TASK = "TASK",
    CONTRACT_ANALYSIS = "CONTRACT_ANALYSIS",
  }
}
