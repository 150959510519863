import React from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TaskNewCreateDTO, ContractFieldDTOV1, TaskNewDTO } from "openapi";
import { SwitchBar } from "new-components";
import { FormSelectItem } from "components/FormItems/FormSelect/FormSelect";
import RelativeDueDateComponent from "../../../components/RelativeDueDate/RelativeDueDate";
import FixedDueDateComponent from "../../../components/FixedDueDate/FixedDueDate";
import {
  CalculatedFixedDatesType,
  CalculatedRelativeDatesType,
  Task,
  NO_DUE_DATE,
} from "../../TaskDetails";
import {
  DueDateWrapper,
  DueDateContainer,
  UnsetDueDateMessage,
} from "./styles";

type DueDateProps = {
  activeTab: string;
  setActiveTab: (value: string) => void;
  control: Control<Task>;
  isDisabled: boolean;
  checkAccess: () => boolean;
  reminderIsEnabled: boolean;
  setReminderIsEnabled: (value: boolean) => void;
  repeatIsEnabled: boolean;
  setRepeatIsEnabled: (value: boolean) => void;
  getFixedFieldValue?: CalculatedFixedDatesType;
  dateFieldOptions: FormSelectItem[];
  getDynamicFieldValue?: CalculatedRelativeDatesType;
  task?: TaskNewDTO;
  isBaseDate: boolean;
  taskId?: string;
  fields?: ContractFieldDTOV1[];
};
const DueDate = ({
  activeTab,
  setActiveTab,
  checkAccess,
  control,
  isDisabled,
  reminderIsEnabled,
  repeatIsEnabled,
  setReminderIsEnabled,
  setRepeatIsEnabled,
  getFixedFieldValue,
  dateFieldOptions,
  getDynamicFieldValue,
  task,
  isBaseDate,
  taskId,
  fields,
}: DueDateProps) => {
  const { t } = useTranslation();
  return (
    <DueDateWrapper>
      <SwitchBar
        activeKey={activeTab}
        setActiveKey={setActiveTab}
        disabled={isDisabled}
        options={[
          {
            key: TaskNewCreateDTO.dueDateType.FIXED,
            label: t("pages.tasks.modal.fields.dateTypes.fixed"),
            active: activeTab === TaskNewCreateDTO.dueDateType.FIXED,
          },
          {
            key: TaskNewCreateDTO.dueDateType.RELATIVE,
            label: t("pages.tasks.modal.fields.dateTypes.relative"),
            active: activeTab === TaskNewCreateDTO.dueDateType.RELATIVE,
          },
          {
            key: NO_DUE_DATE,
            label: t("pages.tasks.modal.fields.dateTypes.noDueDate"),
            active: activeTab === NO_DUE_DATE,
          },
        ]}
      />
      <DueDateContainer>
        {activeTab === TaskNewCreateDTO.dueDateType.FIXED && (
          <FixedDueDateComponent
            disabled={!checkAccess()}
            control={control}
            reminderIsEnabled={reminderIsEnabled}
            repeatIsEnabled={repeatIsEnabled}
            toggleReminder={setReminderIsEnabled}
            toggleRepeat={setRepeatIsEnabled}
            calculatedDates={getFixedFieldValue}
          />
        )}
        {activeTab === TaskNewCreateDTO.dueDateType.RELATIVE && (
          <RelativeDueDateComponent
            control={control}
            dateFieldOptions={dateFieldOptions || []}
            calculatedDates={getDynamicFieldValue}
            reminderIsEnabled={reminderIsEnabled}
            repeatIsEnabled={repeatIsEnabled}
            toggleReminder={setReminderIsEnabled}
            toggleRepeat={setRepeatIsEnabled}
            task={task}
            isBaseDate={isBaseDate}
            taskId={taskId}
            fields={fields}
          />
        )}
        {activeTab === NO_DUE_DATE && (
          <UnsetDueDateMessage>
            <span>{t("pages.tasks.modal.hints.noDueDate")}</span>
          </UnsetDueDateMessage>
        )}
      </DueDateContainer>
    </DueDateWrapper>
  );
};

export default DueDate;
