import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { Container } from "components";
import { useUserInfo } from "hooks/GlobalStateHooks";
import Menu from "./Menu/Menu";

const AdminLayout = () => {
  const { userInfo } = useUserInfo();

  if (!userInfo?.admin) return <Navigate to="/" />;

  return (
    <Container fullWidth>
      <>
        <Menu />
        <Outlet />
      </>
    </Container>
  );
};

export default AdminLayout;
