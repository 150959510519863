import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import { ContractAttachmentService } from "openapi";
import {
  isTotalUploadSizeValid,
  getSizeLimit,
  totalSizeInMegaBytes,
} from "components/ContractUpload/helpers";
import {
  Dropzone,
  FolderIcon,
  Description,
  Caption,
  Divider,
  Button,
  Footer,
} from "./styles";
import { UploadSingleFileProps } from ".";

const SingleFileUpload = ({
  contractId,
  refetchPdf,
  showSelectTemplateButton,
  showImportFromTemplateModal,
  setIsLoading,
}: UploadSingleFileProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleUpload = async (file: File) => {
    if (!contractId) {
      enqueueSnackbar("This is not an existing contract!", {
        variant: "error",
      });
      return;
    }

    const isValidSize = isTotalUploadSizeValid([file]);

    if (!isValidSize) {
      enqueueSnackbar(
        t("contractUpload.validation.rejectedBySize", {
          maxSize: getSizeLimit(),
          fileSize: totalSizeInMegaBytes([file]).toFixed(1),
        }),
        {
          variant: "error",
        }
      );
      return;
    }

    try {
      setIsLoading(true);
      await ContractAttachmentService.uploadFileForContract(contractId, {
        file,
      });
      await refetchPdf?.();
      enqueueSnackbar(t("contractUpload.successfulUpload.subtitle"), {
        variant: "success",
      });
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar(t("contractUpload.failedUpload"), {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    void handleUpload(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: { "application/pdf": [".pdf"] },
  });

  return (
    <>
      <Dropzone {...getRootProps()} isFullHeight={!showSelectTemplateButton}>
        <input {...getInputProps()} data-cy="dropzone" />
        <FolderIcon />
        <Description>{t("contractUpload.dropZoneText")}</Description>
        <Caption>{t("contractUpload.dropZoneCaption")}</Caption>

        <Button id="uploadButton" variant="outlined">
          {t("contractUpload.buttons.selectContract")}
        </Button>
      </Dropzone>

      {showSelectTemplateButton && (
        <Footer>
          <Divider>{t("common.labels.or")}</Divider>

          <Button
            onClick={showImportFromTemplateModal}
            id="selectTemplateButton"
            variant="outlined"
          >
            {t("contractUpload.buttons.selectTemplate")}
          </Button>
        </Footer>
      )}
    </>
  );
};

export default SingleFileUpload;
