import styled from "@emotion/styled";
import { CTAButton } from "components";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  gap: 1px;

  svg {
    fill: ${theme.color.white};
  }
`;

export const ImportButton = styled(CTAButton)<{ hasRoundedBorder?: boolean }>`
  ${({ hasRoundedBorder }) =>
    hasRoundedBorder &&
    `
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `}
`;

export const MoreButton = styled(CTAButton)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: ${theme.spacing.sm};
`;

export const Menu = styled.ul`
  width: 200px;
  padding: 0;
`;

export const MenuItem = styled.li<{ remove?: boolean }>`
  display: flex;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.sm};
  font-weight: ${theme.font.weight.normal};
  border-radius: ${theme.borderRadius};
  transition: background-color 0.15s ease-in-out;

  svg {
    fill: ${theme.color.black};
  }

  ${({ remove }) =>
    remove &&
    `
    color: ${theme.color.red[600]};

    svg {
      fill: ${theme.color.red[600]};
    }
  `}

  :hover {
    background-color: ${theme.color.gray[200]};
    cursor: pointer;
  }
`;
