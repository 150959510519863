import React, { useState, createElement } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import Checkbox from "@mui/material/Checkbox";
import { ContractDTOV1 } from "openapi";
import { useContractVerificationMutation } from "shared/api";
import { useTeam } from "contexts/team/hooks";
import { CTAButton, NewModal } from "components";
import { Info } from "new-components";
import { Wrapper, AiIcon, DoNotShowOption } from "./styles";

const VerifyAllBanner = ({
  contracts,
  refetch,
}: {
  contracts: ContractDTOV1[];
  refetch: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const { organizationId } = useTeam();
  const [isOpen, setIsOpen] = useState(false);
  const doNotShowAgain =
    localStorage
      .getItem("verify-all-ai-fields-do-not-show-again-modal-status")
      ?.toString() || "false";
  const [showModalSessionState, setShowModalSessionState] =
    useState(doNotShowAgain);

  const contractVerificationMutation = useContractVerificationMutation();
  const contractsForVerification = contracts.filter(
    (contract) => contract.unapprovedAnalysisValues
  );

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleVerifyAllSubmit = () => {
    try {
      contractsForVerification.forEach(async (contract) => {
        await contractVerificationMutation.mutateAsync({
          organizationId: organizationId,
          teamId: contract.teamId,
          contractId: contract.id,
        });
      });

      void refetch();

      enqueueSnackbar(
        t("pages.contracts.messages.successfullyVerifiedAllContracts"),
        {
          variant: "success",
        }
      );
    } catch (e) {
      enqueueSnackbar(t("pages.contracts.messages.failedVerifyAllContracts"), {
        variant: "error",
      });
    }

    localStorage.setItem(
      "verify-all-ai-fields-do-not-show-again-modal-status",
      showModalSessionState
    );
    setIsOpen(false);
  };

  const text = t("pages.contracts.verifyBanner.text");
  const icon = <AiIcon className="cta" />;
  const verifyAllButton = () => (
    <CTAButton
      label="Verify all"
      name={t("common.buttons.verifyAll")}
      variant="secondary"
      onClick={() =>
        showModalSessionState === "true"
          ? handleVerifyAllSubmit()
          : handleOpen()
      }
    />
  );

  if (!contractsForVerification.length) return null;

  return (
    <>
      <Wrapper>
        <Info
          className="verify-all-banner"
          icon={icon}
          text={text}
          element={createElement(verifyAllButton)}
        />
      </Wrapper>

      <NewModal
        open={isOpen}
        handleClose={handleClose}
        fullWidth
        icon={icon}
        title={t("pages.contracts.verifyBanner.modal.title")}
        body={
          <>
            {t("pages.contracts.verifyBanner.text")}

            <DoNotShowOption>
              <Checkbox
                disableRipple
                onChange={(e) =>
                  setShowModalSessionState(e.target.checked.toString())
                }
              />
              <span>
                {t("pages.contracts.verifyBanner.modal.doNotShowAgain")}
              </span>
            </DoNotShowOption>
          </>
        }
        footer={
          <>
            <CTAButton
              label="cancel"
              name={t("common.buttons.cancel")}
              variant="secondary"
              onClick={handleClose}
            />
            <CTAButton
              label="verifyAll"
              name={t("common.buttons.verifyAll")}
              variant="primary"
              onClick={handleVerifyAllSubmit}
            />
          </>
        }
      />
    </>
  );
};

export default VerifyAllBanner;
