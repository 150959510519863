import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  font-family: "Courier New", monospace;
  color: ${theme.color.white};
  background-color: ${theme.color.gray[700]};
  border-radius: ${theme.borderRadius};
`;

export const Title = styled.div`
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.bold};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: ${theme.spacing.lg};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

  span {
    font-family: "Courier New", monospace;
    color: ${theme.color.white};
    font-size: ${theme.font.size.caption};
  }

  svg {
    margin-left: ${theme.spacing.md};
    color: ${theme.color.green[500]};
    width: 16px;
    height: 16px;
  }
`;

export const Body = styled.pre`
  padding: 0 ${theme.spacing.lg};
  height: 260px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
`;
