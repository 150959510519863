import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import React, { FC } from "react";
import { ToolbarButton } from "./ToolbarButton";
import { ToolbarSpecificButtonProps } from "./types";
import DownloadIcon from "@mui/icons-material/Download";
import { useTranslation } from "react-i18next";
export const DownloadButton: FC<ToolbarSpecificButtonProps<"Download">> = ({
  Slot,
  collapses,
  collapsed,
}) => {
  const { t } = useTranslation();
  return (
    <Slot>
      {(props) => {
        return collapsed ? (
          <MenuItem
            onClick={() => {
              props.onClick();
            }}
          >
            <ListItemIcon>
              <DownloadIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("pdfViewer.toolbar.download")}</ListItemText>
          </MenuItem>
        ) : (
          <ToolbarButton
            tooltip={t("pdfViewer.toolbar.download")}
            onClick={() => {
              props.onClick();
            }}
            collapses={collapses}
          >
            <DownloadIcon />
          </ToolbarButton>
        );
      }}
    </Slot>
  );
};
