import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, Control } from "react-hook-form";
import { NotificationSettingsUpdateDto } from "openapi";
import { NotificationType } from "../types";
import { Cell } from "./styles";
import { Switch } from "new-components";

type Props = {
  control: Control;
  notificationData: NotificationType;
  onSubmit: (data: NotificationSettingsUpdateDto) => void;
};

const ListItem = ({ control, notificationData: data, onSubmit }: Props) => {
  const { t } = useTranslation();
  const keyName = data.key;

  return (
    <>
      <Cell>
        {t(
          `pages.settings.tabs.notifications.configuration.general.${keyName}`
        )}
      </Cell>
      <Cell>
        <Controller
          control={control}
          name={`${keyName}-email`}
          render={() => (
            <Switch
              checked={data?.email}
              onChange={(e) => {
                const reqBody = {
                  [keyName]: {
                    email: e.target.checked,
                    app: data?.app,
                  },
                };

                onSubmit(reqBody);
              }}
            />
          )}
        />
      </Cell>

      <Cell>
        <Controller
          control={control}
          name={`${keyName}-app`}
          render={() => (
            <Switch
              checked={data?.app}
              onChange={(e) => {
                const reqBody = {
                  [keyName]: {
                    email: data?.email,
                    app: e.target.checked,
                  },
                };

                onSubmit(reqBody);
              }}
            />
          )}
        />
      </Cell>
    </>
  );
};

export default ListItem;
