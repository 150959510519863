import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.h5`
  line-height: 1.5rem;
  font-size: ${theme.font.size.h5};
  font-weight: 500;
  font-weight: ${theme.font.weight.medium};
  color: ${theme.color.gray[900]};
  margin: 0;
  margin-bottom: ${theme.spacing.sm};
  // @TODO: Double check if can be removed
  display: flex;
  align-items: center;
  gap: ${theme.spacing.lg};
`;
