import { Box, Divider, IconButton, Popper, Tooltip } from "@mui/material";
import { StyledTextField } from "components/StyledComponents/StyledBaseFields";
import React, { FC, useEffect, useRef, useState } from "react";
import { theme } from "theme";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { OCRSearchStoreProps, Search } from "./types";
import { Store } from "@react-pdf-viewer/core";
import { useOCRSearch } from "./useSearch";
export type OCRSearchPopoverProps = {
  store: Store<OCRSearchStoreProps>;
  ocrEnabled: boolean;
  enableOCR: () => void;
  onSearchChanged: (search: Search | undefined) => void;
};

export const OCRSearchPopover: FC<OCRSearchPopoverProps> = ({
  store,
  enableOCR,
  ocrEnabled,
  onSearchChanged,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();
  const [searchInput, setSearchInput] = useState("");

  const {
    open,
    performSearch,
    setSearch,
    matchCount,
    currentMatch,
    jumpToNextMatch,
    jumpToPreviousMatch,
  } = useOCRSearch(store);

  useEffect(() => {
    const debounceTimerId = setTimeout(() => {
      performSearch();
    }, 200);
    return () => {
      clearTimeout(debounceTimerId);
    };
  }, [searchInput]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [inputRef]);

  const clearSearch = () => {
    setSearch(null);
    setSearchInput("");
    onSearchChanged(undefined);
  };

  useEffect(() => {
    if (!ocrEnabled) {
      store.update("anchor", undefined);
      clearSearch();
    }
  }, [ocrEnabled]);

  useEffect(() => {
    store.subscribe("search", onSearchChanged);
    return () => store.unsubscribe("search", onSearchChanged);
  }, [onSearchChanged]);

  return (
    <>
      <Tooltip title={t("pdfViewer.toolbar.searchOCR")}>
        <IconButton
          sx={{
            color: ocrEnabled ? theme.color.blue[700] : theme.color.gray[700],
            opacity: ocrEnabled ? 1 : 0.5,
          }}
          onClick={(e) => {
            if (!ocrEnabled) {
              enableOCR();
            }

            const anchor = store.get("anchor")
              ? undefined
              : e.currentTarget.parentElement ?? undefined;
            store.update("anchor", anchor);
            if (!anchor) {
              clearSearch();
            }
          }}
        >
          <SearchIcon />
        </IconButton>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={store.get("anchor")}
        disablePortal
        placement={"bottom-start"}
        sx={{ zIndex: 1400 }}
      >
        <StyledTextField
          size="small"
          inputRef={inputRef}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              ev.preventDefault();
              jumpToNextMatch();
            } else if (ev.key === "Escape") {
              ev.preventDefault();
              store.update("anchor", undefined);
              clearSearch();
            }
          }}
          InputProps={{
            endAdornment: (
              <>
                <Box
                  sx={{ mr: "1em", whiteSpace: "nowrap", fontSize: ".875em" }}
                >
                  {`${matchCount > 0 ? currentMatch + 1 : 0} / ${matchCount}`}
                </Box>
                <Divider orientation="vertical" flexItem />
                <Tooltip title={t("common.buttons.back")}>
                  <IconButton
                    sx={{ color: theme.color.blue[700] }}
                    onClick={jumpToPreviousMatch}
                  >
                    <KeyboardArrowUpIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("common.buttons.next")}>
                  <IconButton
                    sx={{ color: theme.color.blue[700] }}
                    onClick={jumpToNextMatch}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("common.buttons.close")}>
                  <IconButton
                    sx={{ color: theme.color.blue[700] }}
                    onClick={() => {
                      store.update("anchor", undefined);
                      clearSearch();
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </>
            ),
          }}
          sx={{ mt: 2, backgroundColor: theme.color.white }}
          label={t("pdfViewer.toolbar.searchOCR")}
          value={searchInput}
          onChange={(e) => {
            setSearch(e.currentTarget.value);
            setSearchInput(e.currentTarget.value);
          }}
          autoFocus={true}
          onFocus={(event) => {
            event.target.select();
          }}
        />
      </Popper>
    </>
  );
};
