import styled from "@emotion/styled";
import { getContrastColor } from "utils/color";
import a from "color-alpha";
import { Close } from "@mui/icons-material";

type BlockProps = {
  orientation?: string;
  bgColor?: string;
  type?: string;
  blendMode?: "soft" | "hard";
};

const defaultColor = "#ffffff";
const defaultBgColor = "#4a5568";

export const Wrapper = styled.div`
  display: flex;
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 0 0.5rem;
  font-size: 0.8rem;
  min-height: 1.8rem;
  line-height: normal;
  color: ${defaultColor};
  background-color: ${defaultBgColor};

  ${({ bgColor, blendMode }: BlockProps) =>
    bgColor &&
    `
    color: ${blendMode === "hard" ? getContrastColor(bgColor) : bgColor};
    background-color: ${blendMode === "hard" ? bgColor : a(bgColor, 0.15)};
  `}

  // @TODO: This is the standard, let's remove the blendMode from above when we update the colors accordingly.
  ${({ bgColor: color }: BlockProps) =>
    color &&
    `
    color: ${color};
    background-color: ${color}40;
  `}

  ${({ type }: BlockProps) =>
    type === "default" &&
    `
    border-radius: 0.5rem;
  `}

  ${({ orientation }: BlockProps) =>
    orientation === "left" &&
    `
    color: ${defaultColor};
    background-color: ${defaultBgColor};
    border: 1px solid ${defaultBgColor};
    border-radius: 0.5rem 0 0 0.5rem;
  `}

  ${({ orientation, bgColor }: BlockProps) =>
    orientation === "right" &&
    `
    color: ${getContrastColor(bgColor || defaultColor)};
    background-color: ${bgColor || getContrastColor(defaultBgColor)};
    border: 1px solid ${defaultBgColor};
    border-radius: 0 0.5rem 0.5rem 0;
  `}
`;

export const CloseIcon = styled(Close)`
  font-size: 16px;
  margin-left: 0.5rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  :focus,
  :active {
    transform: scale(0.7);
  }
`;
