import React, { useState, useEffect } from "react";
import CardWrapper from "components/CardWrapper/CardWrapper";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import { useTranslation } from "react-i18next";
import { InvitationDTO } from "openapi";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Header, Row } from "components/BasicTable/types";
import BasicTable from "components/BasicTable/BasicTable";
import { ActionButtons } from "../../styles";
import { IconButton } from "@mui/material";
import DeleteTeamPendingInvitation from "./DeleteTeamPendingInvitations";

export type TeamPendingInvitationsProps = {
  teamPendingInvitationsData?: InvitationDTO[];
};

const TeamPendingInvitations = ({
  teamPendingInvitationsData,
}: TeamPendingInvitationsProps) => {
  const { t } = useTranslation();
  const [data, setData] = useState<Row[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState<string | undefined>(
    undefined
  );

  const getEmail = (id: string | undefined): string => {
    const member = data?.find((item) => item.id === id) as {
      email?: string | undefined;
    };
    return member ? member.email ?? "" : "";
  };

  const convertData = (teamPendingInvitations: InvitationDTO[]) => {
    return teamPendingInvitations.map((member) => {
      return {
        id: member.id,
        email: member.email,
        action: (
          <ActionButtons isInline>
            <IconButton
              className="delete"
              onClick={() => handleActionDeleteClick(member.id)}
            >
              <DeleteForeverIcon fontSize="small" />
            </IconButton>
          </ActionButtons>
        ),
      };
    });
  };

  useEffect(() => {
    if (teamPendingInvitationsData) {
      setData(convertData(teamPendingInvitationsData));
    }
  }, [teamPendingInvitationsData]);

  const headers: Header[] = [
    {
      key: "email",
      name: t("pages.settings.tabs.team.teamInvites.email").toString(),
    },
    {
      key: "action",
      name: "",
      notSortable: true,
    },
  ];

  const handleActionDeleteClick = (id: string) => {
    setShowDeleteModal(true);
    setSelectedMemberId(id);
  };

  const hasPendingInvitations =
    teamPendingInvitationsData && teamPendingInvitationsData.length > 0;

  if (!hasPendingInvitations) return null;

  return (
    <CardWrapper data-testid="team-pending-invitation">
      <CardHeaderWrapper
        title={t("pages.settings.tabs.team.teamInvites.title")}
        subTitle=""
      />
      <BasicTable
        id="teamInvitationsTable"
        headers={headers}
        data={data}
        isLastCellStyled
      />
      <DeleteTeamPendingInvitation
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        teamMemberEmail={getEmail(selectedMemberId)}
        resetSelectedMemberIndex={() => setSelectedMemberId(undefined)}
        selectedMemberId={selectedMemberId}
      />
    </CardWrapper>
  );
};

export default TeamPendingInvitations;
