import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TableHeadRow = styled.div`
  display: flex;
  width: 100%;
  background-color: ${theme.color.gray[100]};
  padding: ${theme.spacing.md} ${theme.spacing.lg};
  font-weight: ${theme.font.weight.semiBold};
  margin-bottom: ${theme.spacing.sm};
  cursor: default;
  border-radius: ${theme.borderRadius};
  text-transform: capitalize;
  color: ${theme.color.gray[600]};
  font-size: ${theme.font.size.body};
`;

export const TableHeadCell = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  :not(:first-child) {
    justify-content: center;
  }
`;

export const TableCell = styled.div`
  flex: 1;
  padding: ${theme.spacing.sm} ${theme.spacing.lg};
  display: flex;
  align-items: center;

  label {
    margin: 0;
  }

  :not(:first-child) {
    justify-content: center;
  }
`;

export const TableRow = styled.div`
  display: flex;
  width: 100%;
`;
