import React from "react";
import { useTranslation } from "react-i18next";
import WarningIcon from "@mui/icons-material/Warning";
import { CTAButton, NewModal } from "components";
import { theme } from "theme";
import { useSnackbar } from "notistack";
import { useInvitationDeleteMutation } from "shared/api";
import { useTeam } from "../../../../../../contexts/team/hooks";

type Props = {
  open: boolean;
  handleClose: () => void;
  resetSelectedMemberIndex: () => void;
  teamMemberEmail: string;
  selectedMemberId?: string;
};

const DeleteTeamPendingInvitation = ({
  open,
  handleClose,
  teamMemberEmail,
  selectedMemberId,
  resetSelectedMemberIndex,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { organizationId } = useTeam();
  const { mutateAsync: deleteInvitation } = useInvitationDeleteMutation();
  const handleDelete = async () => {
    if (!selectedMemberId) return;

    try {
      await deleteInvitation({
        teamMemberId: selectedMemberId,
        organizationId: organizationId,
      });
      enqueueSnackbar(
        t("pages.settings.tabs.team.teamInvites.modals.delete.successMessage"),
        {
          variant: "success",
        }
      );
    } catch (error) {
      enqueueSnackbar(
        t("pages.settings.tabs.team.teamInvites.modals.delete.errorMessage"),
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <NewModal
      open={open}
      icon={<WarningIcon sx={{ fill: theme.color.red[600] }} />}
      handleClose={handleClose}
      title={t("pages.settings.tabs.team.teamInvites.modals.delete.title", {
        teamMemberEmail: teamMemberEmail,
      })}
      fullWidth
      body={
        <>
          {t("pages.settings.tabs.team.teamInvites.modals.delete.description")}
        </>
      }
      footer={
        <>
          <CTAButton
            variant="secondary"
            onClick={handleClose}
            name={t("common.buttons.cancel")}
            label="close-revoke-invitation-button"
          />
          <CTAButton
            color="red"
            onClick={() => {
              void handleDelete();
              resetSelectedMemberIndex();
              handleClose();
            }}
            name={t("common.buttons.revoke")}
            label="revoke-invitation-button"
          />
        </>
      }
    />
  );
};

export default DeleteTeamPendingInvitation;
