import React from "react";
import { FormOffsetFieldWrapper, Row } from "./styles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { CTAButton } from "components";
import { FormNumericField, FormSelect } from "components/FormItems";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "theme";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import DueDateComponent from "../DueDate/DueDate";
import { Container } from "./styles";
import { Control } from "react-hook-form";
import { TaskAutomationDueDateConfigDTO } from "openapi";
import { useTranslation } from "react-i18next";
import { CalculatedFixedDatesType } from "pages/Tasks/Modals/TaskDetails";
import {
  FormItem,
  FormItemHint,
  FormItemLabel,
} from "../RelativeDueDate/styles";

export type FixedDueDateProps = {
  control: unknown;
  disabled?: boolean;
  reminderIsEnabled?: boolean;
  repeatIsEnabled?: boolean;
  toggleReminder: (value: boolean) => void;
  toggleRepeat: (value: boolean) => void;
  calculatedDates?: CalculatedFixedDatesType;
};

const FixedDueDateComponent = ({
  control,
  disabled,
  reminderIsEnabled,
  repeatIsEnabled,
  toggleReminder,
  toggleRepeat,
  calculatedDates,
}: FixedDueDateProps) => {
  const { t } = useTranslation();
  const dateFiledsEnums = TaskAutomationDueDateConfigDTO.unit;

  const dateUnits = Object.keys(dateFiledsEnums).map((item) => ({
    key: item,
    value: t(
      `common.intevalEnums.${
        dateFiledsEnums[item as keyof typeof dateFiledsEnums] as string
      }`
    ),
  }));

  return (
    <Container>
      <Row
        justifyItems="flex-start"
        gap={theme.spacing.lg}
        verticalMargin={theme.spacing.lg}
      >
        <AccessTimeIcon />
        <DueDateComponent
          control={control}
          disabled={disabled}
          fieldName="dueDateConfig.dueDate"
          fieldLabel={t("pages.tasks.modal.fields.dueDate")}
        />
      </Row>
      <Row
        justifyItems="flex-start"
        gap={theme.spacing.lg}
        verticalMargin={theme.spacing.lg}
      >
        <RepeatOutlinedIcon />
        {repeatIsEnabled ? (
          <>
            <FormItemLabel>{t("common.repeatEvery")}</FormItemLabel>
            <FormItem>
              <FormOffsetFieldWrapper>
                <FormNumericField
                  label=""
                  name="dueDateConfig.repeatConfig.offset"
                  decimalScale={0}
                  control={control as Control}
                  disabled={!repeatIsEnabled}
                  placeholder="1"
                  showButtons
                />
              </FormOffsetFieldWrapper>
              <FormSelect
                control={control as Control}
                name="dueDateConfig.repeatConfig.unit"
                options={dateUnits}
                style={{ maxWidth: "120px" }}
                margin="none"
                disabled={!repeatIsEnabled}
              />
              <IconButton
                className="delete"
                onClick={() => toggleRepeat(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              {calculatedDates?.repeatDate ? (
                <FormItemHint>
                  {t("pages.tasks.modal.hints.calculatedDate", {
                    date: calculatedDates?.repeatDate,
                  })}
                </FormItemHint>
              ) : null}
            </FormItem>
          </>
        ) : (
          <CTAButton
            variant="tertiary"
            name={t("common.buttons.addRepeat")}
            onClick={() => toggleRepeat(true)}
          />
        )}
      </Row>
      <Row
        justifyItems="flex-start"
        gap={theme.spacing.lg}
        verticalMargin={theme.spacing.lg}
      >
        <NotificationsNoneIcon />
        {reminderIsEnabled ? (
          <>
            <FormItem>
              <FormOffsetFieldWrapper>
                <FormNumericField
                  label=""
                  name="reminderConfig.offset"
                  decimalScale={0}
                  control={control as Control}
                  style={{ maxWidth: "80px" }}
                  placeholder="1"
                  showButtons
                />
              </FormOffsetFieldWrapper>
              <FormSelect
                control={control as Control}
                name="reminderConfig.unit"
                options={dateUnits}
                style={{ maxWidth: "120px" }}
                margin="none"
              />
              {calculatedDates?.reminderDate ? (
                <FormItemHint>
                  {t("pages.tasks.modal.hints.calculatedDate", {
                    date: calculatedDates?.reminderDate,
                  })}
                </FormItemHint>
              ) : null}
              <span>{t("pages.tasks.modal.hints.beforeDueDate")}</span>
              <IconButton
                className="delete"
                onClick={() => toggleReminder(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </FormItem>
          </>
        ) : (
          <CTAButton
            variant="tertiary"
            name={t("common.buttons.addReminder")}
            onClick={() => toggleReminder(true)}
          />
        )}
      </Row>
    </Container>
  );
};

export default FixedDueDateComponent;
