/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ContractViewItemDto, ContractViewService } from "openapi";
import { useQuery } from "@tanstack/react-query";

export const useContractViewsQuery = (
  teamId: string | undefined,
  type: ContractViewItemDto.type = ContractViewItemDto.type.CONTRACT
) => {
  return useQuery({
    queryKey: ["views", teamId, type],
    queryFn: async () => {
      const views = await ContractViewService.getAll(teamId!);
      views.items = views.items.filter(
        (view) => !view.type || view.type === type
      );
      return views;
    },
    enabled: !!teamId,
  });
};
