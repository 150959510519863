import { Tooltip } from "@mui/material";
import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";

type OverflowTipProps = {
  title: string;
  children: React.ReactElement;
};

export const OverflowTip: FC<PropsWithChildren<OverflowTipProps>> = ({
  title,
  children,
}) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLSpanElement>(null);
  const textElementStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  useEffect(() => {
    if (!textElementRef.current) return;

    const onResize = () => {
      if (!textElementRef.current) return;
      setIsOverflow(
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth
      );
    };

    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return (
    <Tooltip
      arrow
      title={title}
      disableHoverListener={!isOverflowed}
      disableInteractive
    >
      {React.cloneElement(children, {
        ref: textElementRef,
        style: textElementStyle,
      })}
    </Tooltip>
  );
};
