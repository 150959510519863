import { TFunction } from "i18next";
import {
  IFieldOption,
  IValidator,
} from "@flatfile/adapter/build/main/interfaces";
import {
  ContractFieldDTOV1,
  ListFieldTypeDtoV1,
  ListFieldItem,
  ContactDatapointDefinitionDTO,
  DurationFieldTypeDtoV1,
  TeamDescriptionDto,
  ContractDTOV1,
  CategoryDTO,
} from "openapi";
import { Language } from "shared/i18n/i18n";
import {
  getCountriesOptions,
  initCountries,
} from "utils/internationalizations";

import type = ContractFieldDTOV1.type;

export const createSelectField = (
  key: string,
  label: string,
  options: IFieldOption[],
  validators: IValidator[] = [],
  customType?: ContractFieldDTOV1.type
) => {
  return {
    type: "enum",
    key,
    label,
    config: {
      options,
    },
    validators,
    metadata: {
      customType,
    },
  };
};

export const createTextField = (
  key: string,
  label: string,
  constraints?: unknown,
  customType?: ContractFieldDTOV1.type | string
) => {
  return {
    key,
    label,
    type: "string",
    constraints: constraints || [],
    metadata: {
      customType,
    },
  };
};

export const createDateField = (
  key: string,
  label: string,
  userDateFormat: string,
  customType?: ContractFieldDTOV1.type,
  format?: string
) => {
  return {
    key,
    label,
    type: "date",
    metadata: {
      format: userDateFormat,
    },
  };
};

export const createStatusField = (
  key: string,
  enumLike: Record<string, unknown>,
  t: TFunction<"translation">,
  customType?: ContractFieldDTOV1.type
) => {
  const options = Object.keys(enumLike).map<IFieldOption>((option) => {
    return {
      value: option,
      label: t(`pages.import.labels.${key}Options.` + option),
    };
  });

  const filteredOptions = options.filter(
    (item) =>
      item.value !== ContractDTOV1.status.SIGNING &&
      item.value !== ContractDTOV1.status.PROCESSING
  );

  return createSelectField(
    key,
    t(`pages.import.labels.${key}`),
    filteredOptions,
    [],
    customType
  );
};

export const createEnumField = (
  key: string,
  enumLike: Record<string, unknown>,
  t: TFunction<"translation">
) => {
  const options = Object.keys(enumLike).map<IFieldOption>((option) => {
    return {
      value: option,
      label: t(`pages.import.labels.${key}Options.` + option),
    };
  });

  return createSelectField(key, t(`pages.import.labels.${key}`), options);
};

export const createCategoryField = (
  categories: CategoryDTO[],
  t: TFunction<"translation">,
  locale: Language
) => {
  const options = categories.map((category) => {
    const label =
      typeof category.name === "string" ? category.name : category.name[locale];
    return {
      value: category.id,
      label: label,
    };
  });

  return createSelectField(
    "categoryId",
    t("pages.import.labels.categoryId"),
    options as IFieldOption[],
    [],
    ContractFieldDTOV1.type.LIST
  );
};

export const createListField = (
  field: ContractFieldDTOV1,
  items: ListFieldItem[],
  t: TFunction<"translation">,
  locale: Language,
  validators: IValidator[] = []
) => {
  const translationPrefix =
    field.visibleId && field.oldStandardField
      ? `enums.${field.visibleId}`
      : // @Note: Shall we add a validation here so we can show any errors for this field?
        undefined;
  const options = items.map((item) => {
    return {
      value: item.id,
      label: translationPrefix
        ? t(`${translationPrefix}.${item.value}`)
        : item.value,
    };
  });
  return createSelectField(
    field.id,
    field.name[locale],
    options,
    validators,
    ContractFieldDTOV1.type.LIST
  );
};

export const createCountryField = (
  field: ContractFieldDTOV1 | ContactDatapointDefinitionDTO,
  t: TFunction<"translation">,
  locale: Language,
  validators: IValidator[] = []
) => {
  void initCountries(locale);
  const countryList = getCountriesOptions(locale);
  const options = countryList.map((item) => {
    return {
      value: item.key,
      label: item.value,
    };
  });
  return createSelectField(
    field.id,
    field.name[locale],
    options,
    validators,
    ContractFieldDTOV1.type.COUNTRY
  );
};

export const createDurationField = (
  key: string,
  label: string,
  t: TFunction<"translation">,
  userDateFormat: string
) => {
  return [
    {
      // @Note: Do we need this ? Or shall we just use the `createSelectField` functions?
      ...createEnumField("durationType", DurationFieldTypeDtoV1.type, t),
      key: `${key}.type`,
      label: `${label} - ${t("pages.import.labels.duration.type")}`,
    },
    createDateField(
      `${key}.startDate`,
      `${label} - ${t("pages.import.labels.duration.startDate")}`,
      userDateFormat
    ),
    createDateField(
      `${key}.endDate`,
      `${label} - ${t("pages.import.labels.duration.endDate")}`,
      userDateFormat
    ),
    createTextField(
      `${key}.interval`,
      `${label} - ${t("pages.import.labels.duration.interval")}`
    ),
    createDateField(
      `${key}.terminationDate`,
      `${label} - ${t("pages.import.labels.duration.terminationDate")}`,
      userDateFormat
    ),
    createTextField(
      `${key}.noticePeriod`,
      `${label} - ${t("pages.import.labels.duration.noticePeriod")}`
    ),
    createTextField(
      `${key}.automaticRenewal`,
      `${label} - ${t("pages.import.labels.duration.automaticRenewal")}`
    ),
  ];
};

/*
export const createCustomContactFields = (
  customFields: ContactDatapointDefinitionDTO[],
  locale: Language,
  t: TFunction<"translation">
) => {
  return customFields.map((fieldData) => {
    switch (fieldData.type) {
      case type.COUNTRY: {
        return createCountryField(fieldData, t, locale);
      }
      default:
        return createTextField(fieldData.id, fieldData.name[locale]);
    }
  });
};
*/

export const createCustomContactFields = (
  customFields: ContactDatapointDefinitionDTO[],
  locale: Language,
  t: TFunction<"translation">
) => {
  const contactNameDatapoint = customFields.find(
    (item) => item.visibleId === "name"
  );
  if (!contactNameDatapoint) {
    return [];
  }

  return [
    createTextField("counterPartyId", t("common.headers.counterParty.id")),
    createTextField(
      contactNameDatapoint.id,
      t("common.headers.counterParty.name")
    ),
  ];
};

export const createCustomFields = (
  customFields: ContractFieldDTOV1[],
  locale: Language,
  t: TFunction<"translation">,
  userDateFormat: string
) => {
  return customFields.map((fieldData) => {
    switch (fieldData.type) {
      case type.LIST: {
        const items = (fieldData.data as ListFieldTypeDtoV1)?.items;
        return createListField(fieldData, items, t, locale);
      }
      case type.COUNTRY: {
        return createCountryField(fieldData, t, locale);
      }
      case type.DURATION: {
        return createDurationField(
          fieldData.id,
          fieldData.name[locale],
          t,
          userDateFormat
        );
      }
      default:
        return createTextField(fieldData.id, fieldData.name[locale]);
    }
  });
};

export const createTeamField = (
  teams: TeamDescriptionDto[],
  key: keyof ContractDTOV1,
  t: TFunction<"translation">
) => {
  const options = teams.map<IFieldOption>((team) => {
    return {
      value: team.id,
      label: team.name,
    };
  });
  return createSelectField(key, t("pages.import.labels.teamId"), options);
};

export const getCurrentTeams = (
  teams: TeamDescriptionDto[],
  teamId: string
) => {
  const parentTeam = teams.find(
    (item) =>
      item.id === teamId || item.children.find((item) => item.id === teamId)
  );
  return parentTeam ? [parentTeam, ...parentTeam.children] : [];
};
