import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const PDFContent = styled.object`
  height: calc(100% - 4.5rem);
  width: 100%;
  border: none;
  margin-top: 0.5rem;
`;

export const ModalTitleWrapper = styled.h3`
  line-height: 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: #1a202c;
  margin: 0;
  margin-bottom: 0.5rem;
`;

export const ModalRoundedBox = styled(Box)`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: #fff5f5;
`;

export const ContractWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
`;
