import React from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import WarningIcon from "@mui/icons-material/Warning";
import { ApiError, OrganizationService } from "openapi";
import { NewModal, CTAButton } from "components";
import { theme } from "theme";

export type DeleteModalConfirmationsProps = {
  currentOrganizationId: string;
  roleId: string;
  open: boolean;
  handleClose: () => void;
  fetchRoles: () => void;
};

const DeleteModalConfirmations = ({
  currentOrganizationId,
  roleId,
  open,
  handleClose,
  fetchRoles,
}: DeleteModalConfirmationsProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteRole = async () => {
    try {
      await OrganizationService.deleteRole(roleId, currentOrganizationId);
      void fetchRoles();

      enqueueSnackbar(
        t("pages.settings.tabs.team.teamRoles.modals.delete.successMessage"),
        {
          variant: "success",
        }
      );
    } catch (e) {
      const errorBody = (e as ApiError).body as { message: string };
      if (
        errorBody.message === "Cannot delete role that is assigned to members"
      ) {
        enqueueSnackbar(
          t(
            "pages.settings.tabs.team.teamRoles.modals.delete.errorMessageHasMembers"
          ),
          {
            variant: "error",
          }
        );
        return;
      }

      enqueueSnackbar(
        t("pages.settings.tabs.team.teamRoles.modals.delete.errorMessage"),
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <NewModal
      data-testid="delete-role-modal"
      fullWidth
      open={open}
      handleClose={handleClose}
      title={t("pages.settings.tabs.team.teamRoles.modals.delete.title")}
      icon={<WarningIcon sx={{ fill: theme.color.red[600] }} />}
      body={
        <>{t("pages.settings.tabs.team.teamRoles.modals.delete.description")}</>
      }
      footer={
        <>
          <CTAButton
            name={t("common.buttons.cancel")}
            variant="secondary"
            onClick={handleClose}
          />
          <CTAButton
            color="red"
            name={t("common.buttons.delete")}
            onClick={() => {
              void handleDeleteRole();
              handleClose();
            }}
          />
        </>
      }
    />
  );
};

export default DeleteModalConfirmations;
