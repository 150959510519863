import styled from "@emotion/styled";
import theme from "theme/theme";

export const Container = styled.div`
  display: flex;
`;

const commonStyles = `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${theme.spacing.md};
`;

export const Sections = styled.div`
  ${commonStyles}
  flex-direction: column;
  flex: 50%;

  h4 {
    margin: 0 0 ${theme.spacing.lg};
  }

  :hover {
    cursor: grab;
  }

  :active {
    cursor: grabbing;
  }
`;

export const Field = styled.div`
  padding: 0.5rem;
  border: 2px solid #336699;
  border-radius: 0.5rem;
  background-color: white;
  width: fit-content;
`;
