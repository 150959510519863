import React from "react";
import { TextFieldProps } from "@mui/material";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { StyledTextField } from "../../StyledComponents/StyledBaseFields";
import { useTranslation } from "react-i18next";
import { useLocale } from "hooks/GlobalStateHooks";
import {
  ButtonsWrapper,
  FieldWrapper,
  IncrementButton,
  DecrementButton,
} from "./styles";
import ChevronIcon from "assets/svg/chevron-icon.svg?react";

export type FormNumericFieldProps<T extends FieldValues> = Omit<
  TextFieldProps,
  "name"
> & {
  control?: Control<T>;
  name: Path<T>;
  label: string;
  decimalScale?: number | null;
  allowNegative?: boolean;
  fixedDecimalScale?: boolean;
  size?: string;
  inputProps?: {
    suggestion?: boolean;
  };
  testId?: string;
  placeholder?: string;
  showButtons?: boolean;
};
type NumericFieldProps = { [key: string]: unknown; testId?: string };
const NumericField = ({ ...props }: NumericFieldProps) => (
  <StyledTextField
    margin="normal"
    sx={{ width: "100%" }}
    {...props}
    data-testid={props.testId}
  />
);

export function FormNumericField<T extends FieldValues>({
  name,
  control,
  inputProps,
  InputProps,
  testId,
  ...props
}: FormNumericFieldProps<T>): JSX.Element {
  const { t } = useTranslation();
  const { locale } = useLocale();

  const decimalSeparator = locale === "en" ? "." : ",";
  const {
    fixedDecimalScale = true,
    allowNegative,
    decimalScale,
    label,
    size = "small",
    disabled,
    onBlur,
    onFocus,
    placeholder,
    showButtons,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, onBlur: _onBlur },
        fieldState: { invalid, error },
      }) => {
        const handleIncrement = () => {
          onChange(Number(value) + 1);
        };

        const handleDecrement = () => {
          if (Number(value) <= 1) return;
          onChange(Number(value) - 1);
        };

        const helperMessage = error?.message
          ? t(error?.message)
          : t("common.errors.invalidField");

        return (
          <FieldWrapper showButtons={showButtons}>
            <NumericFormat
              testId={testId}
              id={name}
              label={label}
              placeholder={placeholder || label}
              customInput={NumericField}
              decimalSeparator={decimalSeparator}
              decimalScale={decimalScale ?? 2}
              fixedDecimalScale={fixedDecimalScale}
              onFocus={(e) => {
                onFocus?.(e);
              }}
              onBlur={(e) => {
                _onBlur();
                onBlur?.(e);
              }}
              size={size}
              error={invalid}
              helperText={error?.message ? helperMessage : ""}
              value={(value as string) ?? ""}
              onValueChange={(val) => {
                onChange(val.floatValue ?? null);
              }}
              disabled={disabled}
              InputProps={InputProps}
              suggestion={inputProps?.suggestion}
              allowNegative={allowNegative}
            />
            {showButtons ? (
              <ButtonsWrapper>
                <IncrementButton
                  onClick={handleIncrement}
                  variant="tertiary"
                  icon={<ChevronIcon />}
                  iconSize="tiny"
                  label="increment-button"
                  size="tiny"
                />
                <DecrementButton
                  onClick={handleDecrement}
                  variant="tertiary"
                  icon={<ChevronIcon />}
                  iconSize="tiny"
                  label="decrement-button"
                  size="tiny"
                />
              </ButtonsWrapper>
            ) : null}
          </FieldWrapper>
        );
      }}
    />
  );
}
