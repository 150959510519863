import React from "react";
import { useTranslation } from "react-i18next";
import { CTAButton, NewModal } from "components";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { theme } from "theme";
import { useSnackbar } from "notistack";
import { useRemoveMemberFromOrganizationMutation } from "shared/api";

type Props = {
  open: boolean;
  handleClose: () => void;
  resetSelectedMemberIndex: () => void;
  teamMemberFullName: string;
  selectedMemberId?: string;
  selectedTeamId: string;
};

const RemoveTeamMember = ({
  open,
  handleClose,
  teamMemberFullName,
  selectedTeamId,
  selectedMemberId,
  resetSelectedMemberIndex,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: removeMemberFromOrganization } =
    useRemoveMemberFromOrganizationMutation();

  const handleDelete = async () => {
    if (!selectedMemberId) return;

    try {
      await removeMemberFromOrganization({
        memberId: selectedMemberId,
        teamId: selectedTeamId,
      });
      enqueueSnackbar(
        t("pages.settings.tabs.team.teamMembers.modals.delete.successMessage"),
        {
          variant: "success",
        }
      );
    } catch (error) {
      enqueueSnackbar(
        t("pages.settings.tabs.team.teamMembers.modals.delete.errorMessage"),
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <NewModal
      open={open}
      icon={
        <DangerousIcon fontSize="large" sx={{ fill: theme.color.red[600] }} />
      }
      handleClose={handleClose}
      title={t("pages.settings.tabs.team.teamMembers.modals.delete.title", {
        teamMemberName: teamMemberFullName,
      })}
      fullWidth
      body={
        <>
          {t("pages.settings.tabs.team.teamMembers.modals.delete.description")}
        </>
      }
      footer={
        <>
          <CTAButton
            variant="secondary"
            name={t("common.buttons.cancel")}
            onClick={handleClose}
            label="close-delete-member-modal"
          />
          <CTAButton
            color="red"
            name={t(
              "pages.settings.tabs.team.teamMembers.modals.delete.confirmDelete"
            )}
            onClick={() => {
              void handleDelete();
              resetSelectedMemberIndex();
              handleClose();
            }}
            label="delete-member-button"
          />
        </>
      }
    />
  );
};

export default RemoveTeamMember;
