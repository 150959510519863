import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import CardWrapper from "components/CardWrapper/CardWrapper";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import { UserService, NotificationSettingsUpdateDto } from "openapi";
import { useTranslation } from "react-i18next";
import NotificationItem from "./NotificationItem/NotificationItem";
import AllNotifications from "./NotificationItem/AllNotifications";
import {
  convertToArray,
  switchAll,
  checkAllNotificationStatus,
} from "./helpers/helpers";
import { NotificationType } from "./types";
import { Table, Title } from "./styles";

const Notification = () => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm();
  const [data, setData] = useState<NotificationType[]>([]);
  const [rawData, setRawData] = useState<NotificationSettingsUpdateDto>();
  const [isAllEnabled, setIsAllEnabled] = useState<boolean>();

  const toggleAll = () => {
    const allActive = !checkAllNotificationStatus(data);
    const reqBody = switchAll(
      rawData as NotificationSettingsUpdateDto,
      allActive
    );

    void UserService.editUserNotificationSettings(reqBody).then(() =>
      fetchNotifications()
    );
  };

  const fetchNotifications = () => {
    UserService.getUserNotificationSettings()
      .then((response) => {
        setRawData(response);
        setData(convertToArray(response));
      })
      .catch((error) => console.error(error));
  };

  const onSubmit = (reqBody: NotificationSettingsUpdateDto) => {
    UserService.editUserNotificationSettings(reqBody)
      .then(() => fetchNotifications())
      .catch((error) => console.error(error));
  };

  useEffect(() => fetchNotifications(), []);

  useEffect(
    () => setIsAllEnabled(checkAllNotificationStatus(data)),
    [JSON.stringify(data)]
  );

  if (!data.length) return null;

  return (
    <CardWrapper style={{ width: "100%", overflowY: "auto" }}>
      <CardHeaderWrapper
        title={t("pages.settings.tabs.notifications.title")}
        subTitle={t("pages.settings.tabs.notifications.subtitle")}
      />

      <AllNotifications
        control={control}
        enabled={isAllEnabled}
        onChange={() => toggleAll()}
      />

      <Table onSubmit={handleSubmit(onSubmit)}>
        <Title>
          {t(
            "pages.settings.tabs.notifications.configuration.columnTitles.general"
          )}
        </Title>
        <Title>
          {t(
            "pages.settings.tabs.notifications.configuration.columnTitles.email"
          )}
        </Title>
        <Title>
          {t(
            "pages.settings.tabs.notifications.configuration.columnTitles.inApp"
          )}
        </Title>

        {data?.map((notification: NotificationType) => (
          <NotificationItem
            control={control}
            key={notification.key}
            notificationData={notification}
            onSubmit={onSubmit}
          />
        ))}
      </Table>
    </CardWrapper>
  );
};

export default Notification;
