import { ActionPayload } from "contexts/types";
import { TeamInfoDto, TeamPermissionInfoDto, UserInfoDto } from "openapi";

export enum TeamActionType {
  setSelectedTeam,
  setSettingsTab,
  toggleDrawer,
}

export type TeamState = {
  selectedTeam?: TeamInfoDto;
  permissions: Record<string, TeamPermissionInfoDto> | undefined;
  drawerOpenState: boolean;
  status: "idle" | "success" | "error";
  error: string | null;
};

export type TeamAction = {
  type: TeamActionType;
  params?: unknown;
};

export type UserAndTeamInfo = {
  userInfo: UserInfoDto | undefined;
  teamInfo: TeamInfoDto;
};

export type SetSelectedTeamPayload = ActionPayload<UserAndTeamInfo>;
