/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserTeamInfoDto = {
  role: UserTeamInfoDto.role;
  teamName: string;
  subscription: string | null;
};

export namespace UserTeamInfoDto {
  export enum role {
    USER = "USER",
    LIMITED_USER = "LIMITED_USER",
    OWNER = "OWNER",
  }
}
