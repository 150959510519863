import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import { theme } from "theme";

const preventTextWrapperPropForwarding = (propName: string) => {
  return propName !== "suggestion";
};

export const StyledTextField = styled(TextField, {
  shouldForwardProp: preventTextWrapperPropForwarding,
})`
  .MuiInputBase-root {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
    border-width: 1px;
    border-radius: 0.5rem;
    border-color: #a0aec0;
    margin: 0;
    ${({ suggestion }: { suggestion?: boolean }) =>
      suggestion && `color: ${theme.color.orange[600]}`};
  }

  label {
    width: calc(100% - 40px) !important;
  }
`;
