import styled from "@emotion/styled";
import { theme } from "theme";

export const Table = styled.form`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;

  @media screen and (min-width: 600px) {
    grid-template-columns: 8fr 1fr 1fr;
  }
`;

export const Title = styled.div`
  font-weight: ${theme.font.weight.medium};
  margin-bottom: ${theme.spacing.lg};
`;
