import React from "react";
import { StyledButton } from "./styles";

type Props = {
  id?: string;
  children: React.ReactNode | string;
  onClick?: () => void;
  style?: React.CSSProperties;
};

const Button = ({ id, children, onClick, style }: Props) => (
  <StyledButton id={id} type="button" onClick={onClick} style={style}>
    {children}
  </StyledButton>
);

export default Button;
