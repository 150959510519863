import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { theme } from "theme";
import WarningIcon from "@mui/icons-material/Warning";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";

const commonStyles = `
  font-weight: ${theme.font.weight.medium};
  border-radius: ${theme.borderRadius};
  box-shadow: ${theme.shadow.standard};
  padding: ${theme.spacing.sm} ${theme.spacing.md};

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ViewButton = styled(Button)`
  color: ${theme.color.gray[900]};
  text-transform: none;
  border: 1px solid ${theme.color.gray[300]};
  ${commonStyles}
`;

export const ViewsList = styled(OverlayScrollbarsComponent)`
  overflow-y: auto;
  max-height: 280px;
`;

export const ViewsListTitle = styled.p`
  font-weight: ${theme.font.weight.bold};
  font-size: ${theme.font.size.body};
  margin: ${theme.spacing.md} ${theme.spacing.lg} ${theme.spacing.md};
`;

export const ViewWarningIcon = styled(WarningIcon)`
  margin-right: 0.5rem;
  color: ${theme.color.orange[400]};
  svg {
    fill: ${theme.color.orange[400]} !important;
  }
`;

export const BaseDropDownButtonWithIcon = styled(Button)<{
  isSubMenuOpened?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-transform: none;
  color: ${theme.color.gray[700]};
  padding: ${theme.spacing.md} ${theme.spacing.lg};
  gap: ${theme.spacing.sm};
  background-color: ${({ isSubMenuOpened }) =>
    isSubMenuOpened ? `${theme.color.black}08` : "transparent"};
  svg {
    fill: ${theme.color.black}80;
  }
  &:hover {
    background-color: ${theme.color.black}08 !important;
`;

export const ArrowIcon = styled(ArrowRightOutlinedIcon)`
  margin-right: 0 !important;
  margin-left: auto;
`;

export const ViewListHeader = styled.div`
  display: flex;
  alignt-items: center;
  justify-content: flex-start;
  color: ${theme.color.gray[700]};
  flex-wrap: wrap;
  width: 100%;
`;

export const ViewListHeading = styled.div`
  width: 100%;
  font-weight: ${theme.font.weight.bold};
  font-size: ${theme.font.size.caption};
  padding: ${theme.spacing.xxs} ${theme.spacing.lg};
`;

export const CurrentView = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${theme.spacing.xxs} ${theme.spacing.sm} ${theme.spacing.xxs}
    ${theme.spacing.lg};
  min-height: 40px;
`;

export const Separator = styled.hr`
  width: 100%;
  height: 1px;
  margin: ${theme.spacing.sm} 0;
  border: none;
  background-color: ${theme.color.gray[300]};
`;

export const AiIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
