import React, { FC } from "react";
import * as Sentry from "@sentry/react";
import { ContactDatapointDefinitionDTO } from "openapi";
import { DataPointFactoryProps, DataPointProps } from "./types";
import { NumberDatapoint } from "./NumberDatapoint";
import { SingleLineTextDatapoint } from "./SingleLineTextDatapoint";
import { MultiLineTextDatapoint } from "./MultiLineTextDatapoint";
import { AmountDatapoint } from "./AmountDatapoint";
import { CountryDatapoint } from "./CountryDatapoint";
import { DateDatapoint } from "./DateDatapoint";
import { LinkDatapoint } from "./LinkDatapoint";
import { NumberCellEditor } from "../../pages/Contracts/CellEditors/NumberCellEditor";
import { CustomCellEditor } from "../../pages/Contracts/CellEditors/CustomCellEditor";
import { DateCellEditor } from "../../pages/Contracts/CellEditors/DateCellEditor";
import { SelectCellEditor } from "../../pages/Contracts/CellEditors/SelectCellEditor";
// import { FormulaDatapoint } from "./FormulaDatapoint";
// import { ListDatapoint } from "./ListDatapoint";

export const DataPoint: FC<DataPointFactoryProps> = ({
  id,
  definitions,
  contact,
  editable,
}) => {
  const definition = definitions.find((definition) => definition.id === id);
  if (!definition) {
    Sentry.captureMessage(`Couldn't find defintion id: ${id}`);
    return null;
  }

  const datapointsByType: Record<
    ContactDatapointDefinitionDTO.type,
    React.FC<DataPointProps>
  > = {
    [ContactDatapointDefinitionDTO.type.TEXTFIELD]: SingleLineTextDatapoint,
    [ContactDatapointDefinitionDTO.type.TEXTAREA]: MultiLineTextDatapoint,
    [ContactDatapointDefinitionDTO.type.NUMBER]: NumberDatapoint,
    [ContactDatapointDefinitionDTO.type.DATE]: DateDatapoint,
    [ContactDatapointDefinitionDTO.type.AMOUNT]: AmountDatapoint,
    [ContactDatapointDefinitionDTO.type.LINK]: LinkDatapoint,
    [ContactDatapointDefinitionDTO.type.COUNTRY]: CountryDatapoint,
    [ContactDatapointDefinitionDTO.type.LIST]: SingleLineTextDatapoint, // TODO
    [ContactDatapointDefinitionDTO.type.FORMULA]: SingleLineTextDatapoint, // TODO
  };

  const object = datapointsByType[definition.type];
  return React.createElement(object, {
    definition: definition,
    definitions: definitions,
    contact: contact,
    editable: editable,
  });
};

export const CellEditors: Record<ContactDatapointDefinitionDTO.type, unknown> =
  {
    TEXTFIELD: CustomCellEditor,
    TEXTAREA: CustomCellEditor,
    NUMBER: NumberCellEditor,
    LINK: CustomCellEditor,
    DATE: DateCellEditor,
    AMOUNT: NumberCellEditor,
    COUNTRY: SelectCellEditor,
    LIST: SelectCellEditor,
    FORMULA: CustomCellEditor,
  };
