import React, { useState, useRef, RefObject } from "react";
import { useTranslation } from "react-i18next";
import { useTeam } from "contexts/team/hooks";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { GridReadyEvent, GridApi, ColDef } from "ag-grid-community";
import { enqueueSnackbar } from "notistack";
import {
  OrganizationNewService,
  AssignCategoryToTeamDTO,
  ApiError,
} from "openapi";
import { Loader, SectionHeader } from "components";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { useOrganizationAllTeamsQuery } from "shared/api/organization";
import { useOrganizationCategoriesQuery } from "shared/api";
import { useLocale } from "hooks";
import CreateTeamIcon from "assets/svg/create-team-icon.svg?react";
import TeamIcon from "assets/svg/team-icon.svg?react";
import { Header, Matrix as MatrixTable, CreateTeamModal } from "./components";
import {
  Wrapper,
  SectionHeaderContainer,
  HeaderIconStyles,
  SectionDescription,
} from "./styles";
import { getErrorKey } from "utils/helpers";

export type ViewMode = "team" | "category";

export type AccessInfoType = {
  teamId: string;
  categoryId: string;
  access: boolean;
};

export type CategoryOptionType = {
  id: string;
  name: string;
  color: string;
};

export type TeamOptionType = {
  id: string;
  name: string;
};

const Teams = () => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSaveButtonVisible, setIsSaveButtonVisible] =
    useState<boolean>(false);
  const { organizationId } = useTeam();
  const {
    data: teamsData,
    isLoading,
    refetch: refetchTeams,
  } = useOrganizationAllTeamsQuery(organizationId);
  const { data: categoriesData, refetch: refetchCategories } =
    useOrganizationCategoriesQuery(organizationId);
  const [viewMode, setViewMode] = useState<ViewMode>("team");
  const [accessInfo, setAccessInfo] = useState<AccessInfoType[]>([]);
  const [filteredRowsIds, setFilteredRowsIds] = useState(new Set<string>());
  const [filteredColumnIds, setFilteredColumnIds] = useState<string[]>([]);

  const [gridApi, setGridApi] = useState<GridApi>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const gridRef = useRef(null) as unknown as RefObject<AgGridReact>;

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const onColumnsFilter = (fieldId: string) => {
    if (!columnDefs?.length) return;

    const newFilteredColumnIds = filteredColumnIds?.includes(fieldId)
      ? filteredColumnIds.filter((id) => id !== fieldId)
      : [...(filteredColumnIds || []), fieldId];

    setFilteredColumnIds(newFilteredColumnIds);

    const updatedColumnDefs = columnDefs.map((column) => {
      // The 'name' column remains always visible
      if (column.field === "name") return { ...column, hide: false };

      return {
        ...column,
        hide: !newFilteredColumnIds.includes(column.field as string),
      };
    });

    const columnsWithoutNameColumn = updatedColumnDefs.filter(
      (col) => col.field !== "name"
    );

    if (columnsWithoutNameColumn.every((col) => col.hide)) {
      updatedColumnDefs.forEach((col) => {
        col.hide = false;
      });
    }
    setColumnDefs(updatedColumnDefs);
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    gridApi?.setQuickFilter(event.target.value);
  };

  const handleViewModeChange = () => {
    setAccessInfo([]);

    setIsSaveButtonVisible(false);

    setViewMode((prev) => (prev === "team" ? "category" : "team"));
  };

  const refetchAll = () => {
    void refetchTeams();
    void refetchCategories();
  };

  const onSave = async () => {
    const grandCategoryAccessOptions = accessInfo.filter(
      (option) => option.access && option
    );
    const removeGrandCategoryAccessOptions = accessInfo.filter(
      (option) => !option.access && option
    );

    const handleSuccess = () => {
      enqueueSnackbar(
        t(
          "pages.settings.organization.teams.notifications.grantAccess.success"
        ),
        {
          variant: "success",
        }
      );
    };

    const handleError = (error: unknown) => {
      if (!(error instanceof ApiError)) return;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const errorType = getErrorKey(error?.body?.type as string);
      const errorMessage = t(
        `pages.settings.organization.teams.notifications.removeAccess.error.${errorType}`
      );

      enqueueSnackbar(errorMessage, { variant: "error" });
    };

    try {
      await Promise.all([
        ...grandCategoryAccessOptions.map((data) => {
          return OrganizationNewService.assignTeamToCategory(
            organizationId,
            data.teamId,
            { categoryId: data.categoryId } as AssignCategoryToTeamDTO
          );
        }),
        ...removeGrandCategoryAccessOptions.map((data) => {
          return OrganizationNewService.removeTeamFromCategory(
            organizationId,
            data.teamId,
            data.categoryId
          );
        }),
      ]);
      handleSuccess();
    } catch (error) {
      handleError(error);
    } finally {
      void refetchAll();
      setIsSaveButtonVisible(false);
    }
  };

  const closeModal = () => setIsModalOpen(false);

  const categories = categoriesData?.map((cat) => ({
    id: cat.id,
    name: cat.name[locale],
    color: cat.color,
  }));

  if (isLoading) return <Loader />;

  return (
    <>
      <Wrapper>
        <Header
          gridRef={gridRef}
          viewMode={viewMode}
          handleViewModeChange={handleViewModeChange}
          handleOnSearch={onSearch}
          handleOnSave={onSave}
          categories={categories as CategoryOptionType[]}
          teams={teamsData as TeamOptionType[]}
          isSaveButtonVisible={isSaveButtonVisible}
          setFilteredRowsIds={setFilteredRowsIds}
          setFilteredColumnIds={setFilteredColumnIds}
          onColumnsFilter={onColumnsFilter}
        />

        <CardWrapper>
          <SectionHeaderContainer>
            <SectionHeader
              title={t(
                "pages.settings.tabs.subTeams.section.showSubTeams.title"
              )}
              icon={<TeamIcon />}
              iconStyle={HeaderIconStyles}
              noPadding
              buttonVariant="secondary"
              baseButtonText={t(
                "pages.settings.tabs.team.teamNameForm.common.buttons.create"
              )}
              buttonId="create team"
              onButtonClick={() => setIsModalOpen(true)}
              buttonIcon={<CreateTeamIcon />}
            />
            <SectionDescription>
              {viewMode === "team"
                ? t(
                    "pages.settings.tabs.subTeams.section.showSubTeams.description.teams"
                  )
                : t(
                    "pages.settings.tabs.subTeams.section.showSubTeams.description.categories"
                  )}
            </SectionDescription>
            <CreateTeamModal open={isModalOpen} onClose={closeModal} />
          </SectionHeaderContainer>

          {categories?.length && teamsData?.length && (
            <MatrixTable
              gridRef={gridRef as unknown as RefObject<AgGridReact>}
              gridApi={gridApi}
              onGridReady={onGridReady}
              columnDefs={columnDefs}
              setColumnDefs={setColumnDefs}
              viewMode={viewMode}
              categories={categories}
              teamsData={teamsData}
              accessInfo={accessInfo}
              filteredRowsIds={filteredRowsIds}
              setAccessInfo={setAccessInfo}
              setIsDirty={setIsSaveButtonVisible}
            />
          )}
        </CardWrapper>
      </Wrapper>
    </>
  );
};

export default Teams;
