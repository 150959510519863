/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ApiError, AuthService, LimitedAuthMethodsDTO } from "openapi";
import { useQuery } from "@tanstack/react-query";

export const useAuthMethodsQuery = (hostname: string) => {
  return useQuery<LimitedAuthMethodsDTO, ApiError>({
    queryKey: ["methods", hostname],
    queryFn: async (): Promise<LimitedAuthMethodsDTO> => {
      if (!hostname) {
        return {
          password: true,
          google: true,
          microsoft: true,
          oidc: null,
        };
      }
      return AuthService.getAuthMethodsForTeam(hostname);
    },
    retry: false,
  });
};
