import CircularLoading from "components/CircularLoading/CircularLoading";
import { parseState } from "pages/LoginPage/components/providers";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const OAuthCallback = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const opener = window.opener as Window | null;
    if (opener && opener !== window) {
      const target = new URL(window.location.href);

      const state = searchParams.get("state");
      if (state) {
        const parsedState = parseState(state);
        if (
          parsedState.tenant &&
          !target.hostname.startsWith(parsedState.tenant)
        ) {
          target.hostname = `${parsedState.tenant}.${target.hostname}`;
        }
      }

      opener.postMessage(
        JSON.stringify({
          status: "success",
          code: searchParams.get("code"),
          state: searchParams.get("state"),
          redirect: window.location.href.split("?")[0],
        }),
        target.origin
      );
      window.close();
    }
  }, []);
  return <CircularLoading isLoading />;
};

export default OAuthCallback;
