import React from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import {
  ContentEditable,
  EditorContainer,
  PlaceholderElement,
} from "../styles";

import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { Toolbar } from "./Toolbar";

import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { LinkNode } from "@lexical/link";
import {
  DOMConversion,
  EditorState,
  ElementFormatType,
  ElementNode,
  TextNode,
  LexicalEditor,
} from "lexical";
import { Box } from "@mui/material";
import { theme } from "theme";
import { ImportPlugin } from "./plugins/ImportPlugin";
import { ImageNode } from "./nodes/ImageNode";
import { ImagePlugin } from "./plugins/ImagePlugin";
import { ExtendedTextNode } from "./nodes/ExtendedTextNode";
import { patchStyleTransformation } from "./utils";
import { useTranslation } from "react-i18next";
import "../editor-theme.css";

patchStyleTransformation(ListNode);

const patchStyle = (
  originalDOMConverter: (node: HTMLElement) => DOMConversion | null
): ((node: HTMLElement) => DOMConversion | null) => {
  return (node) => {
    const original = originalDOMConverter?.(node);
    if (!original) {
      return null;
    }

    const originalConversion = original.conversion;
    original.conversion = (element) => {
      const output = originalConversion(element);
      if (!output) {
        return output;
      }
      if (node.style) {
        const outputElementNode = output.node as ElementNode;
        outputElementNode.setFormat(
          element.style.textAlign as ElementFormatType
        );
        const indent = parseInt(element.style.textIndent, 10) / 20;
        if (indent > 0) {
          outputElementNode.setIndent(indent);
        }
      }
      return output;
    };

    return original;
  };
};

const originalHeadingImportDOM = HeadingNode.importDOM();
HeadingNode.importDOM = () => {
  if (!originalHeadingImportDOM) {
    return originalHeadingImportDOM;
  }
  const moddedDOMConverter = { ...originalHeadingImportDOM };
  for (const key of Object.keys(originalHeadingImportDOM)) {
    moddedDOMConverter[key] = patchStyle(moddedDOMConverter[key]);
  }
  return moddedDOMConverter;
};

const Placeholder = () => {
  const { t } = useTranslation();
  return (
    <PlaceholderElement>
      {t("pages.settings.tabs.contractTemplates.editor.placeholder")}
    </PlaceholderElement>
  );
};

type TextEditorProps = {
  initialHTML?: string;
  importHTML?: string;
  onChange(editorState: EditorState, editor: LexicalEditor): void;
};

export const TextEditor = ({
  onChange,
  initialHTML,
  importHTML,
}: TextEditorProps) => {
  return (
    <LexicalComposer
      initialConfig={{
        theme: {
          text: {
            bold: "editor-text-bold",
            italic: "editor-text-italic",
            underline: "editor-text-underline",
            strikethrough: "editor-text-strikethrough",
            underlineStrikethrough: "editor-text-underlineStrikethrough",
          },
          image: "editor-image",
          table: "editor-table",
          tableRow: "editor-table-row",
          tableCell: "editor-table-cell",
          list: {
            olDepth: [
              "editor-list-ol-depth-0",
              "editor-list-ol-depth-1",
              "editor-list-ol-depth-2",
              "editor-list-ol-depth-3",
              "editor-list-ol-depth-4",
            ],
            nested: {
              listitem: "editor-list-nested-listitem",
            },
          },
        },
        namespace: "TextEditor",
        onError(error, editor) {
          console.log(error);
        },
        nodes: [
          HeadingNode,
          ListNode,
          ListItemNode,
          QuoteNode,
          TableNode,
          TableCellNode,
          TableRowNode,
          LinkNode,
          ImageNode,
          ExtendedTextNode,
          {
            replace: TextNode,
            with: (node: TextNode) =>
              new ExtendedTextNode(node.__text, node.__key),
          },
        ],
      }}
    >
      <EditorContainer>
        <Toolbar />
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            border: `1px solid ${theme.color.gray[400]}`,
            borderBottomLeftRadius: "0.5rem",
            borderBottomRightRadius: "0.5rem",
            borderTopWidth: 0,
          }}
          className="editor-inner"
        >
          <RichTextPlugin
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
            contentEditable={<ContentEditable />}
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <LinkPlugin />
          <ListPlugin />
          <ImagePlugin />
          <TabIndentationPlugin />
          <ImportPlugin mode="replace" templateData={initialHTML} />
          <ImportPlugin mode="append" templateData={importHTML} />
          <OnChangePlugin onChange={onChange} />
        </Box>
      </EditorContainer>
    </LexicalComposer>
  );
};
