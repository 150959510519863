import styled from "@emotion/styled";
import { theme } from "theme";

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
`;

export const MarkAsDoneButton = styled.button<{
  done?: boolean;
}>`
  margin-top: ${theme.spacing.sm};
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  svg {
    #circle {
      stroke: ${(props) => (props.done ? "none" : theme.color.gray[500])};
      fill: ${(props) => (props.done ? theme.color.green[500] : "none")};
    }
    #check {
      fill: ${(props) =>
        props.done ? theme.color.white : theme.color.gray[500]};
    }
  }
  &:hover {
    svg {
      #circle {
        stroke: ${theme.color.blue[600]};
      }
      #check {
        fill: ${theme.color.blue[600]};
      }
    }
  }
  pointer-events: ${(props) => (props.done ? "none" : "auto")};
`;
