import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import CardWrapper from "components/CardWrapper/CardWrapper";
import {
  TitleWrapper,
  SubTitleWrapper,
} from "components/CardHeaderWrapper/styles";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import { CreateTeam } from "containers";
import { useTeam } from "contexts/team/hooks";
import ProfileForm from "./ProfileForm";
import SecurityForm from "./SecurityForm";
import { FederatedIdentityConnections } from "./Connections";
import { Box, Fade } from "@mui/material";
import { CardTitle } from "components";
import { theme } from "theme";
import { ReviewSection } from "./ReviewSection";
import { Switch } from "new-components";
import PublicApiSection from "./PublicApiSection/PublicApiSection";

const Profile = () => {
  const { t } = useTranslation();
  const { selectedTeam } = useTeam();
  const [checked, setChecked] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: theme.spacing.sm,
      }}
    >
      <CardWrapper>
        <>
          <CardHeaderWrapper
            title={t("pages.settings.tabs.profile.title")}
            subTitle={t("pages.settings.tabs.profile.subtitle")}
          />
          <ProfileForm />
        </>
      </CardWrapper>

      <CardWrapper>
        <div
          style={{
            maxWidth: "36rem",
          }}
        >
          <CardHeaderWrapper
            title={t("pages.settings.tabs.security.title")}
            subTitle={t("pages.settings.tabs.security.subtitle")}
          />

          <SecurityForm />

          <TitleWrapper style={{ marginTop: "2.5rem" }}>
            {t("pages.settings.tabs.security.identities.title")}
          </TitleWrapper>

          <SubTitleWrapper>
            {t("pages.settings.tabs.security.identities.subTitle")}
          </SubTitleWrapper>

          <FederatedIdentityConnections />
        </div>
      </CardWrapper>

      <CardWrapper>
        <ReviewSection />
      </CardWrapper>

      {!selectedTeam?.isSubTeam && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: theme.spacing.md,
          }}
        >
          <Switch
            checked={checked}
            onChange={() => setChecked(!checked)}
            label={t("pages.settings.tabs.profile.section.createTeam.toggle")}
          />
          <Fade in={checked}>
            <Box>
              <CardWrapper>
                <div style={{ maxWidth: "36rem" }}>
                  <CreateTeam
                    variant="parent"
                    header={
                      <>
                        <CardTitle>
                          {t(
                            "pages.settings.tabs.profile.section.createTeam.title"
                          )}
                        </CardTitle>
                        <span>
                          <Trans i18nKey="pages.settings.tabs.profile.section.createTeam.subTitle" />
                        </span>
                      </>
                    }
                  />
                </div>
              </CardWrapper>
              <PublicApiSection />
            </Box>
          </Fade>
        </Box>
      )}
    </div>
  );
};

export default Profile;
