import styled from "@emotion/styled";
import theme from "theme/theme";
import { ContentEditable as LexicalContentEditable } from "@lexical/react/LexicalContentEditable";

export const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-height: 100%;
  color: ${theme.color.black};
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const ContentEditable = styled(LexicalContentEditable)`
  resize: none;
  font-size: 15px;
  caret-color: ${theme.color.black};
  tab-size: 1;
  outline: 0;
  padding: 15px 10px;
`;

export const PlaceholderElement = styled.div`
  color: ${theme.color.gray[800]};
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  bottom: 3.25em;
  left: 0.75em;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
`;

export const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid ${theme.color.gray[400]};
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.5em 0;
  overflow: auto;
  min-height: 57px;
`;

export const ContractTemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 3.5em 0;
`;
