import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import GridOnIcon from "@mui/icons-material/GridOn";
import { useOverviewQuery } from "shared/api/overview";
import { useOrganizationCategoriesQuery, useTasksQuery } from "shared/api";
import { useTeam } from "contexts/team/hooks";
import { useGuideModal, useUserInfo } from "hooks/GlobalStateHooks";
import BaseBodyWrapper from "components/BaseBodyWrapper/BaseBodyWrapper";
import { FormSelect } from "components/FormItems/FormSelect/FormSelect";
import { GuideModal } from "containers";
import * as Widgets from "./Widgets";
import { Features } from "../../constants";
import NewModal from "components/Modal/NewModal";
import ImportStatusModal from "components/FlatFileImporter/client/components/ImportStatusModal/ImportStatusModal";
import { CategoryDTO, ImportDataDTO } from "openapi";

export enum StatisticsTypeSelectKey {
  SHOW_TOTAL = "SHOW_TOTAL",
  SHOW_SELECTED_ONLY = "SHOW_SELECTED_ONLY",
}

const Dashboard = () => {
  const { t } = useTranslation();
  const { selectedTeamId, organizationId, hasFeature } = useTeam();
  const { teamById } = useUserInfo();
  const { showGuideModal, setShowGuideModal } = useGuideModal();
  const {
    data: overviewData,
    refetch: refetchOverviewData,
    isLoading,
  } = useOverviewQuery(selectedTeamId);
  const { refetch: refetchTasks } = useTasksQuery(selectedTeamId);
  const [showCSVImportStatus, setShowCSVImportStatus] = useState(false);
  const [importStatus, setImportStatus] = useState<ImportDataDTO>();

  const { data: categories } = useOrganizationCategoriesQuery(organizationId);

  const { control } = useForm({
    defaultValues: {
      statisticsType: StatisticsTypeSelectKey.SHOW_TOTAL,
    },
  });

  const statisticsTypeWatch = useWatch({
    name: "statisticsType",
    control: control,
  });

  const selectedData =
    statisticsTypeWatch === StatisticsTypeSelectKey.SHOW_TOTAL
      ? overviewData?.sumData
      : overviewData?.data;

  const multiTeamMode = Boolean(teamById(selectedTeamId)?.children.length);

  const handleOnCSVData = (status: ImportDataDTO) => {
    setShowGuideModal(false);
    setShowCSVImportStatus(true);
    setImportStatus(status);
  };

  const overviewCategoriesData = useMemo(() => {
    if (!selectedData || !categories) {
      return [];
    }

    return selectedData.categories.map((data) => {
      const newCategory = categories.find((c) => c.id === data.category.id);

      return {
        ...data,
        category: newCategory as CategoryDTO,
      };
    });
  }, [selectedData, categories]);

  return (
    <>
      <BaseBodyWrapper
        actions={
          <>
            {multiTeamMode && (
              <FormSelect
                name="statisticsType"
                options={Object.keys(StatisticsTypeSelectKey)}
                translationPrefix={
                  "pages.dashboard.statisticsTypeSelectOptions"
                }
                control={control}
                margin="none"
              />
            )}
          </>
        }
      >
        <Box sx={{ padding: "1rem 0" }}>
          <Grid container spacing={1.25}>
            <Grid item sm={12} md={6}>
              <Widgets.Tasks displayMode={statisticsTypeWatch} />
              <Widgets.Recent data={selectedData?.recentlyAdded} />
              <Widgets.Activity data={selectedData?.notifications} />
              {hasFeature(Features.ESIGNATURE) && (
                <Widgets.Signature data={selectedData?.signatures} />
              )}
            </Grid>
            <Grid item sm={12} md={6}>
              <Widgets.Statistics data={selectedData} isLoading={isLoading} />
              <Widgets.Categories data={overviewCategoriesData} />
            </Grid>
          </Grid>
        </Box>
      </BaseBodyWrapper>

      <GuideModal
        fetchData={async () => {
          await refetchOverviewData();
          await refetchTasks();
        }}
        open={showGuideModal}
        handleClose={() => setShowGuideModal(false)}
        handleOnCSVData={handleOnCSVData}
      />

      <NewModal
        open={showCSVImportStatus}
        handleClose={() => setShowCSVImportStatus(false)}
        title={t("pages.import.importModal.importTitle")}
        icon={<GridOnIcon />}
        body={
          importStatus ? <ImportStatusModal importData={importStatus} /> : <></>
        }
        fullWidth
      />
    </>
  );
};

export default Dashboard;
