import styled from "@emotion/styled";

export const Wrapper = styled.div<{ link?: boolean }>`
  ${({ link }) =>
    link &&
    `
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  `}
`;
