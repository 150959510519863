import styled from "@emotion/styled";
import { theme } from "theme";
import { breakpoints } from "../../constants";

type Props = {
  fullWidth?: boolean;
};

export const Wrapper = styled.div<Props>`
  width: auto;
  height: 100%;
  padding: ${theme.spacing.lg};
  overflow: auto;
  transform: translate3d(0, 0, 0);

  @media (min-width: ${breakpoints.sm}px) {
    margin: 0 auto;
    width: ${breakpoints.xl}px;
    ${({ fullWidth }) => fullWidth && `width: 100%`};
  }
`;
