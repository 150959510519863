/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationDto } from "../models/NotificationDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class NotificationService {
  /**
   * Get all notifications
   * @returns NotificationDto
   * @throws ApiError
   */
  public static getNotifications(): CancelablePromise<NotificationDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/notification/notifications",
    });
  }

  /**
   * Mark all notifications as read
   * @returns any
   * @throws ApiError
   */
  public static markAllAsRead(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/notification/read",
    });
  }
}
