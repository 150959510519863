import React, { CSSProperties } from "react";
import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const TitleWrapper = styled.h3<{
  colorstyle: string;
}>`
  font-size: 1rem;
  font-weight: 500;
  color: #1a202c;
  margin: 0px;
  line-height: 1.5rem;
  color: ${(prop) => prop.colorstyle};
`;

type Prop = {
  titleStyles?: CSSProperties;
  titleKey?: string;
  baseButtonTextKey: string;
  buttonStyle?: string;
  colorStyle?: string;
  onButtonClick?: () => void;
  icon?: JSX.Element;
  header?: JSX.Element;
  noPadding?: boolean;
  iconStyle?: React.CSSProperties;
  buttonIcon?: JSX.Element | React.ReactNode;
  buttonId?: string;
  hideButton?: boolean;
  buttonDisabled?: boolean;
  padding?: string;
};

const CardHeaderWrapperWithButton = ({
  titleStyles,
  titleKey,
  baseButtonTextKey,
  buttonStyle,
  colorStyle = "none",
  onButtonClick,
  header,
  icon,
  padding = "0 0.75rem 1rem 1.25rem",
  noPadding,
  iconStyle,
  buttonIcon,
  buttonId,
  hideButton,
}: Prop) => {
  const { t } = useTranslation();

  const boxPadding = noPadding ? { padding: "0" } : { padding: padding };

  return (
    <Box sx={boxPadding}>
      <Box className="flex-align-center">
        {icon && (
          <Box
            sx={{
              marginRight: "0.5rem",
              color: colorStyle,
              ...iconStyle,
            }}
          >
            {icon}
          </Box>
        )}
        <Box
          className="flex-align-center"
          sx={{
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <TitleWrapper colorstyle={colorStyle} style={titleStyles}>
            {header ?? `${t(titleKey || "")}`}
          </TitleWrapper>
          {!hideButton ? (
            <Button
              size="small"
              id={buttonId}
              className={buttonStyle}
              onClick={onButtonClick}
            >
              {buttonIcon ? buttonIcon : null}
              {t(baseButtonTextKey)}
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default CardHeaderWrapperWithButton;
