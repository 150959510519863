import React, { useEffect, useState } from "react";
import {
  FormSelect,
  FormSelectItem,
  FormSelectProps,
} from "../FormSelect/FormSelect";
import { useLocale } from "../../../hooks";
import { FieldValues } from "react-hook-form";
import { getCountriesOptions } from "../../../utils/internationalizations";

export type CountrySelectProps<T extends FieldValues> = Omit<
  FormSelectProps<T>,
  "options"
>;

export function CountrySelect<T extends FieldValues>({
  ...rest
}: CountrySelectProps<T>) {
  const { locale } = useLocale();
  const [options, setOptions] = useState<FormSelectItem[]>([]);
  const getCountries = () => {
    setOptions(getCountriesOptions(locale));
  };
  useEffect(() => {
    getCountries();
  }, [locale]);

  return <FormSelect options={options} {...rest} />;
}
