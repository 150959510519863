import { Reducer } from "react";
import * as _ from "./actions";
import {
  TeamState,
  TeamActionType,
  TeamAction,
  SetSelectedTeamPayload,
} from "./types";

export const teamReducer: Reducer<TeamState, TeamAction> = (
  prevState,
  action
) => {
  switch (action.type) {
    case TeamActionType.setSelectedTeam:
      return _.setSelectedTeam(
        prevState,
        action.params as SetSelectedTeamPayload
      );
    case TeamActionType.toggleDrawer:
      return _.toggleDrawer(prevState, action.params as boolean);
    default:
      return prevState;
  }
};
