import React from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { Container, LoadingMessage } from "./styles";

const LoadingComponent = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <LoadingMessage>{t("guide.steps.first.loadingMessage")}</LoadingMessage>
      <CircularProgress size="1rem" />
    </Container>
  );
};

export default LoadingComponent;
