/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TranslationDTO = {
  text: string;
  sourceLanguage: TranslationDTO.sourceLanguage;
  targetLanguage: TranslationDTO.targetLanguage;
};

export namespace TranslationDTO {
  export enum sourceLanguage {
    EN = "en",
    DE = "de",
  }

  export enum targetLanguage {
    EN = "en",
    DE = "de",
  }
}
