import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
const breatheAnimation = keyframes`0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }`;

export const LoaderCubes = styled.div`
  display: block;
  margin: 190px auto;
  position: relative;
  width: 80px;
  height: 80px;
  & div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #3182ce;
    animation-name: ${breatheAnimation};
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
  }
  & div:nth-of-type(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  & div:nth-of-type(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  & div:nth-of-type(3) {
    left: 56px;
    animation-delay: 0;
  }
`;
