import React from "react";
import { AnalysisDataDto } from "openapi";
import AnalyzedPartner from "./AnalyzedPartner";
import styled from "@emotion/styled";
import { parseDuration, parseRepeatingIntervals } from "./helpers";
import { dateFormatter } from "constants/utils";
import { useLocale, useUserInfo } from "hooks/GlobalStateHooks";

type Props = {
  analysisData: AnalysisDataDto;
};
const AnalysisData = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

export const AnalyzeOverview = ({ analysisData }: Props) => {
  const { locale } = useLocale();
  const { userInfo } = useUserInfo();
  const userDateFormat = userInfo?.dateFormat;

  const rawContractTerms = analysisData.terms.raw_contract_terms;
  const standardizedContractTerms =
    analysisData.terms.standardized_contract_terms.contract_terms;
  const nextPossibleCancellation =
    analysisData.terms.next_possible_cancellation;

  return (
    <div>
      <AnalysisData>
        <h2>Category</h2>
        <div>
          {analysisData.type.contract_type} ({analysisData.type.confidence})
        </div>

        <h2>Partners</h2>
        <div></div>
        {analysisData.parties.contract_parties.map((party, idx) => (
          <AnalyzedPartner key={idx} party={party} />
        ))}

        <h2>Raw contract terms</h2>
        <div></div>
        <div>automatic_renewal_period</div>
        <div>{rawContractTerms.automatic_renewal_period.value}</div>
        <div>cancellation_period</div>
        <div>{parseDuration(rawContractTerms.cancellation_period.value)}</div>
        <div>cancellation_effective_calendar_event</div>
        <div>
          {parseRepeatingIntervals(
            rawContractTerms.cancellation_effective_calendar_event.value
          )}
        </div>
        <div>contract_start_date</div>
        <div>
          {dateFormatter(
            locale,
            rawContractTerms.contract_start_date.value,
            userDateFormat
          )}
        </div>
        <div>first_possible_cancellation_date</div>
        <div>
          {dateFormatter(
            locale,
            rawContractTerms.first_possible_cancellation_date.value,
            userDateFormat
          )}
        </div>
        <div>contract_end_date</div>
        <div>
          {dateFormatter(
            locale,
            rawContractTerms.contract_end_date.value,
            userDateFormat
          )}
        </div>
        <div>contract_period</div>
        <div>{parseDuration(rawContractTerms.contract_period.value)}</div>
        <div>is_unlimited</div>
        <div>{rawContractTerms.is_unlimited.value}</div>
        <div>maximum_contract_period</div>
        <div>{rawContractTerms.maximum_contract_period.value}</div>
        <div>minimum_contract_period</div>
        <div>{rawContractTerms.minimum_contract_period.value}</div>

        <h2>Standardized contract terms</h2>
        <div></div>
        <div>cancellation_effective_calendar_event</div>
        <div>
          {parseRepeatingIntervals(
            standardizedContractTerms.cancellation_effective_calendar_event
              .value
          )}
        </div>
        <div>cancellation_period</div>
        <div>
          {parseDuration(standardizedContractTerms.cancellation_period.value)}
        </div>
        <div>contract_start_date</div>
        <div>
          {dateFormatter(
            locale,
            standardizedContractTerms.contract_start_date.value,
            userDateFormat
          )}
        </div>
        <div>contract_end_date</div>
        <div>
          {dateFormatter(
            locale,
            standardizedContractTerms.contract_end_date.value,
            userDateFormat
          )}
        </div>
        <div>earliest_cancellation_effective_date</div>
        <div>
          {dateFormatter(
            locale,
            standardizedContractTerms.earliest_cancellation_effective_date
              .value,
            userDateFormat
          )}
        </div>

        <h2>Next possible cancellation</h2>
        <div></div>
        <div>effective_date</div>
        <div>
          {dateFormatter(
            locale,
            nextPossibleCancellation.effective_date.value,
            userDateFormat
          )}
        </div>
        <div>notified_date</div>
        <div>
          {dateFormatter(
            locale,
            nextPossibleCancellation.notified_date.value,
            userDateFormat
          )}
        </div>
      </AnalysisData>
    </div>
  );
};
