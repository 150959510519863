import styled from "@emotion/styled";
import { Box, Grid, Button as MUIButton } from "@mui/material";
import { Divider as AuthDivider } from "components/AuthCardWrapper";
import { theme } from "theme";
import OpenFolder from "../../assets/svg/folder-open-logo.svg?react";

export const Description = styled.div`
  font-size: ${theme.font.size.body};
  font-weight: ${theme.font.weight.semiBold};
  color: ${theme.color.blue[600]};
  text-align: center;
`;

export const Caption = styled.div`
  font-size: ${theme.font.size.caption};
  color: ${theme.color.blue[600]};
  margin-bottom: ${theme.spacing.lg};
  text-align: center;
`;

export const Divider = styled(AuthDivider)`
  max-width: 200px;
  margin: ${theme.spacing.xl} 0;
  text-transform: capitalize;
`;

export const Button = styled(MUIButton)`
  min-width: 200px;
  text-transform: none;
`;

export const FilenameLabel = styled(Box)`
  font-weight: bolder;
  overflow-wrap: break-word;
`;

export const PaddedGrid = styled(Grid)`
  margin-top: 1rem;
  padding: 0 1rem;
`;

export const TitleWrapper = styled.h1`
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.874rem;
  line-height: 2.25rem;
`;

export const Dropzone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.lg};
  margin: ${theme.spacing.sm} 0;
  width: 100%;
  height: ${({ isFullHeight }: { isFullHeight: boolean }) =>
    isFullHeight ? "100%" : " 70%"};
  border: 3px dashed ${theme.color.blue[200]};
  border-radius: ${theme.borderRadius};
  background-color: ${theme.color.blue[100]}80;
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out;

  :hover {
    border-color: ${theme.color.blue[200]};
    background-color: ${theme.color.blue[100]};
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 30%;
`;

export const FolderIcon = styled(OpenFolder)`
  width: 80px;
  height: 80px;
`;

export const Row = styled.div`
  margin: ${theme.spacing.md} ${theme.spacing.xl};
`;
