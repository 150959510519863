/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractSignatureRequestDto } from "../models/ContractSignatureRequestDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ContractSignatureService {
  /**
   * Create a signature request for the contract
   * @param id
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static createSignatureRequest(
    id: string,
    requestBody: ContractSignatureRequestDto
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/contract-signature/create/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Cancel a signature process
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static cancelSignatureProcess(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/contract-signature/cancel/{id}",
      path: {
        id: id,
      },
    });
  }
}
