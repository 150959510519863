const durationRegexp = /P(\d+\w)*/;
export const parseDuration = function (input: string | null): string {
  if (!input) {
    return "";
  }
  interface TranslationDict {
    [key: string]: string;
  }
  const timeUnits: TranslationDict = {
    Y: "year",
    M: "month",
    W: "week",
    D: "day",
  };
  const match = input.match(durationRegexp);
  if (!match) {
    return input;
  }
  const timePart = match[1];
  const factor = timePart.substring(0, timePart.length - 1);
  const timeUnit = timeUnits[timePart.substring(timePart.length - 1)];
  return `period ${factor} ${timeUnit} (${input})`;
};

const repeatingIntervalsRegexp = /R\/([\w-]+)\/P(\d+\w)*/;
export const parseRepeatingIntervals = function (input: string | null): string {
  if (!input) {
    return "";
  }
  interface TranslationDict {
    [key: string]: string;
  }
  const timeUnits: TranslationDict = {
    Y: "year",
    M: "month",
    W: "week",
    D: "day",
  };
  const match = input.match(repeatingIntervalsRegexp);
  if (!match) {
    return input;
  }
  const timePart = match[2];
  const factor = timePart.substring(0, timePart.length - 1);
  const timeUnit = timeUnits[timePart.substring(timePart.length - 1)];
  return `recurring ${match[1]} period ${factor} ${timeUnit} (${input})`;
};
