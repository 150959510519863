import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleWrapper = styled.h3`
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.bold};
  color: ${theme.color.gray[900]};
  margin: 0 0 ${theme.spacing.sm};
  line-height: 1;
`;

export const SubTitleWrapper = styled.p`
  margin: 0 0 ${theme.spacing.xl};
  font-size: ${theme.font.size.body};
`;

export const Actions = styled.div``;
