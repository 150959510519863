import styled from "@emotion/styled";
import { theme } from "theme";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${theme.spacing.lg};

  svg .crossIcon {
    fill: ${theme.color.green[600]} !important;
    color: ${theme.color.red[600]} !important;
  }
`;

export const ButtonsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.md};
`;

export const FiltersWrapper = styled.div`
  position: relative;
`;

export const FiltersCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: -14px;
  transform: translate(-50%, -50%);
  height: 18px;
  width: 18px;
  background-color: ${theme.color.indigo[600]};
  color: white;
  padding: ${theme.spacing.xs};
  border: 1px solid ${theme.color.white};
  border-radius: 50%;
  font-size: ${theme.font.size.caption};
`;
