import { RenderPageProps } from "@react-pdf-viewer/core";
import React, { FC, useLayoutEffect, useMemo } from "react";
import { Block } from "aws-sdk/clients/textract";
import { OCRLayerProps } from "../OCRLayer";

type PageRendererProps = {
  renderPageProps: RenderPageProps;
  rotationDelta: number;
  showOCR: boolean;
  searchRegex?: RegExp;
  blocks?: Block[];
  handleSelection?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  OCRLayer: (props: Omit<OCRLayerProps, "store">) => React.ReactElement;
};

export const PageRenderer: FC<PageRendererProps> = ({
  renderPageProps,
  blocks,
  showOCR,
  searchRegex,
  rotationDelta,
  handleSelection,
  OCRLayer,
}) => {
  const filteredBlocks = useMemo(
    () =>
      blocks?.filter(
        (block) =>
          block.BlockType === "LINE" &&
          block.Page === renderPageProps.pageIndex + 1
      ),
    [blocks, renderPageProps.pageIndex]
  );

  useLayoutEffect(() => {
    if (
      renderPageProps.canvasLayerRendered &&
      renderPageProps.textLayerRendered
    ) {
      renderPageProps.markRendered(renderPageProps.pageIndex);
    }
  }, [renderPageProps.canvasLayerRendered, renderPageProps.textLayerRendered]);

  const shouldDisplayOCRLayer = showOCR && !!filteredBlocks;

  return (
    <>
      {shouldDisplayOCRLayer && (
        <OCRLayer
          blocks={filteredBlocks}
          doc={renderPageProps.doc}
          pageIndex={renderPageProps.pageIndex}
          width={renderPageProps.width}
          height={renderPageProps.height}
          scale={renderPageProps.scale}
          rotation={renderPageProps.rotation}
          searchRegex={searchRegex}
          rotationDelta={rotationDelta}
          handleSelection={handleSelection}
        />
      )}
      {renderPageProps.textLayer.children}
      {renderPageProps.canvasLayer.children}
      {/*renderPageProps.annotationLayer.children*/}
    </>
  );
};
