import styled from "@emotion/styled";
import { theme } from "theme";

export const FiltersWrapper = styled.div`
  position: relative;
`;

export const FiltersCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: -18px;
  transform: translate(-50%, -50%);
  height: 24px;
  width: 24px;
  background-color: ${theme.color.indigo[600]};
  color: white;
  padding: ${theme.spacing.xs};
  border: 1px solid ${theme.color.white};
  border-radius: 50%;
  font-size: ${theme.font.size.caption};
`;

export const SectionTitle = styled.h2`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${theme.font.size.subheading};
  margin: 0;
  margin-bottom: ${theme.spacing.xl};
`;

export const Section = styled.div`
  margin-bottom: ${theme.spacing.xl};
`;

const commonStyles = `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
`;

export const CategoryList = styled.ul`
  ${commonStyles}
  gap: ${theme.spacing.sm};
  list-style-type: none;
`;

export const TeamList = styled.div`
  ${commonStyles}
  gap: ${theme.spacing.lg};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 ${theme.spacing.lg};
`;
