import React, { useEffect } from "react";
import {
  AddMemberToRoleDTO,
  OrganizationService,
  TeamMemberDto,
  TeamMemberUpdateDto,
} from "openapi";
import { useTranslation } from "react-i18next";
import RoleItem from "./RoleItem/RoleItem";
import { getNameWithSuffix } from "../../Organization/helper";
import { enqueueSnackbar } from "notistack";
import { TeamService } from "openapi";
import { Card, IconWrapper, TitleContent } from "../styles";
import { CTAButton } from "components";
import {
  InfoIcon,
  LockIcon,
  SwitcherDescription,
  Title,
  MemberRoles,
  Table,
  MessageContainer,
  CategoryHeader,
  FormButtons,
  RolesContainer,
  VisibilityWrapper,
} from "./styles";
import { useTeam } from "contexts/team/hooks";
import { Control, useForm } from "react-hook-form";
import { useRolesOfMemberQuery, useRolesQuery } from "shared/api";
import { useOrganizationAllTeamsQuery } from "shared/api/organization";
import { getDirtyValues } from "utils/forms";
import { FormSwitch } from "components/FormItems/FormSwitch/FormSwitch";
import { useTeamMemberQuery } from "shared/api/team-members";
import { Features } from "constants/features";

export type RolesProps = {
  member: TeamMemberDto;
  teamId: string;
};

export type RoleOption = {
  id: string;
  name: string;
  checked?: boolean;
};

const Roles = ({ member, teamId }: RolesProps) => {
  const { t } = useTranslation();
  const { organizationId, features, hasFeature } = useTeam();
  const {
    control,
    getValues,
    watch,
    reset,
    setValue,
    resetField,
    handleSubmit,
    formState: { dirtyFields, isDirty, isSubmitting },
  } = useForm<{
    categoryOwner: boolean;
    owner: boolean;
    roles: Record<string, boolean>;
    taskAutomationAdmin: boolean;
    contractTemplateAdmin: boolean;
  }>({
    defaultValues: {
      categoryOwner: false,
      owner: false,
      roles: {},
      taskAutomationAdmin: false,
      contractTemplateAdmin: false,
    },
  });

  const { data: organizationData, isLoading: organizationDataLoading } =
    useOrganizationAllTeamsQuery(organizationId);
  const { data: organizationRoles, isLoading: organizationRolesLoading } =
    useRolesQuery(teamId);
  const {
    data: memberRoles,
    isLoading: memberRolesLoading,
    refetch: refetchRoles,
  } = useRolesOfMemberQuery(member.id, teamId);

  const { refetch: refetchMember } = useTeamMemberQuery(teamId, member.id);

  const taskAutomationEnabled = features?.includes(Features.TASK_AUTOMATION);

  const isLoading =
    organizationDataLoading || organizationRolesLoading || memberRolesLoading;

  useEffect(() => {
    if (member && organizationData && organizationRoles && memberRoles) {
      const checkedRoles = new Set(memberRoles.map((role) => role.id));
      reset({
        owner: member.owner,
        categoryOwner: member.categoryOwner,
        taskAutomationAdmin: member.taskAutomationAdmin,
        contractTemplateAdmin: member.contractTemplateAdmin,
        roles: organizationRoles.reduce((acc, role) => {
          return {
            ...acc,
            [role.id]: checkedRoles.has(role.id),
          };
        }, {} as Record<string, boolean>),
      });
    }
  }, [member, organizationData, organizationRoles, memberRoles]);

  const organizationOwner = watch("owner");
  const categoryOwner = watch("categoryOwner");
  const taskAutomationAdmin = watch("taskAutomationAdmin");
  const contractTemplateAdmin = watch("contractTemplateAdmin");

  useEffect(() => {
    if (organizationOwner) {
      setValue("categoryOwner", true);
      setValue("taskAutomationAdmin", true);
      setValue("contractTemplateAdmin", true);
    } else {
      resetField("categoryOwner");
      resetField("taskAutomationAdmin");
      resetField("contractTemplateAdmin");
    }
  }, [organizationOwner]);

  const handleFormReset = () => {
    reset();
  };

  const onSubmit = async () => {
    if (!organizationRoles) return;
    const dirtyValues = getDirtyValues(dirtyFields, getValues());

    if (dirtyValues.owner) {
      try {
        const requestBody: TeamMemberUpdateDto = {
          owner: organizationOwner,
          categoryAdmin: categoryOwner,
          taskAutomationAdmin: taskAutomationAdmin,
          contractTemplateAdmin: contractTemplateAdmin,
        };
        await TeamService.updateMember(member.id, teamId, requestBody);
        enqueueSnackbar(
          t("pages.settings.tabs.team.teamMembers.modals.edit.successMessage"),
          {
            variant: "success",
          }
        );
        await refetchRoles();
        await refetchMember();
      } catch (e) {
        enqueueSnackbar(
          t("pages.settings.tabs.team.teamMembers.modals.edit.errorMessage"),
          { variant: "error" }
        );
      }
      return;
    }

    try {
      if (dirtyValues.roles) {
        const rolesToAdd: AddMemberToRoleDTO[] = [];
        const rolesToRemove: string[] = [];

        for (const [roleId, assigned] of Object.entries(dirtyValues.roles)) {
          if (assigned) {
            rolesToAdd.push({ id: roleId });
          } else {
            rolesToRemove.push(roleId);
          }
        }

        const promisesToAdd = rolesToAdd?.map(
          async (newRole: AddMemberToRoleDTO) => {
            await OrganizationService.addRoleToMember(
              member.id,
              teamId,
              newRole
            ).then(() => {
              setValue(`roles.${newRole.id}`, true);
            });
          }
        );
        await Promise.allSettled(promisesToAdd);

        const promisesRemoveRoles = rolesToRemove?.map(
          async (roleIdToRemove) => {
            await OrganizationService.removeRoleFromMember(
              member.id,
              roleIdToRemove,
              teamId
            ).then(() => {
              setValue(`roles.${roleIdToRemove}`, false);
            });
          }
        );
        await Promise.allSettled(promisesRemoveRoles);
      }

      const requestBody: TeamMemberUpdateDto = {
        owner: organizationOwner,
        categoryAdmin: categoryOwner,
        taskAutomationAdmin: taskAutomationAdmin,
        contractTemplateAdmin: contractTemplateAdmin,
      };
      await TeamService.updateMember(member.id, teamId, requestBody);
      enqueueSnackbar(
        t("pages.settings.tabs.team.teamMembers.modals.edit.successMessage"),
        {
          variant: "success",
        }
      );
      await refetchRoles();
      await refetchMember();
    } catch (error) {
      enqueueSnackbar(
        t("pages.settings.tabs.team.teamMembers.modals.edit.errorMessage"),
        { variant: "error" }
      );
    }
  };

  if (isLoading) return null;

  return (
    <Card>
      <TitleContent>
        <IconWrapper>
          <LockIcon />
        </IconWrapper>
        {t("pages.settings.tabs.team.teamMembers.details.rolesAndPermissions")}
      </TitleContent>

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <FormSwitch
          control={control}
          name="owner"
          label={
            <SwitcherDescription>
              {t(
                "pages.settings.tabs.team.teamMembers.details.makeOrganizationOwner"
              )}
            </SwitcherDescription>
          }
        />

        <MemberRoles>
          <div className="title">
            {t("pages.settings.tabs.team.teamMembers.details.roles.title")}
          </div>

          <div className="description">
            {t("pages.settings.tabs.team.teamMembers.details.roles.subtitle")}
          </div>
        </MemberRoles>

        {!organizationOwner ? (
          <>
            <Table>
              <Title>
                {t("pages.settings.tabs.team.teamMembers.details.roles.role")}
              </Title>
              <Title>
                {t("pages.settings.tabs.team.teamMembers.details.roles.access")}
              </Title>
            </Table>
            <RolesContainer>
              <Table>
                {organizationRoles?.map((role) => (
                  <RoleItem
                    key={role.id}
                    control={control as unknown as Control}
                    name={`roles.${role.id}`}
                    label={getNameWithSuffix(
                      role.name,
                      role.teamId,
                      organizationData?.map((t) => ({
                        id: t.id,
                        name: t.name,
                        permissions: null,
                      })) ?? [],
                      t
                    )}
                  />
                ))}
              </Table>
            </RolesContainer>
          </>
        ) : (
          <MessageContainer>
            <InfoIcon />
            {t("pages.settings.tabs.team.teamMembers.details.roles.fullAccess")}
          </MessageContainer>
        )}

        <div>
          {taskAutomationEnabled ? (
            <VisibilityWrapper disabled={organizationOwner}>
              <CategoryHeader>
                <div className="title">
                  {t(
                    "pages.settings.tabs.team.teamMembers.details.taskAutomation"
                  )}
                </div>
              </CategoryHeader>
              <FormSwitch
                control={control}
                name="taskAutomationAdmin"
                label={
                  <SwitcherDescription>
                    {t(
                      "pages.settings.tabs.team.teamMembers.details.enableTaskAutomation"
                    )}
                  </SwitcherDescription>
                }
              />
            </VisibilityWrapper>
          ) : null}

          <VisibilityWrapper disabled={organizationOwner}>
            <CategoryHeader>
              <div className="title">
                {t(
                  "pages.settings.tabs.team.teamMembers.details.categoryAdministrator"
                )}
              </div>
            </CategoryHeader>

            <FormSwitch
              control={control}
              name="categoryOwner"
              label={
                <SwitcherDescription>
                  {t(
                    "pages.settings.tabs.team.teamMembers.details.makeCategoryOwner"
                  )}
                </SwitcherDescription>
              }
            />
          </VisibilityWrapper>

          {hasFeature(Features.CONTRACT_TEMPLATES) && (
            <VisibilityWrapper disabled={organizationOwner}>
              <CategoryHeader>
                <div className="title">
                  {t(
                    "pages.settings.tabs.team.teamMembers.details.templateOwner"
                  )}
                </div>
              </CategoryHeader>

              <FormSwitch
                control={control}
                name="contractTemplateAdmin"
                label={
                  <SwitcherDescription>
                    {t(
                      "pages.settings.tabs.team.teamMembers.details.makeTemplateOwner"
                    )}
                  </SwitcherDescription>
                }
              />
            </VisibilityWrapper>
          )}

          <FormButtons>
            <CTAButton
              label="member-cancel-button"
              name={t(
                "pages.settings.tabs.team.teamNameForm.common.buttons.cancel"
              )}
              type="reset"
              variant="secondary"
              onClick={handleFormReset}
              disabled={!isDirty || isSubmitting}
            />

            <CTAButton
              label="member-submit-button"
              name={t(
                "pages.settings.tabs.team.teamNameForm.common.buttons.save"
              )}
              type="submit"
              variant="primary"
              disabled={!isDirty || isSubmitting}
            />
          </FormButtons>
        </div>
      </form>
    </Card>
  );
};

export default Roles;
