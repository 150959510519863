import React from "react";
import { FormTextField } from "components";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CardWrapper from "components/CardWrapper/CardWrapper";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import { AdminService, UserListDto } from "openapi";

const searchFilterInitialValues = {
  search: "",
};

type Props = {
  searchResultSetter: React.Dispatch<
    React.SetStateAction<UserListDto | undefined>
  >;
  searchResultTableDisplaySetter: React.Dispatch<React.SetStateAction<boolean>>;
};

const Users = ({
  searchResultSetter,
  searchResultTableDisplaySetter,
}: Props) => {
  const { t } = useTranslation();

  const { control, getValues } = useForm({
    defaultValues: searchFilterInitialValues,
  });

  const handleKeyboardDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const search = getValues().search;
      searchResultSetter(await AdminService.findUsers(search));
      searchResultTableDisplaySetter(true);
    }
  };

  return (
    <>
      <CardWrapper>
        <>
          <CardHeaderWrapper title={t("pages.admin.tabs.users.title")} />
          <form
            name="searchUserForm"
            noValidate
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
              margin: "1rem 0",
              width: "100%",
            }}
          >
            <FormTextField
              control={control}
              margin="none"
              name="search"
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                handleKeyboardDown(e)
              }
              label={t("pages.admin.tabs.users.form.searchUser")}
            />
          </form>
        </>
      </CardWrapper>
    </>
  );
};

export default Users;
