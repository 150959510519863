import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div<{ variation: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.lg};
  border-radius: ${theme.borderRadius};
  width: fit-content;

  ${({ variation }) =>
    variation === "standard" &&
    `
    border: 2px solid ${theme.color.gray[300]};
    background-color: ${theme.color.gray[100]};

    svg {
      fill: ${theme.color.gray[600]};
    }
  `}

  ${({ variation }) =>
    variation === "info" &&
    `
    border: 2px solid ${theme.color.blue[600]};
    background-color: ${theme.color.blue[300]};
  `}

${({ variation }) =>
    variation === "success" &&
    `
    border: 2px solid ${theme.color.green[600]};
    background-color: ${theme.color.green[300]};
  `}

  ${({ variation }) =>
    variation === "error" &&
    `
    border: 2px solid ${theme.color.red[400]};
    background-color: ${theme.color.red[200]};
  `}
`;

export const IconWrapper = styled.div<{ variation: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${theme.borderRadius};
  min-width: 56px;
  min-height: 56px;

  margin-right: ${theme.spacing.xl};

  & > svg {
    width: 42px;
    height: 42px;
  }

  ${({ variation }) =>
    variation === "standard" &&
    `
    background: transparent;
  `}

  ${({ variation }) =>
    variation === "info" &&
    `
    background: ${theme.color.blue[600]};
  `}

  ${({ variation }) =>
    variation === "error" &&
    `
    background: ${theme.color.red[600]};
  `}
`;

export const TextWrapper = styled.div<{ variation?: string }>`
  ${({ variation }) =>
    variation === "standard" &&
    `
    margin: 0 ${theme.spacing.md};
  `}
`;

export const Title = styled.h3<{ variation?: string }>`
  margin: 0;

  ${({ variation }) =>
    variation === "standard" &&
    `
      font-size: ${theme.font.size.body};
      font-weight: ${theme.font.weight.normal};
      margin-bottom: ${theme.spacing.md};
  `}
`;

export const Description = styled.div<{
  noMargin: boolean;
  variation?: string;
}>`
  font-size: ${theme.font.size.body};

  ${({ noMargin }) => !noMargin && `margin-top: ${theme.spacing.sm};`}

  ${({ variation }) =>
    variation === "standard" &&
    `
      color: ${theme.color.gray[600]};
  `}
`;
