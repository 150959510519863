import styled from "@emotion/styled";
import { theme } from "theme";

export const Title = styled.div<{ bgColor?: string; textColor?: string }>`
  background-color: ${(props) => props.bgColor ?? theme.color.blue[200]};
  font-size: ${theme.font.size.caption};
  padding: 0 ${theme.spacing.xs};
  border-radius: ${theme.labelBorderRadius};
  color: ${(props) => props.textColor ?? theme.color.blue[600]};
`;
