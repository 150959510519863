import styled from "@emotion/styled";
import { theme } from "theme";

export const Title = styled.h4`
  margin: 0 0 ${theme.spacing.xs};
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.medium};
`;

export const Description = styled.p`
  margin: 0;
  font-size: ${theme.font.size.body};
  margin-bottom: ${theme.spacing.xl};
`;
