import styled from "@emotion/styled";
import { Button as MuiButton } from "@mui/material";
import gLogo from "./assets/google-logo.svg";
import openIDLogo from "./assets/openid-logo.svg";
import msLogo from "./assets/microsoft-logo.svg";
import { AuthProvider } from "./AuthProvider.enum";
import { theme } from "theme";

export const SignInButton = styled(MuiButton)`
  display: flex;
  align-items: center;
  text-transform: none;
  white-space: nowrap;
  background-color: ${theme.color.white};
  color: ${theme.color.gray[700]};
  height: 2.5rem;

  ${({ provider }: { provider?: AuthProvider }) =>
    provider === AuthProvider.MICROSOFT
      ? `
        font-family: "SegoeUI", sans-serif;
        font-weight: ${theme.font.weight.semiBold};
        padding: 0 ${theme.spacing.md};
    `
      : `
      font-family: "Roboto", sans-serif;
      font-weight: ${theme.font.weight.medium};
      padding: 0 ${theme.spacing.sm};
    `}
`;

export const GoogleImage = styled.img`
  content: url("${gLogo}");
  width: 1.125rem;
  height: 1.125rem;
  margin-right: ${theme.spacing.xl};
`;
export const MicrosoftImage = styled.img`
  content: url("${msLogo}");
  width: 1.125rem;
  height: 1.125rem;
  margin-right: ${theme.spacing.md};
`;

export const OpenIDImage = styled.img`
  content: url("${openIDLogo}");
  width: 1.125rem;
  height: 1.125rem;
  margin-right: ${theme.spacing.md};
`;

export const SignInText = styled.span`
  line-height: 0;
`;
