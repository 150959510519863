import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import Button from "@mui/material/Button";
import { useStyles as useStyledButtons } from "components/StyledComponents/StyledBaseButtons";
import { ContractNameDto, ContractDTOV1 } from "openapi";
import ContractLinkModalContent from "../../../../Modals/LinkContract";
import { NewModal } from "components";

type Props = {
  selectedContract?: ContractDTOV1;
  contractGroup: ContractNameDto[];
  fetchData: (loadFromStash?: boolean | undefined) => Promise<void>;
};

const LinkContract = ({
  selectedContract,
  contractGroup,
  fetchData,
}: Props) => {
  const [showLinkContractModal, setShowLinkContractModal] = useState(false);
  const buttonClasses = useStyledButtons();
  const { t } = useTranslation();

  const handleContractModalToLink = () => setShowLinkContractModal(true);
  const handleContractModalToLinkClose = () => setShowLinkContractModal(false);

  return (
    <>
      <Button
        id="contractLinkBtn"
        className={buttonClasses.previewHeaderButton}
        onClick={handleContractModalToLink}
      >
        <LibraryAddIcon fontSize="small" sx={{ marginRight: ".4rem" }} />
        {t("pages.contractDetails.buttons.linkExistingContracts")}
      </Button>

      <NewModal
        open={showLinkContractModal}
        handleClose={handleContractModalToLinkClose}
        title={t("pages.contractDetails.modals.linkContract.title")}
        body={
          <ContractLinkModalContent
            handleClose={handleContractModalToLinkClose}
            selectedContract={selectedContract}
            contractGroup={contractGroup}
            refetchData={fetchData}
          />
        }
      />
    </>
  );
};

export default LinkContract;
