/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractExportRequestDto } from "../models/ContractExportRequestDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ContractExportService {
  /**
   * Export the contracts in xlsx
   * @param teamId
   * @param requestBody
   * @returns binary
   * @returns any
   * @throws ApiError
   */
  public static exportContracts(
    teamId: string,
    requestBody: ContractExportRequestDto
  ): CancelablePromise<Blob | any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/contract-export/export",
      query: {
        teamId: teamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
}
