import styled from "@emotion/styled";
import { Link as LinkComponent } from "react-router-dom";
import { theme } from "theme";

export const Wrapper = styled.div``;
export const Row = styled.div``;

export const SignerStatusContainer = styled.div`
  gap: ${theme.spacing.xs};
  display: flex;
  flex-direction: row-reverse;
`;

export const Point = styled.div`
  ${({ size }: { size: number }) => `
  width: ${size}px;
  height: ${size}px;
`}
  border-radius: 50%;
  background-color: ${theme.color.gray[700]};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.md};
  justify-content: space-between;
  padding: ${theme.spacing.lg} ${theme.spacing.xl};

  svg {
    width: 20px;
  }
`;

export const SignatureCreditContainer = styled.div`
  display: flex;
  font-size: ${theme.font.size.caption};
  font-weight: ${theme.font.weight.normal};
  align-items: center;
  color: ${theme.color.gray[700]};
  gap: ${theme.spacing.sm};
`;

export const SignatureCreditInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
`;

export const SignatureHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${theme.spacing.lg} ${theme.spacing.xl};

  a {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xs};
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline !important;
  }

  :nth-of-type(odd) {
    background-color: ${theme.color.gray[200]}80;
  }
`;

export const Link = styled(LinkComponent)`
  border-bottom: 1px solid transparent;

  :hover {
    border-bottom: 1px solid ${theme.color.gray[600]};
  }
`;

export const EmptyMessage = styled.div`
  padding: ${theme.spacing.xxs} ${theme.spacing.xl};
  margin-bottom: ${theme.spacing.xxl};
`;

export const SignatureType = styled.div`
  font-size: ${theme.font.size.caption};
  color: ${theme.color.gray[700]};
  font-weight: ${theme.font.weight.normal};
`;

export const SignatureToolTipContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
