import { useSnackbar } from "notistack";
import { ApiError, UserService } from "openapi";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { BaseIdentity } from "./BaseIdentity";
import { IdentityProps, OIDCIdentityProps } from "./types";

export const OIDCIdentity: FC<IdentityProps<OIDCIdentityProps>> = ({
  id,
  refetch,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    if (id) {
      void UserService.disconnectFederatedIdentity("oidc", id.id)
        .then(() => {
          refetch();
          enqueueSnackbar(
            t("pages.settings.tabs.security.identities.disconnected"),
            { variant: "success" }
          );
        })
        .catch((err: ApiError) => {
          if (err.status === 403) {
            enqueueSnackbar(
              t("pages.settings.tabs.security.identities.error.forbidden"),
              { variant: "error" }
            );
          }
        });
    }
  };

  return (
    <BaseIdentity
      provider="oidc"
      id={id}
      name={id?.name}
      onClick={handleClick}
    />
  );
};
