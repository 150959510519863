import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Link } from "@mui/material";
import Grid from "@mui/material/Grid";
import Linkify from "linkify-react";
import { LinkDatapointDTO } from "openapi";
import { DatapointContent } from "pages/Contract/styles";
import DetailNameWrapper from "components/DetailNameWrapper/DetailNameWrapper";
import { FormTextField } from "components";
import { useLocale } from "hooks/GlobalStateHooks";
import { DataPointProps } from "./types";
import { ContactDataDTO } from "../../pages/Contacts/ContactDataDTO";

export const evaluateLink = (data: LinkDatapointDTO) => {
  return (
    <Linkify options={{ tagName: Link, target: "_blank" }}>
      {data ? data.value.value : "-"}
    </Linkify>
  );
};

export const LinkDatapoint: FC<DataPointProps> = ({
  definition,
  contact,
  editable,
}) => {
  const { locale } = useLocale();
  const { control } = useFormContext<ContactDataDTO>();

  const name = definition.name[locale];
  if (editable) {
    return (
      <Grid item xs={12} md={6}>
        <FormTextField
          control={control}
          name={`${definition.visibleId}.value.value`}
          label={definition.name[locale]}
        />
      </Grid>
    );
  }
  const data = contact[definition.visibleId] as LinkDatapointDTO;
  return (
    <Grid item xs={12} md={6}>
      <DetailNameWrapper>{name}</DetailNameWrapper>
      <DatapointContent>{evaluateLink(data)}</DatapointContent>
    </Grid>
  );
};
