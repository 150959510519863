const routePaths = {
  INIT: "/",
  NOTFOUND: "/notfound",
  FORBIDDEN: "/forbidden",
  HOME: "/home",
  LOGIN: "/login",
  LOGOUT: "/logout",
  REGISTER: "/register",
  TEAM_CREATION: "/team-creation",
  CONTACTS: "/contacts",
  CONTACTS_VIEW: "/contacts/:id",
  CONTRACTS: "/contracts",
  CONTRACT_TASKS: "/contracts/:id/tasks",
  CONTRACT_VIEW: "/contracts/:id",
  CONTRACT_EDITVIEW: "/contracts/:id/edit",
  TASKS: "/tasks",
  TASK_DETAILS: "/tasks?task=:id",
  IMPORT: "/import",
  EXPORT: "/export",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  VERIFICATION_EMAIL: "/verify",
  SETTINGS: "/settings",
  SETTINGS_PROFILE: "profile",
  SETTINGS_USER: "/user",
  SETTINGS_USER_PROFILE: "/user/profile",
  SETTINGS_USER_NOTIFICATIONS: "/user/notifications",

  SETTINGS_ORGANIZATION: "/settings/organization/details",
  SETTINGS_ORGANIZATION_PERMISSION_CHANGE_LOG:
    "/settings/organization/permission-change-log",
  SETTINGS_TEAMS: "/settings/teams",
  SETTINGS_TEAM: "/settings/teams/:id",

  SETTINGS_MEMBERS: "/settings/members",
  SETTINGS_CUSTOM_FIELDS: "/settings/custom-fields",
  SETTINGS_CATEGORY: "/settings/category",
  SETTINGS_CONTRACT_TEMPLATES: "/settings/contract-templates",
  SETTINGS_PAYMENT: "subscription",
  SETTINGS_BACKUP: "backup",
  SETTINGS_WEBHOOK: "/settings/webhooks",
  SETTINGS_TASK_AUTOMATION: "/settings/task-automations",
  PAYMENT: "/settings/payment",
  SETTINGS_SUBSCRIPTION: "/settings/subscription",
  PAYMENT_ACCOUNT_DETAILS: "/settings/payment/account-details",
  PAYMENT_BILLING_DETAILS: "/settings/payment/billing-details",
  PAYMENT_METHODS: "/settings/payment/methods",
  PAYMENT_INVOICES: "/settings/payment/invoices",
  ADMIN: "/kiosk",
  ADMIN_PENDING_CONTRACT: "contracts",
  ADMIN_PENDING_CONTRACT_EDIT: "contracts/:id",
  ADMIN_USERS: "users",
  ADMIN_USERS_IMPERSONATE: ":id",
  ADMIN_ORGANIZATIONS: "organizations",
  FEDERATED_IDENTITY_APPROVAL: "/federated-identity/:id/approve",
  OAUTH_CALLBACK: "/federated-identity/:provider",

  //migration
  MIGRATION_PASSWORD_RESET: "/password/reset/:token",
  MIGRATION_VERIFICATION_EMAIL: "/email/verify/:id/:token",
  MAINTENANCE: "/maintenance",
  MAINTENANCE_REGISTER: "/maintenance/register",
  MAINTENANCE_LOGIN: "/maintenance/admin",
  MAINTENANCE_VERIFICATION_EMAIL: "/maintenance/verify",
  MAINTENANCE_FORGOT_PASSWORD: "/maintenance/forgot-password",
  MAINTENANCE_RESET_PASSWORD: "/maintenance/reset-password",
};

export default routePaths;
