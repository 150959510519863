import styled from "@emotion/styled";

export const Wrapper = styled.div`
  margin: 1rem 0;
`;

export const Title = styled.h1`
  font-weight: 400;
  color: rgb(26, 32, 44);
  text-align: start;
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin: 0px;
`;
