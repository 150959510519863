import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, Control } from "react-hook-form";
import ContactsIcon from "@mui/icons-material/Contacts";
import {
  ContactTypeDTO,
  ContactDatapointDefinitionDTO,
  TextDatapointDTO,
} from "openapi";
import { theme } from "theme";
import AutoCompleteSelect from "components/FormItems/AutoCompleteSelect/AutoCompleteSelect";
import Section from "../Section/Section";
import { ContactDataDTO } from "../../../Contacts/ContactDataDTO";
import { TitleWrapper, Title } from "../../styles";
import {
  Toolbar,
  SearchBar,
  Column,
  Container,
  EmptyContactArea,
} from "./styles";

type BodyProps = {
  control: Control;
  options: { key: string; value: string }[];
  setSelectedContactId: (value: string) => void;
  showPreview: boolean;
  previewContactData: ContactDataDTO | undefined;
  contactType: ContactTypeDTO;
  contactDataPointDefinitions: ContactDatapointDefinitionDTO[];
  contact: ContactDataDTO;
  selectedContactData?: ContactDataDTO | undefined;
};

const Body = (props: BodyProps) => {
  const { t } = useTranslation();
  return (
    <>
      {!props.showPreview && (
        <Toolbar>
          <SearchBar>
            <Controller
              control={props.control}
              name="searchContact"
              render={() => (
                <AutoCompleteSelect
                  label={t("pages.contacts.header.searchContacts")}
                  options={props.options ?? []}
                  onSelect={(target) => props.setSelectedContactId(target?.key)}
                  inputStyles={{ margin: 0 }}
                />
              )}
            />
          </SearchBar>
        </Toolbar>
      )}
      {/* @Todo: Extract this in a component, as this could be reused in different places:
        - Contact view
        - Contact in contract
        - Both sides of the merge view
        - Merge preview view
        */}
      {props.showPreview ? (
        <Container>
          <Column>
            <TitleWrapper style={{ marginBottom: theme.spacing.lg }}>
              <ContactsIcon fontSize="small" />
              <Title>
                {
                  (props.previewContactData?.name as TextDatapointDTO)?.value
                    ?.value as string
                }
              </Title>
            </TitleWrapper>
            {props.contactType?.sections?.map((section) => (
              <Section
                key={section.id}
                editable={false}
                section={section}
                definitions={props.contactDataPointDefinitions}
                values={props.previewContactData ?? ({} as ContactDataDTO)}
              />
            ))}
          </Column>
        </Container>
      ) : (
        <>
          <Container>
            <Column>
              <TitleWrapper style={{ marginBottom: theme.spacing.lg }}>
                <ContactsIcon fontSize="small" />
                <Title>
                  {
                    (props.contact?.name as TextDatapointDTO)?.value
                      ?.value as string
                  }
                </Title>
              </TitleWrapper>
              {props.contactType?.sections?.map((section) => (
                <Section
                  key={section.id}
                  editable={false}
                  section={section}
                  definitions={props.contactDataPointDefinitions}
                  values={props.contact ?? ({} as ContactDataDTO)}
                />
              ))}
            </Column>
            {props.selectedContactData ? (
              <Column>
                <TitleWrapper style={{ marginBottom: theme.spacing.lg }}>
                  <ContactsIcon fontSize="small" />
                  <Title>
                    {
                      (props.selectedContactData?.name as TextDatapointDTO)
                        ?.value?.value as string
                    }
                  </Title>
                </TitleWrapper>
                {props.contactType?.sections?.map((section) => {
                  if (props.selectedContactData === undefined) return null;

                  return (
                    <Section
                      key={section.id}
                      editable={false}
                      section={section}
                      definitions={props.contactDataPointDefinitions}
                      values={props.selectedContactData}
                    />
                  );
                })}
              </Column>
            ) : (
              <EmptyContactArea>
                {t("pages.contacts.modal.merge.noContactToCompareMessage")}
              </EmptyContactArea>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default Body;
