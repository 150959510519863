import React from "react";
import { useTranslation } from "react-i18next";
import { Control, Controller } from "react-hook-form";
import { RadioGroup, FormControlLabel } from "@mui/material";
import { TeamPermissionDto, CategoryDTO } from "openapi";
import { useLocale } from "hooks";
import { Chip } from "components";
import { getColor } from "components/Chip/helpers";
import { CustomRadio } from "components/FormItems/RadioGroup/styles";
import { FormType } from "../../TeamRoles/types";
import {
  Wrapper,
  TableHeadRow,
  TableHeadCell,
  TableRow,
  TableCell,
} from "./styles";

const Table = ({
  control,
  categories,
}: {
  control: Control<FormType>;
  categories: CategoryDTO[];
}) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const defaultPermissionLevel = "NO_ACCESS";

  const headers = [
    {
      key: "name",
      name: t(
        "pages.settings.organization.teams.permissions.table.headers.category"
      ),
    },
    {
      key: "fullAccess",
      name: t(
        "pages.settings.organization.teams.permissions.table.headers.fullAccess"
      ),
    },
    {
      key: "readOnly",
      name: t(
        "pages.settings.organization.teams.permissions.table.headers.readOnly"
      ),
    },
    {
      key: "NoAccess",
      name: t(
        "pages.settings.organization.teams.permissions.table.headers.noAccess"
      ),
    },
  ];

  if (!categories.length) return null;

  return (
    <Wrapper>
      <TableHeadRow>
        {headers.map(({ key, name }) => (
          <TableHeadCell key={key}>{name}</TableHeadCell>
        ))}
      </TableHeadRow>

      {categories.map((category, index) => (
        <Controller
          key={category.id}
          name={`permissions.${index}.level`}
          control={control}
          defaultValue={defaultPermissionLevel}
          render={({ field }) => (
            <RadioGroup {...field} row data-testid="permissions-category-item">
              <TableRow key={category.id}>
                <TableCell>
                  <Chip blendMode="soft" color={getColor(category.color)}>
                    {category.name[locale]}
                  </Chip>
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    key={category.id}
                    value={TeamPermissionDto.level.WRITE}
                    label=""
                    control={<CustomRadio />}
                    checked={field.value === TeamPermissionDto.level.WRITE}
                  />
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    key={category.id}
                    value={TeamPermissionDto.level.READ}
                    label=""
                    control={<CustomRadio />}
                    checked={field.value === TeamPermissionDto.level.READ}
                  />
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    key={category.id}
                    value={defaultPermissionLevel}
                    label=""
                    control={<CustomRadio />}
                    checked={field.value === defaultPermissionLevel}
                  />
                </TableCell>
              </TableRow>
            </RadioGroup>
          )}
        />
      ))}
    </Wrapper>
  );
};

export default Table;
