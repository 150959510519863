import React, { forwardRef, useImperativeHandle, useState } from "react";
import { GridApi, ICellEditorParams } from "ag-grid-community";
import { useLocale } from "../../../hooks/GlobalStateHooks";
import { CellValue } from "../helpers";
import { NumericCellEditor } from "./style";
import { NumberFieldConfiguration } from "openapi";

interface CustomCellEditorParams extends ICellEditorParams {
  type?: string;
  value: CellValue;
  configuration?: NumberFieldConfiguration | null | undefined;
  api: GridApi;
  matchedFieldValue?: number;
  isCustomField?: boolean;
}

export const NumberCellEditor = forwardRef(
  (props: CustomCellEditorParams, ref) => {
    const {
      matchedFieldValue,
      isCustomField,
      value: cellValue,
      type,
      configuration,
    } = props;
    const [value, setValue] = useState<number | null>(
      (cellValue?.value ?? cellValue ?? matchedFieldValue) as number
    );
    const { locale } = useLocale();
    const decimalSeparator = locale === "en" ? "." : ",";
    const thousandSeparator = locale === "de" ? "." : ",";
    const decimalScale = configuration?.decimalScale || 2;

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return isCustomField ? { value } : value;
        },
      };
    });
    const isAmount = type === "amount";
    return (
      <NumericCellEditor
        autoFocus={true}
        value={value}
        onValueChange={(val) => {
          setValue(val.floatValue ?? null);
        }}
        onBlur={() => {
          props.api.stopEditing();
        }}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        fixedDecimalScale={isAmount}
        decimalScale={isAmount ? 2 : decimalScale}
      />
    );
  }
);

NumberCellEditor.displayName = "NumberCellEditor";
