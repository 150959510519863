import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import WarningIcon from "@mui/icons-material/Warning";

const TitleWrapper = styled.h3`
  line-height: 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: #1a202c;
  margin: 0;
  margin-bottom: 0.5rem;
`;

const RoundedBox = styled(Box)`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: #fff5f5;
`;

const ContractDeleteGridViewModalContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box className="flex-align-center">
        <Box className="flex-center">
          <RoundedBox className="flex-column-center">
            <WarningIcon sx={{ fill: "#e53e3e" }} />
          </RoundedBox>
        </Box>
        <Box sx={{ marginLeft: "1rem" }}>
          <TitleWrapper>
            {t("pages.contracts.header.modals.deleteViewModal.title")}
          </TitleWrapper>
          {t("pages.contracts.header.modals.deleteViewModal.subtitle")}
        </Box>
      </Box>
    </>
  );
};

export default ContractDeleteGridViewModalContent;
