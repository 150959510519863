import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot } from "lexical";
import { $generateNodesFromDOM } from "@lexical/html";

type ImportPluginProps = {
  templateData?: string;
  mode: "replace" | "append";
};

export const ImportPlugin = ({ templateData, mode }: ImportPluginProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (templateData) {
      editor.update(() => {
        let root = $getRoot();
        if (mode === "replace") {
          root = root.clear();
        }
        const parser = new DOMParser();
        const dom = parser.parseFromString(templateData, "text/html");
        const nodes = $generateNodesFromDOM(editor, dom);
        root.append(...nodes);
      });
    }
  }, [templateData]);

  return null;
};
