import { TaskAutomationDueDateConfigDTO } from "openapi";
import * as Yup from "yup";

export const defaultFormValues = {
  title: "",
  assigneeIds: [],
  categoryIds: [],
  description: "",
};

export const defaultDateValues = {
  dueDateConfig: {
    enabled: false,
    offset: 1,
    unit: TaskAutomationDueDateConfigDTO.unit.DAY,
    dueDate: "",
    relativeFieldId: "",
    repeatConfig: {
      enabled: false,
      unit: TaskAutomationDueDateConfigDTO.unit.DAY,
      offset: 1,
    },
  },
  reminderConfig: {
    enabled: false,
    unit: TaskAutomationDueDateConfigDTO.unit.DAY,
    offset: 1,
  },
};

export const validationSchema = (
  dueDateIsEnabled: boolean,
  repeatIsEnabled: boolean,
  reminderIsEnabled: boolean
) => {
  return Yup.object().shape({
    title: Yup.string().required(),
    categoryIds: Yup.array().of(Yup.string().required()).min(1).required(),
    assigneeIds: Yup.array().of(Yup.string()),
    description: Yup.string(),
    dueDateConfig: Yup.lazy(() => {
      if (dueDateIsEnabled) {
        return Yup.object().shape({
          offset: Yup.number().required(),
          unit: Yup.string().required(),
          relativeFieldId: Yup.string().required(),
          repeatConfig: repeatIsEnabled
            ? Yup.object().shape({
                offset: Yup.number().required(),
                unit: Yup.string().required(),
              })
            : Yup.object(),
        });
      }
      return Yup.mixed().notRequired();
    }),
    reminderConfig: Yup.lazy(() => {
      if (reminderIsEnabled) {
        return Yup.object().shape({
          offset: Yup.number().required(),
          unit: Yup.string().required(),
        });
      }
      return Yup.mixed().notRequired();
    }),
  });
};
