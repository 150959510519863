import { Loader } from "components";
import { useTeam } from "contexts/team/hooks";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useContactDataPointsDefinitionsQuery,
  useContactTypesQuery,
} from "shared/api/contacts";
import { routePaths } from "../../constants";
import { Toolbar } from "./components";
import RightSide from "./components/RightSide/RightSide";
import { Column, Columns, Wrapper } from "./styles";
import { ContactData } from "./components/ContactData/ContactData";

const Contact = () => {
  const { id: contactId, mode } = useParams();
  const { parentTeamId: organizationId } = useTeam();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const navigate = useNavigate();
  const { data: contactTypes } = useContactTypesQuery(organizationId);
  const { data: contactDefinitions } =
    useContactDataPointsDefinitionsQuery(organizationId);

  const isEditable = contactId === "draft" || mode === "edit";
  const setIsEditable = (editable: boolean) => {
    navigate(editable ? "edit" : "..", { relative: "path" });
  };

  const handleOnGoBackClick = () => {
    if (contactId === "draft") {
      navigate(routePaths.CONTACTS);
      return;
    }

    if (isEditable) {
      setIsEditable(false);
    } else {
      navigate(routePaths.CONTACTS);
    }
  };

  if (!contactId || !contactTypes || !contactDefinitions) return <Loader />;

  return (
    <Wrapper>
      <Toolbar
        contactId={contactId}
        setShowMergeModal={setShowMergeModal}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        handleOnGoBackClick={handleOnGoBackClick}
      />

      <Columns>
        <Column>
          <ContactData
            showMergeModal={showMergeModal}
            setShowMergeModal={setShowMergeModal}
            isEditable={isEditable}
            setIsEditable={setIsEditable}
            contactId={contactId}
            contactTypes={contactTypes}
            contactDefinitions={contactDefinitions}
          />
        </Column>
        <Column>
          <RightSide
            contactId={contactId}
            canCreateContract={contactId !== "draft"}
          />
        </Column>
      </Columns>
    </Wrapper>
  );
};

export default Contact;
