/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SFTPBackupConfig = {
  hostname: string;
  port: number;
  username: string;
  sftpPassword: string;
  path: string;
  type: SFTPBackupConfig.type;
  password?: string;
};

export namespace SFTPBackupConfig {
  export enum type {
    FTPS = "FTPS",
    SFTP = "SFTP",
    AWS_S3 = "AWS S3",
  }
}
