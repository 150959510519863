/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ImportViaEmailService {
  /**
   * Email address to import contracts via email
   * @param teamId
   * @returns string
   * @throws ApiError
   */
  public static getEmailAddress(teamId: string): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/import-via-email/email-address",
      query: {
        teamId: teamId,
      },
    });
  }
}
