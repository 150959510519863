/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type WebhookEventDTO = {
  id: string;
  type: WebhookEventDTO.type;
};

export namespace WebhookEventDTO {
  export enum type {
    CONTRACT_CREATED = "CONTRACT_CREATED",
    CONTRACT_EDITED = "CONTRACT_EDITED",
    MEMBER_INVITATION_CREATED = "MEMBER_INVITATION_CREATED",
    MEMBER_INVITATION_ACCEPTED = "MEMBER_INVITATION_ACCEPTED",
    TASK_CREATED = "TASK_CREATED",
    TASK_UPDATED = "TASK_UPDATED",
    TASK_DUE_DATE_REACHED = "TASK_DUE_DATE_REACHED",
    TASK_REMINDER_SENT = "TASK_REMINDER_SENT",
    SUMMARY_UPDATED = "SUMMARY_UPDATED",
  }
}
