import {
  ContractDTOV1,
  TaskNewCreateDTO,
  TeamMemberDto,
  TeamPermissionInfoDto,
} from "openapi";
import * as Yup from "yup";

export const validationSchema = (
  activeTab: string,
  reminderIsEnabled: boolean,
  repeatIsEnabled: boolean
) => {
  return Yup.object().shape({
    title: Yup.string().required(),
    contractId: Yup.string().required(),
    dueDateConfig: Yup.lazy(() => {
      if (activeTab === TaskNewCreateDTO.dueDateType.RELATIVE) {
        return Yup.object().shape({
          offset: Yup.number().required(),
          unit: Yup.string().required(),
          relativeFieldId: Yup.string().required(),
          repeatConfig: repeatIsEnabled
            ? Yup.object().shape({
                offset: Yup.number().required(),
                unit: Yup.string().required(),
              })
            : Yup.object(),
        });
      } else if (activeTab === TaskNewCreateDTO.dueDateType.FIXED) {
        return Yup.object().shape({
          dueDate: Yup.string().required(),
          repeatConfig: repeatIsEnabled
            ? Yup.object().shape({
                offset: Yup.number().required(),
                unit: Yup.string().required(),
              })
            : Yup.object(),
        });
      }
      return Yup.mixed().notRequired();
    }),
    reminderConfig: Yup.lazy(() => {
      if (reminderIsEnabled) {
        return Yup.object().shape({
          offset: Yup.number().required(),
          unit: Yup.string().required(),
        });
      }
      return Yup.mixed().notRequired();
    }),
  });
};

export const userIsOwner = (member: TeamMemberDto) => {
  return (
    member.role === TeamPermissionInfoDto.role.OWNER ||
    member.role === TeamPermissionInfoDto.role.USER
  );
};

export const userHasAccessToCategory = (
  member: TeamMemberDto,
  contract: ContractDTOV1
) => {
  return (
    (member.role === TeamPermissionInfoDto.role.LIMITED_USER &&
      member.permissions.length &&
      member.permissions.some(
        (item) => item.categoryId === contract?.categoryId
      )) ||
    userIsOwner(member)
  );
};
