import React from "react";
import { FormTextField } from "components";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { SFTPBackupConfig } from "openapi";
import { PasswordField } from "new-components";

const BackupFormSFTP = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<SFTPBackupConfig>();

  return (
    <>
      <FormTextField
        control={control}
        name="hostname"
        label={t("pages.settings.tabs.backup.sftp.form.hostname")}
        required
      />
      <FormTextField
        control={control}
        name="port"
        defaultValue={22}
        label={t("pages.settings.tabs.backup.sftp.form.port")}
        required
      />
      <FormTextField
        control={control}
        name="username"
        label={t("pages.settings.tabs.backup.sftp.form.username")}
        required
      />
      <PasswordField
        control={control}
        name="sftpPassword"
        label={t("pages.settings.tabs.backup.sftp.form.password")}
        required
      />
      <FormTextField
        control={control}
        name="path"
        label={t("pages.settings.tabs.backup.sftp.form.path")}
        required
      />
    </>
  );
};

export default BackupFormSFTP;
