import { FormSelectItem } from "components/FormItems/FormSelect/FormSelect";
import {
  CategoryDTO,
  ContractFieldDTOV1,
  TeamMemberDto,
  TeamMembersDto,
} from "openapi";
import { TFunction } from "i18next";
import { Language } from "shared/i18n/i18n";

export type assigneesWithoutAccessToCategoryProps = {
  member: TeamMemberDto;
  unaccessibleCategories: CategoryDTO[];
};

export const getCategoriesInfoByIds = (
  categories: CategoryDTO[] | undefined,
  selectedCategoryIds: string[]
) => {
  return categories?.filter((category) =>
    selectedCategoryIds.includes(category.id)
  );
};

export const getFieldInfoById = (
  fields: ContractFieldDTOV1[] | undefined,
  fieldId: string | null | undefined
) => {
  if (!fields?.length || !fieldId) return;
  const fieldIdWoSuffix = fieldId.includes("__")
    ? fieldId.split("__")[0]
    : fieldId;
  return fields.find((field) => field.id === fieldIdWoSuffix);
};

export const getAssigneesWithoutAccessToCategory = (
  categories: CategoryDTO[] | undefined,
  teamMembersData: TeamMembersDto | undefined,
  selectedCategoryIds: string[],
  selectedAssigneeIds: string[] | null | undefined
): assigneesWithoutAccessToCategoryProps[] => {
  if (
    !categories ||
    !teamMembersData ||
    !selectedCategoryIds?.length ||
    !selectedAssigneeIds?.length
  )
    return [];

  const selectedTeamMembers = teamMembersData?.members?.filter((member) =>
    selectedAssigneeIds.includes(member.id)
  );

  return selectedTeamMembers?.reduce(
    (acc: assigneesWithoutAccessToCategoryProps[], member: TeamMemberDto) => {
      if (!member.permissions.length) return acc;

      const accessibleCategoryIds = member.permissions.map(
        (permission) => permission.categoryId
      );
      const selectedCategories = categories?.filter((category) =>
        selectedCategoryIds.includes(category.id)
      );
      const unaccessibleCategories = selectedCategories?.filter(
        (category) => !accessibleCategoryIds.includes(category.id)
      );
      if (!unaccessibleCategories?.length) return acc;
      return [...acc, { member, unaccessibleCategories }];
    },
    []
  );
};

export const getCategoriesWithoutDateField = (
  categories: CategoryDTO[] | undefined,
  selectedCategoryIds: string[],
  selectedDateFieldId: string | null | undefined
) => {
  if (!categories || !selectedCategoryIds?.length || !selectedDateFieldId)
    return [];

  const [fieldId] = selectedDateFieldId.split("__");

  const selectedCategories = getCategoriesInfoByIds(
    categories,
    selectedCategoryIds
  );
  return selectedCategories?.reduce(
    (acc: CategoryDTO[], category: CategoryDTO) => {
      const hasField = category.sections.some((section) =>
        section.fields.map((item) => item.id).includes(fieldId)
      );
      if (hasField) return acc;
      return [...acc, category];
    },
    []
  );
};

export const getAvailableDateFieldsBySelectedCategories = (
  selectedCategories: CategoryDTO[] | undefined,
  fields: ContractFieldDTOV1[] | undefined
) => {
  if (!selectedCategories?.length || !fields?.length) return;

  const availableFieldIdsBySelectedCategories: string[] = [];
  selectedCategories?.forEach((category) => {
    category.sections.forEach((section) =>
      section.fields.forEach((field) =>
        availableFieldIdsBySelectedCategories.push(field.id)
      )
    );
  });
  const fieldsFilteredBySelectedCategories = fields?.filter((field) =>
    availableFieldIdsBySelectedCategories.includes(field.id)
  );
  const dateFields = fieldsFilteredBySelectedCategories?.filter(
    (field) => field.type === "DATE" || field.type === "DURATION"
  );

  return dateFields;
};

export const durationFieldDateNames: string[] = [
  "startDate",
  "endDate",
  "terminationDate",
];

export const getDateFieldOptions = (
  dateFields: ContractFieldDTOV1[],
  t: TFunction<"translation">,
  locale: Language
) => {
  return dateFields.reduce((acc: FormSelectItem[], field) => {
    if (field.type === "DATE") {
      return [
        ...acc,
        {
          key: field.id,
          value: field.name[locale],
        },
      ];
    }
    if (field.type === "DURATION") {
      const fieldIdWithSuffix = durationFieldDateNames.map((subField) => {
        return {
          key: `${field.id}__${subField}`,
          value: `${t(`pages.tasks.modal.fields.dates.${subField}`)} (${
            field.name[locale]
          })`,
        };
      });

      return [...acc, ...fieldIdWithSuffix];
    }
    return acc;
  }, []);
};
