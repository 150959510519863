import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { ImportViaEmailService } from "openapi/services/ImportViaEmailService";

export const useImportViaEmailQuery = (
  teamId: string
): UseQueryResult<string> => {
  return useQuery({
    queryKey: ["importViaEmail", teamId],
    queryFn: async () => {
      const emailAddress = await ImportViaEmailService.getEmailAddress(teamId);
      return emailAddress ?? "";
    },
    enabled: !!teamId,
  });
};
