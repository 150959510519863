import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { ICellRendererParams } from "ag-grid-community";
import {
  ContactFieldTypeDTOV1,
  ContractDTOV1,
  ContractFieldDTOV1,
} from "openapi";
import { formatContactName } from "../../Contact/helpers";
import styled from "@emotion/styled";
import { ContactDatapointDTOs } from "../../Contacts/ContactDataDTO";
import { AnalysisCellRendererWrapper } from "./AnalysisCellRendererWrapper";
import useDataPointAnalysis from "components/Datapoints/hooks/useDatapointAnalysis";
import { queryClient } from "shared/api/client";
import { useUpdateContractMutation } from "shared/api/contracts";
import { AGGridBaseTableContext } from "components/AgGridBaseTable/types";

export const Wrapper = styled.div`
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ContactCellRenderer = (
  props: ICellRendererParams<ContractDTOV1, unknown, AGGridBaseTableContext> & {
    definition: ContractFieldDTOV1;
  }
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { value } = props;
  const { contacts, contactTypes } = props.context;

  const fieldData = props.data?.fields[props.definition.id];
  const { suggestions, selectInsight } = useDataPointAnalysis(
    props.definition,
    props.data as ContractDTOV1,
    fieldData
  );

  const updateContractMutation = useUpdateContractMutation();

  const onSelectInsight = async (value: string) => {
    const contactId = contacts.find((contact) => {
      return (contact.name as ContactDatapointDTOs).value.value === value;
    })?.id;
    if (contactId) {
      await selectInsight("value" as never, contactId);
    } else {
      if (!props.data?.teamId) {
        return;
      }
      updateContractMutation.mutate(
        {
          teamId: props.data.teamId,
          contractId: props.data.id,
          requestBody: {
            fields: {
              [props.definition.id]: {
                value: null,
                $create: {
                  typeId: contactTypes[0].id,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  name: {
                    value: {
                      value: value,
                    },
                  },
                },
              } as ContactFieldTypeDTOV1,
            },
          },
        },
        {
          onSuccess: () => {
            void queryClient.invalidateQueries(["contacts"]);
          },
        }
      );
    }
  };

  const contact = contacts?.find((item) => item.id === value);

  return (
    <AnalysisCellRendererWrapper
      {...props}
      field="value"
      suggestions={suggestions}
      onSelectInsight={onSelectInsight}
    >
      <Wrapper>
        {contact ? (
          <RouterLink to={`/contacts/${contact.id}`}>
            {formatContactName(contact)}
          </RouterLink>
        ) : (
          "-"
        )}
      </Wrapper>
    </AnalysisCellRendererWrapper>
  );
};

export default ContactCellRenderer;
