import React, { useEffect, useState } from "react";
import { Box, List } from "@mui/material";
import {
  FederatedIdentityDto,
  OIDCIdentity as OIDCIdentityType,
  UserService,
} from "openapi";
import { GoogleIdentity } from "./Identities/GoogleIdentity";
import { MicrosoftIdentity } from "./Identities/MicrosoftIdentity";
import { OIDCIdentity } from "./Identities/OIDCIdentity";

export const FederatedIdentityConnections = () => {
  const [federatedIdentities, setFederatedIdentities] =
    useState<FederatedIdentityDto>();

  const refetch = () => {
    void UserService.getFederatedIdentities().then((identities) => {
      setFederatedIdentities(identities);
    });
  };
  useEffect(() => {
    void refetch();
  }, []);

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <List sx={{ pt: 0 }}>
        {federatedIdentities &&
          Object.entries(federatedIdentities).map(([provider, id]) => {
            switch (provider) {
              case "google":
                return (
                  <GoogleIdentity
                    key={provider}
                    id={id as string}
                    refetch={refetch}
                  />
                );
              case "microsoft":
                return (
                  <MicrosoftIdentity
                    key={provider}
                    id={id as string}
                    refetch={refetch}
                  />
                );
              case "oidc":
                return id ? (
                  <OIDCIdentity
                    key={provider}
                    id={id as OIDCIdentityType}
                    refetch={refetch}
                  />
                ) : null;

              default:
                return null;
            }
          })}
      </List>
    </Box>
  );
};
