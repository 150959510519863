import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  a {
    display: flex;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    color: #4a5568;
    margin: 0.5rem 1rem 0.5rem 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s linear;
  }

  a svg {
    margin-right: 0.5rem;
  }

  .active {
    border-bottom: 2px solid #1976d2;
  }
`;
