import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;

export const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 1400;
`;
