import React, { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormTextField } from "components";
import { FormTextFieldProps } from "../../components/FormItems/FormTextField/FormTextField";
import { FieldValues } from "react-hook-form";

export function PasswordField<T extends FieldValues>({
  control,
  name,
  label,
  showVisibilityIcon = false,
  ...rest
}: FormTextFieldProps<T> & { showVisibilityIcon?: boolean }): JSX.Element {
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  return (
    <FormTextField
      control={control}
      name={name}
      type={isPasswordShown ? "text" : "password"}
      label={label}
      InputProps={
        showVisibilityIcon
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setIsPasswordShown(!isPasswordShown)}
                    edge="end"
                  >
                    {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }
          : undefined
      }
      {...rest}
    />
  );
}
