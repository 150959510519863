/* eslint-disable */
import React from "react";
import { Date, Header, Title, Wrapper, Body } from "./styles";
import EditEntityItem from "../EditEntityItem/EditEntityItem";
import {
  AttachmentData,
  ContractChangeLogDTO,
  ContractFieldDTOV1,
  ContractFieldDto,
  ContractMovedData,
  DocumentData,
  RecipientDto,
  SignatureRequestCancelDTO,
  SignatureRequestDTO,
  SignerData,
  SignerDeclinedData,
  TaskData,
  UserInfoDto,
} from "openapi";
import dayjs from "dayjs";
import { useLocale, useUserInfo } from "hooks";
import { useTranslation } from "react-i18next";
import CreateEntityItem from "../CreateEntityItem/CreateEntityItem";
import FieldWithIconEntityItem from "../FieldWithIconEntityItem/FieldWithIconEntityItem";
import {
  availableTranslationsByEventType,
  dateFields,
  fieldsToSkip,
  getTranslation,
} from "../../utils";
import {
  ChangeLogDataSimpleItemType,
  ChangeLogDataItemType,
  ChangeLogDataType,
  LogEntityProps,
} from "../../types";
import { Loader } from "components";
import LogIcon from "assets/svg/log-icon.svg?react";
import CalendarIcon from "assets/svg/calendar-icon.svg?react";
import TagIcon from "assets/svg/tag-icon.svg?react";

import ListIcon from "assets/svg/list-icon.svg?react";
import CircleArrow from "assets/svg/circle-arrow-icon.svg?react";
import SimpleField from "../Fields/SimpleField";
import DateField from "../Fields/DateField";
import CategoryField from "../Fields/CategoryField";
import ContactField from "../Fields/ContactField";
import DurationField from "../Fields/DurationField";
import AmountField from "../Fields/AmountField";
import Tags from "../Tags/Tags";

export const getFieldIcon = (
  fieldType: ContractFieldDTOV1.type | undefined
) => {
  switch (fieldType) {
    case ContractFieldDTOV1.type.AMOUNT:
    case ContractFieldDTOV1.type.NUMBER:
    case ContractFieldDTOV1.type.FORMULA:
    case ContractFieldDTOV1.type.DURATION:
    case ContractFieldDTOV1.type.TEXTAREA:
    case ContractFieldDTOV1.type.TEXTFIELD:
    case ContractFieldDTOV1.type.LINK:
    case ContractFieldDTOV1.type.LIST: {
      return <ListIcon />;
    }

    case ContractFieldDTOV1.type.COUNTRY:
    case ContractFieldDTOV1.type.CONTACT: {
      return <CircleArrow />;
    }
    case ContractFieldDTOV1.type.DATE: {
      return <CalendarIcon />;
    }
    default: {
      return <LogIcon />;
    }
  }
};

export const getChangedFields = (
  data: ChangeLogDataItemType,
  fieldVisibleId: string,
  userInfo: UserInfoDto | undefined
): ChangeLogDataType => {
  const result: ChangeLogDataType = {};
  const newObj: Record<string, string | undefined> = data.to || {};
  const oldObj: Record<string, string | undefined> = data.from || {};

  const newKeys = Object.keys(newObj);
  const oldKeys = Object.keys(oldObj);

  const allKeys = new Set([...newKeys, ...oldKeys]);

  allKeys.forEach((key) => {
    if (newObj[key] !== oldObj[key]) {
      const fieldSlug = key === "value" ? fieldVisibleId : key;
      const isDateField = dateFields.find((item) => item === fieldSlug);
      result[fieldSlug] = {
        from: {
          value:
            isDateField && oldObj[key]
              ? dayjs(oldObj[key])?.format(userInfo?.dateFormat)
              : oldObj[key],
        },
        to: {
          value:
            isDateField && newObj[key]
              ? dayjs(newObj[key])?.format(userInfo?.dateFormat)
              : newObj[key],
        },
      };
    }
  });
  return result;
};

const LogEntity = ({
  changeLog,
  categories,
  teams,
  fields,
  contacts,
  teamMembers,
  currentUserId,
}: LogEntityProps) => {
  const { userInfo } = useUserInfo();
  const { locale } = useLocale();
  const { t } = useTranslation();

  const isLoading = !userInfo;

  if (isLoading) return <Loader />;

  const author = teamMembers?.find(
    (member) => member.id === changeLog.authorId
  );

  const currentUserIsAuthor = currentUserId === changeLog.authorId;

  const fullName = currentUserIsAuthor
    ? `${author?.firstname as string} ${author?.lastname as string} (${t(
        "pages.contractDetails.changeLog.misc.you"
      )})`
    : `${author?.firstname as string} ${author?.lastname as string}`;

  const date = dayjs(changeLog.createdAt).format(
    `${userInfo?.dateFormat} HH:mm`
  );

  const eventType = changeLog.event;

  const changeLogData = changeLog.data;

  const renderContractCreated = () => {
    return renderContractInitiated();
  };

  const renderContractInitiated = () => {
    return Object.keys(changeLogData).map((key, index) => {
      const item = (
        changeLogData as Record<string, string | Record<string, string>>
      )[key];

      if (fieldsToSkip.includes(key) || !item) return null;

      const field = fields?.find((field) => field.visibleId === key);
      const fieldData = {
        to: item,
      };

      if (key === "categoryId") {
        return (
          <CategoryField
            fieldData={fieldData as ChangeLogDataSimpleItemType}
            categories={categories}
            key={index}
            variant="row"
          />
        );
      }

      if (key === "partnerCompany") {
        return (
          <ContactField
            fieldData={fieldData as ChangeLogDataItemType}
            contacts={contacts}
            key={index}
            variant="row"
          />
        );
      }

      if (key === "tags") {
        return (
          <Tags
            fieldData={fieldData as ChangeLogDataSimpleItemType}
            fieldIcon={<TagIcon />}
            variant="row"
            key={index}
          />
        );
      }
      switch (field?.type) {
        case ContractFieldDto.type.DURATION: {
          return (
            <DurationField
              fieldData={fieldData as ChangeLogDataItemType}
              fieldVisibleId={key}
              userInfo={userInfo}
              variant="row"
              key={index}
            />
          );
        }
        case ContractFieldDto.type.AMOUNT: {
          return (
            <AmountField
              fieldData={fieldData as ChangeLogDataItemType}
              fieldVisibleId={key}
              userInfo={userInfo}
              field={field}
              variant="row"
              key={index}
            />
          );
        }
        case ContractFieldDto.type.DATE: {
          return (
            <DateField
              fieldData={fieldData as ChangeLogDataItemType}
              fieldVisibleId={key}
              fields={fields}
              variant="row"
              key={index}
            />
          );
        }
        default:
          return (
            <SimpleField
              fieldData={fieldData as ChangeLogDataItemType}
              fieldVisibleId={key}
              field={field}
              variant="row"
              key={index}
            />
          );
      }
    });
  };

  const renderContractEdited = () => {
    return Object.keys(changeLogData).map((key, index) => {
      const item = (changeLogData as Record<string, ChangeLogDataType>)[key];
      if (fieldsToSkip.includes(key) || !item) return null;

      const field = fields?.find((field) => field.visibleId === key);

      if (key === "categoryId") {
        return (
          <CategoryField
            fieldData={item as ChangeLogDataSimpleItemType}
            categories={categories}
            key={index}
          />
        );
      }

      if (key === "partnerCompany") {
        return (
          <ContactField fieldData={item} contacts={contacts} key={index} />
        );
      }

      if (key === "tags") {
        return <Tags fieldData={item} fieldIcon={<TagIcon />} key={index} />;
      }

      switch (field?.type) {
        case ContractFieldDto.type.DURATION: {
          return (
            <DurationField
              fieldData={item}
              fieldVisibleId={key}
              userInfo={userInfo}
            />
          );
        }
        case ContractFieldDto.type.AMOUNT: {
          return (
            <AmountField
              fieldData={item}
              fieldVisibleId={key}
              userInfo={userInfo}
              field={field}
            />
          );
        }
        case ContractFieldDto.type.DATE: {
          return (
            <DateField fieldData={item} fieldVisibleId={key} fields={fields} />
          );
        }
        default:
          return (
            <SimpleField fieldData={item} fieldVisibleId={key} field={field} />
          );
      }
    });
  };

  const renderContractMoved = () => {
    const sourceTeamName = teams?.find(
      (team) => team.id === (changeLogData as ContractMovedData).sourceTeamId
    )?.name;
    const targetTeamName = teams?.find(
      (team) => team.id === (changeLogData as ContractMovedData).targetTeamId
    )?.name;
    const data = {
      from: { value: sourceTeamName },
      to: { value: targetTeamName },
    };
    return <EditEntityItem data={data} />;
  };

  const renderSignatureInitiated = () => {
    return Object.keys(changeLogData).map((key) => {
      const item = (changeLogData as SignatureRequestDTO)[
        key as keyof SignatureRequestDTO
      ];
      if (!item || !(item as string[] | RecipientDto[])?.length) return null;

      const fieldName = t(`common.signature.${key}`);

      switch (key) {
        case "recipients": {
          const recipientEmails = (item as RecipientDto[]).map(
            (recipient: RecipientDto) => recipient.email
          );
          const data = {
            to: { value: recipientEmails.join(", ") },
          };
          return (
            <EditEntityItem
              data={data}
              fieldName={fieldName}
              fieldIcon={<ListIcon />}
            />
          );
        }
        case "ccEmails": {
          const data = {
            to: { value: (item as string[]).join(", ") },
          };
          return (
            <EditEntityItem
              data={data}
              fieldName={fieldName}
              fieldIcon={<ListIcon />}
            />
          );
        }
        case "locale": {
          const data = {
            to: { value: t(`common.languages.${item as string}`) },
          };
          return (
            <EditEntityItem
              data={data}
              fieldName={fieldName}
              fieldIcon={<ListIcon />}
            />
          );
        }
        case "eID":
        case "withOrder": {
          const data = {
            to: { value: item ? "Enabled" : "Disabled" },
          };
          return (
            <EditEntityItem
              data={data}
              fieldName={fieldName}
              fieldIcon={<ListIcon />}
            />
          );
        }
        default: {
          return null;
        }
      }
    });
  };

  const renderSignatureSent = () => {
    const data = changeLogData as SignerData;
    const signerInfo = `${data.name} (${data.email})`;
    return <FieldWithIconEntityItem name={signerInfo} variant="signature" />;
  };

  const renderSignatureCancelled = () => {
    const data = changeLogData as SignatureRequestCancelDTO;
    return (
      <FieldWithIconEntityItem name={data.cancelReason} variant="signature" />
    );
  };

  const renderSignatureDeclined = () => {
    const data = changeLogData as SignerDeclinedData;
    const declineInfo = `${data.name} (${data.email}): ${
      data?.declineReason ? data?.declineReason : ""
    } `;
    return <FieldWithIconEntityItem name={declineInfo} variant="signature" />;
  };

  const renderSignatureReminderSent = () => {
    const data = changeLogData as SignerData;
    const signerInfo = `${data.name} (${data.email})`;
    return <FieldWithIconEntityItem name={signerInfo} variant="signature" />;
  };

  const renderSignatureSigned = () => {
    const data = changeLogData as SignerData;
    const signerInfo = `${data.name} (${data.email})`;
    return <FieldWithIconEntityItem name={signerInfo} variant="signature" />;
  };

  const renderTaskAddedRemoved = () => {
    return (
      <FieldWithIconEntityItem
        name={(changeLogData as TaskData).title}
        variant="task"
      />
    );
  };

  const renderDocumentUploadedRemoved = () => {
    return (
      <FieldWithIconEntityItem
        name={(changeLogData as DocumentData).fileName}
      />
    );
  };

  const attachmentUploadedRemoved = () => {
    return (
      <FieldWithIconEntityItem name={(changeLogData as AttachmentData).title} />
    );
  };

  const renderTitleByEventType = () => {
    const author = fullName
      ? fullName
      : t("pages.contractDetails.changeLog.misc.deletedUser");

    let title = "";

    if (availableTranslationsByEventType.includes(eventType)) {
      title = t(`pages.contractDetails.changeLog.entityTitles.${eventType}`, {
        author,
      });
    } else {
      title = t("pages.contractDetails.changeLog.entityTitles.default");
    }
    return <Title>{title}</Title>;
  };

  const renderEntitiyByEventType = () => {
    switch (eventType) {
      case ContractChangeLogDTO.event.CONTRACT_EDITED: {
        return renderContractEdited();
      }
      case ContractChangeLogDTO.event.CONTRACT_INITIATED: {
        return renderContractInitiated();
      }
      case ContractChangeLogDTO.event.CONTRACT_CREATED: {
        return renderContractCreated();
      }
      case ContractChangeLogDTO.event.DOCUMENT_UPLOADED:
      case ContractChangeLogDTO.event.DOCUMENT_REMOVED: {
        return renderDocumentUploadedRemoved();
      }
      case ContractChangeLogDTO.event.CONTRACT_MOVED: {
        return renderContractMoved();
      }
      case ContractChangeLogDTO.event.SIGNATURE_INITIATED: {
        return renderSignatureInitiated();
      }
      case ContractChangeLogDTO.event.SIGNATURE_SENT: {
        return renderSignatureSent();
      }
      case ContractChangeLogDTO.event.SIGNATURE_CANCELLED: {
        return renderSignatureCancelled();
      }
      case ContractChangeLogDTO.event.SIGNATURE_DECLINED: {
        return renderSignatureDeclined();
      }
      case ContractChangeLogDTO.event.SIGNATURE_REMINDER_SENT: {
        return renderSignatureReminderSent();
      }
      case ContractChangeLogDTO.event.SIGNATURE_SIGNED: {
        return renderSignatureSigned();
      }
      case ContractChangeLogDTO.event.CONTRACT_TASK_ADDED:
      case ContractChangeLogDTO.event.CONTRACT_TASK_REMOVED: {
        return renderTaskAddedRemoved();
      }
      case ContractChangeLogDTO.event.ATTACHMENT_ADDED:
      case ContractChangeLogDTO.event.ATTACHMENT_REMOVED:
      case ContractChangeLogDTO.event.MAIN_CONTRACT_CHANGED: {
        return attachmentUploadedRemoved();
      }
      default:
        return "";
    }
  };

  return (
    <Wrapper>
      <Header>
        {renderTitleByEventType()}
        <Date>{date}</Date>
      </Header>
      <Body>{renderEntitiyByEventType()}</Body>
    </Wrapper>
  );
};

export default LogEntity;
