import { useCallback, useEffect, useMemo, useState } from "react";
import { openWindow } from "utils/oauth";
import { AuthProvider } from "./AuthProvider.enum";
import { AuthProviders, parseState } from "./providers";
import {
  generateRandomCodeVerifier,
  calculatePKCECodeChallenge,
  generateRandomState,
} from "oauth4webapi";
import { LimitedIdentityProviderDTO } from "openapi";
import { getOriginWithoutHostname, useHostname } from "hooks/HostnameHook";

export type AuthResponse = {
  code: string;
  redirect: string;
  verifier: string;
};

export const useOAuthAction = (
  provider: AuthProvider,
  data?: LimitedIdentityProviderDTO
) => {
  const hostname = useHostname();
  const [popupWindow, setPopupWindow] = useState<Window>();
  const [actionResult, setActionResult] = useState<AuthResponse>();

  const handleAuthMessage = (code: string, state: string, redirect: string) => {
    if (code) {
      const storedState = localStorage.getItem(`${provider}_state`);
      const storedVerifier = localStorage.getItem(`${provider}_verifier`);

      const parsedState = parseState(state);

      localStorage.removeItem(`${provider}_state`);
      localStorage.removeItem(`${provider}_verifier`);

      if (parsedState.id !== storedState || !storedVerifier) {
        return;
      }

      setActionResult({
        code: code,
        redirect: redirect,
        verifier: storedVerifier,
      });
    }
  };

  const assignLocation = useCallback(() => {
    const assign = async () => {
      if (!popupWindow) {
        return;
      }

      const state = generateRandomState();
      const codeVerifier = generateRandomCodeVerifier();
      const codeChallenge = await calculatePKCECodeChallenge(codeVerifier);
      localStorage.setItem(`${provider}_state`, state);
      localStorage.setItem(`${provider}_verifier`, codeVerifier);

      const client = new AuthProviders[provider](
        state,
        codeChallenge,
        hostname ?? undefined,
        data
      );
      popupWindow.location.replace(await client.getAuthUrl());
    };
    void assign();
  }, [provider, popupWindow]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent<string>) => {
      if (event.source !== popupWindow) {
        return;
      }

      if (
        event.origin !== getOriginWithoutHostname() &&
        event.origin !== window.location.origin
      ) {
        console.error(
          "origin mismatch",
          event.origin,
          getOriginWithoutHostname()
        );
        return;
      }

      try {
        const data = JSON.parse(event.data) as Record<string, string>;
        if (data.code) {
          handleAuthMessage(data.code, data.state, data.redirect);
          popupWindow.close();
          setPopupWindow(undefined);
        }
      } catch (e) {
        console.error(`couldn't parse auth message in ${provider} action`);
      }
    };

    if (popupWindow) {
      void assignLocation();
      window.addEventListener("message", handleMessage);
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [popupWindow]);

  const triggerAuthFlow = useCallback(() => {
    if (popupWindow && !popupWindow.closed) {
      void assignLocation();
      popupWindow.focus();
      return;
    }

    const popup = openWindow();
    if (popup) {
      setPopupWindow(popup);
    }
  }, [popupWindow]);

  const reset = () => {
    setActionResult(undefined);
  };

  return useMemo(() => {
    return {
      provider: provider,
      triggerAuthFlow,
      actionResult,
      reset,
    };
  }, [provider, triggerAuthFlow, actionResult]);
};
