export const hexToRgb = (hex: string) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(
    shorthandRegex,
    function (m, r: string, g: string, b: string) {
      return r + r + g + g + b + b;
    }
  );

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const getContrastColor = (hex: string) => {
  const rgb = hexToRgb(hex);
  if (!rgb) {
    return "#000000";
  }

  if (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 186) {
    return "#000000";
  }
  return "#FFFFFF";
};

function isValidHex(hex: string) {
  return /^#?[0-9A-Fa-f]{6}$/.test(hex);
}

export const getSoftenedColor = (hex: string) => {
  if (!isValidHex(hex)) return;

  hex = hex.replace("#", "");

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const softR = Math.floor((r + 255) / 2);
  const softG = Math.floor((g + 255) / 2);
  const softB = Math.floor((b + 255) / 2);

  const softHex =
    "#" +
    ((1 << 24) + (softR << 16) + (softG << 8) + softB).toString(16).slice(1);

  return softHex;
};

export const getDarkenedColor = (hex: string) => {
  if (!isValidHex(hex)) return;

  const darkMultiplier = 0.3;

  hex = hex.replace("#", "");

  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  r = Math.floor(r * darkMultiplier);
  g = Math.floor(g * darkMultiplier);
  b = Math.floor(b * darkMultiplier);

  const darkHex =
    "#" +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();

  return darkHex;
};
