import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { ApiError, UserService } from "openapi";
import { BaseIdentity } from "./BaseIdentity";
import { IdentityProps, PublicIdentityProps } from "./types";
import { useOAuthAction } from "pages/LoginPage/components/OAuthAction";
import { AuthProvider } from "pages/LoginPage/components/AuthProvider.enum";

export const GoogleIdentity: FC<IdentityProps<PublicIdentityProps>> = ({
  id,
  refetch,
}) => {
  const { t } = useTranslation();
  const { triggerAuthFlow, actionResult } = useOAuthAction(AuthProvider.GOOGLE);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    if (!id) {
      void triggerAuthFlow();
    } else {
      void UserService.disconnectFederatedIdentity("google", id)
        .then(() => {
          refetch();
          enqueueSnackbar(
            t("pages.settings.tabs.security.identities.disconnected"),
            { variant: "success" }
          );
        })
        .catch((err: ApiError) => {
          if (err.status === 403) {
            enqueueSnackbar(
              t("pages.settings.tabs.security.identities.error.forbidden"),
              { variant: "error" }
            );
          }
        });
    }
  };

  useEffect(() => {
    if (!actionResult) {
      return;
    }

    void UserService.connectFederatedIdentity("google", actionResult)
      .then(() => {
        refetch();
        enqueueSnackbar(
          t("pages.settings.tabs.security.identities.connected"),
          { variant: "success" }
        );
      })
      .catch((err: ApiError) => {
        if (err.status === 409) {
          enqueueSnackbar(
            t("pages.settings.tabs.security.identities.error.conflict"),
            { variant: "error" }
          );
        }
      });
  }, [actionResult]);

  return <BaseIdentity provider="google" id={id} onClick={handleClick} />;
};
