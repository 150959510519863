import styled from "@emotion/styled";
import { theme } from "theme";

export const Values = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xxs};
`;

export const Wrapper = styled.div`
  font-size: ${theme.font.size.body};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xxs};
`;

export const ValueIconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  svg {
    transform: rotate(90deg);
    width: 20px;
    height: 20px;
  }
`;
