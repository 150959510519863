import React from "react";
import {
  CategoryOptionWrapper as Wrapper,
  ColorIndicator,
  LabelWrapper,
} from "./styles";

type CategoryOptionTypes = {
  active: boolean;
  label: string;
  color: string;
  onClick: () => void;
};

const CategoryOption = ({
  active,
  label,
  color,
  onClick,
}: CategoryOptionTypes) => {
  return (
    <Wrapper active={active} onClick={onClick}>
      <ColorIndicator color={color} />
      <LabelWrapper>
        <span>{label}</span>
      </LabelWrapper>
    </Wrapper>
  );
};

export default CategoryOption;
