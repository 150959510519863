import styled from "@emotion/styled";
import { Radio } from "@mui/material";
import { theme } from "theme";

const size = "24px";

export const CustomRadio = styled(Radio)`
  margin: ${theme.spacing.sm};

  &.MuiRadio-root {
    color: transparent;
    border: 1px solid ${theme.color.gray[600]};
    width: ${size};
    height: ${size};
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out,
      border 0.25s ease-in-out;
  }

  &:hover {
    border: 1px solid ${theme.color.blue[900]};
    background-color: ${theme.color.blue[600]};
  }

  &.Mui-checked {
    border: 1px solid ${theme.color.blue[900]};
    background-color: ${theme.color.blue[900]};

    svg:nth-of-type(2) {
      fill: ${theme.color.white};
      transform: scale(1.25);
    }
  }

  &.Mui-checked:hover {
    border: 1px solid ${theme.color.blue[600]};
    background-color: ${theme.color.blue[600]};
  }

  &.Mui-disabled {
    color: transparent;
    border: 1px solid ${theme.color.gray[400]};
    background-color: transparent;

    &.Mui-checked {
      background-color: ${theme.color.gray[400]};
    }
  }
`;
