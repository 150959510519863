import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { NumberDatapointDTO, NumberFieldConfiguration } from "openapi";
import { useLocale } from "hooks/GlobalStateHooks";
import { DatapointContent } from "pages/Contract/styles";
import DetailNameWrapper from "components/DetailNameWrapper/DetailNameWrapper";
import { FormNumericField } from "components/FormItems/FormNumeric/FormNumericField";
import { DataPointProps } from "./types";
import { ContactDataDTO } from "../../pages/Contacts/ContactDataDTO";
import { scaleLocaleDecimalNumber } from "utils/helpers";

export const NumberDatapoint: FC<DataPointProps> = ({
  definition,
  contact,
  editable,
}) => {
  const { locale } = useLocale();
  const { control } = useFormContext<ContactDataDTO>();

  const name = definition.name[locale];
  const configuration = definition.configuration as NumberFieldConfiguration;
  const decimalScale = configuration?.decimalScale || 2;

  if (editable) {
    return (
      <Grid item xs={12} md={6}>
        <FormNumericField
          control={control}
          name={`${definition.visibleId}.value.value`}
          label={definition.name[locale]}
          decimalScale={decimalScale}
          fixedDecimalScale={false}
        />
      </Grid>
    );
  }
  const data = contact[definition.visibleId] as NumberDatapointDTO;
  return (
    <Grid item xs={12} md={6}>
      <DetailNameWrapper>{name}</DetailNameWrapper>
      <DatapointContent>
        {data && data?.value?.value
          ? scaleLocaleDecimalNumber(data.value.value, decimalScale, locale)
          : "-"}
      </DatapointContent>
    </Grid>
  );
};
