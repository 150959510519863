import React from "react";
import { Divider as StyledDivider } from "./styles";

type Props = {
  children: JSX.Element | string;
  style?: React.CSSProperties;
};

const Divider = ({ children, ...props }: Props) => (
  <StyledDivider {...props}>{children}</StyledDivider>
);

export default Divider;
