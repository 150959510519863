import React from "react";
import { useCopyToClipboard } from "./hook";
import { DoneIcon, Button, CopyIcon } from "./styles";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export const CopyToClipboardButton = (props: {
  content: string;
  caption?: {
    before?: string;
    after?: string;
  };
}) => {
  const { t } = useTranslation();
  const [setCopyToClipboard, isCopied] = useCopyToClipboard();
  const handleCopyToClipboard = () => void setCopyToClipboard(props.content);

  const textBefore = props.caption?.before;
  const textAfter = props.caption?.after;

  return (
    <Tooltip
      title={isCopied ? t("common.tooltips.copied") : t("common.tooltips.copy")}
      placement="top"
      arrow
      disableInteractive
    >
      <Button type="button" onClick={handleCopyToClipboard}>
        {!isCopied ? (
          <>
            {textBefore && <span>{textBefore}</span>}
            <CopyIcon />
          </>
        ) : (
          <>
            {textAfter && <span>{textAfter}</span>}
            <DoneIcon />
          </>
        )}
      </Button>
    </Tooltip>
  );
};
