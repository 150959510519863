import React, { forwardRef } from "react";
import { Adornment, Background, Container, Text } from "./styles";

export type BaseTagProps = {
  icon?: React.ReactNode;
  backgroundColor: string;
  color?: string;
  children?: string | React.JSX.Element;
  className?: string;
};

const BaseTag = forwardRef<HTMLDivElement, BaseTagProps>(
  ({ backgroundColor, color, icon, children, className, ...props }, ref) => {
    return (
      <Container ref={ref}>
        <Background
          {...props}
          className={className}
          backgroundColor={backgroundColor}
          color={color}
        >
          {icon && <Adornment>{icon}</Adornment>}
          {children ? <Text>{children}</Text> : null}
        </Background>
      </Container>
    );
  }
);
BaseTag.displayName = "BaseTag";

export default BaseTag;
