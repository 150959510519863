import React, { useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CanAccessTeam } from "shared/service/authService";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Popover, Tooltip } from "@mui/material";
import { useUserInfo } from "hooks";
import { useTeam } from "contexts/team/hooks";
import { getInitials } from "contexts/team/helpers";
import { theme } from "theme";
import routePaths from "constants/routePaths";
import { Dropdown as DropdownList } from "./components";
import { Label } from "../../styles";
import { ListItemButton, Icon } from "./styles";

const TeamSelector = ({ visible = false }: { visible: boolean }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { setSelectedTeam, selectedTeam } = useTeam();
  const { teams: organizationsAndTeams, userInfo } = useUserInfo();
  const navigate = useNavigate();

  const selectedTeamName = selectedTeam ? selectedTeam.name : "";
  const tooltipTitle = !visible ? selectedTeamName : "";
  const showDropdown = !(
    organizationsAndTeams &&
    organizationsAndTeams?.length === 1 &&
    !organizationsAndTeams[0]?.children.length
  );

  const handleClick = (event: unknown) => {
    if (!showDropdown) return;
    setAnchorEl((event as MouseEvent<HTMLButtonElement>).currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  const handleSelectTeam = async (teamId: string) => {
    const team = organizationsAndTeams?.find((team) => team.id === teamId);

    if (team && !userInfo?.admin && !CanAccessTeam(team)) {
      enqueueSnackbar(t("pages.forbidden.description"), { variant: "warning" });
      return;
    }

    await setSelectedTeam(teamId);
    navigate(routePaths.HOME, {
      state: {
        force: true,
      },
    });
  };

  return organizationsAndTeams ? (
    <>
      <Tooltip title={tooltipTitle} placement="right" arrow>
        <div>
          <ListItemButton
            visible={visible}
            onClick={handleClick}
            data-cy="teamSelectorButton"
            disableHoverEffect={!showDropdown}
            disableRipple={!showDropdown}
          >
            <Icon visible={visible}>
              <span>{getInitials(selectedTeamName)}</span>
            </Icon>

            <Label visible={visible} bold>
              <span>{selectedTeamName}</span>
            </Label>

            {visible && showDropdown && (
              <ArrowDropDownIcon
                fontSize="small"
                sx={{ color: theme.color.gray[600] }}
              />
            )}
          </ListItemButton>
        </div>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: theme.borderRadius,
            padding: theme.spacing.md,
            boxShadow: theme.shadow.alternative,
            transform: "translate(2px, -12px)",
            backgroundColor: theme.color.white,
            maxHeight: "80vh",
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <DropdownList
          handleSelectTeam={handleSelectTeam}
          handleClose={handleClose}
          selectedTeamId={selectedTeam?.id as string}
          defaultOpenItemId={selectedTeam?.id as string}
          organizationsAndTeams={organizationsAndTeams}
        />
      </Popover>
    </>
  ) : (
    <></>
  );
};

export default TeamSelector;
