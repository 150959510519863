import styled from "@emotion/styled";
import { StyledTextField } from "../../../components/StyledComponents/StyledBaseFields";
import { NumericFormat } from "react-number-format";
import { css } from "@emotion/react";
import { theme } from "../../../theme";

const cellEditorCommonStyle = css`
  width: 100%;
  height: 100%;
  border: none;
  outline: ${theme.color.blue[600]} auto 5px;
  font-size: ${theme.font.size.caption};
  padding-left: 15px;
`;

export const CellEditorField = styled(StyledTextField)`
  .MuiInputBase-root {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

export const TextCellEditor = styled.input`
  ${cellEditorCommonStyle}
  padding-left: ${({ isTitle }: { isTitle: boolean }) =>
    isTitle ? "61px" : "15px"};
`;

export const TextAreaCellEditor = styled.textarea`
  ${cellEditorCommonStyle}
  width: 200px;
`;

export const NumericCellEditor = styled(NumericFormat)`
  ${cellEditorCommonStyle}
`;
