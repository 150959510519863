import React, { useEffect, useState } from "react";
import { Box, Grid, List, ListItem } from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { CategoryDTO } from "openapi";
import { DoughnutChart } from "components/Chart";
import CardCategoryHeaderWrapper from "components/CardCategoryHeaderWrapper/CardCategoryHeaderWrapper";
import CardBodyWrapper from "components/CardCenteredBodyWrapper/CardCenteredBodyWrapper";
import CardWrapper from "../../../components/CardWrapper/CardWrapper";
import {
  CategoryColorBlock,
  CategoryTitle,
  CategoryStatistics,
} from "./styles";

import "overlayscrollbars/overlayscrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useLocale } from "hooks";

type Props = {
  data: { category: CategoryDTO; count: number }[] | undefined;
};

const Categories = ({ data }: Props) => {
  const { locale } = useLocale();
  const [showPercent, setShowPercent] = useState(false);
  const [numberOfContractsWithCategory, setNumberOfContractsWithCategory] =
    useState<number>(0);

  const getStatistics = (counter: number, showPercent: boolean) => {
    if (showPercent) {
      const calculatedPercentage = parseFloat(
        ((counter / numberOfContractsWithCategory) * 100).toFixed(1)
      );
      return `${calculatedPercentage}%`;
    } else {
      return counter;
    }
  };

  useEffect(() => {
    if (data && data?.length > 0) {
      [...data].sort((a, b) => b.count - a.count);
      setNumberOfContractsWithCategory(
        data.map((item) => item.count).reduce((prev, next) => prev + next, 0)
      );
    }
  }, [data]);

  return (
    <CardWrapper>
      <Box>
        <CardCategoryHeaderWrapper
          titleKey="pages.dashboard.categories.title"
          setShowPercent={setShowPercent}
          numberOfContracts={numberOfContractsWithCategory}
          icon={<LocalOfferIcon sx={{ minWidth: "18px" }} />}
        />
        {data && data.length > 0 ? (
          <Grid container sx={{ marginTop: "2rem" }}>
            <Grid item xs={6} alignItems="baseline">
              <DoughnutChart data={data} />
            </Grid>
            <Grid item xs={6} sx={{ position: "relative" }} alignItems="center">
              <OverlayScrollbarsComponent
                defer
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  overflowY: "auto",
                }}
              >
                <List>
                  {data.map((mergedItem, index) => {
                    const { category, count } = mergedItem;
                    const statistics = getStatistics(count, showPercent);

                    return (
                      <ListItem
                        key={index}
                        sx={{ justifyContent: "space-between" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <CategoryColorBlock color={category.color} />
                          <CategoryTitle>{category.name[locale]}</CategoryTitle>
                        </Box>
                        <CategoryStatistics>{statistics}</CategoryStatistics>
                      </ListItem>
                    );
                  })}
                </List>
              </OverlayScrollbarsComponent>
            </Grid>
          </Grid>
        ) : (
          <CardBodyWrapper textKey="pages.dashboard.categories.description" />
        )}
      </Box>
    </CardWrapper>
  );
};

export default Categories;
