import styled from "@emotion/styled";
import theme from "theme/theme";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/overlayscrollbars.css";

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${theme.spacing.md};
  width: 100%;
  flex: 50%;

  ${({ type }: { type?: string }) =>
    type === "secondary" &&
    `
    flex: 25%;
    position: sticky;
    align-self: start;
    max-width: 25%;
  `}
`;

export const ColumnTitle = styled.h3`
  margin: 0 0 ${theme.spacing.lg};
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.semiBold};
  color: ${theme.color.gray[600]};
  line-height: ${theme.lineHeight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Background = styled(OverlayScrollbarsComponent)`
  width: 100%;
  max-height: calc(100vh - 275px);
  overflow-x: hidden;
  overflow-y: auto;
  ${({ white }: { white?: boolean }) =>
    white &&
    `
  background-color: ${theme.color.white};
  padding: ${theme.spacing.lg};
  border-radius: ${theme.borderRadius};
  box-shadow: ${theme.shadow.standard};
`}
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
`;
