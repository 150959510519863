import styled from "@emotion/styled";
import theme from "theme/theme";

export const FieldsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.sm};
  margin: ${theme.spacing.sm} 0;
  padding: ${theme.spacing.lg};
  border-radius: ${theme.borderRadius};
  transition: background-color 0.25s ease-in-out, border-color 0.15s ease-in-out;
  border: 3px dashed ${theme.color.gray[300]};

  ${({ highlighted }: { highlighted?: boolean }) =>
    highlighted &&
    `
    border: 3px dashed ${theme.color.blue[200]};
    background-color: ${theme.color.blue[100]};
  `}
`;
