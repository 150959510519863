import { TFunction } from "i18next";
import { ContactTypeDTO } from "../../openapi";
import { getLocale } from "../../components/AgGridBaseTable/locale";

export const getContactTableLocaleTexts = (t: TFunction<"translation">) => {
  const prefix = "pages.contacts.table";

  return {
    ...getLocale(t),
    noRowsToShow: t(`${prefix}.noRowsToShow`),
    totalAndFilteredRows: t("pages.contracts.messages.totalAndFilteredRows"),
    of: t("pages.contracts.messages.of"),
  };
};

export const getContactType = (
  contactTypes: ContactTypeDTO[],
  typeId: string
) => {
  return contactTypes.find((type) => type.id === typeId);
};
