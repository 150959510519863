import React from "react";
import { Cell } from "../styles";
import { CategoryDTO } from "openapi";
import { useLocale } from "hooks";
import { Control } from "react-hook-form";
import { FormCheckbox } from "components/FormItems";

export type CategoryItemProps = {
  category: CategoryDTO;
  name: string;
  control?: Control;
};

const CategoryItem = ({ control, name, category }: CategoryItemProps) => {
  const { locale } = useLocale();
  return (
    <>
      <Cell>{category.name[locale]}</Cell>
      <Cell>
        <FormCheckbox
          control={control}
          label=""
          name={name}
          aria-label={category.name[locale]}
        />
      </Cell>
    </>
  );
};
export default CategoryItem;
