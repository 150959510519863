/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaskNewCreateDTO } from "../models/TaskNewCreateDTO";
import type { TaskNewDTO } from "../models/TaskNewDTO";
import type { TaskNewUpdateDTO } from "../models/TaskNewUpdateDTO";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class TaskNewService {
  /**
   * Create task.
   * @param organizationId
   * @param teamId
   * @param requestBody
   * @returns TaskNewDTO
   * @throws ApiError
   */
  public static createTask(
    organizationId: string,
    teamId: string,
    requestBody: TaskNewCreateDTO
  ): CancelablePromise<TaskNewDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/teams/{teamId}/tasks",
      path: {
        organizationId: organizationId,
        teamId: teamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get team tasks.
   * @param organizationId
   * @param teamId
   * @returns TaskNewDTO
   * @throws ApiError
   */
  public static getTasks(
    organizationId: string,
    teamId: string
  ): CancelablePromise<Array<TaskNewDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/teams/{teamId}/tasks",
      path: {
        organizationId: organizationId,
        teamId: teamId,
      },
    });
  }

  /**
   * Update task.
   * @param taskId
   * @param organizationId
   * @param teamId
   * @param requestBody
   * @returns TaskNewDTO
   * @throws ApiError
   */
  public static updateTask(
    taskId: string,
    organizationId: string,
    teamId: string,
    requestBody: TaskNewUpdateDTO
  ): CancelablePromise<TaskNewDTO> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/{organizationId}/teams/{teamId}/tasks/{taskId}",
      path: {
        taskId: taskId,
        organizationId: organizationId,
        teamId: teamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Delete a task.
   * @param taskId
   * @param organizationId
   * @param teamId
   * @returns TaskNewDTO
   * @throws ApiError
   */
  public static deleteTask(
    taskId: string,
    organizationId: string,
    teamId: string
  ): CancelablePromise<TaskNewDTO> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/organizations/{organizationId}/teams/{teamId}/tasks/{taskId}",
      path: {
        taskId: taskId,
        organizationId: organizationId,
        teamId: teamId,
      },
    });
  }

  /**
   * Get task.
   * @param taskId
   * @param organizationId
   * @param teamId
   * @returns TaskNewDTO
   * @throws ApiError
   */
  public static getTask(
    taskId: string,
    organizationId: string,
    teamId: string
  ): CancelablePromise<TaskNewDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/teams/{teamId}/tasks/{taskId}",
      path: {
        taskId: taskId,
        organizationId: organizationId,
        teamId: teamId,
      },
    });
  }

  /**
   * Get contract tasks.
   * @param teamId
   * @param contractId
   * @param organizationId
   * @returns TaskNewDTO
   * @throws ApiError
   */
  public static getTasksByContract(
    teamId: string,
    contractId: string,
    organizationId: string
  ): CancelablePromise<Array<TaskNewDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/teams/{teamId}/contracts/{contractId}/tasks",
      path: {
        teamId: teamId,
        contractId: contractId,
        organizationId: organizationId,
      },
    });
  }
}
