import styled from "@emotion/styled";
import aiIcon from "assets/svg/ai.svg?react";
import { theme } from "theme";

export const Wrapper = styled.div`
  margin: 0 ${theme.spacing.lg} ${theme.spacing.lg};

  .verify-all-banner {
    background-color: ${theme.color.purple[100]};
    border-radius: 16px;
  }
`;

export const AiIcon = styled(aiIcon)`
  color: ${theme.color.purple[900]};
`;

export const DoNotShowOption = styled.div`
  margin-top: ${theme.spacing.lg};
  display: flex;
  align-items: center;

  span {
    padding-left: 0;
  }
`;
