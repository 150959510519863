import { TFunction } from "i18next";
import { TeamDTOV1 } from "openapi";

export const getNameWithSuffix = (
  roleName: string,
  teamId: string,
  teams: TeamDTOV1[],
  t: TFunction
) => {
  let localizedRoleName = roleName;

  if (
    localizedRoleName.toLowerCase() === "owner" ||
    localizedRoleName.toLowerCase() === "user"
  ) {
    localizedRoleName = t(`pages.settings.tabs.team.roleOptions.${roleName}`);
  }

  const team = teams.find((team) => team.id === teamId);

  if (!team) return `${localizedRoleName} - ${t("common.labels.deletedTeam")}`;

  return `${localizedRoleName} - (${team.name})`;
};
