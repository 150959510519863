import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { ApiError, ContractService, ImportDataDTO } from "openapi";
import { IField } from "@flatfile/adapter/build/main/interfaces";
import ImportViaCSV from "components/FlatFileImporter/client";
import { CTAButton } from "components";
import { useTeam } from "contexts/team/hooks";
import { ForbiddenResponse } from "shared/types/ErrorResponse.type";
import ImportViaEmail from "./ImportViaEmail";
import ImportViaPdf from "./ImportViaPdf";
import { FooterComponent } from "../GuideModal";
import { Features } from "constants/features";
import { useNavigate } from "react-router-dom";
import {
  ModalContent,
  List,
  ListItem,
  ItemButton,
  Header,
  BackButton,
} from "../styles";
import { Tooltip } from "@mui/material";
import { fillImportFields } from "components/FlatFileImporter/client/importUtils";
import { getCurrentTeams } from "components/FlatFileImporter/client/helpers";
import {
  useFieldsQuery,
  useContactTypesQuery,
  useOrganizationCategoriesQuery,
  useContactDataPointsDefinitionsQuery,
} from "shared/api";
import { useLocale, useUserInfo } from "hooks";
import ExcelCard from "components/ExcelCard/ExcelCard";
import { dateFormats } from "constants/utils";

type Props = {
  handleClose: () => void;
  setSteps: (val: string[]) => void;
  steps: string[];
  fetchData: () => Promise<void>;
  handleOnCSVData: (status: ImportDataDTO) => void;
};

const ImportSteps = ({
  handleClose,
  setSteps,
  steps,
  handleOnCSVData,
}: Props) => {
  const [currentStep, setCurrentStep] = useState(steps[steps.length - 1]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { teams, userInfo } = useUserInfo();
  const navigate = useNavigate();
  const {
    selectedTeam,
    selectedTeamId,
    organizationId: migratedOrganizationId,
    parentTeamId,
  } = useTeam();
  const { data: fields } = useFieldsQuery(migratedOrganizationId);
  const userDateFormat = userInfo?.dateFormat || dateFormats.EU_DOTS;

  const { data: categories } = useOrganizationCategoriesQuery(
    migratedOrganizationId
  );
  const { data: contactFields } =
    useContactDataPointsDefinitionsQuery(parentTeamId);
  const { data: contactTypes } = useContactTypesQuery(parentTeamId);
  const [sheetsFields, setSheetsFields] = useState<IField[]>();
  const [config, setConfig] = useState({});

  const goBack = () => {
    if (steps.length === 2) {
      setSteps(["main"]);
    }

    const res = steps.filter((stepName) => stepName !== currentStep);
    setSteps(res);
    setCurrentStep("import-contracts");
  };

  const updateStep = (name: string) => {
    setSteps([...steps, name]);
    setCurrentStep(name);
  };

  const createEmptyContract = () => {
    try {
      ContractService.createEmptyContract(selectedTeamId)
        .then((res) => {
          handleClose();
          navigate(`/contracts/${res}`);
        })
        .catch((error) => {
          console.error(error);
        });

      enqueueSnackbar(
        t("pages.contractEdit.forms.information.contractCreated"),
        {
          variant: "success",
        }
      );
    } catch (error) {
      if (!(error instanceof ApiError)) return;
      if (
        error.status === 403 &&
        (error.body as ForbiddenResponse).message === "contract limit reached"
      ) {
        enqueueSnackbar(t("contractUpload.validation.maxContractLimit"), {
          variant: "error",
        });
      }
    }
  };

  const isCSVAvailable = selectedTeam?.features?.includes(Features.IMPORT_CSV);
  const isEmailImportAvailable = selectedTeam?.features?.includes(
    Features.IMPORT_VIA_EMAIL
  );

  useEffect(() => {
    if (categories && fields && teams) {
      void fillImportFields(
        selectedTeamId,
        parentTeamId,
        categories,
        fields,
        getCurrentTeams(teams, selectedTeamId),
        setSheetsFields,
        locale,
        t,
        userDateFormat,
        contactFields
      );
    }
  }, [categories, fields, teams, contactFields, contactTypes]);

  useEffect(() => {
    setConfig({
      categories,
      fields,
      selectedTeamId,
      migratedOrganizationId,
      contactFields,
      contactTypes,
    });
  }, [categories, fields, contactFields, contactTypes, selectedTeamId]);

  return (
    <>
      <ModalContent>
        {currentStep === "import-contracts" && (
          <Header>
            <Typography id="modal-title" variant="h5" component="h1">
              {t("guide.steps.second.title")}
            </Typography>
            <br />
            <Typography
              id="modal-subtitle1"
              variant="subtitle1"
              component="span"
            >
              {t("guide.steps.second.subtitle1")}
            </Typography>
            <br />
            <br />
            <Typography
              id="modal-subtitle2"
              variant="subtitle1"
              component="span"
            >
              {t("guide.steps.second.subtitle2")}
            </Typography>
          </Header>
        )}

        {currentStep === "import-contracts" && (
          <List>
            <ListItem>
              <Tooltip
                title={t("guide.steps.second.options.importViaPDFDescription")}
              >
                <ItemButton onClick={() => updateStep("import-via-pdf")}>
                  <ArrowRightAltIcon
                    fontSize="small"
                    sx={{ marginRight: ".5rem" }}
                  />
                  <div>{t("guide.steps.second.options.importViaPDF")}</div>
                </ItemButton>
              </Tooltip>
            </ListItem>

            {isCSVAvailable && (
              <ListItem>
                <Tooltip
                  title={t(
                    "guide.steps.second.options.importViaCSVDescription"
                  )}
                >
                  <ItemButton onClick={() => updateStep("import-via-csv")}>
                    <ArrowRightAltIcon
                      fontSize="small"
                      sx={{ marginRight: ".5rem" }}
                    />
                    <div>{t("guide.steps.second.options.importViaCSV")}</div>
                  </ItemButton>
                </Tooltip>
              </ListItem>
            )}

            {isEmailImportAvailable && (
              <ListItem>
                <Tooltip
                  title={t(
                    "guide.steps.second.options.importViaEmailDescription"
                  )}
                >
                  <ItemButton onClick={() => updateStep("import-via-email")}>
                    <ArrowRightAltIcon
                      fontSize="small"
                      sx={{ marginRight: ".5rem" }}
                    />
                    <div>{t("guide.steps.second.options.importViaEmail")}</div>
                  </ItemButton>
                </Tooltip>
              </ListItem>
            )}

            <ListItem>
              <Tooltip
                title={t(
                  "guide.steps.second.options.importWithOutFileDescription"
                )}
              >
                <ItemButton onClick={() => createEmptyContract()}>
                  <ArrowRightAltIcon
                    fontSize="small"
                    sx={{ marginRight: ".5rem" }}
                  />
                  <div>{t("guide.steps.second.options.importWithOutFile")}</div>
                </ItemButton>
              </Tooltip>
            </ListItem>
          </List>
        )}

        {currentStep === "import-via-pdf" && (
          <div style={{ margin: "1rem" }}>
            <ImportViaPdf handleClose={handleClose} />
          </div>
        )}

        {currentStep === "import-via-csv" && (
          <ExcelCard subtitle={t("pages.import.subtitle")}>
            <>
              <div>
                <ul className="exportList">
                  <li>{t("pages.import.bulletpoints.first")}</li>
                  <li>{t("pages.import.bulletpoints.second")}</li>
                  <li>{t("pages.import.bulletpoints.third")}</li>
                </ul>
              </div>

              <ImportViaCSV
                id="onboarding-import-contracts-id"
                fields={sheetsFields}
                config={config}
                onResult={handleOnCSVData}
                renderComponent={({ onClick }) => (
                  <CTAButton
                    label="import-via-csv-button"
                    name={t("pages.import.importCSVFile")}
                    size="large"
                    type="submit"
                    variant="primary"
                    onClick={onClick}
                  />
                )}
              />
            </>
          </ExcelCard>
        )}

        {currentStep === "import-via-email" && (
          <div style={{ margin: "1rem" }}>
            <ImportViaEmail />
          </div>
        )}
      </ModalContent>

      <FooterComponent
        handleClose={handleClose}
        backButton={
          <BackButton onClick={goBack}>
            <ArrowCircleLeftIcon
              fontSize="small"
              sx={{ marginRight: ".5rem" }}
            />
            {t("common.buttons.back")}
          </BackButton>
        }
      />
    </>
  );
};

export default ImportSteps;
