import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ButtonWithArrow, Container } from "components";
import routePaths from "../../constants/routePaths";
import { ButtonWrapper, Content } from "../styles";

const UserSettingsLayout = () => {
  const navigate = useNavigate();

  return (
    <Container
      style={{ display: "flex", height: "calc(100vh - 64px)", width: "100%" }}
    >
      <>
        <ButtonWrapper>
          <ButtonWithArrow
            orientation="left"
            label="Dashboard"
            onClick={() => navigate(routePaths.HOME)}
          />
        </ButtonWrapper>

        <Content maxWidth={false}>
          <Outlet />
        </Content>
      </>
    </Container>
  );
};

export default UserSettingsLayout;
