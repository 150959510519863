import React, { useEffect, forwardRef, useMemo } from "react";
import { useParams, unstable_useBlocker, useNavigate } from "react-router-dom";
import {
  FormProvider,
  useForm,
  UseFormSetError,
  useWatch,
} from "react-hook-form";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { t } from "i18next";
import {
  CategoryDTO,
  ContractDTOV1,
  ContractFieldDTOV1,
  ContractInputDTOV1,
  ContractNameDto,
  TeamPermissionDto,
} from "openapi";

import { dateFormatter } from "constants/utils";
import { useTeam } from "contexts/team/hooks";
import { useLocale, useUserInfo } from "hooks/GlobalStateHooks";
import { setValidationErrors } from "shared/service/errorResponseService";
import {
  useContactDataPointsDefinitionsQuery,
  useContactTypesQuery,
  useContactsQuery,
} from "shared/api";

import { UnsavedChangesModal } from "components";
import { usePrintContext } from "contexts/contract/context";
import TagsSection from "./TagsSection/TagsSection";
import Section from "./Section";
import HeaderSection from "./HeaderSection";
import { getChangedValues } from "./helpers";
import { Footer, SaveButton, StickyCard } from "./styles";
import { useOrganizationCategoriesQuery } from "shared/api/organization/categories";
import { categoriesTeamSelector } from "shared/api/organization/categories.helpers";
import { useUpdateContractMutation } from "shared/api/contracts";
import { CircularProgress } from "@mui/material";

type Props = {
  isLoading: boolean;
  contract: ContractDTOV1;
  category: CategoryDTO;
  fields: ContractFieldDTOV1[];
  fetchData: (loadFromStash?: boolean | undefined) => Promise<void>;
  contractGroup: ContractNameDto[];
  isContractDataFetching?: boolean;
};

const ContractData = forwardRef<ReactJSXElement, Props>(
  (
    {
      contract,
      category,
      fields,
      contractGroup,
      fetchData,
      isContractDataFetching,
    }: Props,
    ref
  ) => {
    const { locale } = useLocale();
    const { id, mode } = useParams();
    const {
      selectedTeamId,
      parentTeamId,
      organizationId,
      hasWriteAccess,
      isLimitedUser,
      permissionSet,
    } = useTeam();
    const { userInfo } = useUserInfo();
    const userDateFormat = userInfo?.dateFormat;

    const editable = mode === "edit";

    const { componentRef } = usePrintContext();

    const navigate = useNavigate();

    const methods = useForm({
      defaultValues: { ...contract, fields: {} },
      resolver: undefined,
    });
    const {
      formState: { dirtyFields },
    } = methods;
    const { enqueueSnackbar } = useSnackbar();

    const { data: categories } = useOrganizationCategoriesQuery(
      organizationId,
      (categories) =>
        categories.filter((category) =>
          categoriesTeamSelector(category, contract.teamId)
        )
    );

    const updateContract = useUpdateContractMutation();

    const { data: contactTypes } = useContactTypesQuery(parentTeamId);
    const { data: contactDefinitions } =
      useContactDataPointsDefinitionsQuery(parentTeamId);
    const { data: contacts, refetch: refetchContacts } = useContactsQuery(
      parentTeamId,
      selectedTeamId
    );
    const selectedCategoryId = useWatch({
      name: "categoryId",
      control: methods.control,
    });

    const getMainContractCategoryId = () =>
      contractGroup?.find((item) => item.parentId === null)?.categoryId;
    const setContractForm = () => {
      if (!contract) {
        return;
      }
      const formData = {
        ...contract,
      };
      const formFields = methods.getValues("fields");
      for (const fieldId of Object.keys(formFields)) {
        if (!formData.fields[fieldId]) {
          const definition = fields.find(
            (definition) => definition.id === fieldId
          );
          if (!definition) {
            continue;
          }
          if (definition.type === ContractFieldDTOV1.type.AMOUNT) {
            formData.fields[fieldId] = { value: null, currency: "EUR" };
            continue;
          }
          if (definition.type === ContractFieldDTOV1.type.DURATION) {
            formData.fields[fieldId] = {
              type: null,
              startDate: null,
              endDate: null,
              terminationDate: null,
              automaticRenewal: null,
              interval: null,
              noticePeriod: null,
            };
            continue;
          }
          formData.fields[fieldId] = { value: null };
        }
      }
      methods.reset(formData);
    };

    useEffect(() => {
      setContractForm();
    }, [contract]);

    useEffect(() => {
      updateContract.reset();
    }, [editable]);

    const selectedCategory = useMemo(() => {
      return (
        categories?.find((item) => item.id === selectedCategoryId) || category
      );
    }, [selectedCategoryId, categories]);

    // the navigation should block if...
    // 1) the form contains dirty fields
    // 2) the form was not recently submitted and the submit succeeded (isSuccess)
    const isBlocked =
      Object.keys(dirtyFields).length > 0 && !updateContract.isSuccess;
    const blocker = unstable_useBlocker(isBlocked);

    const handleOnSubmit = async (values: ContractDTOV1) => {
      await handleEditContract(values, async () => {
        await fetchData();
        navigate("..", { relative: "path" });
      });
    };

    const handleEditContract = async (
      values: ContractDTOV1,
      action: () => void
    ) => {
      if (id && contract) {
        try {
          const changedValues = getChangedValues(
            methods.formState.dirtyFields,
            values
          );
          //TODO: recheck it.
          const changedCustomFields = methods.formState.dirtyFields["fields"];
          if (changedCustomFields)
            for (const [key] of Object.entries(changedCustomFields)) {
              const customField = fields?.find(
                (item) =>
                  item.id === key &&
                  (item.type === "AMOUNT" || item.type === "DURATION")
              );
              if (customField) {
                if (customField.type === "DURATION") {
                  (changedValues as ContractInputDTOV1).fields = {
                    ...(changedValues as ContractInputDTOV1).fields,
                    [key]: {
                      type: null,
                      startDate: null,
                      endDate: null,
                      terminationDate: null,
                      automaticRenewal: null,
                      interval: null,
                      noticePeriod: null,
                      // eslint-disable-next-line @typescript-eslint/unbound-method
                      ..._.omitBy(values.fields[key], _.isUndefined),
                    },
                  };
                } else {
                  (changedValues as ContractInputDTOV1).fields = {
                    ...(changedValues as ContractInputDTOV1).fields,
                    [key]: values.fields[key],
                  };
                }
              }
            }

          await updateContract.mutateAsync({
            teamId: contract.teamId,
            contractId: id,
            requestBody: {
              ...changedValues,
              categoryId: selectedCategoryId,
            },
          });

          enqueueSnackbar(
            t("pages.contractEdit.forms.information.successfulChanges"),
            { variant: "success" }
          );
          action();
        } catch (e) {
          const errorConverter: UseFormSetError<never> = (name, error) => {
            methods.setError(name, error);
          };
          setValidationErrors(
            e,
            errorConverter,
            "pages.contractEdit.forms.generalForm",
            undefined,
            enqueueSnackbar,
            t
          );
        }
      }
    };

    const getSelectableCategories = () => {
      if (!isLimitedUser()) {
        return categories?.filter((c) => c.teams.includes(contract?.teamId));
      }
      if (contract) {
        const categoryIds = permissionSet?.[contract.teamId]?.permissions
          ?.filter(
            (permission) => permission.level === TeamPermissionDto.level.WRITE
          )
          ?.map((item) => item.categoryId);
        return categories?.filter(
          (item) =>
            categoryIds?.includes(item.id) &&
            item.teams.includes(contract.teamId)
        );
      }
    };

    const handleCloseModal = (shouldResetBlocker?: boolean) => {
      if (shouldResetBlocker) {
        blocker.reset?.();
      }
    };

    const handleDiscardUnsavedChanges = () => {
      blocker.proceed?.();
      setContractForm();
    };

    const handleSaveChanges = () => {
      void handleEditContract(methods.getValues(), () => {
        if (blocker?.state === "blocked") {
          blocker.proceed();
        }
        void fetchData();
      });
    };

    return (
      <div ref={componentRef}>
        <FormProvider {...methods}>
          <form
            name="contractEditForm"
            onSubmit={methods.handleSubmit(handleOnSubmit)}
          >
            <HeaderSection
              categories={getSelectableCategories()}
              contractTeamId={contract.teamId}
              editable={editable}
              contract={contract}
              category={category}
              fetchData={fetchData}
              getMainContractCategoryId={getMainContractCategoryId}
            />

            <TagsSection
              contract={contract}
              hasWriteAccess={
                editable && hasWriteAccess(contract.categoryId, selectedTeamId)
              }
            />

            {selectedCategory.sections.map((section) => (
              <Section
                key={section.id}
                section={section}
                contract={contract}
                fields={fields}
                editable={editable}
                isContractDataFetching={isContractDataFetching}
                fetchData={fetchData}
                contactTypes={contactTypes ?? []}
                contactDefinitions={contactDefinitions ?? []}
                contacts={contacts ?? []}
                refetchContacts={refetchContacts}
              />
            ))}

            {editable &&
              (Object.keys(dirtyFields).length > 0 ||
                updateContract.isSuccess) && (
                <StickyCard>
                  <SaveButton
                    id="saveBtn"
                    type="submit"
                    disabled={updateContract.isLoading}
                  >
                    {updateContract.isLoading ? (
                      <CircularProgress size="1em" color="inherit" />
                    ) : null}
                    {t("pages.contractEdit.forms.saveButton")}
                  </SaveButton>
                </StickyCard>
              )}
          </form>
        </FormProvider>

        <Footer>
          <div>
            {`${t("pages.contractDetails.footer.createdBy")} ${
              contract.createdBy
            } - ${dateFormatter(locale, contract.createdAt, userDateFormat)}`}
          </div>
          <div>
            {`${t("pages.contractDetails.footer.updatedAt")} ${dateFormatter(
              locale,
              contract.updatedAt,
              userDateFormat
            )}`}
          </div>
        </Footer>

        <UnsavedChangesModal
          title={t("pages.contractDetails.modals.unsavedChanges.title")}
          loading={updateContract.isLoading}
          description={t(
            "pages.contractDetails.modals.unsavedChanges.subtitle"
          )}
          showModal={!!blocker && blocker.state === "blocked"}
          handleCloseModal={handleCloseModal}
          handleDiscardChanges={handleDiscardUnsavedChanges}
          handleSaveChanges={handleSaveChanges}
        />
      </div>
    );
  }
);

ContractData.displayName = "ContractData";

export default ContractData;
