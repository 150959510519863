import {
  LimitedAuthMethodsDTO,
  LimitedIdentityProviderDTO,
  TeamDescriptionDto,
  TokenResponseDto,
} from "openapi";
import jwt_decode from "jwt-decode";

const LOCAL_ACCESS_TOKEN = "access_token";
const LOCAL_REFRESH_TOKEN = "refresh_token";

export function SaveTokens(tokenResponseDto: TokenResponseDto) {
  localStorage.setItem(LOCAL_ACCESS_TOKEN, tokenResponseDto.accessToken);
  localStorage.setItem(LOCAL_REFRESH_TOKEN, tokenResponseDto.refreshToken);
}

export function ClearTokens() {
  localStorage.removeItem(LOCAL_ACCESS_TOKEN);
  localStorage.removeItem(LOCAL_REFRESH_TOKEN);
}

export function GetAccessToken() {
  return localStorage.getItem(LOCAL_ACCESS_TOKEN);
}

export function GetRefreshToken() {
  return localStorage.getItem(LOCAL_REFRESH_TOKEN);
}

export function GetDecodedAccessToken() {
  return jwt_decode<{
    providerData: {
      providerType: "password" | "google" | "microsoft" | "oidc";
      providerId?: string;
    };
  }>(GetAccessToken() ?? "");
}

function CheckAccessByMethods(authMethods: LimitedAuthMethodsDTO) {
  const decodedToken = GetDecodedAccessToken();
  const method =
    authMethods[
      decodedToken.providerData.providerType as keyof LimitedAuthMethodsDTO
    ];

  if (decodedToken.providerData.providerId) {
    return (
      (method as LimitedIdentityProviderDTO | null)?.id ===
      decodedToken.providerData.providerId
    );
  }
  return !!method;
}

export function CanAccessTeam(team: TeamDescriptionDto) {
  if (CheckAccessByMethods(team.authMethods)) return true;
  if (team.delegationAuthMethods) {
    return CheckAccessByMethods(team.delegationAuthMethods);
  }
  return false;
}
