import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import {
  CategoryDTO,
  TeamDTO,
  TeamDTOV1,
  TeamInvitationRequestDto,
} from "openapi";
import { FormTextField, Loader } from "components";
import { FormSelect } from "components/FormItems/FormSelect/FormSelect";
import Permissions from "../Permissions/Permissions";
import { FormType } from "./types";
import { NoPermissions } from "./styles";

export type RoleFormProps = {
  onSubmit: () => void;
  isEditMode: boolean;
  teams?: TeamDTOV1[] | TeamDTO[];
  selectedTeamId?: string;
  categories: CategoryDTO[];
  currentRoleId?: string;
};

export const ROLE_FORM_ID = "role-form";

const RoleFormModal = ({
  onSubmit,
  isEditMode,
  selectedTeamId,
  teams,
  categories,
  currentRoleId,
}: RoleFormProps) => {
  const { t } = useTranslation();
  const [isLoading] = useState(false);

  const { handleSubmit, control, setValue } = useFormContext<FormType>();

  const defaultValue = teams?.length === 1 ? teams[0]?.id : "";
  const isTeamSelectionDisabled = isEditMode || !!defaultValue;

  useEffect(() => {
    defaultValue && setValue("teamId", defaultValue);
  }, []);

  if (isEditMode) if (isLoading || !currentRoleId) return <Loader />;

  return (
    <form
      noValidate
      id={ROLE_FORM_ID}
      onSubmit={handleSubmit(onSubmit)}
      data-testid="role-form"
    >
      <FormTextField
        control={control}
        name="roleName"
        required
        label={t("pages.settings.tabs.team.teamRoles.modals.create.name")}
      />
      {teams && (
        <FormSelect
          control={control}
          name="teamId"
          label={t(
            "pages.settings.tabs.team.teamRoles.modals.create.teamSelect"
          )}
          value={defaultValue}
          disabled={isTeamSelectionDisabled}
          options={teams.map((team) => ({
            key: team?.id,
            value: team?.name,
          }))}
          required
        />
      )}

      {selectedTeamId ? (
        <Permissions
          control={control}
          selectedRole={TeamInvitationRequestDto.role.LIMITED_USER}
          isMemberLimitReached={false}
          categories={categories}
        />
      ) : (
        <NoPermissions>
          {t(
            "pages.settings.tabs.team.teamRoles.modals.create.noSelectedTeamMessage"
          )}
        </NoPermissions>
      )}
    </form>
  );
};

export default RoleFormModal;
