import { useCallback, useState } from "react";
import { ContractDTOV1, ContractFieldDTOV1 } from "openapi";
import { DataPointWithoutMetadata } from "../types";

export type WrapFunction<T extends ContractDTOV1["fields"][string]> = (
  slug: keyof DataPointWithoutMetadata<T>,
  field: JSX.Element
) => JSX.Element | undefined;

export type AnalysisHookData<T extends ContractDTOV1["fields"][string]> = {
  definition: ContractFieldDTOV1;
  data: T;
  selectedSuggestion: DataPointWithoutMetadata<T>;
  setSuggestion: (
    key: keyof DataPointWithoutMetadata<T>,
    value: string | number | null | undefined
  ) => void;
  unsetSuggestion: (key: keyof DataPointWithoutMetadata<T>) => void;
  displayConverters?: Partial<
    Record<
      keyof DataPointWithoutMetadata<T>,
      (value: string) => string | null | undefined
    >
  >;
  dataConverters?: Partial<
    Record<
      keyof DataPointWithoutMetadata<T>,
      (value: string) => string | null | undefined
    >
  >;
};

export const useAnalysis = <T extends ContractDTOV1["fields"][string]>(
  definition: ContractFieldDTOV1,
  data: T,
  displayConverters?: Partial<
    Record<
      keyof DataPointWithoutMetadata<T>,
      (value: string) => string | null | undefined
    >
  >,
  dataConverters = displayConverters
): AnalysisHookData<T> => {
  const [selectedSuggestion, setSelectedSuggestion] = useState(
    {} as DataPointWithoutMetadata<T>
  );

  const setSuggestion = useCallback(
    (
      key: keyof DataPointWithoutMetadata<T>,
      value: string | number | null | undefined
    ) => {
      setSelectedSuggestion((prev) => {
        if (key in prev && prev[key] === value) {
          return prev;
        }
        const updatedState = { ...prev };
        updatedState[key] = value as T[keyof DataPointWithoutMetadata<T>];
        return updatedState;
      });
    },
    [setSelectedSuggestion]
  );

  const unsetSuggestion = useCallback(
    (key: keyof DataPointWithoutMetadata<T>) => {
      setSelectedSuggestion((prev) => {
        const updatedState = { ...prev };
        if (key in updatedState) {
          delete updatedState[key];
          return updatedState;
        }
        return prev;
      });
    },
    [setSelectedSuggestion]
  );

  return {
    definition,
    data,
    setSuggestion,
    unsetSuggestion,
    selectedSuggestion,
    displayConverters,
    dataConverters,
  };
};
