import styled from "@emotion/styled";
import { theme } from "theme";

export const Name = styled.span`
  display: flex;
  align-items: center;
  color: ${theme.color.gray[700]};
  font-weight: ${theme.font.weight.semiBold};

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
