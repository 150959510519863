import React, { useEffect, useState } from "react";
import BasicTable from "components/BasicTable/BasicTable";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { useTranslation } from "react-i18next";
import { AdminService } from "openapi";
import { Header, Row } from "components/BasicTable/types";
import { Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { theme } from "theme";
import {
  parseAmount,
  parseDate,
  parseInterval,
  parsePaymentCycle,
  parsePaymentType,
} from "@contracthero/common";
import * as Sentry from "@sentry/react";

type AnnotationDataItem = {
  startDate: {
    text: string;
  };
  endDate: {
    text: string;
  };
  interval: {
    text: string;
  };
  value: {
    text: string;
  };
};

const AnnotationData = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<Row[]>([]);

  const handleDelete = async (id: string) => {
    await AdminService.deleteAnnotatedFieldData(id);
    await fetchData();
  };

  const fetchData = async () => {
    const fields = await AdminService.getAnnotatedFields();
    const data = fields.map((field) => {
      let annotationData = JSON.stringify(field.annotatedData);
      const item = field.annotatedData as AnnotationDataItem;
      if (field.visibleId === "duration") {
        annotationData = "";
        if (item.startDate) {
          let startDate = "";
          try {
            startDate = parseDate(item.startDate.text) || "";
          } catch (e) {
            Sentry.captureException(e);
          }
          startDate = startDate.substring(0, Math.min(10, startDate.length));
          annotationData += `startDate: ${startDate} `;
        }
        if (item.endDate) {
          let endDate = "";
          try {
            endDate = parseDate(item.endDate.text) || "";
          } catch (e) {
            Sentry.captureException(e);
          }
          endDate = endDate.substring(0, Math.min(10, endDate.length));
          annotationData += `endDate: ${endDate} `;
        }
        if (item.interval) {
          try {
            annotationData += `interval: ${
              parseInterval(item.interval.text) || ""
            } `;
          } catch (e) {
            Sentry.captureException(e);
          }
        }
      } else if (field.visibleId === "partnerCompany") {
        if (item.value) {
          annotationData = item.value.text;
        }
      } else if (field.visibleId === "paymentPriceNet") {
        if (item.value) {
          let result;
          try {
            result = parseAmount(item.value.text);
          } catch (e) {
            Sentry.captureException(e);
          }
          annotationData = (result || "").toString();
        }
      } else if (field.visibleId === "paymentCycle") {
        if (item.value) {
          let result;
          try {
            result = parsePaymentCycle(item.value.text);
          } catch (e) {
            Sentry.captureException(e);
          }
          annotationData = (result || "").toString();
        }
      } else if (field.visibleId === "paymentType") {
        if (item.value) {
          let result;
          try {
            result = parsePaymentType(item.value.text);
          } catch (e) {
            Sentry.captureException(e);
          }
          annotationData = (result || "").toString();
        }
      }
      const annotationElement = (
        <Tooltip
          title={<pre>{JSON.stringify(field.annotatedData, null, 2)}</pre>}
        >
          <div>{annotationData}</div>
        </Tooltip>
      );
      return {
        visibleId: field.visibleId,
        annotationData: annotationElement,
        actions: (
          <DeleteIcon
            fontSize="small"
            sx={{
              color: "black",
              ":hover": { cursor: "pointer" },
              margin: theme.spacing.sm,
            }}
            onClick={() => handleDelete(field.id)}
          />
        ),
      };
    });
    setData(data);
  };

  useEffect(() => {
    void fetchData();
  }, []);

  const header: Header[] = [
    {
      key: "visibleId",
      name: t("pages.admin.tabs.annotationData.name").toUpperCase(),
    },
    {
      key: "annotationData",
      name: t("pages.admin.tabs.annotationData.team").toUpperCase(),
    },
    {
      key: "actions",
      name: t("pages.admin.tabs.annotationData.actions").toUpperCase(),
    },
  ];

  return (
    <CardWrapper>
      <>
        <CardHeaderWrapper
          title={t("pages.admin.tabs.categoryMappings.title")}
        />
        <BasicTable headers={header} data={data} />
      </>
    </CardWrapper>
  );
};

export default AnnotationData;
