import styled from "@emotion/styled";
import { Alert, FormHelperText, InputAdornment } from "@mui/material";
import { theme } from "theme";

export const Section = styled.div`
  margin: ${theme.spacing.xxl} 0;
`;

export const PageWrapper = styled.div`
  max-width: 655px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 30% auto;
  grid-column-gap: 50px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${theme.spacing.xl};

  .MuiCheckbox-root {
    padding: 0;
  }
  .MuiFormControl-root {
    margin: 0;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
`;

export const TextLink = styled.button`
  padding: 0;
  border: none;
  background: none;
  border: none;
  color: ${theme.color.blue[600]};
  cursor: pointer;
  font-size: ${theme.font.size.body};

  :hover {
    text-decoration: underline;
  }
`;

export const FormHint = styled(FormHelperText)`
  margin-top: -${theme.spacing.lg};
`;

export const CheckboxLabel = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
`;

export const AlertWrapper = styled(Alert)`
  margin-bottom: ${theme.spacing.xl};
`;

export const SectionSubtitle = styled.h4`
  margin: 0 0 ${theme.spacing.xs};
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.medium};
`;

export const SectionText = styled.p`
  margin: 0;
  font-size: ${theme.font.size.body};
  margin-bottom: ${theme.spacing.xl};
`;

export const InputAdornmentWrapper = styled(InputAdornment)`
  display: flex;
  gap: ${theme.spacing.sm};
`;
