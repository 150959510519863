import * as Sentry from "@sentry/react";
import {
  ApiError,
  OrganizationNewService,
  OrganizationUpdateDTO,
} from "openapi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { getErrorKey } from "utils/helpers";

export const useOrganizationQuery = (id?: string) => {
  return useQuery({
    queryKey: ["organizations", id],
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return await OrganizationNewService.getOrganization(id!);
    },
    enabled: !!id,
  });
};

export const useOrganizationsQuery = () => {
  return useQuery({
    queryKey: ["organizations"],
    queryFn: async () => {
      return await OrganizationNewService.getOrganizationsOfUser();
    },
  });
};

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: {
      organizationId: string;
      requestBody: OrganizationUpdateDTO;
    }) => {
      return OrganizationNewService.updateOrganization(
        data.organizationId,
        data.requestBody
      );
    },
    onSuccess: async (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["organization", variables.organizationId],
      });
      await queryClient.refetchQueries({
        queryKey: ["organization", variables.organizationId],
      });

      enqueueSnackbar(
        t(
          "pages.settings.tabs.team-security.delegationSettings.messages.success"
        ),
        { variant: "success", autoHideDuration: 6000 }
      );
    },
    onError: (error: ApiError) => {
      Sentry &&
        Sentry.captureMessage(
          `Something went wrong while trying to update delegationId: ${JSON.stringify(
            error
          )}`
        );

      if (!(error instanceof ApiError)) return;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const errorType = error?.body?.type;
      const errorKey = errorType ? getErrorKey(errorType as string) : "default";
      const errorMessage = t(
        `pages.settings.tabs.team-security.delegationSettings.messages.error.${errorKey}`
      );
      enqueueSnackbar(errorMessage, {
        variant: "error",
        autoHideDuration: 6000,
      });
    },
  });
};
