import { CategoryDTO } from "openapi";
import { Language } from "shared/i18n/i18n";
import { theme } from "theme";

/**
 * Note: Similar to Chip colors but,
 * this one is 1 level brighter than the default.
 * */
export const getHoverBackgroundColor = (hexCode: string) => {
  if (!hexCode) {
    return theme.color.blue[400];
  }

  switch (hexCode.toLocaleLowerCase()) {
    case "#e2e8f0":
      return theme.color.gray[400];
    case "#feb2b2":
      return theme.color.red[400];
    case "#fbd38d":
      return theme.color.orange[400];
    case "#faf089":
      return theme.color.yellow[400];
    case "#9ae6b4":
    case "#38a169":
      return theme.color.green[400];
    case "#90cdf4":
      return theme.color.blue[400];
    case "#a3bffa":
      return theme.color.indigo[400];
    case "#d6bcfa":
      return theme.color.purple[400];
    case "#fbb6ce":
      return theme.color.pink[400];
    case "#7edce2":
      return theme.color.teal[400];
    case "#ddff99":
      return theme.color.mindaro[400];
    case "#a6f296":
      return theme.color.lightGreen[400];
    case "#e595e5":
      return theme.color.plum[400];
    case "#ff9999":
      return theme.color.salmonPink[400];
    case "#fbc49d":
      return theme.color.peach[400];
    default:
      return theme.color.blue[400];
  }
};

interface MergedDataItem {
  count: number;
  backgroundColor: string;
  hoverBackgroundColor: string;
}

export function transformChartData(
  data: { category: CategoryDTO; count: number }[],
  locale: Language
) {
  const mergedData: Record<string, MergedDataItem> = {};
  data.forEach((item) => {
    const categoryName = item.category.name[locale];

    // eslint-disable-next-line no-prototype-builtins
    if (mergedData.hasOwnProperty(categoryName)) {
      mergedData[categoryName].count += item.count;
    } else {
      mergedData[categoryName] = {
        count: item.count,
        backgroundColor: item.category.color,
        hoverBackgroundColor: getHoverBackgroundColor(item.category.color),
      };
    }
  });

  const labels = Object.keys(mergedData);
  const countData = Object.values(mergedData).map((item) => item.count);
  const backgroundColors = Object.values(mergedData).map(
    (item) => item.backgroundColor
  );
  const hoverBackgroundColors = Object.values(mergedData).map(
    (item) => item.hoverBackgroundColor
  );

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: countData,
        backgroundColor: backgroundColors,
        hoverBackgroundColor: hoverBackgroundColors,
      },
    ],
  };

  return chartData;
}
