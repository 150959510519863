import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import {
  ContactTypeDTO,
  ContactDatapointDefinitionDTO,
  TextDatapointDTO,
  OrganizationService,
} from "openapi";
import { NewModal } from "components";
import * as api from "shared/api";
import { useTeam } from "contexts/team/hooks";
import { ContactDataDTO } from "../../../Contacts/ContactDataDTO";
import Footer from "./Footer";
import Body from "./Body";
import { merge } from "lodash";

type Props = {
  contact: ContactDataDTO;
  open: boolean;
  onClose: () => void;
  contactType?: ContactTypeDTO;
  contactDataPointDefinitions: ContactDatapointDefinitionDTO[];
};

export const getOptions = (
  optionsWithoutCurrentContact: ContactDataDTO[] | undefined
) => {
  const options = optionsWithoutCurrentContact
    ?.map((contact) => ({
      key: contact.id,
      value:
        ((contact?.name as TextDatapointDTO)?.value?.value as string) || "",
    }))
    .sort((a, b) => a.value.localeCompare(b.value));
  return options;
};

const MergeContactModal = ({
  contact,
  open,
  onClose,
  contactType,
  contactDataPointDefinitions,
}: Props) => {
  const {
    selectedTeamId,
    parentTeamId: organizationId,
    organizationId: migratedOrganizationId,
  } = useTeam();
  const { t } = useTranslation();
  const [selectedContactId, setSelectedContactId] = useState<string>();
  const methods = useForm();
  const { control, handleSubmit } = useForm();
  const [showPreview, setShowPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewContactData, setPreviewContactData] = useState<
    ContactDataDTO | undefined
  >(contact);
  const { refetch: refetchContracts } = api.useContractsQuery(selectedTeamId);
  const { data: contacts } = api.useContactsQuery(
    organizationId,
    selectedTeamId
  );
  const { data: fields } = api.useFieldsQuery(migratedOrganizationId);
  const { mutateAsync: updateContact } = api.useUpdateContactMutation();
  const deleteContactMutation = api.useDeleteContactMutation();
  const [conflictResolverSide, setConflictResolverSide] = useState("");

  const optionsWithoutCurrentContact = contacts?.filter(
    (c) => c.id !== contact.id
  );

  const options = getOptions(optionsWithoutCurrentContact);

  const selectedContactData = contacts?.find(
    (c: ContactDataDTO) => c.id === selectedContactId
  );

  const handleConflictResolver = (selectedSide: string) => {
    if (selectedSide === "right") {
      const mergedContact = merge({}, contact, selectedContactData);
      setPreviewContactData(mergedContact);
      setConflictResolverSide("right");
    } else {
      const mergedContact = merge({}, selectedContactData, contact);
      setPreviewContactData(mergedContact);
      setConflictResolverSide("left");
    }
  };

  const handleConflictResolverRadioButtons = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedSide = e?.target?.value;
    handleConflictResolver(selectedSide);
  };

  const handleReset = () => {
    handleConflictResolver("left");
    setSelectedContactId("");
    setPreviewContactData(undefined);
    setShowPreview(false);
  };

  const handleOnClose = () => {
    handleReset();
    onClose();
  };

  const onSubmit = async () => {
    if (!organizationId || !previewContactData || !selectedContactId) return;
    try {
      setLoading(true);

      const requestBody = {
        ...previewContactData,
      } as Partial<ContactDataDTO>;

      // Note: Removed as the props are not part of the request body
      delete requestBody?.createdAt;
      delete requestBody?.updatedAt;
      delete requestBody?.contractsNumber;
      delete requestBody?.editable;

      await updateContact({
        organizationId,
        contactId: contact.id,
        requestBody: requestBody,
      });

      // @Note: Before cleaning up the merged contact,
      // we need to update the contracts that use this contact.
      const contracts = await OrganizationService.getContractsByContact(
        selectedContactId,
        organizationId,
        selectedTeamId
      );

      for (const contract of contracts) {
        const partnerField = fields?.find(
          (field) => field.visibleId === "partnerCompany"
        );

        if (!partnerField) return;

        await OrganizationService.updateContract(contract.teamId, contract.id, {
          fields: {
            [partnerField?.id]: { value: contact.id },
          },
        });
      }

      await deleteContactMutation.mutateAsync({
        organizationId,
        contactId: selectedContactId,
      });

      await refetchContracts();

      enqueueSnackbar(t("pages.contacts.update.successMessage"), {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(t("pages.contacts.update.errorMessage"), {
        variant: "error",
      });
      console.error(error);
    } finally {
      setLoading(false);
    }

    handleOnClose();
  };

  useEffect(() => {
    // @Note: Initialize default preview data from the left contact.
    if (!conflictResolverSide || conflictResolverSide === "left") {
      handleConflictResolver("left");
    } else {
      handleConflictResolver("right");
    }
  }, [selectedContactData]);

  return (
    <NewModal
      fullWidth
      maxWidth="lg"
      open={open}
      handleClose={handleOnClose}
      title={t("pages.contacts.modal.merge.title")}
      icon={<CallMergeIcon />}
      body={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} id="merge-contracts-form">
            <Body
              control={control}
              options={options ?? []}
              setSelectedContactId={setSelectedContactId}
              showPreview={showPreview}
              previewContactData={previewContactData}
              contactType={contactType ?? ({} as ContactTypeDTO)}
              contactDataPointDefinitions={contactDataPointDefinitions}
              contact={contact}
              selectedContactData={selectedContactData}
            />
          </form>
        </FormProvider>
      }
      footer={
        <Footer
          defaultConflictResolverSide={conflictResolverSide}
          handleConflictResolver={handleConflictResolverRadioButtons}
          selectedContactId={selectedContactId as string}
          showPreview={showPreview}
          control={control}
          onClose={handleOnClose}
          loading={loading}
          setShowPreview={setShowPreview}
          selectedContactData={selectedContactData ?? ({} as ContactDataDTO)}
        />
      }
    />
  );
};

export default MergeContactModal;
