import React, { useEffect } from "react";
import { Box, Button, Link } from "@mui/material";
import { UserService } from "../../openapi";
import {
  Link as RouterLink,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import * as Yup from "yup";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setValidationErrors } from "../../shared/service/errorResponseService";
import { useStyles } from "../../components/StyledComponents/StyledBaseButtons";
import { FormTextField } from "components";
import { useTranslation } from "react-i18next";
import routePaths from "../../constants/routePaths";
import { UseFormSetError } from "react-hook-form/dist/types/form";
import { passwordValidator } from "../../constants/utils";
import { Language } from "../../shared/i18n/i18n";
import { useLocale } from "../../hooks";
import { useSnackbar } from "notistack";

type Prop = {
  alertElementSetter: React.Dispatch<
    React.SetStateAction<
      | React.ReactElement<
          unknown,
          string | React.JSXElementConstructor<unknown>
        >
      | undefined
    >
  >;
  alertKeySetter: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const validationSchema = () =>
  Yup.object({
    token: Yup.string().required(),
    password: passwordValidator(),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "pages.register.validation.passwordConfirmation"
    ),
  });

const initialValues = {
  token: "",
  password: "",
  passwordConfirmation: "",
};

function createErrorSetter<T extends FieldValues>(
  setError: UseFormSetError<T>,
  alertKeySetter: Prop["alertKeySetter"],
  alertElementSetter: Prop["alertElementSetter"]
): UseFormSetError<T> {
  return (name, errorOption) => {
    setError(name, errorOption);
    alertKeySetter(errorOption.message);
    alertElementSetter(
      <Link component={RouterLink} to={routePaths.FORGOT_PASSWORD} />
    );
  };
}

const ResetPasswordForm = ({ alertElementSetter, alertKeySetter }: Prop) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { changeLocale } = useLocale();

  let token = new URLSearchParams(location.search).get("resetPasswordToken");

  if (!token) {
    token = new URLSearchParams(location.search).get("token");
  }

  const lang = new URLSearchParams(location.search).get("lang");

  useEffect(() => {
    if (lang) {
      void changeLocale(lang as Language);
    }
  }, []);

  const { control, handleSubmit, setError } = useForm({
    defaultValues: { ...initialValues, token: token ?? "" },
    resolver: yupResolver(validationSchema()),
  });

  const onSubmit = async (values: typeof initialValues) => {
    const { passwordConfirmation, ...apiModel } = values;

    try {
      await UserService.resetPassword({ ...apiModel });
      navigate(routePaths.LOGIN);
    } catch (e) {
      const setApiErrors = createErrorSetter(
        setError,
        alertKeySetter,
        alertElementSetter
      );
      setValidationErrors(
        e,
        setApiErrors,
        "pages.resetPassword",
        undefined,
        enqueueSnackbar,
        t
      );
    }
  };

  return !token ? (
    <Navigate to={routePaths.FORGOT_PASSWORD} />
  ) : (
    <form
      name="newPasswordForm"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      style={{
        padding: "0 5rem",
        margin: "2.5rem 0",
      }}
    >
      <FormTextField
        control={control}
        name="password"
        type="password"
        required
        label={t("pages.resetPassword.form.password")}
      />
      <FormTextField
        control={control}
        name="passwordConfirmation"
        type="password"
        required
        label={t("pages.resetPassword.form.passwordConfirm")}
      />
      <Box sx={{ mt: 3 }}>
        <Button
          id="resetButton"
          type="submit"
          size="large"
          className={classes.baseButton}
        >
          {t("pages.resetPassword.form.submit")}
        </Button>
      </Box>
    </form>
  );
};

export default ResetPasswordForm;
