// @TODO: Slowly migrate to the new modal component
import React from "react";
import { Breakpoint, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";

export enum ModalResultEnum {
  DELETE,
  SAVE,
  EDIT,
  CANCEL,
}

const BootstrapDialog = styled(Dialog)<{ fullWidth: boolean }>(
  ({ theme, fullWidth }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: fullWidth ? "" : "30rem",
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  })
);

interface DialogTitleProps {
  id: string;
  dialogTitle?: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { t } = useTranslation();
  const { dialogTitle, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {dialogTitle && <Box sx={{ maxWidth: "80%" }}>{t(dialogTitle)}</Box>}
      {onClose ? (
        <IconButton
          id="closeButton"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            zIndex: 1,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

type Props = {
  open: boolean;
  children: JSX.Element;
  dialogTitle?: string;
  isShowHeader?: boolean;
  isShowActionButtons?: boolean;
  baseButtonTextKey?: string;
  baseButtonActionType?: ModalResultEnum;
  baseButtonClassName?: string;
  extraButtonTextKey?: string;
  extraButtonActionType?: ModalResultEnum;
  extraButtonClassName?: string;
  fullWidth?: boolean;
  maxWidth?: false | Breakpoint | undefined;
  handleClose?: (action?: ModalResultEnum, values?: unknown) => void;
};

const Modal = ({
  open,
  children,
  dialogTitle,
  isShowHeader = true,
  isShowActionButtons = true,
  baseButtonTextKey,
  baseButtonActionType,
  baseButtonClassName,
  extraButtonTextKey,
  extraButtonActionType,
  extraButtonClassName,
  fullWidth = false,
  maxWidth,
  handleClose,
}: Props) => {
  const { t } = useTranslation();

  if (!open) return null;

  const handleModalClose = (
    _event: React.ReactEventHandler,
    reason: string
  ) => {
    if (reason !== "backdropClick") {
      handleClose && handleClose();
    }
  };

  const handleActionModalClose = (
    action?: ModalResultEnum,
    values?: unknown
  ) => {
    handleClose && handleClose(action, values);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleModalClose}
        aria-labelledby="dialog-title"
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        {isShowHeader && (
          <BootstrapDialogTitle
            id="customized-dialog-title"
            dialogTitle={dialogTitle}
            onClose={handleClose}
          />
        )}
        <DialogContent>{children}</DialogContent>
        {isShowActionButtons && (
          <DialogActions sx={{ backgroundColor: "#f8f9fa" }}>
            <Button
              id="modalExtraBtn"
              className={extraButtonClassName}
              onClick={() => handleActionModalClose(extraButtonActionType)}
            >
              {t(extraButtonTextKey ?? "")}
            </Button>
            <Button
              id="modalBaseBtn"
              className={baseButtonClassName}
              onClick={() => handleActionModalClose(baseButtonActionType)}
            >
              {t(baseButtonTextKey ?? "")}
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  );
};

export default Modal;
