import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { theme } from "theme";

const iconSize = "24px";

export const TagWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};

  svg {
    fill: transparent;
  }
`;

export const CellContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NameLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};

  .icon-container {
    width: ${iconSize};
    display: flex;
    align-items: center;
  }
`;

export const AttachmentIconTiled = styled(AttachmentIcon)`
  transform: rotate(45deg);
`;
