import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 10% 90%;
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  transition: background-color 0.2s ease-in-out;
  border-radius: ${theme.borderRadius};
  margin-bottom: ${theme.spacing.xxs};
  transition: transform 0.2s ease-in-out;

  ${({ even }: { even?: boolean }) =>
    even &&
    `
    background-color: ${theme.color.gray[200]}99;
  `}

  :hover {
    cursor: pointer;
    opacity: 0.85;
    transform: translateX(${theme.spacing.xxs});
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: ${theme.spacing.sm} 0;

  svg {
    fill: ${theme.color.blue[300]};
    font-size: 32px;
  }
`;

export const Description = styled.div`
  font-size: ${theme.font.size.body};
  padding: ${theme.spacing.sm};
`;

export const MainFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.xxs} ${theme.spacing.xs};
`;

export const TimeDetails = styled.div`
  font-size: ${theme.font.size.caption};
  font-weight: ${theme.font.weight.semiBold};
  color: ${theme.color.blue[600]};
  padding: ${theme.spacing.xs};
  border-radius: ${theme.borderRadius};
`;
