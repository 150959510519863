import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ContractNameDto,
  ContractDTOV1,
  ContractAttachmentService,
} from "openapi";
import { useTeam } from "contexts/team/hooks";
import { Box, Button } from "@mui/material";
import { useStyles as useStyledButtons } from "components/StyledComponents/StyledBaseButtons";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Modal, { ModalResultEnum } from "components/Modal/Modal";
import ContractDeletePDFModalContent from "pages/Contract/Modals/DeletePDF";
import { Features } from "constants/features";
import {
  Attachment,
  LinkContract,
  Signature,
  EditButton,
  FullscreenToggle,
  Dropdown,
} from "./components";
import DownloadPDF from "./components/DownloadPDF/DownloadPDF";
import { DeleteContractButton } from "..";
import { Wrapper } from "./styles";
type Props = {
  contractFile?: Blob | undefined | null;
  fetchData: (loadFromStash?: boolean | undefined) => Promise<void>;
  contract: ContractDTOV1;
  fetchPdf: (loadFromStash?: boolean | undefined) => Promise<void>;
  contractHasPDFFile: boolean;
  contractGroup: ContractNameDto[];
  editable: boolean;
  setContractFile: (contractFile: Blob | undefined) => void;
};

const Toolbar = ({
  fetchData,
  contract,
  contractFile,
  contractGroup,
  contractHasPDFFile,
  fetchPdf,
  editable,
  setContractFile,
}: Props) => {
  const { t } = useTranslation();
  const { hasWriteAccess, hasFeature } = useTeam();
  const buttonClasses = useStyledButtons();
  const isESignatureActive = hasFeature(Features.ESIGNATURE);
  const isRequestSignatureDisabled =
    (!contractHasPDFFile && isESignatureActive) ||
    contract?.status === ContractDTOV1.status.SIGNING ||
    contract.signature?.status === ContractDTOV1.status.SIGNING ||
    contract.signature?.status === "DECLINED" ||
    contract.signature?.status === "SIGNED";
  const showRequestSignature =
    isESignatureActive && contract.signature?.status !== "completed";

  const [showDeletePDFContractModal, setShowDeletePDFContractModal] =
    useState(false);

  const handleContractModalToDeletePDF = () => {
    setShowDeletePDFContractModal(true);
  };

  const handleContractModalToDeletePDFClose = async (
    action?: ModalResultEnum
  ) => {
    if (action === ModalResultEnum.DELETE) {
      await ContractAttachmentService.deleteFileFromContract(contract.id);
      setContractFile(undefined);
    }

    setShowDeletePDFContractModal(false);
  };

  if (editable) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
          }}
        >
          {contractHasPDFFile && (
            <Button
              className={buttonClasses.editHeaderDeleteButton}
              onClick={handleContractModalToDeletePDF}
              sx={{ textTransform: "none" }}
            >
              <DeleteForeverIcon fontSize="small" />
              {t("pages.contractEdit.buttons.deletePdf")}
            </Button>
          )}
        </Box>
        <Modal
          open={showDeletePDFContractModal}
          isShowHeader={false}
          baseButtonTextKey="pages.contractEdit.modals.deleteContractPDF.buttons.delete"
          baseButtonActionType={ModalResultEnum.DELETE}
          extraButtonTextKey="pages.contractEdit.modals.deleteContractPDF.buttons.cancel"
          extraButtonActionType={ModalResultEnum.CANCEL}
          baseButtonClassName={buttonClasses.deleteButton}
          extraButtonClassName={buttonClasses.cancelButton}
          handleClose={handleContractModalToDeletePDFClose}
        >
          <ContractDeletePDFModalContent />
        </Modal>
      </>
    );
  }

  return (
    <Wrapper>
      {showRequestSignature && (
        <Signature
          hasPDF={contractHasPDFFile}
          disabled={isRequestSignatureDisabled}
          fetchData={fetchData}
        />
      )}

      {hasWriteAccess(contract.categoryId, contract.teamId) ? (
        <>
          <Attachment
            fetchData={fetchData}
            contractData={contract}
            contractGroup={contractGroup}
            fetchPdf={fetchPdf}
          />
          <LinkContract
            selectedContract={contract}
            contractGroup={contractGroup}
            fetchData={fetchData}
          />
          <FullscreenToggle contractFile={contractFile} />
          <DownloadPDF contractFile={contractFile} contract={contract} />
          <EditButton contractData={contract} />
          <DeleteContractButton
            contractData={contract}
            contractGroup={contractGroup}
          />
          <Dropdown />
        </>
      ) : (
        <>
          <FullscreenToggle contractFile={contractFile} />
          <DownloadPDF contractFile={contractFile} contract={contract} />
        </>
      )}
    </Wrapper>
  );
};

export default Toolbar;
