import React from "react";
import { Box, Grid } from "@mui/material";
import BaseBodyWrapper from "components/BaseBodyWrapper/BaseBodyWrapper";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import ExcelIcon from "assets/svg/excel.react.svg?react";

type ExcelCardInput = {
  children: JSX.Element;
  title?: string;
  subtitle: string;
};

const ExcelCard = ({ title, subtitle, children }: ExcelCardInput) => {
  return (
    <BaseBodyWrapper titleKey={title}>
      <Box sx={{ padding: "1rem 0" }}>
        <CardHeaderWrapper title={subtitle} />
        <Grid container spacing={5}>
          <Grid item sm={12} md={6}>
            {children}
          </Grid>
          <Grid item sm={12} md={6}>
            <ExcelIcon />
          </Grid>
        </Grid>
      </Box>
    </BaseBodyWrapper>
  );
};

export default ExcelCard;
