/* eslint-disable @typescript-eslint/unbound-method */
import { ToolbarProps, ToolbarSlot } from "@react-pdf-viewer/default-layout";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ToolbarButtonContainer, ToolbarContainer } from "./toolbar/styles";
import { OCRSearchPopoverProps } from "./search/OCRSearchPopover";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import { ToolbarButton } from "./toolbar/buttons/ToolbarButton";
import { DownloadButton } from "./toolbar/buttons/DownloadButton";
import { PrintButton } from "./toolbar/buttons/PrintButton";
import { RotateButton } from "./toolbar/buttons/RotateButton";
import { FullscreenButton } from "./toolbar/buttons/FullscreenButton";
import { ZoomOutButton } from "./toolbar/buttons/ZoomOutButton";
import { ZoomInButton } from "./toolbar/buttons/ZoomInButton";
import { AdditionalActionsButton } from "./toolbar/buttons/AdditionalActionsButton";

export const getToolbarRenderFunction = (
  showOCR: boolean,
  hasOCR: boolean,
  onClickShowOCR: () => void,
  OCRSearchPopover: (
    props: Omit<OCRSearchPopoverProps, "store" | "onSearchChanged">
  ) => ReactElement
) => {
  return function RenderToolbar(
    Toolbar: (props: ToolbarProps) => ReactElement
  ) {
    const { t } = useTranslation();
    return (
      <Toolbar>
        {(slots: ToolbarSlot) => {
          const {
            CurrentPageInput,
            Download,
            EnterFullScreen,
            NumberOfPages,
            Print,
            Zoom,
            ZoomIn,
            Rotate,
            ZoomOut,
          } = slots;

          return (
            <ToolbarContainer>
              {hasOCR && (
                <>
                  <ToolbarButton
                    onClick={() => {
                      onClickShowOCR();
                    }}
                    highlighted
                    tooltip={t("pdfViewer.toolbar.displayOCR")}
                  >
                    <FormatShapesIcon />
                  </ToolbarButton>
                  <ToolbarButtonContainer>
                    <OCRSearchPopover
                      ocrEnabled={showOCR}
                      enableOCR={onClickShowOCR}
                    />
                  </ToolbarButtonContainer>
                </>
              )}
              <ToolbarButtonContainer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  <CurrentPageInput /> / <NumberOfPages />
                </div>
              </ToolbarButtonContainer>
              <ZoomOutButton Slot={ZoomOut} />
              <ToolbarButtonContainer>
                <Zoom />
              </ToolbarButtonContainer>
              <ZoomInButton Slot={ZoomIn} />
              <RotateButton Slot={Rotate} collapses />
              <FullscreenButton Slot={EnterFullScreen} collapses />
              <DownloadButton Slot={Download} collapses />
              <PrintButton Slot={Print} collapses />
              <AdditionalActionsButton>
                <RotateButton Slot={Rotate} collapses collapsed />
                <FullscreenButton Slot={EnterFullScreen} collapses collapsed />
                <DownloadButton Slot={Download} collapses collapsed />
                <PrintButton Slot={Print} collapses collapsed />
              </AdditionalActionsButton>
            </ToolbarContainer>
          );
        }}
      </Toolbar>
    );
  };
};
