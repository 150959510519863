import { ContractDTOV1 } from "openapi";

export const isUUID = (input: string) => {
  const regex = /^[a-z,0-9,-]{36,36}$/;
  return regex.test(input);
};

export const countVerificationStatus = (contract: ContractDTOV1) => {
  const fields = contract?.fields;

  if (!fields) return;

  let verifiedCount = 0;
  let unverifiedCount = 0;

  Object.values(fields).forEach((field) => {
    if (field.metadata) {
      Object.values(field.metadata).forEach(({ unverified }) =>
        unverified ? unverifiedCount++ : verifiedCount++
      );
    }
  });

  return { verified: verifiedCount, unverified: unverifiedCount };
};
