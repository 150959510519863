import React from "react";
import { Wrapper } from "./styles";

type Props = {
  style?: React.CSSProperties;
  children: React.ReactNode;
};

const PaperContainer = ({ children, style, ...props }: Props) => (
  <Wrapper {...props} style={style}>
    {children}
  </Wrapper>
);

export default PaperContainer;
