import { ColDef, ValueGetterParams } from "ag-grid-community";
import { Language } from "shared/i18n/i18n";
import dayjs from "dayjs";
import { ContactDatapointDefinitionDTO, ContractFieldDTOV1 } from "openapi";
import { dateFormatter } from "constants/utils";
import { CellEditors } from "components/Datapoints/DataPoint";
import { LinkCellRenderer } from "./components";
import { getCountryName } from "./helpers";
import { adjustColumnDefs } from "../../../../contexts/grid/default";
import { TFunction } from "i18next";
import { ContactDataDTO, ContactDatapointDTOs } from "../../ContactDataDTO";
import { countryCellRenderer } from "pages/Contracts/helpers";
import { getCountriesOptions } from "utils/internationalizations";
import { CustomCellEditorType } from "pages/Contracts/CellEditors/CustomCellEditor";
import { getDateColumnFilter } from "./helpers";

const defaultColumnWidth = 160;

const primarySortFieldVisibleId = "name";

const filterByType: Record<ContractFieldDTOV1.type, string> = {
  TEXTFIELD: "agTextColumnFilter",
  TEXTAREA: "agTextColumnFilter",
  NUMBER: "agNumberColumnFilter",
  LIST: "agSetColumnFilter",
  LINK: "agTextColumnFilter",
  DATE: "agDateColumnFilter",
  AMOUNT: "agNumberColumnFilter",
  COUNTRY: "agSetColumnFilter",
  DURATION: "agSetColumnFilter",
  FORMULA: "agNumberColumnFilter",
  CONTACT: "agSetColumnFilter",
};

export const getColumnDataConfig = (
  definitions: ContactDatapointDefinitionDTO[],
  locale: Language,
  t: TFunction<"translation">,
  userDateFormat: string
) => {
  const columns: ColDef<ContactDataDTO>[] = [
    {
      field: "id",
      headerName: t("common.headers.counterParty.id"),
      getQuickFilterText: () => "",
      editable: false,
      hide: true,
    },
  ];
  columns.push(
    ...definitions.map((definition) => {
      const column: ColDef<ContactDataDTO> = {
        colId: definition.visibleId,
        headerName: definition.name[locale],
        field: `${definition.visibleId}.value`,
        sort:
          definition.visibleId === primarySortFieldVisibleId
            ? "asc"
            : undefined,
        sortIndex:
          definition.visibleId === primarySortFieldVisibleId ? 0 : undefined,
        sortable: true,
        filter: filterByType[definition.type],
        minWidth: defaultColumnWidth,
        editable: (params) => params.data?.editable ?? false,
        cellClass: (params) => (params.data?.editable ? "editable-cell" : ""),
        cellEditor: CellEditors[definition.type],
        cellEditorParams: {
          isCustomField: true,
          type: CustomCellEditorType[definition.type],
          translationKey: definition.visibleId,
          cellRenderer:
            definition.type === "COUNTRY"
              ? countryCellRenderer(locale)
              : undefined,
          values:
            definition.type === "COUNTRY" ? getCountriesOptions(locale) : [],
        },
        valueGetter: (params: ValueGetterParams) => {
          if (!(params.data as ContactDataDTO)[definition.visibleId]) {
            return "";
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
          return params.data[definition.visibleId]?.value?.value;
        },
        valueSetter: (params) => {
          if (!params.data[definition.visibleId]) {
            params.data[definition.visibleId] = {} as ContactDatapointDTOs;
          }
          (params.data[definition.visibleId] as ContactDatapointDTOs).value =
            params.newValue as ContactDatapointDTOs["value"];
          return true;
        },
        width: defaultColumnWidth,
      };

      if (definition.type === "COUNTRY") {
        return {
          ...column,
          cellRenderer: ({ value }: { value: string }) => {
            return getCountryName(value, locale);
          },
        };
      }

      if (definition.type === "TEXTFIELD") {
        return {
          ...column,
          cellRenderer: LinkCellRenderer,
        };
      }

      if (definition.type === "DATE") {
        return {
          ...column,
          filterParams: {
            comparator: (dateFromFilter: Date, cellValue: string) => {
              getDateColumnFilter(dateFromFilter, cellValue);
            },
          },
        };
      }

      return column;
    })
  );

  columns.push({
    colId: "contractsNumber",
    field: "contractsNumber",
    headerName: t("pages.contacts.table.columnNames.contractsNumber"),
    cellRenderer: LinkCellRenderer,
    sortable: true,
    filter: filterByType.NUMBER,
    editable: false,
    width: defaultColumnWidth,
  });

  columns.push({
    colId: "createdAt",
    field: "createdAt",
    filter: filterByType.DATE,
    headerName: t("pages.contacts.table.columnNames.createdAt"),
    sortable: true,
    hide: true,
    editable: false,
    width: defaultColumnWidth,
    cellRenderer: ({ value }: { value: dayjs.Dayjs }) =>
      dateFormatter(locale, value, userDateFormat),
  });
  const orderedColumnsFromStart = { id: -3, name: -2, contractsNumber: -1 };
  return adjustColumnDefs(columns, orderedColumnsFromStart);
};
