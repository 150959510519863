import { Header } from "components/BasicTable/types";
import { TFunction } from "i18next";
import { PermissionChangeLogDTO, TeamMembersDto } from "openapi";
import { theme } from "theme";

export const DELETED_CATEGORY_COLOR = theme.color.gray[200];

export const getTableHeaders = (t: TFunction<"translation">) => {
  return [
    {
      key: "when",
      name: t(
        "pages.settings.organization.permissionChangeLog.table.headings.when"
      ),
    },
    {
      key: "who",
      name: t(
        "pages.settings.organization.permissionChangeLog.table.headings.who"
      ),
    },
    {
      key: "action",
      name: t(
        "pages.settings.organization.permissionChangeLog.table.headings.action"
      ),
      notSortable: true,
    },
  ] as Header[];
};

export const getAuthorName = (
  teamMemberData: TeamMembersDto,
  item: PermissionChangeLogDTO,
  t: TFunction<"translation">
) => {
  const currentUserId = teamMemberData?.memberId;

  const author = teamMemberData?.members?.find(
    (member) => member.id === item.authorId
  );

  if (item.event === PermissionChangeLogDTO.event.INITIAL_STATE)
    return t("common.labels.empty");

  if (!author) return t("common.labels.deletedUser");

  const currentUserIsAuthor = currentUserId === item.authorId;

  const fullName = currentUserIsAuthor
    ? `${author?.firstname as string} ${author?.lastname as string} (${t(
        "pages.contractDetails.changeLog.misc.you"
      )})`
    : `${author?.firstname as string} ${author?.lastname as string}`;

  return fullName;
};

export const getDateTitle = (
  item: PermissionChangeLogDTO,
  t: TFunction<"translation">
) => {
  if (item.event === PermissionChangeLogDTO.event.INITIAL_STATE)
    return t(
      "pages.settings.organization.permissionChangeLog.table.headings.initial"
    );
  return "";
};
