import React, { forwardRef } from "react";
import { motion } from "framer-motion";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Close } from "@mui/icons-material";
import { ContractFieldDTOV1 } from "openapi";
import { useLocale } from "hooks/GlobalStateHooks";
import theme from "theme/theme";
import { useDndContext } from "@dnd-kit/core";
import {
  SortableFieldContainer,
  SortableFieldItem,
  SortableFieldLabel,
  SortableFieldValue,
  Remove,
  MockFieldsGroup,
  MockFieldRow,
} from "../styles";
import { useTranslation } from "react-i18next";

type SectionFieldBaseProps = {
  id?: string;
  field?: ContractFieldDTOV1;
  dragging?: boolean;
  handleProps?: Record<string, unknown>;
  style?: React.CSSProperties;
};

type SectionFieldPropsWithoutActions = SectionFieldBaseProps & {
  sectionId?: never;
  onRemoveField?: never;
};

type SectionFieldPropsWithActions = SectionFieldBaseProps & {
  sectionId: string;
  onRemoveField: (field: ContractFieldDTOV1, sectionId: string) => void;
};

type SectionFieldProps =
  | SectionFieldPropsWithoutActions
  | SectionFieldPropsWithActions;

const getMockData = (type: string) => {
  switch (type) {
    case "DATE":
      return new Date().toLocaleDateString();
    case "NUMBER":
      return 12;
    case "TEXTFIELD":
      return "Sample text";
    case "COUNTRY":
      return "Germany";
    case "AMOUNT":
      return "100€";
    case "DURATION":
      return "3 months";
    case "LINK":
      return "example.com";
    default:
      return "-";
  }
};

const MockFiled = ({ label, value }: { label: string; value: string }) => (
  <span className="node">
    <SortableFieldLabel>{label}</SortableFieldLabel>
    <div>{value}</div>
  </span>
);

const SectionField = forwardRef<HTMLDivElement, SectionFieldProps>(
  (
    { field, sectionId, onRemoveField, dragging, handleProps, style, ...props },
    ref
  ) => {
    const { t } = useTranslation();
    const { locale } = useLocale();
    const { active, over } = useDndContext();
    // @TODO: Update in next steps and use new version of data points
    const isDuration = field?.type === "DURATION";

    if (!field) {
      return null;
    }

    let action: "drag" | "delete" | null = dragging ? "drag" : null;

    if (active && dragging && !over) {
      action = "delete";
    }

    const fieldName = field.name[locale] ?? field.name;

    return (
      <motion.div {...props} ref={ref} style={style}>
        <SortableFieldContainer
          action={action}
          data-id={`sortable-${field.id}`}
        >
          <SortableFieldItem active={!action}>
            <span {...handleProps}>
              <DragIndicatorIcon
                sx={{
                  cursor: action ? "grabbing" : "grab",
                  ":hover": { color: theme.color.blue[300] },
                }}
              />
              {!isDuration && (
                <SortableFieldLabel
                  style={{ cursor: action ? "grabbing" : "grab" }}
                >
                  {fieldName}
                </SortableFieldLabel>
              )}
            </span>

            <Remove>
              <Close
                fontSize="small"
                onClick={() => {
                  if (onRemoveField) {
                    onRemoveField(field, sectionId);
                  }
                }}
              />
            </Remove>
          </SortableFieldItem>

          {isDuration ? (
            <SortableFieldValue active={!!action}>
              <MockFieldsGroup>
                <MockFieldRow>
                  <MockFiled
                    label={t(
                      "pages.contractEdit.forms.durationForm.durationType"
                    )}
                    value={t(
                      "pages.contractEdit.forms.durationForm.durationTypeOptions.AUTOMATIC_RENEWAL"
                    )}
                  />
                </MockFieldRow>

                <MockFieldRow>
                  <MockFiled
                    label={t("pages.contractDetails.duration.startDate")}
                    value="-"
                  />
                  <MockFiled
                    label={t("pages.contractDetails.duration.durationInMonths")}
                    value="-"
                  />
                </MockFieldRow>

                <MockFieldRow>
                  <MockFiled
                    label={t("pages.contractEdit.forms.durationForm.endDate")}
                    value="-"
                  />
                  <MockFiled
                    label={t(
                      "pages.contractEdit.forms.durationForm.noticeInMonths"
                    )}
                    value="-"
                  />
                </MockFieldRow>

                <MockFieldRow>
                  <MockFiled
                    label={t(
                      "pages.contractEdit.forms.durationForm.lastTerminationDate"
                    )}
                    value="-"
                  />
                  <MockFiled
                    label={t(
                      "pages.contractEdit.forms.durationForm.autoRenewalInMonths"
                    )}
                    value="-"
                  />
                </MockFieldRow>
              </MockFieldsGroup>
            </SortableFieldValue>
          ) : (
            <SortableFieldValue active={!!action}>
              {getMockData(field.type)}
            </SortableFieldValue>
          )}
        </SortableFieldContainer>
      </motion.div>
    );
  }
);
SectionField.displayName = "SectionField";
export default SectionField;
