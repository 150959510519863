import styled from "@emotion/styled";
import { theme } from "theme";

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h3<{
  colorstyle: string;
}>`
  font-size: 1rem;
  font-weight: 500;
  color: #1a202c;
  margin: 0px;
  line-height: 1.5rem;
  color: ${(prop) => prop.colorstyle};
`;

export const SubTitle = styled.p<{
  colorstyle: string;
}>`
  font-size: ${theme.font.size.caption};
  font-weight: ${theme.font.weight.medium};
  line-height: 1.5rem;
  margin: 0;
  color: ${(prop) => prop.colorstyle};
`;
