/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CategoryChange = {
  action: CategoryChange.action;
  id: string;
};

export namespace CategoryChange {
  export enum action {
    ALLOWED = "ALLOWED",
    DENIED = "DENIED",
  }
}
