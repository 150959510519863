import styled from "@emotion/styled";
import { theme } from "theme";

export const Container = styled.div`
  display: inline-flex;
`;

export const Background = styled.div<{
  backgroundColor?: string;
  color?: string;
  custom?: boolean;
}>`
  border-radius: ${theme.labelBorderRadius};
  display: inline-flex;
  align-items: center;
  min-height: 1rem;

  ${(p) => p.backgroundColor && `background-color: ${p.backgroundColor};`}
  ${(p) => p.color && `color: ${p.color};`}

  font-size: 0.75rem;
  padding: 0 ${theme.spacing.xs};
  gap: ${theme.spacing.xs};
  flex-shrink: 0;
  align-self: baseline;
`;

export const Adornment = styled.div`
  display: flex;
  max-width: ${theme.font.size.body};
  max-height: ${theme.font.size.body};
  svg {
    fill: transparent;
    width: 100%;
    height: 100%;
  }
  flex-shrink: 0;
`;

export const Text = styled.span`
  flex-shrink: 0;
  line-height: 1;
`;
