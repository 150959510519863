import styled from "@emotion/styled";
import {
  ListItemIcon,
  ListItemButton as MUIListItemButton,
} from "@mui/material";
import { theme } from "theme";

export const Wrapper = styled.div`
  ${({ visible }: { visible: boolean }) =>
    visible
      ? `
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing.md} ${theme.spacing.md};
    gap: ${theme.spacing.md};
    width: 100%;
    background-color: ${theme.color.gray[100]}80;
    border-radius: ${theme.borderRadius};
    cursor: pointer;
    margin-right: ${theme.spacing.xs};
  `
      : `
    display: none;
  `}

  svg {
    color: ${theme.color.gray[600]};
  }
`;

export const PopoverContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.md};
  padding: ${theme.spacing.lg} 0;
  max-width: 420px;
`;

export const ListItem = styled.div<{ active?: boolean }>`
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${theme.spacing.xs};
  margin: ${theme.spacing.xxs} auto;
  border: 1px solid transparent;
  border-radius: ${theme.borderRadius};
  transition: border 0.25s, background-color 0.25s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid ${theme.color.blue[200]};
    background-color: ${theme.color.blue[100]};
  }

  background-color: ${({ active }) =>
    active ? `${theme.color.blue[400]}80` : "transparent"};
`;

export const List = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: ${theme.spacing.lg};
`;

export const ListItemButton = styled(MUIListItemButton)<{
  visible?: boolean;
  disableHoverEffect?: boolean;
}>`
  border-radius: ${theme.borderRadius};
  padding-left: ${theme.spacing.xs};
  ${({ disableHoverEffect }) =>
    disableHoverEffect &&
    `
 &:hover {
    background-color: transparent !important;
    cursor : default;
         }`}
`;

export const Icon = styled(ListItemIcon)`
  color: ${theme.color.gray[700]};
  margin-right: ${({ visible }: { visible: boolean }) =>
    visible && theme.spacing.sm};
  transition: margin-right 0.15s linear;
  text-align: center;

  span {
    font-size: ${theme.font.size.body};
    color: ${theme.color.gray[600]};
    padding: ${theme.spacing.xxs} 0;
    border: 1px solid ${theme.color.gray[300]};
    border-radius: ${theme.borderRadius};
    min-width: 44px;
  }

  span:hover {
    background-color: none;
  }
`;
