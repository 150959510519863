import React from "react";
import { StyledLabel } from "./styles";

type Props = { label: string; ariaLabel?: string };

const Label = ({ label, ariaLabel }: Props) => (
  <StyledLabel aria-label={ariaLabel}>{label}</StyledLabel>
);

export default Label;
