import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div<{ gap?: keyof typeof theme.spacing }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing.sm};
  gap: ${(props) =>
    props.gap ? `${theme.spacing[props.gap]}` : theme.spacing.sm};
`;
