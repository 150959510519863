import styled from "@emotion/styled";
import { theme } from "theme";

const CardTitle = styled.h3`
  font-size: 1rem;
  font-weight: 500;
  color: ${theme.color.gray[900]}
  margin: 0 0 1rem 0;
  line-height: 1.5rem;
`;

export default CardTitle;
