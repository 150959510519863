import styled from "@emotion/styled";
import CTAButton from "components/CTAButton/CTAButton";
import { theme } from "theme";

export const FieldWrapper = styled.div<{ showButtons?: boolean }>`
  position: relative;
  .MuiInputBase-input {
    padding-right: ${({ showButtons }) =>
      showButtons ? theme.spacing.xl : theme.spacing.md};
  }
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  right: ${theme.spacing.sm};
  top: ${theme.spacing.xs};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing.xs};
`;

export const IncrementButton = styled(CTAButton)`
  svg {
    fill: ${theme.color.gray[700]};
    transform: scaleY(-1);
  }
`;

export const DecrementButton = styled(CTAButton)`
  svg {
    fill: ${theme.color.gray[700]};
  }
`;
