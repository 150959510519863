import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import ImportEmail from "components/ImportViaEmail/ImportViaEmail";
import { Header } from "../styles";

const ImportViaEmail = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header style={{ marginBottom: "1rem" }}>
        <Typography id="modal-title" variant="h6" component="h2">
          {t("guide.steps.importViaEmail.title")}
        </Typography>
        <Typography id="modal-title" variant="subtitle1" component="span">
          {t("guide.steps.importViaEmail.subtitle")}
        </Typography>
      </Header>
      <ImportEmail />
    </>
  );
};

export default ImportViaEmail;
