import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { useSnackbar } from "notistack";
import {
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import {
  ApiError,
  ContractFieldCreateDTOV1,
  ContractFieldDTOV1,
  ContractFieldDto,
  NumberFieldConfiguration,
  CommonService,
  TranslationDTO,
} from "openapi";
import { CTAButton, NewModal } from "components";
import { useTeam } from "contexts/team/hooks";
import theme from "theme/theme";
import { FormTextField } from "components";
import { FormSelect } from "components/FormItems/FormSelect/FormSelect";
import { selectableCustomFieldTypes } from "../../../../../../../constants/utils";
import { getListItems, hasDuplicateListItems } from "./helpers";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { BadRequestResponse } from "../../../../../../../shared/types/ErrorResponse.type";
import { maxDecimalConfigurationValueValidator } from "constants/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ContractFieldConfiguration } from "../../types";
import { useLocale } from "../../../../../../../hooks";
import { FormSection, Row } from "../../Categories/styles";
import EditIcon from "assets/svg/edit-icon.svg?react";
import { useContractDefinitionCreateMutation } from "../../../../../../../shared/api/contract-definition";
import { ButtonsWrapper, Description, FlexBox } from "./styles";

export const excludedFields = () => {
  const fields = [
    ContractFieldDto.type.COUNTRY,
    ContractFieldDto.type.FORMULA,
    ContractFieldDto.type.DURATION,
    ContractFieldDto.type.CONTACT,
  ];

  return fields;
};

const defaultConfigurations: {
  [key in ContractFieldCreateDTOV1["type"]]?: ContractFieldConfiguration | null;
} = {
  // Any newtypes and their corresponding default configurations go here.
  [ContractFieldDto.type.NUMBER]: { key: "decimalScale", value: 2 },
};

type Props = {
  open: boolean;
  onClose: () => void;
};

type AddNewFieldForm = {
  name: { de: string; en: string };
  type: ContractFieldCreateDTOV1.type | string;
  listItemsAsString: string;
  configuration: ContractFieldConfiguration | string;
};

const AddNewFieldModal = ({ open, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { organizationId } = useTeam();

  const sourceLanguage = locale === "en" ? "en" : "de";
  const targetLanguage = locale === "en" ? "de" : "en";

  const validationSchema = () =>
    Yup.object().shape({
      name: Yup.object().shape({
        [sourceLanguage ?? targetLanguage]: Yup.string().required(),
      }),
      type: Yup.string().required(),
      listItemsAsString: Yup.string().when("type", {
        is: ContractFieldDTOV1.type.LIST,
        then: Yup.string().required(),
      }),
      configuration: Yup.string().when("type", {
        is: ContractFieldDTOV1.type.NUMBER,
        then: maxDecimalConfigurationValueValidator(5),
      }),
    });

  const { control, handleSubmit, getValues, reset, setValue, setError } =
    useForm<AddNewFieldForm>({
      resolver: yupResolver(validationSchema()),
      defaultValues: {
        name: { de: "", en: "" },
        type: "",
        listItemsAsString: "",
        configuration: "",
      },
    });

  const [nameTranslation, setNameTranslation] = useState<string>("");
  const [nameTranslationEdit, setNameTranslationEdit] =
    useState<boolean>(false);

  const createContractDefinition = useContractDefinitionCreateMutation();

  const selectedCustomFieldType = useWatch({
    name: "type",
    control: control,
  });

  useEffect(() => {
    const defaultConfig =
      defaultConfigurations[
        selectedCustomFieldType as keyof typeof defaultConfigurations
      ] || null;
    if (defaultConfig) {
      const value = defaultConfig.value;
      setValue("configuration", value as unknown as ContractFieldConfiguration);
    }
  }, [selectedCustomFieldType, setValue]);

  const onSubmit = async () => {
    const type = getValues().type as ContractFieldCreateDTOV1.type;
    const list = getListItems(getValues().listItemsAsString);
    const defaultConfig = defaultConfigurations[type] || null;
    const configuration = getValues().configuration;
    const useAutoTranslation = nameTranslation && !nameTranslationEdit;

    if (useAutoTranslation) {
      setValue(`name.${targetLanguage}`, nameTranslation);
    }
    const requestBody: ContractFieldCreateDTOV1 = {
      name: {
        en: getValues().name.en ?? getValues().name.de,
        de: getValues().name.de ?? getValues().name.en,
      },
      type,
      visibleId: null,
      oldStandardField: false,
    };

    if (defaultConfig && configuration) {
      requestBody.configuration = {
        [defaultConfig.key]: configuration,
      } as unknown as NumberFieldConfiguration;
    }

    if (list) {
      if (hasDuplicateListItems(list)) {
        setError("listItemsAsString", {
          type: "custom",
          message: t("common.errors.duplicatedListItems"),
        });
        return;
      }

      requestBody.data = {
        items: list.map((item) => ({
          id: item,
          value: item,
        })),
      };
    }
    try {
      await createContractDefinition.mutateAsync({
        organizationId: organizationId,
        field: requestBody,
      });

      enqueueSnackbar(
        t(
          "pages.settings.organization.administrator.fields.modal.create.success"
        ),
        {
          variant: "success",
        }
      );
      handleOnClose();
    } catch (error) {
      if (
        error instanceof ApiError &&
        (error.body as BadRequestResponse).error === "duplicated_name"
      ) {
        setError("name.de", {
          type: "custom",
          message: "common.errors.duplicatedFieldName",
        });
        setError("name.en", {
          type: "custom",
          message: "common.errors.duplicatedFieldName",
        });
        return;
      }
      enqueueSnackbar(
        t(
          "pages.settings.organization.administrator.fields.modal.create.failure"
        ),
        {
          variant: "error",
        }
      );
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    reset();
    onClose();
    setNameTranslation("");
    setNameTranslationEdit(false);
  };
  const getTranslation = async () => {
    const data = getValues();

    if (!data.name || !data.name[sourceLanguage]) return;

    if (data.name[sourceLanguage] === "") {
      setNameTranslation("");
      return;
    }
    const requestBody = {
      text: data.name[locale],
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
    };

    try {
      const translatedName = await CommonService.translateText(
        requestBody as TranslationDTO
      );
      setNameTranslation(translatedName.text);
    } catch (error) {
      enqueueSnackbar(t("pages.categoryDetails.messages.translationFailed"), {
        variant: "error",
      });
    }
  };

  const toggleEdit = () => {
    setNameTranslationEdit(!nameTranslationEdit);
  };

  const onNameInput = () => {
    if (nameTranslationEdit) return;

    const debounceTimerId = setTimeout(async () => {
      await getTranslation();
    }, 1000);

    return () => {
      clearTimeout(debounceTimerId);
    };
  };

  return (
    <NewModal
      open={open}
      handleClose={handleOnClose}
      title={t("pages.contractEdit.modals.customField.title")}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
        },
      }}
      body={
        <form
          name="contractCustomFieldForm"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container className="flex-align-center">
            <FormSection style={{ width: "100%" }}>
              <div>{t("pages.contractEdit.modals.customField.subtitle")}</div>

              <Grid item xs={12}>
                <FormTextField
                  control={control}
                  name={`name.${sourceLanguage}`}
                  label={`${t(
                    "pages.settings.tabs.customFields.form.customFieldName"
                  )} (${t(`common.languages.${sourceLanguage}`)})`}
                  required
                  onKeyDown={() => onNameInput()}
                />
              </Grid>
              {nameTranslation && !nameTranslationEdit ? (
                <Row justifyItems="flex-start" maxWidth="600px">
                  <span>
                    {`${t(
                      "pages.settings.tabs.customFields.form.customFieldName"
                    )} (${t(`common.languages.${targetLanguage}`)}): `}
                    <strong>{nameTranslation}</strong>
                  </span>
                  <IconButton onClick={toggleEdit}>
                    <EditIcon fill={theme.color.blue[600]} />
                  </IconButton>
                </Row>
              ) : null}

              {nameTranslationEdit ? (
                <FormTextField
                  control={control}
                  name={`name.${targetLanguage}`}
                  label={`${t(
                    "pages.settings.tabs.customFields.form.customFieldName"
                  )} (${t(`common.languages.${targetLanguage}`)})`}
                  defaultValue={nameTranslation ?? ""}
                  required
                />
              ) : null}
            </FormSection>
            <FormSection style={{ width: "100%" }}>
              <div>
                {t(
                  "pages.contractEdit.modals.customField.customFieldTypeDescription"
                )}
              </div>
              <Grid item xs={12}>
                <FormSelect
                  control={control}
                  name="type"
                  label={t(
                    "pages.settings.tabs.customFields.form.customFieldType"
                  )}
                  options={selectableCustomFieldTypes(excludedFields()).sort(
                    (a, b) =>
                      t(`customFields.typeOptions.${a}`).localeCompare(
                        t(`customFields.typeOptions.${b}`)
                      )
                  )}
                  translationPrefix={"customFields.typeOptions"}
                  required
                />
              </Grid>
            </FormSection>
            {selectedCustomFieldType === ContractFieldDto.type.LIST && (
              <FlexBox>
                <Grid item xs={6}>
                  <FormTextField
                    control={control}
                    name="listItemsAsString"
                    label={t(
                      "pages.contractEdit.modals.customField.customFieldValues"
                    )}
                    required
                    multiline
                    minRows={4}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Description>
                    {t(
                      "pages.contractEdit.modals.customField.customFieldValuesDescription"
                    )}
                  </Description>
                </Grid>
              </FlexBox>
            )}
            {selectedCustomFieldType === ContractFieldDto.type.NUMBER && (
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-numberfield-config-content"
                    id="panel-numberfield-config-header"
                    style={{ padding: "0 0.375rem" }}
                  >
                    <Typography>
                      {t(
                        "pages.contractEdit.modals.customField.NumberFieldConfiguration"
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormTextField
                          control={control}
                          name="configuration"
                          label={t(
                            "pages.contractEdit.modals.customField.NumberFieldConfiguration"
                          )}
                          required
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Box>
                          {t(
                            "pages.contractEdit.modals.customField.NumberFieldConfigurationDescription"
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
          </Grid>

          {/* FIELD TYPE */}
          <ButtonsWrapper>
            <CTAButton
              type="reset"
              variant="secondary"
              label="cancelAddCustomFieldButton"
              onClick={() => handleOnClose()}
              name={t("pages.contractEdit.modals.customField.buttons.cancel")}
            />
            <CTAButton
              variant="primary"
              type="submit"
              label="addCustomFieldButton"
              name={t("pages.contractEdit.modals.customField.buttons.addField")}
            />
          </ButtonsWrapper>
        </form>
      }
    ></NewModal>
  );
};

export default AddNewFieldModal;
