import React, { createContext, useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";

type PrintProviderProps = {
  children: JSX.Element;
  title: string | undefined;
};

type PrintContextProps = {
  handlePrint: ReturnType<typeof useReactToPrint>;
  componentRef: React.MutableRefObject<null>;
  title: string | undefined;
};

const PrintContext = createContext<PrintContextProps | null>(null);

export const usePrintContext = () => {
  const context = useContext(PrintContext);
  if (!context) {
    throw new Error("usePrintContext must be used within a PrintProvider");
  }
  return context;
};

export const PrintProvider: React.FC<PrintProviderProps> = ({
  children,
  title,
}) => {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: title,
  });

  const value: PrintContextProps = {
    handlePrint,
    componentRef,
    title,
  };

  return (
    <PrintContext.Provider value={value}>{children}</PrintContext.Provider>
  );
};
