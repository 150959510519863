import React from "react";
import {
  FormSelect,
  FormSelectItem,
  FormSelectProps,
} from "../FormSelect/FormSelect";
import { FieldValues } from "react-hook-form";
import currencies from "../../../utils/currencies-list.json";
import { useTranslation } from "react-i18next";
export type CurrencySelectProps<T extends FieldValues> = Omit<
  FormSelectProps<T>,
  "options"
>;

function createOptions() {
  return currencies.map<FormSelectItem>((currency) => {
    return {
      key: currency.cc,
      value: `${currency.name} (${currency.cc})`,
    };
  });
}

export function CurrencySelect<T extends FieldValues>({
  ...rest
}: CurrencySelectProps<T>) {
  const { t } = useTranslation();
  return (
    <FormSelect
      options={[
        {
          key: "XXX",
          value: `${t(
            "pages.contractEdit.forms.financialForm.noCurrency"
          )} (XXX)`,
        },
        ...createOptions(),
      ]}
      {...rest}
    />
  );
}
