/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RegistrationDto = {
  locale: RegistrationDto.locale;
  company?: string;
  email: string;
  firstname?: string;
  lastname?: string;
  password: string | null;
  phone?: string;
  invitationToken?: string;
  chargebeeId?: string;
  skipOcr?: boolean;
};

export namespace RegistrationDto {
  export enum locale {
    EN = "en",
    DE = "de",
  }
}
