import React from "react";
import { dateFormatter } from "constants/utils";
import { useLocale, useUserInfo } from "hooks/GlobalStateHooks";
type Props = {
  value: string;
};

const ReminderDateCellRenderer = ({ value }: Props) => {
  const { locale } = useLocale();
  const { userInfo } = useUserInfo();
  const userDateFormat = userInfo?.dateFormat;

  const formattedValue = dateFormatter(locale, value, userDateFormat);

  if (formattedValue !== "Invalid Date") {
    return <span>{dateFormatter(locale, value, userDateFormat)}</span>;
  } else {
    return <span>{value}</span>;
  }
};

export default ReminderDateCellRenderer;
