import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { isEqual } from "lodash";

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  closestCenter,
  CollisionDescriptor,
  CollisionDetection,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  getFirstCollision,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  pointerWithin,
  rectIntersection,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useTeam } from "contexts/team/hooks";
import {
  CategoryDTO,
  CategoryUpdateDTO,
  ContractFieldDTOV1,
  ContractSectionDTOV1,
  OrganizationNewService,
} from "../../../../openapi";
import { Categories, Fields, Sections } from "./containers";
import { OverlayFactory, Toolbar } from "./components";
import withCustomFieldsData, {
  ContractFieldDefinition,
} from "./CustomFieldsWrapper";
import { Container } from "./styles";
import { DraggableData, FieldData } from "./components/Draggable/types";
import { toDraggableSectionId } from "./components/Draggable/DraggableSection";
import { createPortal } from "react-dom";
import { toSortableFieldId } from "./components/Draggable/SortableField";
import { ActiveElement } from "./types";
import { useFieldsQuery } from "shared/api";
import { isFieldUsed as _isFieldUsed } from "./utils/fields";

export type CustomFieldsProps = {
  categories: CategoryDTO[];
  setCategories: (value: CategoryDTO[]) => void;
  fields: ContractFieldDefinition[];
  setFields: (value: ContractFieldDefinition[]) => void;
  refetchData: () => Promise<void>;
};

export const CustomFieldsComponent = ({
  categories: categoriesData,
  refetchData,
}: CustomFieldsProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [activeElement, setActiveElement] = useState<ActiveElement | null>(
    null
  );
  const { organizationId } = useTeam();
  const [selectedCategory, setSelectedCategory] = useState<CategoryDTO | null>(
    null
  );

  const [sections, setSections] = useState<ContractSectionDTOV1[] | null>(null);

  const [clonedSections, setClonedSections] = useState<
    ContractSectionDTOV1[] | null
  >(null);
  const [isDirty, setIsDirty] = useState(false);

  const [dragMoved, setDragMoved] = useState(false);
  const recentlyMovedToNewContainer = useRef(false);

  const { data: fields } = useFieldsQuery(organizationId);

  const [usedFieldIds, setUsedFieldIds] = useState(new Set<string>());

  useEffect(() => {
    if (!sections || !fields) {
      return;
    }
    const newUsedFields = new Set<string>();
    for (const field of fields) {
      if (_isFieldUsed(sections, field.id)) {
        newUsedFields.add(field.id);
      }
    }
    setUsedFieldIds(newUsedFields);
  }, [fields, sections]);

  const isFieldUsed = useCallback(
    (fieldId: string) => usedFieldIds.has(fieldId),
    [usedFieldIds]
  );

  useEffect(() => {
    setSections((sections) => {
      if (!sections) {
        return null;
      }

      const fieldSet = new Set(fields?.map((field) => field.id));

      const removeDeletedFieldsFromSection = (
        section: ContractSectionDTOV1
      ) => {
        return {
          ...section,
          fields: section.fields.filter((field) => fieldSet.has(field.id)),
        };
      };
      return sections?.map((section) =>
        removeDeletedFieldsFromSection(section)
      );
    });
  }, [fields]);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveElement({
      id: active.id,
      data: { ...active.data.current } as unknown as DraggableData,
    });

    setClonedSections(structuredClone(sections));
  };

  const handleSectionDragEnd = ({ active, over }: DragEndEvent) => {
    const activeData = active?.data.current as DraggableData | undefined;
    const overData = over?.data.current as DraggableData | undefined;

    setSections((sections) => {
      if (!sections) return sections;
      const oldIndex = sections.findIndex(
        (section) => section.id === activeData?.id
      );
      const newIndex = sections.findIndex(
        (section) => section.id === overData?.id
      );
      return arrayMove(sections, oldIndex, newIndex);
    });
  };

  const handleFieldDragEnd = ({ active, over }: DragEndEvent) => {
    const activeData = active?.data.current as DraggableData | undefined;
    const overData = over?.data.current as DraggableData | undefined;

    setSections((sections) => {
      const modifyableSections = structuredClone(sections);
      const currentModifyableSection = modifyableSections?.find(
        (section) => section.id === overData?.id
      );

      const currentField = fields?.find((field) => field.id === activeData?.id);

      if (!currentModifyableSection || !currentField) return sections;

      currentModifyableSection.fields.push({
        id: currentField.id,
      });

      setUsedFieldIds(new Set(usedFieldIds).add(currentField.id));

      return modifyableSections;
    });
  };

  const handleSectionFieldDragEnd = (
    activeData: FieldData,
    overData: FieldData | null
  ) => {
    // when checking the same item, the logic to move the field within the section can be skipped
    if (activeData.id === overData?.id) {
      return;
    }

    // in case the user drags a field out of the section, we delete the field from the section
    if (!overData && activeData.set === "group") {
      if (!sections) {
        return;
      }

      const updatedSections = sections?.map((section) => {
        if (section.id === activeData?.parentSectionId) {
          return {
            ...section,
            fields: section.fields.filter((f) => f.id !== activeData?.id),
          };
        }
        return section;
      });

      setUsedFieldIds((prev) => {
        const next = new Set(prev);
        next.delete(activeData.id);
        return next;
      });
      setSections(updatedSections);
    }

    // in case the user is dragging within the same section, we move the field within the section
    if (overData?.set === "group") {
      const overSectionId = overData.parentSectionId;

      setSections((sections) => {
        if (!sections || !overData) {
          return sections;
        }

        const mutableSections = structuredClone(sections);
        const relevantSection = mutableSections?.find(
          (section) => section.id === overSectionId
        );
        if (!relevantSection) {
          return sections;
        }

        const activeFieldIndex = relevantSection.fields.findIndex(
          (field) => field?.id === activeData?.id
        );
        const overFieldIndex = relevantSection.fields.findIndex(
          (field) => field?.id === overData?.id
        );

        if (activeFieldIndex < 0 || overFieldIndex < 0) {
          return sections;
        }

        relevantSection.fields = arrayMove(
          relevantSection.fields,
          activeFieldIndex,
          overFieldIndex
        );
        return mutableSections;
      });
    }
  };

  const handleOnDragEnd = ({ active, over }: DragEndEvent) => {
    if (!dragMoved) {
      return;
    }

    const activeData = active.data.current as DraggableData | undefined;
    const overData = over?.data.current as DraggableData | undefined;

    if (
      activeData?.type === "field" &&
      (!overData || overData.type === "field")
    ) {
      handleSectionFieldDragEnd(activeData, overData ?? null);
    }

    if (activeData?.type === "field" && overData?.type === "section") {
      handleFieldDragEnd({ active, over } as DragEndEvent);
    }

    if (
      activeData?.type === "section" &&
      overData?.type === "section" &&
      activeData?.id !== overData?.id
    ) {
      handleSectionDragEnd({ active, over } as DragEndEvent);
    }

    setDragMoved(false);
    setActiveElement(null);
  };

  const handleRemoveFieldFromSection = (
    targetField: ContractFieldDTOV1,
    sectionId: string
  ): void => {
    if (!sections) return;

    const currentSectionObj = sections.find(
      (section) => section.id === sectionId
    );
    const currentFieldObj = currentSectionObj?.fields.find(
      (field) => field.id === targetField.id
    );

    const updatedFieldsGroup = currentSectionObj?.fields.filter(
      (field) => field?.id !== currentFieldObj?.id
    );

    const updatedSectionObj = {
      ...currentSectionObj,
      fields: updatedFieldsGroup ?? [],
    };

    const updatedSectionsList = sections.map((sectionObj) => {
      if (sectionObj.id === currentSectionObj?.id) return updatedSectionObj;
      return sectionObj;
    });

    setUsedFieldIds((prev) => {
      const next = new Set(prev);
      next.delete(targetField.id);
      return next;
    });
    setSections(updatedSectionsList as ContractSectionDTOV1[]);
  };

  const handleUpdateCategory = async (sectionId?: string) => {
    if (!selectedCategory) return;

    const updatedSections = sections?.filter(
      (section) => section.id !== sectionId
    );

    const requestBody = {
      ...selectedCategory,
      name: {
        en: (selectedCategory as unknown as CategoryDTO).name["en"],
        de: (selectedCategory as unknown as CategoryDTO).name["de"],
      },
      sections: updatedSections ?? [],
    } satisfies CategoryUpdateDTO;

    try {
      await OrganizationNewService.updateCategory(
        organizationId,
        selectedCategory.id,
        requestBody
      );

      enqueueSnackbar(
        t(
          "pages.settings.organization.administrator.categories.modal.edit.success"
        ),
        {
          variant: "success",
        }
      );
      setIsDirty(false);
      void refetchData();
    } catch (error) {
      enqueueSnackbar(
        t(
          "pages.settings.organization.administrator.categories.modal.edit.failure"
        ),
        {
          variant: "error",
        }
      );
    }
  };

  const sectionsIds = useMemo(() => {
    return sections
      ? sections.map((item) => toDraggableSectionId(item.id))
      : [];
  }, [sections]);

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [sections]);

  const dataOfActiveElement = useMemo(() => {
    if (activeElement?.data.type === "field") {
      return (
        fields?.find((field) => field.id === activeElement.data.id) ?? null
      );
    }
    if (activeElement?.data.type === "section") {
      return (
        sections?.find((section) => section.id === activeElement.data.id) ??
        null
      );
    }
    return null;
  }, [activeElement, sections, fields]);

  const collisionDetection: CollisionDetection = useCallback(
    (args) => {
      const activeElement = args.active;
      const activeData = activeElement.data.current as
        | DraggableData
        | undefined;
      if (!activeData) {
        return pointerWithin(args);
      }

      const getFilterByActiveIdPrefix = (id: string) => {
        if (id.startsWith("draggable-section")) {
          return /draggable-section/;
        }
        if (id.startsWith("sortable-field")) {
          return /sortable-field|droppable-section|draggable-section/;
        }
        if (id.startsWith("draggable-field")) {
          return /sortable-field|droppable-section|draggable-section/;
        }
        return /.*/;
      };

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      let intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);

      intersections = intersections.filter((is) =>
        is.id
          .toString()
          .match(getFilterByActiveIdPrefix(activeElement.id.toString()))
      );

      const collision = getFirstCollision(intersections) as CollisionDescriptor;

      if (collision) {
        let overId = collision.id;
        const data = collision.data;
        if (
          data &&
          activeData.type === "field" &&
          activeData.set === "group" &&
          sectionsIds.includes(overId.toString())
        ) {
          const section = sections?.find(
            (s) =>
              s.id ===
              (data.droppableContainer.data.current as DraggableData).id
          );

          if (section) {
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) => {
                  return (
                    container.id !== overId &&
                    section.fields.find(
                      (field) =>
                        // noticed that this threw once:
                        // Uncaught TypeError: Cannot read properties of undefined (reading 'id')
                        toSortableFieldId(field.id) === container.id.toString()
                    )
                  );
                }
              ),
            })[0]?.id;

            return [{ id: overId }];
          }
        } else {
          return [{ id: overId }];
        }
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        return activeElement.id ? [{ id: activeElement.id }] : [];
      }

      return [];
    },
    [activeElement, sectionsIds]
  );

  const getPageState = () => {
    if (sections !== null) {
      const prevState = selectedCategory?.sections;
      const nextState = sections;
      setIsDirty(!isEqual(prevState, nextState));
    }
  };

  useEffect(() => {
    if (!selectedCategory) {
      setSelectedCategory(categoriesData[0]);
    }

    if (!sections) {
      setSections(categoriesData[0].sections);
    }
  }, [selectedCategory?.id]);

  useEffect(() => getPageState(), [JSON.stringify(sections)]);

  useEffect(() => {
    const selectedCat = categoriesData.find(
      (item) => item.id === selectedCategory?.id
    );
    if (selectedCat) {
      setSelectedCategory(selectedCat);
      return;
    }
    setSelectedCategory(categoriesData[0]);
    setSections(categoriesData[0].sections);
  }, [categoriesData]);

  const handleDragCancel = () => {
    if (clonedSections) {
      setSections(clonedSections);
    }

    setActiveElement(null);
    setClonedSections(null);
  };

  const handleDragMove = () => {
    setDragMoved(true);
  };

  const handleOnDragOver = ({ active, over }: DragOverEvent) => {
    const overId = over?.id;

    const overData = over?.data.current as DraggableData | undefined;
    const activeData = active.data.current as DraggableData | undefined;

    // in case we have no over id, or the active id not a field, we don't want to do anything
    if (!overId || activeData?.type !== "field") {
      return;
    }

    const currentSectionIdOfActive =
      activeData.set === "group" ? activeData.parentSectionId : null;
    let currentSectionIdOfOver: string | null = null;

    if (overData?.type === "field" && overData.set === "group") {
      currentSectionIdOfOver = overData?.parentSectionId;
    } else if (overId.toString().startsWith("droppable-section")) {
      currentSectionIdOfOver = overData?.id ?? null;
    }

    if (!currentSectionIdOfOver) {
      return;
    }

    // only fire if we do a cross section field drag
    if (currentSectionIdOfActive !== currentSectionIdOfOver) {
      setSections((sections) => {
        // not sure about the !overdata here
        if (!sections || !overData) {
          return sections;
        }

        const mutableSections = structuredClone(sections);

        const sectionOfActiveField = mutableSections.find(
          (s) => s.id === currentSectionIdOfActive
        );

        const sectionOfOverField = mutableSections.find(
          (s) => s.id === currentSectionIdOfOver
        );
        if (!sectionOfActiveField || !sectionOfOverField) {
          return sections;
        }

        const overFieldIndex = sectionOfOverField.fields.findIndex(
          (f) => f.id === overData.id
        );

        const activeField = fields?.find((field) => field.id === activeData.id);

        if (!activeField) {
          return sections;
        }

        let newIndex: number;

        if (overId.toString().startsWith("droppable-section")) {
          newIndex = sectionOfOverField.fields.length + 1;
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top >
              over.rect.top + over.rect.height;
          const modifier = isBelowOverItem ? 1 : 0;

          newIndex =
            overFieldIndex >= 0
              ? overFieldIndex + modifier
              : sectionOfOverField.fields.length + 1;
        }

        sectionOfActiveField.fields = sectionOfActiveField.fields.filter(
          (f) => f.id !== activeData.id
        );

        sectionOfOverField.fields = [
          ...sectionOfOverField.fields.slice(0, newIndex),
          {
            id: activeField.id,
          },
          ...sectionOfOverField.fields.slice(
            newIndex,
            sectionOfOverField.fields.length
          ),
        ];

        recentlyMovedToNewContainer.current = true;
        return mutableSections;
      });
    }
  };

  return (
    <>
      <Toolbar onSubmit={handleUpdateCategory} />
      <Container>
        <Categories
          categories={categoriesData}
          isDirty={isDirty}
          setIsDirty={setIsDirty}
          sections={sections}
          setSections={setSections}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          refetchData={refetchData}
          handleUpdateCategory={handleUpdateCategory}
        />
        <DndContext
          sensors={sensors}
          collisionDetection={collisionDetection}
          measuring={{
            droppable: {
              strategy: MeasuringStrategy.Always,
            },
          }}
          onDragStart={handleDragStart}
          // make this a onDragOver, so you can drag between sortable contexts directly.
          onDragEnd={handleOnDragEnd}
          onDragOver={handleOnDragOver}
          onDragCancel={handleDragCancel}
          onDragMove={handleDragMove}
        >
          <SortableContext
            id="sections-container"
            items={sectionsIds}
            strategy={verticalListSortingStrategy}
          >
            <Sections
              fields={fields ?? []}
              sections={sections ?? ([] as ContractSectionDTOV1[])}
              setSections={setSections}
              onRemoveField={handleRemoveFieldFromSection}
              onRemoveSection={handleUpdateCategory}
              selectedCategory={selectedCategory ?? ({} as CategoryDTO)}
            />
          </SortableContext>
          <Fields
            fields={fields ?? []}
            isFieldUsed={isFieldUsed}
            refetchData={refetchData}
          />
          {createPortal(
            <DragOverlay>
              <OverlayFactory
                activeElement={activeElement}
                data={dataOfActiveElement}
                fields={fields ?? []}
                dragged={dragMoved}
              />
            </DragOverlay>,
            document.body
          )}
        </DndContext>
      </Container>
    </>
  );
};

export default withCustomFieldsData(CustomFieldsComponent);
