import theme from "theme/theme";

const padZero = (str: string, len?: number) => {
  len = len || 2;
  const zeros = new Array(len).join("0");
  return `${zeros}${str}`.slice(-len);
};

export const invertColor = (hex: string, bw?: unknown) => {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }

  let r: number | string = parseInt(hex.slice(0, 2), 16);
  let g: number | string = parseInt(hex.slice(2, 4), 16);
  let b: number | string = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
};

// TODO: remove this after we update the colors in the api
export const getColor = (hexCode: string) => {
  if (!hexCode) {
    return theme.color.blue[600];
  }
  switch (hexCode.toLocaleLowerCase()) {
    case "#e2e8f0":
      return theme.color.gray[600];
    case "#feb2b2":
      return theme.color.red[600];
    case "#fbd38d":
      return theme.color.orange[600];
    case "#faf089":
      return theme.color.yellow[600];
    case "#9ae6b4":
    case "#38a169":
      return theme.color.green[600];
    case "#90cdf4":
      return theme.color.blue[600];
    case "#a3bffa":
      return theme.color.indigo[600];
    case "#d6bcfa":
      return theme.color.purple[600];
    case "#fbb6ce":
      return theme.color.pink[600];
    case "#7edce2":
      return theme.color.teal[600];
    case "#ddff99":
      return theme.color.mindaro[600];
    case "#a6f296":
      return theme.color.lightGreen[600];
    case "#e595e5":
      return theme.color.plum[600];
    case "#ff9999":
      return theme.color.salmonPink[600];
    case "#fbc49d":
      return theme.color.peach[600];
    default:
      return theme.color.blue[600];
  }
};
