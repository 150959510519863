import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../../locales/en.json";
import de from "../../locales/de.json";
import * as Sentry from "@sentry/react";

// Detect user browser language and set it as default
export const defaultLanguage =
  window.navigator.language.split("-")[0] === "de" ? "de" : "en";

export const languageFromUrl = new URLSearchParams(window.location.search).get(
  "lang"
);

export enum Language {
  en = "en",
  de = "de",
}

export const resources = {
  en: { translation: en },
  de: { translation: de },
};
void i18n.use(initReactI18next).init({
  returnNull: false,
  lng: languageFromUrl || defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
  parseMissingKeyHandler: (key) => {
    Sentry.captureException(new Error(`Translation key missing: ${key}`));
    return key;
  },

  resources,
});

export default i18n;
