import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { ContractDTOV1, ContractFieldDTOV1 } from "../../../openapi";
import { Box } from "@mui/material";
import Tag from "new-components/Tag";
import { AiSuggestion } from "components";
import { Suggestions } from "components/Datapoints/hooks/types";

export const AnalysisCellRendererWrapper = (
  props: ICellRendererParams<ContractDTOV1, unknown> & {
    definition: ContractFieldDTOV1;
    children: React.ReactElement;
    onSelectInsight?: (value: string) => void | Promise<void>;
    field: string;
    suggestions: Record<string, Suggestions> | null | undefined;
  }
) => {
  const fieldData = props.data?.fields[props.definition.id];
  const fieldSuggestions = props.suggestions?.[props.field];

  const shouldDiplaySuggestions =
    props.field &&
    fieldSuggestions &&
    fieldSuggestions.length > 0 &&
    fieldData?.metadata?.[props.field]?.unverified;

  const selectOption = (value: unknown) => {
    void props.onSelectInsight?.(value as string);
  };

  const cell = (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        component="span"
        sx={{
          width: "100%",
          display: "inline-flex",
          lineHeight: "normal",
          justifyContent: "space-between",
          minWidth: 0,
        }}
      >
        {props.children}
        {shouldDiplaySuggestions ? <Tag variant="ai" /> : null}
      </Box>
    </Box>
  );

  return shouldDiplaySuggestions ? (
    <AiSuggestion options={fieldSuggestions} handleOptionClick={selectOption}>
      {cell}
    </AiSuggestion>
  ) : (
    cell
  );
};
