import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import { theme } from "theme";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Container = styled(IconButton)`
  background-color: ${theme.color.white};
  transform: translateX(-4px);
  transition: transform 0.15s linear;

  :hover {
    background-color: ${theme.color.white};
    transform: translateX(-2px);
  }

  ${({ open }: { open?: boolean }) =>
    open &&
    `
    box-shadow: 1px 1px 1px 1px ${theme.color.gray[300]};
    transform: translateX(calc(50% + 4px));
    margin-bottom: 38px;

    :hover {
      background-color: ${theme.color.white};
      transform: translateX(calc(50% + 2px));
    }
  `};
`;
