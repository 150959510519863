import React from "react";
import { RoleBox } from "./styles";

export type RoleProps = {
  name: string;
};
const Role = ({ name }: RoleProps) => {
  return <RoleBox data-testid="role">{name}</RoleBox>;
};

export default Role;
