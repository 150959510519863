import React, { FC, useState, useRef } from "react";
import { t } from "i18next";
import { IconButton, List, Menu } from "@mui/material";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import FolderSharedOutlinedIcon from "@mui/icons-material/FolderSharedOutlined";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { ContractViewDto, ContractViewItemDto } from "openapi";
import { useOverviewState } from "contexts/grid/hooks";
import { useTeam } from "contexts/team/hooks";
import { Features } from "constants/features";
import { useTeamMembersQuery } from "shared/api";
import BaseDropDownButtonItemWithActions from "components/BaseDropDownButtonItemWithActions/BaseDropDownButtonItemWithActions";
import BaseDropDownButtonItem from "components/BaseDropDownButtonItem/BaseDropDownButtonItem";
import { OverflowTip } from "components/OverflowTip/OverflowTip";
import { theme } from "theme";
import LogoIcon from "assets/svg/logo-icon-outline.svg?react";
import Tag from "new-components/Tag/Tag";
import { getViewName } from "../helpers";
import { useStyles } from "../../StyledComponents/StyledBaseButtons";
import {
  ArrowIcon,
  BaseDropDownButtonWithIcon,
  Separator,
  ViewWarningIcon,
  ViewsList,
  ViewListHeader,
  ViewListHeading,
  CurrentView,
  AiIconWrapper,
} from "../styles";
import { useLocation } from "react-router";

type ViewListProps = {
  views: ContractViewDto;
  setView: (viewId: string | null, shouldRefetch?: boolean) => void;
  deleteView: (view: ContractViewItemDto) => void;
  editView: (view: ContractViewItemDto) => void;
  saveCurrentView: () => Promise<void>;
  saveNewView: () => void;
  exportView: () => void;
};

export const EntityViewList: FC<ViewListProps> = ({
  views,
  setView,
  deleteView,
  editView,
  saveCurrentView,
  saveNewView,
  exportView,
}) => {
  const location = useLocation();
  const classes = useStyles();
  const { hasFeature, selectedTeamId } = useTeam();
  const { data: teamMemberData } = useTeamMembersQuery(selectedTeamId);
  const {
    overview: {
      unsavedColumnChangePresent,
      unsavedFilterChangePresent,
      activeView,
      activePreset,
      type,
    },
  } = useOverviewState();
  const myViewsAnchorRef = useRef(null);
  const sharedViewsAnchorRef = useRef(null);
  const { hasWriteAccessToAny } = useTeam();
  const showContractAddButton = hasWriteAccessToAny();
  const [myViewsAnchor, setMyViewsAnchor] = useState<HTMLButtonElement | null>(
    null
  );
  const [shareViewsAnchor, setShareViewsAnchor] =
    useState<HTMLButtonElement | null>(null);

  const openMyViews = Boolean(myViewsAnchor);

  const handleMyViewsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMyViewsAnchor(event.currentTarget);
  };

  const handleMyViewsClose = () => {
    setMyViewsAnchor(null);
  };

  const openSharedViews = Boolean(shareViewsAnchor);
  const handleSharedClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShareViewsAnchor(event.currentTarget);
  };

  const handleSharedClose = () => {
    setShareViewsAnchor(null);
  };

  const handleOnAiViewsClick = () => setView("contract_analysis_view");

  const viewHasChanged =
    unsavedColumnChangePresent || unsavedFilterChangePresent;
  const canSaveChanges = viewHasChanged && activeView && !activePreset;
  const currentTeamMember = teamMemberData?.memberId;
  const personalViews = views?.items
    .filter((view) => !view.shared && view?.owner?.id === currentTeamMember)
    .sort((a, b) => a.name.localeCompare(b.name));
  const personalSharedViews = views?.items
    .filter((view) => view.shared && view?.owner?.id === currentTeamMember)
    .sort((a, b) => a.name.localeCompare(b.name));
  const sharedWithMeViews = views?.items
    .filter((view) => view?.owner?.id !== currentTeamMember)
    .sort((a, b) => a.name.localeCompare(b.name));
  const personalCombinedViews = [...personalViews, ...personalSharedViews];
  const isActiveViewShared = activeView?.owner?.id !== currentTeamMember;

  const isContractAnalysisView = activeView?.name === "contractAnalysisView";
  const path = location.pathname.split("/")[1];
  const isContractPage =
    path === "contracts" && type === ContractViewItemDto.type.CONTRACT;

  const isContractAnalysisViewOptionVisible =
    hasFeature(Features.CONTRACT_ANALYSIS) &&
    !isContractAnalysisView &&
    isContractPage;

  return (
    <List>
      <ViewListHeader>
        <ViewListHeading>
          {t("pages.contracts.header.currentView")}
        </ViewListHeading>
        <CurrentView>
          <CheckIcon fontSize="small" sx={{ mr: theme.spacing.md }} />
          <OverflowTip title={getViewName(t, activeView)}>
            <span>{getViewName(t, activeView)}</span>
          </OverflowTip>
          {activeView?.name && !isActiveViewShared ? (
            <IconButton
              sx={{ ml: "auto" }}
              className="delete"
              onClick={(e) => editView(activeView)}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          ) : null}
        </CurrentView>
      </ViewListHeader>

      <Separator />

      {isContractAnalysisViewOptionVisible && (
        <>
          <ViewsList>
            <BaseDropDownButtonWithIcon
              ref={myViewsAnchorRef}
              aria-label="contractAnalysisViewButton"
              onClick={handleOnAiViewsClick}
            >
              <AiIconWrapper>
                <Tag variant="ai" />
              </AiIconWrapper>
              <span>{t("pages.contracts.header.contractAnalysisView")}</span>
            </BaseDropDownButtonWithIcon>
          </ViewsList>
          <Separator />
        </>
      )}

      {activeView ? (
        <>
          <BaseDropDownButtonItem
            id="defaultView"
            icon={<LogoIcon />}
            itemClassName={classes.baseLinkButton}
            textKey="pages.contracts.header.defaultView"
            onClick={() => setView(null)}
          />
          <Separator />
        </>
      ) : null}

      {personalCombinedViews.length ? (
        <ViewsList>
          <BaseDropDownButtonWithIcon
            onClick={handleMyViewsClick}
            ref={myViewsAnchorRef}
            isSubMenuOpened={openMyViews}
            aria-label="myViewsButton"
          >
            <LockOutlinedIcon />
            <span>{t("pages.contracts.header.myViews")}</span>
            <ArrowIcon />
          </BaseDropDownButtonWithIcon>
          <Menu
            aria-label="myViewsMenu"
            open={openMyViews}
            onClose={handleMyViewsClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: showContractAddButton ? "right" : "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: showContractAddButton ? "left" : "right",
            }}
            anchorEl={myViewsAnchorRef.current}
            PaperProps={{
              style: {
                width: "185px",
                maxHeight: "80vh",
                marginLeft: "1px",
                boxShadow: theme.shadow.standard,
              },
            }}
          >
            {personalCombinedViews?.map((view) => (
              <BaseDropDownButtonItemWithActions
                key={view.id}
                id={view.id}
                text={view.name}
                itemClassName={classes.baseLinkButton}
                onEditClick={() => editView(view)}
                onClick={() => setView(view.id)}
                icon={
                  view.shared ? (
                    <GroupsOutlinedIcon />
                  ) : (
                    <PermIdentityOutlinedIcon fontSize="small" />
                  )
                }
              />
            ))}
          </Menu>
        </ViewsList>
      ) : null}

      {sharedWithMeViews.length ? (
        <>
          <ViewsList>
            {sharedWithMeViews ? (
              <BaseDropDownButtonWithIcon
                onClick={handleSharedClick}
                ref={sharedViewsAnchorRef}
                isSubMenuOpened={openSharedViews}
                aria-label="sharedViewsButton"
              >
                <FolderSharedOutlinedIcon />
                <span>{t("pages.contracts.header.sharedViews")}</span>
                <ArrowIcon />
              </BaseDropDownButtonWithIcon>
            ) : null}
            <Menu
              aria-label="sharedViewsMenu"
              open={openSharedViews}
              onClose={handleSharedClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: showContractAddButton ? "right" : "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: showContractAddButton ? "left" : "right",
              }}
              anchorEl={sharedViewsAnchorRef.current}
              PaperProps={{
                style: {
                  width: "185px",
                  maxHeight: "80vh",
                  marginLeft: "1px",
                  boxShadow: theme.shadow.standard,
                },
              }}
            >
              {sharedWithMeViews?.map((view) => (
                <BaseDropDownButtonItemWithActions
                  key={view.id}
                  id={view.id}
                  text={view.name}
                  itemClassName={classes.baseLinkButton}
                  onClick={() => setView(view.id)}
                />
              ))}
            </Menu>
          </ViewsList>
          <Separator />
        </>
      ) : null}

      {viewHasChanged && !activeView ? (
        <BaseDropDownButtonItem
          id="resetChanges"
          icon={
            viewHasChanged ? <ViewWarningIcon fontSize="small" /> : undefined
          }
          itemClassName={classes.baseLinkButton}
          textKey="pages.contracts.header.resetChanges"
          onClick={() => setView(null)}
        />
      ) : null}

      {canSaveChanges ? (
        <BaseDropDownButtonItem
          id="saveChanges"
          icon={
            viewHasChanged ? <ViewWarningIcon fontSize="small" /> : undefined
          }
          itemClassName={classes.baseLinkButton}
          textKey="pages.contracts.header.saveChanges"
          onClick={() => saveCurrentView()}
        />
      ) : null}

      <BaseDropDownButtonItem
        id="saveNewView"
        icon={<SaveAltOutlinedIcon />}
        itemClassName={classes.baseLinkButton}
        textKey="pages.contracts.header.saveAsNewView"
        onClick={() => saveNewView()}
      />

      {hasFeature(Features.EXCEL_EXPORTS) ? (
        <BaseDropDownButtonItem
          id="exportView"
          icon={<CloudDownloadIcon fontSize="small" />}
          itemClassName={classes.baseLinkButton}
          textKey="pages.contracts.header.exportView"
          onClick={() => exportView()}
        />
      ) : null}
    </List>
  );
};
