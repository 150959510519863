import stringComparison from "string-comparison";
import { ContactDuplicatesDTO, TextDatapointDTO } from "openapi";
import { ContactDataDTO } from "../../ContactDataDTO";
import { Duplicate } from "./DuplicatesModal";

export const isNonDuplicate = (
  contactFirst: ContactDataDTO,
  contactSecond: ContactDataDTO,
  nonDuplicates: ContactDuplicatesDTO
): boolean => {
  const firstContactDuplicates =
    nonDuplicates?.data && nonDuplicates?.data[contactFirst.id];
  return (
    Array.isArray(firstContactDuplicates) &&
    firstContactDuplicates.includes(contactSecond.id)
  );
};

export const doesDuplicateExist = (
  contactFirst: ContactDataDTO,
  contactSecond: ContactDataDTO,
  duplicatesRecords: Duplicate[]
) => {
  return !!duplicatesRecords.find(
    (duplicate) =>
      (duplicate.first === contactFirst.id &&
        duplicate.second === contactSecond.id) ||
      (duplicate.first === contactSecond.id &&
        duplicate.second === contactFirst.id)
  );
};

export const extractNameValue = (contact: ContactDataDTO): string => {
  const value = (contact?.name as TextDatapointDTO)?.value?.value;
  if (typeof value === "string") return value;
  return "";
};

export const getContactDistance = (
  contactFirst: ContactDataDTO,
  contactSecond: ContactDataDTO,
  distanceThreshold: number
) => {
  const compare = stringComparison.jaccardIndex;
  const firstNameValue = extractNameValue(contactFirst);
  const secondNameValue = extractNameValue(contactSecond);
  const distance = compare.distance(firstNameValue, secondNameValue);
  return { isDuplicate: distance < distanceThreshold, distance: distance };
};

export const findDuplicates = (
  contacts: ContactDataDTO[],
  nonDuplicates: ContactDuplicatesDTO
) => {
  const duplicatesRecords: Duplicate[] = [];
  const duplicateLimit = 50;
  const distanceThreshold = 0.15;

  for (let currentIndex = 0; currentIndex < contacts.length; currentIndex++) {
    const contactFirst = contacts[currentIndex];

    if (duplicatesRecords.length > duplicateLimit) {
      break;
    }

    for (
      let compareIndex = currentIndex + 1;
      compareIndex < contacts.length;
      compareIndex++
    ) {
      const contactSecond = contacts[compareIndex];

      if (
        isNonDuplicate(contactFirst, contactSecond, nonDuplicates) ||
        doesDuplicateExist(contactFirst, contactSecond, duplicatesRecords)
      ) {
        continue;
      }

      const { isDuplicate, distance } = getContactDistance(
        contactFirst,
        contactSecond,
        distanceThreshold
      );

      if (isDuplicate) {
        duplicatesRecords.push({
          first: contactFirst.id,
          second: contactSecond.id,
          distance,
        });
      }
    }
  }

  return duplicatesRecords;
};
