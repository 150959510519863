/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Discount = {
  id: string;
  name: string;
  discountType: Discount.discountType;
  discount: number;
  appliedOn: Array<string>;
};

export namespace Discount {
  export enum discountType {
    PERCENTAGE = "percentage",
    FIXED_AMOUNT = "fixed_amount",
  }
}
