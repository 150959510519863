import { UserInfoDto } from "openapi";

declare global {
  interface Window {
    dataLayer: Array<Record<string, unknown>>;
  }
}

export function setGtmDataLayer(userInfo?: UserInfoDto) {
  const eventType = userInfo?.userId ? "user-login" : "user-logout";

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventType,
    app_user_id: userInfo?.userId,
    app_user_email: userInfo?.email,
    app_user_phone: userInfo?.phone,
    app_user_name: userInfo?.fullName,
    app_user_firstname: userInfo?.firstname,
    app_user_lastname: userInfo?.lastname,
    app_user_team_id: userInfo?.defaultTeamId,
    app_user_created_at: userInfo?.userCreatedAt,
  });
}
