import React from "react";
import NotificationItem from "./NotificationItem";
import { NotificationDto } from "openapi";
import { Box } from "@mui/material";
import { CardBodyWrapper } from "components";

type Props = {
  handleClose?: () => void;
  notificationData?: NotificationDto;
};

const Notification = ({ handleClose, notificationData }: Props) => (
  <Box>
    {notificationData?.notifications.length !== 0 ? (
      <>
        {notificationData?.notifications.map((item, index) => (
          <NotificationItem
            key={index}
            value={item}
            handleClose={handleClose}
            isEven={index % 2 ? true : false}
          />
        ))}
      </>
    ) : (
      <CardBodyWrapper
        noHorizontalPadding
        textKey="notificationsModal.noNotificationMessage"
      />
    )}
  </Box>
);

export default Notification;
