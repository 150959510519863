import React, { FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid } from "@mui/material";
import { RegistrationDto, TeamPublicInfoDto, UserService } from "openapi";
import { FormTextField } from "components";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import { FormCheckbox } from "components/FormItems/FormCheckbox/FormCheckbox";
import {
  setInvalidTokenError,
  setValidationErrors,
} from "shared/service/errorResponseService";
import routePaths from "../../constants/routePaths";
import * as Yup from "yup";
import { useLocale } from "hooks/GlobalStateHooks";
import {
  nonFreeEmailVaidator,
  passwordValidator,
  phoneValidator,
} from "../../constants/utils";
import { useSnackbar } from "notistack";

const validationSchema = () =>
  Yup.object({
    invitationToken: Yup.string(),
    email: nonFreeEmailVaidator(),
    firstname: Yup.string().trim().required(),
    lastname: Yup.string().trim().required(),
    phone: phoneValidator(),
    password: passwordValidator(),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "pages.register.validation.passwordConfirmation"
    ),
    terms: Yup.boolean().oneOf(
      [true],
      "pages.register.validation.requiredTerms"
    ),
  });

const initialValues = {
  email: "",
  firstname: "",
  lastname: "",
  phone: "",
  password: "",
  passwordConfirmation: "",
  terms: false,
};

type RegisterFormProps = {
  invitationToken?: string;
  invitationInfo?: TeamPublicInfoDto;
};

const RegisterForm: FC<RegisterFormProps> = ({
  invitationToken,
  invitationInfo,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { locale } = useLocale();
  const navigate = useNavigate();
  const classes = useStyles();
  const [searchParams] = useSearchParams();

  const { control, handleSubmit, setError, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema()),
  });

  useEffect(() => {
    if (invitationInfo) {
      setValue("email", invitationInfo.email);
    }
  }, [invitationInfo]);

  const onSubmit = async (values: typeof initialValues) => {
    const { passwordConfirmation, terms, ...apiModel } = values;
    //@Todo check if chargebeeId is not needed - remove
    const chargebeeId = searchParams.get("chargebeeId") || undefined;

    try {
      const language = locale as unknown as RegistrationDto.locale;
      const response = await UserService.register({
        ...apiModel,
        //@Todo check if chargebeeId is not needed update here and API accordingly
        chargebeeId,
        locale: language,
        invitationToken: invitationToken,
      });

      if (response.emailVerified) {
        navigate(routePaths.LOGIN);
        enqueueSnackbar(t("pages.register.success"), { variant: "success" });
      } else {
        navigate(routePaths.VERIFICATION_EMAIL, {
          state: { email: values.email },
        });
      }
    } catch (e) {
      setInvalidTokenError(e, "pages.register", enqueueSnackbar, t);
      setValidationErrors(
        e,
        setError,
        "pages.register",
        undefined,
        enqueueSnackbar,
        t
      );
    }
  };

  return (
    <>
      <form
        name="registerForm"
        style={{ width: "100%", display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <FormTextField
          sx={{ mt: ".5rem" }}
          control={control}
          name="email"
          label={t("pages.register.form.emailAddress")}
          type="email"
          autoComplete="email"
          disabled={invitationInfo && !!invitationInfo.email}
          required
        />
        <Grid container spacing={1.25}>
          <Grid item xs={12} md={6}>
            <FormTextField
              sx={{ mt: ".5rem" }}
              control={control}
              name="firstname"
              label={t("pages.register.form.firstName")}
              autoComplete="given-name"
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              sx={{ mt: ".5rem" }}
              control={control}
              name="lastname"
              label={t("pages.register.form.lastName")}
              autoComplete="family-name"
              required
            />
          </Grid>
        </Grid>
        <FormTextField
          sx={{ mt: ".5rem" }}
          control={control}
          name="phone"
          label={t("pages.register.form.phone")}
          type="tel"
          autoComplete="tel"
          required
        />
        <Grid container spacing={1.25}>
          <Grid item xs={12} md={6}>
            <FormTextField
              sx={{ mt: ".5rem" }}
              control={control}
              name="password"
              label={t("pages.register.form.password")}
              type="password"
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              sx={{ mt: ".5rem" }}
              control={control}
              name="passwordConfirmation"
              label={t("pages.register.form.passwordConfirm")}
              type="password"
              required
            />
          </Grid>
        </Grid>
        <div style={{ margin: ".5rem 0" }}>
          <FormCheckbox
            id="terms"
            control={control}
            name="terms"
            label={
              <Trans i18nKey="pages.register.termsOfService">
                <a
                  href="https://www.contracthero.com/agb"
                  className="hover-underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("pages.register.terms")}
                </a>
                <a
                  id="passwordConfirmation-helper-text"
                  className="hover-underline"
                  href="https://www.contracthero.com/datenschutzerklaerung-webapp"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("pages.register.policy")}
                </a>
              </Trans>
            }
            required
          />
        </div>
        <Button
          name="registerBtn"
          type="submit"
          fullWidth
          size="large"
          className={classes.baseButton}
        >
          {t("pages.register.form.submit")}
        </Button>
      </form>
    </>
  );
};

export default RegisterForm;
