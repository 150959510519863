import styled from "@emotion/styled";
import { theme } from "theme";
import CHLockIcon from "assets/svg/lock-icon.svg?react";
import CHInfoIcon from "assets/svg/info-icon.svg?react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

export const Table = styled.form`
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

export const Title = styled.div`
  font-weight: ${theme.font.weight.medium};
  margin-bottom: ${theme.spacing.lg};
  background-color: ${theme.color.gray[100]};
  padding: 1rem;
  color: ${theme.color.gray[800]};

  :nth-of-type(1) {
    border-top-left-radius: ${theme.borderRadius};
    border-bottom-left-radius: ${theme.borderRadius};
  }

  :nth-of-type(2) {
    border-top-right-radius: ${theme.borderRadius};
    border-bottom-right-radius: ${theme.borderRadius};
  }
`;

export const Wrapper = styled.div`
  margin-top: ${theme.spacing.xl};
`;

export const RolesAndPermissions = styled.form`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.lg} 0;

  label {
    height: 0;
  }
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacing.md};
  gap: ${theme.spacing.md};
`;

export const BackButton = styled.div`
  margin-bottom: ${theme.spacing.lg};
`;

export const Heading = styled.div`
  font-size: ${theme.font.size.body};
  margin-bottom: ${theme.spacing.xs};
`;

export const Name = styled.div`
  font-size: ${theme.font.size.body};
  font-weight: ${theme.font.weight.bold};
`;

export const AlertWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.lg};
  border: 2px solid ${theme.color.red[400]};
  border-radius: ${theme.borderRadius};
  background: ${theme.color.red[200]};
`;

export const AlertText = styled.div`
  flex: 1 1 auto;
  & h3 {
    margin: 0;
  }
`;

export const AlertIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin-right: 24px;
  flex: 0 0 auto;
  background: ${theme.color.red[600]};
`;

export const TitleWrapper = styled.h3`
  line-height: 1.5rem;
  font-weight: ${theme.font.weight.medium};
  color: ${theme.color.gray[900]};
  margin: 0 0 ${theme.spacing.md};
`;

export const SubTitleWrapper = styled.h4`
  line-height: 1.5rem;
  font-weight: ${theme.font.weight.bold};
  color: ${theme.color.gray[900]};
  margin: 0;
`;

export const SwitcherWrapper = styled.div`
  margin-bottom: ${theme.spacing.xl};
`;

export const FieldLabel = styled.p`
  margin: ${theme.spacing.md} 0 0;
`;

export const FormLabel = styled.p`
  line-height: 1.5rem;
  font-weight: ${theme.font.weight.medium};
  color: ${theme.color.gray[900]};
  margin: 0 0 ${theme.spacing.md};
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.lg};
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.semiBold};
  margin-bottom: ${theme.spacing.lg};
`;

export const SectionSubTitle = styled.div`
  font-size: ${theme.font.size.body};

  .title {
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.md};
  }
`;

export const LockIcon = styled(CHLockIcon)`
  width: 40px;
  height: 40px;
  fill: ${theme.color.white};
`;

export const SwitcherDescription = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  font-size: ${theme.font.size.body};

  div {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.sm};
  }
`;

export const InfoIcon = styled(CHInfoIcon)`
  width: 14px;
  height: 14px;
  fill: ${theme.color.blue[600]};
  margin-right: ${theme.spacing.sm};
`;

const commonHeaderStyles = `
  padding: ${theme.spacing.xl} 0;

  .title {
    font-size: 0.9rem;
    font-weight: ${theme.font.weight.semiBold};
  }

  .description {
    font-size: ${theme.font.size.body};
    font-weight: ${theme.font.weight.normal};
    margin-top: ${theme.spacing.sm};
  }
`;

export const MemberRoles = styled.div`
  ${commonHeaderStyles}
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${theme.borderRadius};
  background-color: ${theme.color.gray[100]};
  color: ${theme.color.gray[900]};
  padding: ${theme.spacing.lg};
  margin-bottom: ${theme.spacing.lg};
  font-size: ${theme.font.size.caption};
  width: fit-content;
`;

export const CategoryHeader = styled.div`
  ${commonHeaderStyles}
`;

export const RolesContainer = styled(OverlayScrollbarsComponent)`
  overflow-y: auto;
  max-height: calc(100vh - 500px);
  @media (max-height: 700px) {
    max-height: 240px;
  }
`;

export const VisibilityWrapper = styled.div<{
  disabled?: boolean;
}>`
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;
