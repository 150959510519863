import styled from "@emotion/styled";
import { Card } from "@mui/material";
import { theme } from "theme";

export const Wrapper = styled(Card)`
  border-radius: ${theme.borderRadius};
  border-color: ${theme.color.gray[200]};
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  height: 100%;
`;
