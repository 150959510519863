import React from "react";
import { useTranslation } from "react-i18next";
import { Wrapper, List, ListItem, IconWrapper } from "./styles";
import { Tooltip } from "@mui/material";

import ContractIcon from "assets/svg/contract-icon.svg?react";
import TasksIcon from "assets/svg/check-square-icon.svg?react";
import SignatureIcon from "assets/svg/signature-icon.svg?react";
import LogIcon from "assets/svg/log-icon.svg?react";

export type TabProps = {
  id: number;
  key: string;
};

type StickyMenuProps = {
  currentSelectedTab: TabProps;
  handleSelectedTab: (val: number, key: string) => void;
  provider?: string;
};

export const options = [
  {
    id: 1,
    key: "documents",
    icon: ContractIcon,
  },
  {
    id: 2,
    key: "tasks",
    icon: TasksIcon,
  },
  {
    id: 3,
    key: "esignature",
    icon: SignatureIcon,
  },
  {
    id: 4,
    key: "log",
    icon: LogIcon,
  },
] as const;

const StickyMenu = ({
  currentSelectedTab,
  handleSelectedTab,
  provider,
}: StickyMenuProps) => {
  const { t } = useTranslation();

  const hiddenButtons: string[] = [];

  if (!provider) {
    const item = options.find((item) => item.key === "esignature");
    if (item) {
      hiddenButtons.push(item?.key);
    }
  }

  const availableOptions = options.filter(
    (item) => !hiddenButtons.includes(item.key)
  );

  return (
    <Wrapper>
      <List>
        {availableOptions.map((item) => {
          const Icon = item.icon;
          // @TODO: Show only the tabs which are available for current user (based on permissions system).
          return (
            <ListItem
              key={item.key}
              active={currentSelectedTab.id === item.id}
              onClick={() => handleSelectedTab(item.id, item.key)}
            >
              <Tooltip
                title={t(`pages.contractDetails.folders.${item.key}`)}
                disableInteractive
                placement="left"
              >
                <IconWrapper>
                  <Icon />
                </IconWrapper>
              </Tooltip>
            </ListItem>
          );
        })}
      </List>
    </Wrapper>
  );
};

export default StickyMenu;
