import React from "react";
import { NavLink as Link } from "react-router-dom";
import { Button, Icon, Label } from "./styles";

export type Props = {
  id: string;
  to: string;
  icon: JSX.Element;
  label: string;
  replace?: boolean;
  showLabel?: boolean;
  onClick?: () => void;
};

const LinkButton = ({
  id,
  to,
  icon,
  label,
  replace,
  showLabel = true,
  onClick,
  ...props
}: Props) => (
  <Link to={to} replace={replace} {...props}>
    {(linkProps) => {
      return (
        <Button active={linkProps.isActive.toString()} onClick={onClick}>
          <Icon active={linkProps.isActive.toString()}>{icon}</Icon>
          {showLabel && (
            <Label active={linkProps.isActive.toString()}>{label}</Label>
          )}
        </Button>
      );
    }}
  </Link>
);

export default LinkButton;
