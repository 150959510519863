import React, { forwardRef, SVGProps } from "react";

const CheckedIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  (props, ref) => {
    return (
      <svg
        ref={ref}
        width="20"
        height="20"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M9 0.25C4.16797 0.25 0.25 4.16797 0.25 9C0.25 13.832 4.16797 17.75 9 17.75C13.832 17.75 17.75 13.832 17.75 9C17.75 4.16797 13.832 0.25 9 0.25ZM12.7793 6.14258L8.66601 11.8457C8.60852 11.9259 8.53273 11.9913 8.44493 12.0364C8.35713 12.0815 8.25984 12.1051 8.16113 12.1051C8.06242 12.1051 7.96513 12.0815 7.87733 12.0364C7.78953 11.9913 7.71374 11.9259 7.65625 11.8457L5.2207 8.4707C5.14648 8.36719 5.2207 8.22266 5.34765 8.22266H6.26367C6.46289 8.22266 6.65234 8.31836 6.76953 8.48242L8.16015 10.4121L11.2305 6.1543C11.3477 5.99219 11.5352 5.89453 11.7363 5.89453H12.6523C12.7793 5.89453 12.8535 6.03906 12.7793 6.14258Z"
          fill="#218650"
        />
      </svg>
    );
  }
);
CheckedIcon.displayName = "CheckedIcon";
export default CheckedIcon;
