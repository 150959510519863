import React from "react";
import { AnalysisCategoryMappingDto } from "openapi/models/AnalysisCategoryMappingDto";
import { AnalysisFieldMappingDto } from "openapi/models/AnalysisFieldMappingDto";

type Props = {
  analysisCategoryMapping: AnalysisCategoryMappingDto[];
  analysisFieldMapping: AnalysisFieldMappingDto[];
};

export const AnalyzeMapping = ({
  analysisCategoryMapping,
  analysisFieldMapping,
}: Props) => {
  const categoriesCategory = Array.from(
    new Set(analysisCategoryMapping.map((item) => item.blueprint))
  );
  const categoriesField = Array.from(
    new Set(analysisFieldMapping.map((item) => item.category))
  );

  return (
    <>
      <div>
        <h2>Category mapping</h2>
        {categoriesCategory.map((category) => {
          const output = [<h3 key={category}>{category}</h3>];
          const categoryMappings = analysisCategoryMapping.filter(
            (item) => item.blueprint === category
          );
          if (categoryMappings.length > 0) {
            for (const categoryMapping of categoryMappings) {
              output.push(
                <div key={categoryMapping.category}>
                  - {categoryMapping.category} {categoryMapping.count}
                </div>
              );
            }
          }
          return output;
        })}
      </div>
      <div>
        <h2>Field mapping</h2>
        {categoriesField.map((category) => {
          const output = [<h3 key={category}>{category}</h3>];
          const fieldMappings = analysisFieldMapping.filter(
            (item) => item.category === category
          );
          if (fieldMappings.length > 0) {
            for (const fieldMapping of fieldMappings) {
              output.push(
                <div key={fieldMapping.oldFieldName}>
                  - {fieldMapping.analysisField} {fieldMapping.oldFieldName}{" "}
                  {fieldMapping.count}
                </div>
              );
            }
          }
          return output;
        })}
      </div>
    </>
  );
};
