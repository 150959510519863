import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SwitchBar } from "new-components";
import { Wrapper, Title } from "./styles";

type Prop = {
  titleKey: string;
  setShowPercent: React.Dispatch<React.SetStateAction<boolean>>;
  numberOfContracts: number;
  icon?: JSX.Element;
};

const CardCategoryHeaderWrapper = ({
  titleKey,
  setShowPercent,
  numberOfContracts,
  icon,
}: Prop) => {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState("count");
  useEffect(() => setShowPercent(viewMode === "percent"), [viewMode]);
  return (
    <Wrapper>
      <Title>
        {icon ? icon : <></>}
        <span>{t(titleKey)}</span>
      </Title>
      {!!numberOfContracts && (
        <SwitchBar
          activeKey={viewMode}
          setActiveKey={setViewMode}
          options={[
            {
              key: "count",
              label: t("common.count"),
              active: viewMode === "count",
            },
            {
              key: "percent",
              label: t("common.percent"),
              active: viewMode === "percent",
            },
          ]}
        />
      )}
    </Wrapper>
  );
};

export default CardCategoryHeaderWrapper;
