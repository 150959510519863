import * as React from "react";
import CardWrapper from "../../../components/CardWrapper/CardWrapper";
import CardHeaderWrapper from "../../../components/CardHeaderWrapper/CardHeaderWrapper";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import CardBodyWrapper from "../../../components/CardCenteredBodyWrapper/CardCenteredBodyWrapper";
import { NotificationItemDto } from "openapi";
import { Box } from "@mui/material";
import ActivityItem from "./ActivityItem";
import SectionTableWrapper from "../SectionTableWrapper";
import { theme } from "theme";
import { useTranslation } from "react-i18next";

export type ActivityProps = {
  data: NotificationItemDto[] | undefined;
};

const Activity = ({ data }: ActivityProps) => {
  const { t } = useTranslation();
  return (
    <CardWrapper
      isShowTopAndBottomPaddingOnly={data ? "true" : undefined}
      data-testid="activity-widget"
    >
      <>
        <Box sx={{ padding: data ? "0 1.25rem 1rem 1.25rem" : "none" }}>
          <CardHeaderWrapper
            title={t("pages.dashboard.activity.title")}
            titleStyles={{ color: theme.color.gray[600] }}
            icon={<RssFeedIcon style={{ color: theme.color.gray[600] }} />}
          />
        </Box>
        {data != null && data.length > 0 ? (
          <SectionTableWrapper maxHeight={360} scrollable>
            {data.map((item, index) => {
              return <ActivityItem key={index} index={index} data={item} />;
            })}
          </SectionTableWrapper>
        ) : (
          <CardBodyWrapper
            textKey="pages.dashboard.activity.description"
            data-testid="activity-widget-description"
          />
        )}
      </>
    </CardWrapper>
  );
};

export default Activity;
