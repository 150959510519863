import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import {
  ApiError,
  CategoryCreateDTO,
  CategoryDTO,
  OrganizationNewService,
  TranslationDTO,
} from "openapi";
import { useTeam } from "contexts/team/hooks";
import { CTAButton, ColorDropdown, LabelWrapper, NewModal } from "components";
import { FormTextField } from "components";
import theme from "theme/theme";
import { getDefaultSections } from "../../../utils";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  FormSelect,
  FormSelectItem,
} from "components/FormItems/FormSelect/FormSelect";
import { BadRequestResponse } from "../../../../../../../shared/types/ErrorResponse.type";
import { CommonService } from "openapi/services/CommonService";
import { useLocale } from "hooks";
import { ButtonsWrapper, FormSection, Grid, Row } from "../styles";
import EditIcon from "assets/svg/edit-icon.svg?react";
import { FormCheckbox } from "components/FormItems";
import { useOrganizationAllTeamsQuery } from "shared/api/organization";
import CircularLoading from "components/CircularLoading/CircularLoading";

export type AddNewCategoryProps = {
  open: boolean;
  onClose: (category?: CategoryDTO) => void;
  categories: CategoryDTO[];
};

export type selectedTeamsType = { [key: string]: boolean };

export type AddNewCategoryForm = CategoryCreateDTO & {
  categoryId: string;
};

const AddNewCategory = ({ open, onClose, categories }: AddNewCategoryProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { selectedTeamId, organizationId } = useTeam();
  const { locale } = useLocale();

  const [categoryOptions, setCategoryOptions] = useState<FormSelectItem[]>([]);
  const [nameTranslation, setNameTranslation] = useState<string>("");
  const [nameTranslationEdit, setNameTranslationEdit] =
    useState<boolean>(false);
  const [checkedBoxes, setCheckedBoxes] = useState<selectedTeamsType>({});
  const [isTeamSelected, setIsTeamSelected] = useState<boolean>(true);

  const sourceLanguage = locale === "en" ? "en" : "de";
  const targetLanguage = locale === "en" ? "de" : "en";

  const validationSchema = () =>
    Yup.object().shape({
      name: Yup.object().shape({
        [sourceLanguage ?? targetLanguage]: Yup.string().required(),
      }),
    });

  const { control, handleSubmit, getValues, reset, setError, setValue } =
    useForm<AddNewCategoryForm>({
      resolver: yupResolver(validationSchema()),
    });

  const { data: subTeams, isLoading: isTeamsLoading } =
    useOrganizationAllTeamsQuery(organizationId);

  useEffect(() => {
    fillCategories(categories);
  }, [categories]);

  useEffect(() => {
    const numberOfSelectedTeams = getSelectedTeams()?.length;
    setIsTeamSelected(!!numberOfSelectedTeams);
  }, [checkedBoxes]);

  useEffect(() => {
    onSelectAll();
  }, [subTeams]);

  const fillCategories = (categories: CategoryDTO[]) => {
    setCategoryOptions(
      categories.map((item) => {
        return {
          key: item.id,
          value: item.name[locale],
          color: item.color,
        };
      })
    );
  };

  const getSelectedTeams = () => {
    const data = getValues();
    if (!data.teams) {
      if (subTeams && subTeams.length <= 1) {
        return [subTeams[0].id];
      }
      return;
    }
    const selectedTeams: string[] = Object.keys(data.teams).filter(
      (item) => data.teams[item as keyof typeof data.teams]
    );
    return selectedTeams;
  };

  const onSubmit = async () => {
    const data = getValues();
    const fields = await OrganizationNewService.getFieldsOfOrganization(
      organizationId
    );

    if (fields?.length < 1) return;

    if (!data.name[targetLanguage] && nameTranslation) {
      setValue(`name.${targetLanguage}`, nameTranslation);
    }

    let initSections;
    if (data.categoryId) {
      initSections = categories.find(
        (item) => item.id === data.categoryId
      )?.sections;
    } else {
      initSections = getDefaultSections(selectedTeamId, fields);
    }

    const color = data?.color;

    const teams = getSelectedTeams();

    const requestBody = {
      name: {
        [sourceLanguage]: data.name[sourceLanguage],
        [targetLanguage]: data.name[targetLanguage],
      },
      color,
      sections: initSections,
      teams,
    } as CategoryCreateDTO;

    try {
      const category = await OrganizationNewService.createCategory(
        organizationId,
        requestBody
      );

      enqueueSnackbar(
        t(
          "pages.settings.organization.administrator.categories.modal.create.success"
        ),
        {
          variant: "success",
        }
      );
      reset();
      handleOnClose(category);
      setCheckedBoxes({});
      onSelectAll();
    } catch (error) {
      if (
        error instanceof ApiError &&
        (error.body as BadRequestResponse).error === "duplicated_category_name"
      ) {
        setError("name.de", {
          type: "custom",
          message:
            "pages.settings.organization.administrator.errors.duplicatedCategoryName",
        });
        setError("name.en", {
          type: "custom",
          message:
            "pages.settings.organization.administrator.errors.duplicatedCategoryName",
        });
        return;
      }

      enqueueSnackbar(
        t(
          "pages.settings.organization.administrator.categories.modal.create.failure"
        ),
        {
          variant: "error",
        }
      );
      reset();
      handleOnClose();
    }
  };

  const handleOnClose = (category?: CategoryDTO) => {
    reset();
    onClose(category);
    setNameTranslation("");
    setNameTranslationEdit(false);
    setCheckedBoxes({});
    setIsTeamSelected(false);
    onSelectAll();
  };

  const getTranslation = async () => {
    const data = getValues();

    if (!data.name || !data.name[sourceLanguage]) return;

    if (data.name[sourceLanguage] === "") {
      setNameTranslation("");
      return;
    }
    const requestBody = {
      text: data.name[locale],
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
    };

    try {
      const translatedName = await CommonService.translateText(
        requestBody as TranslationDTO
      );
      setNameTranslation(translatedName.text);
    } catch (error) {
      enqueueSnackbar(t("pages.categoryDetails.messages.translationFailed"), {
        variant: "error",
      });
    }
  };

  const toggleEdit = () => {
    setNameTranslationEdit(!nameTranslationEdit);
  };

  const onNameInput = () => {
    if (nameTranslationEdit) return;

    const debounceTimerId = setTimeout(async () => {
      await getTranslation();
    }, 1000);

    return () => {
      clearTimeout(debounceTimerId);
    };
  };

  const onClearAll = () => {
    const data = getValues();
    const updatedTeams = Object.keys(data.teams).forEach((team) => {
      setValue(`teams.${team}` as `teams.${number}`, "");
    });

    setCheckedBoxes((prevCheckedBoxes) => ({
      ...prevCheckedBoxes,
      ...(updatedTeams as unknown as selectedTeamsType),
    }));
  };

  const onSelectAll = () => {
    const updatedTeams = subTeams?.forEach((team) => {
      setValue(`teams.${team.id}` as `teams.${number}`, "true");
    });

    setCheckedBoxes((prevCheckedBoxes) => ({
      ...prevCheckedBoxes,
      ...(updatedTeams as unknown as selectedTeamsType),
    }));
  };

  const onChangeCallback = () => {
    const data = getValues();
    setCheckedBoxes((prevCheckedBoxes) => ({
      ...prevCheckedBoxes,
      ...(data.teams as unknown as selectedTeamsType),
    }));
  };

  if (isTeamsLoading) return <CircularLoading isLoading={isTeamsLoading} />;

  const isModalBig = subTeams && subTeams.length > 11;

  return (
    <NewModal
      open={open}
      handleClose={() => handleOnClose()}
      title={t(
        "pages.settings.organization.administrator.categories.modal.create.title"
      )}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: isModalBig ? "1000px" : "600px",
        },
      }}
      body={
        <form
          id="contractCategoryForm"
          name="contractCategoryForm"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          aria-label="add-new-category-modal"
          data-testid="add-new-category-modal"
        >
          <FormSection>
            {t(
              "pages.settings.organization.administrator.categories.modal.create.description"
            )}
            <Row alignItems="flex-start" maxWidth="600px">
              <FormTextField
                control={control}
                name={`name.${sourceLanguage}`}
                label={`${t("pages.contractEdit.modals.category.name")} (${t(
                  `common.languages.${sourceLanguage}`
                )})`}
                required
                onKeyDown={() => {
                  onNameInput();
                }}
              />
              <ColorDropdown
                name="color"
                label={t("pages.contractEdit.modals.category.color")}
                control={control}
              />
            </Row>

            {nameTranslation && !nameTranslationEdit ? (
              <Row
                justifyItems="flex-start"
                maxWidth="600px"
                gap={theme.spacing.xs}
              >
                <span>
                  Category name ({t(`common.languages.${targetLanguage}`)}):{" "}
                  <strong>{nameTranslation}</strong>
                </span>
                <IconButton onClick={toggleEdit}>
                  <EditIcon fill={theme.color.blue[600]} />
                </IconButton>
              </Row>
            ) : null}

            {nameTranslationEdit ? (
              <FormTextField
                control={control}
                name={`name.${targetLanguage}`}
                label={`${t("pages.contractEdit.modals.category.name")} (${t(
                  `common.languages.${targetLanguage}`
                )})`}
                defaultValue={nameTranslation ?? ""}
                required
              />
            ) : null}
          </FormSection>

          <FormSection>
            <LabelWrapper style={{ marginBottom: 0 }}>
              {t("pages.contractEdit.modals.category.copy")}
            </LabelWrapper>
            <FormSelect
              name="categoryId"
              options={categoryOptions}
              label={t(
                "pages.settings.organization.administrator.categories.modal.create.template"
              )}
              control={control}
              style={{ maxWidth: "600px" }}
            />
          </FormSection>

          {subTeams && subTeams.length > 1 ? (
            <FormSection>
              <LabelWrapper style={{ marginBottom: 0 }}>
                {t("pages.contractEdit.modals.category.permissions")}
              </LabelWrapper>
              <Row>
                <span>
                  {t(
                    "pages.contractEdit.modals.category.permissionsDescription"
                  )}{" "}
                  *
                </span>
                <CTAButton
                  label="deselect-all-button"
                  type="button"
                  name={t(
                    "pages.contractEdit.modals.category.permissionsDeselectAll"
                  )}
                  disabled={!isTeamSelected}
                  onClick={onClearAll}
                  variant="tertiary"
                />
              </Row>

              <Grid numberOfColumns={isModalBig ? "4" : "2"}>
                {subTeams?.map((item) => {
                  return (
                    <FormCheckbox
                      control={control}
                      name={`teams.${item.id}` as `teams.${number}`}
                      label={item.name}
                      key={item.id}
                      onChangeCallback={onChangeCallback}
                    />
                  );
                })}
              </Grid>
            </FormSection>
          ) : null}

          <ButtonsWrapper>
            <CTAButton
              label="cancelAddCategoryButton"
              onClick={() => {
                handleOnClose();
              }}
              name={t("common.buttons.cancel")}
              variant="secondary"
            />
            <CTAButton
              type="submit"
              label="addCategoryButton"
              name={t("common.buttons.create")}
              variant="primary"
              disabled={
                !nameTranslation ||
                (subTeams && subTeams.length > 1 && !isTeamSelected)
              }
            />
          </ButtonsWrapper>
        </form>
      }
    />
  );
};

export default AddNewCategory;
