import styled from "@emotion/styled";
import { breakpoints, theme } from "theme";

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: ${theme.spacing.xs};
  @media screen and (min-width: ${breakpoints.lg}) {
    align-items: center;
    flex-direction: row;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
`;

export const OrgWrapper = styled.div`
  display: inline-flex;
`;

export const RolesWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  flex-wrap: wrap;
`;
