import React from "react";
import { Role } from "components";
import { useTranslation } from "react-i18next";
import { OrgWrapper, Wrapper, Title, RolesWrapper } from "./styles";
import StatusLabel from "../StatusLabel/StatusLabel";
import TypeLabel from "../TypeLabel/TypeLabel";
import {
  InvitationLogDTO,
  PermissionChangeLogDTO,
  RoleDTO,
  TeamDTOV1,
} from "openapi";
import { getNameWithSuffix } from "pages/Settings/OrganizationSettings/Organization/helper";

export type InvitationEditType = {
  roles: RoleDTO[];
  data: InvitationLogDTO;
  event: PermissionChangeLogDTO.event;
  organizationName: string;
  teamsData: TeamDTOV1[];
};

const InvitationEdit = ({
  roles,
  data,
  event,
  organizationName,
  teamsData,
}: InvitationEditType) => {
  const { t } = useTranslation();

  const isOwner = data.owner;

  const INVITATION_TO = t(
    "pages.settings.organization.permissionChangeLog.messages.invitationTo"
  );
  const THE_INVITATION = t(
    "pages.settings.organization.permissionChangeLog.messages.theInvitation"
  );
  const GRANTING = t(
    "pages.settings.organization.permissionChangeLog.messages.granting"
  );
  const FULL_OWNERSHIP = t(
    "pages.settings.organization.permissionChangeLog.messages.fullOwnership"
  );
  const ACCESS_TO = t(
    "pages.settings.organization.permissionChangeLog.messages.accessTo"
  );

  const getTitleByEventType = () => {
    switch (event) {
      case PermissionChangeLogDTO.event.INVITATION_SENT: {
        return (
          <Title>
            <StatusLabel
              title={t(
                `pages.settings.organization.permissionChangeLog.status.${event}`
              )}
              variant={event}
            />
            <span>{INVITATION_TO}</span> <span>{data.email}</span>{" "}
            <span>{GRANTING}</span>
            {isOwner ? (
              FULL_OWNERSHIP
            ) : (
              <>
                <TypeLabel variant="userRole" /> <span>{ACCESS_TO}</span>
              </>
            )}
          </Title>
        );
      }
      case PermissionChangeLogDTO.event.INVITATION_ACCEPTED: {
        return (
          <Title>
            <StatusLabel
              title={t(
                `pages.settings.organization.permissionChangeLog.status.${event}`
              )}
              variant={event}
            />
            <span>{THE_INVITATION}</span>
          </Title>
        );
      }
      case PermissionChangeLogDTO.event.INVITATION_DELETED: {
        return (
          <Title>
            <StatusLabel
              title={t(
                `pages.settings.organization.permissionChangeLog.status.${event}`
              )}
              variant={event}
            />
            <span>{INVITATION_TO}</span> <span>{data.email}</span>
          </Title>
        );
      }
      default: {
        return null;
      }
    }
  };

  const showOrganisation = isOwner && organizationName;
  const showRoles =
    !!data.roles.length &&
    !isOwner &&
    event !== PermissionChangeLogDTO.event.INVITATION_DELETED;

  return (
    <Wrapper>
      {getTitleByEventType()}
      {showOrganisation && (
        <OrgWrapper>
          <Role name={organizationName} />
        </OrgWrapper>
      )}

      {showRoles && (
        <RolesWrapper>
          {data.roles.map((item) => {
            const role = roles.find((role) => role.id === item.id);
            const roleName = role
              ? getNameWithSuffix(role?.name, role?.teamId, teamsData, t)
              : "-";
            return <Role key={item.id} name={roleName} />;
          })}
        </RolesWrapper>
      )}
    </Wrapper>
  );
};

export default InvitationEdit;
