import styled from "@emotion/styled";
import { theme } from "theme";
import CHUserIcon from "assets/svg/user-icon.svg?react";

export const Wrapper = styled.div`
  margin-top: ${theme.spacing.xl};
`;

export const Form = styled.form`
  margin: 0.5rem 0;
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacing.md};
  gap: ${theme.spacing.md};
`;

export const BackButton = styled.div`
  margin-bottom: ${theme.spacing.lg};
`;

export const Heading = styled.div`
  font-size: ${theme.font.size.body};
  margin-bottom: ${theme.spacing.xs};
`;

export const Caption = styled.div`
  color: ${theme.color.gray[600]};
  font-size: ${theme.font.size.caption};
  margin-bottom: ${theme.spacing.xs};
`;

export const Name = styled.div`
  font-size: ${theme.font.size.body};
  font-weight: ${theme.font.weight.bold};
`;

export const TitleWrapper = styled.h3`
  line-height: 1.5rem;
  font-weight: ${theme.font.weight.medium};
  color: ${theme.color.gray[900]};
  margin: 0 0 ${theme.spacing.md};
`;

export const SubTitleWrapper = styled.h4`
  line-height: 1.5rem;
  font-weight: ${theme.font.weight.bold};
  color: ${theme.color.gray[900]};
  margin: 0;
`;

export const FormLabel = styled.p`
  line-height: 1.5rem;
  font-weight: ${theme.font.weight.medium};
  color: ${theme.color.gray[900]};
  margin: 0 0 ${theme.spacing.md};
`;

export const IconWrapper = styled.span`
  padding: ${theme.spacing.sm};
  background-color: ${theme.color.blue[600]};
  display: flex;
  align-items: center;
  border-radius: ${theme.borderRadius};
  width: 40px;
  height: 40px;
`;

export const UserIcon = styled(CHUserIcon)`
  width: 24px;
  height: 24px;
  fill: ${theme.color.white};
`;

// @Note: Shared styles
export const Card = styled.div`
  padding: ${theme.spacing.xl};
  margin-bottom: ${theme.spacing.xl};
  background-color: ${theme.color.white};
  box-shadow: ${theme.shadow.standard};
  border-radius: ${theme.borderRadius};
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.lg};
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.semiBold};
  margin-bottom: ${theme.spacing.xl};
`;
