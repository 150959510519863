import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { theme } from "theme";
import { QesPreviewDescriptionContainer, SubTitleWrapper } from "./styles";
import { FC } from "react";

type Props = {
  setHideQesPreview: (value: boolean) => void;
  showQes: boolean;
};
const QesPreview: FC<Props> = ({ setHideQesPreview, showQes }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const handleDoNotShowMe = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHideQesPreview(!e.target.checked);
  };

  return showQes ? (
    <div style={{ paddingBottom: theme.spacing.xl }}>
      <Divider />
      <Accordion
        expanded={expanded}
        onChange={(event, expanded) => handleChange(event, expanded)}
      >
        <AccordionSummary
          style={{
            paddingLeft: "unset",
            paddingRight: "unset",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <SubTitleWrapper style={{ width: "100%" }}>
            {t(
              "pages.contractDetails.modals.requestSignature.previewQes.header"
            )}
          </SubTitleWrapper>
          <SubTitleWrapper style={{ textAlign: "right" }}>
            {t(
              "pages.contractDetails.modals.requestSignature.previewQes.disabled"
            )}
          </SubTitleWrapper>
        </AccordionSummary>
        <QesPreviewDescriptionContainer>
          <p>
            {t(
              "pages.contractDetails.modals.requestSignature.previewQes.description"
            )}
          </p>
          <FormControlLabel
            label={
              <span style={{ fontSize: theme.font.size.body }}>
                {t(
                  "pages.contractDetails.modals.requestSignature.previewQes.doNotShowMeLabel"
                )}
              </span>
            }
            control={<Checkbox onChange={handleDoNotShowMe} />}
          />
        </QesPreviewDescriptionContainer>
      </Accordion>
      <Divider />
    </div>
  ) : null;
};

export default QesPreview;
