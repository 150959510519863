/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ContractFieldCreateDTOV1,
  ContractFieldUpdateDTOV1,
  OrganizationNewService,
} from "openapi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useContractDefinitionsQuery = (
  organizationId: string | undefined
) => {
  return useQuery({
    queryKey: ["contract-definitions", organizationId],
    queryFn: () =>
      OrganizationNewService.getFieldsOfOrganization(organizationId!),
    enabled: !!organizationId,
  });
};

export const useContractDefinitionCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      organizationId: string;
      field: ContractFieldCreateDTOV1;
    }) => {
      return OrganizationNewService.createContractField(
        data.organizationId,
        data.field
      );
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["contract-definitions", variables.organizationId],
      });
    },
  });
};

export const useContractDefinitionDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { organizationId: string; fieldId: string }) => {
      return OrganizationNewService.deleteContractField(
        data.organizationId,
        data.fieldId
      );
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["contract-definitions", variables.organizationId],
      });
    },
  });
};

export const useContractDefinitionUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      organizationId: string;
      fieldId: string;
      field: ContractFieldUpdateDTOV1;
    }) => {
      return OrganizationNewService.updateContractField(
        data.organizationId,
        data.fieldId,
        data.field
      );
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["contract-definitions", variables.organizationId],
      });
    },
  });
};
