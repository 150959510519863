import styled from "@emotion/styled";
import { theme } from "theme";

export const MenuButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  transition: transform 0.25s ease-in-out;
  border-radius: ${theme.borderRadius};
  padding: ${theme.spacing.xs};
  cursor: pointer;

  svg {
    fill: ${theme.color.blue[600]};
  }

  ${({ active }: { active: boolean }) =>
    active &&
    `
    background-color: ${theme.color.blue[600]};

    svg {
      fill: ${theme.color.white};
    }
  `}
`;

export const MenuLabel = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};

  span {
    font-family: ${theme.font.family};
    font-size: ${theme.font.size.body};
    font-weight: ${theme.font.weight.semiBold};
    color: ${({ active }) =>
      active ? theme.color.white : theme.color.blue[600]};
    padding-right: ${theme.spacing.xs};
  }
`;

export const Title = styled.span`
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  font-size: ${theme.font.size.body};
  color: ${theme.color.gray[600]};
`;

export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
  padding: 0;
`;

export const MenuOption = styled.li<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.sm};
  flex-direction: row;
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  cursor: pointer;
  border-radius: ${theme.borderRadius};
  list-style-type: none;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  min-width: 160px;
  min-height: 32px;

  ${({ active }: { active: boolean }) =>
    !active &&
    `
    :hover {
      color: ${theme.color.white};
      background-color: ${theme.color.blue[500]};
    }
  `}

  ${({ active }: { active: boolean }) =>
    active &&
    `
    color: ${theme.color.white};
    background-color: ${theme.color.blue[600]};
  `}
`;

const arrowSize = "24px";

export const ArrowContainer = styled.div<{ orientation: "up" | "down" }>`
  width: ${arrowSize};
  height: ${arrowSize};
  transform: rotate(180deg);

  svg {
    fill: ${theme.color.white};
  }

  ${({ orientation }) =>
    orientation === "up" &&
    `
    transform: rotate(0deg);
  `}
`;
