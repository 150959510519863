import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "./styles";
import { Tooltip } from "@mui/material";

type VerifyTagProps = {
  onClick?: () => void;
  invisible?: boolean;
  verified?: boolean;
  disabled?: boolean;
};

const VerifyTag = ({
  onClick,
  verified,
  disabled,
  invisible,
}: VerifyTagProps) => {
  const { t } = useTranslation();

  const label = verified
    ? t("common.buttons.verified")
    : t("common.buttons.verify");

  const tooltipInfo = t("pages.contracts.tooltips.verifyContract");
  const tooltipAnalysisProcessing = t(
    "pages.contracts.tooltips.verifyContractProcessing"
  );

  const handleOnClick = () => {
    if (verified) return;
    onClick?.();
  };

  const disabledButton = (
    <Tooltip title={tooltipAnalysisProcessing}>
      <Button disabled={disabled}>
        <span>{t("common.buttons.verify")}</span>
      </Button>
    </Tooltip>
  );

  const button = (
    <Button
      type="button"
      onClick={handleOnClick}
      verified={verified}
      invisible={invisible}
    >
      {verified && <Icon />}
      <span>{label}</span>
    </Button>
  );

  const buttonWithTooltip = <Tooltip title={tooltipInfo}>{button}</Tooltip>;

  if (disabled) return disabledButton;

  return verified ? button : buttonWithTooltip;
};

export default VerifyTag;
