import styled from "@emotion/styled";
import { Chip } from "components";

export const ChipContainer = styled.div<{ isHeader: boolean }>`
  width: 100%;
  display: flex;

  ${({ isHeader }) =>
    isHeader &&
    `
    justify-content: center;
  `}
`;

export const StyledChip = styled(Chip)<{ isHeader: boolean }>`
  ${({ isHeader }) =>
    isHeader &&
    `
    width: 100%;
  `}
  max-width: 150px;
`;

export const EllipsisText = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
