/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NotificationItemDto = {
  notificationType: NotificationItemDto.notificationType;
  contractId: string | null;
  teamId: string | null;
  taskId: string | null;
  contractName: string | null;
  numberOfContracts: number | null;
  teamName: string | null;
  userName: string | null;
  taskName: string | null;
  read: boolean;
  createdAt: string;
  updatedAt: string;
};

export namespace NotificationItemDto {
  export enum notificationType {
    CONTRACT_CREATED = "CONTRACT_CREATED",
    CONTRACT_DELETED = "CONTRACT_DELETED",
    CONTRACT_EDITED = "CONTRACT_EDITED",
    CONTRACT_UPLOADED = "CONTRACT_UPLOADED",
    CONTRACT_SIGNED = "CONTRACT_SIGNED",
    CONTRACT_READY = "CONTRACT_READY",
    CONTRACT_REMINDER = "CONTRACT_REMINDER",
    CONTRACT_REMINDER_SET = "CONTRACT_REMINDER_SET",
    JOINED_TEAM = "JOINED_TEAM",
    CONTRACTS_IMPORTED = "CONTRACTS_IMPORTED",
    CONTRACT_SIGNING_FAILED = "CONTRACT_SIGNING_FAILED",
    BACKUP_FAILED = "BACKUP_FAILED",
    MONTHLY_SUMMARY = "MONTHLY_SUMMARY",
    CONTRACT_SIGNING_DECLINED = "CONTRACT_SIGNING_DECLINED",
    TASK_ASSIGNED = "TASK_ASSIGNED",
    TASK_CLOSED = "TASK_CLOSED",
    TASK_MEMBER_ASSIGNED = "TASK_MEMBER_ASSIGNED",
    TASK_DUE_DATE_CHANGED = "TASK_DUE_DATE_CHANGED",
    RETRYING_WEBHOOK_FAILED = "RETRYING_WEBHOOK_FAILED",
    TASK_DUE_DATE_REACHED = "TASK_DUE_DATE_REACHED",
  }
}
