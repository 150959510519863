import React, { SyntheticEvent } from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import { Autocomplete, AutocompleteProps } from "@mui/material";
import { StyledTextField } from "components/StyledComponents/StyledBaseFields";
import { isObject } from "mathjs";
import { Chip, ColoredPoint } from "components";
import { getColor } from "components/Chip/helpers";
import { theme } from "theme";
import { useTranslation } from "react-i18next";

export type FormMultiSelectItem = {
  key: string;
  value: string;
  color?: string;
};

export type FormMultiSelectProps<T extends FieldValues> = Omit<
  AutocompleteProps<FormMultiSelectItem, true, false, false, typeof Chip>,
  "name" | "renderInput"
> & {
  control?: Control<T>;
  name: string;
  label?: string;
  style?: React.CSSProperties;
  error?: boolean;
  helperText?: string;
  disableCloseOnSelect?: boolean;
  limitTags?: number;
  required?: boolean;
};

const FormMultiSelect = <T extends FieldValues>({
  control,
  name,
  label,
  options = [],
  disableCloseOnSelect,
  style,
  error,
  helperText,
  required,
  ...props
}: FormMultiSelectProps<T>) => {
  const { t } = useTranslation();
  return (
    <div style={style} aria-label="FormMultiSelect">
      <Controller
        name={name}
        control={control as Control}
        render={({ field, fieldState }) => {
          const handleAutocompleteChange = (
            event: SyntheticEvent<Element, Event>,
            newValue: FormMultiSelectItem[]
          ) => {
            const updatedFieldValue = newValue.map((item) =>
              isObject(item) ? item.key : item
            );
            field.onChange(updatedFieldValue);
          };

          const onChipDelete = (key: string) => {
            const updatedFieldValue = (field.value as string[]).filter(
              (item: string) => item !== key
            );
            field.onChange(updatedFieldValue);
          };

          return (
            <Autocomplete
              {...props}
              {...field}
              multiple
              id={name}
              value={(field.value as FormMultiSelectItem[]) || []}
              options={options.filter((item: FormMultiSelectItem) => {
                return !(field?.value as string[])?.includes(item.key);
              })}
              getOptionLabel={(option) => option.value}
              noOptionsText={t("common.components.autocomplete.noOptionsLabel")}
              renderTags={() =>
                (field.value as string[]).map((option: string) => {
                  const optionData = options.find(
                    (item) => item.key === option
                  );
                  return (
                    <Chip
                      key={optionData?.key}
                      blendMode="soft"
                      color={getColor(
                        optionData?.color || theme.color.blue[600]
                      )}
                      style={{ margin: theme.spacing.xxs }}
                      onDelete={() => onChipDelete(optionData?.key as string)}
                    >
                      {optionData?.value}
                    </Chip>
                  );
                })
              }
              filterSelectedOptions
              disableCloseOnSelect={disableCloseOnSelect}
              onChange={handleAutocompleteChange}
              renderOption={(props, option) => (
                <li key={option.key} {...props}>
                  {option.color ? <ColoredPoint color={option.color} /> : null}
                  {option.value}
                </li>
              )}
              renderInput={(params) => {
                return (
                  <StyledTextField
                    {...params}
                    label={label}
                    sx={{
                      ".Mui-disabled": {
                        backgroundColor: theme.color.gray[200],
                      },
                    }}
                    helperText={
                      fieldState.error?.message
                        ? t(fieldState.error.message)
                        : helperText
                    }
                    error={fieldState.invalid ?? error}
                    required={required}
                  />
                );
              }}
            />
          );
        }}
      />
    </div>
  );
};

export default FormMultiSelect;
