import styled from "@emotion/styled";

export const StyledLabel = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: #4a5568;

  :hover {
    cursor: pointer;
  }
`;
