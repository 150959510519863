export const mockData = [
  {
    title: "contract.created",
    request: {
      title: "Request",
      content: `url -X GET \
      https://eu1.endpoint.com/api/v2/admin/users \
      -H 'Authorization: Token abcdefab-1234-5678-abcd-112233445566'`,
    },
    response: {
      title: "Data",
      content: `{
        "editor": "John Doe",
        "source": "OVERVIEW",
        "contract": {
          "id": "00000000-aaaaa-0000-0000-000000000000",
          "name": "New contract",
          "tags": "",
          "type": "MAIN_CONTRACT",
          "status": "DRAFT",
          "teamId": "00000000-aaaaa-0000-0000-000000000001",
          "hasFile": false,
          "parentId": null,
          "createdAt": "2024-02-19T16:03:59.446Z",
          "createdBy": "John Doe",
          "updatedAt": "2024-02-19T16:03:59.446Z",
          "categoryId": "00000000-aaaaa-0000-0000-000000000002"
        },
        "timestamp": "2024-02-19T16:03:59.483Z"
      },`,
    },
  },
  {
    title: "contract.edited",
    request: {
      title: "Request",
      content: `url -X GET \
      https://eu1.endpoint.com/api/v2/admin/users \
      -H 'Authorization: Token abcdefab-1234-5678-abcd-112233445566'`,
    },
    response: {
      title: "Data",
      content: `
      {
        "editor": "John Doe",
        "source": "OVERVIEW",
        "contract": {
          "id": "00000000-aaaaa-0000-0000-000000000001",
          "name": "New contract1",
          "tags": "",
          "type": "MAIN_CONTRACT",
          "status": "DRAFT",
          "teamId": "00000000-aaaaa-0000-0000-000000000001",
          "hasFile": false,
          "parentId": null,
          "createdAt": "2024-02-19T16:03:59.446Z",
          "createdBy": "John Doe",
          "updatedAt": "2024-02-19T16:04:01.773Z",
          "categoryId": "00000000-aaaaa-0000-0000-000000000003"
        },
        "timestamp": "2024-02-19T16:04:02.260Z",
        "updated_data": {
          "name": {
            "to": "New contract1",
            "from": "New contract"
          },
          "updatedAt": {
            "to": "2024-02-19T16:04:01.773Z",
            "from": "2024-02-19T16:03:59.446Z"
          }
        }
      }
      `,
    },
  },
  {
    title: "member_invitation.created",
    request: {
      title: "Request",
      content: `url -X GET \
      https://eu1.endpoint.com/api/v2/admin/users \
      -H 'Authorization: Token abcdefab-1234-5678-abcd-112233445566'`,
    },
    response: {
      title: "Data",
      content: `
        {
          "timestamp": "2024-02-19T15:45:16.556Z",
          "invitation": {
            "role": "LIMITED_USER",
            "requester": "John Doe",
            "organization": "Webhook Organisation",
            "invitedUserEmail": "john.doe@email.com"
          }
        },
      `,
    },
  },
  {
    title: "member_invitation.accepted",
    request: {
      title: "Request",
      content: `url -X GET \
      https://eu1.endpoint.com/api/v2/admin/users \
      -H 'Authorization: Token abcdefab-1234-5678-abcd-112233445566'`,
    },
    response: {
      title: "Data",
      content: `{
        "timestamp": "2024-02-19T15:46:30.805Z",
        "invitation": {
          "userName": "John Doe",
          "userEmail": "john.doe@email.com",
          "organization": "Webhook Organisation",
          "invitationSentAt": "2024-02-19T15:45:16.311Z",
          "invitationAcceptedAt": "2024-02-19T15:46:30.805Z"
        }
      }`,
    },
  },
  {
    title: "reminder.sent",
    request: {
      title: "Request",
      content: `url -X GET \
      https://eu1.endpoint.com/api/v2/admin/users \
      -H 'Authorization: Token abcdefab-1234-5678-abcd-112233445566'`,
    },
    response: {
      title: "Data",
      content: `{
      "task": {
        "id": "00000000-aaaaa-0000-0000-000000000001",
        "title": "New Task",
        "status": "OPEN",
        "teamId": "00000000-aaaaa-0000-0000-000000000002",
        "dueDate": "2024-02-29T00:00:00.000Z",
        "contract": {
          "id": "00000000-aaaaa-0000-0000-000000000003",
          "name": "Neuer Vertrag"
        },
        "assignees": [],
        "createdAt": "2024-02-19T11:10:14.014Z",
        "updatedAt": "2024-02-19T11:10:14.014Z",
        "reminderAt": null,
        "description": "Description",
        "reminderUnit": null,
        "reminderInterval": null
      },
      "editor": "John Doe",
      "source": "OVERVIEW",
      "timestamp": "2024-02-19T11:10:14.058Z"
      }`,
    },
  },
];
