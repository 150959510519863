import _ from "lodash";

export function getDirtyValues<
  DirtyFields extends Record<string, unknown>,
  Values extends Record<keyof DirtyFields, unknown>
>(dirtyFields: DirtyFields, values: Values): Partial<typeof values> {
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) return prev;

    return {
      ...prev,
      [key]:
        typeof dirtyFields[key] === "object"
          ? getDirtyValues(
              dirtyFields[key] as DirtyFields,
              values[key] as Values
            )
          : values[key],
    };
  }, {});

  return dirtyValues;
}
// @Note: This is a robust solution to test if an object has at least one value in it's property.
// this is the building block for `new forms` to work care free with unsaved changes logic.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasValidProperty = (obj: Record<string, any>) => {
  return _.some(
    obj,
    (value) => !_.isEmpty(value) || _.isNumber(value) || _.isBoolean(value)
  );
};
