import React from "react";
import { ContractFieldDTOV1, ContractSectionDTOV1 } from "openapi";
import { ActiveElement } from "../../../types";
import { useLocale } from "hooks/GlobalStateHooks";
import BaseSection from "../Base/Section";
import OverlayDraggableField from "./OverlayDraggableField";
import SectionField from "../Base/SectionField";

type OverlayFactoryProps = {
  activeElement: ActiveElement | null;
  fields: ContractFieldDTOV1[];
  data: ContractFieldDTOV1 | ContractSectionDTOV1 | null;
  dragged: boolean;
};

export const OverlayFactory = ({
  activeElement,
  data,
  fields,
  dragged,
}: OverlayFactoryProps) => {
  const { locale } = useLocale();

  if (!activeElement || !data) {
    return null;
  }

  if (activeElement.data.type === "section") {
    return (
      <BaseSection
        section={data as ContractSectionDTOV1}
        isSettingsDisabled={false}
        fields={fields}
      />
    );
  }

  if (activeElement.data.set === "collection") {
    return <OverlayDraggableField name={data.name[locale]} />;
  } else {
    return (
      <SectionField
        field={data as ContractFieldDTOV1}
        dragging={!!activeElement && dragged}
      />
    );
  }
};
