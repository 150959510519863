import styled from "@emotion/styled";
import {
  ListItemButton,
  Menu as MUIMenu,
  MenuItem as MUIMenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { theme } from "theme";

export const Menu = styled(MUIMenu)`
  & .MuiMenu-list {
    padding: 0;
  }
`;

export const MenuItem = styled(MUIMenuItem)`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.md};
  padding: ${theme.spacing.md};
  color: ${theme.color.gray[700]};
  transition: background-color 0.25s ease-in-out;

  :first-child:hover {
    border-top-left-radius: ${theme.borderRadius};
    border-top-right-radius: ${theme.borderRadius};
  }

  :last-child:hover {
    border-bottom-left-radius: ${theme.borderRadius};
    border-bottom-right-radius: ${theme.borderRadius};
  }

  :hover {
    background-color: ${theme.color.blue[700]}14;
  }
`;

export const DropdownButton = styled(ListItemButton)<{
  isOpen: boolean;
  isSidebar?: boolean;
}>`
  border-radius: ${theme.borderRadius};
  background-color: ${theme.color.blue[600]};
  color: ${theme.color.gray[100]};
  transition: background-color 0.25s ease-in-out;

  :hover {
    background-color: ${theme.color.blue[500]};
  }

  & > .MuiListItemText-root {
    color: ${theme.color.white};
  }

  ${({ isSidebar }) =>
    !isSidebar &&
    `
    & .MuiListItemIcon-root {
      min-width: 0 !important;
    }

    .MuiListItemText-primary {
      font-weight: ${theme.font.weight.semiBold};
    }

    &.Mui-disabled > .MuiListItemText-root {
      color: ${theme.color.gray[500]};
    }

    &.Mui-disabled svg {
      color: ${theme.color.gray[500]}; 
    }

    &.Mui-disabled {
      opacity: 1;
      background-color: ${theme.color.gray[300]};
    }
  `}
`;

export const ArrowIcon = styled(AddIcon)<{ isOpen: boolean }>`
  transition: scale 0.25s ease-in-out;
  scale: 1;
  ${({ isOpen }) => isOpen && `scale: .75;`}
  rotate: ${(props) => (!props.isOpen ? "0" : "-180deg")};
  color: ${theme.color.gray[100]};
`;
