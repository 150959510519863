/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddonUpdateDto } from "../models/AddonUpdateDto";
import type { BackupLogDtoDeprecated } from "../models/BackupLogDtoDeprecated";
import type { PartiallyTeamInfoUpdateDto } from "../models/PartiallyTeamInfoUpdateDto";
import type { PaymentIntentCreateDto } from "../models/PaymentIntentCreateDto";
import type { PaymentMethodDto } from "../models/PaymentMethodDto";
import type { PlanUpdate } from "../models/PlanUpdate";
import type { SubTeamCreateDTO } from "../models/SubTeamCreateDTO";
import type { TagCreateDto } from "../models/TagCreateDto";
import type { TagDto } from "../models/TagDto";
import type { TeamCreateDto } from "../models/TeamCreateDto";
import type { TeamInfoDto } from "../models/TeamInfoDto";
import type { TeamInfoUpdateDto } from "../models/TeamInfoUpdateDto";
import type { TeamInvitationInfoRequestDto } from "../models/TeamInvitationInfoRequestDto";
import type { TeamInvitationRequestDto } from "../models/TeamInvitationRequestDto";
import type { TeamInvitationsDto } from "../models/TeamInvitationsDto";
import type { TeamMemberDto } from "../models/TeamMemberDto";
import type { TeamMembersDto } from "../models/TeamMembersDto";
import type { TeamMemberUpdateDto } from "../models/TeamMemberUpdateDto";
import type { TeamPublicInfoDto } from "../models/TeamPublicInfoDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class TeamService {
  /**
   * Create team
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static createTeam(
    requestBody: TeamCreateDto
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/team/teams",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Add new team to already existing team
   * @param id
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static addTeam(
    id: string,
    requestBody: SubTeamCreateDTO
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/team/teams/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Delete team
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static deleteTeam(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/team/teams/{id}",
      path: {
        id: id,
      },
    });
  }

  /**
   * Get team info
   * @param teamId
   * @returns TeamInfoDto
   * @throws ApiError
   */
  public static getTeamInfo(teamId: string): CancelablePromise<TeamInfoDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/team/info",
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Update team info
   * @param teamId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static updateTeamInfo(
    teamId: string,
    requestBody: TeamInfoUpdateDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/team/info",
      query: {
        teamId: teamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Partially update team info
   * @param teamId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static partiallyUpdateTeamInfo(
    teamId: string,
    requestBody: PartiallyTeamInfoUpdateDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/team/info",
      query: {
        teamId: teamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get team members
   * @param teamId
   * @returns TeamMembersDto
   * @throws ApiError
   */
  public static getMembers(teamId: string): CancelablePromise<TeamMembersDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/team/member",
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Get team member with id
   * @param id
   * @param teamId
   * @returns TeamMemberDto
   * @throws ApiError
   */
  public static getMember(
    id: string,
    teamId: string
  ): CancelablePromise<TeamMemberDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/team/member/{id}",
      path: {
        id: id,
      },
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Update team member with id
   * @param id
   * @param teamId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static updateMember(
    id: string,
    teamId: string,
    requestBody: TeamMemberUpdateDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/team/member/{id}",
      path: {
        id: id,
      },
      query: {
        teamId: teamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Send team invitation email
   * @param teamId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static sendInvitation(
    teamId: string,
    requestBody: TeamInvitationRequestDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/team/invitation",
      query: {
        teamId: teamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get pending invitations
   * @param teamId
   * @returns TeamInvitationsDto
   * @throws ApiError
   */
  public static getInvitations(
    teamId: string
  ): CancelablePromise<TeamInvitationsDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/team/invitation",
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Delete invitation
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static deleteInvitation(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/team/invitation/{id}",
      path: {
        id: id,
      },
    });
  }

  /**
   * Get team info from invitation token
   * @param requestBody
   * @returns TeamPublicInfoDto
   * @throws ApiError
   */
  public static getInvitationInfo(
    requestBody: TeamInvitationInfoRequestDto
  ): CancelablePromise<TeamPublicInfoDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/team/invitation-info",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get client payment methods
   * @param teamId
   * @returns PaymentMethodDto
   * @throws ApiError
   */
  public static getPaymentMethods(
    teamId: string
  ): CancelablePromise<Array<PaymentMethodDto>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/team/payment-sources",
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Get client payment method by id
   * @param id
   * @param teamId
   * @returns PaymentMethodDto
   * @throws ApiError
   */
  public static getPaymentMethod(
    id: string,
    teamId: string
  ): CancelablePromise<PaymentMethodDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/team/payment-source/{id}",
      path: {
        id: id,
      },
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Delete a payment methods
   * @param id
   * @param teamId
   * @returns any
   * @throws ApiError
   */
  public static deletePaymentMethod(
    id: string,
    teamId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/team/payment-source/{id}",
      path: {
        id: id,
      },
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Add payment method to client with intent payment
   * @param teamId
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static addPaymentMethodWithIntent(
    teamId: string,
    requestBody: PaymentIntentCreateDto
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/team/payment-source/intent",
      query: {
        teamId: teamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Set payment method as primary
   * @param id
   * @param teamId
   * @returns any
   * @throws ApiError
   */
  public static setPaymentMethodAsPrimary(
    id: string,
    teamId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/team/payment-source/primary/{id}",
      path: {
        id: id,
      },
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Purchase more addons
   * @param teamId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static purchaseAddons(
    teamId: string,
    requestBody: AddonUpdateDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/team/add-addons",
      query: {
        teamId: teamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Change customer plan
   * @param teamId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static purchasePlan(
    teamId: string,
    requestBody: PlanUpdate
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/team/change-plan",
      query: {
        teamId: teamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Add tag to a team
   * @param teamId
   * @param requestBody
   * @returns TagDto
   * @throws ApiError
   */
  public static addTag(
    teamId: string,
    requestBody: TagCreateDto
  ): CancelablePromise<TagDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/team/tags",
      query: {
        teamId: teamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get tags list
   * @param teamId
   * @returns TagDto
   * @throws ApiError
   */
  public static getTags(teamId: string): CancelablePromise<Array<TagDto>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/team/tags",
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Delete a tag
   * @param id
   * @param teamId
   * @returns any
   * @throws ApiError
   */
  public static deleteTag(id: string, teamId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/team/tags/{id}",
      path: {
        id: id,
      },
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Update a tag
   * @param id
   * @param teamId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static updateTag(
    id: string,
    teamId: string,
    requestBody: TagDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/team/tags/{id}",
      path: {
        id: id,
      },
      query: {
        teamId: teamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @deprecated
   * Get backup log
   * @param teamId
   * @returns BackupLogDtoDeprecated
   * @throws ApiError
   */
  public static getBackupLog(
    teamId: string
  ): CancelablePromise<Array<BackupLogDtoDeprecated>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/team/backup-log",
      query: {
        teamId: teamId,
      },
    });
  }
}
