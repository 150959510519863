import React from "react";
import { routePaths } from "constants/";
import { Link } from "react-router-dom";

const TeamNameCellRenderer = (params: {
  data: { name: string; id: string };
}) => (
  <Link
    to={`${routePaths.SETTINGS_TEAMS}/${params.data.id}`}
    className="hover-underline"
    aria-label={`team-name-${params.data.name}`}
  >
    {params.data.name}
  </Link>
);

export default TeamNameCellRenderer;
