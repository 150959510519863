import React, {
  createContext,
  Dispatch,
  Reducer,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import {
  ClearTokens,
  GetAccessToken,
  GetRefreshToken,
  SaveTokens,
} from "../shared/service/authService";
import { TokenResponseDto, UserInfoDto, UserService } from "../openapi";
import { interceptorConfig } from "../shared/config/openapi.config";
import i18n, {
  defaultLanguage,
  languageFromUrl,
  Language,
} from "../shared/i18n/i18n";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CustomEventTypeEnum } from "shared/enums/customevents.enum";
import { useTranslation } from "react-i18next";
import { setGtmDataLayer } from "shared/service/gtm";
import { ActionPayload } from "./types";
import { initCountries } from "utils/internationalizations";

export interface GlobalState {
  authenticated: boolean;
  locale: Language;
  userInfo?: UserInfoDto;
  uploadStatus: boolean;
  invitationToken?: string;
  status: "idle" | "success" | "error";
  error: string | null;
  showGuideModal: boolean;
}

type Prop = {
  children: JSX.Element;
};

const initialState: GlobalState = {
  authenticated: !!GetAccessToken(),
  locale: (languageFromUrl || defaultLanguage) as Language,
  userInfo: undefined,
  uploadStatus: false,
  invitationToken: undefined,
  status: "idle",
  error: null,
  showGuideModal: false,
};

export enum GlobalActionType {
  login,
  logout,
  changeLocale,
  setUserInfo,
  setUploadStatus,
  setInvitationToken,
  setShowGuideModal,
}

export type SetUserInfoActionPayload = ActionPayload<UserInfoDto>;

export interface GlobalAction {
  type: GlobalActionType;
  params?: unknown;
}

const globalReducer: Reducer<GlobalState, GlobalAction> = (
  prevState,
  action
) => {
  switch (action.type) {
    case GlobalActionType.login: {
      const params = action.params as TokenResponseDto;
      SaveTokens(params);
      return { ...prevState, authenticated: true };
    }
    case GlobalActionType.logout: {
      ClearTokens();
      return {
        ...initialState,
        locale: prevState.locale,
        authenticated: false,
      };
    }
    case GlobalActionType.changeLocale: {
      const locale = action.params as Language;
      const newLocale = locale || prevState.locale;

      return { ...prevState, locale: newLocale };
    }
    case GlobalActionType.setUserInfo: {
      const userInfo = action.params as SetUserInfoActionPayload;

      if (!userInfo?.data?.impersonate) {
        setGtmDataLayer(userInfo.data);
      }

      if (userInfo.error) {
        return {
          ...prevState,
          userInfo: undefined,
          status: "error",
          error: userInfo.error.message,
        };
      }
      return {
        ...prevState,
        userInfo: userInfo.data,
        locale: (userInfo?.data?.locale || prevState.locale) as Language,
        status: "success",
        error: null,
      };
    }
    case GlobalActionType.setUploadStatus: {
      const uploadStatus = action.params as boolean;
      return { ...prevState, uploadStatus };
    }
    case GlobalActionType.setInvitationToken: {
      const invitationToken = action.params as string | undefined;
      return { ...prevState, invitationToken };
    }
    case GlobalActionType.setShowGuideModal: {
      const showGuideModal = action.params as boolean;
      return { ...prevState, showGuideModal: showGuideModal };
    }
    default:
      return prevState;
  }
};

export const GlobalContext = createContext<{
  state: GlobalState;
  dispatch: Dispatch<GlobalAction>;
}>({
  state: initialState,
  dispatch: (_value) => {
    return;
  },
});

async function onInit(state: GlobalState, dispatch: Dispatch<GlobalAction>) {
  if (!state.authenticated) {
    dispatch({
      type: GlobalActionType.changeLocale,
      params: languageFromUrl || defaultLanguage,
    });
    return;
  }

  const actionPayload: SetUserInfoActionPayload = {
    error: null,
  };

  try {
    const userInfoResponse = await UserService.getUserInfo();
    actionPayload.data = userInfoResponse;
    await i18n.changeLanguage(userInfoResponse?.locale);
    initCountries(userInfoResponse?.locale);
  } catch (e) {
    if (!GetAccessToken() && !GetRefreshToken()) {
      return;
    }
    if (e instanceof Error) {
      actionPayload.error = e;
    } else {
      console.error("error refreshing user data", e);
      actionPayload.error = new Error("error refreshing user data");
    }
  }
  dispatch({
    type: GlobalActionType.setUserInfo,
    params: actionPayload,
  });
  dispatch({
    type: GlobalActionType.changeLocale,
    params: actionPayload?.data?.locale || defaultLanguage,
  });
}

function onStateChange(state: GlobalState, dispatch: Dispatch<GlobalAction>) {
  interceptorConfig.setLogoutAction(() =>
    dispatch({ type: GlobalActionType.logout })
  );
  interceptorConfig.setLanguage(state.locale);
}

export const GlobalProvider = ({ children }: Prop) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useMemo(() => onStateChange(state, dispatch), [state, dispatch]);

  useEffect(() => {
    const listener = () => {
      enqueueSnackbar(t("common.errors.500"), { variant: "error" });
    };
    document.addEventListener(CustomEventTypeEnum.ERROR500, listener);
    return () => {
      document.removeEventListener(CustomEventTypeEnum.ERROR500, listener);
    };
  }, []);

  interceptorConfig.setNavigateAction(navigate);

  useEffect(() => void onInit(state, dispatch), []);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};
