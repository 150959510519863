import styled from "@emotion/styled";
import { AlertTypeEnum } from "shared/enums/alert.enum";
import { theme } from "theme";
import { Button as MUIButton } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

type AlertStyledProps = {
  alertType?: AlertTypeEnum | undefined;
};

const alertColors = {
  [AlertTypeEnum.success]: {
    bg: theme.color.lightGreen[100],
    border: theme.color.lightGreen[300],
    text: theme.color.lightGreen[900],
    iconColor: theme.color.lightGreen[500],
  },
  [AlertTypeEnum.error]: {
    bg: theme.color.red[100],
    border: theme.color.red[300],
    text: theme.color.red[900],
    iconColor: theme.color.red[500],
  },
  [AlertTypeEnum.warning]: {
    bg: theme.color.yellow[100],
    border: theme.color.yellow[300],
    text: theme.color.yellow[900],
    iconColor: theme.color.yellow[500],
  },
};

export const AlertWrapper = styled.div<AlertStyledProps>`
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.alertType
      ? alertColors[props.alertType]?.text
      : theme.color.blue[900]};
  border: 1px solid;
  border-color: ${(props) =>
    props.alertType
      ? alertColors[props.alertType]?.border
      : theme.color.blue[300]};
  background-color: ${(props) =>
    props.alertType ? alertColors[props.alertType]?.bg : theme.color.blue[100]};
  border-radius: ${theme.borderRadius};
  padding: ${theme.spacing.md};
  margin-bottom: ${theme.spacing.xl};
`;

export const IconWrapper = styled.span<AlertStyledProps>`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  color: ${(props) =>
    props.alertType
      ? alertColors[props.alertType]?.iconColor
      : theme.color.blue[500]};
  margin-right: ${theme.spacing.sm};
  margin-bottom: auto;
`;

export const ButtonWrapper = styled(MUIButton)`
  flex: 0 1 auto;
  text-transform: none;
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  min-height: 36px;
  margin-left: ${theme.spacing.md};
  line-height: ${theme.spacing.lg};
`;

export const ContentWrapper = styled.span`
  flex: 1 1 auto;
`;

export const ErrorIcon = styled(ErrorOutlineIcon)`
  color: ${theme.color.red[600]};
  svg {
    fill: ${theme.color.orange[600]} !important;
  }
`;
