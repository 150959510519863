/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import styled from "@emotion/styled";

export interface Props {
  children: React.ReactNode;
  columns?: number;
  style?: React.CSSProperties;
  horizontal?: boolean;
}

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const List = forwardRef<HTMLDivElement, Props>(({ children }: Props, ref) => {
  return <Container ref={ref}>{children}</Container>;
});

export default List;
