import styled from "@emotion/styled";
import { theme } from "theme";
import ChevronIcon from "assets/svg/chevron-icon.svg?react";
import { Link as RouterLink } from "react-router-dom";

export const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${theme.spacing.xs};
  margin-bottom: ${theme.spacing.md};
`;

export const TitleLink = styled(RouterLink)`
  font-weight: ${theme.font.weight.medium} !important;
`;

export const ChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.xs};
  width: 450px;
`;

export const Button = styled.div`
  cursor: pointer;
  border: none;
  background-color: ${theme.color.blue[100]};
  border-radius: ${theme.borderRadius};
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  color: ${theme.color.blue[600]};
  font-weight: ${theme.font.weight.medium};
  transition: background-color 0.25s;
  &:hover {
    background-color: ${theme.color.blue[200]};
  }
`;

export const ToggleButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: ${theme.spacing.xxs};
  transition: background-color 0.25s;

  svg {
    fill: ${theme.color.blue[600]};
  }
`;

export const ToggleMarker = styled(ChevronIcon)<{ isOpen?: boolean }>`
  cursor: pointer;
  transition: transform 0.25s;
  transform-origin: center center;
  margin-left: auto;

  ${({ isOpen }) =>
    isOpen ? `transform: rotate(180deg)` : `transform: rotate(0)`}}
`;
