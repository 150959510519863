import styled from "@emotion/styled";
import { breakpoints } from "constants/breakpoints";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { theme } from "theme";

export const Wrapper = styled.div`
  margin: ${theme.spacing.xl} 0;
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.lg};

  > * {
    width: 100%;

    @media (min-width: ${breakpoints.md}px) {
      width: calc(50% - ${theme.spacing.lg});
    }
  }
`;

export const SnippetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg};
  margin: ${theme.spacing.xl} 0;
  width: 100%;

  @media (min-width: ${breakpoints.md}px) {
    width: calc(50% - ${theme.spacing.lg});
  }
`;

export const SectionTitle = styled.div`
  margin: ${theme.spacing.xxl} ${theme.spacing.sm};
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.bold};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  width: fit-content;

  :hover {
    svg {
      fill: ${theme.color.blue[300]};
    }
  }
`;

export const ArrowIcon = styled(KeyboardArrowDown)`
  transition: transform 0.2s ease-in-out;

  ${({ active }: { active?: boolean }) =>
    active &&
    `
    transform: rotate(180deg);
  `}
`;

export const SectionDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg};
  margin: 0 ${theme.spacing.sm};
`;
