import { UseFormSetError } from "react-hook-form/dist/types/form";
import { ApiError } from "../../openapi";
import {
  BadRequestResponse,
  ValidationError,
} from "../types/ErrorResponse.type";
import { FieldValues, Path, UseFormGetValues } from "react-hook-form";
import {
  OptionsObject,
  ProviderContext,
  SnackbarKey,
  SnackbarMessage,
} from "notistack";
import { TFunction } from "i18next";
import * as Sentry from "@sentry/react";

enum InvalidTokenTypes {
  token,
  invitationToken,
}

export function setValidationErrors<T extends FieldValues>(
  error: unknown,
  setError: UseFormSetError<T>,
  prefix: string,
  getValues?: UseFormGetValues<T>,
  enqueueSnackbar?: ProviderContext["enqueueSnackbar"],
  t?: TFunction,
  customMessage?: string
) {
  if (!(error instanceof ApiError) || error.status !== 400) {
    Sentry.captureException(error);
    if (enqueueSnackbar && t) {
      enqueueSnackbar(t(customMessage ?? "common.errors.unexpected"), {
        variant: "error",
      });
    }
    return;
  }

  const response = error.body as BadRequestResponse;
  flatten(response.message).forEach((m) => {
    const constraints = Object.keys(m.constraints);
    if (constraints.length > 0) {
      if (
        getValues &&
        t &&
        enqueueSnackbar &&
        getValues(m.property) === undefined
      ) {
        enqueueSnackbar(t(`${prefix}.validation.${constraints[0]}`), {
          variant: "error",
        });
      }

      setError(m.property as Path<T>, {
        type: "api",
        message: `${prefix}.validation.${constraints[0]}`,
      });
    }
  });
}

export function setInvalidTokenError(
  error: unknown,
  prefix: string,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey,
  t: TFunction<"translation">
) {
  if (!(error instanceof ApiError)) {
    return;
  }

  if (error.status !== 400) {
    return;
  }

  const response = error.body as BadRequestResponse;
  const message = flatten(response.message)[0];
  const constraints = Object.keys(message.constraints);
  if (
    constraints.length > 0 &&
    Object.keys(InvalidTokenTypes).includes(message.property)
  ) {
    enqueueSnackbar(t(`${prefix}.validation.${constraints[0]}`), {
      variant: "error",
    });
  }
}

export function setValidationSnackbarErrors(
  error: unknown,
  prefix: string,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey,
  t: TFunction<"translation">
) {
  if (!(error instanceof ApiError)) {
    return;
  }

  if (error.status !== 400) {
    return;
  }

  const response = error.body as BadRequestResponse;
  flatten(response.message).forEach((m) => {
    const constraints = Object.keys(m.constraints);
    if (constraints.length > 0) {
      enqueueSnackbar(t(`${prefix}.validation.${constraints[0]}`), {
        variant: "error",
      });
      return;
    }
  });
}

function flatten<T>(
  errors: ValidationError[],
  path?: Path<T>
): {
  property: Path<T>;
  constraints: { [key: string]: string };
}[] {
  return errors.flatMap((error) => {
    const property = error.property as keyof T & string;
    const currentPath = (path ? `${path}.${property}` : property) as Path<T>;
    const currentErrors = error.constraints ?? {};
    const children = flatten(error.children, currentPath);
    return [
      {
        property: currentPath,
        constraints: currentErrors,
      },
      ...children,
    ];
  });
}
