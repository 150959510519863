import React, { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { HTMLPreview, Overlay, NoContent } from "./styles";

const Preview = ({
  htmlContent,
  scale = 0.5,
  onClick,
}: {
  htmlContent: string;
  scale?: number;
  onClick: () => void;
}) => {
  const { t } = useTranslation();
  const [srcDoc, setSrcDoc] = useState("");

  const isContentValid = (content: string): boolean => {
    const strippedContent = content.replace(/\s|<br>|<p>|<\/p>|&nbsp;/gi, "");
    return strippedContent.length > 0;
  };

  useEffect(() => {
    const html = `
      <html>
        <head>
          <style>
            body {
              margin: 0;
              padding: 0;
              width: ${100 / scale}%;
              height: ${100 / scale}%;
              transform: scale(${scale});
              transform-origin: 0 0;
              overflow: hidden;
            }
          </style>
        </head>
        <body>${htmlContent}</body>
      </html>
    `;
    setSrcDoc(html);
  }, [htmlContent]);

  return (
    <HTMLPreview onClick={onClick} role="button">
      {isContentValid(htmlContent) ? (
        <iframe
          title="Preview"
          srcDoc={srcDoc}
          style={{ width: "100%", height: "100%", border: "none" }}
        />
      ) : (
        <NoContent>
          <span>
            {t(
              "pages.settings.tabs.contractTemplates.gallery.templateCard.noContent"
            )}
          </span>
        </NoContent>
      )}
      <Overlay />
    </HTMLPreview>
  );
};

export default memo(Preview);
