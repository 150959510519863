import React, { MouseEvent } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NonDraggableField from "./NonDraggableField";
import { getIdByName } from "../../utils";
import { DraggableFieldContainer, Container, Title } from "./styles";
import { CollectionFieldData } from "./types";
import { ContractFieldDTOV1 } from "openapi";
import { useSortable } from "@dnd-kit/sortable";
import { OverflowTip } from "components";
import Tag, { TagProps } from "new-components/Tag/Tag";
import { TagIndicators, IconButton } from "./styles";

export type DraggableFieldTypes = {
  id: string;
  name: string;
  isUsed: boolean;
  isFieldAiInsight: boolean;
  popoverComponent: React.ReactNode;
  handleOnOpenPopover?: (e: MouseEvent<HTMLButtonElement>) => void;
  isSettingsDisabled?: boolean;
  handleOnClick?: () => void;
  settings?: React.ReactNode;
  field: ContractFieldDTOV1;
};

const DraggableField = ({
  id,
  name,
  handleOnClick,
  popoverComponent,
  settings,
  field,
  isFieldAiInsight,
}: DraggableFieldTypes) => {
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id: `draggable-field-${id}`,
    data: {
      type: "field",
      id: id,
      set: "collection",
    } satisfies CollectionFieldData,
  });

  let variant: TagProps["variant"] = "ai";

  if (
    ["paymentPriceGross", "paymentPriceMonthly"].includes(field.visibleId || "")
  ) {
    variant = "ai-formula";
  }

  return (
    <Container
      id={id}
      ref={setNodeRef}
      onClick={handleOnClick}
      dragging={isDragging}
    >
      <DraggableFieldContainer>
        <Title {...attributes} {...listeners} grabbing>
          <OverflowTip title={name}>
            <span>{name}</span>
          </OverflowTip>

          {isFieldAiInsight && (
            <TagIndicators>
              <Tag variant={variant} />
            </TagIndicators>
          )}
        </Title>
        {settings}
        {popoverComponent}
      </DraggableFieldContainer>
    </Container>
  );
};

const DraggableFieldWrapper = (props: DraggableFieldTypes) => {
  const settings = !props?.isSettingsDisabled ? (
    <IconButton
      id={`${getIdByName(props.name.toLowerCase())}-field-settings-btn`}
      onClick={props.handleOnOpenPopover}
    >
      <MoreHorizIcon fontSize="small" />
    </IconButton>
  ) : null;

  return props?.isUsed ? (
    <>
      <NonDraggableField {...props} settings={settings} />
    </>
  ) : (
    <DraggableField {...props} settings={settings} />
  );
};

export default DraggableFieldWrapper;
