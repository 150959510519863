import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  AccessTokenDataDTO,
  AccessTokenDTO,
  ApiError,
  UserService,
} from "../../openapi";

const ACCESS_TOKEN_QUERY_KEY = "access-token";
export const useGetAccessTokensQuery = () => {
  return useQuery<AccessTokenDTO[], ApiError>({
    queryKey: [ACCESS_TOKEN_QUERY_KEY],
    queryFn: () => UserService.getAccessTokens(),
    retry(failureCount, error) {
      return (
        failureCount < 3 &&
        !!error &&
        error.status !== 404 &&
        error.status !== 403
      );
    },
    refetchOnWindowFocus: false,
  });
};

export const useAccessTokenGenerateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: AccessTokenDataDTO) => {
      return UserService.createAccessToken(data);
    },
    onSuccess: (_) => {
      void queryClient.invalidateQueries({
        queryKey: [ACCESS_TOKEN_QUERY_KEY],
      });
    },
  });
};

export const useAccessTokenDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { id: string }) => {
      return UserService.deleteAccessToken(data.id);
    },
    onSuccess: (_) => {
      void queryClient.invalidateQueries({
        queryKey: [ACCESS_TOKEN_QUERY_KEY],
      });
    },
  });
};
