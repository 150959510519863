import styled from "@emotion/styled";

export const HandleContainer = styled.div`
  :hover {
    cursor: grab;
  }

  :active {
    cursor: grabbing;
  }
`;
