import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Tooltip, Button } from "@mui/material";
import { useStyles as useStyledButtons } from "components/StyledComponents/StyledBaseButtons";
import NewModal from "components/Modal/NewModal";
import SignatureIcon from "assets/svg/signature.svg?react";
import RequestSignatureContent, {
  FormValues,
} from "../../../../Modals/RequestSignature/RequestSignature";
import { ContractSignatureV1Service } from "openapi";
import { CTAButton } from "components";
import { FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { useLocale } from "hooks";
import { validationSchema } from "./utils";

export type Props = {
  fetchData: (loadFromStash?: boolean | undefined) => Promise<void>;
  disabled?: boolean;
  hasPDF?: boolean;
};

export const initialValues = {
  message: "",
  signers: [{ name: "", email: "" }],
  eID: false,
  withOrder: true,
};

const Signature = ({ fetchData, disabled, hasPDF }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const buttonClasses = useStyledButtons();
  const [showRequestSignatureModal, setShowRequestSignatureModal] =
    useState(false);
  const [showPrepareSignature, setShowPrepareSignature] = useState(false);
  const [isRequestSignatureLoading, setIsRequestSignatureLoading] =
    useState(false);
  const [isSignatureSubmitted, setIsSignatureSubmitted] = useState<
    boolean | null
  >(null);

  const { locale } = useLocale();

  const openRequestSignatureModal = () => setShowRequestSignatureModal(true);
  const closeRequestSignatureModal = () => setShowRequestSignatureModal(false);

  useEffect(() => {
    if (isSignatureSubmitted === false && !showPrepareSignature && id) {
      void ContractSignatureV1Service.cleanupSignature(id);
    }
  }, [showPrepareSignature]);

  const methods = useForm<FormValues>({
    defaultValues: {
      ...initialValues,
      language: locale,
    },
    resolver: yupResolver(validationSchema()),
  });

  const {
    formState: { isSubmitting, isValid },
    reset,
  } = methods;

  const tooltipTitle = hasPDF
    ? t("pages.contractDetails.buttons.requestSignature.alreadyRequested")
    : t("pages.contractDetails.buttons.requestSignature.missingPDF");

  const cleanupSignatureRequest = async () => {
    closeRequestSignatureModal();
    reset();
    if (id) {
      await ContractSignatureV1Service.cleanupSignature(id);
    }
  };

  return (
    <>
      {disabled ? (
        <Tooltip
          title={tooltipTitle}
          arrow
          data-testid="signature-button-tooltip"
        >
          <span>
            <Button
              className={buttonClasses.previewHeaderButton}
              onClick={openRequestSignatureModal}
              disabled={disabled || isRequestSignatureLoading}
            >
              <SignatureIcon width={20} style={{ marginRight: ".4rem" }} />
              {t("pages.contractDetails.buttons.requestSignature.name")}
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Button
          className={buttonClasses.previewHeaderButton}
          onClick={openRequestSignatureModal}
          disabled={isRequestSignatureLoading}
          data-testid="signature-button"
        >
          <SignatureIcon width={20} style={{ marginRight: ".4rem" }} />
          {t("pages.contractDetails.buttons.requestSignature.name")}
        </Button>
      )}

      <NewModal
        open={showRequestSignatureModal}
        handleClose={() => setShowRequestSignatureModal(false)}
        fullWidth
        title={t("pages.contractDetails.modals.requestSignature.title")}
        body={
          <FormProvider {...methods}>
            <RequestSignatureContent
              contractId={id}
              handleClose={closeRequestSignatureModal}
              setIsSignatureSubmitted={setIsSignatureSubmitted}
              refetchData={fetchData}
              setShowSignatureModal={setShowPrepareSignature}
              setIsRequestSignatureLoading={setIsRequestSignatureLoading}
            />
          </FormProvider>
        }
        footer={
          <>
            <CTAButton
              label="cancel"
              name={t("common.buttons.cancel")}
              variant="secondary"
              onClick={async () => {
                await cleanupSignatureRequest();
              }}
            />
            <CTAButton
              label="request-signature-form"
              type="submit"
              name={
                isSubmitting
                  ? t(
                      "pages.contractDetails.modals.requestSignature.buttons.submit"
                    )
                  : t(
                      "pages.contractDetails.modals.requestSignature.buttons.submit"
                    )
              }
              variant="primary"
              loading={isSubmitting}
              form="requestSignatureForm"
              disabled={!isValid}
            />
          </>
        }
      />

      <NewModal
        open={showPrepareSignature}
        title=""
        body={
          <div
            id="prepareSignature"
            style={{ height: "88vh", width: "77vw" }}
          ></div>
        }
        handleClose={() => {
          setShowPrepareSignature(false);
        }}
        sx={{
          ".MuiPaper-root": {
            width: "80vw",
            height: "95vh",
            maxWidth: "80vw",
            maxHeight: "95vh",
          },
        }}
      />
    </>
  );
};

export default Signature;
