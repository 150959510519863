import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { theme } from "theme";

const shine = keyframes`
  to { background-position: 200% center; }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  color: #0000008a;

  h1 {
    background: linear-gradient(
      to right,
      ${theme.color.blue[200]} 20%,
      ${theme.color.blue[600]} 40%,
      ${theme.color.blue[600]} 60%,
      ${theme.color.blue[200]} 80%
    );
    background-size: 200% auto;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${shine} 7s linear infinite;
    font-weight: bold;
    font-size: ${theme.font.size.h1};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  text-align: center;
`;

export const Description = styled.div`
  margin-bottom: ${theme.spacing.xl};
  font-size: ${theme.font.size.subheading};
`;
