import React from "react";
import { getNewAssigneesNames } from "../../helpers";
import { TeamMemberDto } from "../../../../../../openapi";

type Props = {
  value: TeamMemberDto[];
};

const AssigneesCellRenderer = ({ value }: Props) => (
  <span style={{ textTransform: "capitalize" }}>
    {getNewAssigneesNames(value)}
  </span>
);

export default AssigneesCellRenderer;
