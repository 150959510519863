import styled from "@emotion/styled";
import { theme } from "theme";

export const DueDateWrapper = styled.div`
  margin: ${theme.spacing.md} 0;
`;

export const DueDateContainer = styled.div`
  margin: ${theme.spacing.md} 0;
  padding: ${theme.spacing.md};
  background-color: ${theme.color.gray[100]};
  border-radius: ${theme.borderRadius};
`;

export const UnsetDueDateMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 188px;
`;
