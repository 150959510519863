import {
  NotificationSettingsDto,
  NotificationSettingsUpdateDto,
} from "openapi";
import { NotificationType } from "../types";

export const convertToArray = (obj: NotificationSettingsDto) => {
  if (JSON.stringify(obj) === JSON.stringify({})) return [];

  const result: NotificationType[] = [];

  Object.entries(obj).map((item) => {
    if (item[0] === "CONTRACT_REMINDER_SET") return null;

    return result.push({
      key: item[0],
      email: item[1]?.email,
      app: item[1]?.app,
    });
  });

  return result.filter((item) => item.key !== "TASK_MEMBER_ASSIGNED");
};

export const checkAllNotificationStatus = (data: NotificationType[]) => {
  const statuses: boolean[] = [];

  data.forEach((item) => {
    /**
     * Reduce the array length by combining the values for each notification
     * as then all need to be true or false in order the toggle to work
     **/
    statuses.push((item.email as boolean) && (item.app as boolean));
  });

  return statuses.every((status) => status === true);
};

export const switchAll = (
  notifications: NotificationSettingsUpdateDto,
  isEnabled: boolean
) => {
  const reqBody = {} as NotificationSettingsDto;

  Object.entries(notifications).map((item) => {
    const pointer = item[0] as keyof NotificationSettingsDto;

    return (reqBody[pointer] = {
      email: isEnabled,
      app: isEnabled,
    });
  });

  return reqBody;
};
