/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PermissionCreateDTO = {
  categoryId: string;
  level: PermissionCreateDTO.level;
};

export namespace PermissionCreateDTO {
  export enum level {
    READ = "READ",
    WRITE = "WRITE",
  }
}
