import React from "react";
import { useTranslation } from "react-i18next";
import { Box, TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import routePaths from "constants/routePaths";
import { NotificationItemDto } from "openapi";
import {
  getIcon,
  getTime,
  getTranslation,
} from "components/Notifications/helper/notificationItem.helper";
import { getNavigationToContractViewById } from "constants/utils";
import theme from "../../../theme/theme";
import notificationType = NotificationItemDto.notificationType;
import styled from "@emotion/styled";

type Props = {
  index: number;
  data: NotificationItemDto;
};

const preventTextWrapperPropForwarding = (propName: string) => {
  return propName !== "clickable";
};

const TextWrapper = styled(Box, {
  shouldForwardProp: preventTextWrapperPropForwarding,
})`
  :hover {
    text-decoration: ${({ clickable }: { clickable: boolean }) =>
      clickable ? "underline" : "none"};
  }
`;

const ActivityItem = ({ index, data }: Props) => {
  const { t } = useTranslation();
  const isClickable = () =>
    data.notificationType !== notificationType.MONTHLY_SUMMARY;
  const navigate = useNavigate();

  const getNavigation = (
    type: NotificationItemDto.notificationType
  ): { link: string; text: string } => {
    switch (type) {
      case NotificationItemDto.notificationType.CONTRACT_DELETED:
        return {
          link: routePaths.CONTRACTS,
          text: "notificationsModal.notificationItem.buttons.navigateToContractsOverview",
        };
      case NotificationItemDto.notificationType.JOINED_TEAM:
        return {
          link: routePaths.SETTINGS_TEAMS,
          text: "notificationsModal.notificationItem.buttons.navigateToTeams",
        };

      case NotificationItemDto.notificationType.TASK_ASSIGNED:
        return {
          link: routePaths.TASK_DETAILS.replace(":id", data?.taskId || ""),
          text: "notificationsModal.notificationItem.buttons.navigateToTeams",
        };
      case NotificationItemDto.notificationType.TASK_DUE_DATE_CHANGED:
        return {
          link: routePaths.TASK_DETAILS.replace(":id", data?.taskId || ""),
          text: "notificationsModal.notificationItem.buttons.navigateToTeams",
        };
      case NotificationItemDto.notificationType.TASK_CLOSED:
        return {
          link: routePaths.TASK_DETAILS.replace(":id", data?.taskId || ""),
          text: "notificationsModal.notificationItem.buttons.navigateToTeams",
        };

      default:
        return {
          link: getNavigationToContractViewById(data.contractId),
          text: "notificationsModal.notificationItem.buttons.navigateToContractPreview",
        };
    }
  };

  return (
    <TableRow
      key={data.contractId}
      onClick={() =>
        isClickable()
          ? navigate(getNavigation(data.notificationType).link)
          : undefined
      }
      sx={{
        backgroundColor: `${
          index % 2 === 0 ? "none" : theme.color.gray["100"]
        }`,
        "& td": { borderBottom: 0 },
        whiteSpace: "normal",
        wordBreak: "break-word",
        ":hover": {
          cursor: isClickable() ? "pointer" : "default",
        },
      }}
    >
      <TableCell width="100%">
        <Box className="flex-align-center">
          {getIcon(data.notificationType)}
          <Box sx={{ flex: "1", margin: "0 0.5rem" }}>
            <Box sx={{ color: theme.color.gray["700"] }}>
              {getTime(t, data.createdAt)}
            </Box>
            <TextWrapper clickable={isClickable()}>
              {getTranslation(t, data)}
            </TextWrapper>
          </Box>
        </Box>
      </TableCell>
      <TableCell align="right" />
    </TableRow>
  );
};

export default ActivityItem;
