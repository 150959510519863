import styled from "@emotion/styled";
import { IconButton as StyledButton } from "@mui/material";
import { theme } from "theme";

type Props = {
  isActive: boolean;
};

export const Button = styled(StyledButton)<Props>`
  svg {
    background-color: ${theme.color.gray[200]};
    color: ${theme.color.gray[600]};
    border-radius: 50%;
    padding: ${theme.spacing.xs};
    ${({ isActive }) => isActive && `transform: rotate(180deg);`}
  }
`;
