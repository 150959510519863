import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.xl};
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
`;
