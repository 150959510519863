import styled from "@emotion/styled";
import { DefaultTableCell } from "components/BasicTable/styles";
import { theme } from "theme";

export const Title = styled.div`
  font-size: ${theme.font.size.h6};
  font-weight: ${theme.font.weight.semiBold};
`;

export const DateWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
  flex-direction: column;
`;

export const DateTitle = styled.div`
  font-size: ${theme.font.size.caption};
`;

export const Date = styled.div`
  color: ${theme.color.gray[600]};
`;

export const TableCellAlignTop = styled(DefaultTableCell)`
  vertical-align: top;
  min-width: 180px;
`;
