import * as Yup from "yup";

const validationSchema = () =>
  Yup.object({
    roleName: Yup.string().required(),
    teamId: Yup.string().required(),
    permissions: Yup.array().of(
      Yup.object().shape({
        categoryId: Yup.string().required(),
        level: Yup.string().required(),
      })
    ),
  });

export default validationSchema;
