import styled from "@emotion/styled";
import MUIList from "@mui/material/List";
import MUIDivider from "@mui/material/Divider";
import { theme } from "../../../theme";

export const List = styled(MUIList)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: ${theme.spacing.xs} ${theme.spacing.sm};
  min-width: 200px;
  max-width: 420px;
  color: ${theme.color.gray[700]};
`;

export const Divider = styled(MUIDivider)`
  border-bottom: 1px dashed ${theme.color.gray[400]};
  margin: ${theme.spacing.xxs};
`;

export const Label = styled.span`
  font-weight: ${theme.font.weight.medium};
`;
