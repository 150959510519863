import { useTeam } from "contexts/team/hooks";
import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  Reducer,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import {
  columnChanged,
  filterChanged,
  initializeOverview,
  resetOverview,
  setActiveView,
} from "./actions";
import { getActiveViewDeterminerFunction, synchronizeGrid } from "./helpers";
import { OverviewActions, OverviewState } from "./types";
import { useContractViewsQuery } from "shared/api/views";
import { ContractViewItemDto } from "openapi";

const initialState: OverviewState = {
  type: ContractViewItemDto.type.CONTRACT,
  agGrid: {
    initialized: false,
  },
  defaultViews: {},
  unsavedFilterChangePresent: false,
  unsavedColumnChangePresent: false,
};

export const OverviewStateContext = createContext<OverviewState>(initialState);
export const OverviewActionContext = createContext<{
  dispatch: Dispatch<OverviewActions>;
}>({
  dispatch: () => null,
});

const overviewReducer: Reducer<OverviewState, OverviewActions> = (
  prevState,
  action
) => {
  switch (action.type) {
    case "notifyUnmount": {
      return resetOverview(prevState, action);
    }
    case "initialize": {
      return initializeOverview(prevState, action);
    }
    case "reset": {
      return resetOverview(prevState, action);
    }
    case "setActiveView": {
      return setActiveView(prevState, action);
    }
    case "filterChanged": {
      return filterChanged(prevState, action);
    }
    case "columnChanged": {
      return columnChanged(prevState, action);
    }
    default:
      return prevState;
  }
};

const OverviewProvider: FC<
  PropsWithChildren<{ type: ContractViewItemDto.type }>
> = ({ type, children }) => {
  const initializeState = () => ({
    ...initialState,
    type: type,
  });

  const [state, dispatch] = useReducer(overviewReducer, type, initializeState);
  const { selectedTeamId } = useTeam();
  const { data: views } = useContractViewsQuery(selectedTeamId, type);

  const { agGrid } = state;

  useEffect(() => {
    if (views) {
      const updatedActiveView = views.items.find(
        (item) => item.id === state.activeView?.id
      );
      if (updatedActiveView) {
        dispatch({
          type: "setActiveView",
          value: {
            id: updatedActiveView.id,
            determineActiveView: getActiveViewDeterminerFunction(views),
          },
        });
      } else {
        dispatch({
          type: "reset",
        });
      }
    }
  }, [views]);

  useEffect(() => {
    synchronizeGrid(agGrid);
  }, [
    agGrid.columnState,
    agGrid.initialColumnState,
    agGrid.filterModel,
    agGrid.columnState,
    views,
  ]);

  const actions = useMemo(
    () => ({
      dispatch,
    }),
    [dispatch]
  );

  return (
    <OverviewActionContext.Provider value={actions}>
      <OverviewStateContext.Provider value={state}>
        {children}
      </OverviewStateContext.Provider>
    </OverviewActionContext.Provider>
  );
};

export { OverviewProvider };
