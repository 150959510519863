import React from "react";
import { useTranslation } from "react-i18next";
import ButtonWithArrow from "components/ButtonWithArrow/ButtonWithArrow";
import { Wrapper, ToolbarContainer } from "./styles";

type Props = {
  toolbar?: React.ReactElement;
  editable: boolean;
  handleClick: () => void;
};

const ContractHeader = ({ toolbar, editable, handleClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ButtonWithArrow
        id="allContractsBtn"
        onClick={handleClick}
        label={
          editable
            ? t("pages.contractEdit.buttons.backToContracts")
            : t("pages.contractDetails.buttons.backToAllContracts")
        }
      />

      <ToolbarContainer>{toolbar}</ToolbarContainer>
    </Wrapper>
  );
};

export default ContractHeader;
