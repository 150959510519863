import { useState } from "react";

export const useCopyToClipboard = (
  delay = 1500
): [(input: string) => Promise<void>, boolean] => {
  const [isCopied, setIsCopied] = useState(false);

  const setCopyToClipboard = async (input: string) => {
    try {
      await navigator.clipboard.writeText(input);
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, delay);
    } catch (err) {
      console.error(err);
    }
  };

  return [setCopyToClipboard, isCopied];
};
