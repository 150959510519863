/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment */

import React from "react";
import { t } from "i18next";
import { ColorDropdown, FormTextField, SectionHeader } from "components";
import { ButtonWithIcon, Row, Wrapper } from "../styles";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
import { useLocale } from "hooks";
import TranslationIcon from "assets/svg/translation-icon.svg?react";
import { CategoryDTO, CommonService, TranslationDTO } from "openapi";
import { enqueueSnackbar } from "notistack";

export type categoryNameType = {
  category: CategoryDTO;
};

const CategoryNameSection = ({ category }: categoryNameType) => {
  const { locale } = useLocale();
  const sourceLanguage = locale === "en" ? "en" : "de";
  const targetLanguage = locale === "en" ? "de" : "en";

  const { control, getValues, setValue } = useFormContext<CategoryDTO>();

  const [isTranslationLoading, setIsTranslationLoading] =
    useState<boolean>(false);

  const getTranslation = async () => {
    const { name } = getValues();
    if (name[sourceLanguage] === "") {
      return;
    }
    const requestBody = {
      text: name[locale],
      sourceLanguage: sourceLanguage as string,
      targetLanguage: targetLanguage as string,
    };

    try {
      setIsTranslationLoading(true);
      const translatedName = await CommonService.translateText(
        requestBody as TranslationDTO
      );
      setValue(`name.${targetLanguage}`, translatedName.text, {
        shouldDirty: true,
      });
    } catch (error) {
      enqueueSnackbar(t("pages.categoryDetails.messages.translationFailed"), {
        variant: "error",
      });
    } finally {
      setIsTranslationLoading(false);
    }
  };

  return (
    <>
      <Wrapper>
        <SectionHeader
          title={t("pages.categoryDetails.sections.overview.details.title")}
          subTitle={t(
            "pages.categoryDetails.sections.overview.details.subtitle"
          )}
          padding={"0 0 1rem 0"}
          hideButton
        />

        <Row alignItems="flex-start" maxWidth="600px">
          <FormTextField
            control={control}
            name={`name.${sourceLanguage}`}
            label={`${t("pages.contractEdit.modals.category.name")} (${t(
              `common.languages.${sourceLanguage}`
            )})`}
            required
          />

          <FormTextField
            control={control}
            name={`name.${targetLanguage}`}
            label={`${t("pages.contractEdit.modals.category.name")} (${t(
              `common.languages.${targetLanguage}`
            )})`}
            required
          />
          <ColorDropdown
            name="color"
            label={t("pages.contractEdit.modals.category.color")}
            control={control}
            defaultValue={category.color}
          />
        </Row>
        <ButtonWithIcon
          onClick={getTranslation}
          startIcon={<TranslationIcon />}
          loading={isTranslationLoading}
          loadingPosition="start"
          variant="contained"
        >
          <span>{t("pages.categoryDetails.buttons.translation")}</span>
        </ButtonWithIcon>
      </Wrapper>
    </>
  );
};

export default CategoryNameSection;
