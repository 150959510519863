/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { TFunction } from "i18next";
import { FlatfileListener } from "@flatfile/listener";
import * as Sentry from "@sentry/react";
import api from "@flatfile/api";
import {
  ContractFieldDTOV1,
  ImportDataDTO,
  OrganizationService,
} from "openapi";
import {
  InputType,
  transformFlatFileDataToContractHeroDataStructure,
} from "./utils";
import {
  flatItemToContractCreateDTO,
  flatItemToContactCreateDTO,
} from "./importUtils";
import { Language } from "shared/i18n/i18n";
import { FlatfileRecord, TPrimitive } from "@flatfile/hooks";
import { recordHook } from "@flatfile/plugin-record-hook";
import dayjs from "dayjs";
import { isUUID } from "../../../pages/Contract/helpers";

type configType = {
  categories: [];
  fields: [];
  results: [];
  selectedTeamId: string;
  organizationId: string;
  contactFields: [];
  contactTypes: [];
};

type validationParams = {
  key: string;
  record: FlatfileRecord;
  data: TPrimitive;
  suffix?: string;
  t: TFunction<"translation">;
  userDateFormat?: string;
};

const flatfileDatePattern =
  /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/(\d{2}|\d{4})$/;

const formatTextField = ({
  key,
  record,
  data,
  t,
  userDateFormat,
}: validationParams) => {
  const date = formatDateField({ data, record, key, t, userDateFormat });

  // @Note: Only gets tricky if data looks like 55/55ABASD/ASD123
  if (
    data &&
    (data as string).split("/").length === 3 &&
    date !== "Invalid date"
  ) {
    return date;
  }

  return data;
};

const formatDateField = ({ data, userDateFormat }: validationParams) => {
  if (!data) return undefined;

  // @Note: Please do not delete, unless there is no better solution.
  // It is solution for PR #2673 - months and days were switching on each table cell edit.
  // Reason: FlatFile converts provided dates in M/D/YY format and they were reformatted on each rerender.
  // Testing date against "flatfileDatePattern" and deciding convert it or not.
  if (flatfileDatePattern.test(data as string)) {
    const parsedDate = dayjs(data as string, { format: userDateFormat }, true);

    if (parsedDate.isValid()) {
      return parsedDate.format(userDateFormat);
    } else {
      return "Invalid date";
    }
  } else {
    return data;
  }
};

const formatNumberField = ({ key, record, data, t }: validationParams) => {
  if (!data) return undefined;

  const fieldValue = +data;

  record.validate(
    key,
    () => typeof fieldValue === "number" && !isNaN(fieldValue),
    t("pages.import.validation.invalidNumber")
  );

  return data;
};

const formatDurationField = ({
  key,
  record,
  data,
  suffix,
  t,
  userDateFormat,
}: validationParams) => {
  // @Note: We could also make those co-dependent fields,
  // but because they are optional we just validate their values for now.
  if (!data || !suffix) return undefined;

  const isDate = ["startDate", "endDate", "terminationDate"].includes(suffix);
  const isNumber = ["noticePeriod", "automaticRenewal", "interval"].includes(
    suffix
  );

  if (isDate) return formatDateField({ data, record, key, t, userDateFormat });
  if (isNumber) return formatNumberField({ data, record, key, t });

  return data;
};

const getValidatedField = (
  key: string,
  data: any,
  config: configType,
  record: FlatfileRecord,
  t: TFunction<"translation">,
  userDateFormat?: string
) => {
  const [prefix, suffix] = key.split(".");

  if (!config || !config.fields) {
    Sentry.captureMessage(
      `Invalid config or config.fields not provided. Key: ${key}, Config: ${JSON.stringify(
        config
      )}`
    );
    return data;
  }

  const field = config?.fields?.find(
    (item) => (item as ContractFieldDTOV1).id === prefix
  );

  if (!field) return data;

  switch ((field as ContractFieldDTOV1).type) {
    case ContractFieldDTOV1.type.DATE:
      return formatDateField({ data, record, key, t, userDateFormat });
    case ContractFieldDTOV1.type.TEXTFIELD:
    case ContractFieldDTOV1.type.TEXTAREA:
      return formatTextField({ data, record, key, t, userDateFormat });
    case ContractFieldDTOV1.type.NUMBER:
      return formatNumberField({ data, record, key, t });
    default:
      if (suffix)
        return formatDurationField({
          data,
          record,
          key,
          suffix,
          t,
          userDateFormat,
        });
      return data;
  }
};

const sendJobAcknowledgement = async (
  jobId: string,
  config: any,
  onResult: (status: ImportDataDTO) => void,
  t: TFunction<"translation">
) => {
  const {
    categories,
    fields,
    results,
    selectedTeamId,
    contactFields,
    contactTypes,
    userDateFormat,
  } = config;

  await api.jobs.ack(jobId, {
    info: t("pages.import.starting"),
    progress: 12,
  });

  const handleOnDataSubmit = async () => {
    const sheets = results;

    for (const sheet of sheets) {
      const { data } = await api.records.get(sheet.id);

      const results = transformFlatFileDataToContractHeroDataStructure(
        data.records as InputType
      );

      const importedContracts = results.map((item) => {
        const contractData = flatItemToContractCreateDTO(
          item as { [x: string]: string },
          {
            fields,
            categories,
            selectedTeamId,
            userDateFormat,
          }
        );

        const importedContact = flatItemToContactCreateDTO(
          item as { [x: string]: string },
          contactFields,
          contactTypes[0]?.id
        );

        if (contractData && contractData.fields && importedContact) {
          const partnerCompanyField = fields.find(
            (item: { visibleId: string; teamId: any }) =>
              item.visibleId === "partnerCompany"
          );

          contractData.fields[partnerCompanyField?.id] = {
            value: isUUID(importedContact.id) ? importedContact.id : null,
            $create: !isUUID(importedContact.id) ? importedContact : null,
          };
        }

        return contractData;
      });

      return OrganizationService.importContracts(
        selectedTeamId,
        importedContracts
      );
    }
  };

  const response = (await handleOnDataSubmit()) as ImportDataDTO;

  if (response?.errors.length) {
    // @Error
    await api.jobs.complete(jobId, {
      outcome: {
        acknowledge: true,
        heading: t("pages.import.modals.error.heading"),
        message: t("pages.import.modals.error.message"),
        buttonText: "Ok",
      },
    });
    onResult(response);
  } else {
    // @Success
    await api.jobs.complete(jobId, {
      outcome: {
        acknowledge: true,
        heading: t("pages.import.modals.success.heading"),
        message: t("pages.import.modals.success.message"),
        buttonText: "Ok",
      },
    });
    onResult(response);
  }
};

export const listener = (
  onResult: (status: ImportDataDTO) => void,
  t: TFunction<"translation">,
  locale: Language,
  userDateFormat?: string
) =>
  FlatfileListener.create((listener) => {
    const config = {};

    listener.on("space:created", async (event) => {
      const spaceId = event?.context?.spaceId as string;
      const environmentId = event?.context?.environmentId as string;
      const spaceDetails = await api?.spaces?.get(spaceId);
      const metaConfig = spaceDetails.data.metadata.config;

      await api.spaces.update(spaceId, {
        environmentId,
        languageOverride: locale,
        translationsPath: `https://raw.githubusercontent.com/ContractHero/Flatfile-Platform-Translations/main/locales/${locale}/translation.json`,
      });

      Object.assign(config, metaConfig);
    });
    listener.use(
      recordHook("contracts-slug", (record: FlatfileRecord) => {
        for (const key of Object.keys(record.value)) {
          const recordKey = record.get(key);

          if (!recordKey) continue;

          record.compute(key, (data: any) => {
            return getValidatedField(
              key,
              data,
              config as configType,
              record,
              t,
              userDateFormat
            );
          });
        }

        return record;
      })
    );
    listener.on(
      "job:ready",
      { job: "workbook:submitActionFg" },
      async (event) => {
        const jobId = event.context.jobId as string;
        // @Note: Extract fields data from the FlatFile workbook
        const workbookId = event.context.workbookId as string;
        const { data: workbookData } = await api.sheets.list({ workbookId });
        // @Note: Extract parameters for sending requests to ContractHero API
        const metaConfig = {
          ...config,
          results: workbookData,
          userDateFormat: userDateFormat,
        };

        try {
          await sendJobAcknowledgement(jobId, metaConfig, onResult, t);
        } catch (error: any) {
          if (Sentry) {
            Sentry.captureMessage(
              `IMPORT: Failed to send job acknowledgement: ${jobId} \n ERROR:`,
              error
            );
          }
        }
      }
    );
  });
