import React from "react";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { useTranslation } from "react-i18next";
import { getInitials } from "contexts/team/helpers";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Container, TeamIcon, TeamInfo, TeamName, MemberRole } from "./styles";

type Props = {
  teamName: string;
  memberRole: string;
};

const LimitedTeamInfoComponent = ({ teamName, memberRole }: Props) => {
  const { t } = useTranslation();

  return (
    <CardWrapper data-cy={teamName}>
      <Container>
        <TeamInfo>
          {teamName ? (
            <TeamIcon>{getInitials(teamName)}</TeamIcon>
          ) : (
            <Skeleton
              variant="rectangular"
              width={44}
              height={27}
              animation="wave"
            />
          )}

          <div>
            {teamName && memberRole ? (
              <>
                <TeamName data-cy="teamName">{teamName}</TeamName>
                <MemberRole>
                  {t(`pages.settings.tabs.team.roleOptions.${memberRole}`)}
                </MemberRole>
              </>
            ) : (
              <Stack spacing={1}>
                <Skeleton
                  variant="rectangular"
                  width={80}
                  height={32}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  width={80}
                  height={20}
                  animation="wave"
                />
              </Stack>
            )}
          </div>
        </TeamInfo>
      </Container>
    </CardWrapper>
  );
};

export default LimitedTeamInfoComponent;
