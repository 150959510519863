import { ColumnResizedEvent, ColumnState } from "ag-grid-community";
import { ContractViewItemDto } from "openapi";
import { AvailableTemplates, availableTemplates } from "./default";
import {
  areUnsavedFilterChangesPresent,
  areUnsavedColumnChangesPresent,
} from "./helpers";
import {
  retrieveTemporaryState,
  retrieveTemporaryFilter,
  retrieveActiveView,
  persistActiveView,
  persistTemporaryFilter,
  persistTemporaryState,
  clearTemporaryState,
} from "./storage";
import {
  ColumnChangedAction,
  FilterChangedAction,
  NotifyUnmountAction,
  OverviewAction,
  OverviewInitAction,
  ResetAction,
  SetActiveViewAction,
} from "./types";

export const initializeOverview: OverviewAction<OverviewInitAction> = (
  prevState,
  action
) => {
  let columnState: ColumnState[];
  let filterModel: Record<string, unknown>;

  const columnApi = action.value.gridRef.current?.columnApi;
  if (!columnApi) {
    return prevState;
  }

  const initialColumnState = columnApi.getColumnState();
  const activePreset = prevState.activePreset;

  const activeViewId = retrieveActiveView(prevState.type);
  let activeView = action.value.determineActiveView(activeViewId);

  const getGridStateWithTemplate = (template: AvailableTemplates) => {
    const presetFunction = availableTemplates[template];
    if (presetFunction) {
      const template = presetFunction(initialColumnState);
      return {
        activeView: template,
        columnState: JSON.parse(template.data) as ColumnState[],
        filterModel: template.filter
          ? (JSON.parse(template.filter) as Record<string, unknown>)
          : {},
      };
    }
  };

  if (initialColumnState.length > 0) {
    let gridState: ReturnType<typeof getGridStateWithTemplate> | null = null;
    if (activePreset) {
      gridState = getGridStateWithTemplate(activePreset);
    } else if (!activeView && activeViewId) {
      gridState = getGridStateWithTemplate(activeViewId as AvailableTemplates);
      if (!gridState?.activeView.persist) {
        clearTemporaryState(prevState.type);
        gridState = null;
      }
    }

    if (gridState) {
      persistActiveView(prevState.type, gridState.activeView.id);
      activeView = gridState.activeView;
      columnState = gridState.columnState;
      filterModel = gridState.filterModel;
    }
  }

  columnState ??= retrieveTemporaryState(prevState.type) ?? [];
  filterModel ??= retrieveTemporaryFilter(prevState.type) ?? {};

  return {
    ...prevState,
    agGrid: {
      initialized: true,
      gridRef: action.value.gridRef,
      filterModel: filterModel,
      columnState: columnState,
      initialColumnState: initialColumnState,
    },
    activeView: activeView,
    unsavedFilterChangePresent: areUnsavedFilterChangesPresent(
      filterModel,
      activeView
    ),
    unsavedColumnChangePresent: areUnsavedColumnChangesPresent(
      prevState,
      columnState,
      activeView
    ),
  };
};

export const resetOverview: OverviewAction<
  ResetAction | NotifyUnmountAction
> = (prevState, action) => {
  if (!prevState.agGrid.initialized) {
    return prevState;
  }

  clearTemporaryState(prevState.type);

  return {
    ...prevState,
    agGrid: {
      ...prevState.agGrid,
      filterModel: {},
      columnState: [],
    },
    unsavedColumnChangePresent: false,
    unsavedFilterChangePresent: false,
    activeView: undefined,
    activePreset: undefined,
  };
};

export const setActiveView: OverviewAction<SetActiveViewAction> = (
  prevState,
  action
) => {
  let view: ContractViewItemDto | undefined;
  const isTemplate = action.value.id in availableTemplates;
  if (isTemplate) {
    if (!prevState.agGrid.initialColumnState) {
      return {
        ...prevState,
        activePreset: action.value.id as AvailableTemplates,
      };
    }
    const template = availableTemplates[action.value.id as AvailableTemplates](
      prevState.agGrid.initialColumnState
    );
    view = template;
  } else {
    view = action.value.determineActiveView(action.value.id);
  }

  if (!view) {
    return prevState;
  }

  let viewColumnState = JSON.parse(view.data) as ColumnState[];

  if (prevState.agGrid.initialColumnState) {
    // the persisted user view might not contain all columns that are present in the initial column state
    // to make sure the view is consistent, we need to merge the persisted view with the initial column state
    // otherwise columns that the customer enabled on his view, that were not present when the custom view was created
    // would still be shown after the view was switched
    viewColumnState = prevState.agGrid.initialColumnState
      .map((initialState) => {
        return (
          viewColumnState.find((state) => state.colId === initialState.colId) ??
          initialState
        );
      })
      .sort((a, b) => {
        const aIndex = viewColumnState.findIndex(
          (state) => state.colId === a.colId
        );
        const bIndex = viewColumnState.findIndex(
          (state) => state.colId === b.colId
        );
        return aIndex - bIndex;
      });
  }

  const viewFilterModel = view.filter
    ? (JSON.parse(view.filter) as Record<string, unknown>)
    : {};

  persistActiveView(prevState.type, view.id);
  persistTemporaryFilter(prevState.type, viewFilterModel);
  persistTemporaryState(prevState.type, viewColumnState);

  return {
    ...prevState,
    activeView: view,
    activePreset: isTemplate
      ? (action.value.id as AvailableTemplates)
      : undefined,
    agGrid: {
      ...prevState.agGrid,
      columnState: viewColumnState,
      filterModel: viewFilterModel,
    },
    unsavedColumnChangePresent: false,
    unsavedFilterChangePresent: false,
  };
};

export const filterChanged: OverviewAction<FilterChangedAction> = (
  prevState,
  action
) => {
  if (!prevState.agGrid.initialized) {
    return prevState;
  }

  const gridApi = prevState.agGrid.gridRef.current;
  if (!gridApi) {
    return prevState;
  }

  const filterModel = gridApi.api.getFilterModel();
  persistTemporaryFilter(prevState.type, filterModel);

  if (action.value.columns.length < 1) {
    return prevState;
  }

  return {
    ...prevState,
    agGrid: {
      ...prevState.agGrid,
      filterModel: filterModel,
    },
    unsavedColumnChangePresent: areUnsavedColumnChangesPresent(
      prevState,
      prevState.agGrid.columnState,
      prevState.activeView
    ),
    unsavedFilterChangePresent: areUnsavedFilterChangesPresent(
      filterModel,
      prevState.activeView
    ),
  };
};

export const columnChanged: OverviewAction<ColumnChangedAction> = (
  prevState,
  action
) => {
  if (!prevState.agGrid.initialized) {
    return prevState;
  }

  const columnApi = prevState.agGrid.gridRef.current?.columnApi;
  if (!columnApi) {
    return prevState;
  }

  if (
    action.value.type === "columnResized" &&
    !(action.value as ColumnResizedEvent).finished
  ) {
    return prevState;
  }

  const columnState = columnApi.getColumnState();
  persistTemporaryState(prevState.type, columnState);

  return {
    ...prevState,
    agGrid: {
      ...prevState.agGrid,
      columnState: columnState,
    },
    unsavedColumnChangePresent: areUnsavedColumnChangesPresent(
      prevState,
      columnState,
      prevState.activeView
    ),
    unsavedFilterChangePresent: areUnsavedFilterChangesPresent(
      prevState.agGrid.filterModel,
      prevState.activeView
    ),
  };
};
