import { FormControl, FormGroup, FormHelperText } from "@mui/material";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import React from "react";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import Switch, { SwitchProps } from "new-components/Switch";

export type FormSwitcherProps<T extends FieldValues> = Omit<
  SwitchProps,
  "name"
> & {
  control?: Control<T>;
  name: Path<T>;
  label: React.ReactNode;
  required?: boolean;
  onChangeCallback?: () => void;
};

export function FormSwitch<T extends FieldValues>({
  name,
  control,
  label,
  required,
  onChangeCallback,
  ...rest
}: FormSwitcherProps<T>): JSX.Element {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange },
        fieldState: { invalid, error },
      }) => {
        return (
          <FormControl required={required} error={invalid} fullWidth>
            <FormGroup row>
              <Switch
                {...rest}
                id={name}
                name={name}
                label={label || ""}
                style={{
                  color: invalid ? red[400] : undefined,
                }}
                value={value}
                checked={!!value}
                onChange={() => {
                  onChange(!value);
                  onChangeCallback && onChangeCallback();
                }}
              />
            </FormGroup>
            {error?.message && (
              <FormHelperText error={invalid}>
                {t(error.message)}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
}
