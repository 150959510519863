import styled from "@emotion/styled";
import { styled as muiStyled } from "@mui/system";
import theme from "theme/theme";

export const ToolbarButtonContainer = muiStyled("div")({
  padding: `0 ${theme.spacing.xxs}`,
});

export const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: var(--font-family);
  width: 100%;
  container-type: inline-size;
  .toolbar-negative-collapsing-button {
    display: none;
  }
  @container (max-width: 500px) {
    .toolbar-collapsing-button {
      display: none;
    }
    .toolbar-negative-collapsing-button {
      display: block;
    }
  }
`;
