import { TFunction } from "i18next";
import { Language } from "shared/i18n/i18n";

export const getSidebarOptions = (t: TFunction<"translation", undefined>) => {
  return {
    toolPanels: [
      {
        id: "columns",
        labelDefault: t("pages.contracts.sidebar.title"),
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: false,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
  };
};

export const getCountryName = (countryCode: string, locale: Language) => {
  if (!countryCode) return "-";
  const displayNames = new Intl.DisplayNames(locale, { type: "region" });
  return displayNames.of(countryCode);
};

export const getDateColumnFilter = (
  dateFromFilter: Date,
  cellValue: string
) => {
  if (cellValue == null) return 0;

  const dateParts = cellValue.split("/");
  const day = Number(dateParts[0]);
  const month = Number(dateParts[1]) - 1;
  const year = Number(dateParts[2]);
  const cellDate = new Date(year, month, day);

  if (cellDate < dateFromFilter) return -1;
  else if (cellDate > dateFromFilter) return 1;
  else return 0;
};
