import React from "react";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import { HandleContainer } from "./styles";

const HandleIcon = ({ ...props }) => (
  <HandleContainer {...props}>
    <ViewCompactIcon />
  </HandleContainer>
);

export default HandleIcon;
