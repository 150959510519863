import { dateFormatter } from "constants/utils";
import dayjs from "dayjs";
import { t } from "i18next";
import { Language } from "shared/i18n/i18n";
import { theme } from "theme";

const isSameDay = (date: dayjs.Dayjs, additionalDays = 0): boolean => {
  return date.isSame(dayjs().add(additionalDays, "days"), "day");
};

export const getTimeAndColor = (
  locale: Language,
  date: dayjs.Dayjs,
  userDateFormat?: string
): { time: string; color: string } => {
  if (date < dayjs().startOf("days")) {
    return {
      time: dateFormatter(locale, date, userDateFormat),
      color: theme.color.red[600],
    };
  }
  if (isSameDay(date))
    return {
      time: t("pages.dashboard.tasks.today").toLocaleLowerCase(),
      color: theme.color.orange[500],
    };
  else if (isSameDay(date, 1))
    return {
      time: t("pages.dashboard.tasks.tomorrow").toLocaleLowerCase(),
      color: theme.color.orange[500],
    };
  else if (isSameDay(date, 2))
    return {
      time: t("pages.dashboard.tasks.theDayAfterTomorrow").toLocaleLowerCase(),
      color: theme.color.orange[500],
    };
  return {
    time: dateFormatter(locale, date, userDateFormat),
    color: theme.color.gray[500],
  };
};
