import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthCardWrapper from "../../components/AuthCardWrapper/AuthCardWrapper";
import { CardLinks } from "../../components/AuthCardWrapper/AuthCardWrapperLinks";
import { AuthCardContainer } from "components/AuthCardWrapper/AuthCardWrapper.styles";
import { AuthWithSSOButton } from "./components/AuthWithSSOButton";
import { AuthProvider } from "./components/AuthProvider.enum";
import LoginForm from "./loginForm";
import { Divider } from "components/AuthCardWrapper";
import { ButtonsWrapper, Container } from "./styles";
import { useAuthMethodsQuery } from "shared/api/auth";
import { useHostname } from "hooks/HostnameHook";
import { Navigate, Path, useLocation, useNavigate } from "react-router-dom";
import routePaths from "constants/routePaths";
import { useSearchParams } from "react-router-dom";
import { TeamPublicInfoDto, TeamService } from "openapi";
import { setInvalidTokenError } from "shared/service/errorResponseService";
import { useSnackbar } from "notistack";
import AlertComponent from "components/Alert/Alert";
import { AlertTypeEnum } from "shared/enums/alert.enum";

const Login = () => {
  const { t } = useTranslation();
  const hostname = useHostname();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [invitationInfo, setInvitationInfo] = useState<TeamPublicInfoDto>();

  const { data, error } = useAuthMethodsQuery(hostname ?? "");

  const links = [];
  if (data?.password) {
    links.push(CardLinks.forgotPassword);
  }
  // if (!hostname) {
  //   links.push(CardLinks.register);
  // }

  const token = searchParams.get("invitationToken") || undefined;

  const location = useLocation();
  const state = location.state as { redirect?: Path } | null;
  const isApprovalPath = state?.redirect?.pathname.match(
    /federated-identity\/.+\/approve$/
  );

  const getInvitedTeamName = async () => {
    if (!token) return;

    try {
      const teamPublicInfo = await TeamService.getInvitationInfo({ token });
      setInvitationInfo(teamPublicInfo);
      if (!teamPublicInfo.userExist) {
        navigate({
          pathname: routePaths.REGISTER,
          search: `?${searchParams.toString()}`,
        });
      }
    } catch (e) {
      setInvalidTokenError(e, "pages.register", enqueueSnackbar, t);
    }
  };

  useEffect(() => void getInvitedTeamName(), []);

  if (error?.status === 404) {
    return <Navigate to={routePaths.NOTFOUND} />;
  }

  return (
    <Container component="main">
      <AuthCardWrapper
        title={t(`pages.${isApprovalPath ? "link" : "login"}.title`)}
        subTitle={t(`pages.${isApprovalPath ? "link" : "login"}.subtitle`)}
        links={links}
      >
        <AuthCardContainer>
          {invitationInfo && (
            <AlertComponent
              type={AlertTypeEnum.success}
              textKey="pages.register.foundInvitation"
              params={{ teamName: invitationInfo.name }}
            />
          )}
          {data?.password && (
            <LoginForm
              inivtationToken={token}
              invitationInfo={invitationInfo}
            />
          )}
          {data?.password &&
            (((data?.google || data.microsoft) && !hostname) || data?.oidc) && (
              <Divider>{t("pages.login.or")}</Divider>
            )}
          <ButtonsWrapper>
            {data?.google && (
              <AuthWithSSOButton provider={AuthProvider.GOOGLE} mode="login" />
            )}
            {data?.microsoft && (
              <AuthWithSSOButton
                provider={AuthProvider.MICROSOFT}
                mode="login"
              />
            )}
            {data?.oidc && (
              <>
                <AuthWithSSOButton
                  provider={AuthProvider.OIDC}
                  mode="login"
                  data={data.oidc}
                />
              </>
            )}
          </ButtonsWrapper>
        </AuthCardContainer>
      </AuthCardWrapper>
    </Container>
  );
};

export default Login;
