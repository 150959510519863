/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OffsetConfig } from "./OffsetConfig";

export type TaskNewCreateDTO = {
  status: TaskNewCreateDTO.status;
  dueDateType?: TaskNewCreateDTO.dueDateType | null;
  title: string;
  description: string;
  contractId: string;
  assigneeIds?: Array<string> | null;
  dueDateConfig?: Record<string, any>;
  reminderConfig?: OffsetConfig | null;
};

export namespace TaskNewCreateDTO {
  export enum status {
    OPEN = "OPEN",
    IN_PROGRESS = "IN_PROGRESS",
    CLOSED = "CLOSED",
  }

  export enum dueDateType {
    FIXED = "FIXED",
    RELATIVE = "RELATIVE",
  }
}
