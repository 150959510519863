import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  height: calc(100vh - 320px);
  width: 100%;

  .ag-header {
    border-top-left-radius: ${theme.borderRadius};
    border-top-right-radius: ${theme.borderRadius};
    background-color: ${theme.color.gray[100]};
    border-bottom: transparent;
  }

  .ag-ltr .ag-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${theme.color.gray[200]};
  }

  .ag-header-cell-label {
    display: flex;
    justify-content: center;
  }

  .ag-pinned-left-header .ag-header-cell .ag-header-cell-label,
  .ag-ltr .ag-cell-last-left-pinned {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ag-header-cell-comp-wrapper {
    justify-content: center;
  }

  .ag-row {
    border-bottom: 1px solid ${theme.color.gray[200]};
  }

  .ag-row-odd {
    background-color: transparent;
  }
`;
