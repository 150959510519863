import styled from "@emotion/styled";
import theme from "theme/theme";
import { RadioGroup as MUIRadioGroup } from "@mui/material";

export const Wrapper = styled.div``;

export const Body = styled.div`
  position: relative;
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${theme.spacing.md};
  margin: 0 0 ${theme.spacing.lg} 0;
`;

export const SearchBar = styled.div`
  width: 50%;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.sm} ${theme.spacing.xl};
  width: 100%;
  background-color: ${theme.color.yellow[200]};
  font-weight: ${theme.font.weight.medium};

  svg {
    width: 16px;
    height: 16px;
    fill: ${theme.color.yellow[600]};
  }
`;

export const InfoMessage = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  width: fit-content;
`;

export const Container = styled.div`
  display: flex;
`;

export const Column = styled.div`
  width: 50%;
  padding: ${theme.spacing.md};
  margin: 0 ${theme.spacing.xl};
  min-width: 420px;
`;

export const EmptyContactArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: ${theme.color.gray[600]};
`;

export const RadioGroup = styled(MUIRadioGroup)`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

export const FooterWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${theme.spacing.lg};
  margin: 0 ${theme.spacing.xxl};
`;

export const Footer = styled.div<{ hasBackButton?: boolean }>`
  display: flex;
  justify-content: ${({ hasBackButton }) =>
    hasBackButton ? "space-between" : "flex-end"};
  width: 100%;
`;
