import styled from "@emotion/styled";
import { DialogContent as MuiDialogContent } from "@mui/material";
import { theme } from "theme";
import { Dialog } from "@mui/material";

export const Header = styled.div<{ hasTitle: boolean }>`
  position: relative;
  display: flex;
  justify-content: ${({ hasTitle }) => (hasTitle ? "center" : "flex-end")};
  align-items: flex-start;
  color: ${theme.color.gray[700]};
  padding: ${theme.spacing.xl} ${theme.spacing.xl};
  ${({ hasTitle }) => !hasTitle && "padding-bottom: 0;"}
  border-bottom: 1px solid ${theme.color.gray[300]};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-self: flex-start;
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.spacing.sm};
`;

type TitleTextProps = {
  hasIcon?: boolean;
  styled?: boolean;
};

export const TitleText = styled.span<TitleTextProps>`
  display: flex;
  padding: 0;
  line-height: 1;
  text-align: center;
  gap: ${theme.spacing.md};
  flex: auto;
  color: ${theme.color.gray[900]};
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.semiBold};

  ${({ styled }) =>
    styled &&
    `
    font-size: ${theme.font.size.h3};
    font-weight: ${theme.font.weight.semiBold};
    color: ${theme.color.blue[600]};
    margin-bottom: 0;
  `}
`;

export const Description = styled.div`
  font-size: ${theme.font.size.body};
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  width: max-content;

  .edit:hover {
    color: ${theme.color.blue[600]};
  }

  .delete:hover {
    color: ${theme.color.red[600]};
  }
`;

export const DialogContent = styled(MuiDialogContent)<{ noPadding?: boolean }>`
  ${({ noPadding }) => noPadding && `padding: 0;`}
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${theme.spacing.xl};
`;

export const Footer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing.xl};
  padding: ${theme.spacing.xl};
  border-top: 1px solid ${theme.color.gray[400]};
`;

export const ModalDialog = styled(Dialog)`
  .MuiPaper-rounded {
    border-radius: ${theme.borderRadius};
  }
`;
