import {
  ContactCreateDTO,
  ContactUpdateDTO,
  OrganizationService,
  ContactTypeDTO,
} from "openapi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ContactDataDTO } from "../../pages/Contacts/ContactDataDTO";

export const useContactQuery = (
  contactId: string,
  orgId: string,
  teamId: string,
  contactTypes?: ContactTypeDTO[]
) => {
  return useQuery({
    queryKey: ["contact", contactId],
    queryFn: async () => {
      if (!contactId) return null;

      if (contactId === "draft" && contactTypes) {
        const draftContactData = {
          typeId: contactTypes[0].id,
        } as ContactDataDTO;

        return draftContactData;
      }

      return (await OrganizationService.getContact(
        contactId,
        orgId,
        teamId
      )) as ContactDataDTO;
    },
  });
};

export const useContactsQuery = (
  orgId: string | undefined,
  teamId: string | undefined
) => {
  return useQuery({
    queryKey: ["contacts", orgId, teamId],
    queryFn: async () =>
      (await OrganizationService.getContacts(
        orgId as string,
        teamId as string
      )) as ContactDataDTO[],
    enabled: !!orgId && !!teamId,
  });
};

export const useContactDataPointDefinitionQuery = (
  id: string,
  definitionId: string
) => {
  return useQuery({
    queryKey: ["contactDatapointDefinition", id, definitionId],
    queryFn: async () =>
      await OrganizationService.getContactDatapointDefinition(definitionId, id),
    enabled: !!id,
  });
};

export const useContactDataPointsDefinitionsQuery = (id: string) => {
  return useQuery({
    queryKey: ["contactDatapointDefinitions", id],
    queryFn: async () =>
      await OrganizationService.getContactDatapointsDefinitions(id),
    enabled: !!id,
  });
};

export const useCreateContactMutation = (
  externalCreateFn?: (data: {
    organizationId: string;
    requestBody: ContactCreateDTO;
  }) => Promise<ContactDataDTO>
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      organizationId: string;
      requestBody: ContactCreateDTO;
    }) => {
      return !externalCreateFn
        ? (OrganizationService.createContact(
            data.organizationId,
            data.requestBody
          ) as Promise<ContactDataDTO>)
        : externalCreateFn(data);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["contacts"] });
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });
};

export const useUpdateContactMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      organizationId: string;
      contactId: string;
      requestBody: ContactUpdateDTO;
    }) => {
      return OrganizationService.updateContact(
        data.contactId,
        data.organizationId,
        data.requestBody
      ) as Promise<ContactDataDTO>;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["contacts"] });
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });
};

export const useDeleteContactMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { organizationId: string; contactId: string }) => {
      return OrganizationService.deleteContact(
        data.contactId,
        data.organizationId
      );
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["contacts"] });
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });
};

export const useContactTypesQuery = (id: string) => {
  return useQuery({
    queryKey: ["contactTypes", id],
    queryFn: async () => await OrganizationService.getContactTypes(id),
  });
};

export const useContactTypeQuery = (id: string, typeId: string) => {
  const queryClient = useQueryClient();
  const queryKey = ["contactType", id, typeId];
  const queryFn = async () => OrganizationService.getContactType(typeId, id);
  const onSuccess = () =>
    void queryClient.invalidateQueries({ queryKey: queryKey });

  return useQuery({ queryKey, queryFn, onSuccess });
};
