import styled from "@emotion/styled";
import { Select as MUISelect, MenuItem as MUIMenuItem } from "@mui/material";
import { theme } from "theme";

export const Wrapper = styled.div`
  &.MuiSelect-select,
  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  margin-top: 16px;
`;

export const ColorIcon = styled.div`
  display: flex;
  align-items: center;
  width: ${theme.spacing.xl};
  height: ${theme.spacing.xl};
  border: 1px solid #00000060;
  border-radius: 30%;
  margin: auto;

  ${({ color }: { color: string }) =>
    color &&
    `
    background-color: ${color};
  `}
`;

export const Select = styled(MUISelect)`
  border-radius: 0.5rem;
  max-height: 40px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
`;

export const MenuItem = styled(MUIMenuItem)``;
