import styled from "@emotion/styled";
import theme from "theme/theme";

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${theme.color.white};
  padding: ${theme.spacing.lg};
  border-radius: ${theme.borderRadius};
  box-shadow: ${theme.shadow.standard};
`;
