/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AddMemberToRoleDTO } from "./AddMemberToRoleDTO";

export type InvitationCreateDTO = {
  language: InvitationCreateDTO.language;
  email: string;
  owner: boolean;
  categoryAdmin: boolean;
  roles: Array<AddMemberToRoleDTO>;
};

export namespace InvitationCreateDTO {
  export enum language {
    EN = "en",
    DE = "de",
  }
}
