import React, {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { MenuItem } from "@mui/material";
import { ColoredPoint } from "../../../components";
import { FormSelectItem } from "../../../components/FormItems/FormSelect/FormSelect";
import { CellValue } from "../helpers";
import { ICellEditorParams } from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { CategoryDTO } from "../../../openapi";
import { CellEditorField } from "./style";
import { theme } from "theme";
import { useLocale } from "hooks";

interface SelectCellEditorParams extends ICellEditorParams {
  values: FormSelectItem[] | string[];
  type?: string;
  translationKey?: string;
  value: CellValue;
  matchedFieldValue?: string;
  isCustomField?: boolean;
}

export const SelectCellEditor = forwardRef(
  (props: SelectCellEditorParams, ref) => {
    const {
      type,
      translationKey,
      values,
      value: cellValue,
      isCustomField,
      matchedFieldValue,
    } = props;
    const { t } = useTranslation();
    const { locale } = useLocale();
    const [value, setValue] = useState<string | CellValue | null>(
      (cellValue?.value ?? cellValue ?? matchedFieldValue) as string
    );
    const [open, setOpen] = useState<boolean>(true);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const paperRef = useRef<HTMLDivElement | null>(null);
    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Tab" && event.shiftKey) {
        props.api.tabToPreviousCell();
      }
      //save changes and move to next cell
      else if (event.key === "Tab" && !event.shiftKey) {
        props.api.tabToNextCell();
      }
    };
    const onChange = (event: ChangeEvent<{ value: string }>) => {
      setOpen(false);
      setValue(event.target.value);
    };

    const menuItem = (
      item: FormSelectItem | string | CategoryDTO,
      index: number
    ) => {
      let menuItem: FormSelectItem | string | CategoryDTO;
      switch (type) {
        case "category":
          menuItem = item as CategoryDTO;
          return (
            <MenuItem key={index} value={menuItem.id}>
              {menuItem.color && <ColoredPoint color={menuItem.color} />}
              <span>{menuItem.name[locale]}</span>
            </MenuItem>
          );
        case "country":
          menuItem = item as FormSelectItem;
          return (
            <MenuItem key={index} value={menuItem.key}>
              <span>{menuItem.value}</span>
            </MenuItem>
          );
        // @TODO: needs to be refactored
        case "contact":
          menuItem = item as FormSelectItem;
          return (
            <MenuItem key={index} value={menuItem.key}>
              <span>{menuItem.value}</span>
            </MenuItem>
          );
        default:
          menuItem = item as string;
          return (
            <MenuItem key={index} value={menuItem}>
              <span>
                {translationKey
                  ? t(`enums.${translationKey}.${menuItem}`)
                  : menuItem}
              </span>
            </MenuItem>
          );
      }
    };
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          (inputRef.current &&
            inputRef.current.contains(event.target as Node)) ||
          (paperRef.current && paperRef.current.contains(event.target as Node))
        ) {
          return;
        }

        props.api.stopEditing();
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [inputRef, paperRef]);
    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        const keyPressed = event.key;
        //Discard changes
        if (keyPressed === "Escape") {
          props.api.stopEditing(true);
        }
        //save changes and close cellEditors
        if (keyPressed === "Enter") {
          props.api.stopEditing();
        }
      };

      document.addEventListener("keyup", handleKeyDown);

      return () => {
        document.removeEventListener("keyup", handleKeyDown);
      };
    }, []);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return isCustomField ? { value } : value;
        },
      };
    });

    if (values && type === "contact") {
      values.sort((a, b) =>
        (a as FormSelectItem).value.localeCompare((b as FormSelectItem).value)
      );
    }

    return (
      <CellEditorField
        ref={inputRef}
        value={value ?? ""}
        focused={true}
        onChange={onChange}
        onClick={() => setOpen(!open)}
        fullWidth
        select={true}
        onKeyDown={onKeyDown}
        size="small"
        SelectProps={{
          open: open,
          MenuProps: {
            PaperProps: {
              ref: paperRef,
            },
            sx: {
              maxHeight: "calc(100% - 40%)",
              "& .MuiPaper-root": {
                boxShadow: theme.shadow.standard,
              },
            },
          },
        }}
        style={{ pointerEvents: "none" }}
      >
        {values?.map((item, index) => menuItem(item, index))}
      </CellEditorField>
    );
  }
);

SelectCellEditor.displayName = "SelectCellEditor";
