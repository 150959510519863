import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, Control } from "react-hook-form";
import { ToggleAllWrapper, Cell } from "./styles";
import { Switch } from "new-components";

type Props = {
  control: Control;
  enabled?: boolean;
  onChange: () => void;
};

const AllNotifications = ({ control, enabled, onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <ToggleAllWrapper>
      <Cell>
        {t(
          "pages.settings.tabs.notifications.configuration.all.allNotifications"
        )}
      </Cell>
      <Cell>
        {enabled
          ? t("pages.settings.tabs.notifications.configuration.all.disableAll")
          : t("pages.settings.tabs.notifications.configuration.all.enableAll")}
      </Cell>
      <Cell>
        <Controller
          control={control}
          name="all-notifications"
          render={() => <Switch checked={enabled} onChange={onChange} />}
        />
      </Cell>
    </ToggleAllWrapper>
  );
};

export default AllNotifications;
