import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import {
  People,
  FindInPage,
  Bookmark,
  BusinessOutlined,
} from "@mui/icons-material";
import { Wrapper } from "../styles";
import styled from "@emotion/styled";

export const StyledNavLink = styled(NavLink)`
  font-family: inherit; // TODO This is stupid, somehow the styled.button reset the font-family so I have to set it. Someone make it go away
`;

const setActiveLink = ({ isActive }: { isActive: boolean }) => {
  return isActive ? "active" : "";
};

const AdminMenu = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <StyledNavLink to="/kiosk/contracts" className={setActiveLink}>
        <>
          <FindInPage fontSize="small" />
          {t("pages.admin.tabTitles.pendingContracts")}
        </>
      </StyledNavLink>

      <StyledNavLink to="/kiosk/categories" className={setActiveLink}>
        <>
          <Bookmark fontSize="small" />
          {t("pages.admin.tabTitles.categories")}
        </>
      </StyledNavLink>

      <StyledNavLink to="/kiosk/users" className={setActiveLink}>
        <>
          <People fontSize="small" />
          {t("pages.admin.tabTitles.users")}
        </>
      </StyledNavLink>

      <StyledNavLink to="/kiosk/organizations" className={setActiveLink}>
        <>
          <BusinessOutlined fontSize="small" />
          {t("pages.admin.tabTitles.organizations")}
        </>
      </StyledNavLink>

      <StyledNavLink to="/kiosk/annotations" className={setActiveLink}>
        <>
          <BusinessOutlined fontSize="small" />
          {t("pages.admin.tabTitles.annotations")}
        </>
      </StyledNavLink>
    </Wrapper>
  );
};

export default AdminMenu;
