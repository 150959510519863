import globalConfig from "shared/config/global.config";
import { AuthProvider } from "./AuthProvider.enum";
import * as oauth from "oauth4webapi";
import { LimitedIdentityProviderDTO } from "openapi";
import { getOriginWithoutHostname } from "hooks/HostnameHook";

export type ProviderFunction = (state: string, codeChallenge: string) => URL;

abstract class AbstractAuthProvider {
  constructor(
    private readonly state: string,
    private readonly codeChallenge: string,
    protected readonly hostname?: string
  ) {}
  protected abstract get type(): AuthProvider;
  protected abstract getAuthorizationEndpoint(): Promise<string> | string;
  protected abstract get clientId(): string;
  protected abstract get scope(): string;
  protected get prompt(): string | null {
    return null;
  }

  protected get origin(): string {
    return getOriginWithoutHostname();
  }

  protected get redirectUri(): string {
    return this.origin + "/federated-identity/" + this.type;
  }

  async getAuthUrl(): Promise<URL> {
    const url = new URL(await this.getAuthorizationEndpoint());
    url.searchParams.append("response_type", "code");
    url.searchParams.append("scope", this.scope);

    const state = JSON.stringify({
      id: this.state,
      tenant: this.hostname,
    });
    const encodedState = encodeURIComponent(btoa(state));

    url.searchParams.append("state", encodedState);
    url.searchParams.append("redirect_uri", this.redirectUri);
    url.searchParams.append("client_id", this.clientId);
    url.searchParams.append("code_challenge", this.codeChallenge);
    url.searchParams.append("code_challenge_method", "S256");
    if (this.prompt) {
      url.searchParams.append("prompt", this.prompt);
    }
    return url;
  }
}

class GoogleAuthProvider extends AbstractAuthProvider {
  protected get type(): AuthProvider {
    return AuthProvider.GOOGLE;
  }
  protected getAuthorizationEndpoint(): string {
    return "https://accounts.google.com/o/oauth2/v2/auth";
  }
  protected get clientId(): string {
    return globalConfig.REACT_APP_GOOGLE_CLIENT_ID as string;
  }
  protected get scope(): string {
    return "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile";
  }

  protected override get prompt(): string {
    return "select_account";
  }
}

class MicrosoftAuthProvider extends AbstractAuthProvider {
  protected get type(): AuthProvider {
    return AuthProvider.MICROSOFT;
  }
  protected getAuthorizationEndpoint() {
    return "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";
  }
  protected get clientId(): string {
    return globalConfig.REACT_APP_MICROSOFT_CLIENT_ID as string;
  }
  protected get scope(): string {
    return "openid profile email User.Read";
  }

  protected override get prompt(): string {
    return "select_account";
  }
}

class OIDCAuthProvider extends AbstractAuthProvider {
  private readonly providerData: LimitedIdentityProviderDTO;
  constructor(
    state: string,
    codeChallenge: string,
    hostname?: string,
    providerData?: LimitedIdentityProviderDTO
  ) {
    super(state, codeChallenge, hostname);
    if (!providerData) {
      throw new Error();
    }
    this.providerData = providerData;
  }
  protected get type(): AuthProvider {
    return AuthProvider.OIDC;
  }
  protected async getAuthorizationEndpoint(): Promise<string> {
    if (!this.hostname) {
      throw new Error("No hostname provided");
    }
    const issuer = new URL(
      `/api/auth/methods/${this.hostname}`,
      window.location.origin
    );

    const originalIssuer = new URL(this.providerData.discoveryUrl);

    const discoveryResult = await oauth
      .discoveryRequest(issuer)
      .then((response) =>
        oauth.processDiscoveryResponse(originalIssuer, response)
      );
    if (!discoveryResult.authorization_endpoint) {
      throw new Error();
    }
    return discoveryResult.authorization_endpoint;
  }
  protected get clientId(): string {
    return this.providerData.clientId;
  }
  protected get scope(): string {
    return "openid profile email";
  }
  protected override get origin(): string {
    return window.location.origin;
  }
}

export const AuthProviders = {
  [AuthProvider.GOOGLE]: GoogleAuthProvider,
  [AuthProvider.MICROSOFT]: MicrosoftAuthProvider,
  [AuthProvider.OIDC]: OIDCAuthProvider,
};

export const parseState = (state: string) =>
  JSON.parse(atob(decodeURIComponent(state))) as { id: string; tenant: string };
