import styled from "@emotion/styled";
import { breakpoints } from "constants/";
import { theme } from "theme";

export const CategoryOptionWrapper = styled.li<{ active?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.md};
  border: 1px solid ${theme.color.gray[300]};
  border-radius: ${theme.borderRadius};
  cursor: pointer;
  transition: width 0.25s ease-in-out, background-color 0.25s ease-in-out,
    border 0.25s ease-in-out, color 0.25s ease-in-out;
  width: calc(100% - ${theme.spacing.md});

  @media (min-width: ${breakpoints.sm}px) {
    width: calc(50% - ${theme.spacing.md});
  }

  :hover {
    border: 1px solid ${theme.color.blue[600]};
  }

  ${({ active }) =>
    active &&
    `
    border: 1px solid transparent;
    color: ${theme.color.white};
    background-color: ${theme.color.blue[600]};

    :hover {
      border: 1px solid ${theme.color.blue[100]};
    }
  `}
`;

export const TeamOptionWrapper = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  width: calc(100% - ${theme.spacing.md});

  @media (min-width: ${breakpoints.sm}px) {
    width: calc(50% - ${theme.spacing.md});
  }

  span {
    max-width: 160px;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
  }
`;

export const ColorIndicator = styled.span<{ color?: string }>`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: 14px;
  height: 14px;
  background-color: ${({ color }) => color};
  border: 1px solid ${theme.color.white};
  border-radius: ${theme.borderRadius};
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
