import { SignerDTO } from "openapi";
import dayjs from "dayjs";

export const isSendReminderAllowed = (
  signer: SignerDTO,
  hours = 2 // default time limit
): boolean => {
  const { requestedAt, remindedAt } = signer;

  const thresholdTime = hours * 60; // minutes
  const currentTime = dayjs();

  if (!requestedAt) return false;
  const requestedTime = dayjs(requestedAt);
  if (currentTime.diff(requestedTime, "minute") < thresholdTime) return false;

  if (!remindedAt) return true;
  const remindedTime = dayjs(remindedAt);

  return currentTime.diff(remindedTime, "minute") >= thresholdTime;
};

export const dateTimeFormatter = (dateTime: string, userDateFormat: string) => {
  if (!dateTime) return "-";
  return dayjs(dateTime).format(`${userDateFormat} / HH:mm`);
};
