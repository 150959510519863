import React from "react";
import { ColorPointComponent } from "./styles";

export type ColoredPointProps = {
  color: string;
};

const ColoredPoint = ({ color }: ColoredPointProps) => {
  return <ColorPointComponent color={color} data-testid="colored-point" />;
};

export default ColoredPoint;
