import styled from "@emotion/styled";
import ContentCopyIcon from "assets/svg/copy-icon.svg?react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorCircleIcon from "@mui/icons-material/Error";
import { theme } from "theme";

const commonIconStyles = `
  font-size: ${theme.font.size.subheading};
  min-width: 22px;
`;

export const CopyIcon = styled(ContentCopyIcon)`
  ${commonIconStyles}
  fill: ${theme.color.gray[600]};
`;

export const DoneIcon = styled(DoneAllIcon)`
  fill: ${theme.color.green[600]};
  ${commonIconStyles}
`;

export const SuccessIcon = styled(CheckCircleIcon)`
  fill: ${theme.color.green[600]};
  ${commonIconStyles}
`;

export const Button = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: fit-content;
  cursor: pointer;
  min-width: 22px;
  padding: 0;
`;

export const ErrorIcon = styled(ErrorCircleIcon)`
  ${commonIconStyles}
  fill: ${theme.color.red[600]};
`;
