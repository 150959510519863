import React from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { SnackbarProvider } from "notistack";
import ErrorHandler from "shared/errors/ErrorHandler";
import { GlobalProvider } from "contexts/GlobalState";
import AppRoutes from "./AppRoutes";
import { SnackbarWithCloseButton } from "components/Snackbar/SnackbarCloseButton";
import InvitationTokenChecker from "pages/RegisterPage/InvitationTokenChecker";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "shared/api/client";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="*"
      element={
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          action={(snackbarKey) => (
            <SnackbarWithCloseButton snackbarKey={snackbarKey} />
          )}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <ErrorBoundary FallbackComponent={ErrorHandler}>
            <GlobalProvider>
              {/**
               * @TODO: Handle registration token and the invitation token differently
               * this is a temporary workaround solution.
               */}
              <InvitationTokenChecker>
                <AppRoutes />
              </InvitationTokenChecker>
            </GlobalProvider>
          </ErrorBoundary>
        </SnackbarProvider>
      }
    />
  )
);

const App = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const theme = urlParams.get("theme");
  if (theme) {
    document.documentElement.setAttribute("data-theme", theme);
  }

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default App;
