/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { enqueueSnackbar } from "notistack";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ApiError,
  OrganizationNewService,
  WebhookDTO,
  CreateWebhookDto,
  UpdateWebhookDTO,
} from "openapi";

const WEBHOOKS_KEY = "webhooks";

export const useWebhooksQuery = (organizationId: string) => {
  return useQuery<WebhookDTO[], ApiError>({
    queryKey: [WEBHOOKS_KEY, organizationId],
    queryFn: async () => {
      return await OrganizationNewService.getAllWebhooks(organizationId);
    },
    onError: (error) => {
      console.error(error);
    },
  });
};

export const useDeleteWebhookMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: { webhookId: string; organizationId: string }) => {
      return OrganizationNewService.deleteWebhook(
        data?.webhookId,
        data?.organizationId
      );
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: [WEBHOOKS_KEY, variables.organizationId],
        exact: true,
      });

      enqueueSnackbar(
        t("pages.settings.organization.webhooks.notifications.success.deleted"),
        { variant: "success" }
      );
    },
    onError: (error: Error) => {
      Sentry &&
        Sentry.captureMessage(
          `Something went wrong while trying to delete a webhook: ${JSON.stringify(
            error
          )}`
        );

      enqueueSnackbar(
        t("pages.settings.organization.webhooks.notifications.error.deleted"),
        {
          variant: "error",
        }
      );
    },
  });
};

export const useCreateWebhookMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: {
      organizationId: string;
      requestBody: CreateWebhookDto;
    }) => {
      return OrganizationNewService.createWebhook(
        data.organizationId,
        data.requestBody
      );
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: [WEBHOOKS_KEY, variables.organizationId],
      });

      enqueueSnackbar(
        t("pages.settings.organization.webhooks.notifications.success.created"),
        { variant: "success" }
      );
    },
    onError: (error: Error) => {
      Sentry &&
        Sentry.captureMessage(
          `Something went wrong while trying to create a webhook: ${JSON.stringify(
            error
          )}`
        );

      enqueueSnackbar(
        t("pages.settings.organization.webhooks.notifications.error.created"),
        { variant: "error" }
      );
    },
  });
};

export const useUpdateWebhookMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: {
      webhookId: string;
      organizationId: string;
      requestBody: UpdateWebhookDTO;
    }) => {
      return OrganizationNewService.updateWebhook(
        data.webhookId,
        data.organizationId,
        data.requestBody
      );
    },
    onSuccess: async (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: [WEBHOOKS_KEY, variables.organizationId],
      });
      await queryClient.refetchQueries({
        queryKey: [WEBHOOKS_KEY, variables.organizationId, variables.webhookId],
      });

      enqueueSnackbar(
        t("pages.settings.organization.webhooks.notifications.success.updated"),
        { variant: "success" }
      );
    },
    onError: (error: Error) => {
      Sentry &&
        Sentry.captureMessage(
          `Something went wrong while trying to update the webhook: ${JSON.stringify(
            error
          )}`
        );

      enqueueSnackbar(
        t("pages.settings.organization.webhooks.notifications.error.updated"),
        { variant: "error" }
      );
    },
  });
};
