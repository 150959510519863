import styled from "@emotion/styled";

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit; // TODO This is stupid, somehow the styled.button reset the font-family so I have to set it. Someone make it go away
  gap: 1rem;
  padding: 0;
  border: none;
  background: none;
  transition: opacity 0.2s ease-in-out;

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
