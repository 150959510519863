import React from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import routePaths from "constants/routePaths";
import { OverviewRecentlyAddedDto, SignatureData } from "../../../openapi";
type Props = {
  item: OverviewRecentlyAddedDto | SignatureData;
  description?: JSX.Element;
  index: number;
  rightSide: JSX.Element;
};

const RecentItem = ({ item, index, rightSide, description }: Props) => {
  return (
    <TableRow
      key={item.contractId}
      sx={{
        backgroundColor: `${index % 2 === 0 ? "none" : "#f9fafb"}`,
        "& td": { borderBottom: 0 },
        whiteSpace: "normal",
        wordBreak: "break-word",
      }}
    >
      <TableCell width="100%">
        <Box className="flex-align-center">
          <Box className="flex-align-center">
            <InsertDriveFileIcon
              sx={{ color: "#68d391", marginRight: "0.5rem" }}
            />
          </Box>
          <Box
            sx={{
              flex: "1",
              color: "#1a202c",
              fontWeight: "500",
            }}
          >
            <Link
              component={RouterLink}
              to={routePaths.CONTRACT_VIEW.replace(":id", item.contractId)}
              className="hover-underline"
            >
              {item.contractName}
            </Link>
            {description && description}
          </Box>
        </Box>
      </TableCell>
      <TableCell
        align="right"
        style={{ color: "#a0aec0", whiteSpace: "nowrap" }}
      >
        {rightSide}
      </TableCell>
    </TableRow>
  );
};

export default RecentItem;
