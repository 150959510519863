/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiError, TeamService, TeamMembersDto, TeamMemberDto } from "openapi";
import { OrganizationService, TeamMemberBasicInfoDTO } from "../../openapi";

export const useTeamMembersQuery = (teamId: string) => {
  return useQuery<TeamMembersDto, ApiError>({
    queryKey: ["members", teamId],
    queryFn: () => TeamService.getMembers(teamId),
    enabled: !!teamId,
    retry(failureCount, error) {
      return (
        failureCount < 3 &&
        !!error &&
        error.status !== 404 &&
        error.status !== 403
      );
    },
    refetchOnWindowFocus: false,
  });
};

export const useTeamMemberQuery = (teamId: string, memberId?: string) => {
  return useQuery<TeamMemberDto, ApiError>({
    queryKey: ["members", teamId, memberId],
    queryFn: () => TeamService.getMember(memberId!, teamId),
    enabled: !!teamId || !!memberId,
    retry(failureCount, error) {
      return (
        failureCount < 3 &&
        !!error &&
        error.status !== 404 &&
        error.status !== 403
      );
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetTeamMembersWithAccessQuery = (
  teamId: string,
  categoryId: string
) => {
  return useQuery<TeamMemberBasicInfoDTO[], ApiError>({
    queryKey: [teamId, categoryId],
    queryFn: () =>
      OrganizationService.getMembersWithAccessToCategory(teamId, categoryId),
    enabled: !!categoryId && !!teamId,
    retry(failureCount, error) {
      return (
        failureCount < 3 &&
        !!error &&
        error.status !== 404 &&
        error.status !== 403
      );
    },
    refetchOnWindowFocus: false,
  });
};

export const useRemoveMemberFromOrganizationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { memberId: string; teamId: string }) => {
      return OrganizationService.removeMemberFromOrganization(
        data.memberId,
        data.teamId
      );
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["members", variables.teamId],
      });
      void queryClient.invalidateQueries({
        queryKey: ["rolesOfMember", variables.memberId],
      });
    },
  });
};
