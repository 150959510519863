import styled from "@emotion/styled";
import { theme } from "theme";

export const CategoryColorBlock = styled.span<{
  color?: string;
}>`
  margin-right: ${theme.spacing.sm};
  height: 1rem;
  width: 1rem;
  min-width: 1rem;
  border-radius: 0.25rem;
  background-color: ${(prop) => prop.color};
`;

export const CategoryTitle = styled.div`
  font-weight: ${theme.font.weight.medium};
  color: ${theme.color.gray["900"]};
  max-width: 10rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const CategoryStatistics = styled.span`
  margin-left: ${theme.spacing.xs};
  font-weight: ${theme.font.weight.normal};
  color: ${theme.color.gray["700"]};
`;
