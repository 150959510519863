import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${theme.spacing.lg};

  svg .crossIcon {
    fill: ${theme.color.green[600]} !important;
    color: ${theme.color.red[600]} !important;
  }
`;

export const ButtonsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.md};
`;

export const SectionHeaderContainer = styled.div`
  margin-bottom: ${theme.spacing.xl};
`;

export const SectionDescription = styled.div`
  margin: ${theme.spacing.xl} 0;
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  gap: 0.5rem;
`;

export const HeaderIconStyles = {
  backgroundColor: theme.color.blue[600],
  width: "40px",
  height: "40px",
  borderRadius: theme.borderRadius,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: "0",
  marginRight: theme.spacing.lg,
  svg: {
    fill: "white",
  },
};

export const Message = styled.p`
  margin: ${theme.spacing.xxl} 0 0;
`;

export const SearchBar = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  justify-content: space-between;
  margin: ${theme.spacing.xxl} 0;
  max-width: 40rem;
`;

export const CategoryPermissionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.sm};
`;

export const FiltersWrapper = styled.div`
  position: relative;
`;

export const FiltersCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: -14px;
  transform: translate(-50%, -50%);
  height: 18px;
  width: 18px;
  background-color: ${theme.color.indigo[600]};
  color: white;
  padding: ${theme.spacing.xs};
  border: 1px solid ${theme.color.white};
  border-radius: 50%;
  font-size: ${theme.font.size.caption};
`;
