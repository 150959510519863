/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ElementNode, Klass, LexicalEditor } from "lexical";

/**
 * Currently some functionality in Lexical isn't working correctly, for example
 * the formatting gets lost when exporting to HTML. This function patches the
 * exportDOM function of the nodes we choose to patch to make sure the exported
 * content does contain all the necessary data to import it correctly at a later point when
 * the user wants to edit it again or he wants to generate a PDF.
 * @param LexicalNode
 */
export function patchStyleTransformation(LexicalNode: Klass<ElementNode>) {
  const originalExportDOM = LexicalNode.prototype.exportDOM;
  LexicalNode.prototype.exportDOM = function exportDOM(editor: LexicalEditor) {
    const result = originalExportDOM.apply(this, [editor]);
    const element = result.element;
    if (element) {
      const formatType = this.getFormatType();
      element.style.textAlign = formatType;

      const direction = this.getDirection();
      if (direction) {
        element.dir = direction;
      }
      const indent = this.getIndent();
      if (indent > 0) {
        element.style.textIndent = `${indent * 20}px`;
      }
    }
    return result;
  };
}
