/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { OverviewService } from "openapi";
import { useQuery } from "@tanstack/react-query";

export const useOverviewQuery = (teamId: string | undefined) => {
  return useQuery({
    queryKey: ["overview", teamId],
    queryFn: () => OverviewService.getOverview(teamId!),
    enabled: !!teamId,
  });
};
