import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow-y: scroll;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: ${theme.font.size.h5};
  font-weight: ${theme.font.weight.semiBold};
`;

export const Subheading = styled.p`
  margin: ${theme.spacing.xxl} 0;
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const URLFieldGroup = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${theme.spacing.md};

  & .MuiFormControl-root {
    margin-bottom: 0;
  }
`;

export const Body = styled.div`
  width: 50%;
`;

export const MessageBoxWrapper = styled.div`
  margin: ${theme.spacing.xl} 0;

  svg {
    width: 42px;
    height: 42px;
  }
`;

export const FieldDescription = styled.div`
  margin-top: ${theme.spacing.xl};
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.bold};
  line-height: 24px;
`;

export const EventName = styled.div`
  color: ${theme.color.gray[700]};

  :hover {
    color: ${theme.color.indigo[600]};
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const LinkAddress = styled.span`
  color: ${theme.color.gray[600]};
`;

export const Status = styled.span<{ active?: boolean }>`
  font-weight: ${theme.font.weight.semiBold};
  ${({ active }) =>
    active
      ? `color: ${theme.color.green[700]};`
      : `color: ${theme.color.orange[700]};`}
`;

export const Footer = styled.div<{ visible: boolean }>`
  ${({ visible }) =>
    visible
      ? `
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: ${theme.spacing.sm};
      margin-top: ${theme.spacing.xxl};
      padding: ${theme.spacing.xl};
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to bottom, ${theme.color.white}80 50%, ${theme.color.white} 50%);
    `
      : `display: none;`}
`;

export const Empty = styled.div`
  color: ${theme.color.gray[600]};
`;

export const EventOption = styled.div`
  align-items: flex-start;
  justify-content: space-between;
  gap: ${theme.spacing.lg};
  font-size: ${theme.font.size.body};
  width: 100%;
  padding-bottom: ${theme.spacing.xl};
  padding-bottom: 1px solid ${theme.color.gray[200]};
`;

export const CheckAllWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 44px;
`;

export const Table = styled.table`
  width: 100%;
  margin-bottom: ${theme.spacing.xxxxl};
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  text-align: left;
  border-bottom: 1px solid ${theme.color.gray[400]};
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid ${theme.color.blue[600]};
  border-bottom: 1px solid ${theme.color.gray[400]};
`;

export const TableHeadCell = styled.th`
  font-weight: ${theme.font.weight.semiBold};
  padding: ${theme.spacing.lg} ${theme.spacing.sm};
`;

export const TableCell = styled.td`
  padding: ${theme.spacing.md} ${theme.spacing.sm};
`;

export const NotificationMessage = styled.div<{ isSuccess?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.lg};
  padding: ${theme.spacing.md} ${theme.spacing.xl};
  color: ${theme.color.white};
  border-radius: ${theme.borderRadius};
  margin-bottom: ${theme.spacing.xl};

  ${({ isSuccess }) =>
    isSuccess
      ? `
      background-color: ${theme.color.green[700]};
    `
      : `
    background-color: ${theme.color.red[700]};
    `}
`;
