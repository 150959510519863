/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MemberDTO } from "./MemberDTO";
import type { PermissionDTO } from "./PermissionDTO";

export type RoleDetailsDTO = {
  id: string;
  type: RoleDetailsDTO.type;
  teamId: string;
  name: string;
  permissions: Array<PermissionDTO>;
  members: Array<MemberDTO>;
};

export namespace RoleDetailsDTO {
  export enum type {
    USER = "USER",
    LIMITED_USER = "LIMITED_USER",
    OWNER = "OWNER",
  }
}
