import styled from "@emotion/styled";
import verifiedIcon from "assets/svg/verified.svg?react";
import { theme } from "theme";

export const Button = styled.button<{
  verified?: boolean;
  disabled?: boolean;
  invisible?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  border: 1px solid;
  border-radius: ${theme.borderRadius};
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out,
    transform 0.25s ease-in-out, color 0.25s ease-in-out;
  color: ${theme.color.purple[600]};

  :active {
    transform: scale(0.96);
  }

  ${({ invisible }) => invisible && `display: none;`}

  ${({ verified }) =>
    verified
      ? `
          cursor: default;
          border-color: ${theme.color.purple[600]};
          background-color: ${theme.color.purple[100]};
        `
      : `
          border-color: ${theme.color.gray[400]};
          background-color: ${theme.color.gray[100]};
          cursor: pointer;

          :hover {
            color: ${theme.color.purple[600]};
            border-color: ${theme.color.purple[600]};
            background-color: ${theme.color.purple[100]};
          }
        `}

  ${({ disabled }) =>
    disabled &&
    `
    :hover {
      color: ${theme.color.gray[400]};
      border-color: ${theme.color.gray[400]};
      background-color: ${theme.color.gray[100]};
      cursor: default;
    }
  `}
`;

export const Icon = styled(verifiedIcon)`
  fill: ${theme.color.green[600]};
`;
