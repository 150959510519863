import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  SelectField: {
    "&.MuiInputBase-root": {
      boxShadow: "0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)",
      borderWidth: "1px",
      borderRadius: "0.5rem",
      borderColor: "#a0aec0",
    },
  },
});
