import styled from "@emotion/styled";
import TemplateSvg from "assets/svg/contract-template-icon.svg?react";
import { theme } from "theme";

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
`;

export const BodyWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.md};
`;

export const TemplateIcon = styled(TemplateSvg)`
  fill: ${theme.color.gray[600]};
`;

export const TemplateName = styled.span`
  color: ${theme.color.gray[600]};
`;
