import React from "react";
import { ChangeLogDataSimpleItemType } from "../../types";
import { useTranslation } from "react-i18next";
import Tag from "new-components/Tag";
import ArrowIcon from "assets/svg/arrow-up.svg?react";
import {
  Values,
  Title,
  Wrapper,
  TitleIconWrapper,
  PreviousValue,
  CurrentValue,
  ValueWrapper,
  ValueIconWrapper,
} from "./styles";

export type TagsFieldType = {
  fieldData: ChangeLogDataSimpleItemType;
  variant?: "row";
  fieldIcon?: JSX.Element;
};

const Tags = ({ fieldData, variant, fieldIcon }: TagsFieldType) => {
  const { t } = useTranslation();
  const fieldName = t("pages.import.labels.tags");

  if (!fieldData) return <></>;

  const isUpdated = !!fieldData.from;
  const valueIsRemoved = !!fieldData.from && !fieldData.to;

  return (
    <Wrapper variant={variant}>
      <Title>
        <TitleIconWrapper>{fieldIcon}</TitleIconWrapper>
        {fieldName}
      </Title>

      <Values variant={variant}>
        {isUpdated ? (
          <ValueWrapper>
            <PreviousValue>
              {fieldData?.from?.split(",").map((tag) => (
                <Tag key={tag} variant="tag">
                  {tag}
                </Tag>
              ))}
            </PreviousValue>
            {!valueIsRemoved && (
              <ValueIconWrapper>
                <ArrowIcon />
              </ValueIconWrapper>
            )}
            <CurrentValue>
              {fieldData?.to?.split(",").map((tag) => (
                <Tag key={tag} variant="tag">
                  {tag}
                </Tag>
              ))}
            </CurrentValue>
          </ValueWrapper>
        ) : (
          <CurrentValue variant={variant}>
            {fieldData?.to?.split(",").map((tag) => (
              <Tag key={tag} variant="tag">
                {tag}
              </Tag>
            ))}
          </CurrentValue>
        )}
      </Values>
    </Wrapper>
  );
};

export default Tags;
