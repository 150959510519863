import React, { useState } from "react";
import { ClickAwayListener, Fade, Paper, Popper, Tooltip } from "@mui/material";
import { useOverviewActions, useOverviewState } from "contexts/grid/hooks";
import {
  ContractViewInputDto,
  ContractViewItemDto,
  ContractViewService,
} from "openapi";
import { useTranslation } from "react-i18next";
import { ViewButton, ViewWarningIcon } from "./styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { theme } from "theme";
import { EntityViewList } from "./components/EntityViewList";
import { useContractViewsQuery } from "shared/api/views";
import { useTeam } from "contexts/team/hooks";
import { flushSync } from "react-dom";
import { getActiveViewDeterminerFunction } from "contexts/grid/helpers";
import { exportView } from "pages/Contracts/helpers";
import { useFieldsQuery } from "shared/api";
import { getFilterModelAsJson, getViewName } from "./helpers";
import ContractSaveOrEditViewModal from "./components/Modals/SaveOrEditViewModal";
import Modal, { ModalResultEnum } from "components/Modal/Modal";
import { useStyles as useStyledButtons } from "../../components/StyledComponents/StyledBaseButtons";
import ContractDeleteGridViewModalContent from "./components/Modals/DeleteGridViewModalContent";
import { useOrganizationCategoriesQuery } from "shared/api/organization/categories";
import { useLocale } from "hooks";

export const EntityGridViewSelector = () => {
  const { locale } = useLocale();
  const { t } = useTranslation();
  const { overview } = useOverviewState();
  const { overview: overviewActions } = useOverviewActions();
  const buttonClasses = useStyledButtons();
  const [showSaveOrEditViewModal, setShowSaveOrEditViewModal] = useState(false);
  const [showDeleteGridViewModal, setShowDeleteGridViewModal] = useState(false);
  const [viewToEdit, setViewToEdit] = useState<ContractViewItemDto | null>(
    null
  );

  const { selectedTeamId, organizationId } = useTeam();
  const { data: views, refetch } = useContractViewsQuery(
    selectedTeamId,
    overview.type
  );
  const { data: categories } = useOrganizationCategoriesQuery(organizationId);
  const { data: fields } = useFieldsQuery(organizationId);
  const [anchorViewEl, setAnchorViewEl] = useState<HTMLButtonElement | null>(
    null
  );
  const isViewModalOpen = Boolean(anchorViewEl);

  const openViewSelector = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorViewEl(event.currentTarget);
  };

  const closeViewSelector = () => setAnchorViewEl(null);

  if (!views || !overview.agGrid.initialized) return null;

  const setView = async (viewId: string | null, shouldRefetch = false) => {
    closeViewSelector();
    if (!viewId) {
      return overviewActions.dispatch({
        type: "reset",
      });
    }
    let currentViews = views;
    if (shouldRefetch) {
      const refetchedViews = await refetch();
      if (refetchedViews.data) {
        currentViews = refetchedViews.data;
      }
    }
    flushSync(() => {
      overviewActions.dispatch({
        type: "setActiveView",
        value: {
          id: viewId,
          determineActiveView: getActiveViewDeterminerFunction(currentViews),
        },
      });
    });
  };

  const createView = async (viewData: Omit<ContractViewInputDto, "type">) => {
    const id = await ContractViewService.create(selectedTeamId, {
      ...viewData,
      type: overview.type,
    });
    await setView(id, true);
  };

  const updateView = async (viewData: Omit<ContractViewItemDto, "owner">) => {
    await ContractViewService.update(viewData.id, {
      name: viewData.name,
      data: viewData.data,
      filter: viewData.filter,
      type: viewData.type,
      shared: viewData.shared,
    });
    await refetch();
  };

  const saveCurrentView = async () => {
    if (overview.agGrid.initialized) {
      let currentView = overview.activeView;
      const columnStateAsJson = JSON.stringify(
        overview.agGrid.gridRef.current?.columnApi.getColumnState()
      );
      if (currentView && overview.agGrid.gridRef.current?.api) {
        currentView = {
          ...currentView,
          data: columnStateAsJson,
          filter: getFilterModelAsJson(overview.agGrid.gridRef.current?.api),
        };
        await updateView(currentView);
      }
    }
  };

  const deleteView = async (action?: ModalResultEnum, _values?: unknown) => {
    if (action === ModalResultEnum.DELETE && viewToEdit) {
      await ContractViewService.delete(viewToEdit.id);
      await refetch();
    }
    if (showSaveOrEditViewModal) {
      setShowSaveOrEditViewModal(false);
    }
    setViewToEdit(null);
    setShowDeleteGridViewModal(false);
  };

  return (
    <>
      <Tooltip
        title={`${t("pages.contracts.header.view")}: ${getViewName(
          t,
          overview.activeView
        )}`}
      >
        <ViewButton
          id="viewBtn"
          onClick={openViewSelector}
          sx={{ ":hover": { backgroundColor: "white" } }}
        >
          {(overview.unsavedColumnChangePresent ||
            overview.unsavedFilterChangePresent) && <ViewWarningIcon />}
          <span>
            {`${t("pages.contracts.header.view")}: ${getViewName(
              t,
              overview.activeView
            )}`}
          </span>
          <KeyboardArrowDownIcon
            sx={{ marginLeft: "0.5rem", fill: theme.color.gray[700] }}
          />
        </ViewButton>
      </Tooltip>
      <Popper
        id={"view-popper"}
        open={isViewModalOpen}
        anchorEl={anchorViewEl}
        placement="bottom-end"
        transition
        sx={{
          width: "260px",
          zIndex: 2,
          boxShadow: theme.shadow.standard,
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={closeViewSelector}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <EntityViewList
                  views={views}
                  setView={setView}
                  saveCurrentView={saveCurrentView}
                  editView={(view) => {
                    setShowSaveOrEditViewModal(true);
                    setViewToEdit(view);
                  }}
                  saveNewView={() => {
                    setShowSaveOrEditViewModal(true);
                  }}
                  exportView={() => {
                    if (overview.agGrid.initialized) {
                      exportView(
                        overview.agGrid.gridRef,
                        getViewName(t, overview.activeView),
                        t,
                        locale,
                        categories,
                        fields
                      );
                    }
                  }}
                  deleteView={(view) => {
                    setShowDeleteGridViewModal(true);
                    setViewToEdit(view);
                  }}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
      <ContractSaveOrEditViewModal
        open={showSaveOrEditViewModal}
        selectedView={viewToEdit}
        gridRef={overview.agGrid.gridRef}
        filterModelGetter={() =>
          overview.agGrid.gridRef?.current?.api
            ? getFilterModelAsJson(overview.agGrid.gridRef?.current?.api)
            : null
        }
        getFilterModelAsJson={() =>
          overview.agGrid.gridRef?.current?.api
            ? getFilterModelAsJson(overview.agGrid.gridRef?.current?.api)
            : null
        }
        createView={createView}
        updateView={updateView}
        deleteView={(view) => {
          setShowDeleteGridViewModal(true);
          setViewToEdit(view);
        }}
        setSelectedViewToEditOrDelete={() => void 0}
        setShowSaveOrEditViewModal={setShowSaveOrEditViewModal}
      />
      <Modal
        open={showDeleteGridViewModal}
        isShowHeader={false}
        baseButtonTextKey="pages.contracts.header.modals.buttons.delete"
        baseButtonActionType={ModalResultEnum.DELETE}
        extraButtonTextKey="pages.contracts.header.modals.buttons.cancel"
        extraButtonActionType={ModalResultEnum.CANCEL}
        baseButtonClassName={buttonClasses.deleteButton}
        extraButtonClassName={buttonClasses.cancelButton}
        handleClose={deleteView}
      >
        <ContractDeleteGridViewModalContent />
      </Modal>
    </>
  );
};
