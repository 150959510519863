import styled from "@emotion/styled";
import { theme } from "theme";

export const TagWrapper = styled.span`
  cursor: pointer;
`;

export const SuggestionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
  width: 100%;
`;

export const SuggestionsTitle = styled.div`
  font-size: ${theme.font.size.caption};
  color: ${theme.color.purple[700]};
`;

export const SuggestionOption = styled.div`
  display: flex;
  cursor: pointer;
  padding: ${theme.spacing.sm};
  transition: background-color 0.25s ease-in-out;

  :hover {
    background-color: ${theme.color.purple[100]};
    border-radius: ${theme.borderRadius};
  }
`;
