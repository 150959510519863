import type {} from "@mui/lab/themeAugmentation";
import { createTheme } from "@mui/material";
import shadows, { Shadows } from "@mui/material/styles/shadows";
import themeStyles from "./theme";

export const muiTheme = createTheme({
  typography: {
    fontFamily: "var(--font-family)",
  },
  shadows: shadows.map(() => "none") as Shadows,
  palette: {
    background: {
      default: themeStyles.color.gray[100],
    },
  },
});
