import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Box, DialogActions } from "@mui/material";
import { TeamService, TeamDTO, TeamInfoDto } from "openapi";
import { useSnackbar } from "notistack";
import { setValidationSnackbarErrors } from "shared/service/errorResponseService";
import { useUserInfo } from "hooks";
import { CTAButton, FormTextField } from "components";
import { theme } from "theme";
import AlarmIcon from "assets/svg/alarm-icon.svg?react";
import { AlertIconWrapper, AlertText, AlertWrapper } from "./styles";

export type DeleteModalContentProps = {
  entity?: TeamDTO | TeamInfoDto;
  handleClose: () => void;
  redirectTo?: string;
};

const DeleteModalContent = ({
  entity,
  handleClose,
  redirectTo,
}: DeleteModalContentProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { control, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const { refreshUserInfo } = useUserInfo();
  const navigate = useNavigate();

  const isOrganization = !(entity as TeamDTO)?.parentTeam;

  const [helperText, setHelperText] = useState(
    t("pages.settings.tabs.team.teamNameForm.modal.delete.hint", {
      entityName: `"${entity?.name ?? ""}"`,
    })
  );

  const [nameFieldValue, setNameFieldValue] = useState<string>("");

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNameFieldValue(e.target.value);
  };

  const onDelete = async () => {
    if (loading) return;

    const nameValue = getValues("entityName") as string;

    if (nameValue !== (entity?.name ?? "")) {
      setHelperText(
        t("pages.settings.tabs.team.teamNameForm.messages.errorMismatchingName")
      );
      return;
    }
    setLoading(true);
    try {
      await TeamService.deleteTeam(entity?.id as string);
      handleClose();
      enqueueSnackbar(
        t(
          "pages.settings.tabs.team.teamNameForm.messages.successfulTeamDelete"
        ),
        { variant: "success" }
      );
      await refreshUserInfo();
      if (redirectTo) {
        navigate(redirectTo);
      }
    } catch (err) {
      setValidationSnackbarErrors(
        err,
        "pages.settings.tabs.team.teamNameForm.modal.delete",
        enqueueSnackbar,
        t
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Box
        className="flex-align-center"
        sx={{ marginBottom: theme.spacing.lg }}
      >
        <Box>
          <AlertWrapper>
            <AlertIconWrapper>
              <AlarmIcon />
            </AlertIconWrapper>
            <AlertText>
              <h3>
                <span>
                  {isOrganization
                    ? t(
                        "pages.settings.organization.overview.sections.delete.modal.message.heading",
                        {
                          entityName: `"${entity?.name ?? ""}"`,
                        }
                      )
                    : t(
                        "pages.settings.team.overview.sections.delete.modal.message.heading",
                        {
                          entityName: `"${entity?.name ?? ""}"`,
                        }
                      )}
                </span>
              </h3>
              <span id="deleteTeamDescription">
                {isOrganization
                  ? t(
                      "pages.settings.organization.overview.sections.delete.modal.message.description"
                    )
                  : t(
                      "pages.settings.team.overview.sections.delete.modal.message.description"
                    )}
              </span>
            </AlertText>
          </AlertWrapper>
        </Box>
      </Box>

      <FormTextField
        control={control}
        name="entityName"
        label={t("pages.settings.tabs.team.teamNameForm.name")}
        required
        helperText={helperText}
        InputProps={{
          onInput: onChange,
        }}
      />

      <DialogActions sx={{ marginTop: theme.spacing.xxl }}>
        <CTAButton
          label="cancelBtn"
          type="reset"
          variant="secondary"
          onClick={handleClose}
          name={t(
            "pages.settings.tabs.team.teamNameForm.modal.delete.buttons.cancel"
          )}
        />
        <CTAButton
          label="deleteBtn"
          type="submit"
          variant="primary"
          color="red"
          disabled={loading || nameFieldValue !== entity?.name}
          onClick={() => onDelete()}
          name={t(
            "pages.settings.tabs.team.teamNameForm.modal.delete.buttons.submit"
          )}
        />
      </DialogActions>
    </div>
  );
};

export default DeleteModalContent;
