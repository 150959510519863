import styled from "@emotion/styled";
import contractFile from "./file.svg?react";
import contractFiles from "./files.svg?react";
import { theme } from "theme";

export const Body = styled.div`
  display: flex;
`;

export const BodyColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};

  :last-of-type {
    padding: 0 ${theme.spacing.md};
  }
`;

export const Description = styled.div`
  font-weight: ${theme.font.weight.bold};
`;

export const DocumentType = styled.div``;

export const Title = styled.div`
  color: ${theme.color.gray[600]};
`;

export const AttachmentsCount = styled.div``;
export const AttachmentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
`;
export const AttachmentItem = styled.div`
  color: ${theme.color.gray[600]};
`;

const common = `
  width: 88px;
`;
export const SingleFile = styled(contractFile)`
  ${common}
`;
export const MultipleFiles = styled(contractFiles)`
  ${common}
`;
