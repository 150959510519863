import React, { CSSProperties, FC, PropsWithChildren, ReactNode } from "react";
import { Block, Wrapper, CloseIcon } from "./styles";

export type ChipProps = {
  color?: string;
  label?: ReactNode;
  type?: string;
  id?: string;
  style?: CSSProperties;
  blendMode?: "soft" | "hard";
  onDelete?: () => void;
};

const Chip: FC<PropsWithChildren<ChipProps>> = React.forwardRef<
  HTMLInputElement,
  PropsWithChildren<ChipProps>
>(
  (
    {
      label,
      color,
      children,
      blendMode = "hard",
      type = "default",
      id,
      style,
      onDelete,
      ...props
    },
    ref
  ) => {
    if (!label)
      return (
        <Block
          ref={ref}
          {...props}
          id={id}
          bgColor={color}
          type={type}
          blendMode={blendMode}
          style={style}
        >
          {children}
          {onDelete ? <CloseIcon onClick={onDelete} /> : null}
        </Block>
      );

    return (
      <Wrapper ref={ref} {...props} id={id} style={style}>
        <Block orientation="left">{label}</Block>
        <Block orientation="right" bgColor={color}>
          {children}
        </Block>
      </Wrapper>
    );
  }
);
Chip.displayName = "Chip";
export default Chip;
