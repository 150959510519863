import * as Yup from "yup";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { AccessTokenDataDTO } from "openapi";
import React, { FC, useState } from "react";
import { FieldTitle } from "../../../../../Tasks/Modals/styles";
import {
  CopyToClipboardButton,
  CTAButton,
  FormTextField,
  NewModal,
} from "components";
import {
  AccessToken,
  AccessTokenBox,
  AccessTokenContainer,
  InformationMessage,
} from "../style";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useAccessTokenGenerateMutation } from "shared/api/users";

const TRANSLATION_PREFIX = "pages.settings.tabs.accessToken.generateModal";
const validationSchema = () =>
  Yup.object({
    name: Yup.string().required(),
  });

type FromProps = {
  methods: UseFormReturn<AccessTokenDataDTO>;
  accessToken: string;
};
const GenerateAccessTokenForm: FC<FromProps> = ({ methods, accessToken }) => {
  const { control } = methods;
  const { t } = useTranslation();

  return (
    <div>
      <FormProvider {...methods}>
        <form noValidate>
          <FieldTitle>{t(`${TRANSLATION_PREFIX}.field.title`)}</FieldTitle>
          <FormTextField
            control={control}
            name="name"
            label={t(`${TRANSLATION_PREFIX}.field.label`)}
            disabled={!!accessToken}
            required
          />
        </form>
      </FormProvider>

      {accessToken && (
        <AccessTokenContainer>
          <AccessTokenBox>
            <AccessToken>{accessToken}</AccessToken>
            <CopyToClipboardButton content={accessToken} />
          </AccessTokenBox>
          <InformationMessage>
            {t(`${TRANSLATION_PREFIX}.informationMessage`)}
          </InformationMessage>
        </AccessTokenContainer>
      )}
    </div>
  );
};

type GenerateAccessTokenModalProps = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

export const GenerateAccessTokenModal: FC<GenerateAccessTokenModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm<AccessTokenDataDTO>({
    defaultValues: {
      name: "",
    },
    resolver: yupResolver(validationSchema()),
  });
  const { handleSubmit } = methods;
  const { mutateAsync: generateAccessToken, isLoading } =
    useAccessTokenGenerateMutation();

  const [accessToken, setAccessToken] = useState<string>("");

  const handleGenerate = async (data: AccessTokenDataDTO) => {
    try {
      const accessTokenData = await generateAccessToken(data);
      setAccessToken(accessTokenData.accessToken);
      enqueueSnackbar(t(`${TRANSLATION_PREFIX}.snackbarMessage.success`), {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(t(`${TRANSLATION_PREFIX}.snackbarMessage.error`), {
        variant: "error",
      });
    }
  };

  const handleClose = () => {
    methods.reset();
    setAccessToken("");
    setIsOpen(false);
  };

  return (
    <NewModal
      open={isOpen}
      handleClose={handleClose}
      title={t(`${TRANSLATION_PREFIX}.title`)}
      body={
        <GenerateAccessTokenForm methods={methods} accessToken={accessToken} />
      }
      footer={
        <>
          <CTAButton
            onClick={handleClose}
            name={t("common.buttons.close")}
            variant="secondary"
          />
          {!accessToken && (
            <CTAButton
              type="submit"
              name={t("common.buttons.generate")}
              onClick={handleSubmit(handleGenerate)}
              disabled={isLoading || !!accessToken}
            />
          )}
        </>
      }
      fullWidth
    />
  );
};
