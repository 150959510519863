import React, { useCallback, useEffect, useState } from "react";
import { useTeam } from "contexts/team/hooks";
import { useFieldsQuery } from "shared/api";
import { getAmountColumnSum, getFormulaColumnSum } from "../helpers";
import { useLocale } from "hooks";
import { Label, StatusItem, Wrapper } from "./styles";
import { ContractFieldDTOV1, I18nDto } from "openapi";
import { IRowModel, IRowNode, IStatusPanelParams } from "ag-grid-community";
import { t } from "i18next";
import { Switch } from "new-components";

export type ColumnSum = {
  id: string;
  visibleId: string | null;
  name: I18nDto;
  sum: string;
};

export type RootNode = {
  childrenAfterFilter: IRowNode[];
};

const CustomStatusBar = (params: IStatusPanelParams) => {
  const [displayResult, setDisplayResult] = useState<ColumnSum[]>([]);
  const {
    selectedTeamId,
    parentTeamId: organizationId,
    organizationId: migratedOrganizationId,
  } = useTeam();
  const { locale } = useLocale();
  const { data: fields } = useFieldsQuery(migratedOrganizationId);
  const [isBarShown, setIsBarShown] = useState(false);

  useEffect(() => {
    calculateSum();
    params.api.addEventListener("cellValueChanged", handleCellValueChanged);
    params.api.addEventListener("filterChanged", handleCellValueChanged);

    return () => {
      params.api.removeEventListener(
        "cellValueChanged",
        handleCellValueChanged
      );
      params.api.removeEventListener("filterChanged", handleCellValueChanged);
    };
  }, [params, fields]);

  const calculateSum = useCallback(() => {
    const customFields = fields?.filter(
      (item) =>
        ["AMOUNT", "FORMULA"].includes(item.type) &&
        [organizationId, selectedTeamId].includes(item.teamId)
    );

    const rows: IRowNode[] =
      (params?.api.getModel() as IRowModel & { rootNode: RootNode })?.rootNode
        ?.childrenAfterFilter || [];

    if (!customFields) return;

    const sum = customFields?.map((item) => {
      const sumFunction =
        item.type === "AMOUNT" ? getAmountColumnSum : getFormulaColumnSum;
      return {
        id: item.id,
        visibleId: item.visibleId,
        name: item.name,
        sum: sumFunction(rows, fields as ContractFieldDTOV1[], item, locale),
      };
    });
    setDisplayResult(sum);
  }, []);

  const handleCellValueChanged = () => {
    calculateSum();
  };

  const toggleView = () => {
    setIsBarShown(!isBarShown);
  };

  return (
    <Wrapper>
      {isBarShown
        ? displayResult.map((item: ColumnSum) => {
            if (!item.visibleId) return null;
            return (
              <StatusItem key={item.id}>
                {item.name[locale]}: <span>{item.sum}</span>
              </StatusItem>
            );
          })
        : null}

      <Switch
        checked={isBarShown}
        onChange={toggleView}
        label={<Label>{t("common.amounts")}</Label>}
      />
    </Wrapper>
  );
};

export default CustomStatusBar;
