import React from "react";
import { useTranslation } from "react-i18next";
import { Wrapper, Title } from "./styles";

import {
  PermissionChangeLogDTO,
  MemberPermissionChangeDTO,
  MemberDeletedDTO,
} from "openapi";

import StatusLabel from "../StatusLabel/StatusLabel";

export type MemberDeleteType = {
  data: MemberPermissionChangeDTO | MemberDeletedDTO;
  event: PermissionChangeLogDTO.event;
};

const MemberDelete = ({ data, event }: MemberDeleteType) => {
  const { t } = useTranslation();

  const memberName = `${(data as MemberDeletedDTO).firstname} ${
    (data as MemberDeletedDTO).lastname
  }`;

  return (
    <Wrapper>
      <Title>
        <StatusLabel
          title={t(
            `pages.settings.organization.permissionChangeLog.status.${event}`
          )}
          variant={event}
        />
        {t(
          "pages.settings.organization.permissionChangeLog.messages.removeMember",
          { memberName }
        )}
      </Title>
    </Wrapper>
  );
};

export default MemberDelete;
