import styled from "@emotion/styled";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box } from "@mui/material";

export const RoundedBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: #e2e8f0;
  color: #4a5568;
  transition: scale 0.2s ease-in-out;

  :hover {
    cursor: pointer;
  }

  :active {
    transform: scale(0.95);
  }
`;

export const Arrow = styled(ArrowBackIosIcon)`
  ${({ orientation }: { orientation?: string }) =>
    orientation === "right"
      ? `
      margin-right: .5rem;
      transform: rotate(180deg);
    `
      : `
      margin-left: .5rem;
    `}
`;
