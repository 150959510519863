import { ContractDTOV1, ContractFieldDTOV1 } from "openapi";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  parseAmount,
  parseCurrency,
  parseDate,
  parseInterval,
} from "@contracthero/common";
import { dateFormatter } from "constants/utils";
import { Language } from "shared/i18n/i18n";
import { FieldKey } from "./types";

dayjs.extend(utc);

export const getFieldKeys = <T extends ContractDTOV1["fields"][string]>(
  data: T | undefined
): FieldKey<T>[] => {
  return Object.keys(data ?? {}).filter(
    (key) => !["analysisData", "annotationData", "metadata"].includes(key)
  ) as FieldKey<T>[];
};

export const getHumanReadableAnalysisParser = <
  T extends ContractDTOV1["fields"][string]
>(
  type: ContractFieldDTOV1.type,
  slug: keyof Omit<T, "analysisData" | "annotationData" | "metadata">,
  locale: Language,
  userDateFormat: string | undefined
): ((value: string) => string | number | undefined) => {
  const parseDateHumanReadable = (value: string) => {
    try {
      const date = parseDate(value);
      return dateFormatter(locale, date, userDateFormat);
    } catch (e) {
      return value;
    }
  };

  switch (type) {
    case ContractFieldDTOV1.type.NUMBER:
      return (value: string) => Number(value);
    case ContractFieldDTOV1.type.DATE:
      return (value) => parseDate(value);
    case ContractFieldDTOV1.type.DURATION:
      switch (slug) {
        case "startDate":
        case "endDate":
        case "terminationDate":
          return (value) => parseDateHumanReadable(value);
        case "interval":
        case "noticePeriod":
        case "automaticRenewal":
          return (value) => parseInterval(value);
      }
      break;
    case ContractFieldDTOV1.type.AMOUNT: {
      switch (slug) {
        case "currency":
          return (value) => parseCurrency(value);
        case "value":
          return (value) => parseAmount(value);
        default:
          break;
      }
      break;
    }
    default:
      break;
  }
  return (value) => value;
};

export const getDefaultAnalysisParser = <
  T extends ContractDTOV1["fields"][string]
>(
  type: ContractFieldDTOV1.type,
  slug: keyof Omit<T, "analysisData" | "annotationData" | "metadata">
): ((value: string) => string | number | undefined) => {
  switch (type) {
    case ContractFieldDTOV1.type.NUMBER:
      return (value: string) => Number(value);
    case ContractFieldDTOV1.type.DATE:
      return (value) => parseDate(value);
    case ContractFieldDTOV1.type.DURATION:
      switch (slug) {
        case "startDate":
        case "endDate":
        case "terminationDate":
          return (value) => parseDate(value);
        case "interval":
        case "noticePeriod":
        case "automaticRenewal":
          return (value) => parseInterval(value);
      }
      break;
    case ContractFieldDTOV1.type.AMOUNT: {
      switch (slug) {
        case "currency":
          return (value) => parseCurrency(value);
        case "value":
          return (value) => parseAmount(value);
        default:
          break;
      }
      break;
    }
    default:
      break;
  }
  return (value) => value;
};
