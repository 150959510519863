import { NotificationService } from "openapi";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export const useNotificationsQuery = () => {
  return useQuery({
    queryKey: ["notifications"],
    queryFn: () => NotificationService.getNotifications(),
    refetchInterval: 30000,
  });
};

export const useMarkNotificationsAsReadMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => NotificationService.markAllAsRead(),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["notifications"] });
    },
  });
};
