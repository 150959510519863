import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div<{
  variant?: string;
}>`
  display: flex;
  align-items: flex-start;
  flex-direction: ${(props) => (props.variant === "row" ? "row" : "column")};
  gap: ${(props) =>
    props.variant === "row" ? theme.spacing.lg : theme.spacing.xxs};
  justify-content: ${(props) =>
    props.variant === "row" ? "flex-start" : "initial"};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: ${theme.spacing.xxs};
  font-weight: ${theme.font.weight.normal};
  font-size: ${theme.font.size.caption};
  color: ${theme.color.gray[700]};
`;

export const TitleIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  margin-right: ${theme.spacing.sm};
  flex: 0 0 auto;
  svg {
    fill: ${theme.color.gray[700]};
    width: 14px;
    height: 14px;
  }
`;

export const Values = styled.div<{ variant?: string }>`
  margin-left: ${(props) => (props.variant === "row" ? "auto" : "none")};
`;

export const ValueWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${theme.spacing.xs};
`;

export const PreviousValue = styled.div`
  flex: 0 1 auto;
  display: flex;
  gap: ${theme.spacing.xs};
  max-width: 45%;
  flex-wrap: wrap;
`;

export const CurrentValue = styled.div<{
  variant?: string;
}>`
  flex: 0 1 auto;
  display: flex;
  gap: ${theme.spacing.xs};
  max-width: ${(props) => (props.variant === "row" ? "100%" : "45%")};
  flex-wrap: wrap;
`;

export const ValueIconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  svg {
    transform: rotate(90deg);
    width: 20px;
    height: 20px;
  }
`;
