import React, { useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Popover from "@mui/material/Popover";
import { useAuthentication, useUserInfo } from "hooks/GlobalStateHooks";
import { UserInfoDto } from "openapi";
import { theme } from "../../../theme";
import { CustomNavLink as LinkButton } from "..";
import { Button as CustomButton } from "../NavLink/styles";
import { routePaths } from "../../../constants";
import { List, Divider, Label } from "./styles";
import { getUserName } from "./helper";

const ProfileDropdown = () => {
  const { userInfo } = useUserInfo();
  const { t } = useTranslation();
  const { logout } = useAuthentication();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: unknown) => {
    setAnchorEl((event as MouseEvent<HTMLButtonElement>).currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  const userFullName = getUserName(userInfo as UserInfoDto);

  return (
    <div>
      <CustomButton
        id="user-profile-settings-button"
        aria-describedby="profile-settings-popover-button"
        onClick={handleClick}
        sx={{ opacity: open ? 0.75 : 1 }}
      >
        <PersonIcon />
        <Label className="user-name">{userFullName}</Label>
        <ArrowDropDownIcon fontSize="small" />
      </CustomButton>

      <Popover
        id="profile-settings-popover-container"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ sx: { boxShadow: theme.shadow.alternative } }}
      >
        <List>
          <LinkButton
            id={"profile-link-button"}
            icon={<PersonOutlineIcon fontSize="small" />}
            to={routePaths.SETTINGS_USER_PROFILE}
            label={t("navbar.profile")}
            onClick={() => handleClose()}
          />
          <LinkButton
            id={"notifications-link-button"}
            icon={<NotificationsNoneIcon fontSize="small" />}
            to={routePaths.SETTINGS_USER_NOTIFICATIONS}
            label={t("navbar.notifications")}
            onClick={() => handleClose()}
          />

          <Divider />

          <CustomButton onClick={() => logout()}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("navbar.logout")} />
          </CustomButton>
        </List>
      </Popover>
    </div>
  );
};

export default ProfileDropdown;
