import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const EmptyTag = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: #6f7680;
  margin: 0 0.5rem;
`;

export const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    box-shadow: none;
    border: none;
  }
`;
