import styled from "@emotion/styled";
import { Drawer } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { theme, breakpoints } from "theme";
import { appBarHeight, mainDrawerWidth, teamDrawerWidth } from "./constants";

const transitionStyles = "width .3s ease-in-out";

type Props = {
  open?: boolean;
  width?: number;
};

type StyledDrawerProps = {
  open?: boolean;
  mainDrawerOpen?: boolean;
  teamDrawerOpenState?: boolean;
};

type StyledContainerProps = {
  mainDrawerOpen?: boolean;
  teamDrawerOpenState?: boolean;
  maxWidth?: boolean | string;
};

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
`;

export const AppBar = styled(MuiAppBar)`
  &.app-bar {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    background-color: #f7fafc;
    width: 100%;
    transition: margin 0.3 ease, ${transitionStyles};
    duration: 0.3s ease-in;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;

    ${({ open }: Props) =>
      open &&
      `
      transition:
        margin .3s ease-out,
        ${transitionStyles};
      duration: .3s ease-out;
    `}
  }
`;

const preventDrawerPropForwarding = (propName: string) => {
  return propName !== "mainDrawerOpen" && propName !== "teamDrawerOpenState";
};

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: preventDrawerPropForwarding,
})<StyledDrawerProps>`
  flex-shrink: 0;
  border: none;
  transition: ${transitionStyles};
  z-index: 2;

  & .MuiDrawer-paper {
    background-color: white;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
  }

  @media (min-width:${breakpoints.sm}) {
    & .MuiDrawer-paper {
      border: none;
      transition: opacity 0.15s ease-out, transform 0.15s ease-out;

      ${({ open }) =>
        open
          ? `
          opacity: 1;
          transform: translateX(0);
          width: ${mainDrawerWidth}px;
        `
          : `
          opacity: 0;
          transform: translateX(-100%);
      `}

      ${({ open, teamDrawerOpenState }) =>
        open &&
        teamDrawerOpenState &&
        `
      width: ${mainDrawerWidth + teamDrawerWidth}px;
      `}
  }

  @media (min-height: 700px) {
    & .MuiDrawer-paper {
      overflow-y: hidden;
    }
  }

  @media (min-width:${breakpoints.sm}) {
    & .MuiDrawer-paper {
      margin-top: 60px;
      height: calc(100vh - 60px);
      z-index: 1;
    }
  }
`;

export const DrawerContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: auto;

  @media (min-width: ${breakpoints.sm}) {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

export const StyledMuiContainer = styled.div<StyledContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: ${appBarHeight}px;
  padding: ${theme.spacing.xl};
  transition: ${transitionStyles};
  width: 100%;

  @media (min-width: ${breakpoints.sm}) {
    margin-top: ${appBarHeight * 1.5}px;

    ${({ mainDrawerOpen }) =>
      mainDrawerOpen &&
      `
      margin-left: ${mainDrawerWidth}px;
    `}

    ${({ mainDrawerOpen, teamDrawerOpenState }) =>
      mainDrawerOpen &&
      teamDrawerOpenState &&
      `
    margin-left: ${mainDrawerWidth + teamDrawerWidth}px;
  `}
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${({ maxWidth }: { maxWidth: boolean | string }) =>
    !maxWidth &&
    `
    @media (min-width: ${breakpoints.sm}) {
      max-width: ${breakpoints.sm};
    }

    @media (min-width: ${breakpoints.md}) {
      max-width: ${breakpoints.md};
    }

    @media (min-width: ${breakpoints.lg}) {
      max-width: ${breakpoints.lg};
    }
  `}
`;

export const ButtonWrapper = styled.div`
  display: none;
  min-width: 120px;
  height: fit-content;
  margin: ${theme.spacing.lg} ${theme.spacing.xxl} 0 0;

  @media (min-width: ${breakpoints.sm}) {
    display: block;
  }
`;
