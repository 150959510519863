/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React from "react";
import { useTranslation } from "react-i18next";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { Features } from "constants/features";
import { useTeam } from "contexts/team/hooks";
import { ContractFieldDTOV1 } from "../../../../openapi";
import { Loader } from "components";
import { useFieldsQuery, useTeamMembersQuery } from "shared/api";
import { queryClient } from "shared/api/client";
import {
  CATEGORY_KEY,
  useOrganizationCategoriesQuery,
} from "shared/api/organization/categories";
import { useLocale } from "hooks";

export type ContractFieldDefinition = ContractFieldDTOV1 & {
  isUsed: boolean;
};

function withCustomFieldsData<P>(WrappedComponent: React.ComponentType<P>) {
  return function WithData(props: Partial<P>) {
    const { locale } = useLocale();
    const { t } = useTranslation();
    const { selectedTeamId, hasFeature, organizationId } = useTeam();

    const { data: memberData } = useTeamMembersQuery(selectedTeamId);
    const member = memberData?.members.find(
      (member) => member.id === memberData.memberId
    );

    const { data: categories } = useOrganizationCategoriesQuery(organizationId);

    const { data: fields, refetch: refetchFields } =
      useFieldsQuery(organizationId);

    const fetchAllData = () => {
      void refetchFields();
      queryClient
        .invalidateQueries({ queryKey: ["categories", selectedTeamId, false] })
        .catch((e) => console.error(e));
      queryClient
        .invalidateQueries({ queryKey: [CATEGORY_KEY, organizationId, locale] })
        .catch((e) => console.error(e));
    };

    const fieldsData = fields?.map((field) => {
      return { ...field, isUsed: false };
    });

    if (!categories || !fieldsData) return <Loader />;

    if (!hasFeature(Features.CONTRACT_CUSTOMIZATION)) {
      return (
        <CardWrapper>
          <p>
            {t("pages.settings.organization.administrator.unavailable.plan")}
          </p>
        </CardWrapper>
      );
    }

    if (!member?.categoryOwner && !member?.owner) {
      return (
        <CardWrapper>
          <div>
            {t(
              "pages.settings.organization.administrator.unavailable.noCategories"
            )}
          </div>
        </CardWrapper>
      );
    }

    if (categories.length < 1) {
      return (
        <CardWrapper>
          <div>
            {t(
              "pages.settings.organization.administrator.unavailable.noCategories"
            )}
          </div>
        </CardWrapper>
      );
    }

    return hasFeature(Features.CONTRACT_CUSTOMIZATION) ? (
      <WrappedComponent
        categories={categories}
        fields={fieldsData}
        refetchData={fetchAllData}
        {...(props as P)}
      />
    ) : (
      <CardWrapper>
        <p>{t("pages.settings.organization.administrator.unavailable.plan")}</p>
      </CardWrapper>
    );
  };
}

export default withCustomFieldsData;
