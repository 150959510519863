import React from "react";
import { useTranslation } from "react-i18next";
import { DropzoneState } from "react-dropzone";
import LockIcon from "@mui/icons-material/Lock";
import OpenFolder from "../../../assets/svg/folder-open-logo.svg?react";
import { Wrapper, Container, Block, Info } from "./styles";

type DropZoneProps = {
  isExistingContract: boolean;
  hasContracts: boolean;
  dropzone: DropzoneState;
};

const DropZone = ({
  isExistingContract,
  hasContracts,
  dropzone,
}: DropZoneProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper data-testid="dropzone" isDragging={dropzone.isDragActive}>
      {isExistingContract ? (
        <Container {...dropzone.getRootProps()}>
          <input {...dropzone.getInputProps()} data-cy="dropzone" />
          <Info>
            <LockIcon fontSize="small" sx={{ marginRight: ".5rem" }} />
            {t("contractUpload.uploadInfo")}
          </Info>
          <Block>
            <div>
              <OpenFolder />
            </div>
            <div>{t("contractUpload.dropZoneText")}</div>
          </Block>
        </Container>
      ) : (
        <Container {...dropzone.getRootProps()}>
          <input {...dropzone.getInputProps()} data-cy="dropzone" />
          <Info>
            <LockIcon fontSize="small" sx={{ marginRight: ".5rem" }} />
            {t("contractUpload.uploadInfo")}
          </Info>
          <Block>
            <OpenFolder />
            <div>
              {hasContracts
                ? t("contractUpload.dropZoneTextWithFiles")
                : t("contractUpload.dropZoneText")}
            </div>
          </Block>
        </Container>
      )}
    </Wrapper>
  );
};

export default DropZone;
