import React, { useEffect, useMemo, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { ContractNameDto } from "../../openapi";
import { FormSelect, FormSelectItem } from "../FormItems/FormSelect/FormSelect";
import { useForm, useWatch } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  compareCaseInsensitive,
  getNavigationToContractViewById,
} from "constants/utils";
import { theme } from "theme";

const getInitialValues = (id: string | undefined) => {
  return {
    contractId: id,
  };
};

type Props = {
  contractGroup: ContractNameDto[];
  setLoadSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
};

const HorizontalStepper = ({ contractGroup, setLoadSuccess }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [prevButtonWidth, setPrevButtonWidth] = useState<number>(0);
  const [nextButtonWidth, setNextButtonWidth] = useState<number>(0);
  const [options, setOptions] = useState<FormSelectItem[]>([]);
  const [contractFileList, setContractFileList] = useState<ContractNameDto[]>(
    []
  );

  const buttonPrevRef = useRef<HTMLButtonElement>(null);
  const buttonNextRef = useRef<HTMLButtonElement>(null);

  const { control, setValue } = useForm({
    defaultValues: getInitialValues(id),
  });

  const selectedContractId = useWatch({
    name: "contractId",
    control: control,
  });

  useEffect(() => {
    setValue("contractId", id);
  }, [id]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setValue("contractId", contractFileList[activeStep + 1].id);
    setLoadSuccess?.(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setValue("contractId", contractFileList[activeStep - 1].id);
    setLoadSuccess?.(false);
  };

  useEffect(() => {
    if (buttonPrevRef && buttonNextRef) {
      setPrevButtonWidth(
        buttonPrevRef.current?.getBoundingClientRect().width ?? 0
      );
      setNextButtonWidth(
        buttonNextRef.current?.getBoundingClientRect().width ?? 0
      );
    }
  }, []);

  useEffect(() => {
    if (id !== selectedContractId) {
      setLoadSuccess?.(false);
      navigate(getNavigationToContractViewById(selectedContractId));
    }
  }, [selectedContractId]);

  useMemo(() => {
    const sortedAttachments = [...contractGroup]
      .slice(1)
      .sort(({ name: a }, { name: b }) => compareCaseInsensitive(a, b));
    const contractList = [contractGroup[0], ...sortedAttachments];
    const selectedIndex = contractList.findIndex(
      (contract) => contract.id === id
    );
    setActiveStep(selectedIndex);
    setContractFileList(contractList);
    setOptions(
      contractList.map((contract) => {
        return {
          key: contract.id,
          value: contract.name,
        };
      })
    );
  }, [contractGroup]);

  return (
    <Box sx={{ width: "100%", mb: theme.spacing.lg }}>
      <Stepper nonLinear activeStep={activeStep}>
        {contractFileList.map((_contract, index) => {
          return (
            <Step key={index}>
              <StepLabel />
            </Step>
          );
        })}
      </Stepper>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          ref={buttonPrevRef}
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1, minWidth: "fit-content" }}
        >
          <KeyboardArrowLeft />
          {t("common.buttons.back")}
        </Button>

        <form
          name="contract-selector"
          noValidate
          style={{
            width: "100%",
            maxWidth: `calc(100% - (${
              prevButtonWidth + nextButtonWidth + 16
            }px))`,
          }}
        >
          <FormSelect
            control={control}
            margin="none"
            options={options}
            name="contractId"
          />
        </form>
        <Button
          ref={buttonNextRef}
          sx={{ ml: 1, minWidth: "fit-content" }}
          disabled={activeStep === contractFileList.length - 1}
          onClick={handleNext}
        >
          {t("common.buttons.next")}
          <KeyboardArrowRight />
        </Button>
      </Box>
    </Box>
  );
};

export default HorizontalStepper;
