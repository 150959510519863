/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { WebhookLogDTO, WebhookLogResponseDTO } from "openapi";
import _ from "lodash";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const safelyParseJSON = (data: string): any => {
  try {
    return JSON.parse(data);
  } catch {
    return data;
  }
};

const customParse = (value: unknown): unknown => {
  if (typeof value === "string") return safelyParseJSON(value);
  return undefined;
};

export const getRequestData = (data?: WebhookLogDTO) => {
  const headers = data?.requestData?.headers || {};
  const body = data?.requestData?.body || {};

  let headerStr = "// HTTP Headers \n\n";
  let requestBody = "// Request body \n\n";

  for (const [key, value] of Object.entries(headers)) {
    const pair = `${key}: ${value as string} \n`;
    headerStr += pair;
  }

  requestBody += JSON.stringify(body, null, 2);

  return headerStr + "\n" + requestBody;
};

export const getResponseData = (data?: WebhookLogResponseDTO): string => {
  const headers = data?.responseData?.headers || {};

  let headerStr = "// HTTP Headers \n\n";
  let requestBody = "// Request body \n\n";

  for (const [key, value] of Object.entries(headers)) {
    const pair = `${key}: ${value as string} \n`;
    headerStr += pair;
  }

  let body = data?.responseData?.body;

  if (typeof body === "string") {
    body = safelyParseJSON(body);
  } else if (typeof body === "object") {
    body = _.cloneDeepWith(body, customParse);
  }

  requestBody += JSON.stringify(body, null, 2);

  return headerStr + "\n" + requestBody;
};
