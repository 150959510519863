import * as React from "react";
import Container from "@mui/material/Container";
import ResetPasswordForm from "./resetPasswordForm";
import AuthCardWrapper from "../../components/AuthCardWrapper/AuthCardWrapper";
import { useTranslation } from "react-i18next";
import { ReactElement, useState } from "react";
import { AlertTypeEnum } from "../../shared/enums/alert.enum";

const ResetPassword = () => {
  const { t } = useTranslation();
  const [element, setElement] = useState<ReactElement | undefined>(undefined);
  const [alertKey, setAlertKey] = useState<string | undefined>(undefined);

  return (
    <>
      <Container component="main" sx={{ marginTop: "2.5rem" }}>
        <AuthCardWrapper
          title={t("pages.resetPassword.title")}
          subTitle={t("pages.resetPassword.subtitle")}
          alertElement={element}
          alertKey={alertKey}
          alertType={AlertTypeEnum.error}
        >
          <ResetPasswordForm
            alertElementSetter={setElement}
            alertKeySetter={setAlertKey}
          />
        </AuthCardWrapper>
      </Container>
    </>
  );
};

export default ResetPassword;
