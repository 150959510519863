import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { Popover } from "@mui/material";
import { CategoryDTO } from "openapi";
import { useTeam } from "contexts/team/hooks";
import {
  getDefaultCategory,
  categoriesTeamSelector,
} from "shared/api/organization/categories.helpers";
import { useLocale } from "hooks";
import { Tag } from "new-components";
import { TextField as SearchInput } from "new-components";
import { getContrastColor } from "utils/color";
import {
  Wrapper,
  PopoverContainer,
  List,
  ListItem,
  ColorPoint,
  paperStyles,
} from "./styles";

type CategorySelectorProps = {
  handleCategoryChange: (value: string) => void;
  categories?: CategoryDTO[];
  currentCategory?: CategoryDTO;
};

const Category = ({
  handleCategoryChange,
  categories,
  currentCategory,
}: CategorySelectorProps) => {
  const { t } = useTranslation();
  const { selectedTeamId } = useTeam();
  const { locale } = useLocale();
  const { id: modeId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<CategoryDTO>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "template-category-selector-popover" : undefined;

  const defaultCategory = getDefaultCategory(categories, selectedTeamId);

  const handleOnClose = () => {
    setAnchorEl(null);
    setSearchTerm("");
  };

  const handleOnSelect = (option: CategoryDTO) => {
    handleCategoryChange(option.id);
    setSelectedCategory(option);
    handleOnClose();
  };

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const filteredCategories = useMemo(() => {
    if (!categories) return [];
    const normalizedSearchTerm = searchTerm.toLowerCase();
    return categories
      .filter((category) => categoriesTeamSelector(category, selectedTeamId))
      .filter((category) =>
        category.name[locale].toLowerCase().includes(normalizedSearchTerm)
      );
  }, [categories, selectedTeamId, searchTerm, locale]);

  useEffect(() => {
    if (modeId === "new" && defaultCategory) {
      setSelectedCategory(defaultCategory);
    } else {
      setSelectedCategory(currentCategory);
    }
  }, [defaultCategory, selectedTeamId, categories]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // @Note: After migration we should always have a default category selected.
  if (!selectedCategory) return <></>;

  return (
    <>
      <Wrapper type="button" onClick={handleOnClick}>
        <Tag
          className="template-category"
          variant="custom"
          backgroundColor={selectedCategory?.color ?? ""}
          color={getContrastColor(selectedCategory?.color ?? "")}
        >
          {selectedCategory?.name[locale]}
        </Tag>
      </Wrapper>

      <Popover
        id={id}
        open={open}
        onClose={handleOnClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{ style: paperStyles }}
      >
        <PopoverContainer>
          <SearchInput
            name="search"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder={t("common.components.search.placeholder")}
            fullWidth
          />
          <List>
            {filteredCategories?.map((category) => (
              <ListItem
                key={category.id}
                onClick={() => handleOnSelect(category)}
              >
                <ColorPoint color={category.color} />
                {category.name[locale]}
              </ListItem>
            ))}
          </List>
        </PopoverContainer>
      </Popover>
    </>
  );
};

export default Category;
