/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useMemo } from "react";
import { Plugin, createStore } from "@react-pdf-viewer/core";
import { OCRSearchStoreProps, Search } from "./types";
import { OCRSearchPopover, OCRSearchPopoverProps } from "./OCRSearchPopover";
import { Block } from "@contracthero/common/dist/src/ocr/types";
import { partitionBlocks } from "@contracthero/common";
import { OCRLayer, OCRLayerProps } from "components/PDFViewer/OCRLayer";
import { JumpToOffsetPayload } from "components/PDFViewer/PDFViewerActionContext";

export interface OCRSearchPlugin extends Plugin {
  OCRSearchPopover(
    props: Omit<OCRSearchPopoverProps, "store">
  ): React.ReactElement;
  OCRLayer(props: Omit<OCRLayerProps, "store">): React.ReactElement;
  jumpTo: (payload: JumpToOffsetPayload) => void;
}

export interface OCRSearchPluginProps {
  onSearchChanged?(search: Search): void;
  blocks: Block[];
}

export const ocrSearchPlugin = (
  props?: OCRSearchPluginProps
): OCRSearchPlugin => {
  const store = useMemo(
    () =>
      createStore<OCRSearchStoreProps>({
        blocks: props?.blocks ?? [],
      }),
    []
  );

  useEffect(() => {
    store.update("blocks", props?.blocks ?? []);
  }, [props?.blocks]);

  useEffect(() => {
    const searchChanged = (search: Search | undefined) => {
      if (search && props?.onSearchChanged) {
        props?.onSearchChanged(search);
      }
    };

    store.subscribe("search", searchChanged);
    return () => {
      store.unsubscribe("search", searchChanged);
    };
  }, []);

  const jumpToBlock = (blockId: string) => {
    const block = document.querySelector(`mark[data-block-id='${blockId}']`);
    block?.scrollIntoView({ block: "center" });
  };

  const jumpTo = (payload: JumpToOffsetPayload) => {
    const lineBlocks = props?.blocks.filter(
      (block) => block.BlockType === "LINE"
    );
    if (!lineBlocks) {
      return;
    }

    const jumpToPage = store.get("jumpToPage");
    if (!jumpToPage) {
      return;
    }

    let cursor = 0;

    const partitionedBlocks = partitionBlocks(lineBlocks);

    for (const block of lineBlocks) {
      if (!block.Text) {
        continue;
      }
      // +1 for new line symbol `\n`
      cursor += block.Text.length + 1;
      if (cursor > payload.beginOffset) {
        jumpToPage((block.Page ?? 0) - 1)
          .then(() => {
            if (block.Id && block.Page) {
              void jumpToBlock(block.Id);
              store.update("activeAnalysisItem", {
                blockId: block.Id,
                groupIdx: partitionedBlocks.findIndex((group) =>
                  group.lines.find((l) => l.id === block.Id)
                ),
                page: block.Page,
                fieldKey: payload.fieldKey,
                visibleId: payload.visibleId,
              });
            }
          })
          .catch((e) => console.error(e));
        break;
      }
    }
  };

  const MemoizedOCRLayer = useCallback(
    (props: OCRLayerProps) => <OCRLayer {...props} store={store} />,
    [store]
  );

  return {
    install: (pluginFunctions) => {
      store.update("jumpToPage", pluginFunctions.jumpToPage);
      store.update("jumpToDestination", pluginFunctions.jumpToDestination);
    },
    onViewerStateChange: (viewerState) => {
      store.update("currentPage", viewerState.pageIndex);

      return viewerState;
    },
    onDocumentLoad: (props) => {
      store.update("doc", props.doc);
    },
    OCRSearchPopover: (props) => <OCRSearchPopover {...props} store={store} />,
    OCRLayer: MemoizedOCRLayer,
    jumpTo: jumpTo,
  };
};
