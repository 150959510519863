import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Popover from "@mui/material/Popover";
import ImportIcon from "assets/svg/import-file.svg?react";
import MoreHorizontalIcon from "assets/svg/more-horizontal.svg?react";
import BinIcon from "assets/svg/bin-icon.svg?react";
import ContractTemplateIcon from "assets/svg/contract-template-icon.svg?react";
import { theme } from "theme";
import { Wrapper, ImportButton, MoreButton, Menu, MenuItem } from "./styles";

type ImportProps = {
  handleOnImport: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteContractTemplate?: () => void;
  handleTemplateDuplication?: () => void;
};

const Import = ({
  handleOnImport,
  handleDeleteContractTemplate,
  handleTemplateDuplication,
}: ImportProps) => {
  const { t } = useTranslation();
  const { id: templateId } = useParams();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "template-import-menu-popover" : undefined;
  const isNewTemplate = templateId === "new" || !templateId;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click?.();
    }
  };

  return (
    <Wrapper>
      <ImportButton
        name={t("pages.settings.tabs.contractTemplates.import")}
        icon={<ImportIcon />}
        onClick={triggerFileInput}
        hasRoundedBorder={!isNewTemplate}
      />

      <input
        ref={fileInputRef}
        hidden
        accept=".docx,.html"
        type="file"
        onChange={handleOnImport}
      />

      {!isNewTemplate && (
        <MoreButton
          name=""
          icon={<MoreHorizontalIcon />}
          onClick={handleClick}
        />
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            backgroundColor: theme.color.white,
            padding: theme.spacing.md,
          },
        }}
      >
        <Menu>
          <MenuItem onClick={handleTemplateDuplication}>
            <ContractTemplateIcon />
            <span>
              {t(
                "pages.settings.organization.contractTemplates.buttons.duplicate"
              )}
            </span>
          </MenuItem>
          <MenuItem
            remove
            onClick={() => {
              handleDeleteContractTemplate?.();
              handleClose();
            }}
          >
            <BinIcon />
            <span>{t("common.buttons.delete")}</span>
          </MenuItem>
        </Menu>
      </Popover>
    </Wrapper>
  );
};

export default Import;
