import React, { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import {
  CategoryListContainer,
  FormButtons,
  TeamsSettingContainer,
  Wrapper,
} from "../styles";
import { FormCheckbox } from "components/FormItems";
import { CTAButton, SectionHeader, UnsavedChangesModal } from "components";
import { t } from "i18next";
import {
  ApiError,
  CategoryDTO,
  CategoryUpdateDTO,
  OrganizationNewService,
} from "openapi";
import { useTeam } from "contexts/team/hooks";
import { useOrganizationAllTeamsQuery } from "shared/api/organization";
import { enqueueSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import CategoryNameSection from "./CategoryNameSection";
import { Checkbox, FormControlLabel } from "@mui/material";
import TeamIcon from "assets/svg/team-icon.svg?react";
import { SettingContainer } from "../../../../../components/SettingContainer/SettingContainer";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { unstable_useBlocker } from "react-router-dom";
import { CategoryPermissionsHeader } from "./styles";
import { BadRequestResponse } from "../../../../../shared/types/ErrorResponse.type";

export type CheckBoxesProps = Record<string, boolean>;

const CategorySetting = ({ category }: { category: CategoryDTO }) => {
  const { organizationId, refetch } = useTeam();
  const getDefaultValues = (category: CategoryDTO) => {
    return {
      name: category.name,
      color: category.color,
      teams: Object.assign(
        {},
        ...category.teams.map((item) => ({ [item]: true }))
      ) as CheckBoxesProps,
    };
  };

  const validationSchema = Yup.object().shape({
    name: Yup.object().shape({
      en: Yup.string().required(
        t("pages.categoryDetails.errors.nameEnRequired")
      ),
      de: Yup.string().required(
        t("pages.categoryDetails.errors.nameDeRequired")
      ),
    }),
    teams: Yup.object(),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: getDefaultValues(category),
  });
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty },
    setValue,
    setError,
  } = methods;
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const blocker = unstable_useBlocker(isDirty);
  const [showModal, setShowModal] = useState(false);

  const queryClient = useQueryClient();
  const { data: subTeams } = useOrganizationAllTeamsQuery(organizationId);
  const teams = useWatch({ name: "teams", control: control });

  useEffect(() => {
    setAllChecked(Object.values(teams).every((item) => item));
  }, [teams]);

  useEffect(() => {
    const isChecked = subTeams?.every((item) => teams[item.id] === true);
    setAllChecked(!!isChecked);
  }, [category, subTeams]);

  const onAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllChecked(event.target.checked);
    const { teams } = getValues();

    for (const team in teams) {
      setValue(`teams.${team}`, event.target.checked, { shouldDirty: true });
    }
  };

  const getSelectedTeams = () => {
    const data: CheckBoxesProps = getValues().teams;
    if (!data) return;
    const selectedTeams: string[] = Object.keys(data).filter(
      (item) => data[item]
    );
    return selectedTeams;
  };

  const onSubmit = async () => {
    const { name, color } = getValues();
    const requestBody = {
      name,
      color,
      teams: getSelectedTeams(),
    } as CategoryUpdateDTO;

    try {
      await OrganizationNewService.updateCategory(
        organizationId,
        category.id,
        requestBody
      );
      enqueueSnackbar(
        t(
          "pages.settings.organization.administrator.categories.modal.edit.success"
        ),
        {
          variant: "success",
        }
      );
      await queryClient.invalidateQueries({
        queryKey: ["organizationAllTeams", organizationId],
      });
      refetch().catch((e) => console.error(e));
      reset({}, { keepValues: true });
    } catch (error) {
      if (
        error instanceof ApiError &&
        (error.body as BadRequestResponse).error === "duplicated_category_name"
      ) {
        setError("name", {
          type: "custom",
          message:
            "pages.settings.organization.administrator.errors.duplicatedCategoryName",
        });
        return;
      }
      enqueueSnackbar(
        t(
          "pages.settings.organization.administrator.categories.modal.edit.failure"
        ),
        {
          variant: "error",
        }
      );
    }
  };

  const handleDiscardChanges = () => {
    blocker.proceed?.();
  };

  const handleSaveChanges = async () => {
    blocker.proceed?.();
    await onSubmit();
    blocker.reset?.();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    blocker.reset?.();
  };

  return (
    <>
      <SettingContainer
        title={t("pages.categoryDetails.sections.overview.title")}
        icon={<TeamIcon />}
      >
        <Wrapper>
          <FormProvider {...methods}>
            <form
              id="categoryPermissionsForm"
              name="categoryPermissionsForm"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              data-testid="add-new-category-modal"
            >
              <CategoryNameSection category={category} />
              {subTeams && subTeams?.length > 1 && (
                <div>
                  <SectionHeader
                    title={t(
                      "pages.categoryDetails.sections.overview.permissions.title"
                    )}
                    subTitle={t(
                      "pages.categoryDetails.sections.overview.permissions.subtitle"
                    )}
                    padding={"2rem 0 1rem 0"}
                    buttonVariant="tertiary"
                  />

                  <CategoryPermissionsHeader>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={onAllChange}
                          name="all"
                          checked={allChecked}
                        />
                      }
                      label={t(
                        "pages.settings.tabs.subTeams.section.showSubTeams.accessAll"
                      )}
                    />
                  </CategoryPermissionsHeader>
                  <CategoryListContainer>
                    <TeamsSettingContainer>
                      {subTeams?.map((item) => {
                        return (
                          <FormCheckbox
                            control={control}
                            name={`teams.${item.id}`}
                            label={item.name}
                            key={item.id}
                          />
                        );
                      })}
                    </TeamsSettingContainer>
                  </CategoryListContainer>
                </div>
              )}
              {isDirty && (
                <FormButtons>
                  <CTAButton
                    name={t(
                      "pages.settings.tabs.team.teamNameForm.common.buttons.cancel"
                    )}
                    type="reset"
                    variant="secondary"
                    onClick={() => {
                      reset(getDefaultValues(category));
                    }}
                    label="category-permission-cancel-button"
                  />
                  <CTAButton
                    name={t(
                      "pages.settings.tabs.team.teamNameForm.common.buttons.save"
                    )}
                    type="submit"
                    variant="primary"
                    label="category-permission-save-button"
                  />
                </FormButtons>
              )}
            </form>
          </FormProvider>
        </Wrapper>
      </SettingContainer>
      <UnsavedChangesModal
        title={t(
          "pages.settings.organization.administrator.categories.modal.unsavedChanges.title"
        )}
        description={t(
          "pages.settings.organization.administrator.categories.modal.unsavedChanges.description"
        )}
        showModal={showModal || (!!blocker && blocker.state === "blocked")}
        handleDiscardChanges={handleDiscardChanges}
        handleSaveChanges={handleSaveChanges}
        handleCloseModal={handleCloseModal}
        saveBtnId={"saveCategoryChanges"}
        discardBtnId={"discardCategoryChanges"}
      />
    </>
  );
};

export default CategorySetting;
