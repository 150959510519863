import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  Reducer,
  createContext,
  useReducer,
} from "react";

enum PDFViewerActionType {
  JUMP_TO_OFFSET = "JUMP_TO_OFFSET",
}

export type JumpToOffsetPayload = {
  beginOffset: number;
  fieldKey: string;
  visibleId: string;
};

type PDFViewerActionState = {
  jumpToOffset: JumpToOffsetPayload | undefined;
};

type JumpToOffsetAction = {
  type: PDFViewerActionType.JUMP_TO_OFFSET;
  offset: JumpToOffsetPayload | undefined;
};

type Action = JumpToOffsetAction;

const initialState: PDFViewerActionState = {
  jumpToOffset: undefined,
};

export const PDFViewerActionContext =
  createContext<PDFViewerActionState>(initialState);

const PDFViewerActionDispatchContext = createContext<Dispatch<Action> | null>(
  () => void 0
);

export const PDFViewerActionsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [actions, dispatch] = useReducer(pdfViewerActionReducer, initialState);

  return (
    <PDFViewerActionContext.Provider value={actions}>
      <PDFViewerActionDispatchContext.Provider value={dispatch}>
        {children}
      </PDFViewerActionDispatchContext.Provider>
    </PDFViewerActionContext.Provider>
  );
};

export const usePDFViewerActionsDispatch = () => {
  const dispatch = React.useContext(PDFViewerActionDispatchContext);
  if (!dispatch) {
    throw new Error(
      "usePDFViewerActionsDispatch must be used within a PDFViewerActionProvider"
    );
  }

  const jumpToOffset = (page: JumpToOffsetPayload) => {
    dispatch({
      type: PDFViewerActionType.JUMP_TO_OFFSET,
      offset: page,
    });

    setTimeout(() => {
      dispatch({
        type: PDFViewerActionType.JUMP_TO_OFFSET,
        offset: undefined,
      });
    }, 100);
  };

  return {
    jumpToOffset,
  };
};

export const usePDFViewerActionsState = () => {
  const state = React.useContext(PDFViewerActionContext);
  if (!state) {
    throw new Error(
      "usePDFViewerActionsState must be used within a PDFViewerActionProvider"
    );
  }

  return state;
};

const pdfViewerActionReducer: Reducer<PDFViewerActionState, Action> = (
  state: PDFViewerActionState,
  action: Action
) => {
  switch (action.type) {
    case PDFViewerActionType.JUMP_TO_OFFSET:
      return {
        ...state,
        jumpToOffset: action.offset,
      };
    default: {
      throw Error(`Unknown action: ${JSON.stringify(action)}`);
    }
  }
};
