import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${theme.spacing.xxs} ${theme.spacing.xl};
  height: 100%;
  margin-right: ${theme.spacing.xxl};
`;

export const StatusItem = styled.div`
  & span {
    font-weight: ${theme.font.weight.bold};
  }
`;

export const Label = styled.span`
  color: ${theme.color.gray[700]};
  font-size: 13px;
`;
