import { FlatfileResults } from "@flatfile/adapter/build/main/interfaces";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import parseCurrency from "parsecurrency";
import * as Sentry from "@sentry/react";
import currencies from "utils/currencies-list.json";
import { isUUID } from "pages/Contract/helpers";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export const getUUID = (id: string) => {
  if (!id || !isUUID(id)) return undefined;
  return id;
};

export const formatDate = (value: string | undefined, dateFormat: string) => {
  if (!value || value === "") {
    return "";
  }

  let date;
  try {
    date = dayjs.utc(value, dateFormat, true);
  } catch (e) {
    Sentry.captureException(e);
  }
  if (!date || !date.isValid()) {
    return null;
  }
  return date.toDate();
};

export const formatNumber = (value?: string) => {
  if (!value || value === "") {
    return;
  }
  return Number(value) ?? null;
};

export const parseAmount = (value: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const parseAmount = parseCurrency(value) as {
    value: number;
    decimal: string;
    symbol: string;
  };
  if (!parseAmount) {
    return;
  }
  const currency = currencies.find(
    (item) => item.symbol === parseAmount.symbol
  )?.cc;
  return {
    value: parseAmount.value,
    currency: currency || "EUR",
  };
};

export const getAmountField = (value: string, key: string) => {
  const amountField = parseAmount(value);
  if (amountField) {
    return { [key]: amountField };
  } else {
    return {};
  }
};

export const getDateField = (
  value: string,
  key: string,
  userDateFormat: string
) => {
  const date = formatDate(value, userDateFormat);
  if (date) {
    return { [key]: { value: date } };
  } else {
    return {};
  }
};

export type InputType = {
  id: string;
  values: { [key: string]: unknown };
  metadata: object;
  valid: boolean;
}[];

type OutputType = {
  [key: string]: unknown;
}[];

export const transformFlatFileDataToContractHeroDataStructure = (
  input: InputType
): OutputType => {
  return input.map((item) => {
    const newObj: { [key: string]: string } = {};
    const values = item.values;

    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        const itemValue = values[key];
        if (
          typeof itemValue === "object" &&
          itemValue !== null &&
          "value" in itemValue
        ) {
          const value = itemValue.value;

          if (typeof value === "string") {
            newObj[key] = value;
          }
        }
      }
    }

    return newObj;
  });
};

export type ExtendedFlatFileResults = FlatfileResults & {
  $meta: {
    headers_matched: { matched_key: string; index: number }[];
    headers_raw: { index: number; value: string }[];
  };
};
export const getColumnName = (
  results: ExtendedFlatFileResults,
  fieldId: string
) => {
  const headerMatched = results.$meta.headers_matched.find(
    (row) => row.matched_key === fieldId
  );

  if (!headerMatched) {
    return "";
  }
  const headerRaw = results.$meta.headers_raw?.find(
    (row) => row.index === headerMatched.index
  );
  return headerRaw ? headerRaw.value : "";
};
