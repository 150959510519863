import React from "react";
import { useTeam } from "contexts/team/hooks";
import { OverviewDataDto, TeamInfoDto } from "openapi";
import { Card } from "../../components";
import { useStatisticsData } from "./helpers/helpers";
import { Wrapper } from "./styles";

type StatisticsProps = {
  data: unknown;
  isLoading?: boolean;
};

const Statistics = ({ data, isLoading }: StatisticsProps) => {
  const { selectedTeam } = useTeam();

  const formattedData = useStatisticsData(
    data as OverviewDataDto,
    selectedTeam as TeamInfoDto
  );

  return (
    <Wrapper>
      {formattedData.map((cardData) => (
        <Card
          key={`statistic-card-${cardData.id}`}
          data={cardData}
          isLoading={isLoading}
        />
      ))}
    </Wrapper>
  );
};

export default Statistics;
