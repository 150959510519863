import React from "react";
import { Wrapper, ButtonWrapper, Button } from "./styles";

export type SwitchBarOptionType = {
  key: string;
  label: string;
  active: boolean;
};

export type SwitchBarProps = {
  activeKey: string;
  setActiveKey: (value: string) => void;
  disabled?: boolean;
  options: SwitchBarOptionType[];
};

const SwitchBar = ({
  activeKey,
  setActiveKey,
  options,
  disabled,
}: SwitchBarProps) => {
  const handleOnClick = (key: string) => {
    setActiveKey(key);
  };

  if (!options?.length) return <></>;

  return (
    <Wrapper disabled={disabled}>
      {options.map(({ key, label }, index) => (
        <ButtonWrapper
          key={`switch-bar-option-${index}`}
          active={activeKey === key}
          isFirst={index === 0}
          isLast={index === options.length - 1}
          noDivider={options.length < 3}
        >
          <Button active={activeKey === key} onClick={() => handleOnClick(key)}>
            <span>{label}</span>
          </Button>
        </ButtonWrapper>
      ))}
    </Wrapper>
  );
};

export default SwitchBar;
