import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

const TitleWrapper = styled.h1`
  font-weight: 400;
  color: #1a202c;
  text-align: start;
  margin-bottom: 0px;
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin: 0px;
`;

const ActionsWrapper = styled.div`
  float: right;
`;

type Props = {
  children: JSX.Element;
  actions?: JSX.Element;
  titleKey?: string;
};

const BaseBodyWrapper = ({ children, titleKey, actions }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <TitleWrapper>
        {titleKey && t(titleKey)}
        {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
      </TitleWrapper>
      {children}
    </>
  );
};

export default BaseBodyWrapper;
