import {
  ApiError,
  PartiallyTeamInfoUpdateDto,
  TeamInfoDto,
  TeamService,
  ContractService,
} from "openapi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useTeamQuery = (teamId: string) => {
  return useQuery<TeamInfoDto, ApiError>({
    queryKey: ["teams", teamId],
    queryFn: () => TeamService.getTeamInfo(teamId),
    enabled: !!teamId,
    retry(failureCount, error) {
      return (
        failureCount < 3 &&
        !!error &&
        error.status !== 404 &&
        error.status !== 403
      );
    },
    refetchOnWindowFocus: false,
  });
};

export const useTeamUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      teamId: string;
      team: PartiallyTeamInfoUpdateDto;
    }) => {
      return TeamService.partiallyUpdateTeamInfo(data.teamId, data.team);
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["teams", variables.teamId],
      });
    },
  });
};

export const useTagQuery = (teamId: string) => {
  return useQuery({
    queryKey: ["tags", teamId],
    queryFn: () => TeamService.getTags(teamId),
    enabled: !!teamId,
  });
};

export const useAddNewTagToContractMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { teamId: string; name: string; color: string }) => {
      return TeamService.addTag(data.teamId, {
        name: data.name,
        color: data.color,
      });
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["tags", variables.teamId],
        exact: true,
      });
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });
};

export const useRemoveTagFromContractMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      contractId: string;
      tagId: string;
      teamId: string;
    }) => {
      return ContractService.removeTag(data.contractId, data.tagId);
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["tags", variables.teamId],
        exact: true,
      });
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });
};

export const useAddTagToContractMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      contractId: string;
      tagId: string;
      teamId: string;
    }) => {
      return ContractService.addTags(data.contractId, { id: data.tagId });
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["tags", variables.teamId],
        exact: true,
      });
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });
};
