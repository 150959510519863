import React, {
  useState,
  useEffect,
  RefObject,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import {
  GridReadyEvent,
  GridApi,
  ColDef,
  CellValueChangedEvent,
  IRowNode,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { TeamDTO } from "openapi";
import {
  CategoryNameCellRenderer,
  TeamNameCellRenderer,
  CheckboxCellRenderer,
} from "./Cells";
import { ViewMode, AccessInfoType } from "../../Teams";
import { Wrapper } from "./styles";

type MatrixTableProps = {
  viewMode: ViewMode;
  gridRef?: RefObject<AgGridReact>;
  gridApi?: GridApi;
  onGridReady: (params: GridReadyEvent) => void;
  teamsData?: TeamDTO[];
  categories?: {
    id: string;
    name: string;
    color: string;
  }[];
  setIsDirty: (val: boolean) => void;
  accessInfo?: AccessInfoType[];
  setAccessInfo: Dispatch<SetStateAction<AccessInfoType[]>>;
  filteredRowsIds?: Set<string>;
  columnDefs?: ColDef[];
  setColumnDefs: Dispatch<SetStateAction<ColDef[]>>;
};

const MatrixTable = ({
  viewMode,
  gridRef,
  gridApi,
  onGridReady,
  teamsData = [],
  categories = [],
  setIsDirty,
  setAccessInfo,
  filteredRowsIds = new Set<string>(),
  columnDefs,
  setColumnDefs,
}: MatrixTableProps) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState([]);

  const updateGridColumns = () => {
    const commonColProps = {
      menuTabs: [],
      editable: false,
      viewMode: viewMode,
      cellRenderer: CheckboxCellRenderer,
      hide: false,
      resizable: true,
      minWidth: 150,
    };

    if (viewMode === "team") {
      const columns = [
        {
          id: "id",
          field: "name",
          headerName: "Team Name",
          cellRenderer: TeamNameCellRenderer,
          filter: "agSetColumnFilter",
          filterParams: { selectAllOnMiniFilter: true },
          sortable: true,
          menuTabs: [],
          pinned: true,
          editable: false,
          width: 200,
        },
        ...(categories?.map((category) => ({
          ...commonColProps,
          field: category.id,
          headerName: category.name,
          headerComponent: CategoryNameCellRenderer,
          headerComponentParams: {
            ...category,
            categoryId: category.id,
            isHeader: true,
          },
          sortable: true,
          width: 150,
        })) ?? []),
      ];

      setColumnDefs(columns);
    } else {
      const columns = [
        {
          id: "id",
          field: "name",
          color: "color",
          headerName: t(
            "pages.settings.organization.teams.table.headers.categoryName"
          ),
          cellRenderer: CategoryNameCellRenderer,
          filter: "agSetColumnFilter",
          filterParams: { selectAllOnMiniFilter: true },
          sortable: true,
          menuTabs: [],
          editable: false,
          width: 200,
        },
        ...(teamsData?.map((team) => {
          return {
            ...commonColProps,
            id: team.id,
            field: team.id,
            headerName: team.name,
            sortable: true,
            cellRendererParams: {
              teamId: team.id,
            },
            width: 150,
          };
        }) ?? []),
      ];

      setColumnDefs(columns);
    }
  };

  const updateGridRows = () => {
    if (viewMode === "team") {
      const data = teamsData?.map((team) => {
        return {
          id: team.id,
          name: team.name,
          cellRenderer: CategoryNameCellRenderer,
          ...categories?.reduce(
            (acc, category) => ({
              ...acc,
              [category.id]: !!team.categories.find(
                (cat) => cat.id === category.id
              ),
            }),
            {}
          ),
        };
      });
      setRowData(data as []);
    } else {
      const data = categories?.map((category) => ({
        id: category.id,
        name: category.name,
        color: category?.color,
        ...teamsData?.reduce(
          (acc, team) => ({
            ...acc,
            [team.id]: !!team.categories.find((cat) => cat.id === category.id),
          }),
          {}
        ),
      }));
      setRowData(data as []);
    }
  };

  const onCellValueChanged = (params: CellValueChangedEvent) => {
    const currentAccessInfo = {
      teamId: (viewMode === "team"
        ? (params.data as { id: string })?.id
        : params.colDef.field) as string,
      categoryId: (viewMode === "team"
        ? params.colDef.field
        : (params.data as { id: string })?.id) as string,
      access: params.value as boolean,
    };

    setAccessInfo((prevValues) => {
      const newValueIndex = prevValues.findIndex(
        (value) =>
          value.teamId === currentAccessInfo.teamId &&
          value.categoryId === currentAccessInfo.categoryId
      );

      let updatedValues;
      if (newValueIndex >= 0) {
        updatedValues = prevValues.filter((_, i) => i !== newValueIndex);
      } else {
        updatedValues = [...prevValues, currentAccessInfo];
      }

      setIsDirty(updatedValues.length > 0);
      return updatedValues;
    });
  };

  const isExternalFilterPresent = useCallback((): boolean => {
    return filteredRowsIds?.size > 0;
  }, [filteredRowsIds]);

  const doesExternalFilterPass = useCallback(
    (node: IRowNode) => {
      if (!filteredRowsIds) return false;

      if (node.data) {
        return filteredRowsIds.has((node?.data as IRowNode)?.id ?? "");
      }

      return true;
    },
    [filteredRowsIds]
  );

  useEffect(() => {
    if (gridApi) {
      updateGridColumns();
      updateGridRows();
    }
  }, [viewMode, gridApi, teamsData.length]);

  useEffect(() => {
    if (!gridRef) return;
    gridRef?.current?.api?.onFilterChanged();
  }, [filteredRowsIds]);

  const rowHeight = 50;
  return (
    <Wrapper className="ag-theme-alpine">
      <AgGridReact
        ref={gridRef as RefObject<AgGridReact>}
        columnDefs={columnDefs}
        rowData={rowData}
        onGridReady={onGridReady}
        animateRows
        onCellValueChanged={onCellValueChanged}
        isExternalFilterPresent={isExternalFilterPresent}
        doesExternalFilterPass={doesExternalFilterPass}
        headerHeight={rowHeight}
        rowHeight={rowHeight}
      />
    </Wrapper>
  );
};

export default MatrixTable;
