import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { FallbackProps } from "react-error-boundary";
import * as Sentry from "@sentry/react";

const ErrorHandler = ({ error }: FallbackProps) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    Sentry.captureException(error);
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  }, [error]);

  return <h1>Sorry.. there was an error</h1>;
};
export default ErrorHandler;
