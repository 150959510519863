/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { WebhookLogResponseDTO } from "./WebhookLogResponseDTO";

export type WebhookLogDTO = {
  id: string;
  event: WebhookLogDTO.event;
  retries: number;
  state: Record<string, any>;
  createdAt: string;
  requestData: {
    headers?: Record<string, any>;
    body?: Record<string, any>;
  };
  responses: Array<WebhookLogResponseDTO>;
};

export namespace WebhookLogDTO {
  export enum event {
    CONTRACT_CREATED = "CONTRACT_CREATED",
    CONTRACT_EDITED = "CONTRACT_EDITED",
    MEMBER_INVITATION_CREATED = "MEMBER_INVITATION_CREATED",
    MEMBER_INVITATION_ACCEPTED = "MEMBER_INVITATION_ACCEPTED",
    TASK_CREATED = "TASK_CREATED",
    TASK_UPDATED = "TASK_UPDATED",
    TASK_DUE_DATE_REACHED = "TASK_DUE_DATE_REACHED",
    TASK_REMINDER_SENT = "TASK_REMINDER_SENT",
    SUMMARY_UPDATED = "SUMMARY_UPDATED",
  }
}
