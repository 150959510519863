import React from "react";
import { useOrganizationTeamsQuery, useRolesOfMemberQuery } from "shared/api";
import { Role } from "components";
import { theme } from "theme";
import styled from "@emotion/styled";
import { getNameWithSuffix } from "../../helper";
import { t } from "i18next";

export type TeamMemberRolesProps = {
  memberId: string;
  organizationId: string;
};

const RolesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.xs};
`;

const TeamMemberRoles = ({
  memberId,
  organizationId,
}: TeamMemberRolesProps) => {
  const {
    data: rolesData,
    isError: isFetchMemberRolesError,
    isLoading: isLoadingRolesData,
  } = useRolesOfMemberQuery(memberId, organizationId);

  const {
    data: organizationData,
    isError: isFetchOrganizationsError,
    isLoading: isLoadingOrganizationData,
  } = useOrganizationTeamsQuery(organizationId);

  if (isLoadingRolesData || isLoadingOrganizationData) return null;

  if (isFetchMemberRolesError || isFetchOrganizationsError)
    return <div>Error</div>;

  const rolesOptions =
    rolesData?.map((r) => ({
      id: r?.id,
      name: getNameWithSuffix(r.name, r.teamId, organizationData?.teams, t),
    })) ?? [];

  return (
    <RolesWrapper data-testid="team-member-roles">
      {rolesOptions?.map((role) => (
        <Role key={role.id} name={role.name} />
      ))}
    </RolesWrapper>
  );
};

export default TeamMemberRoles;
