import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from "lexical";

import { $applyNodeReplacement, DecoratorNode } from "lexical";
import * as React from "react";
import { Suspense } from "react";

export type ImagePayload = {
  nodeKey?: NodeKey;
  src: string;
  width?: number;
  height?: number;
  altText: string;
};

export type SerializedImageNode = Spread<
  {
    height?: number;
    src: string;
    width?: number;
    type: "image";
    version: 1;
  },
  SerializedLexicalNode
>;

const ImageComponent = React.lazy(() => import("./ImageComponent"));

const convertImageElement = (domNode: Node): DOMConversionOutput | null => {
  if (domNode instanceof HTMLImageElement) {
    return {
      node: $createImageNode({
        src: domNode.src,
        altText: domNode.alt,
        width: domNode.width,
        height: domNode.height,
      }),
    };
  }
  return null;
};

export class ImageNode extends DecoratorNode<JSX.Element> {
  static getType(): string {
    return "image";
  }

  static clone(node: ImageNode) {
    return new ImageNode(node.__src, node.__width, node.__height);
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: (node: Node) => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    };
  }

  private __src: string;
  private __width: number | "inherit";
  private __height: number | "inherit";

  constructor(
    src: string,
    width?: number | "inherit",
    height?: number | "inherit",
    key?: NodeKey
  ) {
    super(key);
    this.__src = src;
    this.__width = width || "inherit";
    this.__height = height || "inherit";
  }

  createDOM(config: EditorConfig, _editor: LexicalEditor): HTMLElement {
    const span = document.createElement("span");
    const imageClassName = config.theme.image;
    if (imageClassName !== undefined) {
      span.className = imageClassName;
    }
    return span;
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const img = document.createElement("img");
    img.setAttribute("src", this.__src);
    img.setAttribute("width", this.__width.toString());
    img.setAttribute("height", this.__height.toString());
    return { element: img };
  }

  updateDOM(
    _prevNode: unknown,
    _dom: HTMLElement,
    _config: EditorConfig
  ): boolean {
    return false;
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    const { height, width, src } = serializedNode;
    const node = $createImageNode({
      altText: "",
      height,
      src,
      width,
    });
    return node;
  }

  exportJSON(): SerializedImageNode {
    return {
      height: this.__height === "inherit" ? 0 : this.__height,
      src: this.__src,
      type: "image",
      version: 1,
      width: this.__width === "inherit" ? 0 : this.__width,
    };
  }

  setWidthAndHeight(
    width: "inherit" | number,
    height: "inherit" | number
  ): void {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  decorate(editor: LexicalEditor, config: EditorConfig): JSX.Element {
    return (
      <Suspense fallback={null}>
        <ImageComponent
          src={this.__src}
          width={this.__width}
          height={this.__height}
          altText=""
          nodeKey={this.getKey()}
        />
      </Suspense>
    );
  }
}

export const $createImageNode = (payload: ImagePayload): ImageNode => {
  return $applyNodeReplacement(
    new ImageNode(payload.src, payload.width, payload.height, payload.nodeKey)
  );
};

export const $isImageNode = (
  node: LexicalNode | null | undefined
): node is ImageNode => {
  return node instanceof ImageNode;
};
