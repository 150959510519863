import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  baseDropzone: {
    borderWidth: "2px",
    borderStyle: "dashed",
    borderRadius: "0.75rem",
    borderColor: "#90cdf4",
    backgroundColor: "#e9f3ff",
    margin: "1rem 0.5rem",
    width: "unset",
    minHeight: "unset",
    padding: "2.5rem 0.5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  baseFilezone: {
    borderRadius: "0.75rem",
    borderOpacity: "1",
    borderColor: "#f7fafc",
    bgOpacity: "1",
    backgroundColor: "#f7fafc",
    margin: "1rem 0.5rem",
    padding: "0.75rem 0",
  },
});
