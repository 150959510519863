import styled from "@emotion/styled";
import { theme } from "theme";

export const settingsButtonSize = "32px";
export const popoverIconSize = "16px";

export const PopoverContainer = styled.div`
  min-width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${theme.spacing.sm};
  cursor: pointer;
`;

export const PopoverOption = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.sm};
  font-size: ${theme.font.size.body};
  color: ${theme.color.gray[700]};
  transition: background-color 0.15s ease-in-out;

  :hover {
    background-color: ${theme.color.gray[200]};
    border-radius: ${theme.borderRadius};
  }

  svg {
    width: ${popoverIconSize};
    height: ${popoverIconSize};
    margin-right: ${theme.spacing.sm};
  }

  ${({ danger }: { danger?: boolean }) =>
    danger &&
    `
    color: ${theme.color.red[500]};

    :hover {
      background-color: ${theme.color.red[100]};
    }
  `}
`;

export const paperPropsStyles = {
  border: `1px solid ${theme.color.gray[300]}`,
  backgroundColor: theme.color.white,
};
