import React from "react";
import { Label } from "./styles";

export type StatusLabelType = {
  title: string;
  variant: string;
};
const StatusLabel = ({ title, variant }: StatusLabelType) => {
  return <Label variant={variant}>{title}</Label>;
};

export default StatusLabel;
