/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { OrganizationNewService, VerifyAnalysisDTO } from "openapi";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useVerifyDatapointMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: {
      organizationId: string;
      teamId: string;
      contractId: string;
      datapointId: string;
      requestBody: VerifyAnalysisDTO;
    }) => {
      return OrganizationNewService.verifyDatapoint(
        data.organizationId,
        data.teamId,
        data.contractId,
        data.datapointId,
        data.requestBody
      );
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ["contracts"],
      });
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });
};
