import React from "react";
import { useTranslation } from "react-i18next";
import { Wrapper, Title, ListWrapper } from "./styles";

import { CategoryDTO, TeamLogDataDTO } from "openapi";
import { AllowIcon, Value } from "../InitialEntry/styles";
import TypeLabel from "../TypeLabel/TypeLabel";
import CategoryBadge from "../CategoryBadge/CategoryBadge";
import { useLocale } from "hooks";
import { DELETED_CATEGORY_COLOR } from "../../helpers";

export type TeamInitialType = {
  data: TeamLogDataDTO;
  categories: CategoryDTO[];
};

const TeamInitial = ({ data, categories }: TeamInitialType) => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  return (
    <Wrapper>
      <Title>{data.name}</Title>
      {!!data?.categories?.length && (
        <>
          <TypeLabel variant="categoryAccess" />
          <Value>
            <AllowIcon /> {t("common.labels.allowed")}
          </Value>
          <ListWrapper>
            {data.categories.map((id) => {
              const category = categories.find((item) => item.id === id);
              const categoryName =
                category?.name[locale] || t("common.labels.deletedCategory");
              const categoryColor = category?.color || DELETED_CATEGORY_COLOR;
              return (
                <CategoryBadge
                  key={id}
                  color={categoryColor}
                  name={categoryName}
                />
              );
            })}
          </ListWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default TeamInitial;
