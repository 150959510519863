/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PermissionDTO = {
  id: string;
  categoryId: string;
  teamId: string;
  level: PermissionDTO.level;
};

export namespace PermissionDTO {
  export enum level {
    READ = "READ",
    WRITE = "WRITE",
  }
}
