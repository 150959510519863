import React, { useEffect, useState } from "react";
import BasicTable from "components/BasicTable/BasicTable";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { useTranslation } from "react-i18next";
import { AdminService, ContractCategoryBlueprintDto } from "openapi";
import { Header, Row } from "components/BasicTable/types";
import { useForm } from "react-hook-form";

const CategoryMapping = () => {
  const { t } = useTranslation();
  const [blueprintCategories, setBlueprintCategories] = useState<
    ContractCategoryBlueprintDto[]
  >([]);
  const [data, setData] = useState<Row[]>([]);
  const { handleSubmit, register } = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });

  const updateCategoryMapping = async (
    categoryId: string,
    blueprintName: string
  ) => {
    if (!blueprintName) {
      return;
    }
    let blueprint = blueprintCategories.find(
      (item) => item.type === blueprintName
    );
    if (!blueprint) {
      blueprint = await AdminService.createBlueprintCategory({
        type: blueprintName,
      });
    }
    await AdminService.createCategoryMapping(categoryId, blueprint.id, {
      count: 1,
    });
  };

  const fetchData = async () => {
    const blueprintCategories = await AdminService.getBlueprintCategories();
    setBlueprintCategories(blueprintCategories);
  };

  const fetchCategoryMappings = async (search: string) => {
    const categoryMappings = await AdminService.getCategoryMappings(search);

    const data = categoryMappings.map((categoryMapping) => {
      return {
        id: categoryMapping.categoryid,
        name: categoryMapping.categoryname,
        team: categoryMapping.teamname,
        contracts: categoryMapping.contracts,
        blueprintCategory: (
          <div>
            <input
              type="text"
              list="suggestions"
              onBlur={(e) =>
                updateCategoryMapping(
                  categoryMapping.categoryid,
                  e.currentTarget.value
                )
              }
            ></input>
          </div>
        ),
      };
    });
    setData(data);
  };

  useEffect(() => {
    void fetchCategoryMappings("");
  }, [blueprintCategories]);

  useEffect(() => {
    void fetchData();
  }, []);

  const header: Header[] = [
    {
      key: "name",
      name: t("pages.admin.tabs.categoryMappings.name").toUpperCase(),
    },
    {
      key: "team",
      name: t("pages.admin.tabs.categoryMappings.team").toUpperCase(),
    },
    {
      key: "contracts",
      name: t("pages.admin.tabs.categoryMappings.contracts").toUpperCase(),
    },
    {
      key: "blueprintCategory",
      name: t(
        "pages.admin.tabs.categoryMappings.blueprintCategory"
      ).toUpperCase(),
    },
  ];

  function search(values: { search: string }) {
    void fetchCategoryMappings(values.search);
  }

  return (
    <CardWrapper>
      <>
        <datalist id="suggestions">
          {blueprintCategories.map((blueprintCategory) => {
            return (
              <option
                key={blueprintCategory.type}
                value={blueprintCategory.type}
              />
            );
          })}
        </datalist>
        <form name="annotationForm" onSubmit={handleSubmit(search)} noValidate>
          <input placeholder="search" {...register("search")} />
        </form>
        <CardHeaderWrapper
          title={t("pages.admin.tabs.categoryMappings.title")}
        />
        <BasicTable headers={header} data={data} />
      </>
    </CardWrapper>
  );
};

export default CategoryMapping;
