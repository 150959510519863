import styled from "@emotion/styled";
import theme from "theme/theme";

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${theme.spacing.lg};
  top: 80px;
  z-index: 1;
`;

export const Group = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  margin: 0 ${theme.spacing.sm};
`;
