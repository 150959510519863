import styled from "@emotion/styled";
import { theme } from "theme";

export const DropdownWrapper = styled.div`
  position: relative;
  z-index: 1000;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + 6px);
  right: 0;
  background-color: ${theme.color.white};
  border-radius: ${theme.spacing.xs};
  width: 220px;
  filter: drop-shadow(1px 2px 6px rgba(0, 0, 0, 0.1));
`;
