import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { ProfileDto, ProfileService } from "openapi";
import { setValidationErrors } from "shared/service/errorResponseService";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormTextField } from "components";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import { FormSelect } from "components/FormItems/FormSelect/FormSelect";
import { Language } from "shared/i18n/i18n";
import { useUserInfo } from "hooks/GlobalStateHooks";
import { useSnackbar } from "notistack";
import { dateFormats, nonFreeEmailVaidator } from "constants/utils";

const validationSchema = () =>
  Yup.object({
    email: nonFreeEmailVaidator(),
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    phone: Yup.string().nullable(),
  });

const initialValues: ProfileDto = {
  email: "",
  firstname: "",
  lastname: "",
  phone: null,
  locale: ProfileDto.locale.DE,
  dateFormat: dateFormats.EU_DOTS,
};

const ProfileForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { refreshUserInfo } = useUserInfo();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState<ProfileDto>();

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(validationSchema()),
  });

  const fetchData = async () => {
    setData(await ProfileService.getProfile());
  };

  useEffect(() => {
    void fetchData();
  }, []);

  useEffect(() => {
    if (data) reset(data);
  }, [data]);

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await ProfileService.updateProfile(values);
      await refreshUserInfo();
      enqueueSnackbar(
        t("pages.settings.tabs.profile.information.profileUpdated"),
        { variant: "success" }
      );
    } catch (e) {
      setValidationErrors(
        e,
        setError,
        "pages.settings.tabs.profile",
        undefined,
        enqueueSnackbar,
        t
      );
    }
  };

  return (
    <>
      <form
        name="profileForm"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        style={{
          margin: "1rem 0",
          maxWidth: "36rem",
        }}
      >
        <FormTextField
          control={control}
          name="email"
          label={t("pages.settings.tabs.profile.form.emailAddress")}
          type="email"
          required
        />
        <Grid container spacing={1.25}>
          <Grid item xs={12} md={6}>
            <FormTextField
              control={control}
              name="firstname"
              label={t("pages.settings.tabs.profile.form.firstName")}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              control={control}
              name="lastname"
              label={t("pages.settings.tabs.profile.form.lastName")}
              required
            />
          </Grid>
        </Grid>
        <FormTextField
          control={control}
          name="phone"
          label={t("pages.settings.tabs.profile.form.phone")}
          type="tel"
          required
        />
        <Grid container spacing={1.25}>
          <Grid item xs={12} md={6}>
            <FormSelect
              control={control}
              name="locale"
              options={Object.keys(Language)}
              translationPrefix={"common.languages"}
              label={t("pages.settings.tabs.profile.form.language")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelect
              control={control}
              name="dateFormat"
              options={Object.values(dateFormats)}
              label={t("pages.settings.tabs.profile.form.dateFormat")}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 3, mb: 2 }}>
          <Button
            id="updateBtn"
            type="submit"
            size="large"
            className={classes.baseButton}
            fullWidth
          >
            {t("pages.settings.tabs.profile.form.submit")}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default ProfileForm;
