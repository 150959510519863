import styled from "@emotion/styled";
import { theme } from "theme";

export const Name = styled.span`
  color: ${theme.color.gray[700]};

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
