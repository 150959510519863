import { Tooltip } from "@mui/material";
import { useOverviewState } from "contexts/grid/hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import { ViewButton } from "./styles";
import { theme } from "theme";

export const EntityGridFilterResetButton = () => {
  const { overview } = useOverviewState();
  const { t } = useTranslation();

  const clearGridFilters = () => {
    setTimeout(() => {
      if (overview.agGrid.initialized && overview.agGrid.gridRef.current) {
        overview.agGrid.gridRef.current?.api.setFilterModel(null);
        overview.agGrid.gridRef.current?.api.onFilterChanged();
      }
    }, 0);
  };

  return (
    <>
      {overview.agGrid.initialized &&
        Object.keys(overview.agGrid.filterModel).length > 0 && (
          <Tooltip title={t("pages.contracts.header.clearFilters")}>
            <ViewButton
              onClick={clearGridFilters}
              sx={{
                "> *": {
                  color: theme.color.red[700],
                },
              }}
            >
              <ClearIcon fontSize="small" sx={{ mr: "0.25rem" }} />
              <span>{t("pages.contracts.header.clearFilters")}</span>
            </ViewButton>
          </Tooltip>
        )}
    </>
  );
};
