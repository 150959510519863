import styled from "@emotion/styled";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions } from "@mui/material";
import Button from "@mui/material/Button";
import PublishIcon from "@mui/icons-material/Publish";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FlashOnIcon from "@mui/icons-material/FlashOn";

export const ModalContent = styled(DialogContent)`
  width: calc(100% - 2rem);
  color: #4a5568;
  background-color: #fff;
  padding: 2rem 2rem 0;

  @media screen and (min-width: 768px) {
    max-width: 640px;
    width: fit-content;
  }
`;

export const Header = styled.div`
  padding: 0 1rem;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;

  span {
    margin: 0.5rem 1rem;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;

    span {
      flex-basis: 50%;
      margin: 0;
    }
  }
`;

const commonIconStyles = `
  margin-right: 1rem;
  color: #124172;
`;
export const FileIcon = styled(InsertDriveFileIcon)`
  ${commonIconStyles}
`;
export const ImportIcon = styled(PublishIcon)`
  ${commonIconStyles}
`;
export const ThunderIcon = styled(FlashOnIcon)`
  ${commonIconStyles}
`;

export const ItemButton = styled.button`
  display: flex;
  align-items: center;
  text-align: left;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0.5rem 1rem;
  color: #4a5568;
  background-color: #e9f3ff;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 2px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;

  :hover {
    box-shadow: rgba(3, 102, 214, 0.6) 0px 0px 0px 2px;
  }

  :active {
    transform: scale(0.99);
    opacity: 0.8;
  }
`;

export const Footer = styled(DialogActions)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem 2rem;
`;

export const FooterForm = styled.form`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const BackButton = styled(Button)`
  text-transform: capitalize;
`;

export const SkipButton = styled(Button)`
  text-transform: capitalize;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  padding: 2rem;
`;

export const LoadingMessage = styled.div`
  font-weight: 500;
  line-height: 1.5px;
  margin-right: 1rem;
`;
