import styled from "@emotion/styled";
import { theme } from "theme";

export const DefaultTable = styled.table<{ disableDefaultStyles?: boolean }>`
  ${({ disableDefaultStyles }) =>
    !disableDefaultStyles &&
    `
    margin-top: ${theme.spacing.xl};
    box-shadow: ${theme.shadow.alternative};
    width: 100%;
    border-spacing: 0px;
    border-radius: ${theme.borderRadius};
    overflow: hidden;
  `}
`;

export const DefaultTableHead = styled.thead<{
  disableDefaultStyles?: boolean;
}>`
  ${({ disableDefaultStyles }) =>
    !disableDefaultStyles &&
    `
    background-color: ${theme.color.gray[100]};
  `}
`;

export const DefaultTableHeadCell = styled.th<{
  disableDefaultStyles?: boolean;
}>`
  ${({ disableDefaultStyles }) =>
    !disableDefaultStyles &&
    `
    text-align: left;
    padding: ${theme.spacing.md} ${theme.spacing.xl};
    text-transform: uppercase;
    font-size: ${theme.font.size.caption};
    border-bottom: 1px solid ${theme.color.blue[600]}26;
    cursor: pointer;
    font-weight: 500;
    min-width: 170px;
  `}
`;

export const DefaultTableRow = styled.tr<{ disableDefaultStyles?: boolean }>`
  ${({ disableDefaultStyles }) =>
    !disableDefaultStyles &&
    `
    background-color: ${theme.color.gray[100]};
  `}
`;

export const DefaultTableCell = styled.td<{
  isLastCellStyled?: boolean;
  disableDefaultStyles?: boolean;
  alignCellTop?: boolean;
}>`
  ${({ disableDefaultStyles }) =>
    !disableDefaultStyles &&
    `
    padding: ${theme.spacing.md} ${theme.spacing.xl};
    border-bottom: 1px solid ${theme.color.blue[600]}26;
    word-wrap: break-word;
    background-color: ${theme.color.white};
    font-size: ${theme.font.size.body};
    height: 56px;
    line-height: 20px;
  `}
  ${({ isLastCellStyled }) =>
    isLastCellStyled &&
    `
    text-align: right;
  `}
`;

export const OrderIndicator = styled.span`
  width: 10px;
  display: inline-block;
  padding-left: 3px;
`;

export const TableHeadCellRow = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
`;

export const TableHeadCellText = styled.span`
  display: grid;
  place-items: center;
`;
