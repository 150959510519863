import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import React, { FC } from "react";
import { ToolbarButton } from "./ToolbarButton";
import { ToolbarSpecificButtonProps } from "./types";
import { RotateDirection } from "@react-pdf-viewer/core";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useTranslation } from "react-i18next";
export const RotateButton: FC<ToolbarSpecificButtonProps<"Rotate">> = ({
  Slot,
  collapses,
  collapsed,
}) => {
  const { t } = useTranslation();

  return (
    <Slot direction={RotateDirection.Backward}>
      {(props) => {
        return collapsed ? (
          <MenuItem
            onClick={() => {
              props.onClick();
            }}
          >
            <ListItemIcon>
              <RotateLeftIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("pdfViewer.toolbar.rotate")}</ListItemText>
          </MenuItem>
        ) : (
          <ToolbarButton
            tooltip={t("pdfViewer.toolbar.rotate")}
            onClick={() => {
              props.onClick();
            }}
            collapses={collapses}
          >
            <RotateLeftIcon />
          </ToolbarButton>
        );
      }}
    </Slot>
  );
};
