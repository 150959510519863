import { Tooltip } from "@mui/material";
import React, { FC, ReactNode } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { InfoWrapper } from "./styles";

type InfoTooltipProps = {
  children: string | ReactNode;
  customStyles?: {
    tooltip?: React.CSSProperties;
    arrow?: React.CSSProperties;
  };
  showArrow?: boolean;
};

export const InfoTooltip: FC<InfoTooltipProps> = ({
  children,
  customStyles = {},
  showArrow = false,
}) => {
  return (
    <InfoWrapper>
      <Tooltip
        title={children}
        arrow={showArrow}
        componentsProps={{
          tooltip: {
            sx: {
              ...customStyles.tooltip,
            },
          },
          arrow: {
            sx: {
              color:
                customStyles.arrow?.color ||
                customStyles.tooltip?.backgroundColor,
            },
          },
        }}
      >
        <InfoOutlinedIcon />
      </Tooltip>
    </InfoWrapper>
  );
};
