import styled from "@emotion/styled";
import { theme } from "theme";

export const SubMenu = styled.div<{ expanded?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
  ${(props) =>
    props.expanded &&
    `
    :before {
      content: "";
      position: absolute;
      left: ${theme.spacing.xl};
      top: 0;
      width: 2px;
      height: 100%;
      background-color: ${theme.color.gray[200]};
    }
    .MuiButtonBase-root {
      gap: ${theme.spacing.sm};
      padding-left: 42px;
    }
  `}
`;

export const ArrowIcon = styled.div<{ open?: boolean }>`
  svg {
    width: 14px;
    transition: transform 0.25s ease-in-out;
    transform: ${({ open }: { open?: boolean }) =>
      open ? "rotate(180deg)" : "rotate(0)"};
    fill: ${theme.color.gray[700]};
  }
`;
