import React, { ReactElement } from "react";
import styled from "@emotion/styled";
import { AlertTypeEnum } from "../../shared/enums/alert.enum";
import { Trans, useTranslation } from "react-i18next";
import { StringMap, TOptions } from "i18next";

export const AlertWrapper = styled.div<{
  type?: AlertTypeEnum;
  isLeftBorder?: boolean;
}>`
  width: 100%;
  padding: 1rem;
  color: ${(prop) =>
    prop.type === AlertTypeEnum.success
      ? "#014451"
      : prop.type === AlertTypeEnum.error
      ? "#742a2a;"
      : "#c05621"};
  background-color: ${(prop) =>
    prop.type === AlertTypeEnum.success
      ? "#d5f5f6"
      : prop.type === AlertTypeEnum.error
      ? "#fff5f5"
      : "#fffaf0"};
  border-left: ${(prop) => (prop.isLeftBorder ? "4px solid" : "none")}
    ${(prop) =>
      prop.type === AlertTypeEnum.success
        ? "#0694a2"
        : prop.type === AlertTypeEnum.error
        ? "#f56565"
        : "#ed8936"};
  ${(prop) =>
    prop.isLeftBorder
      ? "text-alig: left; margin-bottom: 0; margin-top: 1.5rem;"
      : "text-align: center; margin-bottom: 1.5rem; margin-top: 0;"};
`;

type Prop = {
  textKey: string; //translation key
  type?: AlertTypeEnum;
  element?: ReactElement;
  params?: TOptions<StringMap>;
  isLeftBorder?: boolean;
};

const AlertComponent = ({
  type,
  textKey,
  element,
  params,
  isLeftBorder = true,
}: Prop) => {
  const { t } = useTranslation();

  return (
    <AlertWrapper type={type} role="alert" isLeftBorder={isLeftBorder}>
      {element ? (
        <Trans i18nKey={textKey}>{element}</Trans>
      ) : params ? (
        t(textKey, params)
      ) : (
        t(textKey)
      )}
    </AlertWrapper>
  );
};

export default AlertComponent;
