import React, { useState } from "react";
import { usePrintContext } from "contexts/contract/context";
import { Button } from "@mui/material";
import { useStyles as useStyledButtons } from "components/StyledComponents/StyledBaseButtons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { t } from "i18next";
import { DropdownWrapper, DropdownMenu } from "./styles";
import PrintIcon from "@mui/icons-material/Print";
import { useOutsideClick } from "hooks";

const Dropdown = () => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const { handlePrint } = usePrintContext();
  const buttonClasses = useStyledButtons();

  const dropdownRef = useOutsideClick(() => {
    if (isDropdownOpened) {
      setIsDropdownOpened(false);
    }
  });

  const onPrintHandler = () => {
    setIsDropdownOpened(false);
    handlePrint();
  };

  return (
    <DropdownWrapper ref={dropdownRef}>
      <Button
        aria-label="toggle-contract-menu-button"
        onClick={() => setIsDropdownOpened(!isDropdownOpened)}
        className={buttonClasses.previewHeaderToggleButton}
      >
        <MoreVertIcon fontSize="medium" />
      </Button>

      {!!isDropdownOpened && (
        <DropdownMenu>
          <Button
            aria-label="print-contract-details-button"
            onClick={onPrintHandler}
            className={buttonClasses.previewHeaderDropdownButton}
          >
            <PrintIcon fontSize="small" sx={{ marginRight: "0.4rem" }} />
            {t("pages.contractDetails.buttons.printContractDetails")}
          </Button>
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
};

export default Dropdown;
