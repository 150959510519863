import styled from "@emotion/styled";
import { theme } from "theme";

type ButtonProps = {
  variant?: "primary" | "secondary" | "tertiary";
  size?: "tiny" | "large" | "small" | "stretched";
  iconPosition?: "left" | "right";
  color: keyof typeof theme.color | undefined;
  hoverColor?: keyof typeof theme.color | undefined;
  iconSize?: "tiny" | "small" | "large" | undefined;
};

export const Button = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing.md} ${theme.spacing.xl};
  outline: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: ${theme.borderRadius};
  font-size: ${theme.font.size.body};
  font-weight: ${theme.font.weight.semiBold};
  font-family: inherit; // TODO This is stupid, somehow the styled.button reset the font-family so I have to set it. Someone make it go away
  cursor: pointer;
  line-height: ${theme.spacing.xl};
  transition: all 0.2s;
  gap: ${theme.spacing.sm};
  max-height: 46px;
  & > svg {
    ${({ iconSize }) => {
      switch (iconSize) {
        case "tiny":
          return `
          width: 12px;
          height: 12px;
          `;
        case "small":
          return `
          width: 16px;
          height: 16px;
          `;
        case "large":
          return `
          width: 32px;
          height: 32px;
          `;
        default:
          return `
          width: 24px;
          height: 24px;
          `;
      }
    }}
    fill: currentColor;
  }
  ${({ variant, color, hoverColor }) => {
    switch (variant) {
      case "primary":
        return `
          background-color: ${theme.color[color || "blue"][600]};
          color: ${theme.color.white};
          &:hover {
            background-color: ${
              theme.color[hoverColor || color || "blue"][500]
            };
          }
          &:focus:not(:active) {
            outline:  3px solid ${theme.color[color || "blue"][700]};
          }
          &:active {
            background-color: ${theme.color[color || "blue"][700]};
          }
          :focus:not(:focus-visible) {
            outline: none;
          }
        `;
      case "secondary":
        return `
          background-color: ${theme.color.white};
          color: ${theme.color[color || "blue"][600]};
          border-color: ${theme.color[color || "blue"][600]};
          &:hover {
            background-color: ${
              theme.color[hoverColor || color || "blue"][500]
            };
            border-color: ${theme.color[color || "blue"][500]};
            color: ${theme.color.white};
            svg {
              fill: ${theme.color.white};
            }
          }
          &:focus:not(:active) {
            outline:  2px solid ${theme.color[color || "blue"][700]};
          }
          &:active {
            color: ${theme.color.white};
            background-color: ${theme.color[color || "blue"][700]};
            svg {
              fill: ${theme.color.white};
            }
          }
          :focus:not(:focus-visible) {
            outline: none;
          }

        `;
      case "tertiary":
        return `
          padding: ${theme.spacing.xs};
          color: ${theme.color[color || "blue"][600]};
          text-decoration-line: none;
          text-decoration-thickness: 1px;
          text-underline-offset: 4px;
          &:hover {
            color: ${theme.color[hoverColor || color || "blue"][700]};
            text-decoration-line: underline;
          }
          &:focus:not(:active) {
            outline:  3px solid ${theme.color[color || "blue"][700]};
          }
          &:active {
            color: ${theme.color[color || "blue"][700]};
          }
          :focus:not(:focus-visible) {
            outline: none;
          }

        `;
    }
  }}

  ${({ size }) => {
    switch (size) {
      case "tiny":
        return `
          padding: 0;
          max-height: 24px;
        `;
      case "small":
        return `
          padding: ${theme.spacing.sm} ${theme.spacing.lg};
          max-height: 32px;
        `;
      case "stretched":
        return `
          width: 100%;
        `;
    }
  }}

  ${({ iconPosition }) =>
    iconPosition === "right" &&
    `
      flex-direction: row-reverse;
    `}

  ${({ disabled, variant }) =>
    disabled &&
    `
      pointer-events: none;
      ${
        variant === "primary"
          ? `
            background-color: ${theme.color.gray[300]};
            color: ${theme.color.gray[500]};
          `
          : variant === "secondary"
          ? `
            border-color: ${theme.color.gray[300]};
            color: ${theme.color.gray[500]};
          `
          : `
            color: ${theme.color.gray[500]};
          `
      }
    `}
`;
