import styled from "@emotion/styled";
import CustomArrowIcon from "assets/svg/arrow-left.svg?react";
import CustomTemplateIcon from "assets/svg/contract-template-icon.svg?react";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${theme.spacing.md};
  padding: 0 ${theme.spacing.lg} ${theme.spacing.lg};
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${theme.spacing.sm};
`;

export const HeadersIcons = styled.div`
  display: flex;
  align-items: center;
`;

const iconTemplateSize = "24px";
const iconArrowSize = "36px";
const commonIconStyles = `
  width: ${iconTemplateSize};
  height: ${iconTemplateSize};
  fill: ${theme.color.blue[600]};
  align-self: center;
`;

export const TemplateIcon = styled(CustomTemplateIcon)`
  ${commonIconStyles}
  margin: auto ${theme.spacing.sm};
`;

export const ArrowIcon = styled(CustomArrowIcon)`
  ${commonIconStyles}
  width: ${iconArrowSize};
  height: ${iconArrowSize};
`;

export const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.sm};
`;
