import React from "react";
import { useTranslation } from "react-i18next";
import { ContactDataDTO } from "pages/Contacts/ContactDataDTO";
import { Chip } from "components";
import { Tooltip } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LanguageIcon from "@mui/icons-material/Language";
import ArticleIcon from "@mui/icons-material/Article";
import PeopleIcon from "@mui/icons-material/People";
import ContactsIcon from "@mui/icons-material/Contacts";
import NoteAlt from "@mui/icons-material/NoteAlt";
import { getCountryName } from "../../components/Table/helpers";
import theme from "theme/theme";
import {
  Wrapper,
  RadioContainer,
  List,
  ListItem,
  StickyListItem,
  Text,
} from "./styles";
import { useLocale } from "hooks";

type ContactDetailsProps = {
  data: ContactDataDTO;
  isSelected?: boolean;
  isPreview?: boolean;
  component: React.ReactNode | null;
};

const contactFields = [
  { icon: ContactsIcon, field: "name" },
  { icon: LocalPhoneIcon, field: "phone" },
  { icon: MailIcon, field: "email" },
  { icon: LocationOnIcon, field: "address" },
  { icon: LocationOnIcon, field: "address2" },
  { icon: LocationOnIcon, field: "postalCode" },
  { icon: LocationOnIcon, field: "country" },
  { icon: NoteAlt, field: "notes" },
  { icon: ArticleIcon, field: "vatId" },
  { icon: LocationCityIcon, field: "city" },
  { icon: PeopleIcon, field: "numberOfEmployees" },
  { icon: LanguageIcon, field: "website" },
];

const getTitle = (text: string) => (text?.length > 21 ? text : "");

const ContactDetails = (props: ContactDetailsProps) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  return (
    <Wrapper>
      <List>
        {contactFields.map((fieldObj, index) => {
          const text =
            fieldObj.field === "country"
              ? getCountryName(props?.data[fieldObj.field] as string, locale)
              : (props?.data[fieldObj.field] as string) ?? "-";
          return (
            <ListItem key={index}>
              <>
                <Tooltip
                  title={t(
                    `pages.contacts.table.columnNames.${fieldObj.field}`
                  )}
                >
                  {React.createElement(fieldObj.icon, { fontSize: "small" })}
                </Tooltip>
                <Tooltip
                  title={getTitle(props?.data[fieldObj.field] as string)}
                >
                  <Text>{text}</Text>
                </Tooltip>
              </>
            </ListItem>
          );
        })}

        {!props.isPreview && props.component && (
          <StickyListItem isSelected={props.isSelected}>
            <RadioContainer>
              {props.isSelected && (
                <Chip blendMode="hard" color={theme.color.blue[500]}>
                  {t("pages.contacts.modal.duplicates.primary")}
                </Chip>
              )}
              {props.component}
            </RadioContainer>
          </StickyListItem>
        )}
      </List>
    </Wrapper>
  );
};

export default ContactDetails;
