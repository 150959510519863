/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OverviewDto } from "../models/OverviewDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class OverviewService {
  /**
   * Get data for dashboard
   * @param teamId
   * @returns OverviewDto
   * @throws ApiError
   */
  public static getOverview(teamId: string): CancelablePromise<OverviewDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/overview",
      query: {
        teamId: teamId,
      },
    });
  }
}
