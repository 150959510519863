/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TeamPermissionDto = {
  categoryId: string | null;
  level: TeamPermissionDto.level;
};

export namespace TeamPermissionDto {
  export enum level {
    READ = "READ",
    WRITE = "WRITE",
  }
}
