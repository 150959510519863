import React from "react";
import { dateFormatter } from "constants/utils";
import { useLocale, useUserInfo } from "hooks/GlobalStateHooks";
import { Tooltip } from "@mui/material";
import { RelativeDateContainer } from "./style";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { theme } from "theme";
import { useTranslation } from "react-i18next";

type Props = {
  value: string;
};

const DueDateCellRenderer = ({ value }: Props) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { userInfo } = useUserInfo();
  const userDateFormat = userInfo?.dateFormat;

  const formattedValue = dateFormatter(locale, value, userDateFormat);

  if (formattedValue !== "Invalid Date") {
    return <span>{dateFormatter(locale, value, userDateFormat)}</span>;
  } else {
    return (
      <Tooltip
        title={t(
          "pages.tasks.table.cellRenderers.dueDateCellRenderer.tooltipTitle",
          { fieldName: value }
        )}
      >
        <RelativeDateContainer>
          <ErrorOutlineIcon
            sx={{ height: theme.font.size.h6, color: theme.color.orange[600] }}
          />
          {value}
        </RelativeDateContainer>
      </Tooltip>
    );
  }
};

export default DueDateCellRenderer;
