import { Store } from "@react-pdf-viewer/core";
import { useEffect, useMemo, useState } from "react";
import { Match, OCRSearchStoreProps } from "./types";
import { Block } from "@contracthero/common/dist/src/ocr/types";

export const useOCRSearch = (store: Store<OCRSearchStoreProps>) => {
  const [searchRegExp, setSearchRegExp] = useState<RegExp | null>();
  const [open, setOpen] = useState(!!store.get("anchor"));
  const [matches, setMatches] = useState<Match[]>([]);
  const [currentMatch, setCurrentMatch] = useState(0);
  const [, setCurrentPage] = useState(0);
  const doc = store.get("doc");
  const blocks = store.get("blocks");

  useEffect(() => {
    const setOpenStatus = () => {
      setOpen(!!store.get("anchor"));
    };

    const updateCurrentPage = () => {
      setCurrentPage(store.get("currentPage") ?? 0);
    };

    store.subscribe("anchor", setOpenStatus);
    store.subscribe("currentPage", updateCurrentPage);
    return () => {
      store.unsubscribe("anchor", setOpenStatus);
      store.unsubscribe("currentPage", updateCurrentPage);
    };
  }, []);

  const pageBlocks = useMemo(
    () =>
      blocks
        ?.filter((b) => b.BlockType === "PAGE")
        .sort((a, b) => (a.Page ?? 0) - (b.Page ?? 0)),
    [blocks]
  );

  const lineBlocks = useMemo(() => {
    const lineMap = new Map<string, Block>();
    if (blocks) {
      for (const block of blocks) {
        if (block.Id && block.BlockType === "LINE") {
          lineMap.set(block.Id, block);
        }
      }
    }
    return lineMap;
  }, [blocks]);

  const setSearch = (input: string | null) => {
    const regex = input ? new RegExp(`(${input})`, "gi") : null;
    setSearchRegExp(regex);

    if (input && regex) {
      store.update("search", {
        search: input,
        regex: regex,
      });
    } else {
      store.update("search", undefined);
      setMatches([]);
      setCurrentMatch(0);
    }
  };

  const highlightElement = (match: Match) => {
    if (match) {
      if (match.block.Id) {
        const activeContainer = document.querySelector(
          `mark[data-block-id='${match.block.Id}'][data-part-index='${match.partIndex}']`
        );

        const currentlyActiveOccuranceElement = document.querySelector(
          ".ocr-layer-text-container > mark#active-occurance"
        );

        if (currentlyActiveOccuranceElement) {
          currentlyActiveOccuranceElement.removeAttribute("id");
        }

        if (activeContainer?.getAttribute("id") !== "active-occurance") {
          activeContainer?.setAttribute("id", "active-occurance");
          activeContainer?.scrollIntoView({ block: "center" });
        }
      }
    }
  };

  const jumpToMatch = (match: Match) => {
    if (!match) {
      return;
    }
    if (match.block.Id) {
      const activeContainer = document.querySelector(
        `mark[data-block-id='${match.block.Id}'][data-part-index='${match.partIndex}']`
      );
      if (activeContainer) {
        highlightElement(match);
        return;
      }
    }

    const jumpToPage = store.get("jumpToPage");
    if (jumpToPage) {
      const highlightAfterJump = (pageNumber: number) => {
        store.unsubscribe("currentPage", highlightAfterJump);
        setTimeout(() => {
          void highlightElement(match);
        }, 1000);
      };
      store.subscribe("currentPage", highlightAfterJump);
      void jumpToPage(match.page);
    }
  };

  const jumpToMatchWithIndex = (matchIndex: number) => {
    if (matchIndex < 0) {
      matchIndex = Math.max(0, matches.length - 1);
    }

    if (matchIndex >= matches.length) {
      matchIndex = 0;
    }

    setCurrentMatch(matchIndex);
    jumpToMatch(matches[matchIndex]);
  };

  const jumpToNextMatch = () => {
    jumpToMatchWithIndex(currentMatch + 1);
  };

  const jumpToPreviousMatch = () => {
    jumpToMatchWithIndex(currentMatch - 1);
  };

  const performSearch = () => {
    if (!doc || !searchRegExp) {
      return;
    }

    const matches = new Array<Match>();
    if (pageBlocks && lineBlocks) {
      for (const page of pageBlocks) {
        const relationships = page.Relationships ?? [];
        for (const relationship of relationships) {
          if (relationship.Type !== "CHILD") {
            continue;
          }
          for (const id of relationship.Ids ?? []) {
            const line = lineBlocks.get(id);
            if (!line || !line.Text) {
              continue;
            }

            const page = line.Page ?? 0;
            const splitText = line.Text.split(searchRegExp);

            if (splitText.length > 1) {
              matches.push(
                ...splitText
                  .map((t, idx) => ({
                    keyword: searchRegExp,
                    text: t,
                    page: page - 1,
                    block: line,
                    partIndex: idx,
                  }))
                  .filter(({ text }) => text.match(searchRegExp))
              );
            }
          }
        }
      }
      setMatches(matches);
      if (matches.length > 0) {
        jumpToMatch(matches[0]);
        setCurrentMatch(0);
      }
    }
  };

  return {
    searchRegExp,
    setSearch,
    performSearch,
    currentMatch,
    jumpToNextMatch,
    jumpToPreviousMatch,
    matchCount: matches.length,
    open: open,
  };
};
