import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div<{ isDragging?: boolean }>`
  transition: background-color 0.25s ease-in-out;

  ${({ isDragging }) =>
    isDragging &&
    `
    background-color: ${theme.color.blue[200]}80;
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing.lg};
  padding: ${theme.spacing.xl};
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.lg};
  width: 100%;
  min-height: 260px;
  border: 3px dashed ${theme.color.blue[300]}80;
  border-radius: ${theme.borderRadius};
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.semiBold};
  color: ${theme.color.blue[600]};
  background-color: ${theme.color.blue[200]}80;
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${theme.color.blue[100]};
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.semiBold};
  color: ${theme.color.green[500]};
  margin-top: ${theme.spacing.sm};
  padding: ${theme.spacing.sm};
  border-radius: ${theme.borderRadius};
`;
