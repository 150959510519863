import { formatContactName } from "../../pages/Contact/helpers";
import { ContactDataDTO } from "../../pages/Contacts/ContactDataDTO";
import {
  ContractFieldDTOV1,
  MultiLineTextFieldTypeDtoV1,
  SingleLineTextFieldTypeDtoV1,
} from "../../openapi";

export const getContactFieldValue = (contactData: ContactDataDTO): string => {
  return formatContactName(contactData);
};

export const getContactOptions = (contacts: ContactDataDTO[]) => {
  const options = [] as { value: string; key: string }[];

  contacts.forEach((contact) => {
    const option = {
      value: formatContactName(contact),
      key: contact.id,
    };
    options.push(option);
  });

  return options;
};

export const formatFieldValue = (
  fieldData: SingleLineTextFieldTypeDtoV1 | MultiLineTextFieldTypeDtoV1
) => {
  if (!fieldData || !fieldData?.value) {
    return "-";
  }
  const trimmedValue = fieldData.value?.toString().trim();

  return trimmedValue ? trimmedValue : "-";
};

export const getFieldIdByVisibleId = (
  definitions: ContractFieldDTOV1[],
  visibleId: string
) => {
  return definitions.filter((item) => item.visibleId === visibleId)[0].id;
};
