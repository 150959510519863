import React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { useOrganizationCurrentTeamQuery } from "shared/api/organization";
import { useTeam } from "contexts/team/hooks";
import routePaths from "constants/routePaths";
import { ButtonWithArrow, Loader, SectionHeader } from "components";
import { DetailsSection, DeleteSection } from "../shared";
import PermissionsForm from "./components/PermissionsForm";
import TreeIcon from "assets/svg/tree-icon.svg?react";
import { HeaderIconStyles } from "../Teams/styles";
import { BackButton } from "./styles";

const Team = () => {
  const { t } = useTranslation();
  const { id: currentTeamId } = useParams();
  const { organizationId } = useTeam();
  const navigate = useNavigate();

  const { data: currentTeam, isLoading: isTeamLoading } =
    useOrganizationCurrentTeamQuery(organizationId, currentTeamId as string);

  const onBack = () => {
    navigate(routePaths.SETTINGS_TEAMS);
  };

  if (isTeamLoading) return <Loader />;
  if (!currentTeam) return null;

  return (
    <>
      <BackButton>
        <ButtonWithArrow
          onClick={onBack}
          label={t("pages.settings.tabs.subTeams.section.showSubTeams.goBack")}
        />
      </BackButton>

      <DetailsSection currentEntity={currentTeam} />

      <CardWrapper>
        <SectionHeader
          title={t(
            "pages.settings.tabs.subTeams.section.showSubTeams.categoryAccess"
          )}
          icon={<TreeIcon />}
          noPadding
          iconStyle={HeaderIconStyles}
          hideButton={true}
        />
        <PermissionsForm />
      </CardWrapper>

      <DeleteSection currentEntity={currentTeam} />
    </>
  );
};

export default Team;
