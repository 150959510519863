import React, { useEffect, useState } from "react";
import Modal, { ModalResultEnum } from "components/Modal/Modal";
import { setValidationErrors } from "shared/service/errorResponseService";
import { Box, Button, DialogActions } from "@mui/material";
import { FormTextField } from "components";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { ContractViewInputDto, ContractViewItemDto } from "openapi";
import { AgGridReact } from "ag-grid-react";
import { theme } from "theme";
import { Switch } from "new-components";

const TitleWrapper = styled.h3`
  line-height: 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: #1a202c;
  margin: 0;
  margin-bottom: 0.5rem;
`;

const SwitcherWrapper = styled.div`
  width: 100%;
  & .MuiFormControlLabel-root {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

const initialValues = {
  name: "",
  shared: false,
};

type Props = {
  open: boolean;
  selectedView: ContractViewItemDto | null;
  gridRef: React.MutableRefObject<AgGridReact | undefined>;
  filterModelGetter: () => string | null;
  getFilterModelAsJson: () => string | null;
  createView: (viewData: Omit<ContractViewInputDto, "type">) => Promise<void>;
  updateView: (viewData: Omit<ContractViewItemDto, "owner">) => Promise<void>;
  setSelectedViewToEditOrDelete: React.Dispatch<
    React.SetStateAction<ContractViewItemDto | undefined>
  >;
  setShowSaveOrEditViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  deleteView?: (viewData: ContractViewItemDto) => void;
};

const ContractSaveOrEditViewModal = ({
  open,
  selectedView,
  gridRef,
  filterModelGetter,
  getFilterModelAsJson,
  updateView,
  setSelectedViewToEditOrDelete,
  setShowSaveOrEditViewModal,
  createView,
  deleteView,
}: Props) => {
  const { t } = useTranslation();
  const buttonClasses = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [shared, setShared] = useState<boolean>(false);

  const { control, getValues, setValue, watch, reset, setError } = useForm({
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (selectedView) {
      setValue("name", selectedView.name);
      setShared(selectedView?.shared);
    } else {
      reset(initialValues);
    }
  }, [selectedView]);

  const handleSaveOrEditViewModalClose = async (
    action?: ModalResultEnum,
    values?: unknown
  ) => {
    try {
      if (action === ModalResultEnum.SAVE) {
        const columnStateAsJson = JSON.stringify(
          gridRef.current?.columnApi.getColumnState()
        );
        const name = (values as { name: string }).name;
        await createView({
          name: name,
          data: columnStateAsJson,
          filter: filterModelGetter(),
          shared: shared,
        });
      } else if (action === ModalResultEnum.EDIT && selectedView) {
        const viewData = {
          id: selectedView.id,
          name: (values as { name: string }).name,
          data: selectedView.data,
          filter: getFilterModelAsJson(),
          type: selectedView.type,
          shared: shared,
        };
        await updateView(viewData);
      }
      reset(initialValues);
      setSelectedViewToEditOrDelete(undefined);
      setShowSaveOrEditViewModal(false);
    } catch (e) {
      setValidationErrors(
        e,
        setError,
        "pages.contracts.header.modals.saveViewModal",
        undefined,
        enqueueSnackbar,
        t
      );
    }
  };

  const handleKeyboardDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit();
    }
  };

  const handleModalClose = () => {
    void handleSaveOrEditViewModalClose(ModalResultEnum.CANCEL);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!selectedView?.id) return;
    e.preventDefault();
    e.stopPropagation();

    const deleteHandler = deleteView
      ? (
          type: ModalResultEnum.DELETE,
          id: ContractViewItemDto | string | null
        ) => deleteView(selectedView)
      : () => null;
    deleteHandler && deleteHandler(ModalResultEnum.DELETE, selectedView.id);
  };

  const onSubmit = () => {
    void handleSaveOrEditViewModalClose(
      !selectedView ? ModalResultEnum.SAVE : ModalResultEnum.EDIT,
      getValues()
    );
  };

  return (
    <Modal open={open} isShowHeader={false} isShowActionButtons={false}>
      <>
        <Box>
          <TitleWrapper>
            {!selectedView
              ? t("pages.contracts.header.modals.saveViewModal.title")
              : t("pages.contracts.header.modals.editViewModal.title")}
          </TitleWrapper>
          {!selectedView
            ? t("pages.contracts.header.modals.saveViewModal.subtitle")
            : t("pages.contracts.header.modals.editViewModal.subtitle")}
        </Box>
        <form
          name="contractSaveOrEditView"
          noValidate
          style={{
            marginTop: "1rem",
          }}
        >
          <FormTextField
            control={control}
            name="name"
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
              handleKeyboardDown(e)
            }
            label={
              !selectedView
                ? t("pages.contracts.header.modals.saveViewModal.name")
                : t("pages.contracts.header.modals.editViewModal.name")
            }
          />

          <SwitcherWrapper>
            <Switch
              checked={shared}
              onChange={() => {
                setShared(!shared);
              }}
              name="shared"
              label={t("pages.contracts.header.modals.editViewModal.share")}
              labelPosition="left"
              stretch
            />
          </SwitcherWrapper>

          <DialogActions
            sx={{
              marginTop: theme.spacing.xxl,
              marginBottom: theme.spacing.xs,
              padding: "0 !important",
            }}
          >
            {selectedView ? (
              <Button
                className={buttonClasses.deleteOutlineButton}
                onClick={(e) => handleDeleteClick(e)}
                sx={{ marginRight: "auto" }}
              >
                {t("pages.contracts.header.modals.buttons.deleteView")}
              </Button>
            ) : null}
            <Button
              className={buttonClasses.cancelButton}
              onClick={() => handleModalClose()}
            >
              {t("pages.contracts.header.modals.buttons.cancel")}
            </Button>
            <Button
              className={buttonClasses.saveButton}
              disabled={!watch().name}
              onClick={() => onSubmit()}
            >
              {t("pages.contracts.header.modals.buttons.save")}
            </Button>
          </DialogActions>
        </form>
      </>
    </Modal>
  );
};

export default ContractSaveOrEditViewModal;
