import React, { FC, useEffect, useState } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar,
} from "notistack";
import { ApiError, LoginDto, TeamPublicInfoDto } from "openapi";
import { ForbiddenResponse } from "shared/types/ErrorResponse.type";
import { setValidationErrors } from "shared/service/errorResponseService";
import { useAuthentication } from "hooks/GlobalStateHooks";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import { FormTextField } from "components";
import { FormCheckbox } from "components/FormItems/FormCheckbox/FormCheckbox";
import { theme } from "theme";
import routePaths from "../../constants/routePaths";
import { Form } from "./styles";
import { Button } from "components";

const validationSchema = () =>
  Yup.object({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    rememberMe: Yup.boolean().default(false),
  });

const initialValues: LoginDto = {
  email: "",
  password: "",
  rememberMe: false,
};

function handleUnverifiedEmail(
  apiError: ApiError,
  navigate: NavigateFunction,
  email: string
): boolean {
  if (apiError.status !== 403) {
    return false;
  }

  const response = apiError.body as ForbiddenResponse;

  if (response.message === "unverified") {
    navigate(routePaths.VERIFICATION_EMAIL, {
      state: { email },
    });
    return true;
  }
  return false;
}

function handleBadEmailOrPassword(
  apiError: ApiError,
  t: TFunction<"translation">,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey
) {
  if (apiError.status === 401) {
    enqueueSnackbar(t("pages.login.validation.invalidData"), {
      variant: "error",
    });
    return true;
  }
  return false;
}

type LoginFormProps = {
  inivtationToken?: string;
  invitationInfo?: TeamPublicInfoDto;
};

const LoginForm: FC<LoginFormProps> = ({ invitationInfo }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { loginWithPassword } = useAuthentication();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { control, handleSubmit, setError, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema()),
  });

  useEffect(() => {
    if (invitationInfo) {
      setValue("email", invitationInfo.email);
    }
  }, [invitationInfo]);

  const onSubmit = async (values: LoginDto) => {
    setIsLoading(true);
    try {
      await loginWithPassword(values);
    } catch (e) {
      if (handleUnverifiedEmail(e as ApiError, navigate, values.email)) {
        return;
      } else if (handleBadEmailOrPassword(e as ApiError, t, enqueueSnackbar)) {
        return;
      } else {
        setValidationErrors(
          e,
          setError,
          "pages.login",
          undefined,
          enqueueSnackbar,
          t
        );
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Form name="loginForm" noValidate onSubmit={handleSubmit(onSubmit)}>
        <FormTextField
          sx={{ margin: 0 }}
          control={control}
          name="email"
          label={t("pages.login.form.email")}
          disabled={invitationInfo && !!invitationInfo.email}
          required
        />
        <FormTextField
          sx={{ margin: 0 }}
          control={control}
          name="password"
          required
          label={t("pages.login.form.password")}
          type="password"
          autoComplete="current-password"
        />
        <FormCheckbox
          control={control}
          name="rememberMe"
          sx={{ color: theme.color.gray[700] }}
          label={
            <span style={{ color: theme.color.gray[700] }}>
              {t("pages.login.rememberMe")}
            </span>
          }
        />
        <Button
          id="loginBtn"
          type="submit"
          size="large"
          fullWidth
          className={classes.baseButton}
          style={{ margin: 0 }}
          isLoading={isLoading}
        >
          {t("pages.login.form.submit")}
        </Button>
      </Form>
    </>
  );
};

export default LoginForm;
